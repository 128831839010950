/* eslint-disable no-alert */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import ButtonSquare from '../common/atoms/buttonBasic/ButtonSquare';
import CopyRight from '../common/atoms/commonParts/CopyRight';

import McsFooterPR from '../common/molecules/footerParts/McsFooterPR';
import McsFooterNav from '../common/molecules/footerParts/McsFooterNav';
import McsRoundSNSButtons from '../common/molecules/commonParts/McsRoundSNSButtons';
import McsFooterBannerBack from '../common/molecules/footerParts/McsFooterBannerBack';
import McsFooterBannerFC from '../common/molecules/footerParts/McsFooterBannerFC';
import McsJasrac from '../common/molecules/commonParts/McsJasrac';
import McsFooterPaidFC from '../common/molecules/footerParts/McsFooterPaidFC';

import OrgPostContent from '../common/organisms/footerParts/OrgPostContent';
import OrgTwitterShareButton from '../common/organisms/footerParts/OrgTwitterShareButton';

import * as styles from './footer.module.scss';

import {
  PROption,
  PROptionInitialValue,
  ButtonOption,
  ButtonOptionInitialValue,
  PostOption,
  PostOptionInitialValue,
  FanclubOption,
  FanclubOptionInitialValue,
  BackOption,
  BackOptionInitialValue,
  TwitterOption,
  TwitterOptionInitialValue,
  PaidFCOption,
  PaidFCOptionInitialValue,
} from '../../interfaces/footerInterFace';

type Props = {
  // PR
  isViewPR?: boolean;
  prOption?: PROption;
  // SNS
  isViewSNS?: boolean;
  onClickTwitter?: () => void;
  onClickFaceBook?: () => void;
  onClickLine?: () => void;
  // nav
  isViewNav?: boolean;
  onClickAbout?: () => void;
  onClickUserAgreement?: () => void;
  onClickTransactions?: () => void;
  onClickPrivacyPolicy?: () => void;
  onClickFAQ?: () => void;
  onClickRule?: () => void;
  onClickOperatingCompany?: () => void;
  onClickContact?: () => void;
  // jasrac
  isViewJasrac?: boolean;
  // copyright
  isViewCopyRight?: boolean;
  // button
  isViewButton?: boolean;
  buttonOption?: ButtonOption;
  // post
  isViewPost?: boolean;
  postOption?: PostOption;
  // fanclub
  isViewFanclub?: boolean;
  fanclubOption?: FanclubOption;
  // back
  isViewBack?: boolean;
  backOption?: BackOption;
  // twitter
  isViewTwitter?: boolean;
  twitterOption?: TwitterOption;
  // 有料FCフッダー
  isPaidFC?: boolean;
  paidFCOption?: PaidFCOption;
};

const OrgFooter: FC<Props> = ({
  // PR
  isViewPR = false,
  prOption = PROptionInitialValue,
  // SNS
  isViewSNS = false,
  onClickTwitter = () => undefined,
  onClickFaceBook = () => undefined,
  onClickLine = () => undefined,
  // nav
  isViewNav = false,
  onClickAbout = () => undefined,
  onClickUserAgreement = () => undefined,
  onClickTransactions = () => undefined,
  onClickPrivacyPolicy = () => undefined,
  onClickFAQ = () => undefined,
  onClickRule = () => undefined,
  onClickOperatingCompany = () => undefined,
  onClickContact = () => undefined,
  // jasrac
  isViewJasrac = false,
  // copyright
  isViewCopyRight = false,
  // button
  isViewButton = false,
  buttonOption = ButtonOptionInitialValue,
  // post
  isViewPost = false,
  postOption = PostOptionInitialValue,
  // fanclub
  isViewFanclub = false,
  fanclubOption = FanclubOptionInitialValue,
  // back
  isViewBack = false,
  backOption = BackOptionInitialValue,
  // twitter
  isViewTwitter = false,
  twitterOption = TwitterOptionInitialValue,
  // 有料FCフッダー
  isPaidFC = false,
  paidFCOption = PaidFCOptionInitialValue,
}) => {
  const classNames = ClassNames(styles.OrgFooterBase, {
    [styles.OrgFooterBaseWithBanner]:
      isViewButton || isViewFanclub || isViewBack || isViewPost,
    [styles.OrgFooterBaseWithTwitter]: isViewTwitter,
  });

  return (
    <div className={classNames}>
      {isViewPR && (
        <McsFooterPR
          rightButtonText={prOption.right.text}
          onClickRightButton={prOption.right.onClick}
        />
      )}

      {isViewSNS && (
        <McsRoundSNSButtons
          onTwitterClick={onClickTwitter}
          onFaceBookClick={onClickFaceBook}
          onLineClick={onClickLine}
        />
      )}

      {isViewNav && (
        <McsFooterNav
          onClickAbout={onClickAbout}
          onClickUserAgreement={onClickUserAgreement}
          onClickTransactions={onClickTransactions}
          onClickPrivacyPolicy={onClickPrivacyPolicy}
          onClickFAQ={onClickFAQ}
          onClickRule={onClickRule}
          onClickOperatingCompany={onClickOperatingCompany}
          onClickContact={onClickContact}
        />
      )}

      {isViewJasrac && <McsJasrac />}

      {isViewCopyRight && <CopyRight />}

      {isViewButton && (
        <ButtonSquare
          text={buttonOption.text}
          onClick={buttonOption.onClick}
          cssClass={styles.OrgFooterFixPrimaryButton}
        />
      )}

      {isViewPost && (
        <OrgPostContent
          fanclubList={postOption.fanclubList}
          onClickFanclub={postOption.onClickFanclub}
          cssClass={styles.OrgFooterFixPrimaryButton}
        />
      )}

      {isViewFanclub && (
        <McsFooterBannerFC
          imageUrl={fanclubOption.fanclub.icon_image}
          mainText={fanclubOption.fanclub.name}
          subText={fanclubOption.fanclub.site_name}
          buttonText={fanclubOption.text}
          onClickFanclub={fanclubOption.onClickFanclub}
          onClickButton={fanclubOption.onClickButton}
          cssClass={styles.OrgFooterFixBanner}
        />
      )}

      {isViewBack && (
        <McsFooterBannerBack
          onClick={backOption.onClick}
          cssClass={styles.OrgFooterFixBanner}
        />
      )}

      {isViewTwitter && (
        <OrgTwitterShareButton
          text={twitterOption.text}
          onClickButton={twitterOption.onClickButton}
          cssClass={styles.OrgFooterTwitterShareButton}
        />
      )}

      {isPaidFC && (
        <McsFooterPaidFC
          option={paidFCOption}
          cssClass={styles.MscFooterPaidFC}
        />
      )}
    </div>
  );
};

export default OrgFooter;
