import { Reducer } from 'redux';
import { Actions } from '../actions/changeCreditCardActions';
import * as ActionType from '../actions/changeCreditCardConstants';
import {} from '../interfaces/cardInterFace';

type State = {
  hasNoCard: boolean;
  ready: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
};

export const initialState: State = {
  hasNoCard: false,
  ready: false,
  loading: false,
  success: false,
  error: false,
};

const changeEmailReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // get ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    // got ready
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get card info
    case ActionType.GET_CARD_INFO_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_CARD_INFO_SUCCEED: {
      const hasNoCard = action.payload.result === null;

      return {
        ...state,
        loading: false,
        hasNoCard,
      };
    }
    case ActionType.GET_CARD_INFO_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // update card info
    case ActionType.UPDATE_CARD_INFO_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.UPDATE_CARD_INFO_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ActionType.UPDATE_CARD_INFO_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default changeEmailReducer;
