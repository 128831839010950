/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */

import moment from 'moment';
import { SelectField } from '../interfaces/utilInterFace';

const FROM_YEAR = 1900;
const YEAR: SelectField = [...Array(moment().year() - FROM_YEAR + 1)].map(
  (_, i) => ({
    label: (i + FROM_YEAR).toString(),
    value: i + FROM_YEAR,
  }),
);
const YEARS = YEAR.reverse();
const MONTHS: SelectField = [...Array(12)].map((_, i) => ({
  label: (i + 1).toString(),
  value: i + 1,
}));
const DATES: SelectField = [...Array(31)].map((_, i) => ({
  value: i + 1,
  label: (i + 1).toString(),
}));

/**
 * 月と日が存在する年を返す
 */
export const getYears = (month: number, date: number): SelectField => {
  if (!month) return YEARS;

  const current = moment();

  if (!date && month > current.month() + 1) {
    return YEARS.slice(0, YEARS.length - 1);
  }

  const expect = moment()
    .month(month - 1)
    .date(date);
  if (current.isBefore(expect)) return YEARS.slice(0, YEARS.length - 1);

  return YEARS;
};

/**
 * 年と日が存在する月を返す
 */
export const getMonths = (year: number, date: number): SelectField => {
  if (!year && !date) return MONTHS;

  if (!year) {
    return MONTHS.filter(m => {
      const expect = moment()
        .month(m.value - 1)
        .date(date);

      return m.value === expect.month() + 1;
    });
  }

  if (!date) {
    const current = moment();

    return current.year() > year
      ? MONTHS
      : MONTHS.filter(m => m.value <= current.month() + 1);
  }

  const current = moment();

  return MONTHS.filter(m => {
    const expect = moment()
      .year(year)
      .month(m.value - 1)
      .date(date);

    return current.isSameOrAfter(expect) && m.value === expect.month() + 1;
  });
};

/**
 * 年と月が存在する日を返す
 */
export const getDates = (year: number, month: number): SelectField => {
  if (!month) return DATES;

  if (!year) {
    const expect = moment().month(month - 1);

    return DATES.filter(d => d.value <= expect.year(1900).daysInMonth());
  }

  const current = moment();
  const expect = moment()
    .year(year)
    .month(month - 1);
  if (current.isSame(expect))
    return DATES.filter(d => d.value <= current.date() - 1);

  return DATES.filter(d => d.value <= expect.daysInMonth());
};

export const getDatesFromToday = () => {
  const dates: SelectField = [...Array(365)].map((_, i) => ({
    label: moment()
      .add('day', i)
      .format('YYYY/M/D'),
    // value: i + 1,
    value: moment()
      .add('day', i)
      .valueOf(),
  }));

  return dates;
};

/**
 * interval刻みの0:00 ~ 23:59までの時間リストを返す
 * @param interval 分
 */
export const getTimes = (interval = 15) => {
  const times: SelectField = [];

  let i = 0;
  while (
    moment().date() ===
    moment()
      .hour(0)
      .minute(0)
      .second(0)
      .millisecond(0)
      .add('minutes', i * interval)
      .date()
  ) {
    times.push({
      label: moment()
        .hour(0)
        .minute(0)
        .second(0)
        .add('minutes', i * interval)
        .format('HH:mm'),
      // value: i + 1,
      value: moment()
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .add('minutes', i * interval)
        .valueOf(),
    });
    i++;
  }

  return times;
};
