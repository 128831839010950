/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState, useCallback } from 'react';
import ClassNames from 'classnames';

import ButtonSquare from '../../atoms/buttonBasic/ButtonSquare';
import McsInputSecondary from '../../molecules/formParts/McsInputSecondary';
import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';

import * as styles from './OrgContentAddCategory.module.scss';

interface Props {
  cssClass?: string;
  text?: string;
  onChangeText?: (e: React.FormEvent<HTMLInputElement>) => void;
  onClickAdd?: () => void;
  error?: string;
}

const OrgContentAddCategory: FC<Props> = ({
  cssClass = '',
  text = '',
  onChangeText = () => undefined,
  onClickAdd = () => undefined,
  error = '',
}) => {
  const classNames = ClassNames(styles.OrgContentAddCategoryBase, cssClass);

  const [isEditable, setEditable] = useState(false);

  const handleOpen = useCallback(() => {
    setEditable(true);
  }, []);

  const handleClose = useCallback(() => {
    setEditable(false);
  }, []);

  return (
    <div className={classNames}>
      {isEditable ? (
        <div>
          <div className={styles.OrgContentAddCategoryInputContainer}>
            <McsInputSecondary
              inputName="subject"
              type="Senary"
              value={text}
              placeholder="新規カテゴリ名"
              onChange={onChangeText}
              maxLength={20}
              isError={!!error}
              errorText={error}
              cssClass={styles.OrgContentAddCategoryInput}
            />
            <ButtonSquare
              text="追加する"
              type="Senary"
              size="Secondary"
              onClick={onClickAdd}
              cssClass={styles.OrgContentAddCategoryButton}
            />
          </div>

          <div className={styles.closeWrapper}>
            <ButtonRoundedCorners
              text="閉じる"
              type="Quinary"
              onClick={handleClose}
              cssClass={styles.close}
            />
          </div>
        </div>
      ) : (
        <ButtonSquare
          text="カテゴリ追加"
          type="Quaternary"
          size="Tertiary"
          onClick={handleOpen}
          cssClass={styles.OrgContentAddCategoryOpen}
        />
      )}
    </div>
  );
};

export default OrgContentAddCategory;
