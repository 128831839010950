import React, { FC } from 'react';
import ClassNames from 'classnames';

import LabelTag from '../../atoms/label/LabelTag';

import * as styles from './OrgSalesAndTransfer.module.scss';

interface Props {
  label?: string;
  price?: number;
  description?: string;
  cssClass?: string;
}

const OrgProductDetails: FC<Props> = ({
  label = '',
  price = 0,
  description = '',
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.base, cssClass);

  return (
    <ul className={classNames}>
      <li className={styles.unit}>
        <LabelTag type="Secondary" text={label} />
        <p className={styles.price}>
          {price.toLocaleString()}
          <span>円</span>
        </p>
      </li>
      <li className={styles.unit2}>
        <p className={styles.description}>{description}</p>
      </li>
    </ul>
  );
};

export default OrgProductDetails;
