/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import IconArrow from '../../atoms/iconParts/IconArrow';

import McsIconLabelRoundPrimary from '../../molecules/iconWithLabel/McsIconLabelRoundPrimary';
import McsIconWithText from '../../molecules/iconWithText/McsIconWithTextPrimary';

import * as styles from './OrgHeaderNav.module.scss';

type Props = {
  iconSrc?: string;
  userName?: string;
  userId?: string;
  userFollow?: string;
  userLike?: string;

  onUserClick?: () => void;
  onFollowClick?: () => void;
  onLikeClick?: () => void;
  onClickSetting?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onClickHelp?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
  onLogoutClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;

  cssClass?: string;
};

const OrgHeaderNav: FC<Props> = ({
  iconSrc = '',
  userName = 'userName',
  userId = 'userId',
  userFollow = '0',
  userLike = '0',

  onUserClick = () => undefined,
  onFollowClick = () => undefined,
  onLikeClick = () => undefined,
  onClickSetting = () => undefined,
  onClickHelp = () => undefined,
  onLogoutClick = () => undefined,

  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgHeaderNavBase, cssClass);

  return (
    <div className={classNames}>
      <div className={styles.OrgHeaderNavUser} onClick={onUserClick}>
        <McsIconLabelRoundPrimary
          src={iconSrc}
          mainText={userName}
          subText={`@${userId}`}
        />
        <IconArrow />
      </div>

      <div>
        <McsIconWithText
          iconType="Star"
          mainText={userFollow}
          subText="フォロー"
          onClick={onFollowClick}
        />
        <McsIconWithText
          iconType="FavoriteWideLine"
          mainText={userLike}
          subText="いいね"
          onClick={onLikeClick}
        />
      </div>

      <ul className={styles.OrgHeaderNavLinks}>
        <li>
          <a onClick={onClickSetting}>設定</a>
        </li>
        <li>
          <a onClick={onClickHelp}>ヘルプセンター</a>
        </li>
        <li>
          <a onClick={onLogoutClick}>ログアウト</a>
        </li>
      </ul>
    </div>
  );
};

export default OrgHeaderNav;
