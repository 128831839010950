/* eslint-disable no-useless-return */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
import React, { FC, useRef, useState, useMemo, useEffect } from 'react';
import ClassNames from 'classnames';
import * as styles from './InputFileHero.module.scss';

interface Props {
  title?: string;
  type?: string;
  fileType?: 'image' | 'video' | 'audio';
  inputNameHero?: string;
  preview?: string;
  fileName?: string;
  cssClass?: string;
  disabled?: boolean;

  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;

  isVariableHeight?: boolean;

  isError?: boolean;
  errorText?: string;
}

const Input: FC<Props> = ({
  title = 'アイキャッチ画像',
  type = 'Primary',
  fileType = 'image',
  inputNameHero = '',
  preview = '',
  fileName = '',
  cssClass = '',
  disabled = false,

  onClick = () => undefined,
  onFocus = () => undefined,
  onBlur = () => undefined,
  onChange = () => undefined,

  isVariableHeight = false,

  isError = false,
  errorText = 'Error',
}) => {
  const classNames = ClassNames(styles.InputBase, cssClass, {
    [styles.InputPrimary]: type === 'Primary', // Primary
    [styles.InputSecondary]: type === 'Secondary', // Secondary
    [styles.InputTertiary]: type === 'Tertiary', // Tertiary
    [styles.InputQuaternary]: type === 'Quaternary', // Quaternary

    [styles.InputError]: isError, // isError
  });

  const bgImageRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(128);

  const accept = useMemo(() => {
    return fileType === 'image'
      ? 'image/*'
      : fileType === 'video'
      ? 'video/mp4,video/quicktime'
      : fileType === 'audio'
      ? 'audio/mpeg,audio/aac'
      : '';
  }, [fileType]);

  // 仮、現状window resizeには対応していない
  useEffect(() => {
    if (!preview || !isVariableHeight) return;

    const image = new Image();

    image.addEventListener('load', () => {
      if (!bgImageRef.current) return;

      const { naturalHeight, naturalWidth } = image;

      setHeight(
        (naturalHeight * bgImageRef.current.clientWidth) / naturalWidth,
      );
    });
    image.src = preview;
  }, [isVariableHeight, preview]);

  return (
    <>
      <div className={classNames}>
        <div
          ref={bgImageRef}
          className={styles.InputFileThumbnail}
          style={
            preview
              ? {
                  background: `url(${preview}) #ffffff no-repeat center center / cover`,
                  height,
                }
              : {}
          }
        >
          {fileType === 'video' && (
            <video
              src={preview}
              // poster={preview}
              className={styles.InputFileVideoPreview}
              style={{
                zIndex: preview ? 0 : -1,
              }}
            />
          )}
          {/* 背景画像ある時表示 */}
          {!!preview && fileType === 'audio' && (
            <div className={styles.InputFileVoicePreview} />
          )}
          {!!preview && fileType === 'image' && (
            <div className={styles.InputFileThumbnailCover} />
          )}
          {/*
            背景画像ある時表示
              styles.InputFileTitle
            背景画像ない時時表示
              styles.InputFileTitleNull
          */}
          <div
            className={
              preview ? styles.InputFileTitle : styles.InputFileTitleNull
            }
          >
            {title}
          </div>
          {!!fileName && <div className={styles.InputFileName}>{fileName}</div>}
          <input
            type="file"
            accept={accept}
            name={inputNameHero}
            onChange={onChange}
            disabled={disabled}
            multiple
          />
        </div>
      </div>
      {isError && <p className={styles.InputErrorText}>{errorText}</p>}
    </>
  );
};

export default Input;
