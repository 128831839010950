import { AllState } from '../store';

const getUserState = (state: AllState) => {
  return state.user;
};

const getIsLastPage = (state: AllState) => {
  return state.user.pager.current_page === state.user.pager.last_page;
};

export default {
  getUserState,
  getIsLastPage,
};
