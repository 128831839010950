import React, { FC } from 'react';
import ClassNames from 'classnames';

import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';

import McsIconLabelSquareSecondary from '../../molecules/iconWithLabel/McsIconLabelSquareSecondary';

import {
  Fanclub,
  FanclubInitialValue,
} from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgFCListUnitTertiary.module.scss';

type Props = {
  fanclub?: Partial<Fanclub>;
  isFollow?: boolean;
  isFollowButton?: boolean;
  onClickFanclub?: () => void;
  onClickFollow?: () => void;
  cssClass?: string;
};

const OrgListUnit: FC<Props> = ({
  fanclub = FanclubInitialValue,
  isFollow = false,
  isFollowButton = false,
  onClickFanclub = () => undefined,
  onClickFollow = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListUnitBase, cssClass);

  return (
    <li className={classNames} key={fanclub.id}>
      <McsIconLabelSquareSecondary
        src={fanclub.icon_image}
        mainText={fanclub.name}
        subText={fanclub.site_name}
        readText={fanclub.description}
        onClick={onClickFanclub}
        cssClass={
          isFollowButton
            ? styles.OrgFCListUnitUser
            : styles.OrgFCListUnitUserNoFollow
        }
      />
      {isFollowButton && (
        <ButtonRoundedCorners
          text={isFollow ? 'フォロー中' : 'フォロー'}
          type={isFollow ? 'Primary' : 'Secondary'}
          size="Septenary"
          cssClass={styles.OrgFCListUnitFollowButton}
          onClick={onClickFollow}
        />
      )}
    </li>
  );
};

export default OrgListUnit;
