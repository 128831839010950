import * as ActionType from './editUserProfileConstants';
import {
  UpdateUserProfileParams,
  UpdateUserProfileErrors,
  UpdateUserProfileResult,
} from '../interfaces/userInterFace';

export const getReady = () =>
  ({
    type: ActionType.GET_READY,
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const updateUserProfileEvent = {
  start: (params: UpdateUserProfileParams) =>
    ({
      type: ActionType.UPDATE_USER_PROFILE_START,
      payload: params,
    } as const),

  succeed: (result: UpdateUserProfileResult) =>
    ({
      type: ActionType.UPDATE_USER_PROFILE_SUCCEED,
      payload: { result },
    } as const),

  fail: (errors: UpdateUserProfileErrors) =>
    ({
      type: ActionType.UPDATE_USER_PROFILE_FAIL,
      payload: { errors },
    } as const),
};

export const cleanup = () => ({
  type: ActionType.CLEANUP,
});

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // update user profile
  | ReturnType<typeof updateUserProfileEvent.start>
  | ReturnType<typeof updateUserProfileEvent.succeed>
  | ReturnType<typeof updateUserProfileEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
