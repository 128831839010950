import { Reducer } from 'redux';
import { Actions } from '../actions/userActions';
import * as ActionType from '../actions/userConstants';
import { Pager, PagerInitialValue } from '../interfaces/commonInterFace';
import {
  UserProfile,
  UserProfileInitialValue,
} from '../interfaces/userInterFace';
import { Fanclub, FanclubWithUsers } from '../interfaces/fanclubInterFace';
import { Content } from '../interfaces/contentInterFace';

type State = {
  profile: UserProfile;
  follows: Fanclub[];
  likes: Content[];
  pager: Pager;
  pickups: FanclubWithUsers[];
  recommends: Fanclub[];
  ready: boolean;
  loading: boolean;
  error: boolean;
  success: boolean;
};

const initialState: State = {
  profile: UserProfileInitialValue,
  follows: [],
  likes: [],
  pager: PagerInitialValue,
  pickups: [],
  recommends: [],
  ready: false,
  loading: true,
  error: false,
  success: false,
};

const userReducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get user profile
    case ActionType.GET_USER_PROFILE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_USER_PROFILE_SUCCEED: {
      const { result } = action.payload;

      return {
        ...state,
        profile: result,
        loading: false,
      };
    }
    case ActionType.GET_USER_PROFILE_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // get following fanclub list
    case ActionType.GET_FOLLOWING_FANCLUB_LIST_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_FOLLOWING_FANCLUB_LIST_SUCCEED: {
      const { reset } = action.payload.params;
      const { data, ...pager } = action.payload.result.fanclubs;
      const follows = reset ? data : [...state.follows, ...data];

      return {
        ...state,
        follows,
        pager,
        loading: false,
      };
    }
    case ActionType.GET_FOLLOWING_FANCLUB_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // get liked content list
    case ActionType.GET_LIKED_CONTENT_LIST_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_LIKED_CONTENT_LIST_SUCCEED: {
      const { reset } = action.payload.params;
      const { data, ...pager } = action.payload.result;
      const likes = reset ? data : [...state.likes, ...data];

      return {
        ...state,
        likes,
        pager,
        loading: false,
      };
    }
    case ActionType.GET_LIKED_CONTENT_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // get pickup fanclub list
    case ActionType.GET_PICKUP_FANCLUB_LIST_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_PICKUP_FANCLUB_LIST_SUCCEED: {
      return {
        ...state,
        pickups: action.payload.result.data,
        loading: false,
      };
    }
    case ActionType.GET_PICKUP_FANCLUB_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // get recommend fanclub list
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_SUCCEED: {
      return {
        ...state,
        recommends: action.payload.result.data,
        loading: false,
      };
    }
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // freejoin fanclub
    case ActionType.FREEJOIN_FANCLUB_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.FREEJOIN_FANCLUB_SUCCEED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.FREEJOIN_FANCLUB_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // withdraw fanclub
    case ActionType.WITHDRAW_FANCLUB_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.WITHDRAW_FANCLUB_SUCCEED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.WITHDRAW_FANCLUB_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default userReducer;
