/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState, useEffect } from 'react';
import ClassNames from 'classnames';
import blankImageUrl from '../../assets/images/blank-image.png';
import * as styles from './IconThumbnailRectangle.module.scss';

interface Props {
  src?: string;
  type?: string;
  size?: string;
  blur?: boolean;
  cssClass?: string;
  onClick?: () => void;
}

const Icon: FC<Props> = ({
  src = '',
  type = 'Primary',
  size = 'Primary',
  blur = false,
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.IconBase, cssClass, {
    [styles.IconPrimary]: type === 'Primary', // Primary
    [styles.IconSizePrimary]: size === 'Primary', // Primary
    [styles.IconSizeSecondary]: size === 'Secondary', // Secondary
  });

  const image = src || blankImageUrl;

  return (
    <div className={classNames}>
      <div
        className={blur ? styles.withBlur : styles.noBlur}
        onClick={onClick}
        style={{
          background: `url(${image}) no-repeat center center / cover`,
        }}
      />
    </div>
  );
};

export default Icon;
