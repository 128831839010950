import { AllState } from '../store';

const getContentState = (state: AllState) => {
  return state.content;
};

const getContentDetails = (state: AllState) => {
  return state.content.contentDetails;
};

const getRecentPhotoList = (state: AllState) => {
  return state.content.recentPhotos;
};

const getRecentBlogList = (state: AllState) => {
  return state.content.recentBlogs;
};

const getRecommendFanclubList = (state: AllState) => {
  return state.content.recommendFanclubs;
};

const getCommentCount = (state: AllState) => {
  return state.content.contentDetails.comments.length;
};

export default {
  getContentState,
  getContentDetails,
  getRecentPhotoList,
  getRecentBlogList,
  getRecommendFanclubList,
  getCommentCount,
};
