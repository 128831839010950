import { Reducer } from 'redux';
import { Actions } from '../actions/adminMenuActions';
import * as ActionType from '../actions/adminMenuConstants';

import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../interfaces/fanclubInterFace';

type State = {
  details: FanclubDetails;
  unreadChatCount: number;
  ready: boolean;
  loading: boolean;
  error: boolean;
};

export const initialState: State = {
  details: FanclubDetailsInitialValue,
  unreadChatCount: 0,
  ready: false,
  loading: false,
  error: false,
};

const artistReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fanclub details
    case ActionType.GET_FANCLUB_DETAILS_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_SUCCEED: {
      return {
        ...state,
        details: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // チャットの未読件数取得
    case ActionType.GET_UNREAD_CHAT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_UNREAD_CHAT_SUCCEED: {
      return {
        ...state,
        unreadChatCount: action.payload,
        loading: false,
      };
    }
    case ActionType.GET_UNREAD_CHAT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default artistReducer;
