/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useState, useCallback } from 'react';
import Slider, { Settings } from 'react-slick';

import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';

import ImageUrl from '../../assets/images/dummy-onbarding.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import * as styles from './OrgOnBoarding.module.scss';

interface Props {
  imageList?: string[];
  onClickStart?: () => void;
}

const OrgOnBoarding: FC<Props> = ({
  imageList = [ImageUrl, ImageUrl, ImageUrl, ImageUrl],
  onClickStart = () => undefined,
}) => {
  const [nav, setNav] = useState<Slider>();
  const [current, setCurrent] = useState(0);
  const settings: Settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index: number) => {
      handleChange(index);
    },
  };

  const handleChange = useCallback((e: number) => {
    setCurrent(e);
  }, []);

  const onClickOverlay = () => {
    onClickStart();
  };

  const onClickButton = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    if (current === imageList.length - 1) {
      onClickStart();
    } else {
      nav?.slickNext();
    }
    e.stopPropagation();
  };

  return (
    <div className={styles.OrgOnBoardingWrap} onClick={onClickOverlay}>
      <div className={styles.OrgOnBoardingContent}>
        <Slider
          asNavFor={nav}
          ref={(slider: Slider) => setNav(slider)}
          {...settings}
        >
          {imageList.map(image => (
            <div
              className={styles.OrgOnBoardingContentItem}
              key={image}
              onClick={e => {
                e.stopPropagation();
              }}
            >
              <img src={image} alt="" />
            </div>
          ))}
        </Slider>
      </div>

      <ul className={styles.OrgOnBoardingNav}>
        {imageList.map((image, index) => (
          <li
            key={image}
            className={
              current === index
                ? styles.OrgOnBoardingNavItemAct
                : styles.OrgOnBoardingNavItem
            }
            onClick={e => {
              nav?.slickGoTo(index);
              e.stopPropagation();
            }}
          >
            <span />
          </li>
        ))}
      </ul>

      <ButtonRoundedCorners
        text={current === imageList.length - 1 ? 'さあ、はじめよう' : '次へ'}
        onClick={onClickButton}
        cssClass={styles.OrgOnBoardingButton}
      />

      <div className={styles.OrgOnBoardingBg} />
    </div>
  );
};

export default OrgOnBoarding;
