/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import * as styles from './McsNavBlogPrevNext.module.scss';

type Props = {
  prevLinkText?: string;
  topLinkText?: string;
  nextLinkText?: string;
  onClickPrevLink?: () => void;
  onClickTopLink?: () => void;
  onClickNextLink?: () => void;
  cssClass?: string;
};

const McsNavBlogPrevNext: FC<Props> = ({
  prevLinkText = '',
  topLinkText = '',
  nextLinkText = '',
  onClickPrevLink = () => undefined,
  onClickTopLink = () => undefined,
  onClickNextLink = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.McsNavBlogPrevNextBase, cssClass);

  return (
    <div className={classNames}>
      <div className={styles.McsNavBlogPrevNextLink} onClick={onClickPrevLink}>
        {prevLinkText}
      </div>
      <div className={styles.McsNavBlogPrevNextLink} onClick={onClickTopLink}>
        {topLinkText}
      </div>
      <div className={styles.McsNavBlogPrevNextLink} onClick={onClickNextLink}>
        {nextLinkText}
      </div>
    </div>
  );
};

export default McsNavBlogPrevNext;
