/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, takeLeading } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/reissuePasswordSendEmailConstants';
import { sendEmailEvent } from '../actions/reissuePasswordSendEmailActions';
import { SendEmailResponse } from '../interfaces/passwordInterFace';
import { Status, ErrorResponse } from '../interfaces/commonInterFace';

// send email
const sendEmailHandler = api.getPostFactory();
function* runSendEmail(
  handler: typeof sendEmailHandler,
  action: ReturnType<typeof sendEmailEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: SendEmailResponse = yield call(
      handler,
      data,
      '/password/email',
    );

    if (!ReturnData) {
      yield put(sendEmailEvent.fail(''));

      return;
    }

    yield put(sendEmailEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    if (res.status_code === Status.NotFound) {
      yield put(sendEmailEvent.fail(res.message));

      return;
    }

    if (res.status_code === Status.ServerError) {
      yield put(sendEmailEvent.fail(res.message));

      return;
    }

    yield put(sendEmailEvent.fail(''));
  }
}
export function* sendEmail(handler: typeof sendEmailHandler) {
  yield takeLeading(ActionType.SEND_EMAIL_START, runSendEmail, handler);
}

export const reissuePasswordSendEmailSagas = [
  fork(sendEmail, sendEmailHandler),
];
