/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import McsIconLabelSquareQuaternary from '../../molecules/iconWithLabel/McsIconLabelSquareQuaternary';
import IconArrow from '../../atoms/iconParts/IconArrow';

import {
  Fanclub,
  FanclubInitialValue,
} from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgFCListUnitPrimary.module.scss';

type PartialFanclub = Partial<Fanclub>;
interface Props {
  cssClass?: string;
  fanclub?: PartialFanclub;
  onClick?: () => void;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  fanclub = FanclubInitialValue,
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgFCListUnitBase, cssClass);

  return (
    <li className={classNames} key={fanclub.id} onClick={onClick}>
      <McsIconLabelSquareQuaternary
        src={fanclub.icon_image}
        mainText={fanclub.name}
        subText={fanclub.site_name}
      />
      <IconArrow />
    </li>
  );
};

export default OrgListUnit;
