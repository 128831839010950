/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-return */
/* eslint-disable no-unused-expressions */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  likeEvent,
  freejoinFanclubEvent,
  cleanup,
} from '../../actions/contentActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';

import { tweet, shareFB, shareLINE } from '../../utils/snsHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import OrgPhotosListModal from '../../components/common/organisms/modalParts/OrgPhotosListModal';
import Content from '../../components/artist/content';
import { PATH } from '../../utils/routerHelper';

const ArtistContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId, contentId } = useParams<{
    artistId?: string;
    contentId?: string;
  }>();
  const { ready: authReady } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const {
    ready,
    contentDetails,
    fanclubDetails,
    recentBlogs,
    recentPhotos,
    recommendFanclubs,
    loading,
    error,
    data,
  } = useSelector(selector.content.getContentState);

  const [photoModal, setPhotoModal] = useState(false);
  const [top, setTop] = useState(0);

  const isGuest = useMemo(() => fanclubDetails.user_type === 99, [
    fanclubDetails.user_type,
  ]);
  const isFreejoin = useMemo(() => fanclubDetails.user_type === 0, [
    fanclubDetails.user_type,
  ]);
  const isPaidFanclub = useMemo(
    () =>
      fanclubDetails.fanclub.paid_status === 2 &&
      !!fanclubDetails.is_account_registerd,
    [fanclubDetails.fanclub.paid_status, fanclubDetails.is_account_registerd],
  );
  // 写真のリスト
  const photos = useMemo(() => {
    if (!contentDetails.photos) return [];

    return Array.isArray(contentDetails.photos)
      ? contentDetails.photos.map(photo => photo.url)
      : [];
  }, [contentDetails]);
  const prevLinkText = useMemo(() => {
    if (!contentDetails.prev_id) return '';

    if (contentDetails.detail.type === 1) return '前の記事';

    if (contentDetails.detail.type === 2) return '前の写真';

    if (contentDetails.detail.type === 3) return '前の動画';

    if (contentDetails.detail.type === 4) return '前の音声';

    return '';
  }, [contentDetails.detail.type, contentDetails.prev_id]);
  const nextLinkText = useMemo(() => {
    if (!contentDetails.next_id) return '';

    if (contentDetails.detail.type === 1) return '次の記事';

    if (contentDetails.detail.type === 2) return '次の写真';

    if (contentDetails.detail.type === 3) return '次の動画';

    if (contentDetails.detail.type === 4) return '次の音声';

    return '';
  }, [contentDetails.detail.type, contentDetails.next_id]);

  const handleClickCreateUser = useCallback(() => {
    const userId = contentDetails.create_user?.name_id;

    if (userId) history.push(PATH.getUserPath(userId));
  }, [contentDetails.create_user, history]);
  const handleClickTwitter = useCallback(() => {
    tweet(window.document.title, window.location.href);
  }, []);
  const handleClickFacebook = useCallback(() => {
    shareFB(window.location.href);
  }, []);
  const handleClickLine = useCallback(() => {
    shareLINE(window.location.href);
  }, []);
  const handleClickComment = useCallback(() => {
    if (!isLogin) {
      history.push(PATH.SIGNUP);

      return;
    }

    const {
      fanclub: { group_id },
      id: content_id,
    } = contentDetails.detail;

    if (!group_id || !content_id) return;

    history.push(PATH.getCommentPath(group_id, content_id));
  }, [contentDetails.detail, history, isLogin]);

  const handleClickLike = useCallback(() => {
    if (!isLogin) {
      history.push(PATH.SIGNUP);

      return;
    }
    const {
      fanclub: { group_id },
      id: content_id,
    } = contentDetails.detail;

    if (!group_id || !content_id) return;

    history.push(PATH.getLikePath(group_id, content_id));
  }, [contentDetails.detail, history, isLogin]);

  const handleLike = useCallback(() => {
    if (!isLogin) {
      history.push(PATH.SIGNUP);

      return;
    }

    const type = contentDetails.is_like ? 'unlike' : 'like';

    dispatch(likeEvent.start({ type, content_id: Number(contentId) }));
  }, [contentDetails.is_like, contentId, dispatch, history, isLogin]);
  const handleClickPrevLink = useCallback(() => {
    if (!artistId || !contentDetails.prev_id) return;

    history.push(PATH.getContentPath(artistId, contentDetails.prev_id));
  }, [artistId, contentDetails.prev_id, history]);
  const handleClickTopLink = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getArtistPath(artistId));
  }, [artistId, history]);
  const handleClickNextLink = useCallback(() => {
    if (!artistId || !contentDetails.next_id) return;

    history.push(PATH.getContentPath(artistId, contentDetails.next_id));
  }, [artistId, contentDetails.next_id, history]);
  const handleClickContent = useCallback(
    (artistId: string, contentId: number) => {
      if (!artistId || !contentId) return;

      history.push(PATH.getContentPath(artistId, contentId));
    },
    [history],
  );
  const handleClickFanclub = useCallback(
    (artistId: string) => {
      if (!artistId) return;

      history.push(PATH.getArtistPath(artistId));
    },
    [history],
  );
  const handleClickModalButton = useCallback(() => {
    if (error.open_area === 0) return;

    if (!artistId) return;

    if (!isLogin) {
      history.push(PATH.SIGNUP);

      return;
    }

    if (error.open_area === 1) {
      history.push(PATH.getPaidjoinPath(artistId));
    }

    if (error.open_area === 2) {
      // フォロー

      dispatch(freejoinFanclubEvent.start({ artistId }, Number(contentId)));
    }

    if (error.open_area === 3) {
      history.push(PATH.SIGNUP);
    }
  }, [artistId, contentId, dispatch, error.open_area, history, isLogin]);

  const handleClickPhotoModalOpen = useCallback(() => {
    setTop(window.scrollY);
    document.body.style.overflow = 'hidden';
    setPhotoModal(true);
  }, []);

  const handleClickPhotoModalClose = useCallback(() => {
    document.body.style.overflow = 'auto';
    setPhotoModal(false);
    window.scrollTo(0, top);
  }, [top]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(() => {
    if (!artistId) return {};

    const canFreejoin = isGuest;
    const canPaidjoin = (isGuest || isFreejoin) && isPaidFanclub;

    const defaultOptions: Parameters<typeof useFooter>[0] = {
      pr: {
        right: isLogin
          ? {
              text: 'コミュニティを作る',
              onClick: () => history.push(PATH.OPEN_FC_AGREEMENT),
            }
          : {
              text: '無料ではじめる',
              onClick: () => history.push(PATH.SIGNUP),
            },
      },
      sns: true,
      nav: true,
      copyright: true,
    };

    if (canFreejoin)
      return {
        ...defaultOptions,
        fanclub: {
          fanclub: fanclubDetails.fanclub,
          text: 'フォローする',
          onClickFanclub: () => history.push(PATH.getArtistPath(artistId)),
          onClickButton: () =>
            dispatch(
              freejoinFanclubEvent.start({ artistId }, Number(contentId)),
            ),
        },
      };

    if (canPaidjoin)
      return {
        ...defaultOptions,
        fanclub: {
          fanclub: fanclubDetails.fanclub,
          text: 'コミュニティへ入会する',
          onClickFanclub: () => history.push(PATH.getArtistPath(artistId)),
          onClickButton: () => history.push(PATH.getPaidjoinPath(artistId)),
        },
      };

    return {
      ...defaultOptions,
      back: {
        onClick: () => history.push(PATH.getArtistPath(artistId)),
      },
    };
  }, [
    artistId,
    contentId,
    dispatch,
    fanclubDetails.fanclub,
    history,
    isFreejoin,
    isGuest,
    isLogin,
    isPaidFanclub,
  ]);
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!artistId) {
      history.replace(PATH.HOME);

      return;
    }

    dispatch(getReady(artistId, Number(contentId)));
  }, [artistId, authReady, contentId, dispatch, history, isLogin]);

  useEffect(() => {
    if (error.open_area === 0) return;

    if (!isLogin) {
      // history.replace(PATH.SIGNUP);
    }
  }, [error, history, isLogin]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>KRAP</title>
        ) : error.open_area ? (
          <title>{error.fanclub.name} | KRAP</title>
        ) : (
          <title>
            {contentDetails.detail.subject} |{' '}
            {contentDetails.detail.fanclub.name} | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />
      {photoModal && (
        <OrgPhotosListModal
          photos={photos}
          onClickModalClose={handleClickPhotoModalClose}
        />
      )}

      <Content
        details={contentDetails}
        recentPhotos={recentPhotos}
        blogs={recentBlogs}
        recommends={recommendFanclubs}
        users={data}
        photos={photos}
        onClickCreateUser={handleClickCreateUser}
        // sns
        onClickTwitter={handleClickTwitter}
        onClickFaceBook={handleClickFacebook}
        onClickLine={handleClickLine}
        // いいね・コメント
        onClickComment={handleClickComment}
        onLike={handleLike}
        onClickLike={handleClickLike}
        // link
        prevLinkText={prevLinkText}
        topLinkText="一覧へ"
        nextLinkText={nextLinkText}
        onClickPrevLink={handleClickPrevLink}
        onClickTopLink={handleClickTopLink}
        onClickNextLink={handleClickNextLink}
        onClickContent={handleClickContent}
        onClickFanclub={handleClickFanclub}
        // modal
        errorOpenArea={error.open_area}
        errorFanclub={error.fanclub}
        onClickModalButton={handleClickModalButton}
        onClickPhotoModalOpen={handleClickPhotoModalOpen}
      />
    </>
  );
};

export default ArtistContainer;
