export const GET_READY = 'ADMIN_COMMUNITY_EDIT/GET_READY' as const;
export const GOT_READY = 'ADMIN_COMMUNITY_EDIT/GOT_READY' as const;

export const GET_FANCLUB_DETAILS_START = 'ADMIN_COMMUNITY_EDIT/GET_FANCLUB_DETAILS_START' as const;
export const GET_FANCLUB_DETAILS_SUCCEED = 'ADMIN_COMMUNITY_EDIT/GET_FANCLUB_DETAILS_SUCCEED' as const;
export const GET_FANCLUB_DETAILS_FAIL = 'ADMIN_COMMUNITY_EDIT/GET_FANCLUB_DETAILS_FAIL' as const;

export const UPDATE_FANCLUB_START = 'ADMIN_COMMUNITY_EDIT/UPDATE_FANCLUB_START' as const;
export const UPDATE_FANCLUB_SUCCEED = 'ADMIN_COMMUNITY_EDIT/UPDATE_FANCLUB_SUCCEED' as const;
export const UPDATE_FANCLUB_FAIL = 'ADMIN_COMMUNITY_EDIT/UPDATE_FANCLUB_FAIL' as const;

export const CLEANUP = 'ADMIN_COMMUNITY_EDIT/CLEANUP' as const;
