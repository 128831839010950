/* eslint-disable no-shadow */
import React, { FC } from 'react';
import {
  StripeCardNumberElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
} from '@stripe/stripe-js';
import { StripeInputErrors } from '../../interfaces/stripeInterFace';
import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../../interfaces/fanclubInterFace';
import { Card } from '../../interfaces/cardInterFace';

import TitleH1 from '../common/atoms/title/TitleH1';
import CheckBox from '../common/atoms/formParts/CheckBox';
import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';
import ButtonSquare from '../common/atoms/buttonBasic/ButtonSquare';

import McsTextBoxScroll from '../common/molecules/formParts/McsTextBoxScroll';

import OrgProductDetails from '../common/organisms/paidjoin/OrgProductDetails';
import OrgCanUseCardInfo from '../common/organisms/paidjoin/OrgCanUseCardInfo';
import OrgCardInput from '../common/organisms/paidjoin/OrgCardInput';
import OrgCardInfo from '../common/organisms/paidjoin/OrgCardInfo';

import styles from './paidjoinRegister.module.scss';

type Props = {
  details?: FanclubDetails;
  card?: Card | null;
  name?: string;
  agreed?: boolean;
  canPaidjoin?: boolean;

  onChangeCardInfo?: (
    e:
      | StripeCardNumberElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | StripeCardExpiryElementChangeEvent,
  ) => void;
  onChangeName?: (e: React.FormEvent<HTMLInputElement>) => void;
  onLinkToChangeCreditCard?: () => void;
  onToggleAgreed?: () => void;
  onSubmit?: () => void;
  cardErrors?: StripeInputErrors;
};

const DESCRIPTION = `前文
本利用規約（以下「本規約」といいます。）には、KRAPサービスの提供条件及び株式会社ナノ・メディア（以下「当社」といいます。）とユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。

第1条（適用）
1. 本規約は、本サービスの提供条件及び本サービスの利用に関する当社とユーザーとの間の権利義務関係を定めることを目的とし、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。
2. 当社が本サイト上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
3. 本規約の内容と、前項のルール、諸規定等その他本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。

第2条（定義）
本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
(1) 「サービス利用契約」とは、本規約を契約条件として当社とユーザーの間で締結される、本サービスの利用契約を意味します。
(2) 「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
(3) 「投稿データ」とは、ユーザーが本サービスを利用して投稿その他送信するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。
(4) 「当社ウェブサイト」とは、そのドメインが「krap.me」である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。
(5) 「ユーザー」とは、第3条（登録）に基づいて本サービスの利用者としての登録がなされた個人または法人を意味します。
(6)「コミュニティ」とは、ユーザーがアーティスト活動のために、本サービス内に作成するコンテンツを投稿・公開できるページを意味します。
(7)「有料コミュニティ」とは、月額課金したユーザーのみが閲覧できるコミュニティを意味します。
(8) 「フォロワー」とは、本規約を承認の上、本サービスの利用のために、1つ又は複数のコミュニティーページでフォロワー登録を行った者のことをいいます。
(9) 「有料フォロワー」とは、本規約を承認の上、本サービスの利用のために、1つ又は複数のコミュニティーページで月額課金した者のことをいいます。
(10) 「アーティスト」とは、本サービスにおいて自己の専用のコミュニティーページを組成し、運営する個人又は法人を意味します。
(11) 「有料アーティスト」とは、本サービスにおいて自己の専用のコミュニティーページを組成し、有料フォロワーに対し有料で運営する個人又は法人を意味します。
(12) 「本サービス」とは、当社が提供するKRAPという名称のサービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。

第3条（登録）
1. 本サービスの利用を希望する者（以下「登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
2. 当社は、当社の基準に従って、第１項に基づいて登録申請を行った登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者の登録ユーザーとしての登録は、当社が本項の通知を行ったことをもって完了したものとします。
3. 前項に定める登録の完了時に、サービス利用契約が登録ユーザーと当社の間に成立し、登録ユーザーは本サービスを本規約に従い利用することができるようになります。
4. 当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
(1) 当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
(2) 未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
(3) 反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
(4) 過去当社との契約に違反した者またはその関係者であると当社が判断した場合
(5) 第10条に定める措置を受けたことがある場合
(6) その他、登録を適当でないと当社が判断した場合

第4条（登録事項の変更）
ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。

第5条（パスワード及びユーザーIDの管理）
1. ユーザーは、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
2. パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はユーザーが負うものとします。

第6条（料金及び支払方法）
1. 有料コミュニティへの加入及び有料コンテンツへのアクセスには利用料のお支払が必要となります。有料フォロワーは、設定を確認いただくことで、いつ何時にても、本サービスへの加入状況に関する詳細をご覧いただけます。有料フォロワーは、当人のサービスアカウントについて個人利用のみを目的とするものにご同意いただくものとします。本規約において、｢1日｣は、日本標準時の午前零時に始まり、同じ暦日の日本標準時の午後11時59分に終わるものとします。
2. 料金請求について
(1) 有料コミュニティへの加入手続が完了した時点をもって、有料コミュニティに関する利用契約が成立するものとします。有料フォロワーは、有料コミュニティへの加入をお申込みいただくことにより、有料コミュニティの有料フォロワーとしての月額利用料及びこれに適用される各種税金を、登録手続において有料フォロワーから申し出があり、当社によって承諾されたクレジットカードその他の継続的な課金が可能な支払方法(以下｢本支払方法｣といいます。)により、当社が有料フォロワーに対して請求する権限が付与されることに明示的にご同意いただいたことになります。また、有料コミュニティの有料フォロワーとしての月額利用料のほか、追加サービス、その他の料金及びこれに適用される各種税金（月額利用料及びこれに適用される各種税金と合わせて、以下「月額利用料等」といいます。）についても同様とします。
(2) 本支払方法の利用にあたって、有料フォロワーは、ご指定のクレジットカードの発行会社の規約その他の本支払方法に関連するサービス規約の定めるところに従うことに加え、以下の事項をあらかじめ了承するものとします。
・ 有料コミュニティから退出を行わない限り、当社に対し本支払方法にて継続して月額利用料等の支払を行うこと。
・ 当初登録した本支払方法の内容（クレジットカードの番号・有効期限等）に変更があった場合、又は別の支払方法へ変更する場合、設定をクリックし、遅滞なくその旨を登録すること。
・ 上記に記載するとおりの変更の登録を怠ったとしても、当初登録したカード番号・有効期限が更新されたり、クレジットカードが再発行されたりしたときは、クレジットカードの発行会社が把握する更新後又は再発行後の本支払方法により月額利用料等の支払を行うこと。
・ ご指定のクレジットカードの発行会社が月額利用料等の支払を行わない場合があること。
本利用規約において、｢料金請求｣とは、有料フォロワーの本支払方法に対して請求又は引き落としをさせていただくことを意味します。利用料は、有料コミュニティへの加入時点から発生するものとし、それ以後は、有料フォロワーが有料コミュニティへの加入をキャンセルし、有料コミュニティから退出し、フォロワー資格を喪失し、又はアカウント若しくは本サービスが別途本規約に従って停止若しくは中止されない限り、月次の更新の際に料金請求させていただきます。
(3) 当社は、毎月、有料フォロワーの加入開始に応当する暦日（但し、加入開始日が31日であるなど、当該応当日が特定の月に含まれないときは、当該月の末日とします。）に、当該月の有料フォロワーによる利用状況に応じて、有料フォロワーの本支払方法に対して自動的に請求いたします。但し、有料フォロワーが、有料フォロワーの本支払方法を変更した場合、かかる変更の結果、有料フォロワーへの請求日が変更されることがあります。有料フォロワーは、販売促進用割引、有料フォロワーの加入プランの変更及び適用される税金の変更により、請求金額が毎月異なることがあること、並びに、かかる金額を有料フォロワーの本支払方法に請求することにつき、予め承諾するものとします。翌月の料金請求を防ぐには、有料フォロワーは、月次の更新がなされる前に有料コミュニティから退出する必要があります。当社は、月額利用料等を、有料フォロワーが登録手続に際してご登録の本支払方法(又は、有料フォロワーが有料フォロワーのアカウント情報を変更した場合には、かかる別の本支払方法)に対して請求いたします。有料フォロワーが有料コミュニティを退出した場合は、月額利用料等の返金はいたしません。また、当該月の残りの期間中であっても有料コミュニティのページにアクセスすることはできません。
(4) すべての料金及び手数料は返金できません。当社は、本サービスが技術的問題により14日以上の期間にわたり利用できなかった場合等、当社が適当であると判断した場合には)、当該不使用による影響を受けた加入者に対して優待、特典等（以下「優待等」といいます。）を提供することがあります。かかる優待等の金額及び形式、並びにそれらの提供の決定は、当社の単独かつ絶対的裁量によるものであり、ある場合に優待等の提供があったとしても、将来、同様又は異なる状況において優待等を受ける権利が付与されるというわけではありません。
3. 未払金額について
当社は、本サービスに関して有料フォロワーにお支払いただけなかった金額を回収する権利を保持するものとします。有料フォロワーは支払義務のあるすべての金額、及び当該金額の回収に関連して当社に発生したすべての費用(回収代行業者の手数料、合理的範囲の弁護士報酬及び仲裁若しくは訴訟の費用を含みますがこれらに限定されません)に関して当社に責任を負うこととなります。

第7条（禁止事項）
ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。
(1) 法令に違反する行為または犯罪行為に関連する行為
(2) 当社、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為
(3) 公序良俗に反する行為
(4) 当社、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
(5) 本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの他の利用者に送信すること
・ 過度に暴力的または残虐な表現を含む情報
・ コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報
・ 当社、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報
・ 過度にわいせつな表現を含む情報
・ 差別を助長する表現を含む情報
・ 自殺、自傷行為を助長する表現を含む情報
・ 薬物の不適切な利用を助長する表現を含む情報
・ 反社会的な表現を含む情報
・ チェーンメール等の第三者への情報の拡散を求める情報
・ 他人に不快感を与える表現を含む情報
(6) 本サービスのネットワークまたはシステム等に過度な負荷をかける行為
(7) 当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為
(8) 本サービスの運営を妨害するおそれのある行為
(9) 当社のネットワークまたはシステム等への不正アクセス
(10) 第三者に成りすます行為
(11) 本サービスの他のユーザーのIDまたはパスワードを利用する行為
(12) 当社が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為
(13) 本サービスの他の利用者の情報の収集
(14) 当社、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為
(15) 当社ウェブサイト上で掲載する本サービス利用に関するルール（https://krap.me/rule/regulation/）に抵触する行為
(16) 反社会的勢力等への利益供与
(17) 面識のない異性との出会いを目的とした行為
(18) 当社が個別に承諾する場合を除き、本サービス上のコンテンツを複製、販売、出版、貸与、公衆送信、改変、翻案等を行なうこと（但し、アーティストがコミュニティにおいて投稿する情報であって、アーティストがこれらの行為を明示的に承諾する場合を除きます。）、及びコンテンツに付加された技術的保護措置を解除、回避等すること
(19) IDを複数名で共有すること
(20) 本サービスを通じて金銭の代理弁済や代理受領、資金決済にかかわる行為をすること
(21) 本サービスにおいて明示的に許諾された方法以外の方法により、自己又は他のユーザーの個人情報（本名、住所、電話番号、電子メールアドレスその他の連絡先を含みます。）を掲載等すること
(22) 現金取引行為（リアルマネートレード）又は、ユーザーID及びアーティストID、本コンテンツ内のデータ等を現金に換金する行為やそれらを媒介とした物品や情報等の売買行為、又はそれに準じた行為若しくはそれを誘発、勧誘する発言をすること
(23) 前各号の行為を直接または間接に惹起し、または容易にする行為
(24) 前各号の行為を試みること
(25) その他、当社が不適切と判断する行為

第8条（本サービスの停止等）
当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。
(1) 本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合
(2) コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合
(3) 地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
(4) その他、当社が停止または中断を必要と判断した場合

第9条（権利帰属）
1. 当社ウェブサイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
2. ユーザーは、投稿データについて、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。
3. ユーザーは、投稿データについて、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、登録したコミュニティを管理しているアーティストに対しても、本サービスを利用してユーザーが投稿その他送信した投稿データの使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。
4. ユーザーは、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。

第10条（登録抹消等）
1. 当社は、ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、投稿データを削除もしくは非表示にし、当該ユーザーについて本サービスの利用を一時的に停止し、またはユーザーとしての登録を抹消することができます。
(1) 本規約のいずれかの条項に違反した場合
(2) 登録事項に虚偽の事実があることが判明した場合
(3) 支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
(4) 【6】ヶ月以上本サービスの利用がない場合
(5) 当社からの問い合わせその他の回答を求める連絡に対して【30日】間以上応答がない場合
(6) 第3条第4項各号に該当する場合
(7) その他、当社が本サービスの利用またはユーザーとしての登録の継続を適当でないと判断した場合
2. 前項各号のいずれかの事由に該当した場合、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。

第11条（退会）
1. ユーザーは、当社所定の手続の完了により、本サービスから退会し、本サービスのユーザーとしての登録を抹消することができます。
2. 退会にあたり、当社に対して負っている債務が有る場合は、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
3. 退会後の利用者情報の取扱いについては、第15条の規定に従うものとします。
4. 複数コミュニティに登録した有料フォロワーは、各コミュニティを個別に退会し、すべてのコミュニティを退会完了後にユーザーとして退会可能となります。

第12条（本サービスの内容の変更、終了）
1. 当社は、当社の都合により、本サービスの内容を変更し、または提供を終了することができます。
2. 当社が本サービスの提供を終了する場合、当社はユーザーに事前に通知するものとします。

第13条（保証の否認及び免責）
1. 当社は、本サービスがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、ユーザーによる本サービスの利用がユーザーに適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。
2. 当社は、本サービスに関してユーザーが被った損害につき、過去【12ヶ月】間にユーザーが当社に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。
3. 本サービスまたは当社ウェブサイトに関連してユーザーと他のユーザーまたは第三者との間において生じた取引、連絡、紛争等については、ユーザーが自己の責任によって解決するものとします。
4. ユーザーは、本規約に違反した場合、故意過失を問わず、当該違反により損害を被った当社、アーティスト、他のユーザーその他の第三者に対し、当該損害を賠償する責任を負います。

第14条（秘密保持）
ユーザーは、本サービスに関連して当社がユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。

第15条（利用者情報の取扱い）
1. 当社によるユーザーの利用者情報の取扱いについては、別途当社プライバシーポリシー（https://krap.me/privacypolicy）の定めによるものとし、ユーザーはこのプライバシーポリシーに従って当社がユーザーの利用者情報を取扱うことについて同意するものとします。
2. 当社は、ユーザーが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、ユーザーはこれに異議を唱えないものとします。
3. 当社は、ユーザー記述情報を本サービスの提供及び利用促進に必要な範囲において、無償で、当社サーバーへの複製、フォロワー及びアーティストに対する公衆送信その他の方法により利用することができるものとします。ただし、裁判所等の公的機関により法令又は令状等に基づき開示請求がなされる場合及び当社の業務上必要とされる場合等正当な理由に基づく場合を除き、フォロワー間又はアーティストとフォロワー間においてやりとりされる情報のうち、閲覧できるフォロワー又はアーティストが特定された情報を、当社、第三者（当該フォロワー又は当該アーティストが閲覧を許可した第三者を除きます。）が閲覧することはありません。なお、ユーザー記述情報に含まれる著作権は当社に譲渡されるものではありません。

第16条（本規約等の変更）
当社は、当社が必要と認めた場合は、本規約を変更できるものとします。本規約を変更する場合、変更後の本規約の施行時期及び内容を当社ウェブサイト上での掲示その他の適切な方法により周知し、またはユーザーに通知します。但し、法令上ユーザーの同意が必要となるような内容の変更の場合は、当社所定の方法でユーザーの同意を得るものとします。

第17条（連絡／通知）
1. 本サービスに関する問い合わせその他ユーザーから当社に対する連絡または通知、及び本規約の変更に関する通知その他当社からユーザーに対する連絡または通知は、当社の定める方法で行うものとします。
2. 当社が登録事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、ユーザーは当該連絡または通知を受領したものとみなします。

第18条（サービス利用契約上の地位の譲渡等）
1. ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
2. 当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びにユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。

第19条（分離可能性）
本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。

第20条（準拠法及び管轄裁判所）
1. 本規約及びサービス利用契約の準拠法は日本法とします。
2. 本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。`;

const PaidJoin: FC<Props> = ({
  details = FanclubDetailsInitialValue,
  card = null,
  name = '',
  agreed = false,
  canPaidjoin = false,

  onChangeCardInfo = () => undefined,
  onChangeName = () => undefined,
  onLinkToChangeCreditCard = () => undefined,
  onToggleAgreed = () => undefined,
  onSubmit = () => undefined,
  cardErrors = {},
}) => {
  return (
    <div className={styles.container}>
      <TitleH1 text="コミュニティ入会" cssClass={styles.title} />
      <p className={styles.read}>以下の内容でコミュニティへ入会します。</p>

      <form>
        <OrgProductDetails
          fanclub={details.fanclub}
          cssClass={styles.product}
          paymentTagText="決済"
          paymentBodyText="クレジットカード"
        />

        {card ? (
          <>
            <p
              className={styles.readMini}
            >{`以下のご登録済クレジットカードで\n決済登録いたします。`}</p>

            <OrgCardInfo card={card} />

            <ButtonSquare
              text="クレジットカード変更"
              type="Quaternary"
              size="Secondary"
              onClick={onLinkToChangeCreditCard}
              cssClass={styles.editCreditCardButton}
            />
          </>
        ) : (
          <>
            <OrgCanUseCardInfo cssClass={styles.cardInfo} />

            <OrgCardInput
              name={name}
              onChangeCardInfo={onChangeCardInfo}
              onChangeName={onChangeName}
              errors={cardErrors}
            />
          </>
        )}

        <McsTextBoxScroll
          value={DESCRIPTION}
          labelTitle="利用規約"
          readonly
          cssClass={styles.description}
        />

        <CheckBox
          labelText="利用規約に同意します"
          checked={agreed}
          onChange={onToggleAgreed}
          cssClass={styles.checkbox}
        />

        <ButtonRoundedCorners
          text="コミュニティへ入会する"
          type={canPaidjoin ? 'Primary' : 'Quaternary'}
          size="Primary"
          onClick={onSubmit}
          cssClass={styles.button}
        />
      </form>
    </div>
  );
};

export default PaidJoin;
