/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, takeLeading } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/attachGenresConstants';
import { checkAndSetStatusError } from './authSagas';
import { attachGenresEvent } from '../actions/attachGenresActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import { AttachGenresResponse } from '../interfaces/genreInterFace';

// attach genres
const attachGenresHandler = api.getPostFactory(true);
function* runAttachGenres(
  handler: typeof attachGenresHandler,
  action: ReturnType<typeof attachGenresEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: AttachGenresResponse = yield call(
      handler,
      data,
      '/user/attach_genres',
    );

    if (!ReturnData) {
      yield put(attachGenresEvent.fail());

      return;
    }

    yield put(attachGenresEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(attachGenresEvent.fail());
  }
}
export function* attachGenres(handler: typeof attachGenresHandler) {
  yield takeLeading(ActionType.ATTACH_GENRES_START, runAttachGenres, handler);
}

export const attachGenresSagas = [fork(attachGenres, attachGenresHandler)];
