import * as ActionType from './adminPhotoRegisterConstants';
import {
  GetFanclubDetailsParams,
  GetFanclubDetailsResult,
} from '../interfaces/fanclubInterFace';
import {
  CreatePhotoParams,
  CreatePhotoResult,
  CreatePhotoErrors,
  GetContentDetailsParams,
  GetContentDetailsResult,
  UpdatePhotoParams,
  UpdatePhotoResult,
  UpdatePhotoErrors,
  DeletePhotoParams,
} from '../interfaces/contentInterFace';
import {
  AddFCCategoryErrors,
  AddFCCategoryParams,
  AddFCCategoryResult,
  GetFCCategoryListParams,
  GetFCCategoryListResult,
} from '../interfaces/categoryInterFace';

export const getReady = (artistId: string, contentId: number) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId, contentId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getFanclubDetailsEvent = {
  start: (params: GetFanclubDetailsParams) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetFanclubDetailsResult) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_FAIL,
    } as const),
};

export const getPhotoEvent = {
  start: (params: GetContentDetailsParams) =>
    ({
      type: ActionType.GET_PHOTO_START,
      payload: params,
    } as const),

  succeed: (result: GetContentDetailsResult) =>
    ({
      type: ActionType.GET_PHOTO_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_PHOTO_FAIL,
    } as const),
};

export const getFCCategoryListEvent = {
  start: (params: GetFCCategoryListParams) =>
    ({
      type: ActionType.GET_FC_CATEGORY_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetFCCategoryListResult) =>
    ({
      type: ActionType.GET_FC_CATEGORY_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FC_CATEGORY_LIST_FAIL,
    } as const),
};

export const addFCCategoryEvent = {
  start: (params: AddFCCategoryParams) =>
    ({
      type: ActionType.ADD_FC_CATEGORY_START,
      payload: params,
    } as const),

  succeed: (params: AddFCCategoryParams, result: AddFCCategoryResult) =>
    ({
      type: ActionType.ADD_FC_CATEGORY_SUCCEED,
      payload: { params, result },
    } as const),

  fail: (errors: AddFCCategoryErrors) =>
    ({
      type: ActionType.ADD_FC_CATEGORY_FAIL,
      payload: { errors },
    } as const),
};

export const createPhotoEvent = {
  start: (params: CreatePhotoParams) =>
    ({
      type: ActionType.CREATE_PHOTO_START,
      payload: params,
    } as const),

  succeed: (result: CreatePhotoResult) =>
    ({
      type: ActionType.CREATE_PHOTO_SUCCEED,
      payload: { result },
    } as const),

  fail: (errors: CreatePhotoErrors) =>
    ({
      type: ActionType.CREATE_PHOTO_FAIL,
      payload: { errors },
    } as const),
};

export const updatePhotoEvent = {
  start: (params: UpdatePhotoParams) =>
    ({
      type: ActionType.UPDATE_PHOTO_START,
      payload: params,
    } as const),

  succeed: (result: UpdatePhotoResult) =>
    ({
      type: ActionType.UPDATE_PHOTO_SUCCEED,
      payload: { result },
    } as const),

  fail: (errors: UpdatePhotoErrors) =>
    ({
      type: ActionType.UPDATE_PHOTO_FAIL,
      payload: { errors },
    } as const),
};

export const deletePhotoEvent = {
  start: (params: DeletePhotoParams) =>
    ({
      type: ActionType.DELETE_PHOTO_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.DELETE_PHOTO_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.DELETE_PHOTO_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fanclub details
  | ReturnType<typeof getFanclubDetailsEvent.start>
  | ReturnType<typeof getFanclubDetailsEvent.succeed>
  | ReturnType<typeof getFanclubDetailsEvent.fail>
  // get photo event
  | ReturnType<typeof getPhotoEvent.start>
  | ReturnType<typeof getPhotoEvent.succeed>
  | ReturnType<typeof getPhotoEvent.fail>
  // get fc category list
  | ReturnType<typeof getFCCategoryListEvent.start>
  | ReturnType<typeof getFCCategoryListEvent.succeed>
  | ReturnType<typeof getFCCategoryListEvent.fail>
  // add fc category
  | ReturnType<typeof addFCCategoryEvent.start>
  | ReturnType<typeof addFCCategoryEvent.succeed>
  | ReturnType<typeof addFCCategoryEvent.fail>
  // create photo
  | ReturnType<typeof createPhotoEvent.start>
  | ReturnType<typeof createPhotoEvent.succeed>
  | ReturnType<typeof createPhotoEvent.fail>
  // update photo
  | ReturnType<typeof updatePhotoEvent.start>
  | ReturnType<typeof updatePhotoEvent.succeed>
  | ReturnType<typeof updatePhotoEvent.fail>
  // delete photo
  | ReturnType<typeof deletePhotoEvent.start>
  | ReturnType<typeof deletePhotoEvent.succeed>
  | ReturnType<typeof deletePhotoEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
