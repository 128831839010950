/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './TitleH4.module.scss';

interface Props {
  text: string;
  type?: string;
  cssClass?: string;
  onClick?: () => void;
}

const Title: FC<Props> = ({
  text = 'Title',
  type = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.TitleBase, cssClass, {
    [styles.TitlePrimary]: type === 'Primary', // Primary
    [styles.TitleSecondary]: type === 'Secondary', // Secondary
  });

  return (
    <h4 className={classNames} onClick={onClick}>
      <span>{text}</span>
    </h4>
  );
};

export default Title;
