/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import Tab from '../common/atoms/tab/Tab';
import LabelTag from '../common/atoms/label/LabelTag';

import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';

import OrgSalesAndTransfer from '../common/organisms/admin/OrgSalesAndTransfer';
import OrgPool from '../common/organisms/admin/OrgPool';
import OrgHistory from '../common/organisms/admin/OrgHistory';

import * as styles from './adminSales.module.scss';
import { AmountList, TabMode } from '../../interfaces/salesInterFace';

type Props = {
  fanclubName?: string;
  tabMode?: TabMode;
  handleChangeTab?: (v: TabMode) => void;
  handleBack?: () => void;
  label?: string;
  amount?: number;
  description?: string;
  summaryTitle?: string;
  transferWaiting?: number;
  summaryPrice?: number;
  list?: AmountList[];
};

const Component: FC<Props> = ({
  fanclubName = '',
  tabMode = 'SALES',
  handleChangeTab = () => undefined,
  handleBack = () => undefined,
  label = '',
  amount = 0,
  description = '',
  summaryTitle = '',
  transferWaiting = 0,
  summaryPrice = 0,
  list = [],
}) => {
  const classNames = ClassNames(styles.container);

  return (
    <div className={classNames}>
      <McsTitleSecondary
        mainText="売上情報"
        subText={fanclubName}
        onClick={handleBack}
        cssClass={styles.title}
      />

      <div className={styles.tabMenuWrap}>
        <Tab
          text="売上情報"
          cssClass={styles.tabMenuItem}
          type={tabMode === 'SALES' ? 'Quinary' : 'Quaternary'}
          onClick={() => {
            handleChangeTab('SALES');
          }}
        />
        <Tab
          text="振込情報"
          cssClass={styles.tabMenuItem}
          type={tabMode === 'TRANSFER' ? 'Quinary' : 'Quaternary'}
          onClick={() => {
            handleChangeTab('TRANSFER');
          }}
        />
      </div>

      <div className={styles.body}>
        <OrgSalesAndTransfer
          label={label}
          price={amount}
          description={description}
        />

        {tabMode === 'TRANSFER' ? (
          <OrgPool cssClass={styles.pool} price={transferWaiting} />
        ) : null}
      </div>

      {tabMode === 'SALES' ? (
        <LabelTag
          text="確定分"
          type="Secondary"
          cssClass={styles.historyLabel}
        />
      ) : null}
      <OrgHistory
        summaryTitle={summaryTitle}
        summaryPrice={summaryPrice}
        list={list}
        cssClass={styles.history}
      />
    </div>
  );
};

export default Component;
