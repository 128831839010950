/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  getContentListEvent,
  freejoinFanclubEvent,
  withdrawFanclubEvent,
  cleanup,
} from '../../actions/artistActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { disableScroll, enableScroll } from '../../utils/scrollHelper';
import { PATH } from '../../utils/routerHelper';

import { ContentListType } from '../../interfaces/contentInterFace';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import Artist from '../../components/artist/artist';

const ArtistContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const [tabMode, setTabMode] = useState<ContentListType>(0);
  const [isViewModal, setViewModal] = useState(false);
  const { prefectures } = useSelector(selector.util.getSelectFields);
  const headerMode = useSelector(selector.util.getHeaderMode);
  const { ready: authReady, profile } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const {
    ready,
    loading,
    details,
    contents,
    recommendFanclubs: recommends,
    unreadChatCount,
  } = useSelector(selector.artist.getArtistState);
  const isLastPage = useSelector(selector.artist.getIsLastPage);
  const [follows, setFollows] = useState<number[]>([]);

  // フォローFCのIDリスト作成
  useEffect(() => {
    const fanClubs: number[] = [];
    profile.fanclubs.follows.map(fanClub => {
      fanClubs.push(fanClub.id);

      return null;
    });

    setFollows(fanClubs);
  }, [profile.fanclubs]);

  const prefecture = useMemo(
    () =>
      prefectures.find(p => p.value === details.fanclub.prefecture)?.label ||
      '',
    [details.fanclub.prefecture, prefectures],
  );

  const isGuest = useMemo(() => details.user_type === 99, [details.user_type]);
  const isFreejoin = useMemo(() => follows.includes(details.fanclub.id), [
    follows,
    details.fanclub.id,
  ]);
  const isPaidjoin = useMemo(() => details.user_type === 1, [
    details.user_type,
  ]);
  const isFollowed = useMemo(() => isFreejoin || isPaidjoin, [
    isFreejoin,
    isPaidjoin,
  ]);
  const isManaged = useMemo(
    () => details.user_type === 2 || details.user_type === 3,
    [details.user_type],
  );
  const isNotPaidJoin = useMemo(() => isLogin && !isPaidjoin && !isManaged, [
    isLogin,
    isManaged,
    isPaidjoin,
  ]);
  const isPaidFanclub = useMemo(
    () => details.fanclub.paid_status === 2 && !!details.is_account_registerd,
    [details.fanclub.paid_status, details.is_account_registerd],
  );
  const chatAvailable = useMemo(
    () => isPaidFanclub && (isPaidjoin || isManaged),
    [isPaidFanclub, isPaidjoin, isManaged],
  );

  const handleClickLogin = useCallback(() => {
    history.push(PATH.SIGNUP);
  }, [history]);
  const handleStartForFree = useCallback(() => {
    history.push(PATH.SIGNUP);
  }, [history]);
  const handleFollowClick = useCallback(() => {
    if (details.user_type === null) {
      history.push(PATH.SIGNUP);
    }
    if (!artistId || isPaidjoin) return;

    if (isFreejoin) {
      const followList = follows.filter(item => item !== details.fanclub.id);
      setFollows(followList);
      dispatch(withdrawFanclubEvent.start({ artistId }));
    } else {
      setFollows([...follows, details.fanclub.id]);
      dispatch(freejoinFanclubEvent.start({ artistId }));
    }
  }, [
    details.user_type,
    dispatch,
    artistId,
    history,
    isPaidjoin,
    isFreejoin,
    follows,
    details.fanclub.id,
  ]);
  const handleClickMemberList = useCallback(() => {
    setViewModal(true);
    disableScroll();
  }, []);
  const handleClickFollowersList = useCallback(() => {
    if (!artistId) return;
    history.push(PATH.getFollowerPath(artistId));
  }, [artistId, history]);

  const handleClickUser = useCallback(
    (userId: string) => {
      if (!userId) return;

      history.push(PATH.getUserPath(userId));
    },
    [history],
  );
  const handleClickBlur = useCallback(() => {
    setViewModal(false);
    enableScroll();
  }, []);
  const handleClickPaidJoin = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getPaidjoinPath(artistId));
  }, [artistId, history]);
  const handleChangeTab = useCallback((type: ContentListType) => {
    setTabMode(type);
  }, []);
  const handleClickContent = useCallback(
    (artistId: string, contentId: number) => {
      if (!artistId || !contentId) return;

      const nextPath = PATH.getContentPath(artistId, contentId);

      // if (!isLogin) {
      //   history.push(PATH.SIGNUP, {
      //     referer: nextPath,
      //     SIGNUP: {
      //       from: 'contents',
      //     },
      //   });

      //   return;
      // }

      history.push(nextPath);
    },
    [history],
  );
  const handleReadMore = useCallback(() => {
    dispatch(
      getContentListEvent.start({
        type: tabMode,
        group_id: artistId,
        page: contents.pager.current_page + 1,
      }),
    );
  }, [contents.pager.current_page, dispatch, artistId, tabMode]);
  const handleClickFanclub = useCallback(
    (artistId: string) => {
      if (!artistId) return;

      history.push(PATH.getArtistPath(artistId));
    },
    [history],
  );

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(() => {
    const canLogin = !isLogin;
    const canFreejoin = isGuest;
    const canPaidjoin = !chatAvailable && isFreejoin && isPaidFanclub;

    const defaultOptions: Parameters<typeof useFooter>[0] = {
      pr: {
        right: {
          text: 'コミュニティを作る',
          onClick: () => history.push(PATH.OPEN_FC_AGREEMENT),
        },
      },
      sns: true,
      nav: true,
      copyright: true,
    };

    if (canLogin) {
      return {
        ...defaultOptions,
        button: {
          text: '無料ではじめる',
          onClick: () => history.push(PATH.SIGNUP),
        },
      };
    }

    if (canFreejoin) {
      return {
        ...defaultOptions,
        button: {
          text: 'フォローする',
          onClick: () =>
            artistId
              ? dispatch(freejoinFanclubEvent.start({ artistId }))
              : undefined,
        },
      };
    }

    if (canPaidjoin) {
      return {
        ...defaultOptions,
        button: {
          text: 'コミュニティへ入会する',
          onClick: () =>
            artistId ? history.push(PATH.getPaidjoinPath(artistId)) : undefined,
        },
      };
    }

    if (chatAvailable) {
      return {
        ...defaultOptions,
        paidFC: {
          timeline: {
            state: true,
          },
          chat: {
            state: false,
            onClick: () =>
              artistId ? history.push(PATH.getFCChatPath(artistId)) : undefined,
          },
          unreadChat: unreadChatCount,
        },
      };
    }

    return defaultOptions;
  }, [
    dispatch,
    artistId,
    history,
    isFreejoin,
    isGuest,
    isPaidFanclub,
    isLogin,
    chatAvailable,
    unreadChatCount,
  ]);
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch, artistId, history]);

  useEffect(() => {
    if (!authReady) return;

    if (!artistId) {
      history.replace(PATH.HOME);

      return;
    }

    dispatch(getReady({ artistId, chatAvailable }));
  }, [authReady, dispatch, artistId, history, chatAvailable]);

  useEffect(() => {
    if (!authReady || !ready) return;

    dispatch(
      getContentListEvent.start({
        reset: true,
        group_id: artistId,
        type: tabMode,
        limit: tabMode === 2 ? 30 : 10,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, artistId, tabMode]);

  useEffect(() => {
    setViewModal(false);
  }, [headerMode]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>KRAP</title>
        ) : (
          <title>{details.fanclub.name} | KRAP</title>
        )}
      </Helmet>

      <OrgLoader isLoaded={authReady && ready} />

      <Artist
        // loading
        isLoading={loading}
        // optional header
        isLogin={isLogin}
        onClickLogin={handleClickLogin}
        onClickStartForFree={handleStartForFree}
        // artist
        details={details}
        prefecture={prefecture}
        isFollow={isFollowed}
        isPaid={isPaidjoin}
        isManage={isManaged}
        onFollowClick={handleFollowClick}
        isViewModalMemberList={isViewModal}
        onClickMemberList={handleClickMemberList}
        onClickFollowersList={handleClickFollowersList}
        onClickUser={handleClickUser}
        onClickBlur={handleClickBlur}
        isViewPaidJoin={isNotPaidJoin && isPaidFanclub}
        onClickPaidJoin={handleClickPaidJoin}
        // contents
        type={tabMode}
        contents={contents.list}
        onChangeTab={handleChangeTab}
        isViewLikeCount
        handleClickContent={handleClickContent}
        isViewReadMoreButton={!isLastPage}
        onReadMore={handleReadMore}
        // recommends
        isViewRecommends={isNotPaidJoin}
        recommends={recommends}
        onClickFanclub={handleClickFanclub}
      />
    </>
  );
};

export default ArtistContainer;
