/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react';

import ButtonFullWidth from '../common/atoms/buttonBasic/ButtonFullWidth';

import { Notification } from '../../interfaces/newsInterFace';

import * as styles from './news.module.scss';
import OrgNewsUnit from '../common/organisms/listUnitParts/OrgNewsUnit';

interface Props {
  notifications?: Notification[];
  onClickMarkAllAsRead?: () => void;
  onClickNotification?: (id: number) => void;
  isViewReadMoreButton?: boolean;
  onClickReadMore?: () => void;
}

const Component: FC<Props> = ({
  notifications = [],
  onClickMarkAllAsRead = () => undefined,
  onClickNotification = () => undefined,
  isViewReadMoreButton = true,
  onClickReadMore: onReadMore = () => undefined,
}) => {
  return (
    <div className={styles.NewsContainer}>
      <div className={styles.NewsHeader}>
        <p className={styles.NewsNoticeText}>お知らせ</p>
        <p className={styles.NewsMarkAsRead} onClick={onClickMarkAllAsRead}>
          すべて既読にする
        </p>
      </div>

      <div className={styles.NewsContentsContainer}>
        <ul>
          {notifications.map(notification => (
            <OrgNewsUnit
              key={notification.pivot.id}
              notification={notification}
              onClick={onClickNotification}
            />
          ))}
        </ul>

        {isViewReadMoreButton && (
          <ButtonFullWidth
            text="もっと見る"
            type="Secondary"
            onClick={onReadMore}
          />
        )}
      </div>
    </div>
  );
};

export default Component;
