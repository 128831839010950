import { Reducer } from 'redux';

import { Actions } from '../actions/adminFollowersActions';
import * as ActionType from '../actions/adminFollowersConstants';

import { Pager, PagerInitialValue } from '../interfaces/commonInterFace';

import { User } from '../interfaces/userInterFace';
import { BlockFollowerErrors } from '../interfaces/followerInterFace';
import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../interfaces/fanclubInterFace';

type State = {
  details: FanclubDetails;
  followers: User[];
  pager: Pager;
  ready: boolean;
  loading: boolean;
  success: boolean;
  errors: BlockFollowerErrors;
};

export const initialState: State = {
  details: FanclubDetailsInitialValue,
  followers: [],
  pager: PagerInitialValue,
  ready: false,
  loading: false,
  success: false,
  errors: {},
};

const adminFollowersReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fanclub details
    case ActionType.GET_FANCLUB_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_SUCCEED: {
      return {
        ...state,
        loading: false,
        details: action.payload.result,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // get fc follower list
    case ActionType.GET_FC_FOLLOWER_LIST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FC_FOLLOWER_LIST_SUCCEED: {
      const { data: followers, ...pager } = action.payload.result.followers;

      return {
        ...state,
        loading: false,
        followers,
        pager,
      };
    }
    case ActionType.GET_FC_FOLLOWER_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // block follower
    case ActionType.BLOCK_FOLLOWER_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.BLOCK_FOLLOWER_SUCCEED: {
      const { block_user } = action.payload.params;

      return {
        ...state,
        details: {
          ...state.details,
          blockedCount: state.details.blockedCount + 1,
          followerCount: state.details.followerCount - 1,
        },
        followers: state.followers.filter(
          follower => follower.name_id !== block_user,
        ),
        loading: false,
      };
    }
    case ActionType.BLOCK_FOLLOWER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default adminFollowersReducer;
