/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import moment from 'moment';
import blankThumbnailUrl from '../../assets/images/thumbnail-noimage.png';
import TitleWithSubLine from '../../atoms/title/TitleWithSubLine';
import {
  NewsDetails,
  NewsDetailsInitialValue,
} from '../../../../interfaces/newsInterFace';
import * as styles from './OrgHeroNews.module.scss';

interface Props {
  cssClass?: string;
  detail?: NewsDetails;
  isViewThumbnail?: boolean;
}

const OrgHero: FC<Props> = ({
  cssClass = '',
  detail = NewsDetailsInitialValue,
  isViewThumbnail = false,
}) => {
  const classNames = ClassNames(styles.OrgHeroBase, cssClass);

  return (
    <div className={classNames}>
      {isViewThumbnail && (
        <div className={styles.OrgHeroMain}>
          <div
            className={styles.OrgHeroImage}
            style={{
              background: `url(${detail?.url ||
                blankThumbnailUrl}) no-repeat center / cover`,
            }}
          />
        </div>
      )}

      <div className={styles.OrgHeroTitle}>
        <TitleWithSubLine
          mainText={detail.subject || ''}
          subText={
            detail.release_at
              ? moment(detail.release_at).format('YYYY.MM.DD HH:mm')
              : ''
          }
        />
      </div>
    </div>
  );
};

export default OrgHero;
