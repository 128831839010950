import { Reducer } from 'redux';

import { Actions } from '../actions/adminLikesActions';
import * as ActionType from '../actions/adminLikesConstants';

import { Content } from '../interfaces/contentInterFace';

type State = {
  likes: Content[];
  ready: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
};

export const initialState: State = {
  likes: [],
  ready: false,
  loading: false,
  success: false,
  error: false,
};

const adminFollowersReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get like list
    case ActionType.GET_LIKE_LIST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_LIKE_LIST_SUCCEED: {
      const { data: likes } = action.payload.result;

      return {
        ...state,
        likes,
        loading: false,
      };
    }
    case ActionType.GET_LIKE_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default adminFollowersReducer;
