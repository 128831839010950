import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { parse } from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import {
  resetPasswordEvent,
  cleanup,
} from '../../actions/reissuePasswordResetPasswordActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import {
  validatePassword,
  validateConfirmPassword,
} from '../../utils/validationHelper';
import { ResetPasswordValidationErrors } from '../../interfaces/passwordInterFace';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import ResetPassword from '../../components/reissuePassword/resetPassword';

const ResetPasswordContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [password, setPassword] = useState('');
  const [password_confirmation, setPasswordConfirmation] = useState('');
  const { loading, errors, success } = useSelector(
    selector.reissuePasswordResetPassword.getReissuePasswordResetPasswordState,
  );
  const { token, email } = useMemo(
    () =>
      parse(history.location.search) as {
        token: string;
        email: string;
      },
    [history.location.search],
  );
  // params依存かつparamsの変更が激しいので、Memo化はしない
  const validationErrors: ResetPasswordValidationErrors = {
    token: errors?.token?.length ? errors.token[0] : '',
    email: errors?.email?.length ? errors.email[0] : '',
    password: errors?.password?.length
      ? errors.password[0]
      : validatePassword(password),
    password_confirmation: errors?.password_confirmation?.length
      ? errors.password_confirmation[0]
      : validateConfirmPassword(password, password_confirmation),
  };

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (name === 'password') {
      setPassword(value);
    } else if (name === 'passwordConf') {
      setPasswordConfirmation(value);
    }
  }, []);

  const handleResetPassword = useCallback(() => {
    dispatch(
      resetPasswordEvent.start({
        token,
        email,
        password,
        password_confirmation,
      }),
    );
  }, [dispatch, email, password, password_confirmation, token]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    if (!token || !email) history.replace(PATH.REISSUE_PASSWORD_SEND_EMAIL);

    return () => {
      dispatch(cleanup());
    };
  }, [dispatch, email, history, token]);

  useEffect(() => {
    if (success) history.replace(PATH.REISSUE_PASSWORD_LOGIN);
  }, [history, success]);

  return (
    <>
      <Helmet>
        <title>パスワード再登録 | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}

      <ResetPassword
        valuePass={password}
        valuePassConf={password_confirmation}
        onChange={handleChange}
        onRegisterEvent={handleResetPassword}
        errors={validationErrors}
      />
    </>
  );
};

export default ResetPasswordContainer;
