import { Reducer } from 'redux';
import { Actions } from '../actions/settingActions';
import * as ActionType from '../actions/settingConstants';
import { Card } from '../interfaces/cardInterFace';

type State = {
  card: Card | null;
  ready: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
};

export const initialState: State = {
  card: null,
  ready: false,
  loading: false,
  error: false,
  success: false,
};

const authReducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        ready: true,
      };
    }

    // get card info
    case ActionType.GET_CARD_INFO_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_CARD_INFO_SUCCEED: {
      return {
        ...state,
        card: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_CARD_INFO_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // メール受取設定の変更
    case ActionType.CHANGE_RECEPTION_SETTING_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.CHANGE_RECEPTION_SETTING_SUCCEED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.CHANGE_RECEPTION_SETTING_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default authReducer;
