/* eslint-disable import/no-cycle */

import {
  Status,
  ErrorResponse,
  Pager,
  PagerInitialValue,
  BoolNumber,
} from './commonInterFace';
import { User, SimpleUser, UserType } from './userInterFace';
import { Comment } from './commentInterFace';
import { Fanclub, FanclubInitialValue } from './fanclubInterFace';
import { Category, ContentCategory } from './categoryInterFace';
import { InputFile } from './utilInterFace';

/**
 * 1: 有料フォロワーのみに公開
 * 2: フォロワーのみに公開
 * 3: 全員に公開
 */
export type OpenArea = 1 | 2 | 3;

/**
 * 1: 自分で管理
 * 2: 外部(JASRAC等)に管理委託
 */
export type CopyRight = 1 | 2;

/**
 * 1: blog, 2: photo, 3: movie, 4: voice
 */
export type ContentType = 1 | 2 | 3 | 4;
/**
 * 0: timeline, 1: blog, 2: photo, 3: movie, 4: voice
 */
export type ContentListType = 0 | ContentType;

export type ContentRegisterMode = 'create' | 'update';

export enum ContentLabel {
  'ブログ' = 1,
  '画像' = 2,
  '動画' = 3,
  '音声' = 4,
}

export type Tab<Type> = { type: Type; label: string };
export const TimeLineTabList: Tab<ContentListType>[] = [
  {
    type: 0,
    label: 'タイムライン',
  },
  {
    type: 1,
    label: 'ブログ',
  },
  {
    type: 3,
    label: '動画',
  },
  {
    type: 2,
    label: '画像',
  },
  {
    type: 4,
    label: '音声',
  },
];
export const AdminContentsTabList: Tab<ContentType>[] = [
  {
    type: 1,
    label: 'ブログ',
  },
  {
    type: 3,
    label: '動画',
  },
  {
    type: 2,
    label: '画像',
  },
  {
    type: 4,
    label: '音声',
  },
];

export type Photo = {
  id: number;
  content_id: number;
  url: string;
};
export const PhotoInitialValue: Photo = {
  id: 0,
  content_id: 0,
  url: '',
};
export type Movie = {
  id: number;
  content_id: number;
  url: string;
};
export const MovieInitialValue: Movie = {
  id: 0,
  content_id: 0,
  url: '',
};
export type Voice = {
  id: number;
  content_id: number;
  url: string;
  recording_time: number;
};
export const VoiceInitialValue: Voice = {
  id: 0,
  content_id: 0,
  url: '',
  recording_time: 0,
};

export type ContentBase = {
  type: ContentType;
  id: number;
  user_id: number | null;
  fanclub_id: number;
  subject: string;
  url: string | null; // thumbnail
  copyright: CopyRight | null;
  song_title: string | null;
  composer: string | null;
  lyricist: string | null;
  work_code: string | null;
  open_area: OpenArea;
  is_release: BoolNumber;
  release_at: string;
  close_at: string | null;
  is_like: BoolNumber;
  total_like: number;
  total_comment: number;
  categories: Category[];
  user: User | null;
  user_type?: UserType;
  fanclub: Omit<Fanclub, 'genres'>;
};
export type ContentBlog = ContentBase & {
  type: 1;
  body: string;
};
export type ContentPhoto = ContentBase & {
  type: 2;
  photos: string[];
};
export type ContentMovie = ContentBase & {
  type: 3;
  movie: Movie;
};
export type ContentVoice = ContentBase & {
  type: 4;
  voice: Voice;
};
export type Content = ContentBlog | ContentPhoto | ContentMovie | ContentVoice;
export const ContentInitialValue: Content = {
  id: 0,
  type: 1,
  user_id: null,
  fanclub_id: 0,
  subject: '',
  url: '',
  copyright: null,
  song_title: '',
  composer: '',
  lyricist: '',
  work_code: '',
  release_at: '',
  close_at: null,
  open_area: 1,
  is_release: 1,
  is_like: 0,
  total_like: 0,
  total_comment: 0,
  categories: [],
  user: null,
  fanclub: FanclubInitialValue,
  body: '',
};
export const ContentBlogInitialValue: ContentBlog = {
  ...ContentInitialValue,
  type: 1,
  body: '',
};
export const ContentPhotoInitialValue: ContentPhoto = {
  ...ContentInitialValue,
  type: 2,
  photos: [],
};
export const ContentMovieInitialValue: ContentMovie = {
  ...ContentInitialValue,
  type: 3,
  movie: MovieInitialValue,
};
export const ContentVoiceInitialValue: ContentVoice = {
  ...ContentInitialValue,
  type: 4,
  voice: VoiceInitialValue,
};

export type ContentDetail = {
  id: number;
  type: ContentType;
  user_id: number | null;
  fanclub_id: number;
  subject: string;
  body: string | null;
  url: string | null; // thumbnail
  copyright: CopyRight | null;
  song_title: string | null;
  composer: string | null;
  lyricist: string | null;
  work_code: string | null;
  release_at: string;
  close_at: string | null;
  open_area: OpenArea;
  is_release: BoolNumber;
  total_like: number;
  fanclub: Omit<Fanclub, 'genres'>;
};
export const ContentDetailInitialValue: ContentDetail = {
  id: 0,
  type: 1,
  user_id: null,
  fanclub_id: 0,
  subject: '',
  body: null,
  url: null,
  copyright: null,
  song_title: '',
  composer: '',
  lyricist: '',
  work_code: '',
  release_at: '',
  close_at: null,
  open_area: 1,
  is_release: 0,
  total_like: 0,
  fanclub: FanclubInitialValue,
};
export type ContentDetails = {
  detail: ContentDetail;
  photos: Photo | Photo[] | null;
  movie: Movie | null;
  voice: Voice | null;
  create_user: SimpleUser | null;
  categories: ContentCategory[];
  comments: Comment[];
  is_like: BoolNumber;
  prev_id: number | null;
  next_id: number | null;
};
export const ContentDetailsInitialValue: ContentDetails = {
  detail: ContentDetailInitialValue,
  photos: null,
  movie: null,
  voice: null,
  create_user: null,
  categories: [],
  comments: [],
  is_like: 0,
  prev_id: null,
  next_id: null,
};

export type ContentListWithPager = {
  list: Content[];
  pager: Pager;
};
export const ContentListWithPagerInitialValue: ContentListWithPager = {
  list: [],
  pager: PagerInitialValue,
};

type CreateContentParamsBase = {
  artistId: string;
  subject?: string;
  release_at?: string; // YYYY-MM-DD HH:mm:ss
  close_at?: string; // YYYY-MM-DD HH:mm:ss
  open_area?: OpenArea;
  is_release: BoolNumber; // 0: 下書き保存, 1: 投稿
  categories?: number[];
  thumb?: File | null;
};

export type InputBlogParams = Required<
  Omit<
    CreateBlogParams & UpdateBlogParams,
    | 'artistId'
    | 'contentId'
    | 'thumb'
    | 'photos'
    | 'release_at'
    | 'close_at'
    | 'is_release'
  >
> & {
  thumb: InputFile;
  photos: InputFile[];
  release_at_date: string;
  release_at_time: string;
  close_at_date: string;
  close_at_time: string;
};
export const InputBlogParamsInitialValue: InputBlogParams = {
  subject: '',
  release_at_date: '',
  release_at_time: '',
  close_at_date: '',
  close_at_time: '',
  open_area: 3,
  categories: [],
  thumb: {
    file: null,
    preview: '',
  },
  body: '',
  photos: [],
  delete_photo_ids: [],
  is_delete_thumb: false,
};
export type RegisterBlogValidationErrors = Record<
  keyof Omit<CreateBlogParams & UpdateBlogParams, 'artistId' | 'contentId'>,
  string
>;
export const RegisterBlogValidationErrorsInitialValue: RegisterBlogValidationErrors = {
  thumb: '',
  subject: '',
  categories: '',
  body: '',
  photos: '',
  release_at: '',
  close_at: '',
  open_area: '',
  is_delete_thumb: '',
  delete_photo_ids: '',
  is_release: '',
};

export type InputMovieParams = Required<
  Omit<
    CreateMovieParams & UpdateMovieParams,
    | 'artistId'
    | 'contentId'
    | 'thumb'
    | 'movie'
    | 'release_at'
    | 'close_at'
    | 'is_release'
  >
> & {
  thumb: InputFile;
  movie: InputFile;
  release_at_date: string;
  release_at_time: string;
  close_at_date: string;
  close_at_time: string;
};
export const InputMovieParamsInitialValue: InputMovieParams = {
  subject: '',
  release_at_date: '',
  release_at_time: '',
  close_at_date: '',
  close_at_time: '',
  open_area: 3,
  categories: [],
  thumb: {
    file: null,
    preview: '',
  },
  movie: {
    file: null,
    preview: '',
  },
  copyright: 1,
  song_title: '',
  composer: '',
  lyricist: '',
  work_code: '',
  is_delete_thumb: false,
};
export type RegisterMovieValidationErrors = Record<
  keyof Omit<CreateMovieParams & UpdateMovieParams, 'artistId' | 'contentId'>,
  string
>;
export const RegisterMovieValidationErrorsInitialValue: RegisterMovieValidationErrors = {
  thumb: '',
  subject: '',
  categories: '',
  movie: '',
  copyright: '',
  song_title: '',
  composer: '',
  lyricist: '',
  work_code: '',
  release_at: '',
  close_at: '',
  open_area: '',
  is_delete_thumb: '',
  is_release: '',
};

export type InputPhotoParams = Required<
  Omit<
    CreatePhotoParams & UpdatePhotoParams,
    | 'artistId'
    | 'contentId'
    | 'thumb'
    | 'photos'
    | 'release_at'
    | 'close_at'
    | 'is_release'
  >
> & {
  photos: InputFile[];
  release_at_date: string;
  release_at_time: string;
  close_at_date: string;
  close_at_time: string;
};
export const InputPhotoParamsInitialValue: InputPhotoParams = {
  subject: '',
  photos: [],
  delete_photo_ids: [],
  release_at_date: '',
  release_at_time: '',
  close_at_date: '',
  close_at_time: '',
  open_area: 3,
  categories: [],
};
export type RegisterPhotoValidationErrors = Record<
  keyof Omit<CreatePhotoParams & UpdatePhotoParams, 'artistId' | 'contentId'>,
  string
>;
export const RegisterPhotoValidationErrorsInitialValue: RegisterPhotoValidationErrors = {
  thumb: '',
  subject: '',
  photos: '',
  categories: '',
  release_at: '',
  close_at: '',
  open_area: '',
  delete_photo_ids: '',
  is_release: '',
};

export type InputVoiceParams = Required<
  Omit<
    CreateVoiceParams & UpdateVoiceParams,
    | 'artistId'
    | 'contentId'
    | 'thumb'
    | 'voice'
    | 'release_at'
    | 'close_at'
    | 'is_release'
  >
> & {
  thumb: InputFile;
  voice: InputFile;
  release_at_date: string;
  release_at_time: string;
  close_at_date: string;
  close_at_time: string;
};
export const InputVoiceParamsInitialValue: InputVoiceParams = {
  subject: '',
  release_at_date: '',
  release_at_time: '',
  close_at_date: '',
  close_at_time: '',
  open_area: 3,
  categories: [],
  thumb: {
    file: null,
    preview: '',
  },
  voice: {
    file: null,
    preview: '',
  },
  recording_time: 0,
  copyright: 1,
  song_title: '',
  composer: '',
  lyricist: '',
  work_code: '',
  is_delete_thumb: false,
};
export type RegisterVoiceValidationErrors = Record<
  keyof Omit<CreateVoiceParams & UpdateVoiceParams, 'artistId' | 'contentId'>,
  string
>;
export const RegisterVoiceValidationErrorsInitialValue: RegisterVoiceValidationErrors = {
  thumb: '',
  subject: '',
  categories: '',
  voice: '',
  copyright: '',
  song_title: '',
  composer: '',
  lyricist: '',
  work_code: '',
  recording_time: '',
  release_at: '',
  close_at: '',
  open_area: '',
  is_delete_thumb: '',
  is_release: '',
};

export type CreateBlogParams = CreateContentParamsBase & {
  body: string;
  photos: (File | null)[];
};
export type CreateBlogErrors = {
  [key in keyof Partial<Omit<CreateBlogParams, 'categories'>>]: string[];
} & {
  'categories.0'?: string[];
};
export type CreateBlogResult = {
  content: ContentBlog;
  categories: Category[];
  photo: Photo | null;
};
export type CreateBlogResponse = {
  status_code: Status.OK;
  result: CreateBlogResult;
};
export type CreateBlogErrorResponse = ErrorResponse<keyof CreateBlogParams>;

export type CreatePhotoParams = CreateContentParamsBase & {
  photos: (File | null)[];
};
export type CreatePhotoErrors = {
  [key in keyof Partial<
    Omit<CreatePhotoParams, 'categories' | 'photos'>
  >]: string[];
} & {
  'categories.0'?: string[];
  'photos.0'?: string[];
};
export type CreatePhotoResult = {
  content: ContentPhoto;
  categories: Category[];
  photos: Photo[];
};
export type CreatePhotoResponse = {
  status_code: Status.OK;
  result: CreatePhotoResult;
};
export type CreatePhotoErrorResponse = ErrorResponse<keyof CreatePhotoParams>;

export type CreateMovieParams = CreateContentParamsBase & {
  movie: File | null;
  copyright: CopyRight;
  song_title: string;
  composer: string;
  lyricist: string;
  work_code: string;
};
export type CreateMovieErrors = {
  [key in keyof Partial<Omit<CreateMovieParams, 'categories'>>]: string[];
} & {
  'categories.0'?: string[];
};
export type CreateMovieResult = {
  content: ContentMovie;
  categories: Category[];
  movie: Movie;
};
export type CreateMovieResponse = {
  status_code: Status.OK;
  result: CreateMovieResult;
};
export type CreateMovieErrorResponse = ErrorResponse<keyof CreateMovieParams>;

export type CreateVoiceParams = CreateContentParamsBase & {
  voice: File | null;
  recording_time: number; // seconds
  copyright: CopyRight;
  song_title: string;
  composer: string;
  lyricist: string;
  work_code: string;
};
export type CreateVoiceErrors = {
  [key in keyof Partial<Omit<CreateVoiceParams, 'categories'>>]: string[];
} & {
  'categories.0'?: string[];
};
export type CreateVoiceResult = {
  content: ContentVoice;
  categories: Category[];
  voice: Voice;
};
export type CreateVoiceResponse = {
  status_code: Status.OK;
  result: CreateVoiceResult;
};
export type CreateVoiceErrorResponse = ErrorResponse<keyof CreateVoiceParams>;

/**
 * サムネイル更新について
 * 上書きの場合はthumbを送る
 * 削除の場合はis_delete_thumbをtrueにして送る
 */
type UpdateContentParamsBase = {
  artistId: string;
  contentId: number;
  subject?: string;
  release_at?: string; // YYYY-MM-DD HH:mm:ss
  close_at?: string; // YYYY-MM-DD HH:mm:ss
  open_area?: OpenArea;
  is_release: BoolNumber; // 0: 下書き保存, 1: 投稿
  categories?: number[];
  thumb?: File | null;
  is_delete_thumb?: boolean; // サムネイルを削除する場合
};

export type UpdateBlogParams = UpdateContentParamsBase & {
  body: string;
  photos?: (File | null)[];
  delete_photo_ids?: number[];
};
export type UpdateBlogErrors = {
  [key in keyof Partial<Omit<UpdateBlogParams, 'categories'>>]: string[];
} & {
  'categories.0'?: string[];
};
export type UpdateBlogResult = {
  content: ContentBlog;
  categories: Category[];
  photo: Photo | null;
};
export type UpdateBlogResponse = {
  status_code: Status.OK;
  result: UpdateBlogResult;
};
export type UpdateBlogErrorResponse = ErrorResponse<keyof UpdateBlogParams>;

export type UpdatePhotoParams = Omit<
  UpdateContentParamsBase,
  'is_delete_thumb'
> & {
  photos?: (File | null)[];
  delete_photo_ids: number[];
};
export type UpdatePhotoErrors = {
  [key in keyof Partial<
    Omit<UpdatePhotoParams, 'categories' | 'photos'>
  >]: string[];
} & {
  'categories.0'?: string[];
  'photos.0'?: string[];
};
export type UpdatePhotoResult = {
  content: ContentPhoto;
  categories: Category[];
  photos: Photo[];
};
export type UpdatePhotoResponse = {
  status_code: Status.OK;
  result: UpdatePhotoResult;
};
export type UpdatePhotoErrorResponse = ErrorResponse<keyof UpdatePhotoParams>;

export type UpdateMovieParams = UpdateContentParamsBase & {
  movie?: File | null;
  copyright: CopyRight;
  song_title: string;
  composer: string;
  lyricist: string;
  work_code: string;
};
export type UpdateMovieErrors = {
  [key in keyof Partial<Omit<UpdateMovieParams, 'categories'>>]: string[];
} & {
  'categories.0'?: string[];
};
export type UpdateMovieResult = {
  content: ContentMovie;
  categories: Category[];
  movie: Movie;
};
export type UpdateMovieResponse = {
  status_code: Status.OK;
  result: UpdateMovieResult;
};
export type UpdateMovieErrorResponse = ErrorResponse<keyof UpdateMovieParams>;

export type UpdateVoiceParams = UpdateContentParamsBase & {
  voice?: File | null;
  recording_time?: number; // seconds
  copyright: CopyRight;
  song_title: string;
  composer: string;
  lyricist: string;
  work_code: string;
};
export type UpdateVoiceErrors = {
  [key in keyof Partial<Omit<UpdateVoiceParams, 'categories'>>]: string[];
} & {
  'categories.0'?: string[];
};
export type UpdateVoiceResult = {
  content: ContentVoice;
  categories: Category[];
  voice: Voice;
};
export type UpdateVoiceResponse = {
  status_code: Status.OK;
  result: UpdateVoiceResult;
};
export type UpdateVoiceErrorResponse = ErrorResponse<keyof UpdateVoiceParams>;

export type DeleteContentParams = {
  artistId: string;
  contentId: number;
  type: ContentType;
};
export type DeleteContentResponse = {
  status_code: Status.OK;
  message: string;
};
export type DeleteBlogParams = DeleteContentParams & {
  type: 1;
};
export type DeletePhotoParams = DeleteContentParams & {
  type: 2;
};
export type DeleteMovieParams = DeleteContentParams & {
  type: 3;
};
export type DeleteVoiceParams = DeleteContentParams & {
  type: 4;
};

export type GetContentListParams = {
  reset?: boolean;
  type: ContentListType;
  group_id?: string;
  create_user_id?: number;
  category_id?: number;
  page?: number;
  user_type?: 'artist';
  limit?: number;
};
export type GetContentListResult = Pager & {
  data: Content[];
};
export type GetContentListResponse = {
  status_code: Status.OK;
  result: GetContentListResult;
};

export type GetRecentContentPhotoListParams = GetContentListParams & {
  type: 2;
};
export type GetContentPhotoListResult = Pager & {
  data: ContentPhoto[];
};
export type GetContentPhotoListResponse = {
  status_code: Status.OK;
  result: GetContentPhotoListResult;
};

export type GetRecentContentBlogListParams = GetContentListParams & {
  type: 1;
};
export type GetContentBlogListResult = Pager & {
  data: ContentBlog[];
};
export type GetContentBlogListResponse = {
  status_code: Status.OK;
  result: GetContentBlogListResult;
};

export type GetLikedContentListParams = {
  userId: string;
  page?: number;
  reset?: boolean;
};
export type GetLikedContentListResult = Pager & {
  data: Content[];
};
export type GetLikedContentListResponse = {
  status_code: Status.OK;
  result: GetLikedContentListResult;
};

export type GetAdminContentListParams = GetContentListParams & {
  type: ContentType;
  user_type: 'artist';
};

export type GetContentListForHomeParams = {
  type: ContentListType;
  open_area?: 3;
  group_id?: string[];
  page?: number;
  limit?: number;
  reset?: boolean;
};

export type GetContentDetailsParams = {
  contentId: number;
};
export type GetContentDetailsResult = ContentDetails;
export type GetContentDetailsResponse = {
  status_code: Status.OK;
  result: GetContentDetailsResult;
};
/**
 * アーティストにブロックされている場合：user_typeが9で返ってくる
 * その他未フォロー等の場合：モーダル表示用のファンクラブ情報等が返ってくる
 */
export type GetContentDetailsErrorResponse =
  | {
      status_code: Status.Forbidden;
      result: '権限がありません';
      fanclub: Fanclub;
      open_area: OpenArea;
      user_type: undefined;
    }
  | {
      status_code: Status.Forbidden;
      result: '権限がありません';
      fanclub: undefined;
      open_area: undefined;
      user_type: UserType;
    };

export type LikeParams = {
  content_id: number;
};
export type LikeResult = {
  content_id: number;
  user_id: number;
  is_like: BoolNumber;
  total_like: number;
};
export type LikeResponse = {
  status_code: Status.OK;
  result: LikeResult;
};
export type LikeErrorResponse = ErrorResponse<keyof LikeParams>;

export type ReleaseLikeParams = {
  content_id: number;
};
export type ReleaseLikeResult = {
  content_id: number;
  user_id: number;
  is_like: BoolNumber;
  total_like: number;
};
export type ReleaseLikeResponse = {
  status_code: Status.OK;
  result: ReleaseLikeResult;
};
export type ReleaseLikeErrorResponse = ErrorResponse<keyof ReleaseLikeParams>;

export type GetLikeListParams = {
  artistId: string;
  limit?: number;
  page?: number;
};
export type GetLikeListResult = Pager & {
  data: Content[];
};
export type GetLikeListResponse = {
  status_code: Status.OK;
  result: GetLikeListResult;
};
