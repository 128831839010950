/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import moment from 'moment';
import blankThumbnailUrl from '../../assets/images/thumbnail-noimage.png';
import TitleWithSubLine from '../../atoms/title/TitleWithSubLine';
import LabelTag from '../../atoms/label/LabelTag';
import {
  ContentDetail,
  ContentDetailInitialValue,
} from '../../../../interfaces/contentInterFace';
import * as styles from './OrgHeroContent.module.scss';
import { ContentCategory } from '../../../../interfaces/categoryInterFace';

type PartialContentDetail = Partial<ContentDetail>;

interface Props {
  cssClass?: string;
  detail?: PartialContentDetail;
  categories?: ContentCategory[];
  isViewThumbnail?: boolean;
}

const OrgHero: FC<Props> = ({
  cssClass = '',
  detail = ContentDetailInitialValue,
  categories = [],
  isViewThumbnail = false,
}) => {
  const classNames = ClassNames(styles.OrgHeroBase, cssClass);

  return (
    <div className={classNames}>
      {isViewThumbnail && (
        <div className={styles.OrgHeroMain}>
          <div
            className={styles.OrgHeroImage}
            style={{
              background: `url(${detail?.url ||
                blankThumbnailUrl}) no-repeat center / cover`,
            }}
          />
        </div>
      )}

      <div className={styles.OrgHeroTitle}>
        <TitleWithSubLine
          mainText={detail.subject ?? ''}
          subText={
            detail.release_at
              ? moment(detail.release_at).format('YYYY.MM.DD HH:mm')
              : ''
          }
        />
      </div>

      <div className={styles.OrgHeroTag}>
        {categories.length ? (
          categories.map(c => (
            <LabelTag
              key={c.label}
              text={c.label}
              type="Tertiary"
              cssClass={styles.OrgHeroTagItem}
            />
          ))
        ) : (
          <LabelTag
            text="未分類"
            type="Tertiary"
            cssClass={styles.OrgHeroTagItem}
          />
        )}
      </div>
    </div>
  );
};

export default OrgHero;
