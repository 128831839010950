import { Status } from './commonInterFace';

export type TwitterAuth = {
  url: string;
  oauth_token_secret: string;
};
export const TwitterAuthInitialValue: TwitterAuth = {
  url: '',
  oauth_token_secret: '',
};
export type GetTwitterAuthUrlResponse = {
  status_code: Status.OK;
} & TwitterAuth;

export type AuthenticateParams = {
  oauth_token: string;
  oauth_verifier: string;
  oauth_token_secret: string;
};
export type AuthenticateResponse = {
  status_code: Status.OK;
  access_token: string;
  message: 'login' | 'create';
  token_type: 'bearer';
  expires_in: 3600;
};
