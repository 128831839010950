import * as ActionType from './editFanclubConstants';
import {
  GetFanclubDetailsParams,
  GetFanclubDetailsResult,
  UpdateFanclubParams,
  UpdateFanclubResult,
  UpdateFanclubErrors,
} from '../interfaces/fanclubInterFace';

export const getFanclubDetailsEvent = {
  start: (params: GetFanclubDetailsParams) => ({
    type: ActionType.GET_FANCLUB_DETAILS_START,
    payload: params,
  }),

  succeed: (result: GetFanclubDetailsResult) => ({
    type: ActionType.GET_FANCLUB_DETAILS_SUCCEED,
    payload: { result },
  }),

  fail: () => ({
    type: ActionType.GET_FANCLUB_DETAILS_FAIL,
  }),
};

export const updateFanclubEvent = {
  start: (params: UpdateFanclubParams) => ({
    type: ActionType.UPDATE_FANCLUB_START,
    payload: params,
  }),

  succeed: (result: UpdateFanclubResult) => ({
    type: ActionType.UPDATE_FANCLUB_SUCCEED,
    payload: { result },
  }),

  fail: (errors: UpdateFanclubErrors) => ({
    type: ActionType.UPDATE_FANCLUB_FAIL,
    payload: { errors },
  }),
};

export const cleanup = () => ({
  type: ActionType.CLEANUP,
});

export type Actions =
  // get fanclub details
  | ReturnType<typeof getFanclubDetailsEvent.start>
  | ReturnType<typeof getFanclubDetailsEvent.succeed>
  | ReturnType<typeof getFanclubDetailsEvent.fail>
  // update fanclub
  | ReturnType<typeof updateFanclubEvent.start>
  | ReturnType<typeof updateFanclubEvent.succeed>
  | ReturnType<typeof updateFanclubEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
