/* eslint-disable no-useless-return */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import {
  changePasswordEvent,
  cleanup,
} from '../../actions/changePasswordActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';
import {
  validatePassword,
  validateConfirmPassword,
} from '../../utils/validationHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import ChangePassword from '../../components/setting/changePassword';

import {
  ChangePasswordParams,
  ChangePasswordParamsInitialValue,
  ChangePasswordValidationErrors,
} from '../../interfaces/passwordInterFace';

const ChangePasswordContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [params, setParams] = useState<ChangePasswordParams>(
    ChangePasswordParamsInitialValue,
  );
  const {
    ready: authReady,
    profile: { user },
  } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const { loading, errors, success } = useSelector(
    selector.changePassword.getChangePasswordState,
  );
  // params依存かつparamsの変更が激しいので、Memo化はしない
  const validationErrors: ChangePasswordValidationErrors = {
    current_password:
      errors.current_password?.[0] ?? validatePassword(params.current_password),
    new_password:
      errors.new_password?.[0] ?? validatePassword(params.new_password),
    new_password_confirmation:
      errors.new_password_confirmation?.[0] ??
      validateConfirmPassword(
        params.new_password,
        params.new_password_confirmation,
      ),
  };

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    setParams(params => ({ ...params, [name]: value }));
  }, []);
  const handleChangePassword = useCallback(() => {
    dispatch(changePasswordEvent.start(params));
  }, [dispatch, params]);
  const handleBack = useCallback(() => {
    history.push(PATH.SETTING_MENU);
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (authReady && !isLogin) {
      history.push(PATH.SIGNUP);
    }
  }, [authReady, history, isLogin]);

  useEffect(() => {
    if (JSON.stringify(errors) === JSON.stringify({})) return;

    window.alert('パスワードの変更に失敗しました');
    window.scrollTo(0, 0);
  }, [errors]);

  useEffect(() => {
    if (success) history.push(PATH.SIGNUP);
  }, [history, success]);

  return (
    <>
      <Helmet>
        <title>パスワード変更 | 設定 | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady} />

      <ChangePassword
        userId={user.name_id}
        params={params}
        onChange={handleChange}
        onChangePassWordEvent={handleChangePassword}
        onBackEvent={handleBack}
        errors={validationErrors}
      />
    </>
  );
};

export default ChangePasswordContainer;
