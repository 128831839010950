export const GET_READY = 'NEWS/GET_READY' as const;
export const GOT_READY = 'NEWS/GOT_READY' as const;

export const GET_NOTIFICATION_LIST_START = 'NEWS/GET_NOTIFICATION_LIST_START' as const;
export const GET_NOTIFICATION_LIST_SUCCEED = 'NEWS/GET_NOTIFICATION_LIST_SUCCEED' as const;
export const GET_NOTIFICATION_LIST_FAIL = 'NEWS/GET_NOTIFICATION_LIST_FAIL' as const;

export const MARK_AS_READ_START = 'NEWS/MARK_AS_READ_START' as const;
export const MARK_AS_READ_SUCCEED = 'NEWS/MARK_AS_READ_SUCCEED' as const;
export const MARK_AS_READ_FAIL = 'NEWS/MARK_AS_READ_FAIL' as const;

export const MARK_ALL_AS_READ_START = 'NEWS/MARK_ALL_AS_READ_START' as const;
export const MARK_ALL_AS_READ_SUCCEED = 'NEWS/MARK_ALL_AS_READ_SUCCEED' as const;
export const MARK_ALL_AS_READ_FAIL = 'NEWS/MARK_ALL_AS_READ_FAIL' as const;

export const CLEANUP = 'NEWS/CLEANUP' as const;
