import * as ActionType from './adminSalesConstants';

import { getSalesInfoParams } from '../interfaces/salesInterFace';

export const getReady = (artistId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getFCSalesInfo = {
  start: (artistId: string) =>
    ({
      type: ActionType.GET_FC_SALES_INFO_START,
      payload: artistId,
    } as const),

  succeed: (result: getSalesInfoParams) =>
    ({
      type: ActionType.GET_FC_SALES_INFO_SUCCEED,
      payload: result,
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FC_SALES_INFO_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fanclub sales info
  | ReturnType<typeof getFCSalesInfo.start>
  | ReturnType<typeof getFCSalesInfo.succeed>
  | ReturnType<typeof getFCSalesInfo.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
