import React, { FC } from 'react';

import TitleH2 from '../common/atoms/title/TitleH2';
import ButtonSquare from '../common/atoms/buttonBasic/ButtonSquare';

import OrgPickUpForYouFCList from '../common/organisms/listParts/OrgPickUpForYouFCList';

import { FanclubWithUserInfo } from '../../interfaces/fanclubInterFace';

import * as styles from './followArtist.module.scss';

type Props = {
  fanclubList?: FanclubWithUserInfo[];
  onFollowClick?: (artistId: string) => void;
  onStartKrapEvent?: () => void;
};

const Component: FC<Props> = ({
  fanclubList = [],
  onFollowClick = () => undefined,
  onStartKrapEvent = () => undefined,
}) => {
  return (
    <div className={styles.containerWrap}>
      <TitleH2 text="アーティストをフォローしよう" cssClass={styles.title} />
      <div className={styles.read}>
        <p>気になるアーティストを3つ以上選んでKRAPをはじめよう！</p>
      </div>
      <OrgPickUpForYouFCList
        fanclubList={fanclubList}
        onClickFollow={onFollowClick}
      />

      <ButtonSquare
        text="KRAPを始める"
        type="Primary"
        size="Secondary"
        onClick={onStartKrapEvent}
        cssClass={styles.button}
      />
    </div>
  );
};

export default Component;
