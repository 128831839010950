/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  getContentListEvent,
  deleteContentEvent,
  cleanup,
} from '../../actions/adminContentsActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';
import { tweet } from '../../utils/snsHelper';

import { ContentType, Content } from '../../interfaces/contentInterFace';
import { Options } from '../../interfaces/footerInterFace';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminContents from '../../components/admin/adminContents';

const AdminContentsContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const [params, setParams] = useState<{
    type: ContentType;
    create_user_id: number;
    category_id: number;
  }>({
    type: 1,
    create_user_id: 0,
    category_id: 0,
  });
  const {
    ready: authReady,
    profile: {
      user: me,
      fanclubs: { manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const { ready, contents, pager, loading } = useSelector(
    selector.adminContents.getAdminContentsState,
  );
  const memberField = useSelector(selector.adminContents.getMemberField);
  const categoryField = useSelector(selector.adminContents.getCategoryField);
  const isLastPage = useSelector(selector.adminContents.getIsLastPage);
  const fanclub = useMemo(() => manages.find(f => f.group_id === artistId), [
    artistId,
    manages,
  ]);
  const memberFieldWithUnselected = useMemo(
    () => [
      {
        value: 0,
        label: '全てのメンバー',
      },
      ...memberField,
    ],
    [memberField],
  );
  const categoryFieldWithUnselected = useMemo(
    () => [
      {
        value: 0,
        label: '全てのカテゴリ',
      },
      ...categoryField,
    ],
    [categoryField],
  );

  const handleRegister = useCallback(() => {
    if (!artistId) return;

    // 1:blog, 2:photo, 3:movie, 4:voice
    const typeName =
      params.type === 1
        ? 'blog'
        : params.type === 2
        ? 'photo'
        : params.type === 3
        ? 'movie'
        : params.type === 4
        ? 'voice'
        : '';

    if (!typeName) return;

    history.push(PATH.getAdminRegisterContentPath(artistId, typeName));
  }, [artistId, history, params.type]);
  const handleClickEditCategory = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'CATEGORY'));
  }, [artistId, history]);
  const handleChangeTab = useCallback((type: ContentType) => {
    setParams(params => ({ ...params, type }));
  }, []);
  const handleChangeMember = useCallback(
    (e: React.FormEvent<HTMLSelectElement>) => {
      const { value } = e.currentTarget;
      setParams(params => ({ ...params, create_user_id: Number(value) }));
    },
    [],
  );
  const handleChangeCategory = useCallback(
    (e: React.FormEvent<HTMLSelectElement>) => {
      const { value } = e.currentTarget;
      setParams(params => ({ ...params, category_id: Number(value) }));
    },
    [],
  );
  const handleClickContent = useCallback(
    (content: Content) => {
      if (!artistId) return;

      if (content.user_id !== me.id && fanclub?.pivot?.type !== 3) {
        // 作成者もしくは管理者でない場合
        window.alert('コンテンツの編集は管理者か投稿者のみです');

        return;
      }

      // 1:blog, 2:photo, 3:movie, 4:voice
      const typeName =
        content.type === 1
          ? 'blog'
          : content.type === 2
          ? 'photo'
          : content.type === 3
          ? 'movie'
          : content.type === 4
          ? 'voice'
          : '';

      if (!typeName) return;

      history.push(
        PATH.getAdminRegisterContentPath(artistId, typeName, content.id),
      );
    },
    [fanclub, artistId, history, me.id],
  );
  const handleDeleteContent = useCallback(
    (e: React.MouseEvent<HTMLDListElement, MouseEvent>, content: Content) => {
      if (!artistId) return;

      if (content.user_id !== me.id && fanclub?.pivot?.type !== 3) {
        // 作成者もしくは管理者でない場合
        window.alert('コンテンツの編集は管理者か投稿者のみです');

        return;
      }

      const res = window.confirm(
        `本当に${
          content.subject ? `「${content.subject}」を` : ''
        }削除しますか？`,
      );

      if (!res) {
        e.stopPropagation();

        return;
      }

      dispatch(
        deleteContentEvent.start({
          artistId,
          contentId: content.id,
          user_type: 'artist',
          ...params,
        }),
      );

      e.stopPropagation();
    },
    [dispatch, fanclub, artistId, me.id, params],
  );
  const handleReadMore = useCallback(() => {
    dispatch(
      getContentListEvent.start({
        ...params,
        group_id: artistId,
        user_type: 'artist',
        page: pager.current_page + 1,
      }),
    );
  }, [dispatch, artistId, pager.current_page, params]);
  const handleBack = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'MENU'));
  }, [artistId, history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(() => {
    const baseOptions: Options = {
      copyright: true,
    };

    const contentInfo = history.location?.state?.ADMIN_CONTENTS;

    if (!contentInfo)
      return {
        ...baseOptions,
      };

    // const text = `「${contentInfo.title}」を${
    //   contentInfo.mode === 'create' ? '投稿' : '更新'
    // }しました。`;
    const text = `「${contentInfo.title}」を更新しました。`;

    const url = `${window.location.origin}${PATH.getContentPath(
      contentInfo.artistId,
      contentInfo.contentId,
    )}`;

    const onClickButton = () => tweet(text, url);

    return {
      ...baseOptions,
      twitter: { text, onClickButton },
    };
  }, [history]);
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!fanclub || !artistId) {
      history.replace(PATH.HOME);

      return;
    }

    dispatch(getReady(artistId));
  }, [authReady, dispatch, fanclub, artistId, history]);

  useEffect(() => {
    if (!authReady || !ready) return;

    dispatch(
      getContentListEvent.start({
        type: params.type,
        create_user_id: params.create_user_id || undefined,
        category_id: params.category_id || undefined,
        group_id: artistId,
        user_type: 'artist',
        reset: true,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, artistId, params]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            コンテンツ一覧 | {fanclub?.name ?? ''} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      <AdminContents
        user={me}
        fanclub={fanclub}
        type={params.type}
        createUserId={params.create_user_id}
        categoryId={params.category_id}
        memberList={memberFieldWithUnselected}
        categoryList={categoryFieldWithUnselected}
        contents={contents}
        total={pager.total}
        isViewReadMoreButton={!isLastPage}
        onClickRegister={handleRegister}
        onClickEditCategory={handleClickEditCategory}
        onChangeTab={handleChangeTab}
        onChangeMember={handleChangeMember}
        onChangeCategory={handleChangeCategory}
        onClickContent={handleClickContent}
        onDelete={handleDeleteContent}
        onReadMore={handleReadMore}
        onBack={handleBack}
      />
    </>
  );
};

export default AdminContentsContainer;
