/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  addFCMembersEvent,
  withdrawFanclubEvent,
  deleteFCMembersEvent,
  changeFCMembersAuthorityEvent,
  searchUserListEvent,
  cleanup,
} from '../../actions/adminMembersActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { UserWithType } from '../../interfaces/userInterFace';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminMembers from '../../components/admin/adminMembers';

const AdminMembersContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const [text, setText] = useState('');
  const [dialogOpenIndex, setDialogOpenIndex] = useState(-1);
  const {
    ready: authReady,
    profile: {
      user,
      fanclubs: { manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const {
    ready,
    details: { members },
    searches,
    loading,
    success,
  } = useSelector(selector.adminMembers.getAdminMembersState);

  const fanclub = useMemo(
    () => manages.find(manage => manage.group_id === artistId),
    [artistId, manages],
  );
  const me: UserWithType = useMemo(() => {
    const type = fanclub?.pivot?.type || null;

    return { ...user, type };
  }, [fanclub, user]);
  const memberList: UserWithType[] = useMemo(
    () =>
      members
        .filter(user => user.id !== me.id)
        .map(user => ({
          ...user,
          type: user?.pivot?.type !== undefined ? user.pivot.type : null,
        })),
    [me.id, members],
  );
  const searchList = useMemo(() => {
    const list = searches.filter(searchUser => {
      if (searchUser.id === me.id) return false;

      const isAlreadyMember = memberList.find(
        member => member.id === searchUser.id,
      );
      if (isAlreadyMember) return false;

      if (
        searchUser.fanclubs.length &&
        searchUser.fanclubs[0].pivot?.type !== 0
      )
        return false;

      return true;
    });

    return list;
  }, [me.id, memberList, searches]);

  const handleCloseDialog = useCallback(() => {
    setDialogOpenIndex(-1);
  }, []);
  const handleChangeDialogOpen = useCallback((index: number) => {
    setDialogOpenIndex(i => (i === index ? -1 : index));
  }, []);
  const handleTransferAuthority = useCallback(
    (userId: string) => {
      if (!artistId) return;

      const res = window.confirm('本当に譲渡しますか？');

      setDialogOpenIndex(-1);
      if (!res) return;

      dispatch(
        changeFCMembersAuthorityEvent.start({
          artistId,
          member: userId,
        }),
      );
    },
    [dispatch, artistId],
  );
  const handleDeleteMember = useCallback(
    (userId: string) => {
      if (!artistId) return;

      if (me.name_id === userId && me.type === 3) {
        window.alert(
          'コミュニティを脱退するには管理者権限を他のユーザーへ譲渡してください。',
        );

        return;
      }

      if (me.name_id === userId) {
        const res = window.confirm('本当に脱退しますか？');
        if (!res) return;

        dispatch(
          withdrawFanclubEvent.start({
            artistId,
          }),
        );

        return;
      }

      const res = window.confirm('本当に削除しますか？');

      setDialogOpenIndex(-1);

      if (!res) return;

      dispatch(
        deleteFCMembersEvent.start({
          artistId,
          delete_members: [userId],
        }),
      );
    },
    [dispatch, artistId, me.name_id, me.type],
  );
  const handleChangeSearchText = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setText(e.currentTarget.value);
    },
    [],
  );
  const handleAddFanclubMember = useCallback(
    (userId: string) => {
      dispatch(
        addFCMembersEvent.start({
          artistId: artistId || '',
          new_members: [userId],
        }),
      );
    },
    [dispatch, artistId],
  );
  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!artistId || !fanclub) {
      history.replace(PATH.HOME);

      return;
    }

    dispatch(getReady(artistId));
  }, [authReady, dispatch, fanclub, artistId, history]);

  useEffect(() => {
    if (text === '' || !artistId) return;

    dispatch(
      searchUserListEvent.start({
        keyword: text,
        fanclub: artistId,
      }),
    );
  }, [dispatch, artistId, text]);

  useEffect(() => {
    if (!success || !artistId) return;

    history.replace(PATH.getArtistPath(artistId));
  }, [history, artistId, success]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            メンバー管理 | {fanclub?.name ?? ''} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      <AdminMembers
        fanclubName={fanclub?.site_name}
        me={me}
        memberList={memberList}
        openDialogIndex={dialogOpenIndex}
        handleCloseDialog={handleCloseDialog}
        handleChangeDialogOpen={handleChangeDialogOpen}
        handleDeleteMember={handleDeleteMember}
        handleTransferAuthority={handleTransferAuthority}
        isViewAddMembers={fanclub?.pivot?.type === 3}
        text={text}
        userList={searchList}
        handleChangeSearchText={handleChangeSearchText}
        handleAddFanclubMember={handleAddFanclubMember}
        handleBack={handleBack}
      />
    </>
  );
};

export default AdminMembersContainer;
