import { Reducer } from 'redux';
import { Actions } from '../actions/contentActions';
import * as ActionType from '../actions/contentConstants';
import {
  ContentDetails,
  ContentDetailsInitialValue,
  ContentPhoto,
  ContentBlog,
  OpenArea,
} from '../interfaces/contentInterFace';
import {
  Fanclub,
  FanclubInitialValue,
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../interfaces/fanclubInterFace';
import { likedUser } from '../interfaces/likesInterFace';

type State = {
  contentDetails: ContentDetails;
  fanclubDetails: FanclubDetails;
  recentPhotos: ContentPhoto[];
  recentBlogs: ContentBlog[];
  recommendFanclubs: Fanclub[];
  ready: boolean;
  loading: boolean;
  error: {
    fanclub: Fanclub;
    open_area: 0 | OpenArea;
  };
  data: likedUser[];
};

export const initialState: State = {
  contentDetails: ContentDetailsInitialValue,
  fanclubDetails: FanclubDetailsInitialValue,
  recentPhotos: [],
  recentBlogs: [],
  recommendFanclubs: [],
  ready: false,
  loading: false,
  error: {
    fanclub: FanclubInitialValue,
    open_area: 0,
  },
  data: [],
};

const contentReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...initialState,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get content details
    case ActionType.GET_CONTENT_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_CONTENT_DETAILS_SUCCEED: {
      return {
        ...state,
        contentDetails: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_CONTENT_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    // get fanclub details
    case ActionType.GET_FANCLUB_DETAILS_START: {
      return {
        ...state,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_SUCCEED: {
      return {
        ...state,
        fanclubDetails: action.payload.result,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_FAIL: {
      return {
        ...state,
      };
    }

    // get recent content photo list
    case ActionType.GET_RECENT_CONTENT_PHOTO_LIST_START: {
      return {
        ...state,
      };
    }
    case ActionType.GET_RECENT_CONTENT_PHOTO_LIST_SUCCEED: {
      return {
        ...state,
        recentPhotos: action.payload.result.data,
      };
    }
    case ActionType.GET_RECENT_CONTENT_PHOTO_LIST_FAIL: {
      return {
        ...state,
      };
    }

    // get recent content blog list
    case ActionType.GET_RECENT_CONTENT_BLOG_LIST_START: {
      return {
        ...state,
      };
    }
    case ActionType.GET_RECENT_CONTENT_BLOG_LIST_SUCCEED: {
      return {
        ...state,
        recentBlogs: action.payload.result.data,
      };
    }
    case ActionType.GET_RECENT_CONTENT_BLOG_LIST_FAIL: {
      return {
        ...state,
      };
    }

    // get recommend fanclub list
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_START: {
      return {
        ...state,
      };
    }
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_SUCCEED: {
      return {
        ...state,
        recommendFanclubs: action.payload.result.data,
      };
    }
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_FAIL: {
      return {
        ...state,
      };
    }

    // like
    case ActionType.LIKE_START: {
      const { type } = action.payload;

      const is_like = type === 'like' ? 1 : 0;
      const total_like =
        state.contentDetails.detail.total_like + (type === 'like' ? 1 : -1);

      return {
        ...state,
        contentDetails: {
          ...state.contentDetails,
          is_like,
          detail: {
            ...state.contentDetails.detail,
            total_like,
          },
        },
      };
    }
    case ActionType.LIKE_SUCCEED: {
      const { is_like, total_like } = action.payload.result;

      return {
        ...state,
        contentDetails: {
          ...state.contentDetails,
          is_like,
          detail: {
            ...state.contentDetails.detail,
            total_like,
          },
        },
      };
    }
    case ActionType.LIKE_FAIL: {
      const { type } = action.payload.params;

      const is_like = type === 'like' ? 0 : 1;
      const total_like =
        state.contentDetails.detail.total_like + (type === 'like' ? -1 : 1);

      return {
        ...state,
        contentDetails: {
          ...state.contentDetails,
          is_like,
          detail: {
            ...state.contentDetails.detail,
            total_like,
          },
        },
      };
    }

    // いいねしたユーザー取得
    case ActionType.GET_LIKED_USERS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_LIKED_USERS_SUCCEED: {
      return {
        ...state,
        data: action.payload.data,
        loading: false,
      };
    }
    case ActionType.GET_LIKED_USERS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // freejoin fanclub
    case ActionType.FREEJOIN_FANCLUB_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.FREEJOIN_FANCLUB_SUCCEED: {
      return {
        ...state,
        loading: false,
        error: initialState.error,
      };
    }
    case ActionType.FREEJOIN_FANCLUB_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default contentReducer;
