/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, takeLeading } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/changeEmailConstants';
import { checkAndSetStatusError } from './authSagas';
import { getUserProfileEvent as updateMe } from '../actions/authActions';
import { changeEmailEvent } from '../actions/changeEmailActions';

import { Status } from '../interfaces/commonInterFace';
import {
  ChangeEmailResponse,
  ChangeEmailErrorResponse,
} from '../interfaces/emailInterFace';

// change email
const changeEmailHandler = api.getPostFactory(true);
function* runChangeEmail(
  handler: typeof changeEmailHandler,
  action: ReturnType<typeof changeEmailEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: ChangeEmailResponse = yield call(
      handler,
      data,
      '/user/email/change',
    );

    if (!ReturnData) {
      yield put(changeEmailEvent.fail({}));

      return;
    }

    // update my profile
    yield put(updateMe.start());

    yield put(changeEmailEvent.succeed());
  } catch (error) {
    const res = error.response.data as ChangeEmailErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(changeEmailEvent.fail(res.errors));

      return;
    }

    yield put(changeEmailEvent.fail({}));
  }
}
export function* changeEmail(handler: typeof changeEmailHandler) {
  yield takeLeading(ActionType.CHANGE_EMAIL_START, runChangeEmail, handler);
}

export const changeEmailSagas = [fork(changeEmail, changeEmailHandler)];
