import { Reducer } from 'redux';
import { Actions } from '../actions/adminFCClosedActions';
import * as ActionType from '../actions/adminFCClosedConstants';
import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../interfaces/fanclubInterFace';

type State = {
  detail: FanclubDetails;
  ready: boolean;
  loading: boolean;
};

const initialState: State = {
  detail: FanclubDetailsInitialValue,
  ready: false,
  loading: false,
};

const adminFCClosedReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fanclub details
    case ActionType.GET_FANCLUB_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_SUCCEED: {
      return {
        ...state,
        detail: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // delete fanclub
    case ActionType.CLOSED_FANCLUB_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.CLOSED_FANCLUB_SUCCEED: {
      return {
        ...initialState,
      };
    }
    case ActionType.CLOSED_FANCLUB_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      return state;
    }
  }
};

export default adminFCClosedReducer;
