/**
 * JASRACの作品データベース検索サービスへ遷移
 */
export const openJASRACDatabase = () => {
  const href = 'http://www2.jasrac.or.jp/eJwid/';

  window.open(
    href,
    'window',
    'width=550, height=450, personalbar=0, toolbar=0, scrollbars=1',
  );
};
