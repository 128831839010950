import { Reducer } from 'redux';
import { Actions } from '../actions/artistActions';
import * as ActionType from '../actions/artistConstants';
import {
  FanclubDetails,
  FanclubDetailsInitialValue,
  Fanclub,
} from '../interfaces/fanclubInterFace';
import {
  ContentListWithPager,
  ContentListWithPagerInitialValue,
} from '../interfaces/contentInterFace';

type State = {
  details: FanclubDetails;
  contents: ContentListWithPager;
  recommendFanclubs: Fanclub[];
  unreadChatCount: number;
  ready: boolean;
  loading: boolean;
  error: boolean;
};

export const initialState: State = {
  details: FanclubDetailsInitialValue,
  contents: ContentListWithPagerInitialValue,
  recommendFanclubs: [],
  unreadChatCount: 0,
  ready: false,
  loading: false,
  error: false,
};

const artistReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fanclub details
    case ActionType.GET_FANCLUB_DETAILS_START: {
      return {
        ...state,
        error: false,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_SUCCEED: {
      return {
        ...state,
        details: action.payload.result,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_FAIL: {
      return {
        ...state,
        error: true,
      };
    }

    // get content list
    case ActionType.GET_CONTENT_LIST_START: {
      const { reset } = action.payload;

      return {
        ...state,
        contents: reset ? initialState.contents : state.contents,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_CONTENT_LIST_SUCCEED: {
      const { reset } = action.payload.params;
      const { data, ...pager } = action.payload.result;
      const list = reset ? data : [...state.contents.list, ...data];

      return {
        ...state,
        contents: {
          ...state.contents,
          list,
          pager,
        },
        loading: false,
      };
    }
    case ActionType.GET_CONTENT_LIST_FAIL: {
      return {
        ...state,
        contents: initialState.contents,
        loading: false,
        error: true,
      };
    }

    // get fanclub list
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_START: {
      return {
        ...state,
        error: false,
      };
    }
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_SUCCEED: {
      return {
        ...state,
        recommendFanclubs: action.payload.result.data,
      };
    }
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_FAIL: {
      return {
        ...state,
        error: true,
      };
    }

    // freejoin fanclub
    case ActionType.FREEJOIN_FANCLUB_START: {
      return {
        ...state,
        details: {
          ...state.details,
          user_type: 0,
          followerCount: state.details.followerCount + 1,
        },
      };
    }
    case ActionType.FREEJOIN_FANCLUB_SUCCEED: {
      return {
        ...state,
      };
    }
    case ActionType.FREEJOIN_FANCLUB_FAIL: {
      return {
        ...state,
        details: {
          ...state.details,
          user_type: 99,
          followerCount: state.details.followerCount - 1,
        },
      };
    }

    // withdraw fanclub
    case ActionType.WITHDRAW_FANCLUB_START: {
      return {
        ...state,
        details: {
          ...state.details,
          user_type: 99,
          followerCount: state.details.followerCount - 1,
        },
      };
    }
    case ActionType.WITHDRAW_FANCLUB_SUCCEED: {
      return {
        ...state,
      };
    }
    case ActionType.WITHDRAW_FANCLUB_FAIL: {
      return {
        ...state,
        details: {
          ...state.details,
          user_type: 0,
          followerCount: state.details.followerCount + 1,
        },
      };
    }

    // チャットの未読件数取得
    case ActionType.GET_UNREAD_CHAT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_UNREAD_CHAT_SUCCEED: {
      return {
        ...state,
        unreadChatCount: action.payload,
        loading: false,
      };
    }
    case ActionType.GET_UNREAD_CHAT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default artistReducer;
