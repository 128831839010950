import React, { FC } from 'react';
import ClassNames from 'classnames';

import TitleH3 from '../../atoms/title/TitleH3';

import OrgFCListUnitRecentImages from '../listUnitParts/OrgFCListUnitRecentImages';

import { ContentPhoto } from '../../../../interfaces/contentInterFace';

import * as styles from './OrgRecentImagesFCList.module.scss';

type Props = {
  photoList?: Partial<ContentPhoto>[];
  onClickContent?: (artistId: string, contentId: number) => void;
  cssClass?: string;
};

const OrgRecentImagesFCList: FC<Props> = ({
  photoList = [],
  onClickContent = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListBase, cssClass);

  return (
    <div className={classNames}>
      <TitleH3
        text="このアーティストの最近の画像"
        cssClass={styles.OrgFCListTitle}
      />
      <ul className={styles.OrgFCListWrap}>
        {photoList.map((item, i) => (
          <OrgFCListUnitRecentImages
            key={`OrgFCListUnitRecentImages${i.toString()}`}
            content={item}
            onClick={() =>
              onClickContent(item.fanclub?.group_id ?? '', item.id ?? 0)
            }
          />
        ))}
      </ul>
    </div>
  );
};

export default OrgRecentImagesFCList;
