/* eslint-disable no-nested-ternary */
import React, { FC, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getReady, cleanup } from '../../actions/adminLikesActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { Content } from '../../interfaces/contentInterFace';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminLikes from '../../components/admin/adminLikes';

const AdminContentsContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const {
    ready: authReady,
    profile: {
      fanclubs: { manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const { ready, likes } = useSelector(selector.adminLikes.getAdminLikesState);
  const fanclub = useMemo(() => manages.find(f => f.group_id === artistId), [
    artistId,
    manages,
  ]);

  const handleClickContent = useCallback(
    (content: Content) => {
      if (!artistId) return;

      // 1:blog, 2:photo, 3:movie, 4:voice
      const typeName =
        content.type === 1
          ? 'blog'
          : content.type === 2
          ? 'photo'
          : content.type === 3
          ? 'movie'
          : content.type === 4
          ? 'voice'
          : '';

      if (!typeName) return;

      history.push(PATH.getContentPath(artistId, content.id));
    },
    [artistId, history],
  );
  const handleBack = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'MENU'));
  }, [artistId, history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!artistId || !fanclub) {
      history.replace(PATH.HOME);

      return;
    }

    dispatch(getReady(artistId));
  }, [authReady, dispatch, fanclub, artistId, history]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>いいね一覧 | {fanclub?.name} | コミュニティ管理 | KRAP</title>
        )}
      </Helmet>

      <OrgLoader isLoaded={authReady && ready} />

      <AdminLikes
        fanclub={fanclub}
        contents={likes}
        onClickContent={handleClickContent}
        onBack={handleBack}
      />
    </>
  );
};

export default AdminContentsContainer;
