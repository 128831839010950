import { Reducer } from 'redux';
import { Actions } from '../actions/twitterActions';
import * as ActionType from '../actions/twitterConstants';
import {
  TwitterAuth,
  TwitterAuthInitialValue,
} from '../interfaces/twitterInterFace';

type State = {
  auth: TwitterAuth;
  loading: boolean;
  getTwitterAuthUrlError: boolean;
  getTwitterAuthUrlSuccess: boolean;
  authenticateError: boolean;
  authenticateSuccess: boolean;
  message: 'login' | 'create';
};

export const initialState: State = {
  auth: TwitterAuthInitialValue,
  loading: false,
  getTwitterAuthUrlError: false,
  getTwitterAuthUrlSuccess: false,
  authenticateError: false,
  authenticateSuccess: false,
  message: 'login',
};

const twitterReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // get twitter auth url
    case ActionType.GET_TWITTER_AUTH_URL_START: {
      return {
        ...state,
        loading: true,
        getTwitterAuthUrlError: false,
      };
    }
    case ActionType.GET_TWITTER_AUTH_URL_SUCCEED: {
      return {
        ...state,
        auth: action.payload,
        loading: false,
        getTwitterAuthUrlSuccess: true,
      };
    }
    case ActionType.GET_TWITTER_AUTH_URL_FAIL: {
      return {
        ...state,
        loading: false,
        getTwitterAuthUrlError: true,
      };
    }

    // authenticate with twitter
    case ActionType.AUTHENTICATE_START: {
      return {
        ...state,
        loading: true,
        authenticateError: false,
      };
    }
    case ActionType.AUTHENTICATE_SUCCEED: {
      return {
        ...state,
        loading: false,
        authenticateSuccess: true,
        message: action.payload,
      };
    }
    case ActionType.AUTHENTICATE_FAIL: {
      return {
        ...state,
        loading: false,
        authenticateError: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default twitterReducer;
