export const GET_READY = 'ADMIN_BLOCKS/GET_READY' as const;
export const GOT_READY = 'ADMIN_BLOCKS/GOT_READY' as const;

export const GET_FC_BLOCK_LIST_START = 'ADMIN_BLOCKS/GET_FC_BLOCK_LIST_START' as const;
export const GET_FC_BLOCK_LIST_SUCCEED = 'ADMIN_BLOCKS/GET_FC_BLOCK_LIST_SUCCEED' as const;
export const GET_FC_BLOCK_LIST_FAIL = 'ADMIN_BLOCKS/GET_FC_BLOCK_LIST_FAIL' as const;

export const UNBLOCK_USER_START = 'ADMIN_BLOCKS/UNBLOCK_USER_START' as const;
export const UNBLOCK_USER_SUCCEED = 'ADMIN_BLOCKS/UNBLOCK_USER_SUCCEED' as const;
export const UNBLOCK_USER_FAIL = 'ADMIN_BLOCKS/UNBLOCK_USER_FAIL' as const;

export const CLEANUP = 'ADMIN_BLOCKS/CLEANUP' as const;
