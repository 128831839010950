export const GET_READY = 'PAIDJOIN/GET_READY' as const;
export const GOT_READY = 'PAIDJOIN/GOT_READY' as const;

export const GET_FANCLUB_DETAILS_START = 'PAIDJOIN/GET_FANCLUB_DETAILS_START' as const;
export const GET_FANCLUB_DETAILS_SUCCEED = 'PAIDJOIN/GET_FANCLUB_DETAILS_SUCCEED' as const;
export const GET_FANCLUB_DETAILS_FAIL = 'PAIDJOIN/GET_FANCLUB_DETAILS_FAIL' as const;

export const GET_CARD_INFO_START = 'PAIDJOIN/GET_CARD_INFO_START' as const;
export const GET_CARD_INFO_SUCCEED = 'PAIDJOIN/GET_CARD_INFO_SUCCEED' as const;
export const GET_CARD_INFO_FAIL = 'PAIDJOIN/GET_CARD_INFO_FAIL' as const;

export const PAIDJOIN_FANCLUB_START = 'PAIDJOIN/PAIDJOIN_FANCLUB_START' as const;
export const PAIDJOIN_FANCLUB_SUCCEED = 'PAIDJOIN/PAIDJOIN_FANCLUB_SUCCEED' as const;
export const PAIDJOIN_FANCLUB_FAIL = 'PAIDJOIN/PAIDJOIN_FANCLUB_FAIL' as const;

export const RESET_API_STATUS = 'PAIDJOIN/RESET_API_STATUS' as const;

export const CLEANUP = 'PAIDJOIN/CLEANUP' as const;
