/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, takeLatest, takeLeading } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/openFCCompleteConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  searchUserListEvent,
  addFanclubMembersEvent,
} from '../actions/openFCCompleteActions';

import { Status, ErrorResponse } from '../interfaces/commonInterFace';
import {
  AddFanclubMembersResponse,
  AddFanclubMembersErrorResponse,
} from '../interfaces/memberInterFace';
import { SearchUserListResponse } from '../interfaces/searchInterFace';

// search user list
const searchUserListHandler = api.getGetFactory(true);
function* runSearchUserList(
  handler: typeof searchUserListHandler,
  action: ReturnType<typeof searchUserListEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: SearchUserListResponse = yield call(
      handler,
      data,
      '/search/user',
    );

    if (!ReturnData) {
      yield put(searchUserListEvent.fail());

      return;
    }

    yield put(searchUserListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(searchUserListEvent.fail());
  }
}
export function* searchUserList(handler: typeof searchUserListHandler) {
  yield takeLatest(
    ActionType.SEARCH_USER_LIST_START,
    runSearchUserList,
    handler,
  );
}

// add fanclub members
const addFanclubMembersHandler = api.getPostFactory(true);
function* runAddFanclubMembers(
  handler: typeof addFanclubMembersHandler,
  action: ReturnType<typeof addFanclubMembersEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: AddFanclubMembersResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/member`,
    );

    if (!ReturnData) {
      yield put(addFanclubMembersEvent.fail({}));

      return;
    }

    yield put(addFanclubMembersEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as AddFanclubMembersErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(addFanclubMembersEvent.fail(res.errors));

      return;
    }

    yield put(addFanclubMembersEvent.fail({}));
  }
}
export function* addFanclubMembers(handler: typeof addFanclubMembersHandler) {
  yield takeLeading(
    ActionType.ADD_FANCLUB_MEMBERS_START,
    runAddFanclubMembers,
    handler,
  );
}

export const openFCCompleteSagas = [
  fork(addFanclubMembers, addFanclubMembersHandler),
  fork(searchUserList, searchUserListHandler),
];
