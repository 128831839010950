import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './LabelTitleParallel.module.scss';

interface Props {
  mainText?: string;
  subText?: string;
  type?: string;
  cssClass?: string;
}

const Label: FC<Props> = ({
  mainText = 'Text',
  subText = 'SubText',
  type = 'Primary',
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.LabelBase, cssClass, {
    [styles.LabelPrimary]: type === 'Primary', // Primary
    [styles.LabelSecondary]: type === 'Secondary', // Secondary
    [styles.LabelTertiary]: type === 'Tertiary', // Tertiary
  });

  return (
    <div className={classNames}>
      {mainText}
      <span>{subText}</span>
    </div>
  );
};

export default Label;
