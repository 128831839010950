/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';

import OrgUserListUnitMembernQuinary from '../common/organisms/listUnitParts/OrgUserListUnitMembernQuinary';

import { User } from '../../interfaces/userInterFace';

import * as styles from './adminBlocks.module.scss';

type Props = {
  fanclubName?: string;
  blockList?: User[];
  isAdmin?: boolean;
  handleClickUser?: (userId: string) => void;
  handleUnblockUser?: (userId: string) => void;
  handleBack?: () => void;
};

const Component: FC<Props> = ({
  fanclubName = '',
  blockList = [],
  isAdmin = false,
  handleClickUser = () => undefined,
  handleUnblockUser = () => undefined,
  handleBack = () => undefined,
}) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      <McsTitleSecondary
        mainText="ブロックリスト"
        subText={fanclubName}
        onClick={handleBack}
        cssClass={styles.contentTitle}
      />

      <div className={styles.contentWrap}>
        <ul className={styles.blockListContainer}>
          {blockList.map(follower => (
            <OrgUserListUnitMembernQuinary
              isPIcon={follower?.pivot?.type === 1}
              user={follower}
              onClickUser={() => handleClickUser(follower.name_id)}
              onClickButton={() => handleUnblockUser(follower.name_id)}
              tagText="ブロック解除"
              isButtonHidden={!isAdmin}
              cssClass={styles.blockWrapper}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Component;
