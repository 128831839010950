export const GET_FIND_FANCLUB_LIST_START = 'SEARCH/GET_FIND_FANCLUB_LIST_START' as const;
export const GET_FIND_FANCLUB_LIST_SUCCEED = 'SEARCH/GET_FIND_FANCLUB_LIST_SUCCEED' as const;
export const GET_FIND_FANCLUB_LIST_FAIL = 'SEARCH/GET_FIND_FANCLUB_LIST_FAIL' as const;

export const FREEJOIN_FANCLUB_START = 'SEARCH/FREEJOIN_FANCLUB_START' as const;
export const FREEJOIN_FANCLUB_SUCCEED = 'SEARCH/FREEJOIN_FANCLUB_SUCCEED' as const;
export const FREEJOIN_FANCLUB_FAIL = 'SEARCH/FREEJOIN_FANCLUB_FAIL' as const;

export const WITHDRAW_FANCLUB_START = 'SEARCH/WITHDRAW_FANCLUB_START' as const;
export const WITHDRAW_FANCLUB_SUCCEED = 'SEARCH/WITHDRAW_FANCLUB_SUCCEED' as const;
export const WITHDRAW_FANCLUB_FAIL = 'SEARCH/WITHDRAW_FANCLUB_FAIL' as const;

export const CLEANUP = 'SEARCH/CLEANUP' as const;
