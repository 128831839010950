import * as ActionType from './reissuePasswordSendEmailConstants';
import { SendEmailParams } from '../interfaces/passwordInterFace';

export const sendEmailEvent = {
  start: (params: SendEmailParams) =>
    ({
      type: ActionType.SEND_EMAIL_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.SEND_EMAIL_SUCCEED,
    } as const),

  fail: (message: string) =>
    ({
      type: ActionType.SEND_EMAIL_FAIL,
      payload: message,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // send email
  | ReturnType<typeof sendEmailEvent.start>
  | ReturnType<typeof sendEmailEvent.succeed>
  | ReturnType<typeof sendEmailEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
