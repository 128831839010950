/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import TitleH3 from '../../atoms/title/TitleH3';

import OrgFCListUnitTertiary from '../listUnitParts/OrgFCListUnitTertiary';

import { FanclubWithUserInfo } from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgPickUpForYouFCList.module.scss';

type Props = {
  fanclubList?: Partial<FanclubWithUserInfo>[];
  onClickFollow?: (artistId: string) => void;
  cssClass?: string;
};

const OrgFCList: FC<Props> = ({
  fanclubList = [],
  onClickFollow = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListBase, cssClass);

  return (
    <div className={classNames}>
      <TitleH3
        text="あなたへおすすめのアーティスト"
        cssClass={styles.OrgFCListTitle}
      />
      <ul className={styles.OrgFCListWrap}>
        {fanclubList.map((item, i) => (
          <OrgFCListUnitTertiary
            fanclub={item}
            key={`OrgFCListUnitTertiary${i}`}
            isFollowButton={!item.managed}
            isFollow={item.followed}
            onClickFollow={() => onClickFollow(item.group_id ?? '')}
          />
        ))}
      </ul>
    </div>
  );
};

export default OrgFCList;
