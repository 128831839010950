import React, { FC, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import { withdrawEvent, cleanup } from '../../actions/withdrawActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import Withdraw from '../../components/setting/withdraw';

const WithdrawContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    ready: authReady,
    profile: { user },
  } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const { loading, errors, success } = useSelector(
    selector.withdraw.getWithdrawState,
  );

  const handleBack = useCallback(() => {
    history.push(PATH.SETTING_MENU);
  }, [history]);
  const handleWithdraw = useCallback(() => {
    dispatch(withdrawEvent.start());
  }, [dispatch]);
  const handleClickAdminFC = useCallback(
    (artistId: string) => {
      if (!artistId) return;

      history.push(PATH.getArtistPath(artistId));
    },
    [history],
  );
  const handleClickPaidFC = useCallback(() => {
    history.push(PATH.SETTING_MENU);
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (authReady && !isLogin) {
      history.push(PATH.SIGNUP);
    }
  }, [authReady, history, isLogin]);

  useEffect(() => {
    if (success) history.replace(PATH.HOME);
  }, [history, success]);

  return (
    <>
      <Helmet>
        <title>退会 | 設定 | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}

      <Withdraw
        userId={user.name_id}
        onBackEvent={handleBack}
        onWithdrawEvent={handleWithdraw}
        onClickAdminFC={handleClickAdminFC}
        onClickPaidFC={handleClickPaidFC}
        adminList={errors.admin}
        paidList={errors.paid}
      />
    </>
  );
};

export default WithdrawContainer;
