import * as ActionType from './adminVoiceRegisterConstants';
import {
  GetFanclubDetailsParams,
  GetFanclubDetailsResult,
} from '../interfaces/fanclubInterFace';
import {
  CreateVoiceParams,
  CreateVoiceResult,
  CreateVoiceErrors,
  GetContentDetailsParams,
  GetContentDetailsResult,
  UpdateVoiceParams,
  UpdateVoiceResult,
  UpdateVoiceErrors,
  DeleteVoiceParams,
} from '../interfaces/contentInterFace';
import {
  AddFCCategoryErrors,
  AddFCCategoryParams,
  AddFCCategoryResult,
  GetFCCategoryListParams,
  GetFCCategoryListResult,
} from '../interfaces/categoryInterFace';

export const getReady = (artistId: string, contentId: number) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId, contentId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getFanclubDetailsEvent = {
  start: (params: GetFanclubDetailsParams) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetFanclubDetailsResult) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_FAIL,
    } as const),
};

export const getVoiceEvent = {
  start: (params: GetContentDetailsParams) =>
    ({
      type: ActionType.GET_VOICE_START,
      payload: params,
    } as const),

  succeed: (result: GetContentDetailsResult) =>
    ({
      type: ActionType.GET_VOICE_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_VOICE_FAIL,
    } as const),
};

export const getFCCategoryListEvent = {
  start: (params: GetFCCategoryListParams) =>
    ({
      type: ActionType.GET_FC_CATEGORY_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetFCCategoryListResult) =>
    ({
      type: ActionType.GET_FC_CATEGORY_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FC_CATEGORY_LIST_FAIL,
    } as const),
};

export const addFCCategoryEvent = {
  start: (params: AddFCCategoryParams) =>
    ({
      type: ActionType.ADD_FC_CATEGORY_START,
      payload: params,
    } as const),

  succeed: (params: AddFCCategoryParams, result: AddFCCategoryResult) =>
    ({
      type: ActionType.ADD_FC_CATEGORY_SUCCEED,
      payload: { params, result },
    } as const),

  fail: (errors: AddFCCategoryErrors) =>
    ({
      type: ActionType.ADD_FC_CATEGORY_FAIL,
      payload: { errors },
    } as const),
};

export const createVoiceEvent = {
  start: (params: CreateVoiceParams) =>
    ({
      type: ActionType.CREATE_VOICE_START,
      payload: params,
    } as const),

  succeed: (result: CreateVoiceResult) =>
    ({
      type: ActionType.CREATE_VOICE_SUCCEED,
      payload: { result },
    } as const),

  fail: (errors: CreateVoiceErrors) =>
    ({
      type: ActionType.CREATE_VOICE_FAIL,
      payload: { errors },
    } as const),
};

export const updateVoiceEvent = {
  start: (params: UpdateVoiceParams) =>
    ({
      type: ActionType.UPDATE_VOICE_START,
      payload: params,
    } as const),

  succeed: (result: UpdateVoiceResult) =>
    ({
      type: ActionType.UPDATE_VOICE_SUCCEED,
      payload: { result },
    } as const),

  fail: (errors: UpdateVoiceErrors) =>
    ({
      type: ActionType.UPDATE_VOICE_FAIL,
      payload: { errors },
    } as const),
};

export const deleteVoiceEvent = {
  start: (params: DeleteVoiceParams) =>
    ({
      type: ActionType.DELETE_VOICE_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.DELETE_VOICE_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.DELETE_VOICE_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fanclub details
  | ReturnType<typeof getFanclubDetailsEvent.start>
  | ReturnType<typeof getFanclubDetailsEvent.succeed>
  | ReturnType<typeof getFanclubDetailsEvent.fail>
  // get voice event
  | ReturnType<typeof getVoiceEvent.start>
  | ReturnType<typeof getVoiceEvent.succeed>
  | ReturnType<typeof getVoiceEvent.fail>
  // get fc category list
  | ReturnType<typeof getFCCategoryListEvent.start>
  | ReturnType<typeof getFCCategoryListEvent.succeed>
  | ReturnType<typeof getFCCategoryListEvent.fail>
  // add fc category
  | ReturnType<typeof addFCCategoryEvent.start>
  | ReturnType<typeof addFCCategoryEvent.succeed>
  | ReturnType<typeof addFCCategoryEvent.fail>
  // create voice
  | ReturnType<typeof createVoiceEvent.start>
  | ReturnType<typeof createVoiceEvent.succeed>
  | ReturnType<typeof createVoiceEvent.fail>
  // update voice
  | ReturnType<typeof updateVoiceEvent.start>
  | ReturnType<typeof updateVoiceEvent.succeed>
  | ReturnType<typeof updateVoiceEvent.fail>
  // delete voice
  | ReturnType<typeof deleteVoiceEvent.start>
  | ReturnType<typeof deleteVoiceEvent.succeed>
  | ReturnType<typeof deleteVoiceEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
