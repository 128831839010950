import * as ActionType from './openFCCompleteConstants';

import {
  AddFanclubMembersParams,
  AddFanclubMembersErrors,
  AddFanclubMembersResult,
} from '../interfaces/memberInterFace';
import {
  SearchUserListParams,
  SearchUserListResult,
} from '../interfaces/searchInterFace';

export const searchUserListEvent = {
  start: (params: SearchUserListParams) =>
    ({
      type: ActionType.SEARCH_USER_LIST_START,
      payload: params,
    } as const),

  succeed: (result: SearchUserListResult) =>
    ({
      type: ActionType.SEARCH_USER_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.SEARCH_USER_LIST_FAIL,
    } as const),
};

export const addFanclubMembersEvent = {
  start: (params: AddFanclubMembersParams) =>
    ({
      type: ActionType.ADD_FANCLUB_MEMBERS_START,
      payload: params,
    } as const),

  succeed: (result: AddFanclubMembersResult) =>
    ({
      type: ActionType.ADD_FANCLUB_MEMBERS_SUCCEED,
      payload: { result },
    } as const),

  fail: (errors: AddFanclubMembersErrors) =>
    ({
      type: ActionType.ADD_FANCLUB_MEMBERS_FAIL,
      payload: { errors },
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // search user list
  | ReturnType<typeof searchUserListEvent.start>
  | ReturnType<typeof searchUserListEvent.succeed>
  | ReturnType<typeof searchUserListEvent.fail>
  // add fanclub members
  | ReturnType<typeof addFanclubMembersEvent.start>
  | ReturnType<typeof addFanclubMembersEvent.succeed>
  | ReturnType<typeof addFanclubMembersEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
