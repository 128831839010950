import { Reducer } from 'redux';
import { Actions } from '../actions/followArtistActions';
import * as ActionType from '../actions/followArtistConstants';
import { Fanclub } from '../interfaces/fanclubInterFace';

type State = {
  recommendFanclubs: Fanclub[];
  ready: boolean;
  loading: boolean;
  error: boolean;
};

export const initialState: State = {
  recommendFanclubs: [],
  ready: false,
  loading: false,
  error: false,
};

const followArtistReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get recommend fanclub list
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_SUCCEED: {
      return {
        ...state,
        recommendFanclubs: action.payload.result.data,
        loading: false,
      };
    }
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // freejoin fanclub
    case ActionType.FREEJOIN_FANCLUB_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.FREEJOIN_FANCLUB_SUCCEED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.FREEJOIN_FANCLUB_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // withdraw fanclub
    case ActionType.WITHDRAW_FANCLUB_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.WITHDRAW_FANCLUB_SUCCEED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.WITHDRAW_FANCLUB_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default followArtistReducer;
