/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import OrgHeroNews from '../common/organisms/heroParts/OrgHeroNews';
import OrgContentBlog from '../common/organisms/contentParts/OrgContentBlog';

import {
  NewsDetails,
  NewsDetailsInitialValue,
} from '../../interfaces/newsInterFace';

import * as styles from './newsDetails.module.scss';

interface Props {
  news?: NewsDetails;
}

const Component: FC<Props> = ({ news = NewsDetailsInitialValue }) => {
  const classNames = ClassNames(styles.container);

  return (
    <div className={classNames}>
      <OrgHeroNews isViewThumbnail detail={news} />

      <OrgContentBlog
        body={news.body}
        photo={news.image}
        cssClass={styles.content}
      />
    </div>
  );
};

export default Component;
