/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import { attachGenresEvent, cleanup } from '../../actions/attachGenresActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { GenreWithSelected } from '../../interfaces/genreInterFace';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import AttachGenres from '../../components/signup/attachGenres';

const AttachGenresContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [selectedGenres, setSelectedGenres] = useState<number[]>([]);
  const genreFields = useSelector(selector.util.getGenreFields);
  const { ready: authReady } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const { loading, success } = useSelector(
    selector.attachGenres.getAttachGenresState,
  );
  const genres: GenreWithSelected[][] = useMemo(
    () =>
      genreFields.map(group =>
        group.map(genre => ({
          id: genre.value,
          name: genre.label,
          selected: selectedGenres.includes(genre.value),
        })),
      ),
    [genreFields, selectedGenres],
  );
  const handleAttachGenres = useCallback(
    (genres: number[]) => {
      if (genres.length < 3) {
        window.alert('ジャンルを3つ以上選んでください');

        return;
      }

      dispatch(attachGenresEvent.start({ genres }));
    },
    [dispatch],
  );

  const handleClickGenre = useCallback((genreId: number) => {
    setSelectedGenres(genres =>
      genres.includes(genreId)
        ? genres.filter(genre => genre !== genreId)
        : [...genres, genreId],
    );
  }, []);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    if (success) {
      history.push(PATH.SIGNUP_FOLLOW_ARTIST, {
        referer: history.referer,
      });
    }
  }, [history, success]);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!isLogin) history.push(PATH.SIGNUP);
  }, [authReady, history, isLogin]);

  if (!isLogin) return null;

  return (
    <>
      <Helmet>
        <title>アカウント作成完了 | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady} />

      <AttachGenres
        genres={genres}
        onChange={handleClickGenre}
        onStartKrapEvent={() => handleAttachGenres(selectedGenres)}
        disable={selectedGenres.length < 3}
      />
    </>
  );
};

export default AttachGenresContainer;
