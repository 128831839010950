/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/adminPhotoRegisterConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getFanclubDetailsEvent,
  getPhotoEvent,
  getFCCategoryListEvent,
  addFCCategoryEvent,
  createPhotoEvent,
  updatePhotoEvent,
  deletePhotoEvent,
} from '../actions/adminPhotoRegisterActions';

import { Status, ErrorResponse } from '../interfaces/commonInterFace';
import { GetFanclubDetailsResponse } from '../interfaces/fanclubInterFace';
import {
  GetContentDetailsResponse,
  CreatePhotoResponse,
  CreatePhotoErrorResponse,
  UpdatePhotoResponse,
  UpdatePhotoErrorResponse,
  DeleteContentResponse,
} from '../interfaces/contentInterFace';
import {
  GetFCCategoryListResponse,
  AddFCCategoryResponse,
  AddFCCategoryErrorResponse,
} from '../interfaces/categoryInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId, contentId } = action.payload;

  const getFanclubDetailsTask = yield fork(
    runGetFanclubDetails,
    getFanclubDetailsHandler,
    getFanclubDetailsEvent.start({ artistId }),
  );
  yield join(getFanclubDetailsTask);

  const getFCCategoryListTask = yield fork(
    runGetFCCategoryList,
    getFCCategoryListHandler,
    getFCCategoryListEvent.start({ artistId }),
  );
  yield join(getFCCategoryListTask);

  if (contentId) {
    const getPhotoTask = yield fork(
      runGetPhoto,
      getPhotoHandler,
      getPhotoEvent.start({ contentId }),
    );
    yield join(getPhotoTask);
  }

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get fanclub details
const getFanclubDetailsHandler = api.getGetFactory(true);
function* runGetFanclubDetails(
  handler: typeof getFanclubDetailsHandler,
  action: ReturnType<typeof getFanclubDetailsEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetFanclubDetailsResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(getFanclubDetailsEvent.fail());

      return;
    }

    yield put(getFanclubDetailsEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFanclubDetailsEvent.fail());
  }
}
export function* getFanclubDetails(handler: typeof getFanclubDetailsHandler) {
  yield takeLatest(
    ActionType.GET_FANCLUB_DETAILS_START,
    runGetFanclubDetails,
    handler,
  );
}

// get photo
const getPhotoHandler = api.getGetFactory(true);
function* runGetPhoto(
  handler: typeof getPhotoHandler,
  action: ReturnType<typeof getPhotoEvent.start>,
) {
  const { contentId } = action.payload;
  try {
    const ReturnData: GetContentDetailsResponse = yield call(
      handler,
      {},
      `/contents/${contentId}`,
    );

    if (!ReturnData) {
      yield put(getPhotoEvent.fail());

      return;
    }

    yield put(getPhotoEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getPhotoEvent.fail());
  }
}
export function* getPhoto(handler: typeof getPhotoHandler) {
  yield takeLatest(ActionType.GET_PHOTO_START, runGetPhoto, handler);
}

// get fc category list
const getFCCategoryListHandler = api.getGetFactory(true);
function* runGetFCCategoryList(
  handler: typeof getFCCategoryListHandler,
  action: ReturnType<typeof getFCCategoryListEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: GetFCCategoryListResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/category`,
    );

    if (!ReturnData) {
      yield put(getFCCategoryListEvent.fail());

      return;
    }

    yield put(getFCCategoryListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFCCategoryListEvent.fail());
  }
}
export function* getFCCategoryList(handler: typeof getFCCategoryListHandler) {
  yield takeLatest(
    ActionType.GET_FC_CATEGORY_LIST_START,
    runGetFCCategoryList,
    handler,
  );
}

// add fc category
const addFCCategoryHandler = api.getPostFactory(true);
function* runAddFCCategory(
  handler: typeof addFCCategoryHandler,
  action: ReturnType<typeof addFCCategoryEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: AddFCCategoryResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/category`,
    );

    if (!ReturnData) {
      yield put(addFCCategoryEvent.fail({}));

      return;
    }

    yield put(addFCCategoryEvent.succeed(action.payload, ReturnData.result));
  } catch (error) {
    const res = error.response.data as AddFCCategoryErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(addFCCategoryEvent.fail(res.errors));

      return;
    }

    yield put(addFCCategoryEvent.fail({}));
  }
}
export function* addFCCategory(handler: typeof addFCCategoryHandler) {
  yield takeLeading(
    ActionType.ADD_FC_CATEGORY_START,
    runAddFCCategory,
    handler,
  );
}

// create photo
const createPhotoHandler = api.getFormDataFactory('POST');
function* runCreatePhoto(
  handler: typeof createPhotoHandler,
  action: ReturnType<typeof createPhotoEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: CreatePhotoResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/photo`,
    );

    if (!ReturnData) {
      yield put(createPhotoEvent.fail({}));

      return;
    }

    yield put(createPhotoEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as CreatePhotoErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(createPhotoEvent.fail(res.errors));

      return;
    }

    yield put(createPhotoEvent.fail({}));
  }
}
export function* createPhoto(handler: typeof createPhotoHandler) {
  yield takeLatest(ActionType.CREATE_PHOTO_START, runCreatePhoto, handler);
}

// update photo
const updatePhotoHandler = api.getFormDataFactory('PATCH');
function* runUpdatePhoto(
  handler: typeof updatePhotoHandler,
  action: ReturnType<typeof updatePhotoEvent.start>,
) {
  const { artistId, contentId, ...data } = action.payload;

  try {
    const ReturnData: UpdatePhotoResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/photo/${contentId}`,
    );

    if (!ReturnData) {
      yield put(updatePhotoEvent.fail({}));

      return;
    }

    yield put(updatePhotoEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as UpdatePhotoErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(updatePhotoEvent.fail(res.errors));

      return;
    }

    yield put(updatePhotoEvent.fail({}));
  }
}
export function* updatePhoto(handler: typeof updatePhotoHandler) {
  yield takeLeading(ActionType.UPDATE_PHOTO_START, runUpdatePhoto, handler);
}

// delete photo
const deletePhotoHandler = api.getDeleteFactory(true);
function* runDeletePhoto(
  handler: typeof deletePhotoHandler,
  action: ReturnType<typeof deletePhotoEvent.start>,
) {
  const { artistId, contentId } = action.payload;
  try {
    const ReturnData: DeleteContentResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/photo/${contentId}`,
    );

    if (!ReturnData) {
      yield put(deletePhotoEvent.fail());

      return;
    }

    yield put(deletePhotoEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(deletePhotoEvent.fail());
  }
}
export function* deletePhoto(handler: typeof deletePhotoHandler) {
  yield takeLeading(ActionType.DELETE_PHOTO_START, runDeletePhoto, handler);
}

export const adminPhotoRegisterSagas = [
  fork(getReady),
  fork(getFanclubDetails, getFanclubDetailsHandler),
  fork(getPhoto, getPhotoHandler),
  fork(getFCCategoryList, getFCCategoryListHandler),
  fork(addFCCategory, addFCCategoryHandler),
  fork(createPhoto, createPhotoHandler),
  fork(updatePhoto, updatePhotoHandler),
  fork(deletePhoto, deletePhotoHandler),
];
