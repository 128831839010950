import { AllState } from '../store';

const getAdminFollowersState = (state: AllState) => {
  return state.adminFollowers;
};

const getIsLastPage = (state: AllState) => {
  return (
    state.adminFollowers.pager.current_page ===
    state.adminFollowers.pager.last_page
  );
};

export default {
  getAdminFollowersState,
  getIsLastPage,
};
