import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAuthSessionStorage } from './utilHelper';
import { getFormData } from './formdataHelper';

const DEFAULT_API_CONFIG: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

/**
 * 銀行の金融機関コード、支店コード検索用
 */
const SEARCH_BANK_API_CONFIG: AxiosRequestConfig = {
  baseURL: 'https://bank.teraren.com',
};

/**
 * 郵便番号から住所を検索する用
 */
const SEARCH_ADDRESS_API_CONFIG: AxiosRequestConfig = {
  baseURL: 'https://geoapi.heartrails.com',
};

export const getGetFactory = (withToken?: boolean) => {
  const config = DEFAULT_API_CONFIG;
  const instance = axios.create(config);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getGet = async (params: any, path: string, status = 200) => {
    try {
      const token = getAuthSessionStorage();
      if (withToken && token) {
        instance.defaults.headers.Authorization = `Bearer ${token}`;
      }

      const response: AxiosResponse = await instance.get(path, {
        params,
      });

      if (response.status !== status) {
        throw new Error('Server Error');
      }

      const { data } = response;

      return data;
    } catch (err) {
      if (err.response === undefined) {
        return false;
      }

      throw err;
    }
  };

  return getGet;
};

export const getPostFactory = (withToken?: boolean) => {
  const config = DEFAULT_API_CONFIG;
  const instance = axios.create(config);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getPost = async (body: any, path: string, status = 200) => {
    try {
      const token = getAuthSessionStorage();
      if (withToken && token) {
        instance.defaults.headers.Authorization = `Bearer ${token}`;
      }

      const response: AxiosResponse = await instance.post(path, body);

      if (response.status !== status) {
        throw new Error('Server Error');
      }

      const { data } = response;

      return data;
    } catch (err) {
      if (err.response === undefined) {
        return false;
      }

      throw err;
    }
  };

  return getPost;
};

// export const getPutFactory = (
//   withToken?: boolean,
//   contentType: 'json' | 'formdata' = 'json',
// ) => {
//   const config = DEFAULT_API_CONFIG;
//   const instance = axios.create(config);

//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   const getPut = async (body: any, path: string, statusCode = 200) => {
//     try {
//       const token = getAuthSessionStorage();
//       if (withToken && token) {
//         instance.defaults.headers.Authorization = `Bearer ${token}`;
//       }

//       if (contentType === 'formdata') {
//         instance.defaults.headers['Content-Type'] = 'multipart/form-data';
//       }

//       const response: AxiosResponse = await instance.put(path, body);

//       if (response.status !== statusCode) {
//         throw new Error('Server Error');
//       }

//       const { data } = response;

//       return data;
//     } catch (err) {
//       if (err.response === undefined) {
//         return false;
//       }

//       throw err;
//     }
//   };

//   return getPut;
// };

export const getPatchFactory = (withToken?: boolean) => {
  const config = DEFAULT_API_CONFIG;
  const instance = axios.create(config);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getPatch = async (body: any, path: string, status = 200) => {
    try {
      const token = getAuthSessionStorage();
      if (withToken && token) {
        instance.defaults.headers.Authorization = `Bearer ${token}`;
      }

      const response: AxiosResponse = await instance.patch(path, body);

      if (response.status !== status) {
        throw new Error('Server Error');
      }

      const { data } = response;

      return data;
    } catch (err) {
      if (err.response === undefined) {
        return false;
      }

      throw err;
    }
  };

  return getPatch;
};

export const getDeleteFactory = (withToken?: boolean) => {
  const config = DEFAULT_API_CONFIG;
  const instance = axios.create(config);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getDelete = async (params: any, path: string, status = 200) => {
    try {
      const token = getAuthSessionStorage();
      if (withToken && token) {
        instance.defaults.headers.Authorization = `Bearer ${token}`;
      }

      const response: AxiosResponse = await instance.delete(path, {
        params,
      });

      if (response.status !== status) {
        throw new Error('Server Error');
      }

      const { data } = response;

      return data;
    } catch (err) {
      if (err.response === undefined) {
        return false;
      }

      throw err;
    }
  };

  return getDelete;
};

type MethodType = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';
export const getFormDataFactory = (method: MethodType) => {
  const config = DEFAULT_API_CONFIG;
  const instance = axios.create(config);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getPost = async (body: any, path: string, status = 200) => {
    try {
      const token = getAuthSessionStorage();
      if (token) {
        instance.defaults.headers.Authorization = `Bearer ${token}`;
      }

      const params = getFormData(body, method);

      instance.defaults.headers['Content-Type'] = 'multipart/form-data';

      const response: AxiosResponse = await instance.post(path, params);

      if (response.status !== status) {
        throw new Error('Server Error');
      }

      const { data } = response;

      return data;
    } catch (err) {
      if (err.response === undefined) {
        return false;
      }

      throw err;
    }
  };

  return getPost;
};

export const getBankFactory = () => {
  const config = SEARCH_BANK_API_CONFIG;
  const instance = axios.create(config);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getGet = async (params: any, path: string, status = 200) => {
    try {
      const response: AxiosResponse = await instance.get(path, {
        params,
      });

      if (response.status !== status) {
        throw new Error('Server Error');
      }

      const { data } = response;

      return data;
    } catch (err) {
      if (err.response === undefined) {
        return false;
      }

      throw err;
    }
  };

  return getGet;
};

export const getAddressFactory = () => {
  const config = SEARCH_ADDRESS_API_CONFIG;
  const instance = axios.create(config);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getGet = async (params: any, path: string, status = 200) => {
    try {
      const response: AxiosResponse = await instance.get(path, {
        params,
      });

      if (response.status !== status) {
        throw new Error('Server Error');
      }

      const { data } = response;

      return data;
    } catch (err) {
      if (err.response === undefined) {
        return false;
      }

      throw err;
    }
  };

  return getGet;
};
