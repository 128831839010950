export const GET_READY = 'ADMIN_PHOTO_REGISTER/GET_READY' as const;
export const GOT_READY = 'ADMIN_PHOTO_REGISTER/GOT_READY' as const;

export const GET_FANCLUB_DETAILS_START = 'ADMIN_PHOTO_REGISTER/GET_FANCLUB_DETAILS_START' as const;
export const GET_FANCLUB_DETAILS_SUCCEED = 'ADMIN_PHOTO_REGISTER/GET_FANCLUB_DETAILS_SUCCEED' as const;
export const GET_FANCLUB_DETAILS_FAIL = 'ADMIN_PHOTO_REGISTER/GET_FANCLUB_DETAILS_FAIL' as const;

export const GET_PHOTO_START = 'ADMIN_PHOTO_REGISTER/GET_PHOTO_START' as const;
export const GET_PHOTO_SUCCEED = 'ADMIN_PHOTO_REGISTER/GET_PHOTO_SUCCEED' as const;
export const GET_PHOTO_FAIL = 'ADMIN_PHOTO_REGISTER/GET_PHOTO_FAIL' as const;

export const GET_FC_CATEGORY_LIST_START = 'ADMIN_PHOTO_REGISTER/GET_FC_CATEGORY_LIST_START' as const;
export const GET_FC_CATEGORY_LIST_SUCCEED = 'ADMIN_PHOTO_REGISTER/GET_FC_CATEGORY_LIST_SUCCEED' as const;
export const GET_FC_CATEGORY_LIST_FAIL = 'ADMIN_PHOTO_REGISTER/GET_FC_CATEGORY_LIST_FAIL' as const;

export const ADD_FC_CATEGORY_START = 'ADMIN_PHOTO_REGISTER/ADD_FC_CATEGORY_START' as const;
export const ADD_FC_CATEGORY_SUCCEED = 'ADMIN_PHOTO_REGISTER/ADD_FC_CATEGORY_SUCCEED' as const;
export const ADD_FC_CATEGORY_FAIL = 'ADMIN_PHOTO_REGISTER/ADD_FC_CATEGORY_FAIL' as const;

export const CREATE_PHOTO_START = 'ADMIN_PHOTO_REGISTER/CREATE_PHOTO_START' as const;
export const CREATE_PHOTO_SUCCEED = 'ADMIN_PHOTO_REGISTER/CREATE_PHOTO_SUCCEED' as const;
export const CREATE_PHOTO_FAIL = 'ADMIN_PHOTO_REGISTER/CREATE_PHOTO_FAIL' as const;

export const UPDATE_PHOTO_START = 'ADMIN_PHOTO_REGISTER/UPDATE_PHOTO_START' as const;
export const UPDATE_PHOTO_SUCCEED = 'ADMIN_PHOTO_REGISTER/UPDATE_PHOTO_SUCCEED' as const;
export const UPDATE_PHOTO_FAIL = 'ADMIN_PHOTO_REGISTER/UPDATE_PHOTO_FAIL' as const;

export const DELETE_PHOTO_START = 'ADMIN_PHOTO_REGISTER/DELETE_PHOTO_START' as const;
export const DELETE_PHOTO_SUCCEED = 'ADMIN_PHOTO_REGISTER/DELETE_PHOTO_SUCCEED' as const;
export const DELETE_PHOTO_FAIL = 'ADMIN_PHOTO_REGISTER/DELETE_PHOTO_FAIL' as const;

export const CLEANUP = 'ADMIN_PHOTO_REGISTER/CLEANUP' as const;
