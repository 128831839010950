/* eslint-disable no-useless-return */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import Linkify from 'react-linkify';

import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';
import LabelTag from '../../atoms/label/LabelTag';

import McsIconLabelRoundSenary from '../../molecules/iconWithLabel/McsIconLabelRoundSenary';

import OrgFCListUnitGroup from '../listUnitParts/OrgFCListUnitGroup';

import noUserImageUrl from '../../assets/images/icon-nouser.png';

import { User, UserInitialValue } from '../../../../interfaces/userInterFace';
import { Fanclub } from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgHeroUser.module.scss';

type PartialUser = Partial<User>;
type PartialFanclub = Partial<Fanclub>;

interface Props {
  cssClass?: string;
  user?: PartialUser;
  manages?: PartialFanclub[];
  prefecture?: string;
  isViewEditButton?: boolean;
  onLinkClick?: () => void;
  onEditClick?: () => void;
}

const OrgHero: FC<Props> = ({
  cssClass = '',
  user = UserInitialValue,
  manages = [],
  prefecture = '',
  isViewEditButton = false,
  onLinkClick = () => undefined,
  onEditClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgHeroBase, cssClass);

  return (
    <div className={classNames}>
      <div className={styles.OrgHeroMain}>
        <div
          className={styles.OrgHeroImage}
          style={{
            background: `url(${user?.cover_image}) ${
              user?.cover_image ? '#ffffff' : '#EB5757'
            } no-repeat center / cover`,
          }}
        />
        {isViewEditButton && (
          <ButtonRoundedCorners
            text="編集する"
            type="Secondary"
            size="Quaternary"
            cssClass={styles.OrgHeroFollowButton}
            onClick={onEditClick}
          />
        )}
        <McsIconLabelRoundSenary
          src={user?.icon_image || ''}
          errorSrc={noUserImageUrl}
          mainText={user?.nickname}
          subText={`@${user?.name_id}`}
          cssClass={styles.OrgHeroThumbnail}
        />
      </div>

      <div className={styles.OrgHeroSub}>
        {!!manages.length && (
          <div className={styles.OrgHeroFollowList}>
            <OrgFCListUnitGroup
              fanclubList={manages}
              onLinkClick={onLinkClick}
            />
          </div>
        )}
        {!!prefecture && (
          <div className={styles.OrgHeroData}>
            <div className={styles.OrgHeroDataPlace}>
              <LabelTag text="活動拠点" type="Secondary" />
              <span>{prefecture}</span>
            </div>
          </div>
        )}
        {/* 紹介文、3ライン以上はタップで全文表示 ※必要  */}
        <div className={styles.OrgHeroDescription}>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {user?.self_introduction}
          </Linkify>
        </div>
      </div>
    </div>
  );
};

export default OrgHero;
