import React, { FC, useState, useCallback, useEffect } from 'react';

import McsHeaderComment from '../common/molecules/headerParts/McsHeaderComment';
import McsInputComment from '../common/molecules/formParts/McsInputComment';

import OrgFCListUnitComment from '../common/organisms/listUnitParts/OrgFCListUnitComment';
import OrgJoinFCModal from '../common/organisms/modalParts/OrgJoinFCModal';

import { User, UserInitialValue } from '../../interfaces/userInterFace';
import {
  Fanclub,
  FanclubInitialValue,
} from '../../interfaces/fanclubInterFace';
import { Comment } from '../../interfaces/commentInterFace';

import * as styles from './comment.module.scss';

type Props = {
  user?: User;
  fanclub?: Fanclub;
  comment?: string;
  isAdmin?: boolean;
  comments?: Comment[];
  handleBack?: () => void;
  handleFollow?: () => void;
  handleClickUser?: (userId: string) => void;
  handleDeleteComment?: (id: number) => void;
  handleChangeComment?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  handleSubmitComment?: () => void;
  isViewFollowArtist?: boolean;
};

const Component: FC<Props> = ({
  user = UserInitialValue,
  fanclub = FanclubInitialValue,
  comment = '',
  isAdmin = false,
  comments = [],
  handleBack = () => undefined,
  handleFollow = () => undefined,
  handleClickUser = () => undefined,
  handleDeleteComment = () => undefined,
  handleChangeComment = () => undefined,
  handleSubmitComment = () => undefined,
  isViewFollowArtist = false,
}) => {
  const [padding, setPadding] = useState(36);

  const handleChangeHeight = useCallback((height: number) => {
    setPadding(height + 10);
  }, []);

  useEffect(() => {
    window.scrollTo(0, window.outerHeight);
  }, [padding, comments]);

  return (
    <>
      <div className={styles.commentWrap}>
        <McsHeaderComment
          total={comments.length}
          text=" コメント"
          onClickButton={handleBack}
          cssClass={styles.commentHeader}
        />

        <div
          style={{
            paddingBottom: padding,
          }}
        >
          {comments.map(c => (
            <OrgFCListUnitComment
              comment={c}
              isAdmin={isAdmin}
              isOwn={c.user_id === user.id}
              onClickUser={() => handleClickUser(c.user?.name_id || '')}
              onClickDelete={() => {
                handleDeleteComment(c.id);
              }}
              cssClass={styles.commentItem}
            />
          ))}
        </div>

        <div>
          <McsInputComment
            value={comment}
            onChange={handleChangeComment}
            onSendEvent={handleSubmitComment}
            onChangeHeight={handleChangeHeight}
            cssClass={styles.commentInput}
            placeholder="コメントを書く"
          />
        </div>
      </div>

      {isViewFollowArtist && (
        <div className={styles.followModalWrap}>
          <OrgJoinFCModal
            titleLines={['アーティストをフォローして', 'コメントをしよう！']}
            buttonText="フォローする"
            fanclub={fanclub}
            onClickButton={handleFollow}
            cssClass={styles.followModal}
          />
          <div className={styles.followModalBg} />
        </div>
      )}
    </>
  );
};

export default Component;
