/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, takeLeading } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/twitterConstants';
import { setAccessToken } from '../actions/authActions';
import {
  getTwitterAuthUrlEvent,
  authenticateEvent,
} from '../actions/twitterActions';
import {
  GetTwitterAuthUrlResponse,
  AuthenticateResponse,
} from '../interfaces/twitterInterFace';

import {
  setTwitterAuthSessionStorage,
  deleteTwitterAuthSessionStorage,
  setAuthSessionStorage,
  setAuthCookie,
} from '../utils/utilHelper';

// get twitter auth url
const getTwitterAuthUrlHandler = api.getGetFactory();
function* runGetTwitterAuthUrl(handler: typeof getTwitterAuthUrlHandler) {
  try {
    const ReturnData: GetTwitterAuthUrlResponse = yield call(
      handler,
      {},
      '/auth/twitter/url',
    );

    if (!ReturnData) {
      yield put(getTwitterAuthUrlEvent.fail());

      return;
    }

    const { url, oauth_token_secret } = ReturnData;

    yield call(setTwitterAuthSessionStorage, oauth_token_secret);

    yield put(getTwitterAuthUrlEvent.succeed({ url, oauth_token_secret }));
  } catch (error) {
    yield put(getTwitterAuthUrlEvent.fail());
  }
}
export function* getTWitterAuthUrl(handler: typeof getTwitterAuthUrlHandler) {
  yield takeLeading(
    ActionType.GET_TWITTER_AUTH_URL_START,
    runGetTwitterAuthUrl,
    handler,
  );
}

// authenticate with twitter
const authenticateHandler = api.getPostFactory();
function* runAuthenticate(
  handler: typeof authenticateHandler,
  action: ReturnType<typeof authenticateEvent.start>,
) {
  try {
    const data = action.payload;

    const ReturnData: AuthenticateResponse = yield call(
      handler,
      data,
      '/auth/twitter',
    );

    if (!ReturnData) {
      yield put(authenticateEvent.fail());

      return;
    }

    const { access_token } = ReturnData;

    yield call(deleteTwitterAuthSessionStorage);
    yield call(setAuthSessionStorage, access_token);
    yield call(setAuthCookie, access_token);

    yield put(setAccessToken(access_token));

    yield put(authenticateEvent.succeed(ReturnData.message));
  } catch (error) {
    yield put(authenticateEvent.fail());
  }
}
export function* authenticate(handler: typeof authenticateHandler) {
  yield takeLeading(ActionType.AUTHENTICATE_START, runAuthenticate, handler);
}

export const twitterSagas = [
  fork(getTWitterAuthUrl, getTwitterAuthUrlHandler),
  fork(authenticate, authenticateHandler),
];
