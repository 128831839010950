import React, { FC } from 'react';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import McsTitlePrimary from '../common/molecules/titleParts/McsTitlePrimary';

import OrgUtilityCommunityFCList from '../common/organisms/listParts/OrgUtiltyCommunityFCList';

import * as styles from './withdraw.module.scss';

import { Fanclub } from '../../interfaces/fanclubInterFace';

type Props = {
  userId?: string;
  onBackEvent?: () => void;
  onWithdrawEvent?: () => void;
  onClickAdminFC?: (artistId: string) => void;
  onClickPaidFC?: () => void;
  adminList?: Fanclub[];
  paidList?: Fanclub[];
};

const Component: FC<Props> = ({
  userId = '',
  onBackEvent = () => undefined,
  onWithdrawEvent = () => undefined,
  onClickAdminFC = () => undefined,
  onClickPaidFC = () => undefined,
  adminList = [],
  paidList = [],
}) => {
  return (
    <div>
      <div className={styles.containerWrap}>
        <McsTitlePrimary
          mainText="KRAPから退会する"
          subText={`@${userId}`}
          onClick={onBackEvent}
          cssClass={styles.title}
        />
      </div>
      {adminList.length === 0 && paidList.length === 0 && (
        <div className={styles.read}>
          <p>
            KRAPから退会されますと、お客様のアカウント情報やいいね履歴が削除されます。
            <br /> 再度ご利用いただくには、登録が必要となります。
            <br />
            本当に退会してもよろしいですか？
          </p>
        </div>
      )}

      {adminList.length !== 0 && (
        <>
          <div className={styles.read}>
            <p>
              下記コミュニティの管理者であるためKRAPから退会できません。
              <br />
              管理者権限を他のメンバーに移譲してから再度退会してください。
            </p>
          </div>
          <OrgUtilityCommunityFCList
            listTitle="管理中のコミュニティ"
            fanclubList={adminList}
            onClick={onClickAdminFC}
          />
        </>
      )}

      {paidList.length !== 0 && (
        <>
          <div className={styles.read}>
            <p>
              下記コミュニティのプレミアム会員（課金中）であるためKRAPから退会できません。
              <br />
              下記コミュニティを退会後、KRAPから退会してください。
            </p>
          </div>
          <OrgUtilityCommunityFCList
            listTitle="プレミアム会員のコミュニティ"
            fanclubList={paidList}
            onClick={onClickPaidFC}
          />
        </>
      )}

      <div className={styles.contentWrap}>
        {adminList.length === 0 && paidList.length === 0 ? (
          <div className={styles.buttonWrap}>
            <ButtonRoundedCorners
              text="KRAPから退会する"
              type="Primary"
              size="Primary"
              onClick={onWithdrawEvent}
              cssClass={styles.button}
            />
            <ButtonRoundedCorners
              text="キャンセル"
              type="Quinary"
              size="Primary"
              onClick={onBackEvent}
              cssClass={styles.cancel}
            />
          </div>
        ) : (
          <div className={styles.buttonWrap}>
            <ButtonRoundedCorners
              text="設定へ戻る"
              type="Primary"
              size="Primary"
              onClick={onBackEvent}
              cssClass={styles.button}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Component;
