/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/contentConstants';
import { checkAndSetStatusError } from './authSagas';
import { getUserProfileEvent as updateMe } from '../actions/authActions';
import {
  getReady as getReadyStart,
  gotReady,
  getContentDetailsEvent,
  getFanclubDetailsEvent,
  getRecentContentPhotoListEvent,
  getRecentContentBlogListEvent,
  getRecommendFanclubListEvent,
  likeEvent,
  freejoinFanclubEvent,
  getLikedUsers,
} from '../actions/contentActions';

import { Status, ErrorResponse } from '../interfaces/commonInterFace';
import {
  GetContentDetailsResponse,
  GetContentDetailsErrorResponse,
  GetContentPhotoListResponse,
  GetContentBlogListResponse,
  LikeResponse,
  ReleaseLikeResponse,
} from '../interfaces/contentInterFace';
import {
  FanclubInitialValue,
  GetFanclubDetailsResponse,
  GetPickupFanclubListResponse,
  FreejoinFanclubResponse,
} from '../interfaces/fanclubInterFace';
import { getLikedUsersResponse } from '../interfaces/likesInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId, contentId } = action.payload;

  const getContentDetailsTask = yield fork(
    runGetContentDetails,
    getContentDetailsHandler,
    getContentDetailsEvent.start({ contentId }),
  );
  yield join(getContentDetailsTask);

  const getFanclubDetailsTask = yield fork(
    runGetFanclubDetails,
    getFanclubDetailsHandler,
    getFanclubDetailsEvent.start({ artistId }),
  );
  yield join(getFanclubDetailsTask);

  const getLikedUsersTask = yield fork(
    runGetLikedUsers,
    getLikedUsersHandler,
    getLikedUsers.start({
      contentId: String(contentId),
      limit: 20,
    }),
  );
  yield join(getLikedUsersTask);

  yield put(gotReady());

  yield put(
    getRecentContentBlogListEvent.start({
      type: 1,
      group_id: artistId,
      limit: 3,
    }),
  );
  yield put(
    getRecentContentPhotoListEvent.start({ type: 2, group_id: artistId }),
  );
  yield put(getRecommendFanclubListEvent.start({ scope: 'recommend' }));
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get content details
const getContentDetailsHandler = api.getGetFactory(true);
function* runGetContentDetails(
  handler: typeof getContentDetailsHandler,
  action: ReturnType<typeof getContentDetailsEvent.start>,
) {
  const { contentId } = action.payload;
  try {
    const ReturnData: GetContentDetailsResponse = yield call(
      handler,
      {},
      `/contents/${contentId}`,
    );

    if (!ReturnData) {
      yield put(getContentDetailsEvent.fail(FanclubInitialValue, 0));

      return;
    }

    yield put(getContentDetailsEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as GetContentDetailsErrorResponse;

    if (res.status_code === Status.Forbidden && res.user_type === undefined) {
      yield put(getContentDetailsEvent.fail(res.fanclub, res.open_area));

      return;
    }

    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getContentDetailsEvent.fail(FanclubInitialValue, 0));
  }
}
export function* getContentDetails(handler: typeof getContentDetailsHandler) {
  yield takeLatest(
    ActionType.GET_CONTENT_DETAILS_START,
    runGetContentDetails,
    handler,
  );
}

// get fanclub details
const getFanclubDetailsHandler = api.getGetFactory(true);
function* runGetFanclubDetails(
  handler: typeof getFanclubDetailsHandler,
  action: ReturnType<typeof getFanclubDetailsEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetFanclubDetailsResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(getFanclubDetailsEvent.fail());

      return;
    }

    yield put(getFanclubDetailsEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFanclubDetailsEvent.fail());
  }
}
export function* getFanclubDetails(handler: typeof getFanclubDetailsHandler) {
  yield takeLatest(
    ActionType.GET_FANCLUB_DETAILS_START,
    runGetFanclubDetails,
    handler,
  );
}

// get recent content photo list
const getRecentContentPhotoListHandler = api.getGetFactory(true);
function* runGetRecentContentPhotoList(
  handler: typeof getRecentContentPhotoListHandler,
  action: ReturnType<typeof getRecentContentPhotoListEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: GetContentPhotoListResponse = yield call(
      handler,
      data,
      '/contents',
    );

    if (!ReturnData) {
      yield put(getRecentContentPhotoListEvent.fail());

      return;
    }

    yield put(getRecentContentPhotoListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getRecentContentPhotoListEvent.fail());
  }
}
export function* getRecentContentPhotoList(
  handler: typeof getRecentContentPhotoListHandler,
) {
  yield takeLatest(
    ActionType.GET_RECENT_CONTENT_PHOTO_LIST_START,
    runGetRecentContentPhotoList,
    handler,
  );
}

// get recent content blog list
const getRecentContentBlogListHandler = api.getGetFactory(true);
function* runGetRecentContentBlogList(
  handler: typeof getRecentContentBlogListHandler,
  action: ReturnType<typeof getRecentContentBlogListEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: GetContentBlogListResponse = yield call(
      handler,
      data,
      '/contents',
    );

    if (!ReturnData) {
      yield put(getRecentContentBlogListEvent.fail());

      return;
    }

    yield put(getRecentContentBlogListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getRecentContentBlogListEvent.fail());
  }
}
export function* getRecentContentBlogList(
  handler: typeof getRecentContentBlogListHandler,
) {
  yield takeLatest(
    ActionType.GET_RECENT_CONTENT_BLOG_LIST_START,
    runGetRecentContentBlogList,
    handler,
  );
}

// get recommend fanclub list
const getRecommendFanclubListHandler = api.getGetFactory(true);
function* runGetRecommendFanclubList(
  handler: typeof getRecommendFanclubListHandler,
  action: ReturnType<typeof getRecommendFanclubListEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: GetPickupFanclubListResponse = yield call(
      handler,
      data,
      '/fanclub',
    );

    if (!ReturnData) {
      yield put(getRecommendFanclubListEvent.fail());

      return;
    }

    yield put(getRecommendFanclubListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getRecommendFanclubListEvent.fail());
  }
}
export function* getRecommendFanclubList(
  handler: typeof getRecommendFanclubListHandler,
) {
  yield takeLatest(
    ActionType.GET_RECOMMEND_FANCLUB_LIST_START,
    runGetRecommendFanclubList,
    handler,
  );
}

// like
const likeHandler = api.getPostFactory(true);
const unlikeHandler = api.getDeleteFactory(true);
function* runLike(
  handlerLike: typeof likeHandler,
  handlerUnlike: typeof unlikeHandler,
  action: ReturnType<typeof likeEvent.start>,
) {
  const { type, ...data } = action.payload;

  const handler = type === 'like' ? handlerLike : handlerUnlike;

  try {
    const ReturnData: LikeResponse | ReleaseLikeResponse = yield call(
      handler,
      data,
      '/like',
    );

    if (!ReturnData) {
      yield put(likeEvent.fail(action.payload));

      return;
    }

    // update user profile
    yield put(updateMe.start());

    yield put(likeEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(likeEvent.fail(action.payload));
  }
}
export function* like(
  handlerLike: typeof likeHandler,
  handlerUnlike: typeof unlikeHandler,
) {
  yield takeLeading(ActionType.LIKE_START, runLike, handlerLike, handlerUnlike);
}

// いいねしたユーザー取得
const getLikedUsersHandler = api.getGetFactory(true);
function* runGetLikedUsers(
  handler: typeof getLikedUsersHandler,
  action: ReturnType<typeof getLikedUsers.start>,
) {
  const { contentId, ...data } = action.payload;

  try {
    const ReturnData: getLikedUsersResponse = yield call(
      handler,
      data,
      `/contents/${contentId}/like/users`,
    );

    if (!ReturnData.result) {
      yield put(getLikedUsers.fail());

      return;
    }

    yield put(getLikedUsers.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getLikedUsers.fail());
  }
}

// freejoin fanclub
const freejoinFanclubHandler = api.getPostFactory(true);
function* runFreejoinFanclub(
  handler: typeof freejoinFanclubHandler,
  action: ReturnType<typeof freejoinFanclubEvent.start>,
) {
  const {
    params: { artistId },
    contentId,
  } = action.payload;

  try {
    const ReturnData: FreejoinFanclubResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/freejoin`,
    );

    if (!ReturnData) {
      yield put(freejoinFanclubEvent.fail());

      return;
    }

    // update user profile
    yield put(updateMe.start());
    // reGet content details
    yield put(getContentDetailsEvent.start({ contentId }));
    // update fanclub details
    yield put(getFanclubDetailsEvent.start({ artistId }));

    yield put(freejoinFanclubEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(freejoinFanclubEvent.fail());
  }
}
export function* freejoinFanclub(handler: typeof freejoinFanclubHandler) {
  yield takeLeading(
    ActionType.FREEJOIN_FANCLUB_START,
    runFreejoinFanclub,
    handler,
  );
}

export const contentSagas = [
  fork(getReady),
  fork(getContentDetails, getContentDetailsHandler),
  fork(getFanclubDetails, getFanclubDetailsHandler),
  fork(getRecentContentPhotoList, getRecentContentPhotoListHandler),
  fork(getRecentContentBlogList, getRecentContentBlogListHandler),
  fork(getRecommendFanclubList, getRecommendFanclubListHandler),
  fork(like, likeHandler, unlikeHandler),
  fork(freejoinFanclub, freejoinFanclubHandler),
];
