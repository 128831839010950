/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import blankImageUrl from '../../assets/images/blank-image.png';
import IconThumbnailSquare from '../../atoms/iconThumbnail/IconThumbnailSquare';
import IconArrow from '../../atoms/iconParts/IconArrow';

import { Fanclub } from '../../../../interfaces/fanclubInterFace';
import * as styles from './OrgFCListUnitGroup.module.scss';

type PartialFanclub = Partial<Fanclub>;

interface Props {
  cssClass?: string;
  fanclubList?: PartialFanclub[];
  limit?: number;
  onLinkClick?: () => void;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  fanclubList = [],
  limit = 7,
  onLinkClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgGroupListUnitBase, cssClass);

  return (
    <div className={classNames} onClick={onLinkClick}>
      <div className={styles.OrgGroupListUnitIconTitle}>所属グループ</div>
      <ul>
        {fanclubList.map((item, i) =>
          i <= limit - 1 ? (
            <IconThumbnailSquare
              src={item.icon_image}
              errorSrc={blankImageUrl}
              type="Secondary"
              size="Quaternary"
              key={`IconThumbnailSquare${i.toString()}`}
              cssClass={styles.OrgGroupListUnitIcon}
            />
          ) : null,
        )}
      </ul>
      <IconArrow type="Tertiary" cssClass={styles.OrgGroupListUnitArrow} />
    </div>
  );
};

export default OrgListUnit;
