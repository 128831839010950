/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/adminBlogRegisterConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getFanclubDetailsEvent,
  getBlogEvent,
  getFCCategoryListEvent,
  addFCCategoryEvent,
  createBlogEvent,
  updateBlogEvent,
  deleteBlogEvent,
} from '../actions/adminBlogRegisterActions';
import { Status, ErrorResponse } from '../interfaces/commonInterFace';
import { GetFanclubDetailsResponse } from '../interfaces/fanclubInterFace';
import {
  GetContentDetailsResponse,
  CreateBlogResponse,
  CreateBlogErrorResponse,
  UpdateBlogResponse,
  UpdateBlogErrorResponse,
  DeleteContentResponse,
} from '../interfaces/contentInterFace';
import {
  GetFCCategoryListResponse,
  AddFCCategoryResponse,
  AddFCCategoryErrorResponse,
} from '../interfaces/categoryInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId, contentId } = action.payload;

  const getFanclubDetailsTask = yield fork(
    runGetFanclubDetails,
    getFanclubDetailsHandler,
    getFanclubDetailsEvent.start({ artistId }),
  );
  yield join(getFanclubDetailsTask);

  const getFCCategoryListTask = yield fork(
    runGetFCCategoryList,
    getFCCategoryListHandler,
    getFCCategoryListEvent.start({ artistId }),
  );
  yield join(getFCCategoryListTask);

  if (contentId) {
    const getBlogTask = yield fork(
      runGetBlog,
      getBlogHandler,
      getBlogEvent.start({ contentId }),
    );
    yield join(getBlogTask);
  }

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get fanclub details
const getFanclubDetailsHandler = api.getGetFactory(true);
function* runGetFanclubDetails(
  handler: typeof getFanclubDetailsHandler,
  action: ReturnType<typeof getFanclubDetailsEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetFanclubDetailsResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(getFanclubDetailsEvent.fail());

      return;
    }

    yield put(getFanclubDetailsEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFanclubDetailsEvent.fail());
  }
}
export function* getFanclubDetails(handler: typeof getFanclubDetailsHandler) {
  yield takeLatest(
    ActionType.GET_FANCLUB_DETAILS_START,
    runGetFanclubDetails,
    handler,
  );
}

// get blog
const getBlogHandler = api.getGetFactory(true);
function* runGetBlog(
  handler: typeof getBlogHandler,
  action: ReturnType<typeof getBlogEvent.start>,
) {
  const { contentId } = action.payload;
  try {
    const ReturnData: GetContentDetailsResponse = yield call(
      handler,
      {},
      `/contents/${contentId}`,
    );

    if (!ReturnData) {
      yield put(getBlogEvent.fail());

      return;
    }

    yield put(getBlogEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getBlogEvent.fail());
  }
}
export function* getBlog(handler: typeof getBlogHandler) {
  yield takeLatest(ActionType.GET_BLOG_START, runGetBlog, handler);
}

// get fc category list
const getFCCategoryListHandler = api.getGetFactory(true);
function* runGetFCCategoryList(
  handler: typeof getFCCategoryListHandler,
  action: ReturnType<typeof getFCCategoryListEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: GetFCCategoryListResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/category`,
    );

    if (!ReturnData) {
      yield put(getFCCategoryListEvent.fail());

      return;
    }

    yield put(getFCCategoryListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFCCategoryListEvent.fail());
  }
}
export function* getFCCategoryList(handler: typeof getFCCategoryListHandler) {
  yield takeLatest(
    ActionType.GET_FC_CATEGORY_LIST_START,
    runGetFCCategoryList,
    handler,
  );
}

// add fc category
const addFCCategoryHandler = api.getPostFactory(true);
function* runAddFCCategory(
  handler: typeof addFCCategoryHandler,
  action: ReturnType<typeof addFCCategoryEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: AddFCCategoryResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/category`,
    );

    if (!ReturnData) {
      yield put(addFCCategoryEvent.fail({}));

      return;
    }

    yield put(addFCCategoryEvent.succeed(action.payload, ReturnData.result));
  } catch (error) {
    const res = error.response.data as AddFCCategoryErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(addFCCategoryEvent.fail(res.errors));

      return;
    }

    yield put(addFCCategoryEvent.fail({}));
  }
}
export function* addFCCategory(handler: typeof addFCCategoryHandler) {
  yield takeLeading(
    ActionType.ADD_FC_CATEGORY_START,
    runAddFCCategory,
    handler,
  );
}

// create blog
const createBlogHandler = api.getFormDataFactory('POST');
function* runCreateBlog(
  handler: typeof createBlogHandler,
  action: ReturnType<typeof createBlogEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: CreateBlogResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/blog`,
    );

    if (!ReturnData) {
      yield put(createBlogEvent.fail({}));

      return;
    }

    yield put(createBlogEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as CreateBlogErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(createBlogEvent.fail(res.errors));

      return;
    }

    yield put(createBlogEvent.fail({}));
  }
}
export function* createBlog(handler: typeof createBlogHandler) {
  yield takeLeading(ActionType.CREATE_BLOG_START, runCreateBlog, handler);
}

// update blog
const updateBlogHandler = api.getFormDataFactory('PATCH');
function* runUpdateBlog(
  handler: typeof updateBlogHandler,
  action: ReturnType<typeof updateBlogEvent.start>,
) {
  const { artistId, contentId, ...data } = action.payload;

  try {
    const ReturnData: UpdateBlogResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/blog/${contentId}`,
    );

    if (!ReturnData) {
      yield put(updateBlogEvent.fail({}));

      return;
    }

    yield put(updateBlogEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as UpdateBlogErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(updateBlogEvent.fail(res.errors));

      return;
    }

    yield put(updateBlogEvent.fail({}));
  }
}
export function* updateBlog(handler: typeof updateBlogHandler) {
  yield takeLeading(ActionType.UPDATE_BLOG_START, runUpdateBlog, handler);
}

// delete blog
const deleteBlogHandler = api.getDeleteFactory(true);
function* runDeleteBlog(
  handler: typeof deleteBlogHandler,
  action: ReturnType<typeof deleteBlogEvent.start>,
) {
  const { artistId, contentId } = action.payload;
  try {
    const ReturnData: DeleteContentResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/blog/${contentId}`,
    );

    if (!ReturnData) {
      yield put(deleteBlogEvent.fail());

      return;
    }

    yield put(deleteBlogEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(deleteBlogEvent.fail());
  }
}
export function* deleteBlog(handler: typeof deleteBlogHandler) {
  yield takeLeading(ActionType.DELETE_BLOG_START, runDeleteBlog, handler);
}

export const adminBlogRegisterSagas = [
  fork(getReady),
  fork(getFanclubDetails, getFanclubDetailsHandler),
  fork(getBlog, getBlogHandler),
  fork(getFCCategoryList, getFCCategoryListHandler),
  fork(addFCCategory, addFCCategoryHandler),
  fork(createBlog, createBlogHandler),
  fork(updateBlog, updateBlogHandler),
  fork(deleteBlog, deleteBlogHandler),
];
