/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import McsIconLabelSquareTertiary from '../common/molecules/iconWithLabel/McsIconLabelSquareTertiary';

import OrgAdminListMenuUnit from '../common/organisms/listUnitParts/OrgAdminListMenuUnit';

import blankImageUrl from '../common/assets/images/blank-image.png';

import * as styles from './adminMenu.module.scss';

import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../../interfaces/fanclubInterFace';
import OrgAdminMenuApply from '../common/organisms/admin/OrgAdminMenuApply';

interface Props {
  details?: FanclubDetails;
  isHiddenDenial?: boolean;
  chatCount?: number;
  handleLinkToArtist?: () => void;
  handleLinkToContents?: () => void;
  handleLinkToLikes?: () => void;
  handleLinkToComments?: () => void;
  handleLinkToChat?: () => void;
  handleLinkToSales?: () => void;
  handleLinkToCommunityEdit?: () => void;
  handleLinkToManageMembers?: () => void;
  handleLinkToManageFollowers?: () => void;
  handleClickApplyButton?: () => void;
  handleClickApplyCross?: () => void;
  handleLinkToAdminEdit?: () => void;
  handleLinkToCloseFC?: () => void;
}

const Component: FC<Props> = ({
  details = FanclubDetailsInitialValue,
  isHiddenDenial = false,
  chatCount = 0,
  handleLinkToArtist = () => undefined,
  handleLinkToContents = () => undefined,
  handleLinkToLikes = () => undefined,
  handleLinkToComments = () => undefined,
  handleLinkToChat = () => undefined,
  handleLinkToSales = () => undefined,
  handleLinkToCommunityEdit = () => undefined,
  handleLinkToManageMembers = () => undefined,
  handleLinkToManageFollowers = () => undefined,
  handleClickApplyButton = () => undefined,
  handleClickApplyCross = () => undefined,
  handleLinkToAdminEdit = () => undefined,
  handleLinkToCloseFC = () => undefined,
}) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      <McsIconLabelSquareTertiary
        src={details.fanclub.icon_image || blankImageUrl}
        mainText={details.fanclub.name}
        subText={details.fanclub.site_name}
        onClick={handleLinkToArtist}
        cssClass={styles.contentTitle}
      />

      {details.user_type === 3 && (
        <OrgAdminMenuApply
          paidStatus={details.fanclub.paid_status}
          hasBankAccount={!!details.is_account_registerd}
          isHiddenDenial={isHiddenDenial}
          onClickCross={handleClickApplyCross}
          onClickButton={handleClickApplyButton}
          cssClass={styles.applyWrapper}
        />
      )}

      <ul>
        <OrgAdminListMenuUnit
          iconType="Pen"
          mainTitle="コンテンツ"
          subTitle="ブログ・動画・画像・音声の投稿や編集"
          cssClass={styles.contentList}
          onLinkClick={handleLinkToContents}
        />
        <OrgAdminListMenuUnit
          iconType="FavoriteLinePink"
          mainTitle="いいね！"
          subTitle="いいね！をランキング形式で確認"
          cssClass={styles.contentList}
          onLinkClick={handleLinkToLikes}
        />
        <OrgAdminListMenuUnit
          iconType="SpeechBubblePurple"
          mainTitle="コメント確認"
          subTitle="コンテンツへ寄せられたコメントの確認"
          cssClass={styles.contentList}
          onLinkClick={handleLinkToComments}
        />
      </ul>
      {details.fanclub.paid_status === 2 && (
        <OrgAdminListMenuUnit
          count={chatCount}
          iconType="ChatYellow"
          mainTitle="グループチャット"
          subTitle="ファンのみがトーク可能なグループチャットの管理"
          cssClass={styles.contentList}
          onLinkClick={handleLinkToChat}
        />
      )}
      <ul className={styles.contentListWrap}>
        {/* <OrgAdminListMenuUnit
          mainTitle="お問い合わせ対応"
          subTitle="ファンから寄せられたお問い合わせ"
          cssClass={styles.contentList}
          onLinkClick={handleLinkToFanSupport}
        /> */}
        {details.fanclub.paid_status === 2 && details.user_type === 3 && (
          <OrgAdminListMenuUnit
            iconType="Sales"
            mainTitle="売上情報"
            subTitle="会員数や売上額の確認"
            cssClass={styles.contentList}
            onLinkClick={handleLinkToSales}
          />
        )}
        {details.user_type === 3 && (
          <OrgAdminListMenuUnit
            iconType="Setting"
            mainTitle="コミュニティ設定"
            subTitle="コミュニティ情報やアーティスト情報の編集"
            cssClass={styles.contentList}
            onLinkClick={handleLinkToCommunityEdit}
          />
        )}
        <OrgAdminListMenuUnit
          iconType="Person"
          mainTitle="メンバー管理"
          subTitle="グループ内のユーザーを管理"
          cssClass={styles.contentList}
          onLinkClick={handleLinkToManageMembers}
        />
        <OrgAdminListMenuUnit
          iconType="PersonWithHeart"
          mainTitle="フォロワー管理"
          subTitle="フォロワー（有料含む）を管理"
          cssClass={styles.contentList}
          onLinkClick={handleLinkToManageFollowers}
        />
        {details.fanclub.paid_status === 2 && details.user_type === 3 && (
          <OrgAdminListMenuUnit
            iconType="Admin"
            mainTitle="管理者情報編集"
            subTitle="管理者の情報や売上売り込み先口座など"
            cssClass={styles.contentList}
            onLinkClick={handleLinkToAdminEdit}
          />
        )}
        {details.fanclub.paid_status === 1 && details.user_type === 3 && (
          <OrgAdminListMenuUnit
            iconType="Trash"
            mainTitle="コミュニティを閉鎖する"
            subTitle="コミュニティ閉鎖の窓口"
            cssClass={styles.contentList}
            onLinkClick={handleLinkToCloseFC}
          />
        )}
      </ul>
    </div>
  );
};

export default Component;
