import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './CopyRight.module.scss';

interface Props {
  copyRight?: string;
  cssClass?: string;
}

const CopyRight: FC<Props> = ({
  copyRight = '©NANO Media INC. All Rights Reserved.',
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.CopyRightBase, cssClass);

  return (
    <div className={classNames}>
      <small>{copyRight}</small>
    </div>
  );
};

export default CopyRight;
