import { Reducer } from 'redux';
import { Actions } from '../actions/editUserProfileActions';
import * as ActionType from '../actions/editUserProfileConstants';
import { UpdateUserProfileErrors } from '../interfaces/userInterFace';

type State = {
  ready: boolean;
  loading: boolean;
  errors: UpdateUserProfileErrors;
  success: boolean;
};

const initialState: State = {
  ready: false,
  loading: true,
  errors: {},
  success: false,
};

const editUserProfileReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // update user profile
    case ActionType.UPDATE_USER_PROFILE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.UPDATE_USER_PROFILE_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ActionType.UPDATE_USER_PROFILE_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default editUserProfileReducer;
