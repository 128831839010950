export const GET_READY = 'ADMIN_FC_CLOSED/GET_READY' as const;
export const GOT_READY = 'ADMIN_FC_CLOSED/GOT_READY' as const;

export const GET_FANCLUB_DETAILS_START = 'ADMIN_FC_CLOSED/GET_FANCLUB_DETAILS_START' as const;
export const GET_FANCLUB_DETAILS_SUCCEED = 'ADMIN_FC_CLOSED/GET_FANCLUB_DETAILS_SUCCEED' as const;
export const GET_FANCLUB_DETAILS_FAIL = 'ADMIN_FC_CLOSED/GET_FANCLUB_DETAILS_FAIL' as const;

export const CLOSED_FANCLUB_START = 'ADMIN_FC_CLOSED/CLOSED_FANCLUB_START' as const;
export const CLOSED_FANCLUB_SUCCEED = 'ADMIN_FC_CLOSED/CLOSED_FANCLUB_SUCCEED' as const;
export const CLOSED_FANCLUB_FAIL = 'ADMIN_FC_CLOSED/CLOSED_FANCLUB_FAIL' as const;

export const CLEANUP = 'ADMIN_FC_CLOSED/CLEANUP' as const;
