import * as ActionType from './adminCommentsConstants';
import {
  GetCommentListParams,
  GetCommentListResult,
} from '../interfaces/commentInterFace';

export const getReady = (params: GetCommentListParams) =>
  ({
    type: ActionType.GET_READY,
    payload: params,
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getCommentListEvent = {
  start: (params: GetCommentListParams) =>
    ({
      type: ActionType.GET_COMMENT_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetCommentListResult) =>
    ({
      type: ActionType.GET_COMMENT_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_COMMENT_LIST_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fc category list
  | ReturnType<typeof getCommentListEvent.start>
  | ReturnType<typeof getCommentListEvent.succeed>
  | ReturnType<typeof getCommentListEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
