import React, { FC } from 'react';

import ButtonSquare from '../common/atoms/buttonBasic/ButtonSquare';

import * as styles from './twitterAuthFailure.module.scss';

type Props = {
  onBack?: () => void;
};

const Component: FC<Props> = ({ onBack = () => undefined }) => {
  return (
    <div className={styles.containerWrap}>
      <p className={styles.summary}>新規会員登録に失敗しました。</p>
      <p className={styles.description}>
        {
          'Twitterにメールアドレスの登録がないため、KRAPの新規会員登録ができません。\nTwitterにメールアドレスを登録し再度お試しいただくか、メールアドレスでKRAPを初めてください。'
        }
      </p>
      <ButtonSquare
        type="Tertiary"
        size="Secondary"
        text="戻る"
        onClick={onBack}
        cssClass={styles.button}
      />
    </div>
  );
};

export default Component;
