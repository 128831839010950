import React, { FC } from 'react';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import McsTitlePrimary from '../common/molecules/titleParts/McsTitlePrimary';

import OrgProductDetails from '../common/organisms/paidjoin/OrgProductDetails';

import * as styles from './withdrawPaidFC.module.scss';

import { Fanclub } from '../../interfaces/fanclubInterFace';

type Props = {
  userId?: string;
  select?: Fanclub;
  onBack?: () => void;
  onWithdrawPaidFC?: () => void;
};

const Component: FC<Props> = ({
  userId = '',
  select = undefined,
  onBack = () => undefined,
  onWithdrawPaidFC = () => undefined,
}) => {
  return (
    <div>
      <div className={styles.containerWrap}>
        <McsTitlePrimary
          mainText="コミュニティから退会する"
          subText={`@${userId}`}
          onClick={onBack}
          cssClass={styles.title}
        />
        <div className={styles.body}>
          <OrgProductDetails
            fanclub={select}
            // paymentTagText="最終決済日"
            // paymentBodyText="クレジットカード"
          />

          <ButtonRoundedCorners
            text="コミュニティから退会する"
            type="Primary"
            size="Primary"
            onClick={onWithdrawPaidFC}
            cssClass={styles.button}
          />
        </div>
      </div>
    </div>
  );
};

export default Component;
