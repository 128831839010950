/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react';

import ButtonFullWidth from '../common/atoms/buttonBasic/ButtonFullWidth';

import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';

import OrgAdminListCommentUnit from '../common/organisms/listUnitParts/OrgAdminListCommentUnit';

import * as styles from './adminContents.module.scss';

import { CommentDetails } from '../../interfaces/commentInterFace';

type Props = {
  fanclubName?: string;
  artistId?: string;
  commentList?: CommentDetails[];
  onClickComment?: (artistId: string, contentId: number) => void;
  isViewReadMoreButton?: boolean;
  onReadMore?: () => void;
  onBack?: () => void;
};

const Component: FC<Props> = ({
  fanclubName = '',
  artistId = '',
  commentList = [],
  onClickComment = () => undefined,
  isViewReadMoreButton = true,
  onReadMore = () => undefined,
  onBack = () => undefined,
}) => {
  return (
    <div className={styles.containerWrap}>
      <McsTitleSecondary
        mainText="コメント"
        subText={fanclubName}
        onClick={onBack}
        cssClass={styles.contentTitle}
      />

      {/* Comment List */}
      {commentList.map(comment => (
        <OrgAdminListCommentUnit
          key={`OrgAdminListCommentUnit${comment.id}`}
          comment={comment}
          onClick={() => onClickComment(artistId, comment.content_id)}
        />
      ))}

      {/* Read More Button */}
      {isViewReadMoreButton && (
        <ButtonFullWidth text="もっと見る" type="Senary" onClick={onReadMore} />
      )}
    </div>
  );
};

export default Component;
