import * as ActionType from './attachGenresConstants';
import { AttachGenresParams } from '../interfaces/genreInterFace';

export const attachGenresEvent = {
  start: (params: AttachGenresParams) =>
    ({
      type: ActionType.ATTACH_GENRES_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.ATTACH_GENRES_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.ATTACH_GENRES_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // attach genres
  | ReturnType<typeof attachGenresEvent.start>
  | ReturnType<typeof attachGenresEvent.succeed>
  | ReturnType<typeof attachGenresEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
