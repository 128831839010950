/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, join, takeLatest } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/adminMenuConstants';
import {
  runGetUserProfile,
  getUserProfileHandler,
  checkAndSetStatusError,
} from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getFanclubDetailsEvent,
  getUnreadChatEvent,
} from '../actions/adminMenuActions';
import { ErrorResponse } from '../interfaces/commonInterFace';
import { getUnreadChatResponse } from '../interfaces/chatInterFace';
import { GetFanclubDetailsResponse } from '../interfaces/fanclubInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId } = action.payload;

  const updateMeTask = yield fork(runGetUserProfile, getUserProfileHandler);
  yield join(updateMeTask);

  const getFanclubDetailsTask = yield fork(
    runGetFanclubDetails,
    getFanclubDetailsHandler,
    getFanclubDetailsEvent.start({ artistId }),
  );
  yield join(getFanclubDetailsTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get fanclub details
const getFanclubDetailsHandler = api.getGetFactory(true);
function* runGetFanclubDetails(
  handler: typeof getFanclubDetailsHandler,
  action: ReturnType<typeof getFanclubDetailsEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetFanclubDetailsResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(getFanclubDetailsEvent.fail());

      return;
    }

    yield put(getFanclubDetailsEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFanclubDetailsEvent.fail());
  }
}
export function* getFanclubDetails(handler: typeof getFanclubDetailsHandler) {
  yield takeLatest(
    ActionType.GET_FANCLUB_DETAILS_START,
    runGetFanclubDetails,
    handler,
  );
}

// チャットの未読件数取得
const getUnreadChatHandler = api.getGetFactory(true);
function* runGetUnreadChat(
  handler: typeof getUnreadChatHandler,
  action: ReturnType<typeof getUnreadChatEvent.start>,
) {
  const artistId = action.payload;
  try {
    const ReturnData: getUnreadChatResponse = yield call(
      handler,
      {},
      `fanclub/${artistId}/chats/unreadCount`,
    );

    if (!ReturnData) {
      yield put(getUnreadChatEvent.fail());

      return;
    }

    yield put(getUnreadChatEvent.succeed(ReturnData.result.unread_count));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getUnreadChatEvent.fail());
  }
}
export function* getUnreadChat(handler: typeof getUnreadChatHandler) {
  yield takeLatest(ActionType.GET_UNREAD_CHAT_START, runGetUnreadChat, handler);
}

export const adminMenuSagas = [
  fork(getReady),
  fork(getFanclubDetails, getFanclubDetailsHandler),
  fork(getUnreadChat, getUnreadChatHandler),
];
