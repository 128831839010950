/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';

import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';
import McsTextBoxScroll from '../common/molecules/formParts/McsTextBoxScroll';
import CheckBox from '../common/atoms/formParts/CheckBox';
import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import * as styles from './adminFCClosed.module.scss';
import {
  Fanclub,
  FanclubInitialValue,
} from '../../interfaces/fanclubInterFace';

interface Props {
  fanclub?: Fanclub;
  agreed?: boolean;
  handleBack?: () => void;
  handleToggleAgreed?: () => void;
  handleFCClosed?: () => void;
}

const DESCRIPTION = `・閉鎖申請後、このコミュニティはすぐに削除され、ユーザーはコミュニティを閲覧することが出来なくなります。
・コミュニティ閉鎖後、今まで投稿されたコンテンツ内容やコメント、いいねの情報は削除され、復元出来ません。
・コミュニティ閉鎖後、管理画面へのアクセスが出来なくなります。`;

const Component: FC<Props> = ({
  fanclub = FanclubInitialValue,
  agreed = false,
  handleBack = () => undefined,
  handleToggleAgreed = () => undefined,
  handleFCClosed = () => undefined,
}) => {
  return (
    <div className={styles.containerWrap}>
      <McsTitleSecondary
        mainText="コミュニティを閉鎖する"
        subText={fanclub.site_name}
        onClick={handleBack}
        cssClass={styles.contentTitle}
      />
      <p className={styles.read}>
        「{fanclub.name}」の閉鎖申請を行います。
        <br />
        以下の注意事項をお読みの上、同意いただけましたら
        チェックボックスを入力の上、「閉鎖申請する」ボタンを押してください。
      </p>
      <McsTextBoxScroll
        value={DESCRIPTION}
        labelTitle="注意事項"
        readonly
        cssClass={styles.textArea}
      />
      <CheckBox
        labelText="注意事項を理解し、同意しました。 "
        checked={agreed}
        onChange={handleToggleAgreed}
        cssClass={styles.checkbox}
      />

      <div className={styles.buttonWrap}>
        <ButtonRoundedCorners
          text="コミュニティ閉鎖申請"
          type={agreed ? 'Senary' : 'Quaternary'}
          size="Primary"
          onClick={handleFCClosed}
          cssClass={styles.button}
        />
        <ButtonRoundedCorners
          text="戻る"
          type="Quinary"
          size="Primary"
          onClick={handleBack}
          cssClass={styles.cancel}
        />
      </div>
    </div>
  );
};

export default Component;
