import { useDispatch } from 'react-redux';

import { useEffect } from 'react';
import { setOptions } from '../actions/footerActions';
import { Options } from '../interfaces/footerInterFace';

/**
 * 表示したいfooter要素を指定する
 */
export const useFooter = (options: Options) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOptions(options));
  }, [dispatch, options]);
};
