import React, { FC } from 'react';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import McsTitlePrimary from '../common/molecules/titleParts/McsTitlePrimary';
import McsInputSecondary from '../common/molecules/formParts/McsInputSecondary';

import * as styles from './changeEmail.module.scss';

import { User, UserInitialValue } from '../../interfaces/userInterFace';
import { ChangeEmailValidationErrors } from '../../interfaces/emailInterFace';

type Props = {
  user?: User;
  valueEmail?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  onBackEvent?: () => void;
  onChangeEmailEvent?: () => void;
  errors?: ChangeEmailValidationErrors;
};

const Component: FC<Props> = ({
  user = UserInitialValue,
  valueEmail = '',
  onChange = () => undefined,
  onBackEvent = () => undefined,
  onChangeEmailEvent = () => undefined,
  errors = {
    new_email: '',
  },
}) => {
  return (
    <div className={styles.containerWrap}>
      <McsTitlePrimary
        mainText="メールアドレス変更"
        subText={`@${user.name_id}`}
        onClick={onBackEvent}
        cssClass={styles.title}
      />

      <div className={styles.wrapper}>
        <div className={styles.read}>
          <p>
            現在のメールアドレスは
            <span>{user.email}</span>
            です。変更しますか？メールアドレスはKRAPの公開プロフィールには表示されません。
          </p>
        </div>

        <McsInputSecondary
          value={valueEmail}
          inputType="email"
          inputName="email"
          placeholder="メールアドレスを入力"
          labelTitle="新しいメールアドレス"
          onChange={onChange}
          isError={!!errors.new_email}
          errorText={errors.new_email}
        />
        <div className={styles.buttonWrap}>
          <ButtonRoundedCorners
            text="メールアドレスを変更する"
            type="Primary"
            size="Primary"
            onClick={onChangeEmailEvent}
            cssClass={styles.button}
          />
          <ButtonRoundedCorners
            text="キャンセル"
            type="Quinary"
            size="Primary"
            onClick={onBackEvent}
            cssClass={styles.cancel}
          />
        </div>
      </div>
    </div>
  );
};

export default Component;
