import { Reducer } from 'redux';

import { Actions } from '../actions/chatActions';
import * as ActionType from '../actions/chatConstants';

import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../interfaces/fanclubInterFace';
import { chat } from '../interfaces/chatInterFace';

type State = {
  funClub: FanclubDetails;
  chats: {
    chats: chat[];
    already_read_chat_id: number;
    unread_count: number;
  };
  ready: boolean;
  loading: boolean;
};

export const initialState: State = {
  funClub: FanclubDetailsInitialValue,
  chats: {
    chats: [],
    already_read_chat_id: 0,
    unread_count: 0,
  },
  ready: false,
  loading: false,
};

const chatReducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // ファンクラブ詳細取得
    case ActionType.GET_FANCLUB_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_SUCCEED: {
      return {
        ...state,
        funClub: action.payload,
        loading: false,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // チャット一覧取得
    case ActionType.GET_CHAT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_CHAT_SUCCEED: {
      return {
        ...state,
        chats: {
          ...action.payload.result,
        },
        loading: false,
      };
    }
    case ActionType.GET_CHAT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // チャット送信
    case ActionType.SUBMIT_CHAT_START: {
      return {
        ...state,
      };
    }
    case ActionType.SUBMIT_CHAT_SUCCEED: {
      return {
        ...state,
      };
    }
    case ActionType.SUBMIT_CHAT_FAIL: {
      return {
        ...state,
      };
    }

    // チャット既読処理
    case ActionType.CHAT_READ_START: {
      return {
        ...state,
      };
    }
    case ActionType.CHAT_READ_SUCCEED: {
      return {
        ...state,
      };
    }
    case ActionType.CHAT_READ_FAIL: {
      return {
        ...state,
      };
    }

    // チャット削除処理
    case ActionType.CHAT_DELETE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.CHAT_DELETE_SUCCEED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.CHAT_DELETE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default chatReducer;
