/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  updateFanclubEvent,
  cleanup,
} from '../../actions/adminCommunityEditActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';
import { getYears, getMonths, getDates } from '../../utils/datetimeHelper';
import {
  validateArtistName,
  validateArtistNameKana,
} from '../../utils/validationHelper';

import {
  UpdateFanclubInputParams,
  UpdateFanclubInputParamsInitialValue,
  UpdateFanclubValidationErrors,
} from '../../interfaces/fanclubInterFace';
import { GenreWithSelected } from '../../interfaces/genreInterFace';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminCommunityEdit from '../../components/admin/adminCommunityEdit';

const AdminCommunityEditContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const [params, setParams] = useState<UpdateFanclubInputParams>(
    UpdateFanclubInputParamsInitialValue,
  );
  const {
    ready: authReady,
    profile: {
      fanclubs: { manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const { ready, current, loading, success, errors } = useSelector(
    selector.adminCommunityEdit.getAdminCommunityEditState,
  );
  const { prefectures } = useSelector(selector.util.getSelectFields);
  const genreFields = useSelector(selector.util.getGenreFields);
  const genres: GenreWithSelected[][] = useMemo(
    () =>
      genreFields.map(group =>
        group.map(genre => ({
          id: genre.value,
          name: genre.label,
          selected: params.genres.includes(genre.value),
        })),
      ),
    [genreFields, params.genres],
  );
  const years = useMemo(
    () => getYears(params.formation_on_month, params.formation_on_date),
    [params.formation_on_month, params.formation_on_date],
  );
  const months = useMemo(
    () => getMonths(params.formation_on_year, params.formation_on_date),
    [params.formation_on_year, params.formation_on_date],
  );
  const dates = useMemo(
    () => getDates(params.formation_on_year, params.formation_on_month),
    [params.formation_on_year, params.formation_on_month],
  );

  const validationErrors: UpdateFanclubValidationErrors = useMemo(
    () => ({
      cover_image: errors.cover_image?.[0] ?? '',
      icon_image: errors.icon_image?.[0] ?? '',
      name: errors.name?.[0] ?? validateArtistName(params.name),
      name_kana:
        errors.name_kana?.[0] ?? validateArtistNameKana(params.name_kana),
      site_name: errors.site_name?.[0] ?? '',
      prefecture: errors.prefecture?.[0] ?? '',
      formation_on: errors.formation_on?.[0] ?? '',
      genres: errors.genres?.[0] ?? '',
      description: errors.description?.[0] ?? '',
      site_url: errors.site_url?.[0] ?? '',
      twitter_url: errors.twitter_url?.[0] ?? '',
      facebook_url: errors.facebook_url?.[0] ?? '',
      instagram_url: errors.instagram_url?.[0] ?? '',
    }),
    [errors, params.name, params.name_kana],
  );

  const canSubmit = useMemo(() => {
    return (
      !!params.name &&
      !!params.name_kana &&
      !!params.site_name &&
      !!params.prefecture &&
      !!params.description &&
      !!params.genres.length
    );
  }, [
    params.description,
    params.genres.length,
    params.name,
    params.name_kana,
    params.prefecture,
    params.site_name,
  ]);

  const handleChange = useCallback(
    (
      e: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const { name, value } = e.currentTarget;

      setParams(params => ({ ...params, [name]: value }));
    },
    [],
  );
  const handleChangeGenre = useCallback(
    (genre_id: number) => {
      const genres = params.genres.includes(genre_id)
        ? params.genres.filter(genre => genre !== genre_id)
        : [...params.genres, genre_id];

      setParams(params => ({ ...params, genres }));
    },
    [params.genres],
  );
  const handleChangeImage = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { name, files } = e.currentTarget;

      if (!files) return;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.size > 10000000) {
          // limit 10MB
          window.alert('画像のファイルサイズは10MB以下です');

          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          setParams(params => ({
            ...params,
            [name]: {
              file,
              preview: reader.result as string,
            },
          }));
        };
        reader.readAsDataURL(file);
      }
    },
    [],
  );
  const handleSubmit = () => {
    if (!artistId) return;

    if (!canSubmit) return;

    const {
      cover_image,
      icon_image,
      formation_on_year,
      formation_on_month,
      formation_on_date,
      ...data
    } = params;

    const formation_on =
      formation_on_year || formation_on_month || formation_on_date
        ? [
            formation_on_year,
            formation_on_month.toString().padStart(2, '0'),
            formation_on_date.toString().padStart(2, '0'),
          ].join('-')
        : undefined;

    dispatch(
      updateFanclubEvent.start({
        ...data,
        artistId,
        formation_on,
        cover_image: cover_image.file ?? undefined,
        icon_image: icon_image.file ?? undefined,
      }),
    );
  };
  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    const fanclub = manages.find(fanclub => fanclub.group_id === artistId);

    if (!artistId || !fanclub || fanclub?.pivot?.type !== 3) {
      history.replace(PATH.Forbidden);

      return;
    }

    dispatch(getReady(artistId));
  }, [authReady, dispatch, artistId, history, manages]);

  useEffect(() => {
    if (JSON.stringify(errors) === JSON.stringify({})) return;

    window.scrollTo(0, 0);
    window.alert('入力項目を確認してください');
  }, [errors]);

  useEffect(() => {
    if (!current.fanclub.id) return;

    const { fanclub } = current;

    const [
      formation_on_year,
      formation_on_month,
      formation_on_date,
    ] = fanclub.formation_on
      ? fanclub.formation_on
          .split(' ')[0]
          .split('-')
          .map(s => Number(s))
      : [0, 0, 0];

    const params: UpdateFanclubInputParams = {
      cover_image: {
        file: null,
        preview: fanclub.cover_image,
      },
      icon_image: {
        file: null,
        preview: fanclub.icon_image,
      },
      name: fanclub.name,
      name_kana: fanclub.name_kana,
      site_name: fanclub.site_name,
      prefecture: fanclub.prefecture,
      formation_on_year,
      formation_on_month,
      formation_on_date,
      genres: fanclub.genres.map(genre => genre.id),
      description: fanclub.description,
      site_url: fanclub.site_url ?? '',
      twitter_url: fanclub.twitter_url ?? '',
      facebook_url: fanclub.facebook_url ?? '',
      instagram_url: fanclub.instagram_url ?? '',
    };

    setParams(params);
  }, [current, history]);

  useEffect(() => {
    if (!success || !artistId) return;

    history.replace(PATH.getAdminPath(artistId, 'MENU'));
  }, [artistId, history, success]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            コミュニティ設定 | {current.fanclub.name} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      <AdminCommunityEdit
        artistId={artistId}
        fanclub={params}
        genres={genres}
        prefectureList={prefectures}
        yearList={years}
        monthList={months}
        dateList={dates}
        onChange={handleChange}
        onChangeImage={handleChangeImage}
        onChangeGenre={handleChangeGenre}
        onBack={handleBack}
        onSubmit={handleSubmit}
        errors={validationErrors}
        canSubmit={canSubmit}
      />
    </>
  );
};

export default AdminCommunityEditContainer;
