export const GET_READY = 'ADMIN_MENU/GET_READY' as const;
export const GOT_READY = 'ADMIN_MENU/GOT_READY' as const;

export const GET_FANCLUB_DETAILS_START = 'ADMIN_MENU/GET_FANCLUB_DETAILS_START' as const;
export const GET_FANCLUB_DETAILS_SUCCEED = 'ADMIN_MENU/GET_FANCLUB_DETAILS_SUCCEED' as const;
export const GET_FANCLUB_DETAILS_FAIL = 'ADMIN_MENU/GET_FANCLUB_DETAILS_FAIL' as const;

export const GET_UNREAD_CHAT_START = 'ADMIN_MENU/GET_UNREAD_CHAT_START' as const;
export const GET_UNREAD_CHAT_SUCCEED = 'ADMIN_MENU/GET_UNREAD_CHAT_SUCCEED' as const;
export const GET_UNREAD_CHAT_FAIL = 'ADMIN_MENU/GET_UNREAD_CHAT_FAIL' as const;

export const CLEANUP = 'ADMIN_MENU/CLEANUP' as const;
