/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, join, takeLatest } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/authConstants';
import {
  setAccessToken,
  setAuthError,
  gotReady,
  logInEvent,
  logoutEvent,
  getUserProfileEvent,
  getUnreadCountEvent,
} from '../actions/authActions';
import {
  getAuthSessionStorage,
  getAuthCookie,
  setAuthCookie,
  setAuthSessionStorage,
  deleteAuthSessionStorage,
  deleteAuthCookie,
} from '../utils/utilHelper';

import { Status } from '../interfaces/commonInterFace';
import {
  LoginResponse,
  GetUserProfileResponse,
} from '../interfaces/authInterFace';
import { GetNotificationListResponse } from '../interfaces/newsInterFace';

// initialize
function* runGetReady() {
  const sessionToken = yield call(getAuthSessionStorage);
  const cookieToken = yield call(getAuthCookie);
  const token = sessionToken || cookieToken;

  if (token) {
    yield call(setAuthSessionStorage, token);
    yield put(setAccessToken(token));

    const getUserProfileTask = yield fork(
      runGetUserProfile,
      getUserProfileHandler,
    );
    yield join(getUserProfileTask);

    const getUnreadCountTask = yield fork(
      runGetUnreadCount,
      getUnreadCountHandler,
    );
    yield join(getUnreadCountTask);
  }

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// login
const loginHandler = api.getPostFactory();
function* runLogin(
  handler: typeof loginHandler,
  action: ReturnType<typeof logInEvent.start>,
) {
  const { saveAuthInCookie, ...data } = action.payload;

  try {
    const ReturnData: LoginResponse = yield call(handler, data, '/login');

    if (!ReturnData) {
      yield put(logInEvent.fail());

      return;
    }

    const { access_token } = ReturnData;

    yield call(setAuthSessionStorage, access_token);
    if (saveAuthInCookie) {
      yield call(setAuthCookie, access_token);
    }

    yield put(setAccessToken(access_token));

    yield put(logInEvent.succeed());
  } catch (error) {
    yield put(logInEvent.fail());
  }
}
export function* login(handler: typeof loginHandler) {
  yield takeLatest(ActionType.LOGIN_START, runLogin, handler);
}

// logout
const logoutHandler = api.getPostFactory(true);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function* runLogout(handler: typeof logoutHandler) {
  try {
    /**
     * ログアウトはapiを無視している
     */

    // const ReturnData: LogoutResponse = yield call(handler, {}, '/logout');

    // if (!ReturnData) {
    // yield put(logoutEvent.fail());
    // return;
    // }

    yield call(deleteAuthSessionStorage);
    yield call(deleteAuthCookie);

    yield put(logoutEvent.succeed());
  } catch (error) {
    yield put(logoutEvent.fail());
  }
}
export function* logout(handler: typeof logoutHandler) {
  yield takeLatest(ActionType.LOGOUT_START, runLogout, handler);
}

// get user profile
export const getUserProfileHandler = api.getGetFactory(true);
export function* runGetUserProfile(handler: typeof getUserProfileHandler) {
  try {
    const ReturnData: GetUserProfileResponse = yield call(
      handler,
      {},
      '/user/profile',
    );

    if (!ReturnData || !ReturnData.result) {
      yield put(getUserProfileEvent.fail());

      return;
    }

    yield put(getUserProfileEvent.succeed(ReturnData.result));
  } catch (error) {
    yield put(getUserProfileEvent.fail());
  }
}
export function* getUserProfile(handler: typeof getUserProfileHandler) {
  yield takeLatest(
    ActionType.GET_USER_PROFILE_START,
    runGetUserProfile,
    handler,
  );
}

// get unread count
const getUnreadCountHandler = api.getGetFactory(true);
function* runGetUnreadCount(handler: typeof getUnreadCountHandler) {
  try {
    const ReturnData: GetNotificationListResponse = yield call(
      handler,
      {},
      '/notifications',
    );

    if (!ReturnData) {
      yield put(getUnreadCountEvent.fail());

      return;
    }

    const unreadCount = ReturnData.result.unread_count;
    yield put(getUnreadCountEvent.succeed(unreadCount));
  } catch (error) {
    yield put(getUnreadCountEvent.fail());
  }
}
export function* getUnreadCount(handler: typeof getUnreadCountHandler) {
  yield takeLatest(
    ActionType.GET_UNREAD_COUNT_START,
    runGetUnreadCount,
    handler,
  );
}

export const authSagas = [
  fork(getReady),
  fork(login, loginHandler),
  fork(logout, logoutHandler),
  fork(getUserProfile, getUserProfileHandler),
  fork(getUnreadCount, getUnreadCountHandler),
];

export function* checkAndSetStatusError(status: Status) {
  if (
    status === Status.Unauthorized ||
    status === Status.Forbidden ||
    status === Status.NotFound
  ) {
    yield put(setAuthError(status));
  }
}
