export const GET_READY = 'ADMIN_VOICE_REGISTER/GET_READY' as const;
export const GOT_READY = 'ADMIN_VOICE_REGISTER/GOT_READY' as const;

export const GET_FANCLUB_DETAILS_START = 'ADMIN_VOICE_REGISTER/GET_FANCLUB_DETAILS_START' as const;
export const GET_FANCLUB_DETAILS_SUCCEED = 'ADMIN_VOICE_REGISTER/GET_FANCLUB_DETAILS_SUCCEED' as const;
export const GET_FANCLUB_DETAILS_FAIL = 'ADMIN_VOICE_REGISTER/GET_FANCLUB_DETAILS_FAIL' as const;

export const GET_VOICE_START = 'ADMIN_VOICE_REGISTER/GET_VOICE_START' as const;
export const GET_VOICE_SUCCEED = 'ADMIN_VOICE_REGISTER/GET_VOICE_SUCCEED' as const;
export const GET_VOICE_FAIL = 'ADMIN_VOICE_REGISTER/GET_VOICE_FAIL' as const;

export const GET_FC_CATEGORY_LIST_START = 'ADMIN_VOICE_REGISTER/GET_FC_CATEGORY_LIST_START' as const;
export const GET_FC_CATEGORY_LIST_SUCCEED = 'ADMIN_VOICE_REGISTER/GET_FC_CATEGORY_LIST_SUCCEED' as const;
export const GET_FC_CATEGORY_LIST_FAIL = 'ADMIN_VOICE_REGISTER/GET_FC_CATEGORY_LIST_FAIL' as const;

export const ADD_FC_CATEGORY_START = 'ADMIN_VOICE_REGISTER/ADD_FC_CATEGORY_START' as const;
export const ADD_FC_CATEGORY_SUCCEED = 'ADMIN_VOICE_REGISTER/ADD_FC_CATEGORY_SUCCEED' as const;
export const ADD_FC_CATEGORY_FAIL = 'ADMIN_VOICE_REGISTER/ADD_FC_CATEGORY_FAIL' as const;

export const CREATE_VOICE_START = 'ADMIN_VOICE_REGISTER/CREATE_VOICE_START' as const;
export const CREATE_VOICE_SUCCEED = 'ADMIN_VOICE_REGISTER/CREATE_VOICE_SUCCEED' as const;
export const CREATE_VOICE_FAIL = 'ADMIN_VOICE_REGISTER/CREATE_VOICE_FAIL' as const;

export const UPDATE_VOICE_START = 'ADMIN_VOICE_REGISTER/UPDATE_VOICE_START' as const;
export const UPDATE_VOICE_SUCCEED = 'ADMIN_VOICE_REGISTER/UPDATE_VOICE_SUCCEED' as const;
export const UPDATE_VOICE_FAIL = 'ADMIN_VOICE_REGISTER/UPDATE_VOICE_FAIL' as const;

export const DELETE_VOICE_START = 'ADMIN_VOICE_REGISTER/DELETE_VOICE_START' as const;
export const DELETE_VOICE_SUCCEED = 'ADMIN_VOICE_REGISTER/DELETE_VOICE_SUCCEED' as const;
export const DELETE_VOICE_FAIL = 'ADMIN_VOICE_REGISTER/DELETE_VOICE_FAIL' as const;

export const CLEANUP = 'ADMIN_VOICE_REGISTER/CLEANUP' as const;
