/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { FC, useMemo, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  addFCCategoryEvent,
  createMovieEvent,
  updateMovieEvent,
  deleteMovieEvent,
  cleanup,
} from '../../actions/adminMovieRegisterActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { DATE_TIME_FORMAT } from '../../interfaces/commonInterFace';
import {
  ContentRegisterMode,
  InputMovieParams,
  CreateMovieParams,
  UpdateMovieParams,
  InputMovieParamsInitialValue,
  RegisterMovieValidationErrors,
  ContentType,
} from '../../interfaces/contentInterFace';
import {
  Category,
  CategoryWithSelected,
} from '../../interfaces/categoryInterFace';

import { validateDateTime, validateJASRAC } from '../../utils/validationHelper';
import { openJASRACDatabase } from '../../utils/jasracHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminMovieRegister from '../../components/admin/adminMovieRegister';
import AdminMovieRegisterConfirm from '../../components/admin/adminMovieRegisterConfirm';

const AdminMovieRegisterContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId, contentId } = useParams<{
    artistId?: string;
    contentId?: string;
  }>();

  const [showConfirm, setShowConfirm] = useState(false);
  const [params, setParams] = useState<InputMovieParams>({
    ...InputMovieParamsInitialValue,
    release_at_date: moment().format('YYYY-MM-DD'),
    release_at_time: moment().format('HH:mm'),
  });
  const [newCategory, setNewCategory] = useState('');
  const {
    ready: authReady,
    profile: { user: me },
  } = useSelector(selector.auth.getAuthState);
  const {
    ready,
    loading,
    details,
    current,
    categories,
    addCategorySuccess,
    addCategoryErrors,
    registerMovieSuccess,
    contentMovieResult,
    registerMovieErrors,
    deleteMovieSuccess,
    deleteMovieError,
  } = useSelector(selector.adminMovieRegister.getAdminMovieRegisterState);
  const { open_area, copyright } = useSelector(selector.util.getSelectFields);
  const mode: ContentRegisterMode = useMemo(
    () => (contentId ? 'update' : 'create'),
    [contentId],
  );
  const title = useMemo(
    () => (mode === 'create' ? '動画新規投稿' : '動画編集'),
    [mode],
  );
  const categoryList: CategoryWithSelected[] = useMemo(
    () =>
      categories.map(category => ({
        ...category,
        selected: !!params.categories.find(id => id === category.id),
      })),
    [categories, params.categories],
  );
  const selectedCategoryList: Category[] = useMemo(() => {
    const list = categoryList.filter(c => c.selected);

    return list.length ? list : [{ id: 0, name: '未分類' }];
  }, [categoryList]);
  const openAreaField = useMemo(() => {
    return details.fanclub.paid_status === 2 && details.is_account_registerd
      ? open_area
      : open_area.filter(oa => oa.value !== 1);
  }, [details, open_area]);
  const isViewTab = useMemo(
    () => history.location?.state?.ADMIN_REGISTER_CONTENT?.mode === 'tab',
    [history.location],
  );
  const validationErrors: RegisterMovieValidationErrors = useMemo(
    () => ({
      thumb: registerMovieErrors.thumb?.[0] ?? '',
      subject: registerMovieErrors.subject?.[0] ?? '',
      categories: registerMovieErrors['categories.0']?.[0] ?? '',
      movie: registerMovieErrors.movie?.[0] ?? '',
      copyright: registerMovieErrors.copyright?.[0] ?? '',
      song_title:
        registerMovieErrors.song_title?.[0] ??
        validateJASRAC(params.song_title),
      composer:
        registerMovieErrors.composer?.[0] ?? validateJASRAC(params.composer),
      lyricist:
        registerMovieErrors.lyricist?.[0] ?? validateJASRAC(params.lyricist),
      work_code:
        registerMovieErrors.work_code?.[0] ?? validateJASRAC(params.work_code),
      release_at:
        registerMovieErrors.release_at?.[0] ??
        validateDateTime(`${params.release_at_date} ${params.release_at_time}`),
      close_at:
        registerMovieErrors.close_at?.[0] ??
        validateDateTime(`${params.close_at_date} ${params.close_at_time}`),
      open_area: registerMovieErrors.open_area?.[0] ?? '',
      is_delete_thumb: registerMovieErrors.is_delete_thumb?.[0] ?? '',
      is_release: registerMovieErrors.is_release?.[0] ?? '',
    }),
    [
      params.close_at_date,
      params.close_at_time,
      params.song_title,
      params.composer,
      params.lyricist,
      params.release_at_date,
      params.release_at_time,
      params.work_code,
      registerMovieErrors,
    ],
  );
  const isRequiredEntered = useMemo(() => {
    const {
      subject,
      release_at_date,
      movie,
      copyright,
      song_title,
      composer,
      lyricist,
      work_code,
    } = params;

    if (!subject || !movie.preview || !release_at_date) return false;

    if (
      copyright === 2 &&
      (!song_title || !composer || !lyricist || !work_code)
    )
      return false;

    return true;
  }, [params]);

  const create = useCallback(
    (params: InputMovieParams) => {
      if (!artistId) return;

      const {
        release_at_date,
        release_at_time,
        close_at_date,
        close_at_time,
        thumb,
        movie,
        copyright,
        song_title,
        composer,
        lyricist,
        work_code,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        is_delete_thumb: _,
        ...data
      } = params;

      const copyrights = {
        copyright,
        song_title: copyright === 2 ? song_title : '',
        composer: copyright === 2 ? composer : '',
        lyricist: copyright === 2 ? lyricist : '',
        work_code: copyright === 2 ? work_code : '',
      };

      const release_at = release_at_date
        ? moment(`${release_at_date} ${release_at_time || '00:00'}`).format(
            DATE_TIME_FORMAT,
          )
        : '';

      const close_at = close_at_date
        ? moment(`${close_at_date} ${close_at_time || '00:00'}`).format(
            DATE_TIME_FORMAT,
          )
        : '';

      const createMovieParams: CreateMovieParams = {
        ...data,
        ...copyrights,
        artistId,
        thumb: thumb.file,
        movie: movie.file,
        release_at,
        close_at,
        is_release: 1,
      };

      dispatch(createMovieEvent.start(createMovieParams));
    },
    [dispatch, artistId],
  );
  const update = useCallback(
    (params: InputMovieParams) => {
      if (!artistId) return;

      const {
        release_at_date,
        release_at_time,
        close_at_date,
        close_at_time,
        thumb,
        movie,
        copyright,
        song_title,
        composer,
        lyricist,
        work_code,
        is_delete_thumb,
        ...data
      } = params;

      const copyrights = {
        copyright,
        song_title: copyright === 2 ? song_title : '',
        composer: copyright === 2 ? composer : '',
        lyricist: copyright === 2 ? lyricist : '',
        work_code: copyright === 2 ? work_code : '',
      };

      const release_at = release_at_date
        ? moment(`${release_at_date} ${release_at_time || '00:00'}`).format(
            DATE_TIME_FORMAT,
          )
        : '';

      const close_at = close_at_date
        ? moment(`${close_at_date} ${close_at_time || '00:00'}`).format(
            DATE_TIME_FORMAT,
          )
        : '';

      const updateMovieParams: UpdateMovieParams = {
        ...data,
        ...copyrights,
        artistId,
        contentId: Number(contentId),
        thumb: thumb.file,
        movie: movie.file || undefined,
        release_at,
        close_at,
        is_release: 1,
        is_delete_thumb: is_delete_thumb || undefined,
      };

      dispatch(updateMovieEvent.start(updateMovieParams));
    },
    [contentId, dispatch, artistId],
  );

  const handleChangeTab = useCallback(
    (type: ContentType) => {
      if (!artistId) return;

      const typeName =
        type === 1
          ? 'blog'
          : type === 2
          ? 'photo'
          : type === 3
          ? 'movie'
          : type === 4
          ? 'voice'
          : '';

      if (!typeName) return;

      history.replace(PATH.getAdminRegisterContentPath(artistId, typeName), {
        ADMIN_REGISTER_CONTENT: {
          mode: 'tab',
        },
      });
    },
    [artistId, history],
  );

  const handleChange = useCallback(
    (
      e: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const { name, value } = e.currentTarget;

      if (name === 'copyright' || name === 'open_area') {
        setParams(params => ({ ...params, [name]: Number(value) }));

        return;
      }

      setParams(params => ({ ...params, [name]: value }));
    },
    [],
  );
  const handleChangeFile = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { name, files } = e.currentTarget;

      if (!files) return;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (name === 'movie' && file.size > 500000000) {
          // limit 500MB
          window.alert('動画のファイルサイズは500MB以下です');

          return;
        }

        if (name === 'thumb' && file.size > 10000000) {
          // limit 10MB
          window.alert('画像のファイルサイズは10MB以下です');

          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          setParams(params => ({
            ...params,
            [name]: {
              file,
              preview: reader.result as string,
            },
          }));
        };
        reader.readAsDataURL(file);
      }
    },
    [],
  );
  const handleChangeCategory = useCallback((category_id: number) => {
    setParams(params => ({
      ...params,
      categories: params.categories.includes(category_id)
        ? params.categories.filter(c => c !== category_id)
        : [...params.categories, category_id],
    }));
  }, []);
  const handleDeleteThumbnail = useCallback(() => {
    setParams(params => ({
      ...params,
      thumb: {
        file: null,
        preview: '',
      },
    }));

    if (mode === 'update')
      setParams(params => ({ ...params, is_delete_thumb: true }));
  }, [mode]);

  const handleConfirm = useCallback(() => {
    if (!isRequiredEntered) return;

    window.scrollTo(0, 0);
    setShowConfirm(true);
  }, [isRequiredEntered]);
  const handleModify = useCallback(() => {
    setShowConfirm(false);
    window.scrollTo(0, 0);
  }, []);
  const handlePublish = useCallback(() => {
    if (mode === 'create') create(params);
    else update(params);
  }, [create, mode, params, update]);
  const handleSaveAsDraft = useCallback(() => {
    if (!artistId) return;

    const {
      release_at_date,
      release_at_time,
      close_at_date,
      close_at_time,
      thumb,
      movie,
      is_delete_thumb,
      ...data
    } = params;

    const release_at = release_at_date
      ? moment(`${release_at_date} ${release_at_time || '00:00'}`).format(
          DATE_TIME_FORMAT,
        )
      : '';

    const close_at = close_at_date
      ? moment(`${close_at_date} ${close_at_time || '00:00'}`).format(
          DATE_TIME_FORMAT,
        )
      : '';

    const createMovieParams: CreateMovieParams = {
      ...data,
      artistId,
      thumb: thumb.file,
      movie: movie.file,
      release_at,
      close_at,
      is_release: 0,
    };

    const updateMovieParams: UpdateMovieParams = {
      ...createMovieParams,
      contentId: Number(contentId),
      is_delete_thumb: is_delete_thumb || undefined,
    };

    if (mode === 'create') dispatch(createMovieEvent.start(createMovieParams));
    else dispatch(updateMovieEvent.start(updateMovieParams));
  }, [contentId, dispatch, artistId, mode, params]);
  const handleDelete = useCallback(() => {
    if (!artistId) return;

    const res = window.confirm('本当に削除してもよろしいですか？');

    if (!res) return;

    dispatch(
      deleteMovieEvent.start({
        type: 3,
        artistId,
        contentId: Number(contentId),
      }),
    );
  }, [contentId, dispatch, artistId]);
  const handleClickJASRACDatabase = useCallback(() => {
    openJASRACDatabase();
  }, []);

  const handleBack = useCallback(() => {
    const res = window.confirm('編集した内容を破棄しますか？');
    if (!res) return;

    history.goBack();
  }, [history]);

  const handleChangeNewCategory = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setNewCategory(e.currentTarget.value);
    },
    [],
  );

  const handleAddCategory = useCallback(() => {
    if (!artistId) return;

    dispatch(
      addFCCategoryEvent.start({
        artistId,
        name: newCategory,
      }),
    );
  }, [dispatch, artistId, newCategory]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!artistId) {
      history.replace(PATH.HOME);

      return;
    }

    dispatch(getReady(artistId, Number(contentId)));
  }, [artistId, authReady, contentId, dispatch, history]);

  useEffect(() => {
    if (!current.detail.id) return;

    if (
      !(
        (current.detail.user_id === me.id && details.user_type === 2) ||
        details.user_type === 3
      )
    ) {
      history.replace(PATH.Forbidden);

      return;
    }

    const {
      detail: {
        release_at,
        close_at,
        subject,
        open_area,
        url,
        copyright,
        song_title,
        composer,
        lyricist,
        work_code,
      },
      categories,
      movie,
    } = current;

    const [release_at_date, release_at_time] = release_at
      ? moment(release_at)
          .format(DATE_TIME_FORMAT)
          .split(' ')
      : ['', ''];
    const [close_at_date, close_at_time] = close_at
      ? moment(close_at)
          .format(DATE_TIME_FORMAT)
          .split(' ')
      : ['', ''];
    const movie_preview = movie?.url ?? '';

    const params: InputMovieParams = {
      thumb: {
        file: null,
        preview: url ?? '',
      },
      subject: subject ?? '',
      categories: categories.map(c => c.value),
      open_area: open_area || '',
      movie: {
        file: null,
        preview: movie_preview,
      },
      copyright: copyright ?? 1,
      song_title: song_title ?? '',
      composer: composer ?? '',
      lyricist: lyricist ?? '',
      work_code: work_code ?? '',
      release_at_date,
      release_at_time,
      close_at_date,
      close_at_time,
      is_delete_thumb: false,
    };

    setParams(params);
  }, [current, details.user_type, history, me.id]);

  useEffect(() => {
    if (!addCategorySuccess) return;

    setNewCategory('');
    setParams(params => ({
      ...params,
      categories: [...params.categories, categories[categories.length - 1].id],
    }));
  }, [addCategorySuccess, categories]);

  useEffect(() => {
    if (!artistId) return;

    if (registerMovieSuccess) {
      history.replace(
        PATH.getAdminPath(artistId, 'CONTENTS'),
        contentMovieResult.is_release === 1
          ? {
              ADMIN_CONTENTS: {
                mode,
                title: contentMovieResult.subject,
                artistId,
                contentId: contentMovieResult.id,
              },
            }
          : undefined,
      );
    }
  }, [
    artistId,
    contentMovieResult.id,
    contentMovieResult.is_release,
    contentMovieResult.subject,
    history,
    mode,
    registerMovieSuccess,
  ]);

  useEffect(() => {
    if (!artistId) return;

    if (deleteMovieSuccess) {
      history.replace(PATH.getAdminPath(artistId, 'CONTENTS'));
    }

    if (deleteMovieError) {
      window.alert('動画の削除に失敗しました');
    }
  }, [deleteMovieError, deleteMovieSuccess, artistId, history]);

  useEffect(() => {
    if (JSON.stringify(registerMovieErrors) !== JSON.stringify({})) {
      setShowConfirm(false);
      window.scrollTo(0, 0);
      window.alert('入力項目を確認してください');
    }
  }, [registerMovieErrors, handleModify]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            {title} | {details.fanclub.name} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      {showConfirm ? (
        <AdminMovieRegisterConfirm
          mode={mode}
          title={title}
          params={params}
          fanclubName={details.fanclub.site_name}
          categoryList={selectedCategoryList}
          openAreaList={open_area}
          onPublish={handlePublish}
          onModify={handleModify}
          onBack={handleBack}
        />
      ) : (
        <AdminMovieRegister
          mode={mode}
          type={3}
          title={title}
          params={params}
          fanclubName={details.fanclub.site_name}
          categoryList={categoryList}
          openAreaList={openAreaField}
          copyrightList={copyright}
          isViewTab={isViewTab}
          onChangeTab={handleChangeTab}
          onChange={handleChange}
          onChangeFile={handleChangeFile}
          onClickDeleteThumbnail={handleDeleteThumbnail}
          onChangeCategory={handleChangeCategory}
          onClickJASRACDatabase={handleClickJASRACDatabase}
          canClickConfirm={isRequiredEntered}
          onConfirm={handleConfirm}
          onSaveAsDraft={handleSaveAsDraft}
          onDelete={handleDelete}
          onBack={handleBack}
          errors={validationErrors}
          newCategory={newCategory}
          onChangeNewCategory={handleChangeNewCategory}
          onAddCategory={handleAddCategory}
          categoryError={addCategoryErrors?.name?.[0]}
        />
      )}
    </>
  );
};

export default AdminMovieRegisterContainer;
