/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import IconClose from '../../atoms/iconParts/IconClose';
import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';

import { PaidStatus } from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgAdminMenuApply.module.scss';

interface Props {
  paidStatus?: PaidStatus;
  hasBankAccount?: boolean;
  isHiddenDenial?: boolean;
  onClickCross?: () => void;
  onClickButton?: () => void;
  cssClass?: string;
}

const OrgAdminMenuApply: FC<Props> = ({
  paidStatus = 1,
  hasBankAccount = false,
  isHiddenDenial = false,
  onClickCross = () => undefined,
  onClickButton = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.container, cssClass);

  if (paidStatus === 2 && hasBankAccount) return null;

  return (
    <div className={classNames}>
      {paidStatus === 1 ? (
        <ButtonRoundedCorners
          text="コンテンツを有料化する"
          type="Senary"
          onClick={onClickButton}
        />
      ) : paidStatus === 2 && !hasBankAccount ? (
        <>
          <p className={styles.title}>
            コミュニティを有料化することができます。
          </p>
          <ButtonRoundedCorners
            text="振込先口座を設定する"
            type="Senary"
            onClick={onClickButton}
            cssClass={styles.buttonMargin}
          />
          <p className={styles.read}>
            収益の振込先口座を設定してコミュニティを有料化してください。
          </p>
        </>
      ) : paidStatus === 3 ? (
        <>
          <p className={styles.title}>コンテンツ有料化申請中</p>
          <p className={styles.read}>
            {
              '審査完了まで最大3営業日頂戴いたします。\n完了までしばらくお待ちください。'
            }
          </p>
        </>
      ) : paidStatus === 4 ? (
        <>
          {!isHiddenDenial && (
            <>
              <div className={styles.crossWrapper}>
                <IconClose type="Quinary" onClick={onClickCross} />
              </div>
              <p className={styles.title}>
                コミュニティの有料化が否認されました。
              </p>
              <p className={styles.read}>
                {
                  '申請内容に不備があったため、コミュニティの有料化が否認となりました。\n再度コミュニティ有料化の申請を行う場合、内容をご確認の上、お申込みください。'
                }
              </p>
            </>
          )}
          <ButtonRoundedCorners
            text="コミュニティを有料化する"
            type="Senary"
            onClick={onClickButton}
            cssClass={isHiddenDenial ? '' : styles.buttonMarginTop}
          />
        </>
      ) : null}
    </div>
  );
};

export default OrgAdminMenuApply;
