import { Reducer } from 'redux';
import { Actions } from '../actions/openFCCompleteActions';
import * as ActionType from '../actions/openFCCompleteConstants';
import { UserForSearch } from '../interfaces/userInterFace';
import { AddFanclubMembersErrors } from '../interfaces/memberInterFace';

type State = {
  users: UserForSearch[];
  loading: boolean;
  success: boolean;
  errors: AddFanclubMembersErrors;
};

export const initialState: State = {
  users: [],
  loading: false,
  success: false,
  errors: {},
};

const openFCCompleteReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // search user list
    case ActionType.SEARCH_USER_LIST_START: {
      return {
        ...state,
      };
    }
    case ActionType.SEARCH_USER_LIST_SUCCEED: {
      return {
        ...state,
        users: action.payload.result.users.data,
      };
    }
    case ActionType.SEARCH_USER_LIST_FAIL: {
      return {
        ...state,
      };
    }

    // add fanclub members
    case ActionType.ADD_FANCLUB_MEMBERS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.ADD_FANCLUB_MEMBERS_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ActionType.ADD_FANCLUB_MEMBERS_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default openFCCompleteReducer;
