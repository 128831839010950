import React, { FC, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import { authenticateEvent, cleanup } from '../../actions/twitterActions';
import selector from '../../selectors';

import { useHistory, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { getTwitterAuthSessionStorage } from '../../utils/utilHelper';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';

import TwitterAuthFailure from '../../components/signup/twitterAuthFailure';

const TwitterOAuthCallbackContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLogin = useSelector(selector.auth.getIsLogin);
  const { authenticateSuccess, authenticateError, message } = useSelector(
    selector.twitter.getTwitterState,
  );
  const oauth_token_secret = getTwitterAuthSessionStorage();

  const handleBack = useCallback(() => {
    history.push(PATH.HOME);
  }, [history]);

  useGA(history.location);

  const { oauth_token, oauth_verifier } = parse(history.location.search) as {
    oauth_token?: string;
    oauth_verifier?: string;
  };
  // twitter認証後、twitterからもらうsecret等でログイン
  useEffect(() => {
    if (!(oauth_token && oauth_verifier && oauth_token_secret)) return;

    dispatch(
      authenticateEvent.start({
        oauth_token,
        oauth_verifier,
        oauth_token_secret,
      }),
    );
  }, [dispatch, oauth_token, oauth_token_secret, oauth_verifier]);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);
  useEffect(() => {
    if (authenticateSuccess && isLogin) {
      if (message === 'create') {
        history.replace(PATH.SIGNUP_ATTACH_GENRES);
      } else {
        // sessionStorageからrefererを取得しログイン前のページに遷移
        const referer = sessionStorage.getItem('referer');
        sessionStorage.removeItem('referer');
        history.replace(referer ?? PATH.HOME);
      }
    }
  }, [authenticateSuccess, history, isLogin, message]);

  if (authenticateError) {
    return <TwitterAuthFailure onBack={handleBack} />;
  }

  return (
    <>
      <Helmet>
        <title>KRAP</title>
      </Helmet>
      <OrgLoader />
    </>
  );
};

export default TwitterOAuthCallbackContainer;
