/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';

import { User } from '../../interfaces/userInterFace';

import ButtonFullWidth from '../common/atoms/buttonBasic/ButtonFullWidth';
import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';

import OrgFollowerAdminBar from '../common/organisms/followerParts/OrgFollowerAdminBar';
import OrgFollowerAdminNav from '../common/organisms/followerParts/OrgFollowerAdminNav';
import OrgUserListUnitMembernQuinary from '../common/organisms/listUnitParts/OrgUserListUnitMembernQuinary';
import OrgRefineSearchModal from '../common/organisms/modalParts/OrgRefineSearchModal';

import {
  SortType,
  RefineSearch,
  RefineSearchInitialValue,
} from '../../interfaces/searchInterFace';
import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../../interfaces/fanclubInterFace';

import * as styles from './adminFollowers.module.scss';

interface Props {
  details?: FanclubDetails;
  followerList?: User[];
  sortMode?: SortType;
  refineSearchParams?: RefineSearch;
  isAdmin?: boolean;
  isOpenSortModal?: boolean;
  isOpenRefineSearchModal?: boolean;
  isViewReadMoreButton?: boolean;
  handleBlockUser?: (userId: string) => void;
  handleChangeRefineSearchWord?: (e: React.FormEvent<HTMLInputElement>) => void;
  handleClickRefineSearchAttributeButton?: (name: 'free' | 'paid') => void;
  handleClickRefineSearchButton?: () => void;
  handleSubmitRefineSearch?: () => void;
  handleClickRefineSearchCross?: () => void;
  handleClickRefineSearchBlur?: () => void;
  handleClickBlockList?: () => void;
  handleClickSort?: () => void;
  handleClickSortBg?: () => void;
  handleSelectSort?: (index: number) => void;
  handleReadMore?: () => void;
  handleBack?: () => void;
}

const Component: FC<Props> = ({
  details = FanclubDetailsInitialValue,
  followerList = [],
  sortMode = 'DESC',
  refineSearchParams = RefineSearchInitialValue,
  isAdmin = false,
  isOpenSortModal = false,
  isOpenRefineSearchModal = false,
  isViewReadMoreButton = false,
  handleBlockUser = () => undefined,
  handleChangeRefineSearchWord = () => undefined,
  handleClickRefineSearchAttributeButton = () => undefined,
  handleClickRefineSearchButton = () => undefined,
  handleSubmitRefineSearch = () => undefined,
  handleClickRefineSearchCross = () => undefined,
  handleClickRefineSearchBlur = () => undefined,
  handleClickBlockList = () => undefined,
  handleClickSort = () => undefined,
  handleClickSortBg = () => undefined,
  handleSelectSort = () => undefined,
  handleReadMore = () => undefined,
  handleBack = () => undefined,
}) => {
  return (
    <div className={styles.containerWrap}>
      <McsTitleSecondary
        mainText="フォロワー管理"
        subText={details.fanclub.site_name}
        onClick={handleBack}
        cssClass={styles.contentTitle}
      />

      <OrgFollowerAdminBar
        followerCount={details.followerCount}
        paidFollowerCount={details.paidCount}
        blockedCount={details.blockedCount}
        cssClass={styles.adminBarContainer}
      />

      <div className={styles.refineSearchButtonWrapper}>
        <ButtonRoundedCorners
          text="絞り込み検索"
          type="Senary"
          onClick={handleClickRefineSearchButton}
        />
      </div>

      {isOpenRefineSearchModal && (
        <OrgRefineSearchModal
          params={refineSearchParams}
          onChangeWord={handleChangeRefineSearchWord}
          onClickBlur={handleClickRefineSearchBlur}
          onClickCross={handleClickRefineSearchCross}
          onClickAttributeButton={handleClickRefineSearchAttributeButton}
          onClickSearchButton={handleSubmitRefineSearch}
        />
      )}

      <OrgFollowerAdminNav
        cssClass={styles.adminNavContainer}
        sortType={sortMode}
        isOpenModal={isOpenSortModal}
        onClickBlockList={handleClickBlockList}
        onClickSort={handleClickSort}
        onClickSortBg={handleClickSortBg}
        onSelectSort={handleSelectSort}
      />

      <div className={styles.contentWrap}>
        <ul className={styles.followerListContainer}>
          {followerList.map((follower, i) => (
            <OrgUserListUnitMembernQuinary
              key={follower.id + i.toString()}
              isPIcon={follower?.pivot?.type === 1}
              user={follower}
              onClickButton={() => handleBlockUser(follower.name_id)}
              tagText="ブロック"
              isButtonHidden={!isAdmin}
              cssClass={styles.followerListItem}
            />
          ))}
        </ul>
      </div>

      {/* Read More Button */}
      {isViewReadMoreButton && (
        <ButtonFullWidth
          text="もっと見る"
          type="Senary"
          onClick={handleReadMore}
        />
      )}
    </div>
  );
};

export default Component;
