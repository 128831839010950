/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  getFollowingFanclubListEvent,
  getLikedContentListEvent,
  freejoinFanclubEvent,
  withdrawFanclubEvent,
  cleanup,
} from '../../actions/userActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { disableScroll, enableScroll } from '../../utils/scrollHelper';
import { PATH } from '../../utils/routerHelper';

import { UserTabMode } from '../../interfaces/userInterFace';
import { FanclubWithUserInfo } from '../../interfaces/fanclubInterFace';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import User from '../../components/user/user';

const UserContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { userId } = useParams<{ userId?: string }>();

  const [tabMode, setTabMode] = useState<UserTabMode>(
    history.location?.state?.USER?.mode ?? 'follow',
  );
  const [isViewModal, setViewManageList] = useState(false);
  const headerMode = useSelector(selector.util.getHeaderMode);
  const { ready: authReady, profile: me } = useSelector(
    selector.auth.getAuthState,
  );
  const isLogin = useSelector(selector.auth.getIsLogin);
  const { ready, profile, likes, pager, recommends, pickups } = useSelector(
    selector.user.getUserState,
  );
  const isLastPage = useSelector(selector.user.getIsLastPage);
  const { prefectures } = useSelector(selector.util.getSelectFields);

  const [follows, setFollows] = useState<number[]>([]);
  const [paid, setPaid] = useState<number[]>([]);

  // フォローFCのIDリスト作成
  useEffect(() => {
    const fanClubs: number[] = [];
    const paid: number[] = [];
    me.fanclubs.follows.map(fanClub => {
      fanClubs.push(fanClub.id);
      if (fanClub.pivot?.type === 1) {
        paid.push(fanClub.id);
      }

      return null;
    });

    setFollows(fanClubs);
    setPaid(paid);
  }, [me.fanclubs]);

  const isMe = useMemo(() => me.user.name_id === userId, [
    me.user.name_id,
    userId,
  ]);
  const pickupList = useMemo(() => {
    return pickups.filter(
      pickup =>
        !pickup.users ||
        !pickup.users.length ||
        pickup.users[0].pivot?.type !== 9,
    );
  }, [pickups]);

  const followList: FanclubWithUserInfo[] = useMemo(() => {
    const { manages } = me.fanclubs;

    return profile.fanclubs.follows.map(fanclub => ({
      ...fanclub,
      followed: !!follows.find(f => f === fanclub.id),
      managed: !!manages.find(f => f.id === fanclub.id),
      paid: !!paid.find(f => f === fanclub.id),
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.fanclubs.follows, follows]);

  const handleClickEdit = useCallback(() => {
    if (!isMe) return;

    history.push(PATH.SETTING_PROFILE);
  }, [history, isMe]);
  const handleClickManageList = useCallback(() => {
    setViewManageList(true);
    disableScroll();
  }, []);
  const handleClickFanclub = useCallback(
    (artistId: string) => {
      if (!artistId) return;

      history.push(PATH.getArtistPath(artistId));
    },
    [history],
  );
  const handleClickBlur = useCallback(() => {
    setViewManageList(false);
    enableScroll();
  }, []);
  const handleClickTab = useCallback((mode: UserTabMode) => {
    setTabMode(mode);
  }, []);
  const handleClickFollow = useCallback(
    (artistId: string, id: number) => {
      if (!isLogin) {
        history.push(PATH.SIGNUP);

        return;
      }
      const join = paid.includes(id);
      const followed = follows.includes(id);

      if (join) return;
      if (followed) {
        const followList = follows.filter(item => item !== id);
        setFollows(followList);
        dispatch(withdrawFanclubEvent.start({ artistId }));
      } else {
        setFollows([...follows, id]);
        dispatch(freejoinFanclubEvent.start({ artistId }));
      }
    },
    [dispatch, history, isLogin, paid, follows],
  );
  const handleClickContent = useCallback(
    (artistId: string, contentId: number) => {
      if (!artistId || !contentId) return;

      history.push(PATH.getContentPath(artistId, contentId));
    },
    [history],
  );
  const handleClickUser = useCallback(
    (userId: string) => {
      if (!userId) return;

      history.push(PATH.getUserPath(userId));
    },
    [history],
  );
  const handleClickReadMore = useCallback(() => {
    if (!userId) return;

    if (tabMode === 'follow') {
      dispatch(
        getFollowingFanclubListEvent.start({
          userId,
          page: pager.current_page + 1,
        }),
      );
    } else if (tabMode === 'like') {
      dispatch(
        getLikedContentListEvent.start({
          userId,
          page: pager.current_page + 1,
        }),
      );
    }
  }, [dispatch, userId, pager.current_page, tabMode]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      pr: {
        right: {
          text: 'コミュニティを作る',
          onClick: () => history.push(PATH.OPEN_FC_AGREEMENT),
        },
      },
      sns: true,
      nav: true,
      copyright: true,
    }),
    [history],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!isLogin) {
      history.replace(PATH.SIGNUP);
    } else if (!userId) {
      history.replace(PATH.HOME);
    } else {
      dispatch(getReady(userId));
    }
  }, [authReady, dispatch, history, isLogin, userId]);

  useEffect(() => {
    setViewManageList(false);
  }, [headerMode]);

  useEffect(() => {
    if (!isLogin || !userId) return;

    if (tabMode === 'follow') {
      dispatch(getFollowingFanclubListEvent.start({ userId, reset: true }));
    } else if (tabMode === 'like') {
      dispatch(getLikedContentListEvent.start({ userId, reset: true }));
    }
  }, [dispatch, isLogin, userId, tabMode]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>KRAP</title>
        ) : (
          <title>{profile.user.nickname} | KRAP</title>
        )}
      </Helmet>

      <OrgLoader isLoaded={authReady && ready} />

      <User
        profile={profile}
        prefectures={prefectures}
        tabMode={tabMode}
        follows={followList}
        likes={likes}
        onClickEdit={handleClickEdit}
        onClickManageList={handleClickManageList}
        onClickFanclub={handleClickFanclub}
        onClickBlur={handleClickBlur}
        onClickTab={handleClickTab}
        onClickFollowButton={handleClickFollow}
        onClickContent={handleClickContent}
        onClickUser={handleClickUser}
        onClickReadMore={handleClickReadMore}
        recommends={recommends}
        pickups={pickupList}
        isViewEditButton={isMe}
        isViewManageListModal={isViewModal}
        isViewReadMoreButton={!isLastPage}
      />
    </>
  );
};

export default UserContainer;
