/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import OrgHeaderFCList from '../headerParts/OrgHeaderFCList';

import { Fanclub } from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgFanclubListModal.module.scss';

interface Props {
  fanclubs?: Fanclub[];
  onClickFanclub?: (artistId: string) => void;
  onClickBlur?: () => void;
  cssClass?: string;
}

const OrgFanclubListModal: FC<Props> = ({
  fanclubs = [],
  onClickFanclub = () => undefined,
  onClickBlur = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFanclubListModalNavWrap, cssClass);

  return (
    <div className={classNames} onClick={onClickBlur}>
      <div className={styles.OrgFanclubListModalNavContent}>
        {fanclubs.length && (
          <OrgHeaderFCList fanclubList={fanclubs} onClick={onClickFanclub} />
        )}
      </div>
      <div className={styles.OrgFanclubListModalNavBg} />
    </div>
  );
};

export default OrgFanclubListModal;
