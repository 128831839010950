import React, { FC, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  cleanup,
  changeReceptionSettingsEvent,
} from '../../actions/settingActions';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import selector from '../../selectors';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import Setting from '../../components/setting/setting';

const SettingContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    ready: authReady,
    profile: {
      user,
      fanclubs: { follows },
    },
  } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const { ready, card } = useSelector(selector.setting.getSettingState);

  const isViewWithdrawPaidFC = useMemo(
    () => !!follows.find(fanclub => fanclub.pivot?.type === 1),
    [follows],
  );

  const handleLinkToEditUserProfile = useCallback(() => {
    history.push(PATH.SETTING_PROFILE);
  }, [history]);
  const handleLinkToChangeEmail = useCallback(() => {
    history.push(PATH.SETTING_EMAIL);
  }, [history]);
  const handleChangeReceptionSetting = useCallback(() => {
    dispatch(changeReceptionSettingsEvent.start());
  }, [dispatch]);
  const handleLinkToChangePassword = useCallback(() => {
    history.push(PATH.SETTING_PASSWORD);
  }, [history]);
  const handleLinkToChangeCredit = useCallback(() => {
    history.push(PATH.SETTING_CREDIT_CARD);
  }, [history]);
  const handleLinkToWithdrawPaidFanclub = useCallback(() => {
    history.push(PATH.SETTING_WITHDRAW_FC);
  }, [history]);
  const handleLinkToOpenFC = useCallback(() => {
    history.push(PATH.OPEN_FC_AGREEMENT);
  }, [history]);
  const handleLinkToWithdraw = useCallback(() => {
    history.push(PATH.SETTING_WITHDRAW);
  }, [history]);
  const handleLinkToHelp = useCallback(() => {
    history.push(PATH.CONTACT);
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!isLogin) {
      history.replace(PATH.SIGNUP);

      return;
    }

    dispatch(getReady());
  }, [authReady, dispatch, history, isLogin]);

  return (
    <>
      <Helmet>
        <title>設定 | KRAP</title>
      </Helmet>

      <OrgLoader isLoaded={authReady && ready} />

      <Setting
        user={user}
        card={card}
        isViewWithdrawPaidFC={isViewWithdrawPaidFC}
        handleLinkToEditUserProfile={handleLinkToEditUserProfile}
        handleLinkToChangeEmail={handleLinkToChangeEmail}
        handleChangeReceptionSetting={handleChangeReceptionSetting}
        handleLinkToChangePassword={handleLinkToChangePassword}
        handleLinkToChangeCredit={handleLinkToChangeCredit}
        handleLinkToWithdrawPaidFanclub={handleLinkToWithdrawPaidFanclub}
        handleLinkToOpenFC={handleLinkToOpenFC}
        handleLinkToWithdraw={handleLinkToWithdraw}
        handleLinkToHelp={handleLinkToHelp}
      />
    </>
  );
};

export default SettingContainer;
