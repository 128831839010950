import { Reducer } from 'redux';
import { Actions } from '../actions/adminCommunityEditActions';
import * as ActionType from '../actions/adminCommunityEditConstants';
import {
  FanclubDetails,
  FanclubDetailsInitialValue,
  UpdateFanclubErrors,
} from '../interfaces/fanclubInterFace';

type State = {
  current: FanclubDetails;
  ready: boolean;
  loading: boolean;
  errors: UpdateFanclubErrors;
  success: boolean;
};

const initialState: State = {
  current: FanclubDetailsInitialValue,
  ready: false,
  loading: true,
  errors: {},
  success: false,
};

const adminCommunityEditReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fanclub details
    case ActionType.GET_FANCLUB_DETAILS_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_SUCCEED: {
      return {
        ...state,
        current: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // create fanclub
    case ActionType.UPDATE_FANCLUB_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.UPDATE_FANCLUB_SUCCEED: {
      return {
        ...state,
        success: true,
        loading: false,
      };
    }
    case ActionType.UPDATE_FANCLUB_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default adminCommunityEditReducer;
