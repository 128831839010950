/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import IconShape from '../../atoms/iconParts/IconShape';
import * as styles from './McsIconWithTextSecondary.module.scss';

interface Props {
  mainText?: string;

  iconType?: string;
  cssClass?: string;
  onClick?: (e: React.MouseEvent<HTMLDListElement, MouseEvent>) => void;
}

const McsIconText: FC<Props> = ({
  mainText = 'Text',
  iconType = 'FavoriteLine',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsIconTextBase, cssClass, {
    [styles.McsIconTextColorGray]: iconType === 'FavoriteFill',
    [styles.McsIconTextColorRed]: iconType === 'FavoriteFill',
    [styles.McsIconTextColorGray]: iconType === 'SpeechBubble',
    [styles.McsIconTextColorGray]: iconType === 'Delate',
  });

  return (
    <dl className={classNames} onClick={onClick}>
      <dt>
        <IconShape type={iconType} />
      </dt>
      <dd>{mainText}</dd>
    </dl>
  );
};

export default McsIconText;
