import { Reducer } from 'redux';
import { Actions } from '../actions/adminPhotoRegisterActions';
import * as ActionType from '../actions/adminPhotoRegisterConstants';

import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../interfaces/fanclubInterFace';
import { Category, AddFCCategoryErrors } from '../interfaces/categoryInterFace';
import {
  ContentPhoto,
  ContentPhotoInitialValue,
  ContentDetails,
  ContentDetailsInitialValue,
  UpdatePhotoErrors,
  CreatePhotoErrors,
} from '../interfaces/contentInterFace';

type State = {
  details: FanclubDetails;
  current: ContentDetails;
  categories: Category[];
  ready: boolean;
  loading: boolean;
  addCategorySuccess: boolean;
  addCategoryErrors: AddFCCategoryErrors;
  registerPhotoSuccess: boolean;
  contentPhotoResult: ContentPhoto;
  registerPhotoErrors: CreatePhotoErrors & UpdatePhotoErrors;
  deletePhotoSuccess: boolean;
  deletePhotoError: boolean;
};

export const initialState: State = {
  details: FanclubDetailsInitialValue,
  current: ContentDetailsInitialValue,
  categories: [],
  ready: false,
  loading: false,
  addCategorySuccess: false,
  addCategoryErrors: {},
  registerPhotoSuccess: false,
  contentPhotoResult: ContentPhotoInitialValue,
  registerPhotoErrors: {},
  deletePhotoSuccess: false,
  deletePhotoError: false,
};

const adminPhotoRegisterReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fanclub details
    case ActionType.GET_FANCLUB_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_SUCCEED: {
      return {
        ...state,
        details: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // get photo
    case ActionType.GET_PHOTO_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_PHOTO_SUCCEED: {
      return {
        ...state,
        current: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_PHOTO_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // get fc category list
    case ActionType.GET_FC_CATEGORY_LIST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FC_CATEGORY_LIST_SUCCEED: {
      return {
        ...state,
        categories: action.payload.result.categories,
        loading: false,
      };
    }
    case ActionType.GET_FC_CATEGORY_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // add fc category
    case ActionType.ADD_FC_CATEGORY_START: {
      return {
        ...state,
        loading: true,
        addCategorySuccess: false,
        addCategoryErrors: {},
      };
    }
    case ActionType.ADD_FC_CATEGORY_SUCCEED: {
      return {
        ...state,
        categories: [
          ...state.categories,
          {
            fanclub_id: 0, // action.payload.params.fanclub_group_id
            id: action.payload.result.category_id,
            name: action.payload.result.name,
          },
        ],
        loading: false,
        addCategorySuccess: true,
      };
    }
    case ActionType.ADD_FC_CATEGORY_FAIL: {
      return {
        ...state,
        loading: false,
        addCategoryErrors: action.payload.errors,
      };
    }

    // create photo
    case ActionType.CREATE_PHOTO_START: {
      return {
        ...state,
        loading: true,
        registerPhotoSuccess: false,
        registerPhotoErrors: {},
      };
    }
    case ActionType.CREATE_PHOTO_SUCCEED: {
      return {
        ...state,
        loading: false,
        registerPhotoSuccess: true,
        contentPhotoResult: action.payload.result.content,
      };
    }
    case ActionType.CREATE_PHOTO_FAIL: {
      return {
        ...state,
        loading: false,
        registerPhotoErrors: action.payload.errors,
      };
    }

    // update photo
    case ActionType.UPDATE_PHOTO_START: {
      return {
        ...state,
        loading: true,
        registerPhotoSuccess: false,
        registerPhotoErrors: {},
      };
    }
    case ActionType.UPDATE_PHOTO_SUCCEED: {
      return {
        ...state,
        loading: false,
        registerPhotoSuccess: true,
        contentPhotoResult: action.payload.result.content,
      };
    }
    case ActionType.UPDATE_PHOTO_FAIL: {
      return {
        ...state,
        loading: false,
        registerPhotoErrors: action.payload.errors,
      };
    }

    // delete photo
    case ActionType.DELETE_PHOTO_START: {
      return {
        ...state,
        loading: true,
        deletePhotoSuccess: false,
        deletePhotoError: false,
      };
    }
    case ActionType.DELETE_PHOTO_SUCCEED: {
      return {
        ...state,
        loading: false,
        deletePhotoSuccess: true,
      };
    }
    case ActionType.DELETE_PHOTO_FAIL: {
      return {
        ...state,
        loading: false,
        deletePhotoError: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default adminPhotoRegisterReducer;
