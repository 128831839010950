export const GET_READY = 'HOME/GET_READY' as const;
export const GOT_READY = 'HOME/GOT_READY' as const;

export const GET_PICKUP_FANCLUB_LIST_START = 'HOME/GET_PICKUP_FANCLUB_LIST_START' as const;
export const GET_PICKUP_FANCLUB_LIST_SUCCEED = 'HOME/GET_PICKUP_FANCLUB_LIST_SUCCEED' as const;
export const GET_PICKUP_FANCLUB_LIST_FAIL = 'HOME/GET_PICKUP_FANCLUB_LIST_FAIL' as const;

export const GET_RECOMMEND_FANCLUB_LIST_START = 'HOME/GET_RECOMMEND_FANCLUB_LIST_START' as const;
export const GET_RECOMMEND_FANCLUB_LIST_SUCCEED = 'HOME/GET_RECOMMEND_FANCLUB_LIST_SUCCEED' as const;
export const GET_RECOMMEND_FANCLUB_LIST_FAIL = 'HOME/GET_RECOMMEND_FANCLUB_LIST_FAIL' as const;

export const GET_CONTENT_LIST_WITH_PAGER_START = 'HOME/GET_CONTENT_LIST_WITH_PAGER_START' as const;
export const GET_CONTENT_LIST_WITH_PAGER_SUCCEED = 'HOME/GET_CONTENT_LIST_WITH_PAGER_SUCCEED' as const;
export const GET_CONTENT_LIST_WITH_PAGER_FAIL = 'HOME/GET_CONTENT_LIST_WITH_PAGER_FAIL' as const;

export const CLEANUP = 'HOME/CLEANUP' as const;
