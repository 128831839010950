/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, join, takeLatest } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/homeConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getPickupFanclubListEvent,
  getRecommendFanclubListEvent,
  getContentListEvent,
} from '../actions/homeActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import { GetPickupFanclubListResponse } from '../interfaces/fanclubInterFace';
import { GetContentListResponse } from '../interfaces/contentInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const userType = action.payload.type;

  if (userType === 'fan') {
    const getContentListTask = yield fork(
      runGetContentList,
      getContentListHandler,
      getContentListEvent.start(action.payload.params),
    );
    yield join(getContentListTask);

    yield put(gotReady());

    yield put(
      getPickupFanclubListEvent.start({
        scope: 'pickup',
        limit: 4,
      }),
    );
    yield put(
      getRecommendFanclubListEvent.start({
        scope: 'recommend',
        limit: 10,
      }),
    );
  }

  if (userType === 'guest' || userType === 'service') {
    const getPickupFanclubListTask = yield fork(
      runGetPickupFanclubList,
      getPickupFanclubListHandler,
      getPickupFanclubListEvent.start({
        scope: 'pickup',
        limit: 4,
      }),
    );
    yield join(getPickupFanclubListTask);

    const getRecommendFanclubListTask = yield fork(
      runGetRecommendFanclubList,
      getRecommendFanclubListHandler,
      getRecommendFanclubListEvent.start({
        scope: 'recommend',
        limit: 10,
      }),
    );
    yield join(getRecommendFanclubListTask);

    yield put(gotReady());

    yield put(getContentListEvent.start(action.payload.params));
  }
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get pickup fanclub list
const getPickupFanclubListHandler = api.getGetFactory(true);
function* runGetPickupFanclubList(
  handler: typeof getPickupFanclubListHandler,
  action: ReturnType<typeof getPickupFanclubListEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: GetPickupFanclubListResponse = yield call(
      handler,
      data,
      '/fanclub',
    );

    if (!ReturnData) {
      yield put(getPickupFanclubListEvent.fail());

      return;
    }

    yield put(getPickupFanclubListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getPickupFanclubListEvent.fail());
  }
}
export function* getPickupFanclubList(
  handler: typeof getPickupFanclubListHandler,
) {
  yield takeLatest(
    ActionType.GET_PICKUP_FANCLUB_LIST_START,
    runGetPickupFanclubList,
    handler,
  );
}

// get recommend fanclub list
const getRecommendFanclubListHandler = api.getGetFactory(true);
function* runGetRecommendFanclubList(
  handler: typeof getRecommendFanclubListHandler,
  action: ReturnType<typeof getRecommendFanclubListEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: GetPickupFanclubListResponse = yield call(
      handler,
      data,
      '/fanclub',
    );

    if (!ReturnData) {
      yield put(getRecommendFanclubListEvent.fail());

      return;
    }

    yield put(getRecommendFanclubListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getRecommendFanclubListEvent.fail());
  }
}
export function* getRecommendFanclubList(
  handler: typeof getRecommendFanclubListHandler,
) {
  yield takeLatest(
    ActionType.GET_RECOMMEND_FANCLUB_LIST_START,
    runGetRecommendFanclubList,
    handler,
  );
}

// get content list
const getContentListHandler = api.getPostFactory(true);
function* runGetContentList(
  handler: typeof getContentListHandler,
  action: ReturnType<typeof getContentListEvent.start>,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { reset: _, type, ...data } = action.payload.params;
  const params = type === 0 ? data : { ...data, type };

  try {
    const ReturnData: GetContentListResponse = yield call(
      handler,
      params,
      '/contents/home',
    );

    if (!ReturnData) {
      yield put(getContentListEvent.fail());

      return;
    }

    yield put(
      getContentListEvent.succeed(action.payload.params, ReturnData.result),
    );
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getContentListEvent.fail());
  }
}
export function* getContentList(handler: typeof getContentListHandler) {
  yield takeLatest(
    ActionType.GET_CONTENT_LIST_WITH_PAGER_START,
    runGetContentList,
    handler,
  );
}

export const homeSagas = [
  fork(getReady),
  fork(getPickupFanclubList, getPickupFanclubListHandler),
  fork(getRecommendFanclubList, getRecommendFanclubListHandler),
  fork(getContentList, getContentListHandler),
];
