export const tweet = (text: string, path: string, hashtag = 'KRAP') => {
  const href = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    text,
  )}&url=${encodeURIComponent(path)}&hashtags=${hashtag}`;

  window.open(
    href,
    'window',
    'width=550, height=450, personalbar=0, toolbar=0, scrollbars=1',
  );
};

export const shareFB = (path: string) => {
  const href = encodeURI(`http://www.facebook.com/share.php?u=${path}`);
  window.open(
    href,
    'window',
    'width=550, height=450, personalbar=0, toolbar=0, scrollbars=1',
  );
};

export const shareLINE = (text: string) => {
  const href = encodeURI(`https://line.me/R/msg/text/?${text}`);
  window.open(
    href,
    'window',
    'width=550, height=450, personalbar=0, toolbar=0, scrollbars=1',
  );
};
