/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import TitleWithSubLine from '../../atoms/title/TitleWithSubLine';
import * as styles from './McsTitleTertiary.module.scss';

interface Props {
  mainText?: string;
  subText?: string;
  iconType?: string;
  cssClass?: string;
  onClick?: () => void;
}

const McsTitle: FC<Props> = ({
  mainText = 'Text',
  subText = 'SubText',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsTitleBase, cssClass);

  return (
    <div className={classNames}>
      <TitleWithSubLine
        mainText={mainText}
        subText={subText}
        cssClass={styles.McsTitleTitle}
      />
    </div>
  );
};

export default McsTitle;
