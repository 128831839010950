/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './SelectBox.module.scss';
import { SelectListObject } from '../../../../interfaces/formPartsInterFace';

interface Props {
  value: string;
  type?: string;
  inputName?: string;
  placeholder?: string;
  cssClass?: string;

  selectList: SelectListObject[];
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.FormEvent<HTMLSelectElement>, key?: any) => void;

  isDisabled?: boolean;
  isError?: boolean;
  errorText?: string;
}

const Input: FC<Props> = ({
  value = '',
  type = 'Primary',
  inputName = '',
  placeholder = '',
  cssClass = '',
  selectList = [],
  onClick = () => undefined,
  onFocus = () => undefined,
  onBlur = () => undefined,
  onChange = () => undefined,

  isDisabled = false,
  isError = false,
  errorText = 'Error',
}) => {
  const classNames = ClassNames(styles.SelectBoxBase, cssClass, {
    [styles.SelectBoxPrimary]: type === 'Primary', // Primary
    [styles.SelectBoxSecondary]: type === 'Secondary', // Secondary
    [styles.SelectBoxTertiary]: type === 'Tertiary', // Tertiary

    [styles.SelectBoxDisabled]: isDisabled, //  isDisabled
    [styles.SelectBoxError]: isError, // isError
    [styles.SelectBoxErrorWithOutBorder]:
      isError && (type === 'Tertiary' || type === 'Secondary'), // isError &&
  });

  return (
    <>
      <div className={classNames}>
        <span />
        <select name={inputName} value={value} onChange={onChange}>
          <option
            key={`default_${inputName}`}
            value=""
            placeholder={placeholder}
          >
            {placeholder}
          </option>
          {selectList.map((Item: SelectListObject) => (
            <option
              key={
                Item.value ? Item.value : `${Item.label}_select_${inputName}`
              }
              value={Item.value ? Item.value : Item.value}
            >
              {Item.label}
            </option>
          ))}
        </select>
      </div>
      {isError && <p className={styles.SelectBoxErrorText}>{errorText}</p>}
    </>
  );
};

export default Input;
