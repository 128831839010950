import React, { FC } from 'react';
import ClassNames from 'classnames';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import OrgHeroHome from '../common/organisms/heroParts/OrgHeroHome';
import OrgRecommendFCList from '../common/organisms/listParts/OrgRecommendFCList';
import OrgPickUpFCList from '../common/organisms/listParts/OrgPickUpFCList';
import OrgTimeLIne from '../common/organisms/listParts/OrgTimeLIne';

import {
  Fanclub,
  FanclubInitialValue,
} from '../../interfaces/fanclubInterFace';
import { ContentListType, Content } from '../../interfaces/contentInterFace';

import * as styles from './home.module.scss';

type Props = {
  isLoading?: boolean;
  isLogin?: boolean;
  isServiceUser?: boolean;
  isFanUser?: boolean;
  hero?: Fanclub;
  pickups?: Fanclub[];
  recommends?: Fanclub[];
  type?: ContentListType;
  contents?: Content[];
  handleClickLogin?: () => void;
  handleStartForFree?: () => void;
  handleFindArtist?: () => void;
  handleChangeTab?: (type: ContentListType) => void;
  handleClickContent?: (artistId: string, contentId: number) => void;
  handleClickUser?: (userId: string) => void;
  handleReadMoreContents?: () => void;
  handleClickFanclub?: (artistId: string) => void;
  isLastPage?: boolean;
};

const Component: FC<Props> = ({
  isLoading = false,
  isLogin = false,
  isServiceUser = false,
  isFanUser = false,
  hero = FanclubInitialValue,
  pickups = [],
  recommends = [],
  type = 0,
  contents = [],
  handleClickLogin = () => undefined,
  handleStartForFree = () => undefined,
  handleFindArtist = () => undefined,
  handleChangeTab = () => undefined,
  handleClickContent = () => undefined,
  handleClickUser = () => undefined,
  handleReadMoreContents = () => undefined,
  handleClickFanclub = () => undefined,
  isLastPage = false,
}) => {
  const classNames = ClassNames(styles.homeWrap);

  return (
    <div className={classNames}>
      {/* NotLogin */}
      {!isLogin && (
        <div className={styles.headButtonWrap}>
          <ButtonRoundedCorners
            type="Secondary"
            size="Tertiary"
            text="ログイン"
            onClick={handleClickLogin}
            cssClass={styles.headButtonItem}
          />
          <ButtonRoundedCorners
            size="Tertiary"
            text="無料ではじめる"
            onClick={handleStartForFree}
            cssClass={styles.headButtonItem}
          />
        </div>
      )}

      {isServiceUser && (
        <div className={styles.headButtonWrap}>
          <ButtonRoundedCorners
            size="Tertiary"
            text="アーティストを探す"
            onClick={handleFindArtist}
            cssClass={styles.headButtonItem}
          />
        </div>
      )}

      {isFanUser && (
        <OrgTimeLIne
          // loading
          isLoading={isLoading}
          // tabs
          type={type}
          onChangeTab={handleChangeTab}
          // contents
          contents={contents}
          isViewFanclub
          onClickContent={handleClickContent}
          onClickUser={handleClickUser}
          onClickFanclub={handleClickFanclub}
          // read more
          isViewReadMoreButton={!isLastPage}
          onReadMore={handleReadMoreContents}
        />
      )}

      {/* Hero Banner */}
      {!isFanUser && (
        <OrgHeroHome
          fanclub={hero}
          onClick={() => handleClickFanclub(hero.group_id)}
        />
      )}

      {/* おすすめコミュニティ */}
      <OrgRecommendFCList
        fanclubList={recommends}
        onClickFanclub={handleClickFanclub}
      />

      {/* PICK UP */}
      <OrgPickUpFCList
        fanclubList={pickups}
        onClickFanclub={handleClickFanclub}
        cssClass={styles.contentWrap}
      />

      {/* TimeLine  */}
      {!isFanUser && (
        <OrgTimeLIne
          // loading
          isLoading={isLoading}
          // tabs
          type={type}
          onChangeTab={handleChangeTab}
          // contents
          contents={contents}
          isViewFanclub
          onClickContent={handleClickContent}
          onClickUser={handleClickUser}
          onClickFanclub={handleClickFanclub}
          // read more
          isViewReadMoreButton={!isLastPage}
          onReadMore={handleReadMoreContents}
        />
      )}
    </div>
  );
};

export default Component;
