import { Reducer } from 'redux';
import { Actions } from '../actions/adminMovieRegisterActions';
import * as ActionType from '../actions/adminMovieRegisterConstants';

import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../interfaces/fanclubInterFace';
import { Category, AddFCCategoryErrors } from '../interfaces/categoryInterFace';
import {
  ContentMovie,
  ContentMovieInitialValue,
  ContentDetails,
  ContentDetailsInitialValue,
  UpdateMovieErrors,
  CreateMovieErrors,
} from '../interfaces/contentInterFace';

type State = {
  details: FanclubDetails;
  current: ContentDetails;
  categories: Category[];
  ready: boolean;
  loading: boolean;
  addCategorySuccess: boolean;
  addCategoryErrors: AddFCCategoryErrors;
  registerMovieSuccess: boolean;
  contentMovieResult: ContentMovie;
  registerMovieErrors: CreateMovieErrors & UpdateMovieErrors;
  deleteMovieSuccess: boolean;
  deleteMovieError: boolean;
};

export const initialState: State = {
  details: FanclubDetailsInitialValue,
  current: ContentDetailsInitialValue,
  categories: [],
  ready: false,
  loading: false,
  addCategorySuccess: false,
  addCategoryErrors: {},
  registerMovieSuccess: false,
  contentMovieResult: ContentMovieInitialValue,
  registerMovieErrors: {},
  deleteMovieSuccess: false,
  deleteMovieError: false,
};

const adminMovieRegisterReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fanclub details
    case ActionType.GET_FANCLUB_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_SUCCEED: {
      return {
        ...state,
        details: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // get movie
    case ActionType.GET_MOVIE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_MOVIE_SUCCEED: {
      return {
        ...state,
        current: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_MOVIE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // get fc category list
    case ActionType.GET_FC_CATEGORY_LIST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FC_CATEGORY_LIST_SUCCEED: {
      return {
        ...state,
        categories: action.payload.result.categories,
        loading: false,
      };
    }
    case ActionType.GET_FC_CATEGORY_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // add fc category
    case ActionType.ADD_FC_CATEGORY_START: {
      return {
        ...state,
        loading: true,
        addCategorySuccess: false,
        addCategoryErrors: {},
      };
    }
    case ActionType.ADD_FC_CATEGORY_SUCCEED: {
      return {
        ...state,
        categories: [
          ...state.categories,
          {
            fanclub_id: 0, // action.payload.params.fanclub_group_id
            id: action.payload.result.category_id,
            name: action.payload.result.name,
          },
        ],
        loading: false,
        addCategorySuccess: true,
      };
    }
    case ActionType.ADD_FC_CATEGORY_FAIL: {
      return {
        ...state,
        loading: false,
        addCategoryErrors: action.payload.errors,
      };
    }

    // create movie
    case ActionType.CREATE_MOVIE_START: {
      return {
        ...state,
        loading: true,
        registerMovieSuccess: false,
        registerMovieErrors: {},
      };
    }
    case ActionType.CREATE_MOVIE_SUCCEED: {
      return {
        ...state,
        loading: false,
        registerMovieSuccess: true,
        contentMovieResult: action.payload.result.content,
      };
    }
    case ActionType.CREATE_MOVIE_FAIL: {
      return {
        ...state,
        loading: false,
        registerMovieErrors: action.payload.errors,
      };
    }

    // update movie
    case ActionType.UPDATE_MOVIE_START: {
      return {
        ...state,
        loading: true,
        registerMovieSuccess: false,
        registerMovieErrors: {},
      };
    }
    case ActionType.UPDATE_MOVIE_SUCCEED: {
      return {
        ...state,
        loading: false,
        registerMovieSuccess: true,
        contentMovieResult: action.payload.result.content,
      };
    }
    case ActionType.UPDATE_MOVIE_FAIL: {
      return {
        ...state,
        loading: false,
        registerMovieErrors: action.payload.errors,
      };
    }

    // delete movie
    case ActionType.DELETE_MOVIE_START: {
      return {
        ...state,
        loading: true,
        deleteMovieSuccess: false,
        deleteMovieError: false,
      };
    }
    case ActionType.DELETE_MOVIE_SUCCEED: {
      return {
        ...state,
        loading: false,
        deleteBlogSuccess: true,
      };
    }
    case ActionType.DELETE_MOVIE_FAIL: {
      return {
        ...state,
        loading: false,
        deleteBlogError: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default adminMovieRegisterReducer;
