/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react';

import IconArrow from '../common/atoms/iconParts/IconArrow';

import McsIconWithTextSecondary from '../common/molecules/iconWithText/McsIconWithTextSecondary';
import McsSquareSNSButtons from '../common/molecules/commonParts/McsSquareSNSButtons';
import McsNavBlogPrevNext from '../common/molecules/navParts/McsNavBlogPrevNext';
import McsIconLabelSquareTertiary from '../common/molecules/iconWithLabel/McsIconLabelSquareTertiary';

import OrgHeroContent from '../common/organisms/heroParts/OrgHeroContent';
import OrgContentBlog from '../common/organisms/contentParts/OrgContentBlog';
import OrgContentPhoto from '../common/organisms/contentParts/OrgContentPhoto';
import OrgContentMovie from '../common/organisms/contentParts/OrgContentMovie';
import OrgContentVoice from '../common/organisms/contentParts/OrgContentVoice';
import OrgRecentImagesFCList from '../common/organisms/listParts/OrgRecentImagesFCList';
import OrgRecentBlogFCList from '../common/organisms/listParts/OrgRecentBlogFCList';
import OrgFCListUnitComment from '../common/organisms/listUnitParts/OrgFCListUnitComment';
import OrgRecommendFCList from '../common/organisms/listParts/OrgRecommendFCList';
import OrgJoinFCModal from '../common/organisms/modalParts/OrgJoinFCModal';
import OrglikedMemberList from '../common/organisms/listUnitParts/OrglikedMemberList';

import {
  Fanclub,
  FanclubInitialValue,
} from '../../interfaces/fanclubInterFace';
import {
  ContentDetails,
  ContentDetailsInitialValue,
  ContentPhoto,
  ContentBlog,
  OpenArea,
} from '../../interfaces/contentInterFace';
import { likedUser } from '../../interfaces/likesInterFace';

import * as styles from './content.module.scss';

interface Props {
  details?: ContentDetails;
  recentPhotos?: ContentPhoto[];
  blogs?: ContentBlog[];
  recommends?: Fanclub[];
  users?: likedUser[];
  photos?: string[];
  onClickCreateUser?: () => void;
  onClickTwitter?: () => void;
  onClickFaceBook?: () => void;
  onClickLine?: () => void;
  onClickComment?: () => void;
  onClickLike?: () => void;
  onLike?: () => void;
  prevLinkText?: string;
  topLinkText?: string;
  nextLinkText?: string;
  onClickPrevLink?: () => void;
  onClickTopLink?: () => void;
  onClickNextLink?: () => void;
  onClickContent?: (artistId: string, contentId: number) => void;
  onClickFanclub?: (artistId: string) => void;
  // modal
  errorOpenArea?: 0 | OpenArea;
  errorFanclub?: Fanclub;
  onClickModalButton?: () => void;
  onClickPhotoModalOpen?: () => void;
}

const Component: FC<Props> = ({
  details = ContentDetailsInitialValue,
  recentPhotos = [],
  blogs = [],
  recommends = [],
  users = [],
  photos = [],
  onClickCreateUser = () => undefined,
  onClickTwitter = () => undefined,
  onClickFaceBook = () => undefined,
  onClickLine = () => undefined,
  onClickComment = () => undefined,
  onClickLike = () => undefined,
  onLike = () => undefined,
  prevLinkText = '',
  topLinkText = '',
  nextLinkText = '',
  onClickPrevLink = () => undefined,
  onClickTopLink = () => undefined,
  onClickNextLink = () => undefined,
  onClickContent = () => undefined,
  onClickFanclub = () => undefined,
  // modal
  errorOpenArea = 0,
  errorFanclub = FanclubInitialValue,
  onClickModalButton = () => undefined,
  onClickPhotoModalOpen = () => undefined,
}) => {
  return (
    <div className={styles.detailWrap}>
      {/* コンテンツ */}
      {details.detail.type === 2 ? (
        <div className={styles.photoWrap}>
          <OrgContentPhoto photos={photos} />
        </div>
      ) : details.detail.type === 3 ? (
        <div className={styles.movieWrap}>
          <OrgContentMovie
            movie={details.movie?.url}
            thumbnail={details.detail.url || ''}
          />
        </div>
      ) : details.detail.type === 4 ? (
        <div className={styles.voiceWrap}>
          <OrgContentVoice
            thumbnail={details.detail.url || ''}
            voice={details.voice?.url}
            cssClass={styles.voice}
          />
        </div>
      ) : null}

      {/* Hero Banner */}
      <div>
        <OrgHeroContent
          detail={details.detail}
          categories={details.categories}
          isViewThumbnail={details.detail.type === 1}
        />
      </div>

      {/* 投稿者 */}
      <div
        className={styles.userWrap}
        onClick={() => onClickFanclub(details.detail.fanclub.group_id)}
      >
        <McsIconLabelSquareTertiary
          src={details.detail.fanclub.icon_image}
          mainText={details.detail.fanclub.name}
          subText={details.detail.fanclub.site_name}
          size="Senary"
        />
        <IconArrow type="Tertiary" />
      </div>

      {/* コンテンツ */}
      {details.detail.type === 1 ? (
        <div className={styles.blogWrap}>
          <OrgContentBlog
            body={details.detail.body ?? ''}
            photos={photos}
            onClickPhotoModalOpen={onClickPhotoModalOpen}
          />
        </div>
      ) : null}

      {/* Like */}
      <div className={styles.likeFollowWrap}>
        {/* いいね状態で色を変える */}
        <McsIconWithTextSecondary
          iconType={details.is_like ? 'FavoriteFill' : 'FavoriteLine'}
          mainText={details.detail.total_like.toString()}
          cssClass={styles.likeFollowItem}
          onClick={onLike}
        />
        <McsIconWithTextSecondary
          iconType="SpeechBubble"
          mainText={details.comments.length.toString()}
          cssClass={styles.likeFollowItem}
          onClick={onClickComment}
        />
      </div>
      {/* いいねした人の一覧 */}
      {details.detail.total_like > 0 && (
        <div className={styles.OrgHeroContent}>
          <OrglikedMemberList
            userList={users}
            total={details.detail.total_like}
            text="件のいいね"
            onLinkClick={onClickLike}
          />
        </div>
      )}

      {/* SNS */}
      <div className={styles.snsWrap}>
        <McsSquareSNSButtons
          onTwitterClick={onClickTwitter}
          onFaceBookClick={onClickFaceBook}
          onLineClick={onClickLine}
        />
      </div>

      {/* Navi */}
      <div>
        <McsNavBlogPrevNext
          prevLinkText={prevLinkText}
          topLinkText={topLinkText}
          nextLinkText={nextLinkText}
          onClickPrevLink={onClickPrevLink}
          onClickTopLink={onClickTopLink}
          onClickNextLink={onClickNextLink}
        />
      </div>

      {/* このアーティストの最近の画像 */}
      {recentPhotos.length !== 0 && (
        <OrgRecentImagesFCList
          photoList={recentPhotos}
          onClickContent={onClickContent}
        />
      )}

      {/* このアーティストの最近のブログ */}
      {blogs.length !== 0 && (
        <OrgRecentBlogFCList
          blogList={blogs}
          onClickContent={onClickContent}
          cssClass={styles.contentWrap}
        />
      )}

      <div className={styles.commentCount} onClick={onClickComment}>
        <p>{details.comments.length} コメント</p>
        <IconArrow type="Tertiary" />
      </div>
      {details.comments.length !== 0 && (
        <OrgFCListUnitComment
          comment={details.comments[details.comments.length - 1]}
          isOwn={false}
          cssClass={styles.commentWrap}
        />
      )}

      {/* おすすめコミュニティ */}
      <OrgRecommendFCList
        fanclubList={recommends}
        onClickFanclub={onClickFanclub}
      />

      {errorOpenArea !== 0 && (
        <div className={styles.modalContainer}>
          <OrgJoinFCModal
            titleLines={
              errorOpenArea === 1
                ? ['コミュニティへ入会して', 'コンテンツを見よう！']
                : errorOpenArea === 2
                ? ['フォローして', 'コンテンツを見よう！']
                : []
            }
            buttonText={
              errorOpenArea === 1
                ? 'コミュニティへ入会する'
                : errorOpenArea === 2
                ? 'フォローする'
                : ''
            }
            onClickButton={onClickModalButton}
            fanclub={errorFanclub}
            cssClass={styles.modal}
          />
          <div className={styles.modalBg} />
        </div>
      )}
    </div>
  );
};

export default Component;
