/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import IconClose from '../../atoms/iconParts/IconClose';

import * as styles from './OrgPhotosListModal.module.scss';

interface Props {
  photos?: string[];
  onClickModalClose?: () => void;
  cssClass?: string;
}

const OrgPhotosListModal: FC<Props> = ({
  photos = [],
  onClickModalClose = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgPhotosListModalWrap, cssClass);

  return (
    <div className={classNames}>
      <div className={styles.modalClose}>
        <IconClose type="Secondary" onClick={onClickModalClose} />
      </div>
      <div className={styles.modalBody}>
        {photos.map((photo, index) => (
          <React.Fragment key={`${index.toString()}.${photo}`}>
            <div className={styles.photo}>
              <img src={photo} alt="" />
            </div>
            }
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default OrgPhotosListModal;
