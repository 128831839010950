import { AllState } from '../store';

const getHomeState = (state: AllState) => {
  return state.home;
};

const getIsLastPage = (state: AllState) => {
  return (
    state.home.contents.pager.current_page ===
    state.home.contents.pager.last_page
  );
};

export default {
  getHomeState,
  getIsLastPage,
};
