/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';
import McsIconLabelSquareSenary from '../../molecules/iconWithLabel/McsIconLabelSquareSenary';

import {
  Fanclub,
  FanclubInitialValue,
} from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgJoinFCModal.module.scss';

type NestedPartial<T> = {
  [K in keyof T]?: T[K] extends Array<infer R>
    ? Array<NestedPartial<R>>
    : NestedPartial<T[K]>;
};
type PartialFanclub = NestedPartial<Fanclub>;

interface Props {
  cssClass?: string;
  titleLines?: string[];
  buttonText?: string;
  fanclub?: PartialFanclub;
  onClickButton?: () => void;
}

const OrgJoinFCModal: FC<Props> = ({
  cssClass = '',
  titleLines = [],
  buttonText = '',
  fanclub = FanclubInitialValue,
  onClickButton = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgJoinFCModalBase, cssClass);

  return (
    <div className={classNames}>
      <h4 className={styles.OrgJoinFCModalTitle}>
        {titleLines.map((text, i) => (
          <>
            {i !== 0 && <br />}
            {text}
          </>
        ))}
      </h4>
      <div className={styles.OrgJoinFCModalMain}>
        <div
          className={styles.OrgJoinFCModalImage}
          style={{
            background: `url(${fanclub?.cover_image}) no-repeat center / cover`,
          }}
        />
        <McsIconLabelSquareSenary
          src={fanclub?.icon_image}
          mainText={fanclub?.name}
          subText={fanclub?.site_name}
          cssClass={styles.OrgJoinFCModalThumbnail}
        />
      </div>
      <div className={styles.OrgJoinFCModalContent}>
        <ButtonRoundedCorners
          text={buttonText}
          type="Secondary"
          onClick={onClickButton}
        />
      </div>
    </div>
  );
};

export default OrgJoinFCModal;
