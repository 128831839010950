/* eslint-disable import/no-cycle */
import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { PATH } from '../utils/routerHelper';

/* Common */
import HeaderContainer from '../containers/common/header';
import FooterContainer from '../containers/common/footer';
/* Home */
import HomeContainer from '../containers/home/home';
/* Signup */
import SignUpContainer from '../containers/signup/signUp';
import RegisterContainer from '../containers/signup/register';
import AttachGenresContainer from '../containers/signup/attachGenres';
import FollowArtistContainer from '../containers/signup/followArtist';
import TwitterOAuthCallbackContainer from '../containers/signup/twitterOAuthCallback';
/* Reissue Password */
import ReissuePasswordSendEmailContainer from '../containers/reissuePassword/sendEmail';
import ReissuePasswordResetPasswordContainer from '../containers/reissuePassword/resetPassword';
import ReissuePasswordLoginContainer from '../containers/reissuePassword/login';
/* Setting */
import SettingContainer from '../containers/setting/setting';
import ChangeEmailContainer from '../containers/setting/changeEmail';
import ChangePasswordContainer from '../containers/setting/changePassword';
import ChangeCreditCardContainer from '../containers/setting/changeCreditCard';
import WithdrawContainer from '../containers/setting/withdraw';
import WithdrawPaidFCContainer from '../containers/setting/withdrawPaidFC';
/* Artist */
import ArtistContainer from '../containers/artist/artist';
import FollowersContainer from '../containers/artist/followers';
import ContentContainer from '../containers/artist/content';
import CommentsContainer from '../containers/artist/comments';
import LikesContainer from '../containers/artist/likes';
import PaidJoinContainer from '../containers/paidjoin/paidjoin';
import ChatContainer from '../containers/artist/chat';
/* User */
import UserContainer from '../containers/user/user';
import EditUserProfileContainer from '../containers/user/editUserProfile';
/* Open FC */
import OpenFCDescriptionContainer from '../containers/openFC/description';
import OpenFCRegisterContainer from '../containers/openFC/register';
import OpenFCCompleteContainer from '../containers/openFC/complete';
/* Admin */
import AdminMenuContainer from '../containers/admin/adminMenu';
import AdminContentsContainer from '../containers/admin/adminContents';
import AdminCategoryEditContainer from '../containers/admin/adminCategoryEdit';
import AdminCommentsContainer from '../containers/admin/adminComments';
import AdminBlogRegisterContainer from '../containers/admin/adminBlogRegister';
import AdminMovieRegisterContainer from '../containers/admin/adminMovieRegister';
import AdminPhotoRegisterContainer from '../containers/admin/adminPhotoRegister';
import AdminVoiceRegisterContainer from '../containers/admin/adminVoiceRegister';
import AdminLikesContainer from '../containers/admin/adminLikes';
import AdminCommunityEditContainer from '../containers/admin/adminCommunityEdit';
import AdminMembersContainer from '../containers/admin/adminMembers';
import AdminFollowersContainer from '../containers/admin/adminFollowers';
import AdminBlocksContainer from '../containers/admin/adminBlocks';
import AdminApplyPaidFCContainer from '../containers/admin/adminApplyPaidFC';
import AdminAdministratorContainer from '../containers/admin/adminAdministrator';
import AdminSalesContainer from '../containers/admin/adminSales';
import AdminFCClosedContainer from '../containers/admin/adminFCClosed';
import AdminFCClosedComplete from '../components/admin/adminFCClosedComplete';

/* News */
import NewsContainer from '../containers/news/news';
import NewsDetailsContainer from '../containers/news/newsDetails';

/* Nav */
import ContactContainer from '../containers/nav/contact';

/* Errors */
import Forbidden from '../components/errors/Forbidden';
import NotFound from '../components/errors/NotFound';

import AtomsPartsList from '../components/common/atoms/atomsPartsList';
import MoleculesPartsList from '../components/common/molecules/moleculesPartsList';
import OrganismsPartsList from '../components/common/organisms/organismsPartsList';

const ContentRouter: FC = () => {
  return (
    <>
      <HeaderContainer />

      <Switch>
        {/**
         * トップ
         */}
        <Route path={PATH.HOME} exact component={HomeContainer} />
        {/**
         * ログイン、会員登録
         */}
        <Route path={PATH.SIGNUP} exact component={SignUpContainer} />
        <Route
          path={PATH.SIGNUP_REGISTER}
          exact
          component={RegisterContainer}
        />
        <Route
          path={PATH.SIGNUP_ATTACH_GENRES}
          exact
          component={AttachGenresContainer}
        />
        <Route
          path={PATH.SIGNUP_FOLLOW_ARTIST}
          exact
          component={FollowArtistContainer}
        />
        <Route
          path={PATH.OAUTH_TWITTER_CALLBACK}
          exact
          component={TwitterOAuthCallbackContainer}
        />
        {/**
         * ユーザ設定
         */}
        <Route path={PATH.SETTING_MENU} exact component={SettingContainer} />
        <Route
          path={PATH.SETTING_PROFILE}
          exact
          component={EditUserProfileContainer}
        />
        <Route
          path={PATH.SETTING_EMAIL}
          exact
          component={ChangeEmailContainer}
        />
        <Route
          path={PATH.SETTING_PASSWORD}
          exact
          component={ChangePasswordContainer}
        />
        <Route
          path={PATH.SETTING_CREDIT_CARD}
          exact
          component={ChangeCreditCardContainer}
        />
        <Route
          path={PATH.SETTING_WITHDRAW}
          exact
          component={WithdrawContainer}
        />
        <Route
          path={PATH.SETTING_WITHDRAW_FC}
          exact
          component={WithdrawPaidFCContainer}
        />
        {/**
         * パスワード再発行
         */}
        <Route
          path={PATH.REISSUE_PASSWORD_SEND_EMAIL}
          exact
          component={ReissuePasswordSendEmailContainer}
        />
        <Route
          path={PATH.REISSUE_PASSWORD_CHANGE_PASSWORD}
          exact
          component={ReissuePasswordResetPasswordContainer}
        />
        <Route
          path={PATH.REISSUE_PASSWORD_LOGIN}
          exact
          component={ReissuePasswordLoginContainer}
        />
        {/**
         * ユーザ、アーティスト、コンテンツ
         */}
        <Route path={PATH.USER} exact component={UserContainer} />
        <Route path={PATH.ARTIST} exact component={ArtistContainer} />
        <Route
          path={PATH.ARTIST_FOLLOWER}
          exact
          component={FollowersContainer}
        />
        <Route path={PATH.CONTENT} exact component={ContentContainer} />
        <Route path={PATH.COMMENT} exact component={CommentsContainer} />
        <Route path={PATH.LIKE} exact component={LikesContainer} />
        {/**
         * チャット機能（有料FC限定）
         */}
        <Route path={PATH.FC_CHAT} exact component={ChatContainer} />
        {/**
         * 有料コミュニティ入会
         */}
        <Route path={PATH.PAIDJOIN} exact component={PaidJoinContainer} />
        {/**
         * コミュニティ開設
         */}
        <Route
          path={PATH.OPEN_FC_AGREEMENT}
          exact
          component={OpenFCDescriptionContainer}
        />
        <Route
          path={PATH.OPEN_FC_REGISTER}
          exact
          component={OpenFCRegisterContainer}
        />
        <Route
          path={PATH.OPEN_FC_COMPLETE}
          exact
          component={OpenFCCompleteContainer}
        />
        {/**
         * アーティスト管理
         */}
        <Route path={PATH.ADMIN_MENU} exact component={AdminMenuContainer} />
        <Route
          path={PATH.ADMIN_CONTENTS}
          exact
          component={AdminContentsContainer}
        />
        <Route
          path={PATH.ADMIN_CATEGORY}
          exact
          component={AdminCategoryEditContainer}
        />
        <Route
          path={[PATH.ADMIN_CREATE_BLOG, PATH.ADMIN_UPDATE_BLOG]}
          exact
          component={AdminBlogRegisterContainer}
        />
        <Route
          path={[PATH.ADMIN_CREATE_MOVIE, PATH.ADMIN_UPDATE_MOVIE]}
          exact
          component={AdminMovieRegisterContainer}
        />
        <Route
          path={[PATH.ADMIN_CREATE_PHOTO, PATH.ADMIN_UPDATE_PHOTO]}
          exact
          component={AdminPhotoRegisterContainer}
        />
        <Route
          path={[PATH.ADMIN_CREATE_VOICE, PATH.ADMIN_UPDATE_VOICE]}
          exact
          component={AdminVoiceRegisterContainer}
        />
        <Route path={PATH.ADMIN_LIKES} exact component={AdminLikesContainer} />
        <Route
          path={PATH.ADMIN_COMMENTS}
          exact
          component={AdminCommentsContainer}
        />
        <Route path={PATH.ADMIN_SALES} exact component={AdminSalesContainer} />
        <Route
          path={PATH.ADMIN_COMMUNITY}
          exact
          component={AdminCommunityEditContainer}
        />
        <Route
          path={PATH.ADMIN_MEMBERS}
          exact
          component={AdminMembersContainer}
        />
        <Route
          path={PATH.ADMIN_FOLLOWERS}
          exact
          component={AdminFollowersContainer}
        />
        <Route
          path={PATH.ADMIN_BLOCKS}
          exact
          component={AdminBlocksContainer}
        />
        <Route
          path={PATH.ADMIN_APPLY}
          exact
          component={AdminApplyPaidFCContainer}
        />
        <Route
          path={PATH.ADMIN_ADMINISTRATOR}
          exact
          component={AdminAdministratorContainer}
        />
        <Route
          path={PATH.ADMIN_CLOSE_FC}
          exact
          component={AdminFCClosedContainer}
        />
        <Route
          path={PATH.ADMIN_CLOSE_FC_COMPLETE}
          exact
          component={AdminFCClosedComplete}
        />

        {/**
         * お知らせ
         */}
        <Route path={PATH.NEWS} exact component={NewsContainer} />
        <Route
          path={PATH.NEWS_DETAILS}
          exact
          component={NewsDetailsContainer}
        />
        {/**
         * お問い合わせ
         */}
        <Route path={PATH.CONTACT} exact component={ContactContainer} />
        {/**
         * エラー
         */}
        <Route path={PATH.Forbidden} exact component={Forbidden} />
        <Route path={PATH.NotFound} exact component={NotFound} />
        {/**
         * 開発
         */}
        {process.env.NODE_ENV === 'development' && (
          <Route path={PATH.ATOM_PARTS_LIST} exact component={AtomsPartsList} />
        )}
        {process.env.NODE_ENV === 'development' && (
          <Route
            path={PATH.MOLECULE_PARTS_LIST}
            exact
            component={MoleculesPartsList}
          />
        )}
        {process.env.NODE_ENV === 'development' && (
          <Route
            path={PATH.ORGANISM_PARTS_LIST}
            exact
            component={OrganismsPartsList}
          />
        )}
        {/**
         * 存在しないURLの場合404ページへリダイレクトする
         */}
        <Redirect to={PATH.NotFound} />
      </Switch>

      <FooterContainer />
    </>
  );
};

export default ContentRouter;
