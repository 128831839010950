/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './McsPhotoListUnit.module.scss';

interface Props {
  cssClass?: string;
  photos?: string[];
  onClick?: () => void;
}

const McsPhotoListUnit: FC<Props> = ({
  cssClass = '',
  photos = [],
  onClick = () => undefined,
}) => {
  const { length } = photos;
  const classNames = ClassNames(styles.blogPhotoBase, cssClass, {
    [styles.oneSheet]: length === 1,
    [styles.twoSheet]: length === 2,
    [styles.threeSheet]: length === 3,
    [styles.fourSheets]: length === 4,
    [styles.fiveSheets]: length === 5,
    [styles.fiveSheetsOrMore]: length > 5,
  });

  return (
    <div className={classNames} onClick={onClick}>
      {photos.map((photo, index) => (
        <React.Fragment key={`${index.toString()}.${photo}`}>
          {index < 5 && (
            <div
              style={{
                backgroundImage: `url(${photo})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              {length > 5 && index === 4 && (
                <div className={styles.orMore}>+ {length - 4}枚</div>
              )}
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default McsPhotoListUnit;
