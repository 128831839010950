/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import IconShape from '../../atoms/iconParts/IconShape';
import * as styles from './McsIconWithTextPrimary.module.scss';

interface Props {
  mainText?: string;
  subText?: string;
  iconType?: string;
  cssClass?: string;
  onClick?: () => void;
}

const McsIconText: FC<Props> = ({
  mainText = 'Text',
  subText = 'SubText',
  iconType = 'Star',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsIconTextBase, cssClass);

  return (
    <dl className={classNames} onClick={onClick}>
      <dt>
        <IconShape type={iconType} />
      </dt>
      <dd>
        <span>{mainText}</span>
        {subText}
      </dd>
    </dl>
  );
};

export default McsIconText;
