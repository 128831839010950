import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import { changeEmailEvent, cleanup } from '../../actions/changeEmailActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';
import { validateEmail } from '../../utils/validationHelper';

import { ChangeEmailValidationErrors } from '../../interfaces/emailInterFace';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import ChangeEmail from '../../components/setting/changeEmail';

const ChangeEmailContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [new_email, setNewEmail] = useState('');
  const {
    ready: authReady,
    profile: { user },
  } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const { loading, success, errors } = useSelector(
    selector.changeEmail.getChangeEmailState,
  );
  const validationErrors: ChangeEmailValidationErrors = {
    new_email: errors.new_email?.[0] ?? validateEmail(new_email),
  };

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setNewEmail(e.currentTarget.value);
  }, []);
  const handleChangeEmail = useCallback(() => {
    dispatch(
      changeEmailEvent.start({
        new_email,
      }),
    );
  }, [dispatch, new_email]);
  const handleBack = useCallback(() => {
    history.push(PATH.SETTING_MENU);
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (authReady && !isLogin) {
      history.push(PATH.SIGNUP);
    }
  }, [authReady, history, isLogin]);

  useEffect(() => {
    if (success) history.push(PATH.SETTING_MENU);
  }, [history, success]);

  useEffect(() => {
    if (errors.new_email?.[0]) window.alert(errors.new_email[0]);
  }, [errors]);

  return (
    <>
      <Helmet>
        <title>メールアドレス変更 | 設定 | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady} />

      <ChangeEmail
        user={user}
        valueEmail={new_email}
        onChange={handleChange}
        onChangeEmailEvent={handleChangeEmail}
        onBackEvent={handleBack}
        errors={validationErrors}
      />
    </>
  );
};

export default ChangeEmailContainer;
