import React, { FC } from 'react';
import ClassNames from 'classnames';
import McsTitleTertiary from '../common/molecules/titleParts/McsTitleTertiary';
import McsListLinkSecondary from '../common/molecules/listLinkParts/McsListLinkSecondary';
import McsListStatusDisplay from '../common/molecules/listLinkParts/McsListStatusDisplay';
import { User, UserInitialValue } from '../../interfaces/userInterFace';
import { Card } from '../../interfaces/cardInterFace';
import * as styles from './setting.module.scss';

interface Props {
  user?: User;
  card?: Card | null;
  isViewWithdrawPaidFC?: boolean;
  handleLinkToEditUserProfile?: () => void;
  handleLinkToChangeEmail?: () => void;
  handleLinkToChangePassword?: () => void;
  handleLinkToChangeCredit?: () => void;
  handleLinkToWithdrawPaidFanclub?: () => void;
  handleLinkToOpenFC?: () => void;
  handleLinkToWithdraw?: () => void;
  handleLinkToHelp?: () => void;
  handleChangeReceptionSetting?: () => void;
}

const Component: FC<Props> = ({
  user = UserInitialValue,
  card = null,
  isViewWithdrawPaidFC = false,
  handleLinkToEditUserProfile = () => undefined,
  handleLinkToChangeEmail = () => undefined,
  handleLinkToChangePassword = () => undefined,
  handleLinkToChangeCredit = () => undefined,
  handleLinkToWithdrawPaidFanclub = () => undefined,
  handleLinkToOpenFC = () => undefined,
  handleLinkToWithdraw = () => undefined,
  handleLinkToHelp = () => undefined,
  handleChangeReceptionSetting = () => undefined,
}) => {
  const classNames = ClassNames(styles.containerWrap);

  const creditSubText = card
    ? `**** **** **** ${
        card.last_four
      }\t\t有効期限：${card.exp_month.toString().padStart(2, '0')}/${
        card.exp_year
      }`
    : '';

  return (
    <ul className={classNames}>
      <McsTitleTertiary
        mainText="設定"
        subText={`@${user.name_id}`}
        cssClass={styles.title}
      />
      <McsListLinkSecondary
        mainText="プロフィール編集"
        subText=""
        onClick={handleLinkToEditUserProfile}
        cssClass={styles.listItem}
      />
      <McsListLinkSecondary
        mainText="メールアドレス"
        subText={user.email}
        onClick={handleLinkToChangeEmail}
        cssClass={styles.listItem}
      />
      <McsListStatusDisplay
        mainText="メールマガジン受取設定"
        flag={user.send_flag}
        onClick={handleChangeReceptionSetting}
        cssClass={styles.listItem}
      />
      <McsListLinkSecondary
        mainText="パスワード"
        subText=""
        onClick={handleLinkToChangePassword}
        cssClass={styles.listItem}
      />
      {card && (
        <McsListLinkSecondary
          mainText="クレジットカード"
          subText={creditSubText}
          onClick={handleLinkToChangeCredit}
          cssClass={styles.listItemCredit}
        />
      )}
      <div className={styles.list}>
        <McsListLinkSecondary
          mainText="コミュニティを作る"
          subText=""
          onClick={handleLinkToOpenFC}
          cssClass={styles.listItem}
        />
      </div>
      {isViewWithdrawPaidFC && (
        <McsListLinkSecondary
          mainText="入会中のコミュニティから退会する"
          subText=""
          onClick={handleLinkToWithdrawPaidFanclub}
          cssClass={styles.listItem}
        />
      )}
      <McsListLinkSecondary
        mainText="KRAPから退会する"
        subText=""
        onClick={handleLinkToWithdraw}
        cssClass={styles.listItem}
      />
      <div className={styles.list}>
        <McsListLinkSecondary
          mainText="ヘルプセンター"
          subText=""
          onClick={handleLinkToHelp}
          cssClass={styles.listItemLast}
        />
      </div>
    </ul>
  );
};

export default Component;
