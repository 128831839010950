import { Status, ErrorResponse } from './commonInterFace';
import { UserProfile } from './userInterFace';

import { Fanclub } from './fanclubInterFace';

export type CheckDuplicateEmailParams = {
  email: string;
};
export type CheckDuplicateEmailErrors = {
  [key in keyof Partial<CheckDuplicateEmailParams>]: string[];
};
export type CheckDuplicateEmailValidationErrors = Record<
  keyof CheckDuplicateEmailParams,
  string
>;

export type CheckDuplicateEmailResult = {
  email: string;
};
export type CheckDuplicateEmailResponse = {
  status_code: Status.OK;
  message: string;
  result: CheckDuplicateEmailResult;
};
export type CheckDuplicateEmailErrorResponse = ErrorResponse<
  keyof CheckDuplicateEmailParams
>;

export type SignUpParams = {
  email: string;
  nickname: string;
  name_id: string;
  password: string;
  password_confirmation: string;
};
export const SignUpParamsInitialValue: SignUpParams = {
  email: '',
  nickname: '',
  name_id: '',
  password: '',
  password_confirmation: '',
};
export type SignUpErrors = { [key in keyof Partial<SignUpParams>]: string[] };
export type SignUpValidationErrors = Record<keyof SignUpParams, string>;
export const SignUpValidationErrorsInitialValue: SignUpValidationErrors = {
  email: '',
  nickname: '',
  name_id: '',
  password: '',
  password_confirmation: '',
};
export type SignupResponse = {
  status_code: Status.OK;
  access_token: string;
  token_type: string;
  expires_in: number;
};
export type SignupErrorResponse = ErrorResponse<keyof SignUpParams>;

export type WithdrawErrors = {
  admin: Fanclub[];
  paid: Fanclub[];
};
export const WithdrawErrorsInitialValue: WithdrawErrors = {
  admin: [],
  paid: [],
};
export type WithdrawErrorResult = WithdrawErrors;
export type WithdrawResponse = {
  status_code: Status.OK;
  message: string;
};
export type WithdrawErrorResponse =
  | {
      status_code: Status.BadRequest;
      result: WithdrawErrorResult;
    }
  | {
      status_code: Status.Unauthorized;
      message: string;
    };

export type LogInParams = {
  email: string;
  password: string;
  saveAuthInCookie: boolean;
};
export type LoginValidationErrors = Record<
  keyof Pick<LogInParams, 'email' | 'password'>,
  string
>;
export type LoginResponse = {
  status_code: Status.OK;
  access_token: string;
  token_type: string;
  expires_in: number;
};

export type LoginErrorResponse = ErrorResponse<
  keyof Omit<LogInParams, 'saveAuthInCookie'>
>;

export type LogoutResponse = {
  status_code: Status.OK;
  message: string;
};

export type GetUserProfileResult = UserProfile;
export type GetUserProfileResponse = {
  status_code: Status.OK;
  result: GetUserProfileResult;
};
