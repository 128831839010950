import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import { useHistory, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import * as styles from './Forbidden.module.scss';

const Forbidden: FC = () => {
  const history = useHistory();

  const handleClickBackToTop = useCallback(() => {
    history.replace(PATH.HOME);
  }, [history]);

  useGA(history.location);

  return (
    <>
      <Helmet>
        <title>403 Forbidden | KRAP</title>
      </Helmet>
      <div className={styles.container}>
        <h1 className={styles.title}>403</h1>
        <h2 className={styles.subTitle}>Forbidden</h2>

        <p className={styles.summary}>アクセス権限がありません</p>

        <p className={styles.description}>
          {
            'KRAPをご利用いただきありがとうございます。\nご指定いただいたページへのアクセス権限がありません。トップへ戻り、引き続きKRAPをお楽しみください。'
          }
        </p>

        <ButtonRoundedCorners
          size="Primary"
          text="トップへ戻る"
          onClick={handleClickBackToTop}
          cssClass={styles.button}
        />
      </div>
    </>
  );
};

export default Forbidden;
