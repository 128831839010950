/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import Tab from '../common/atoms/tab/Tab';
import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';

import OrgAdminFormAdministrator from '../common/organisms/admin/OrgAdminFormAdministrator';
import OrgAdminFormBankAccount from '../common/organisms/admin/OrgAdminFormBankAccount';

import { SelectField } from '../../interfaces/utilInterFace';
import {
  UpdateAdministratorInputParams,
  UpdateAdministratorInputParamsInitialValue,
  UpdateAdministratorValidationErrors,
  UpdateAdministratorValidationErrorsInitialValue,
} from '../../interfaces/administratorInterFace';
import {
  BankType,
  Bank,
  BankBranch,
  RegisterOtherBankAccountInputParams,
  RegisterOtherBankAccountInputParamsInitialValue,
  RegisterOtherBankAccountValidationErrors,
  RegisterOtherBankAccountValidationErrorsInitialValue,
  RegisterJPBankAccountInputParams,
  RegisterJPBankAccountInputParamsInitialValue,
  RegisterJPBankAccountValidationErrors,
  RegisterJPBankAccountValidationErrorsInitialValue,
} from '../../interfaces/bankInterFace';
import { AdminAdministratorTabMode } from '../../containers/admin/adminAdministrator';

import * as styles from './adminAdministrator.module.scss';

interface Props {
  fanclubName?: string;
  tabMode?: AdminAdministratorTabMode;
  fixedPrice?: number;
  administratorParams?: UpdateAdministratorInputParams;
  bankType?: BankType;
  otherBankAccountParams?: RegisterOtherBankAccountInputParams;
  jpBankAccountParams?: RegisterJPBankAccountInputParams;
  yearList?: SelectField;
  monthList?: SelectField;
  dateList?: SelectField;
  bankTypeList?: SelectField;
  accountTypeList?: SelectField;
  suggestBankList?: Bank[];
  suggestBankBranchList?: BankBranch[];
  selectedBank?: Bank | null;
  selectedBankBranch?: BankBranch | null;
  handleChangeTab?: (mode: AdminAdministratorTabMode) => void;
  handleChangeAdministrator?: (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  handleChangeAdministratorImage?: (
    e: React.FormEvent<HTMLInputElement>,
  ) => void;
  handleChangeBankType?: (e: React.FormEvent<HTMLSelectElement>) => void;
  handleChangeOtherBankAccount?: (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  handleChangeJPBankAccount?: (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  handleSelectBank?: (code: string) => void;
  handleSelectBankBranch?: (code: string) => void;
  canInputBankBranch?: boolean;
  handleSave?: () => void;
  canSave?: boolean;
  handleBack?: () => void;
  administratorErrors?: UpdateAdministratorValidationErrors;
  otherBankAccountErrors?: RegisterOtherBankAccountValidationErrors;
  jpBankAccountErrors?: RegisterJPBankAccountValidationErrors;
}

const Component: FC<Props> = ({
  fanclubName = '',
  tabMode = AdminAdministratorTabMode.ADMINISTRATOR,
  fixedPrice = 300,
  administratorParams = UpdateAdministratorInputParamsInitialValue,
  bankType = 0,
  otherBankAccountParams = RegisterOtherBankAccountInputParamsInitialValue,
  jpBankAccountParams = RegisterJPBankAccountInputParamsInitialValue,
  yearList = [],
  monthList = [],
  dateList = [],
  bankTypeList = [],
  accountTypeList = [],
  suggestBankList = [],
  suggestBankBranchList = [],
  selectedBank = null,
  selectedBankBranch = null,
  handleChangeTab = () => undefined,
  handleChangeAdministrator = () => undefined,
  handleChangeAdministratorImage = () => undefined,
  handleChangeBankType = () => undefined,
  handleChangeOtherBankAccount = () => undefined,
  handleChangeJPBankAccount = () => undefined,
  handleSelectBank = () => undefined,
  handleSelectBankBranch = () => undefined,
  canInputBankBranch = false,
  handleSave = () => undefined,
  canSave = false,
  handleBack = () => undefined,
  administratorErrors = UpdateAdministratorValidationErrorsInitialValue,
  otherBankAccountErrors = RegisterOtherBankAccountValidationErrorsInitialValue,
  jpBankAccountErrors = RegisterJPBankAccountValidationErrorsInitialValue,
}) => {
  const classNames = ClassNames(styles.container);

  return (
    <div className={classNames}>
      <McsTitleSecondary
        mainText="管理者情報編集"
        subText={fanclubName}
        onClick={handleBack}
        cssClass={styles.title}
      />

      <div className={styles.tabMenuWrap}>
        <Tab
          text="管理者情報"
          cssClass={styles.tabMenuItem}
          type={
            tabMode === AdminAdministratorTabMode.ADMINISTRATOR
              ? 'Quinary'
              : 'Quaternary'
          }
          onClick={() => {
            handleChangeTab(AdminAdministratorTabMode.ADMINISTRATOR);
          }}
        />
        <Tab
          text="振込先口座情報"
          cssClass={styles.tabMenuItem}
          type={
            tabMode === AdminAdministratorTabMode.BANK
              ? 'Quinary'
              : 'Quaternary'
          }
          onClick={() => {
            handleChangeTab(AdminAdministratorTabMode.BANK);
          }}
        />
      </div>

      <div className={styles.body}>
        {tabMode === AdminAdministratorTabMode.ADMINISTRATOR ? (
          <OrgAdminFormAdministrator
            price={fixedPrice}
            administratorParams={administratorParams}
            yearList={yearList}
            monthList={monthList}
            dateList={dateList}
            handleChangeAdministrator={handleChangeAdministrator}
            handleChangeAdministratorImage={handleChangeAdministratorImage}
            administratorErrors={administratorErrors}
          />
        ) : (
          <OrgAdminFormBankAccount
            bankType={bankType}
            otherBankAccountParams={otherBankAccountParams}
            jpBankAccountParams={jpBankAccountParams}
            bankTypeList={bankTypeList}
            assortmentTypeList={accountTypeList}
            suggestBankList={suggestBankList}
            suggestBankBranchList={suggestBankBranchList}
            selectedBank={selectedBank}
            selectedBankBranch={selectedBankBranch}
            handleChangeBankType={handleChangeBankType}
            handleChangeOtherBankAccount={handleChangeOtherBankAccount}
            handleChangeJPBankAccount={handleChangeJPBankAccount}
            handleSelectBank={handleSelectBank}
            handleSelectBankBranch={handleSelectBankBranch}
            canInputBankBranch={canInputBankBranch}
            jpBankAccountErrors={jpBankAccountErrors}
            otherBankAccountErrors={otherBankAccountErrors}
          />
        )}

        <ButtonRoundedCorners
          text="保存する"
          type={canSave ? 'Senary' : 'Quaternary'}
          size="Primary"
          onClick={handleSave}
          cssClass={styles.submit}
        />

        <div className={styles.cancelWrapper}>
          <ButtonRoundedCorners
            text="戻る"
            type="Quinary"
            size="Primary"
            onClick={handleBack}
            cssClass={styles.cancel}
          />
        </div>
      </div>
    </div>
  );
};

export default Component;
