/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';

import Tab from '../common/atoms/tab/Tab';
import LabelTag from '../common/atoms/label/LabelTag';
import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import McsSelectSecondary from '../common/molecules/formParts/McsSelectSecondary';
import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';
import McsInputSecondary from '../common/molecules/formParts/McsInputSecondary';
import McsInputDateTimeSecondary from '../common/molecules/formParts/McsInputDateTimeSecondary';

import OrgContentAddCategory from '../common/organisms/contentParts/OrgContentAddCategory';
import OrgContentPhotoRegister from '../common/organisms/contentParts/OrgContentPhotoRegister';

import * as styles from './adminPhotoRegister.module.scss';

import {
  AdminContentsTabList,
  ContentType,
  Photo,
  InputPhotoParams,
  InputPhotoParamsInitialValue,
  RegisterPhotoValidationErrors,
  RegisterPhotoValidationErrorsInitialValue,
} from '../../interfaces/contentInterFace';
import { CategoryWithSelected } from '../../interfaces/categoryInterFace';
import { SelectField } from '../../interfaces/utilInterFace';

type Props = {
  mode?: 'create' | 'update';
  type?: ContentType;
  title?: string;
  params?: InputPhotoParams;
  fanclubName?: string;
  categoryList?: CategoryWithSelected[];
  currentPhotoList?: Photo[];
  openAreaList?: SelectField;
  isViewTab?: boolean;
  onChangeTab?: (type: ContentType) => void;
  onChange?: (
    e: React.FormEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key?: string,
  ) => void;
  onChangeCategory?: (categoryId: number) => void;
  onAddCategory?: () => void;
  onAddNewPhoto?: (e: React.FormEvent<HTMLInputElement>) => void;
  onDeleteCurrentPhoto?: (id: number) => void;
  onDeleteNewPhoto?: (index: number) => void;
  canClickConfirm?: boolean;
  onConfirm?: () => void;
  onSaveAsDraft?: () => void;
  onDelete?: () => void;
  onBack?: () => void;
  errors?: RegisterPhotoValidationErrors;
  newCategory?: string;
  onChangeNewCategory?: (
    e: React.FormEvent<HTMLInputElement>,
    key?: string,
  ) => void;
  categoryError?: string;
};

const Component: FC<Props> = ({
  mode = 'create',
  type = 2,
  title = '',
  params = InputPhotoParamsInitialValue,
  fanclubName = '',
  categoryList = [],
  currentPhotoList = [],
  openAreaList = [],
  isViewTab = false,
  onChangeTab = () => undefined,
  onChange = () => undefined,
  onChangeCategory = () => undefined,
  onAddCategory = () => undefined,
  onAddNewPhoto = () => undefined,
  onDeleteCurrentPhoto = () => undefined,
  onDeleteNewPhoto = () => undefined,
  canClickConfirm = false,
  onConfirm = () => undefined,
  onSaveAsDraft = () => undefined,
  onDelete = () => undefined,
  onBack = () => undefined,
  errors = RegisterPhotoValidationErrorsInitialValue,
  newCategory = '',
  onChangeNewCategory = () => undefined,
  categoryError = '',
}) => {
  return (
    <div className={styles.containerWrap}>
      <McsTitleSecondary
        mainText={title}
        subText={fanclubName}
        onClick={onBack}
        cssClass={styles.contentTitle}
      />
      {isViewTab && (
        <ul className={styles.contentTabMenu}>
          {AdminContentsTabList.map(tab => (
            <Tab
              key={`Tab${tab.type}`}
              text={tab.label}
              type={type === tab.type ? 'Quinary' : 'Quaternary'}
              onClick={() => onChangeTab(tab.type)}
              cssClass={styles.contentTabMenuItem}
            />
          ))}
        </ul>
      )}
      <div className={styles.contentWrap}>
        <McsInputSecondary
          type="Senary"
          inputName="subject"
          value={params.subject}
          placeholder="タイトルを入力"
          onChange={onChange}
          isError={!!errors.subject}
          errorText={errors.subject}
        />
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.contentItem}>
          {categoryList.map((category, i) => (
            <LabelTag
              key={`category-${i.toString()}`}
              text={category.name}
              type={category.selected ? 'Quaternary' : 'Quinary'}
              cssClass={styles.contentCategoryTag}
              onClick={() => onChangeCategory(category.id)}
            />
          ))}
        </div>
        <OrgContentAddCategory
          error={categoryError}
          text={newCategory}
          onChangeText={onChangeNewCategory}
          onClickAdd={onAddCategory}
        />
      </div>

      <OrgContentPhotoRegister
        currentPhotoList={currentPhotoList}
        newPhotoList={params.photos}
        onDeleteCurrentPhoto={onDeleteCurrentPhoto}
        onDeleteNewPhoto={onDeleteNewPhoto}
        onAddNewPhoto={onAddNewPhoto}
        isError={!!errors.photos}
        errorText={errors.photos}
        cssClass={styles.OrgContentPhotoRegister}
      />

      <div className={styles.contentWrap}>
        <McsInputDateTimeSecondary
          labelTitle="公開日時"
          inputName1="release_at_date"
          inputName2="release_at_time"
          value1={params.release_at_date}
          value2={params.release_at_time}
          onChange={onChange}
          cssClass={styles.contentItemSecondary}
          isError={!!errors.release_at}
          errorText={errors.release_at}
        />
        <McsInputDateTimeSecondary
          labelTitle="公開期限"
          labelSubTitle="設定すると、指定の時間に下書き状態になります。"
          inputName1="close_at_date"
          inputName2="close_at_time"
          value1={params.close_at_date}
          value2={params.close_at_time}
          onChange={onChange}
          cssClass={styles.contentItemSecondary}
          isError={!!errors.close_at}
          errorText={errors.close_at}
        />
        <McsSelectSecondary
          labelTitle="公開範囲"
          inputName="open_area"
          value={params.open_area.toString()}
          selectList={openAreaList}
          onChange={onChange}
          isError={!!errors.open_area}
          errorText={errors.open_area}
        />
      </div>

      <div className={styles.contentButtonWrap}>
        <ButtonRoundedCorners
          text="確認する"
          type={canClickConfirm ? 'Senary' : 'Quaternary'}
          onClick={onConfirm}
          cssClass={styles.contentButtonItem}
        />
        <ButtonRoundedCorners
          text="下書き保存"
          type="Septenary"
          onClick={onSaveAsDraft}
          cssClass={styles.contentButtonItem}
        />

        {mode === 'create' ? (
          <ButtonRoundedCorners
            text="キャンセル"
            type="Quinary"
            onClick={onBack}
            cssClass={styles.cancel}
          />
        ) : mode === 'update' ? (
          <ButtonRoundedCorners
            text="投稿を削除する"
            type="Quinary"
            onClick={onDelete}
            cssClass={styles.cancel}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Component;
