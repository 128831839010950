/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import IconArrow from '../../atoms/iconParts/IconArrow';
import * as styles from './McsListLinkSecondary.module.scss';

interface Props {
  mainText?: string;
  subText?: string;
  iconType?: string;
  cssClass?: string;
  onClick?: () => void;
}

const McsLinkList: FC<Props> = ({
  mainText = 'Text',
  subText = 'SubText',
  iconType = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsLinkListBase, cssClass);

  return (
    <li className={classNames} onClick={onClick}>
      <div>
        <div className={styles.McsLinkListMainTitle}>{mainText}</div>
        {subText !== '' && (
          <div className={styles.McsLinkListSubTitle}>{subText}</div>
        )}
      </div>
      <IconArrow />
    </li>
  );
};

export default McsLinkList;
