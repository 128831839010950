/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, takeLeading } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/withdrawConstants';
import { checkAndSetStatusError } from './authSagas';
import { setAccessToken } from '../actions/authActions';
import { withdrawEvent } from '../actions/withdrawActions';

import { Status } from '../interfaces/commonInterFace';
import {
  WithdrawResponse,
  WithdrawErrorResponse,
} from '../interfaces/authInterFace';

import {
  deleteAuthSessionStorage,
  deleteAuthCookie,
} from '../utils/utilHelper';

// withdraw
const withdrawHandler = api.getDeleteFactory(true);
function* runWithdraw(handler: typeof withdrawHandler) {
  try {
    const ReturnData: WithdrawResponse = yield call(
      handler,
      {},
      '/user/profile',
    );

    if (!ReturnData) {
      yield put(withdrawEvent.fail({ admin: [], paid: [] }));

      return;
    }

    yield call(deleteAuthSessionStorage);
    yield call(deleteAuthCookie);
    yield put(setAccessToken(''));

    yield put(withdrawEvent.succeed());
  } catch (error) {
    const res = error.response.data as WithdrawErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.BadRequest) {
      yield put(withdrawEvent.fail(res.result));

      return;
    }

    yield put(withdrawEvent.fail({ admin: [], paid: [] }));
  }
}
export function* withdraw(handler: typeof withdrawHandler) {
  yield takeLeading(ActionType.WITHDRAW_START, runWithdraw, handler);
}

export const withdrawSagas = [fork(withdraw, withdrawHandler)];
