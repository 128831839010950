import { Reducer } from 'redux';
import { Actions } from '../actions/withdrawActions';
import * as ActionType from '../actions/withdrawConstants';
import {
  WithdrawErrors,
  WithdrawErrorsInitialValue,
} from '../interfaces/authInterFace';

type State = {
  loading: boolean;
  errors: WithdrawErrors;
  success: boolean;
};

export const initialState: State = {
  loading: false,
  errors: WithdrawErrorsInitialValue,
  success: false,
};

const withdrawReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // withdraw
    case ActionType.WITHDRAW_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.WITHDRAW_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ActionType.WITHDRAW_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default withdrawReducer;
