/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, takeLeading } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/changePasswordConstants';
import { checkAndSetStatusError } from './authSagas';
import { changePasswordEvent } from '../actions/changePasswordActions';

import { Status } from '../interfaces/commonInterFace';
import {
  ChangePasswordResponse,
  ChangePasswordErrorResponse,
} from '../interfaces/passwordInterFace';

// change password
const changePasswordHandler = api.getPostFactory(true);
function* runChangePassword(
  handler: typeof changePasswordHandler,
  action: ReturnType<typeof changePasswordEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: ChangePasswordResponse = yield call(
      handler,
      data,
      '/password/change',
    );

    if (!ReturnData) {
      yield put(changePasswordEvent.fail({}));

      return;
    }

    yield put(changePasswordEvent.succeed());
  } catch (error) {
    const res = error.response.data as ChangePasswordErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(changePasswordEvent.fail(res.errors));

      return;
    }
    yield put(changePasswordEvent.fail({}));
  }
}
export function* changePassword(handler: typeof changePasswordHandler) {
  yield takeLeading(
    ActionType.CHANGE_PASSWORD_START,
    runChangePassword,
    handler,
  );
}

export const changePasswordSagas = [
  fork(changePassword, changePasswordHandler),
];
