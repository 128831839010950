/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import { signupEvent, cleanup } from '../../actions/signUpActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';
import {
  validateEmail,
  validateNickName,
  validateUserId,
  validatePassword,
  validateConfirmPassword,
} from '../../utils/validationHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import Register from '../../components/signup/register';

import {
  SignUpParams,
  SignUpParamsInitialValue,
  SignUpValidationErrors,
} from '../../interfaces/authInterFace';

const RegisterContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [params, setParams] = useState<SignUpParams>(SignUpParamsInitialValue);
  const [isAccepted, setAccepted] = useState(false);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const {
    loading,
    errors: { signUp: errors },
  } = useSelector(selector.signUp.getSignupState);

  // params依存かつparamsの変更が激しいので、Memo化はしない
  const signUpValidationErrors: SignUpValidationErrors = {
    email: errors.email?.[0] ?? validateEmail(params.email),
    nickname: errors.nickname?.[0] ?? validateNickName(params.nickname),
    name_id: errors.name_id?.[0] ?? validateUserId(params.name_id),
    password: errors.password?.[0] ?? validatePassword(params.password),
    password_confirmation:
      errors.password_confirmation?.[0] ??
      validateConfirmPassword(params.password, params.password_confirmation),
  };

  // params依存かつparamsの変更が激しいので、Memo化はしない
  const canSubmit =
    !!params.nickname &&
    validateNickName(params.nickname).length === 0 &&
    !!params.name_id &&
    validateUserId(params.name_id).length === 0 &&
    !!params.password &&
    validatePassword(params.password).length === 0 &&
    !!params.password_confirmation &&
    validateConfirmPassword(params.password, params.password_confirmation)
      .length === 0 &&
    isAccepted;

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;

    setParams(params => ({ ...params, [name]: value }));
  }, []);

  const handleToggleAccepted = useCallback(() => {
    setAccepted(isAccepted => !isAccepted);
  }, []);

  const handleRegister = () => {
    if (!canSubmit) return;

    dispatch(signupEvent.start(params));
  };

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    const email = history.location?.state?.SIGNUP_REGISTER?.email;

    if (!email) {
      history.goBack();
    } else {
      setParams(params => ({ ...params, email }));
    }
  }, [dispatch, history]);

  useEffect(() => {
    if (isLogin)
      history.push(PATH.SIGNUP_ATTACH_GENRES, {
        referer: history.referer,
      });
  }, [history, isLogin]);

  useEffect(() => {
    if (!errors.email) return;

    history.replace(PATH.SIGNUP);
  }, [errors.email, history]);

  if (!history.location?.state?.SIGNUP_REGISTER?.email) return null;

  return (
    <>
      <Helmet>
        <title>アカウント作成 | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}

      <Register
        params={params}
        isAccepted={isAccepted}
        onChange={handleChange}
        onToggleAccepted={handleToggleAccepted}
        onClickRegister={handleRegister}
        errors={signUpValidationErrors}
        canSubmit={canSubmit}
      />
    </>
  );
};

export default RegisterContainer;
