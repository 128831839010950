/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './IconJasracLogo.module.scss';

interface Props {
  type?: string;
  cssClass?: string;
}

const Icon: FC<Props> = ({ type = 'Primary', cssClass = '' }) => {
  const classNames = ClassNames(styles.IconBase, cssClass, {
    [styles.IconPrimary]: type === 'Primary', // Primary
  });

  return <div className={classNames} />;
};

export default Icon;
