/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  cleanup,
  getFCFollowerListEvent,
} from '../../actions/followersActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import Followers from '../../components/artist/followers';

const FollowerContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{
    artistId?: string;
  }>();

  const [top, setTop] = useState<number>(0);
  const { ready: authReady } = useSelector(selector.auth.getAuthState);
  const { ready, loading, followers, pager, lastPage, details } = useSelector(
    selector.followers.getFollowersState,
  );

  const handleBack = useCallback(() => {
    if (!artistId) return;
    history.push(PATH.getArtistPath(artistId));
  }, [artistId, history]);

  const handleClickUser = useCallback(
    (userId: string) => {
      if (!userId) return;

      history.push(PATH.getUserPath(userId));
    },
    [history],
  );

  const handleReadMore = useCallback(() => {
    if (!artistId) return;
    const top = window.scrollY;
    setTop(top);
    dispatch(
      getFCFollowerListEvent.start({
        artistId,
        sort_type: 'DESC',
        limit: followers.length + 20,
        user_type: 3,
      }),
    );
  }, [artistId, followers, dispatch]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({}),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    window.scrollTo(0, top);
  }, [top, followers]);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!artistId) {
      history.replace(PATH.HOME);

      return;
    }

    dispatch(getReady(artistId));
  }, [artistId, authReady, dispatch, history]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>KRAP</title>
        ) : (
          <title>フォロワー | {details.fanclub.name} | KRAP</title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />
      <Followers
        handleBack={handleBack}
        total={pager.total}
        onClickUser={handleClickUser}
        isViewReadMoreButton={lastPage}
        followers={followers}
        handleReadMore={handleReadMore}
      />
    </>
  );
};

export default FollowerContainer;
