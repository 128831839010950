/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './Tab.module.scss';

interface Props {
  text: string;
  type?: string;
  size?: string;
  cssClass?: string;
  onClick?: () => void;
}

const Tab: FC<Props> = ({
  text = 'Tab',
  type = 'Primary',
  size = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.TabBase, cssClass, {
    [styles.TabPrimary]: type === 'Primary', // Primary
    [styles.TabSecondary]: type === 'Secondary', // Secondary
    [styles.TabTertiary]: type === 'Tertiary', // Tertiary
    [styles.TabQuaternary]: type === 'Quaternary', // Quaternary
    [styles.TabQuinary]: type === 'Quinary', //  Quinary
    [styles.TabSenary]: type === 'Senary', //  TSenary

    [styles.TabSizePrimary]: size === 'Primary', // Primary
  });

  return (
    <li className={classNames} onClick={onClick}>
      <span>{text}</span>
    </li>
  );
};

export default Tab;
