/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './LabelTag.module.scss';

interface Props {
  text: string;
  type?:
    | 'Primary'
    | 'Secondary'
    | 'Tertiary'
    | 'Quaternary'
    | 'Quinary'
    | 'Senary'
    | 'Septenary'
    | 'PickUp';
  htmlFor?: string | undefined;
  cssClass?: string;
  onClick?: () => void;
}

const Label: FC<Props> = ({
  text = 'Label',
  type = 'Primary',
  htmlFor = undefined,
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.LabelBase, cssClass, {
    [styles.LabelPrimary]: type === 'Primary', // Primary
    [styles.LabelSecondary]: type === 'Secondary', // Secondary
    [styles.LabelTertiary]: type === 'Tertiary', // Tertiary
    [styles.LabelQuaternary]: type === 'Quaternary', // Quaternary
    [styles.LabelQuinary]: type === 'Quinary', // Quinary
    [styles.LabelSenary]: type === 'Senary', // Senary
    [styles.LabelSeptenary]: type === 'Septenary', // Septenary
    [styles.LabelPickUp]: type === 'PickUp', // PickUp
  });

  return (
    <label className={classNames} htmlFor={htmlFor} onClick={onClick}>
      <span> {text} </span>
    </label>
  );
};

export default Label;
