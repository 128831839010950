export const convert2AccountHolder = (str: string) => {
  // 小文字は大文字へ
  // 半角は全角へ
  const kanaMap: { [key in string]: string } = {
    // 小文字
    ァ: 'ア',
    ィ: 'イ',
    ゥ: 'ウ',
    ェ: 'エ',
    ォ: 'オ',
    ッ: 'ツ',
    ャ: 'ヤ',
    ュ: 'ユ',
    ョ: 'ヨ',
    // 数字
    0: '０',
    1: '１',
    2: '２',
    3: '３',
    4: '４',
    5: '５',
    6: '６',
    7: '７',
    8: '８',
    9: '９',
    // 英字
    A: 'Ａ',
    B: 'Ｂ',
    C: 'Ｃ',
    D: 'Ｄ',
    E: 'Ｅ',
    F: 'Ｆ',
    G: 'Ｇ',
    H: 'Ｈ',
    I: 'Ｉ',
    J: 'Ｊ',
    K: 'Ｋ',
    L: 'Ｌ',
    M: 'Ｍ',
    N: 'Ｎ',
    O: 'Ｏ',
    P: 'Ｐ',
    Q: 'Ｑ',
    R: 'Ｒ',
    S: 'Ｓ',
    T: 'Ｔ',
    U: 'Ｕ',
    V: 'Ｖ',
    W: 'Ｗ',
    X: 'Ｘ',
    Y: 'Ｙ',
    Z: 'Ｚ',
    // 記号
    ' ': '　',
    '\\.': '．',
    ',': '，',
    '\\(': '（',
    '\\)': '）',
    '｢': '「',
    '｣': '」',
    ｰ: 'ー',
  };

  const reg = new RegExp(`(${Object.keys(kanaMap).join('|')})`, 'g');

  return str.replace(reg, match => kanaMap[match]).replace(/ﾞ/g, '゛');
  // .replace(/ﾟ/g, '゜');
};
