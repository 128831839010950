/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { FC, useMemo, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import {
  getReady,
  addFCCategoryEvent,
  createBlogEvent,
  updateBlogEvent,
  deleteBlogEvent,
  cleanup,
} from '../../actions/adminBlogRegisterActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { DATE_TIME_FORMAT } from '../../interfaces/commonInterFace';
import {
  ContentRegisterMode,
  InputBlogParams,
  CreateBlogParams,
  UpdateBlogParams,
  InputBlogParamsInitialValue,
  RegisterBlogValidationErrors,
  ContentType,
} from '../../interfaces/contentInterFace';
import {
  Category,
  CategoryWithSelected,
} from '../../interfaces/categoryInterFace';
import { validateDateTime } from '../../utils/validationHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminBlogRegister from '../../components/admin/adminBlogRegister';
import AdminBlogRegisterConfirm from '../../components/admin/adminBlogRegisterConfirm';

const AdminBlogRegisterContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId, contentId } = useParams<{
    artistId?: string;
    contentId?: string;
  }>();

  const [showConfirm, setShowConfirm] = useState(false);
  const [params, setParams] = useState<InputBlogParams>({
    ...InputBlogParamsInitialValue,
    release_at_date: moment().format('YYYY-MM-DD'),
    release_at_time: moment().format('HH:mm'),
  });
  const [newCategory, setNewCategory] = useState('');
  const {
    ready: authReady,
    profile: { user: me },
  } = useSelector(selector.auth.getAuthState);
  const {
    ready,
    loading,
    details,
    current,
    categories,
    addCategorySuccess,
    addCategoryErrors,
    registerBlogSuccess,
    contentBlogResult,
    registerBlogErrors,
    deleteBlogSuccess,
    deleteBlogError,
  } = useSelector(selector.adminBlogRegister.getAdminBlogRegisterState);
  const { open_area } = useSelector(selector.util.getSelectFields);
  const mode: ContentRegisterMode = useMemo(
    () => (contentId ? 'update' : 'create'),
    [contentId],
  );
  const title = useMemo(
    () => (mode === 'create' ? 'ブログ新規投稿' : 'ブログ編集'),
    [mode],
  );
  const currentPhotoList = useMemo(
    () =>
      Array.isArray(current.photos)
        ? current.photos.filter(
            photo => !params.delete_photo_ids.includes(photo.id),
          )
        : [],
    [current.photos, params.delete_photo_ids],
  );
  const categoryList: CategoryWithSelected[] = useMemo(
    () =>
      categories.map(category => ({
        ...category,
        selected: !!params.categories.find(id => id === category.id),
      })),
    [categories, params.categories],
  );
  const selectedCategoryList: Category[] = useMemo(() => {
    const list = categoryList.filter(c => c.selected);

    return list.length ? list : [{ id: 0, name: '未分類' }];
  }, [categoryList]);
  const openAreaField = useMemo(() => {
    return details.fanclub.paid_status === 2 && details.is_account_registerd
      ? open_area
      : open_area.filter(oa => oa.value !== 1);
  }, [details, open_area]);
  const isViewTab = useMemo(
    () => history.location?.state?.ADMIN_REGISTER_CONTENT?.mode === 'tab',
    [history.location],
  );
  const validationErrors: RegisterBlogValidationErrors = useMemo(
    () => ({
      thumb: registerBlogErrors?.thumb?.[0] ?? '',
      subject: registerBlogErrors?.subject?.[0] ?? '',
      categories: registerBlogErrors?.['categories.0']?.[0] ?? '',
      body: registerBlogErrors?.body?.[0] ?? '',
      photos: registerBlogErrors?.photos?.[0] ?? '',
      release_at:
        registerBlogErrors?.release_at?.[0] ??
        validateDateTime(`${params.release_at_date} ${params.release_at_time}`),
      close_at:
        registerBlogErrors?.close_at?.[0] ??
        validateDateTime(`${params.close_at_date} ${params.close_at_time}`),
      open_area: registerBlogErrors?.open_area?.[0] ?? '',
      delete_photo_ids: registerBlogErrors?.delete_photo_ids?.[0] ?? '',
      is_delete_thumb: registerBlogErrors?.is_delete_thumb?.[0] ?? '',
      is_release: registerBlogErrors?.is_release?.[0] ?? '',
    }),
    [registerBlogErrors, params],
  );
  const isRequiredEntered = useMemo(() => {
    const { subject, body, release_at_date } = params;

    if (!subject || !body || !release_at_date) return false;

    return true;
  }, [params]);

  const create = useCallback(
    (params: InputBlogParams) => {
      if (!artistId) return;

      const {
        release_at_date,
        release_at_time,
        close_at_date,
        close_at_time,
        thumb,
        photos,
        is_delete_thumb: _,
        ...data
      } = params;

      const release_at = release_at_date
        ? moment(`${release_at_date} ${release_at_time || '00:00'}`).format(
            DATE_TIME_FORMAT,
          )
        : '';

      const close_at = close_at_date
        ? moment(`${close_at_date} ${close_at_time || '00:00'}`).format(
            DATE_TIME_FORMAT,
          )
        : '';

      const createBlogParams: CreateBlogParams = {
        ...data,
        artistId,
        thumb: thumb.file,
        photos: photos.map(photo => photo.file),
        release_at,
        close_at,
        is_release: 1,
      };

      dispatch(createBlogEvent.start(createBlogParams));
    },
    [dispatch, artistId],
  );
  const update = useCallback(
    (params: InputBlogParams) => {
      if (!artistId) return;

      const {
        release_at_date,
        release_at_time,
        close_at_date,
        close_at_time,
        thumb,
        photos,
        is_delete_thumb,
        ...data
      } = params;

      const release_at = release_at_date
        ? moment(`${release_at_date} ${release_at_time || '00:00'}`).format(
            DATE_TIME_FORMAT,
          )
        : '';

      const close_at = close_at_date
        ? moment(`${close_at_date} ${close_at_time || '00:00'}`).format(
            DATE_TIME_FORMAT,
          )
        : '';

      const updateBlogParams: UpdateBlogParams = {
        ...data,
        artistId,
        contentId: Number(contentId),
        thumb: thumb.file,
        photos: photos.map(photo => photo.file),
        release_at,
        close_at,
        is_release: 1,
        is_delete_thumb: is_delete_thumb || undefined,
      };

      dispatch(updateBlogEvent.start(updateBlogParams));
    },
    [contentId, dispatch, artistId],
  );

  const handleChangeTab = useCallback(
    (type: ContentType) => {
      if (!artistId) return;

      const typeName =
        type === 1
          ? 'blog'
          : type === 2
          ? 'photo'
          : type === 3
          ? 'movie'
          : type === 4
          ? 'voice'
          : '';

      if (!typeName) return;

      history.replace(PATH.getAdminRegisterContentPath(artistId, typeName), {
        ADMIN_REGISTER_CONTENT: {
          mode: 'tab',
        },
      });
    },
    [artistId, history],
  );

  const handleChange = useCallback(
    (
      e: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const { name, value } = e.currentTarget;

      setParams(params => ({ ...params, [name]: value }));
    },
    [],
  );
  const handleChangeImage = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { name, files } = e.currentTarget;

      if (!files) return;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.size > 10000000) {
          window.alert('画像のファイルサイズは10MB以下です');

          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          setParams(params => ({
            ...params,
            [name]: {
              file,
              preview: reader.result as string,
            },
          }));
        };
        reader.readAsDataURL(file);
      }
    },
    [],
  );
  const handleDeleteThumbnail = useCallback(() => {
    setParams(params => ({
      ...params,
      thumb: {
        file: null,
        preview: '',
      },
    }));

    if (mode === 'update')
      setParams(params => ({ ...params, is_delete_thumb: true }));
  }, [mode]);
  const handleDeleteCurrentPhoto = useCallback((id: number) => {
    setParams(params => ({
      ...params,
      delete_photo_ids: [...params.delete_photo_ids, id],
    }));
  }, []);
  const handleAddNewPhoto = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { files } = e.currentTarget;

      if (!files) return;

      const count =
        files.length + currentPhotoList.length + params.photos.length <= 10
          ? files.length
          : 10 - currentPhotoList.length - params.photos.length;

      let hasSizeOverFile = false;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < count; i++) {
        const file = files[i];

        if (file.size > 10000000) {
          // limit 10MB
          hasSizeOverFile = true;

          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          setParams(params => ({
            ...params,
            photos: [
              ...params.photos,
              {
                file,
                preview: reader.result as string,
              },
            ],
          }));
        };
        reader.readAsDataURL(file);
      }

      if (hasSizeOverFile) window.alert('画像のファイルサイズは10MB以下です');
    },
    [currentPhotoList.length, params.photos.length],
  );
  const handleChangeCategory = useCallback((category_id: number) => {
    setParams(params => ({
      ...params,
      categories: params.categories.includes(category_id)
        ? params.categories.filter(c => c !== category_id)
        : [...params.categories, category_id],
    }));
  }, []);
  const handleDeleteNewPhoto = useCallback((index: number) => {
    setParams(params => ({
      ...params,
      photos: params.photos.filter((_, i) => i !== index),
    }));
  }, []);

  const handleConfirm = useCallback(() => {
    if (!isRequiredEntered) return;

    window.scrollTo(0, 0);
    setShowConfirm(true);
  }, [isRequiredEntered]);
  const handleModify = useCallback(() => {
    setShowConfirm(false);
    window.scrollTo(0, 0);
  }, []);
  const handlePublish = () => {
    if (mode === 'create') create(params);
    else update(params);
  };
  const handleSaveAsDraft = () => {
    if (!artistId) return;

    const {
      release_at_date,
      release_at_time,
      close_at_date,
      close_at_time,
      thumb,
      photos,
      is_delete_thumb,
      ...data
    } = params;

    const release_at = release_at_date
      ? moment(`${release_at_date} ${release_at_time || '00:00'}`).format(
          DATE_TIME_FORMAT,
        )
      : '';

    const close_at = close_at_date
      ? moment(`${close_at_date} ${close_at_time || '00:00'}`).format(
          DATE_TIME_FORMAT,
        )
      : '';

    const createBlogParams: CreateBlogParams = {
      ...data,
      artistId,
      thumb: thumb.file,
      photos: photos.map(photo => photo.file),
      release_at,
      close_at,
      is_release: 0,
    };
    const updateBlogParams: UpdateBlogParams = {
      ...createBlogParams,
      contentId: Number(contentId),
      is_delete_thumb: is_delete_thumb || undefined,
    };

    if (mode === 'create') dispatch(createBlogEvent.start(createBlogParams));
    else dispatch(updateBlogEvent.start(updateBlogParams));
  };
  const handleDelete = useCallback(() => {
    if (!artistId) return;

    const res = window.confirm('本当に削除してもよろしいですか？');

    if (!res) return;

    dispatch(
      deleteBlogEvent.start({
        type: 1,
        artistId,
        contentId: Number(contentId),
      }),
    );
  }, [contentId, dispatch, artistId]);

  const handleBack = useCallback(() => {
    const res = window.confirm('編集した内容を破棄しますか？');
    if (!res) return;

    history.goBack();
  }, [history]);

  const handleChangeNewCategory = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setNewCategory(e.currentTarget.value);
    },
    [],
  );

  const handleAddCategory = useCallback(() => {
    if (!artistId) return;

    dispatch(
      addFCCategoryEvent.start({
        artistId,
        name: newCategory,
      }),
    );
  }, [dispatch, artistId, newCategory]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!artistId) {
      history.replace(PATH.HOME);

      return;
    }

    dispatch(getReady(artistId, Number(contentId)));
  }, [artistId, authReady, contentId, dispatch, history]);

  useEffect(() => {
    if (!current.detail.id) return;

    if (
      !(
        (current.detail.user_id === me.id && details.user_type === 2) ||
        details.user_type === 3
      )
    ) {
      history.replace(PATH.NotFound);

      return;
    }

    const {
      detail: { release_at, close_at, subject, body, open_area, url },
      categories,
    } = current;

    const [release_at_date, release_at_time] = release_at
      ? moment(release_at)
          .format(DATE_TIME_FORMAT)
          .split(' ')
      : ['', ''];
    const [close_at_date, close_at_time] = close_at
      ? moment(close_at)
          .format(DATE_TIME_FORMAT)
          .split(' ')
      : ['', ''];

    const params: InputBlogParams = {
      thumb: {
        file: null,
        preview: url ?? '',
      },
      subject: subject ?? '',
      body: body ?? '',
      categories: categories.map(c => c.value),
      open_area: open_area || '',
      photos: [],
      release_at_date,
      release_at_time,
      close_at_date,
      close_at_time,
      delete_photo_ids: [],
      is_delete_thumb: false,
    };

    setParams(params);
  }, [current, details.user_type, history, me.id]);

  useEffect(() => {
    if (!registerBlogSuccess || !artistId) return;

    history.replace(
      PATH.getAdminPath(artistId, 'CONTENTS'),
      contentBlogResult.is_release === 1
        ? {
            ADMIN_CONTENTS: {
              mode,
              title: contentBlogResult.subject,
              artistId,
              contentId: contentBlogResult.id,
            },
          }
        : undefined,
    );
  }, [
    artistId,
    contentBlogResult.id,
    contentBlogResult.is_release,
    contentBlogResult.subject,
    history,
    mode,
    registerBlogSuccess,
  ]);

  useEffect(() => {
    if (!addCategorySuccess) return;

    setNewCategory('');
    setParams(params => ({
      ...params,
      categories: [...params.categories, categories[categories.length - 1].id],
    }));
  }, [addCategorySuccess, categories]);

  useEffect(() => {
    if (!artistId) return;

    if (deleteBlogSuccess) {
      history.replace(PATH.getAdminPath(artistId, 'CONTENTS'));
    }

    if (deleteBlogError) {
      window.alert('ブログの削除に失敗しました');
    }
  }, [deleteBlogError, deleteBlogSuccess, artistId, history]);

  useEffect(() => {
    if (JSON.stringify(registerBlogErrors) !== JSON.stringify({})) {
      setShowConfirm(false);
      window.scrollTo(0, 0);
      window.alert('入力項目を確認してください');
    }
  }, [registerBlogErrors]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            {title} | {details.fanclub.name} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      {showConfirm ? (
        <AdminBlogRegisterConfirm
          mode={mode}
          title={title}
          params={params}
          fanclubName={details.fanclub.site_name}
          categoryList={selectedCategoryList}
          currentPhotoList={currentPhotoList}
          openAreaList={open_area}
          onPublish={handlePublish}
          onModify={handleModify}
          onBack={handleBack}
        />
      ) : (
        <AdminBlogRegister
          mode={mode}
          type={1}
          title={title}
          params={params}
          fanclubName={details.fanclub.site_name}
          categoryList={categoryList}
          openAreaList={openAreaField}
          isViewTab={isViewTab}
          currentPhotoList={currentPhotoList}
          onChangeTab={handleChangeTab}
          onChange={handleChange}
          onChangeImage={handleChangeImage}
          onAddNewPhoto={handleAddNewPhoto}
          onClickDeleteThumbnail={handleDeleteThumbnail}
          onDeleteCurrentPhoto={handleDeleteCurrentPhoto}
          onDeleteNewPhoto={handleDeleteNewPhoto}
          // onClickDeletePhoto={handleDeletePhoto}
          onChangeCategory={handleChangeCategory}
          canClickConfirm={isRequiredEntered}
          onConfirm={handleConfirm}
          onSaveAsDraft={handleSaveAsDraft}
          onDelete={handleDelete}
          onBack={handleBack}
          errors={validationErrors}
          newCategory={newCategory}
          onChangeNewCategory={handleChangeNewCategory}
          onAddCategory={handleAddCategory}
          categoryError={addCategoryErrors?.name?.[0]}
        />
      )}
    </>
  );
};

export default AdminBlogRegisterContainer;
