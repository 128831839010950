import React, { FC } from 'react';
import ClassNames from 'classnames';
import TitleDiv from '../../atoms/title/TitleDiv';
import ButtonSquare from '../../atoms/buttonBasic/ButtonSquare';
import * as styles from './McsBannerPrimary.module.scss';

interface Props {
  cssClass?: string;
  bannerText?: string;
  buttonText?: string;
  onClick?: () => void;
}

const McsBanner: FC<Props> = ({
  cssClass = '',
  bannerText = 'Title',
  buttonText = 'Button',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsBannerBase, cssClass);

  return (
    <div className={classNames}>
      <TitleDiv text={bannerText} cssClass={styles.McsBannerTitle} />
      <ButtonSquare
        text={buttonText}
        onClick={onClick}
        cssClass={styles.McsBannerButton}
      />
    </div>
  );
};

export default McsBanner;
