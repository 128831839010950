import * as ActionType from './homeConstants';
import {
  GetPickupFanclubListParams,
  GetPickupFanclubListResult,
  GetRecommendFanclubListParams,
  GetRecommendFanclubListResult,
} from '../interfaces/fanclubInterFace';
import {
  GetContentListForHomeParams,
  GetContentListResult,
} from '../interfaces/contentInterFace';

export const getReady = (
  type: 'guest' | 'service' | 'fan',
  params: GetContentListForHomeParams,
) =>
  ({
    type: ActionType.GET_READY,
    payload: { type, params },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getPickupFanclubListEvent = {
  start: (params: GetPickupFanclubListParams) =>
    ({
      type: ActionType.GET_PICKUP_FANCLUB_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetPickupFanclubListResult) =>
    ({
      type: ActionType.GET_PICKUP_FANCLUB_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_PICKUP_FANCLUB_LIST_FAIL,
    } as const),
};

export const getRecommendFanclubListEvent = {
  start: (params: GetRecommendFanclubListParams) =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetRecommendFanclubListResult) =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_FAIL,
    } as const),
};

export const getContentListEvent = {
  start: (params: GetContentListForHomeParams, withLoading = false) =>
    ({
      type: ActionType.GET_CONTENT_LIST_WITH_PAGER_START,
      payload: { params, withLoading },
    } as const),

  succeed: (
    params: GetContentListForHomeParams,
    result: GetContentListResult,
  ) =>
    ({
      type: ActionType.GET_CONTENT_LIST_WITH_PAGER_SUCCEED,
      payload: { params, result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_CONTENT_LIST_WITH_PAGER_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get pickup fanclub list
  | ReturnType<typeof getPickupFanclubListEvent.start>
  | ReturnType<typeof getPickupFanclubListEvent.succeed>
  | ReturnType<typeof getPickupFanclubListEvent.fail>
  // get recommend fanclub list
  | ReturnType<typeof getRecommendFanclubListEvent.start>
  | ReturnType<typeof getRecommendFanclubListEvent.succeed>
  | ReturnType<typeof getRecommendFanclubListEvent.fail>
  // get content list
  | ReturnType<typeof getContentListEvent.start>
  | ReturnType<typeof getContentListEvent.succeed>
  | ReturnType<typeof getContentListEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
