/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/adminContentsConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getFCMemberListEvent,
  getFCCategoryListEvent,
  getContentListEvent,
  deleteContentEvent,
} from '../actions/adminContentsActions';
import { ErrorResponse } from '../interfaces/commonInterFace';
import {
  GetContentListResponse,
  DeleteContentResponse,
} from '../interfaces/contentInterFace';
import { GetFCMemberListResponse } from '../interfaces/memberInterFace';
import { GetFCCategoryListResponse } from '../interfaces/categoryInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId } = action.payload;

  const getFCMemberListTask = yield fork(
    runGetFCMemberList,
    getFCMemberListHandler,
    getFCMemberListEvent.start({ artistId }),
  );
  yield join(getFCMemberListTask);

  const getFCCategoryListTask = yield fork(
    runGetFCCategoryList,
    getFCCategoryListHandler,
    getFCCategoryListEvent.start({ artistId }),
  );
  yield join(getFCCategoryListTask);

  const getContentListTask = yield fork(
    runGetContentList,
    getContentListHandler,
    getContentListEvent.start({
      type: 1,
      group_id: artistId,
      user_type: 'artist',
      reset: true,
    }),
  );
  yield join(getContentListTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get fc member list
const getFCMemberListHandler = api.getGetFactory(true);
function* runGetFCMemberList(
  handler: typeof getFCMemberListHandler,
  action: ReturnType<typeof getFCMemberListEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetFCMemberListResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/member`,
    );

    if (!ReturnData) {
      yield put(getFCMemberListEvent.fail());

      return;
    }

    yield put(getFCMemberListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFCMemberListEvent.fail());
  }
}
export function* getFCMemberList(handler: typeof getFCMemberListHandler) {
  yield takeLatest(
    ActionType.GET_FC_MEMBER_LIST_START,
    runGetFCMemberList,
    handler,
  );
}

// get fc category list
const getFCCategoryListHandler = api.getGetFactory(true);
function* runGetFCCategoryList(
  handler: typeof getFCCategoryListHandler,
  action: ReturnType<typeof getFCCategoryListEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetFCCategoryListResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/category`,
    );

    if (!ReturnData) {
      yield put(getFCCategoryListEvent.fail());

      return;
    }

    yield put(getFCCategoryListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFCCategoryListEvent.fail());
  }
}
export function* getFCCategoryList(handler: typeof getFCCategoryListHandler) {
  yield takeLatest(
    ActionType.GET_FC_CATEGORY_LIST_START,
    runGetFCCategoryList,
    handler,
  );
}

// get content list
const getContentListHandler = api.getGetFactory(true);
function* runGetContentList(
  handler: typeof getContentListHandler,
  action: ReturnType<typeof getContentListEvent.start>,
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { reset: _, ...data } = action.payload;

  try {
    const ReturnData: GetContentListResponse = yield call(
      handler,
      data,
      '/contents',
    );

    if (!ReturnData) {
      yield put(getContentListEvent.fail());

      return;
    }

    yield put(getContentListEvent.succeed(action.payload, ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getContentListEvent.fail());
  }
}
export function* getContentList(handler: typeof getContentListHandler) {
  yield takeLatest(
    ActionType.GET_CONTENT_LIST_START,
    runGetContentList,
    handler,
  );
}

// delete content
const deleteContentHandler = api.getDeleteFactory(true);
function* runDeleteContent(
  handler: typeof deleteContentHandler,
  action: ReturnType<typeof deleteContentEvent.start>,
) {
  const { type, artistId, contentId, ...dataForUpdate } = action.payload;

  const name =
    type === 1
      ? 'blog'
      : type === 2
      ? 'photo'
      : type === 3
      ? 'movie'
      : type === 4
      ? 'voice'
      : '';

  try {
    const ReturnData: DeleteContentResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/${name}/${contentId}`,
    );

    if (!ReturnData) {
      yield put(deleteContentEvent.fail());

      return;
    }

    // update contents
    yield put(
      getContentListEvent.start({
        group_id: artistId,
        type,
        reset: true,
        ...dataForUpdate,
      }),
    );

    yield put(deleteContentEvent.succeed(action.payload));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(deleteContentEvent.fail());
  }
}
export function* deleteContent(handler: typeof deleteContentHandler) {
  yield takeLeading(ActionType.DELETE_CONTENT_START, runDeleteContent, handler);
}

export const adminContentsSagas = [
  fork(getReady),
  fork(getContentList, getContentListHandler),
  fork(getFCMemberList, getFCMemberListHandler),
  fork(getFCCategoryList, getFCCategoryListHandler),
  fork(deleteContent, deleteContentHandler),
];
