/**
 * 0: false, 1: true
 */
export type BoolNumber = 0 | 1;

export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss' as const;

export enum Status {
  OK = 200,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  ValidationFailed = 422,
  ServerError = 500,
}

export type ErrorResponse<ValidationErrorKeys extends string = ''> =
  | {
      status_code: Exclude<Status, Status.OK | Status.ValidationFailed>;
      message: string;
    }
  | {
      status_code: Status.ValidationFailed;
      summary: string; // Failed validation.
      errors: { [key in ValidationErrorKeys]: string[] };
    };

export type Pager = {
  // page number
  current_page: number;
  last_page: number;
  from: number;
  to: number;
  total: number;
  // page content count
  per_page: number;
  // url
  first_page_url: string;
  prev_page_url: string;
  path: string;
  next_page_url: string;
  last_page_url: string;
};
export const PagerInitialValue: Pager = {
  current_page: 0,
  last_page: 0,
  from: 0,
  to: 0,
  total: 0,
  per_page: 0,
  first_page_url: '',
  prev_page_url: '',
  path: '',
  next_page_url: '',
  last_page_url: '',
};

export type DeepPartial<T> = { [P in keyof T]?: DeepPartial<T[P]> };

export type commonResponse = {
  status_code: Status.OK;
  message: string;
};
