import React, { FC } from 'react';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';
import CheckBoxButton from '../common/atoms/formParts/CheckBoxButton';
import InputFileBox from '../common/atoms/formParts/InputFileBox';
import LabelTitle from '../common/atoms/title/TitleDiv';

import McsInputSecondary from '../common/molecules/formParts/McsInputSecondary';
import McsSelectSecondary from '../common/molecules/formParts/McsSelectSecondary';
import McsTextAreaSecondary from '../common/molecules/formParts/McsTextAreaSecondary';
import McsSelectBirthSecondary from '../common/molecules/formParts/McsSelectBirthSecondary';

import {
  UpdateUserProfileInputParams,
  UpdateUserProfileInputParamsInitialValue,
  UpdateUserProfileValidationErrors,
  UpdateUserProfileValidationErrorsInitialValue,
} from '../../interfaces/userInterFace';
import { GenreWithSelected } from '../../interfaces/genreInterFace';

import * as styles from './editUserProfile.module.scss';
import { SelectField } from '../../interfaces/utilInterFace';

type Props = {
  userId?: string;
  user?: UpdateUserProfileInputParams;
  prefectureList?: SelectField;
  genreList?: GenreWithSelected[][];
  yearList?: SelectField;
  monthList?: SelectField;
  dateList?: SelectField;
  onChange?: (
    e: React.FormEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key?: string,
  ) => void;
  onChangeImage?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  onChangeGenre?: (genreId: number) => void;
  onBack?: () => void;
  onSubmit?: () => void;
  errors?: UpdateUserProfileValidationErrors;
  canSubmit?: boolean;
};

const Component: FC<Props> = ({
  userId = '',
  user = UpdateUserProfileInputParamsInitialValue,
  prefectureList = [],
  genreList = [],
  yearList = [],
  monthList = [],
  dateList = [],
  onChange = () => undefined,
  onChangeImage = () => undefined,
  onChangeGenre = () => undefined,
  onBack = () => undefined,
  onSubmit = () => undefined,
  errors = UpdateUserProfileValidationErrorsInitialValue,
  canSubmit = false,
}) => {
  return (
    <div className={styles.containerWrap}>
      <div className={styles.fileBox}>
        <InputFileBox
          preview={user.icon_image.preview}
          previewHero={user.cover_image.preview}
          inputName="icon_image"
          inputNameHero="cover_image"
          onChange={onChangeImage}
          isError={!!errors.cover_image || !!errors.icon_image}
          errorText={errors.icon_image}
          errorTextHero={errors.cover_image}
        />
      </div>
      <McsInputSecondary
        value={user.nickname}
        inputType="text"
        inputName="nickname"
        placeholder="ニックネームを入力"
        labelTitle="ニックネーム"
        cssClass={styles.inputBox}
        maxLength={20}
        onChange={onChange}
        isError={!!errors.nickname}
        errorText={errors.nickname}
      />
      <McsInputSecondary
        value={userId}
        labelTitle="ID"
        cssClass={styles.inputBox}
        labelTag="変更不可"
        isDisabled
        isError={!!errors.name_id}
        errorText={errors.name_id}
      />

      <McsSelectSecondary
        value={user.prefecture.toString()}
        inputName="prefecture"
        labelTitle="活動拠点"
        placeholder="選択してください"
        selectList={prefectureList}
        onChange={onChange}
        cssClass={styles.inputBox}
        isError={!!errors.prefecture}
        errorText={errors.prefecture}
      />

      <McsTextAreaSecondary
        TextAreaName="self_introduction"
        value={user.self_introduction}
        placeholder="自己紹介を入力"
        maxLength={150}
        onChange={onChange}
        labelTitle="自己紹介"
        cssClass={styles.inputBox}
        isError={!!errors.self_introduction}
        errorText={errors.self_introduction}
      />

      <div
        className={styles.inputBox}
        style={{ display: 'flex', flexDirection: 'row', marginBottom: 8 }}
      >
        <LabelTitle text="好きな音楽ジャンル" />
        {!!errors.genres && (
          <div style={{ marginLeft: '8px' }}>
            <span className={styles.errorText}>{errors.genres}</span>
          </div>
        )}
      </div>
      <div className={styles.GenresContainer}>
        {genreList.map((group, i) => (
          <div key={`GenreGroup-${i.toString()}`}>
            <hr className={styles.hr} />
            {group.map((genre, j) => (
              <CheckBoxButton
                key={`GenreGroup-${i.toString()}-Genre-${j.toString()}`}
                checked={genre.selected}
                labelText={genre.name}
                onChange={() => onChangeGenre(genre.id)}
                cssClass={styles.buttonMini}
              />
            ))}
          </div>
        ))}
      </div>

      <McsSelectBirthSecondary
        inputName1="birthday_year"
        inputName2="birthday_month"
        inputName3="birthday_date"
        placeholder1="年"
        placeholder2="月"
        placeholder3="日"
        value1={user.birthday_year.toString()}
        value2={user.birthday_month.toString()}
        value3={user.birthday_date.toString()}
        labelTitle="生年月日"
        selectList1={yearList}
        selectList2={monthList}
        selectList3={dateList}
        onChange={onChange}
        cssClass={styles.inputBox}
        isError={!!errors.birthday}
        errorText={errors.birthday}
      />

      <div className={styles.buttonWrap}>
        <ButtonRoundedCorners
          text="保存"
          type={canSubmit ? 'Primary' : 'Quaternary'}
          size="Primary"
          onClick={onSubmit}
          cssClass={styles.button}
        />
        <ButtonRoundedCorners
          text="戻る"
          type="Quinary"
          size="Primary"
          onClick={onBack}
          cssClass={styles.cancel}
        />
      </div>
    </div>
  );
};

export default Component;
