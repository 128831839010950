/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  submitCommentEvent,
  deleteCommentEvent,
  freejoinFanclubEvent,
  cleanup,
} from '../../actions/commentsActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import Comment from '../../components/artist/comment';

const CommentsContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId, contentId } = useParams<{
    artistId?: string;
    contentId?: string;
  }>();

  const [comment, setComment] = useState('');
  const {
    ready: authReady,
    profile: {
      user,
      fanclubs: { follows, manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const {
    ready,
    comments,
    detail,
    create_user,
    loading,
    success,
  } = useSelector(selector.comments.getCommentsState);
  const isAdminUser = useMemo(() => {
    const isAdmin =
      manages.find(fanclub => fanclub.group_id === artistId)?.pivot?.type === 3;
    if (isAdmin) return true;

    if (user.name_id === create_user?.name_id) return true;

    return false;
  }, [artistId, create_user, manages, user.name_id]);
  const isViewFollowArtist = useMemo(
    () =>
      !follows.find(fanclub => fanclub.group_id === artistId) &&
      !manages.find(fanclub => fanclub.group_id === artistId),
    [artistId, follows, manages],
  );

  const handleBack = useCallback(() => {
    if (!artistId || !contentId) return;

    const cid = Number(contentId);
    if (!cid) return;

    history.push(PATH.getContentPath(artistId, cid));
  }, [artistId, contentId, history]);
  const handleFollow = useCallback(() => {
    if (!artistId) return;

    isLogin
      ? dispatch(freejoinFanclubEvent.start({ artistId }))
      : history.push(PATH.SIGNUP);
  }, [artistId, dispatch, history, isLogin]);
  const handleClickUser = useCallback(
    (userId: string) => {
      if (!userId) return;

      history.push(PATH.getUserPath(userId));
    },
    [history],
  );
  const handleDeleteComment = useCallback(
    (commentId: number) => {
      const res = window.confirm('コメントを削除しますか？');

      if (!res) return;

      dispatch(deleteCommentEvent.start({ commentId }));
    },
    [dispatch],
  );
  const handleChangeComment = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      setComment(e.currentTarget.value);
    },
    [],
  );
  const handleSubmitComment = useCallback(() => {
    const trimmedComment = comment.trim();

    if (trimmedComment.length === 0) return;

    dispatch(
      submitCommentEvent.start({
        content_id: Number(contentId),
        comment: trimmedComment,
        user: {
          name_id: user.name_id,
          nickname: user.nickname,
          icon_image: user.icon_image,
        },
      }),
    );
  }, [
    comment,
    contentId,
    dispatch,
    user.icon_image,
    user.name_id,
    user.nickname,
  ]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({}),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!artistId || !contentId) {
      history.replace(PATH.HOME);

      return;
    }

    window.scrollTo(0, window.innerHeight);
    dispatch(getReady(Number(contentId)));
  }, [artistId, authReady, contentId, dispatch, history]);

  useEffect(() => {
    if (success) {
      setComment('');
      window.scrollTo(0, window.innerHeight);
    }
  }, [success]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>KRAP</title>
        ) : (
          <title>
            コメント | {detail.subject} | {detail.fanclub.name} | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      <Comment
        user={user}
        fanclub={detail.fanclub}
        comment={comment}
        isAdmin={isAdminUser}
        comments={comments}
        handleBack={handleBack}
        handleFollow={handleFollow}
        handleClickUser={handleClickUser}
        handleDeleteComment={handleDeleteComment}
        handleChangeComment={handleChangeComment}
        handleSubmitComment={handleSubmitComment}
        isViewFollowArtist={isViewFollowArtist}
      />
    </>
  );
};

export default CommentsContainer;
