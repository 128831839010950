export const GET_READY = 'LIKES/GET_READY' as const;
export const GOT_READY = 'LIKES/GOT_READY' as const;

export const GET_CONTENT_DETAILS_START = 'LIKES/GET_CONTENT_DETAILS_START' as const;
export const GET_CONTENT_DETAILS_SUCCEED = 'LIKES/GET_CONTENT_DETAILS_SUCCEED' as const;
export const GET_CONTENT_DETAILS_FAIL = 'LIKES/GET_CONTENT_DETAILS_FAIL' as const;

export const GET_LIKED_USERS_START = 'LIKES/GET_LIKE_USERS_START' as const;
export const GET_LIKED_USERS_SUCCEED = 'LIKES/GET_LIKE_USERS_SUCCEED' as const;
export const GET_LIKED_USERS_FAIL = 'LIKES/GET_LIKE_USERS_FAIL' as const;

export const CLEANUP = 'LIKES/CLEANUP' as const;
