/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import OrgUserListUnitMemberSecondary from '../listUnitParts/OrgUserListUnitMemberSecondary';
import { User } from '../../../../interfaces/userInterFace';

import * as styles from './OrgInviteMemberListSub.module.scss';

type PartialUser = Partial<User>;

interface Props {
  cssClass?: string;
  userList?: PartialUser[];
  onInviteClick?: (userId: string) => void;
}

const OrgMemberList: FC<Props> = ({
  cssClass = '',
  userList = [],
  onInviteClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgInviteMemberListBase, cssClass);

  return (
    <div className={classNames}>
      <ul className={styles.OrgInviteMemberListWrap}>
        {userList.map((item, i) => (
          <OrgUserListUnitMemberSecondary
            user={item}
            onInviteClick={() => onInviteClick(item?.name_id || '')}
            key={`OrgUserListUnitMemberPrimary${i}`}
          />
        ))}
      </ul>
    </div>
  );
};

export default OrgMemberList;
