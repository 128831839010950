import { Reducer } from 'redux';

import { Actions } from '../actions/adminApplyPaidFCActions';
import * as ActionType from '../actions/adminApplyPaidFCConstants';

import { ApplyPaidFCErrors } from '../interfaces/fanclubInterFace';
import { Address } from '../interfaces/addressInterFace';

type State = {
  address: Address | null;
  ready: boolean;
  loading: boolean;
  success: boolean;
  errors: ApplyPaidFCErrors;
};

export const initialState: State = {
  address: null,
  ready: false,
  loading: false,
  success: false,
  errors: {},
};

const adminApplyPaidFCReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get address
    case ActionType.GET_ADDRESS_START: {
      return {
        ...state,
      };
    }
    case ActionType.GET_ADDRESS_SUCCEED: {
      const address = action.payload.length ? action.payload[0] : null;

      if (!address) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        address,
      };
    }
    case ActionType.GET_ADDRESS_FAIL: {
      return {
        ...state,
      };
    }

    // apply paid fc
    case ActionType.APPLY_PAID_FC_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.APPLY_PAID_FC_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ActionType.APPLY_PAID_FC_FAIL: {
      const { errors } = action.payload;

      return {
        ...state,
        loading: false,
        errors,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default adminApplyPaidFCReducer;
