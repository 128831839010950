/* eslint-disable react/jsx-pascal-case */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { FC, useMemo, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  addFCCategoryEvent,
  createPhotoEvent,
  updatePhotoEvent,
  deletePhotoEvent,
  cleanup,
} from '../../actions/adminPhotoRegisterActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { DATE_TIME_FORMAT } from '../../interfaces/commonInterFace';
import {
  ContentRegisterMode,
  InputPhotoParams,
  CreatePhotoParams,
  UpdatePhotoParams,
  InputPhotoParamsInitialValue,
  RegisterPhotoValidationErrors,
  ContentType,
} from '../../interfaces/contentInterFace';
import {
  Category,
  CategoryWithSelected,
} from '../../interfaces/categoryInterFace';

import { validateDateTime } from '../../utils/validationHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminPhotoRegister from '../../components/admin/adminPhotoRegister';
import AdminPhotoRegisterConfirm from '../../components/admin/adminPhotoRegisterConfirm';

const AdminPhotoRegisterContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId, contentId } = useParams<{
    artistId?: string;
    contentId?: string;
  }>();

  const [showConfirm, setShowConfirm] = useState(false);
  const [params, setParams] = useState<InputPhotoParams>({
    ...InputPhotoParamsInitialValue,
    release_at_date: moment().format('YYYY-MM-DD'),
    release_at_time: moment().format('HH:mm'),
  });
  const [newCategory, setNewCategory] = useState('');
  const {
    ready: authReady,
    profile: { user: me },
  } = useSelector(selector.auth.getAuthState);
  const {
    ready,
    loading,
    details,
    current,
    categories,
    addCategorySuccess,
    addCategoryErrors,
    registerPhotoSuccess,
    contentPhotoResult,
    registerPhotoErrors,
    deletePhotoSuccess,
    deletePhotoError,
  } = useSelector(selector.adminPhotoRegister.getAdminPhotoRegisterState);
  const { open_area } = useSelector(selector.util.getSelectFields);
  const mode: ContentRegisterMode = useMemo(
    () => (contentId ? 'update' : 'create'),
    [contentId],
  );
  const title = useMemo(
    () => (mode === 'create' ? '画像新規投稿' : '画像編集'),
    [mode],
  );
  const currentPhotoList = useMemo(
    () =>
      Array.isArray(current.photos)
        ? current.photos.filter(
            photo => !params.delete_photo_ids.includes(photo.id),
          )
        : [],
    [current.photos, params.delete_photo_ids],
  );
  const categoryList: CategoryWithSelected[] = useMemo(
    () =>
      categories.map(category => ({
        id: category.id,
        name: category.name,
        selected: !!params.categories.find(id => id === category.id),
      })),
    [categories, params.categories],
  );
  const selectedCategoryList: Category[] = useMemo(() => {
    const list = categoryList.filter(c => c.selected);

    return list.length ? list : [{ id: 0, name: '未分類' }];
  }, [categoryList]);
  const openAreaField = useMemo(() => {
    return details.fanclub.paid_status === 2 && details.is_account_registerd
      ? open_area
      : open_area.filter(oa => oa.value !== 1);
  }, [details, open_area]);
  const isViewTab = useMemo(
    () => history.location?.state?.ADMIN_REGISTER_CONTENT?.mode === 'tab',
    [history.location],
  );
  const validationErrors: RegisterPhotoValidationErrors = useMemo(
    () => ({
      thumb: registerPhotoErrors?.thumb?.[0] ?? '',
      subject: registerPhotoErrors?.subject?.[0] ?? '',
      categories: registerPhotoErrors?.['categories.0']?.[0] ?? '',
      photos: registerPhotoErrors?.['photos.0']?.[0] ?? '',
      release_at:
        registerPhotoErrors?.release_at?.[0] ??
        validateDateTime(`${params.release_at_date} ${params.release_at_time}`),
      close_at:
        registerPhotoErrors?.close_at?.[0] ??
        validateDateTime(`${params.close_at_date} ${params.close_at_time}`),
      open_area: registerPhotoErrors?.open_area?.[0] ?? '',
      delete_photo_ids: registerPhotoErrors?.delete_photo_ids?.[0] ?? '',
      is_release: registerPhotoErrors?.is_release?.[0] ?? '',
    }),
    [
      params.close_at_date,
      params.close_at_time,
      params.release_at_date,
      params.release_at_time,
      registerPhotoErrors,
    ],
  );
  const isRequiredEntered = useMemo(() => {
    const { subject, photos, release_at_date } = params;

    if (
      !subject ||
      (!currentPhotoList.length && !photos.length) ||
      !release_at_date
    )
      return false;

    return true;
  }, [currentPhotoList.length, params]);

  const create = useCallback(
    (params: InputPhotoParams) => {
      if (!artistId) return;

      const {
        release_at_date,
        release_at_time,
        close_at_date,
        close_at_time,
        photos,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ...data
      } = params;

      const release_at = release_at_date
        ? moment(`${release_at_date} ${release_at_time || '00:00'}`).format(
            DATE_TIME_FORMAT,
          )
        : '';

      const close_at = close_at_date
        ? moment(`${close_at_date} ${close_at_time || '00:00'}`).format(
            DATE_TIME_FORMAT,
          )
        : '';

      const createPhotoParams: CreatePhotoParams = {
        ...data,
        artistId,
        photos: photos.map(photo => photo.file),
        release_at,
        close_at,
        is_release: 1,
      };

      dispatch(createPhotoEvent.start(createPhotoParams));
    },
    [dispatch, artistId],
  );
  const update = useCallback(
    (params: InputPhotoParams) => {
      if (!artistId) return;

      const {
        photos,
        release_at_date,
        release_at_time,
        close_at_date,
        close_at_time,
        ...data
      } = params;

      const release_at = release_at_date
        ? moment(`${release_at_date} ${release_at_time || '00:00'}`).format(
            DATE_TIME_FORMAT,
          )
        : '';

      const close_at = close_at_date
        ? moment(`${close_at_date} ${close_at_time || '00:00'}`).format(
            DATE_TIME_FORMAT,
          )
        : '';

      const updatePhotoParams: UpdatePhotoParams = {
        ...data,
        artistId,
        contentId: Number(contentId),
        thumb: null,
        photos: photos.map(photo => photo.file),
        release_at,
        close_at,
        is_release: 1,
      };

      dispatch(updatePhotoEvent.start(updatePhotoParams));
    },
    [contentId, dispatch, artistId],
  );

  const handleChangeTab = useCallback(
    (type: ContentType) => {
      if (!artistId) return;

      const typeName =
        type === 1
          ? 'blog'
          : type === 2
          ? 'photo'
          : type === 3
          ? 'movie'
          : type === 4
          ? 'voice'
          : '';

      if (!typeName) return;

      history.replace(PATH.getAdminRegisterContentPath(artistId, typeName), {
        ADMIN_REGISTER_CONTENT: {
          mode: 'tab',
        },
      });
    },
    [artistId, history],
  );

  const handleChange = useCallback(
    (
      e: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const { name, value } = e.currentTarget;

      setParams(params => ({ ...params, [name]: value }));
    },
    [],
  );
  const handleChangeCategory = useCallback((category_id: number) => {
    setParams(params => ({
      ...params,
      categories: params.categories.includes(category_id)
        ? params.categories.filter(c => c !== category_id)
        : [...params.categories, category_id],
    }));
  }, []);
  const handleDeleteCurrentPhoto = useCallback((id: number) => {
    setParams(params => ({
      ...params,
      delete_photo_ids: [...params.delete_photo_ids, id],
    }));
  }, []);
  const handleAddNewPhoto = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { files } = e.currentTarget;

      if (!files) return;

      const count =
        files.length + currentPhotoList.length + params.photos.length <= 10
          ? files.length
          : 10 - currentPhotoList.length - params.photos.length;

      let hasSizeOverFile = false;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < count; i++) {
        const file = files[i];

        if (file.size > 10000000) {
          // limit 10MB
          hasSizeOverFile = true;

          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          setParams(params => ({
            ...params,
            photos: [
              ...params.photos,
              {
                file,
                preview: reader.result as string,
              },
            ],
          }));
        };
        reader.readAsDataURL(file);
      }

      if (hasSizeOverFile) window.alert('画像のファイルサイズは10MB以下です');
    },
    [currentPhotoList.length, params.photos.length],
  );
  const handleDeleteNewPhoto = useCallback((index: number) => {
    setParams(params => ({
      ...params,
      photos: params.photos.filter((_, i) => i !== index),
    }));
  }, []);

  const handleConfirm = useCallback(() => {
    if (!isRequiredEntered) return;

    window.scrollTo(0, 0);
    setShowConfirm(true);
  }, [isRequiredEntered]);
  const handleModify = useCallback(() => {
    setShowConfirm(false);
    window.scrollTo(0, 0);
  }, []);
  const handlePublish = useCallback(() => {
    if (mode === 'create') create(params);
    else update(params);
  }, [create, mode, params, update]);
  const handleSaveAsDraft = useCallback(() => {
    if (!artistId) return;

    const {
      photos,
      delete_photo_ids,
      release_at_date,
      release_at_time,
      close_at_date,
      close_at_time,
      ...data
    } = params;

    const release_at = release_at_date
      ? moment(`${release_at_date} ${release_at_time || '00:00'}`).format(
          DATE_TIME_FORMAT,
        )
      : '';

    const close_at = close_at_date
      ? moment(`${close_at_date} ${close_at_time || '00:00'}`).format(
          DATE_TIME_FORMAT,
        )
      : '';

    const createPhotoParams: CreatePhotoParams = {
      ...data,
      artistId,
      thumb: null,
      release_at,
      close_at,
      photos: photos.map(photo => photo.file),
      is_release: 0,
    };

    const updatePhotoParams: UpdatePhotoParams = {
      ...createPhotoParams,
      contentId: Number(contentId),
      delete_photo_ids,
    };

    if (mode === 'create') dispatch(createPhotoEvent.start(createPhotoParams));
    else dispatch(updatePhotoEvent.start(updatePhotoParams));
  }, [contentId, dispatch, artistId, mode, params]);
  const handleDelete = useCallback(() => {
    if (!artistId) return;

    const res = window.confirm('本当に削除してもよろしいですか？');

    if (!res) return;

    dispatch(
      deletePhotoEvent.start({
        type: 2,
        artistId,
        contentId: Number(contentId),
      }),
    );
  }, [contentId, dispatch, artistId]);

  const handleBack = useCallback(() => {
    const res = window.confirm('編集した内容を破棄しますか？');
    if (!res) return;

    history.goBack();
  }, [history]);

  const handleChangeNewCategory = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setNewCategory(e.currentTarget.value);
    },
    [],
  );

  const handleAddCategory = useCallback(() => {
    if (!artistId) return;

    dispatch(
      addFCCategoryEvent.start({
        artistId,
        name: newCategory,
      }),
    );
  }, [dispatch, artistId, newCategory]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!artistId) {
      history.replace(PATH.HOME);

      return;
    }

    dispatch(getReady(artistId, Number(contentId)));
  }, [artistId, authReady, contentId, dispatch, history]);

  useEffect(() => {
    if (!current.detail.id) return;

    if (
      !(
        (current.detail.user_id === me.id && details.user_type === 2) ||
        details.user_type === 3
      )
    ) {
      history.replace(PATH.Forbidden);

      return;
    }

    const {
      detail: { release_at, close_at, subject, open_area },
      categories,
    } = current;

    const [release_at_date, release_at_time] = release_at
      ? moment(release_at)
          .format(DATE_TIME_FORMAT)
          .split(' ')
      : ['', ''];
    const [close_at_date, close_at_time] = close_at
      ? moment(close_at)
          .format(DATE_TIME_FORMAT)
          .split(' ')
      : ['', ''];

    const params: InputPhotoParams = {
      subject: subject ?? '',
      photos: [],
      delete_photo_ids: [],
      categories: categories.map(c => c.value),
      open_area: open_area || '',
      release_at_date,
      release_at_time,
      close_at_date,
      close_at_time,
    };

    setParams(params);
  }, [current, details.user_type, history, me.id]);

  useEffect(() => {
    if (!addCategorySuccess) return;

    setNewCategory('');
    setParams(params => ({
      ...params,
      categories: [...params.categories, categories[categories.length - 1].id],
    }));
  }, [addCategorySuccess, categories]);

  useEffect(() => {
    if (!artistId) return;

    if (registerPhotoSuccess) {
      history.replace(
        PATH.getAdminPath(artistId, 'CONTENTS'),
        contentPhotoResult.is_release === 1
          ? {
              ADMIN_CONTENTS: {
                mode,
                title: contentPhotoResult.subject,
                artistId,
                contentId: contentPhotoResult.id,
              },
            }
          : undefined,
      );
    }
  }, [
    artistId,
    contentPhotoResult.id,
    contentPhotoResult.is_release,
    contentPhotoResult.subject,
    history,
    mode,
    registerPhotoSuccess,
  ]);

  useEffect(() => {
    if (!artistId) return;

    if (deletePhotoSuccess) {
      history.replace(PATH.getAdminPath(artistId, 'CONTENTS'));
    }

    if (deletePhotoError) {
      window.alert('画像の削除に失敗しました');
    }
  }, [deletePhotoError, deletePhotoSuccess, artistId, history]);

  useEffect(() => {
    if (JSON.stringify(registerPhotoErrors) !== JSON.stringify({})) {
      setShowConfirm(false);
      window.scrollTo(0, 0);
      window.alert('入力項目を確認してください');
    }
  }, [registerPhotoErrors, handleModify]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            {title} | {details.fanclub.name} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      {showConfirm ? (
        <AdminPhotoRegisterConfirm
          mode={mode}
          title={title}
          params={params}
          currentPhotoList={currentPhotoList}
          fanclubName={details.fanclub.site_name}
          categoryList={selectedCategoryList}
          openAreaList={open_area}
          onPublish={handlePublish}
          onModify={handleModify}
          onBack={handleBack}
        />
      ) : (
        <AdminPhotoRegister
          mode={mode}
          type={2}
          title={title}
          params={params}
          fanclubName={details.fanclub.site_name}
          categoryList={categoryList}
          currentPhotoList={currentPhotoList}
          openAreaList={openAreaField}
          isViewTab={isViewTab}
          onChangeTab={handleChangeTab}
          onChange={handleChange}
          onChangeCategory={handleChangeCategory}
          onAddNewPhoto={handleAddNewPhoto}
          onDeleteCurrentPhoto={handleDeleteCurrentPhoto}
          onDeleteNewPhoto={handleDeleteNewPhoto}
          canClickConfirm={isRequiredEntered}
          onConfirm={handleConfirm}
          onSaveAsDraft={handleSaveAsDraft}
          onDelete={handleDelete}
          onBack={handleBack}
          errors={validationErrors}
          newCategory={newCategory}
          onChangeNewCategory={handleChangeNewCategory}
          onAddCategory={handleAddCategory}
          categoryError={addCategoryErrors?.name?.[0]}
        />
      )}
    </>
  );
};

export default AdminPhotoRegisterContainer;
