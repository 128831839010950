import React, { FC, useRef, useEffect } from 'react';
import ClassNames from 'classnames';
import * as styles from './InputFileThumbnail.module.scss';

interface Props {
  value?: string;
  title?: string;
  type?: string;
  inputNameHero?: string;
  cssClass?: string;

  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
}

const Input: FC<Props> = ({
  value = '',
  title = 'アイキャッチ画像',
  type = 'Primary',
  inputNameHero = '',
  cssClass = '',

  onClick = () => undefined,
  onFocus = () => undefined,
  onBlur = () => undefined,
  onChange = () => undefined,
}) => {
  const classNames = ClassNames(styles.InputBase, cssClass, {
    [styles.InputPrimary]: type === 'Primary', // Primary
  });

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('resize', () => {
        if (ref.current) ref.current.style.height = ref.current?.style.width;
      });
    }
  }, [ref]);

  return (
    <>
      <div className={classNames}>
        <div
          ref={ref}
          className={styles.InputFileThumbnail}
          style={
            {
              // background:
              //   "url('https://dummyimage.com/600x400/000/fff') no-repeat center center",
              // backgroundSize: 'cover',
            }
          }
        >
          <div className={styles.InputFileTitle}>
            <span>{title}</span>
          </div>
          <input
            type="file"
            accept="image/*"
            multiple
            name={inputNameHero}
            onChange={onChange}
          />
        </div>
      </div>
    </>
  );
};

export default Input;
