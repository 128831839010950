import { Reducer } from 'redux';
import { Actions } from '../actions/homeActions';
import * as ActionType from '../actions/homeConstants';
import { Fanclub, FanclubWithUsers } from '../interfaces/fanclubInterFace';
import {
  ContentListWithPager,
  ContentListWithPagerInitialValue,
} from '../interfaces/contentInterFace';

type State = {
  pickups: FanclubWithUsers[];
  recommends: Fanclub[];
  contents: ContentListWithPager;
  ready: boolean;
  loading: boolean;
  error: boolean;
};

export const initialState: State = {
  pickups: [],
  recommends: [],
  contents: ContentListWithPagerInitialValue,
  ready: false,
  loading: false,
  error: false,
};

const homeReducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get pickup fanclub list
    case ActionType.GET_PICKUP_FANCLUB_LIST_START: {
      return {
        ...state,
        error: false,
      };
    }
    case ActionType.GET_PICKUP_FANCLUB_LIST_SUCCEED: {
      return {
        ...state,
        pickups: action.payload.result.data,
      };
    }
    case ActionType.GET_PICKUP_FANCLUB_LIST_FAIL: {
      return {
        ...state,
        error: true,
      };
    }

    // get recommend fanclub list
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_START: {
      return {
        ...state,
        error: false,
      };
    }
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_SUCCEED: {
      return {
        ...state,
        recommends: action.payload.result.data,
      };
    }
    case ActionType.GET_RECOMMEND_FANCLUB_LIST_FAIL: {
      return {
        ...state,
        error: true,
      };
    }

    // get content list with pager
    case ActionType.GET_CONTENT_LIST_WITH_PAGER_START: {
      const {
        params: { reset },
        withLoading,
      } = action.payload;

      return {
        ...state,
        loading: withLoading,
        error: false,
        contents: reset ? initialState.contents : state.contents,
      };
    }
    case ActionType.GET_CONTENT_LIST_WITH_PAGER_SUCCEED: {
      const { reset } = action.payload.params;
      const { data, ...pager } = action.payload.result;
      const list = reset ? data : [...state.contents.list, ...data];

      return {
        ...state,
        contents: {
          ...state.contents,
          list,
          pager,
        },
        loading: false,
      };
    }
    case ActionType.GET_CONTENT_LIST_WITH_PAGER_FAIL: {
      return {
        ...state,
        contents: initialState.contents,
        loading: false,
        error: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default homeReducer;
