import React, { FC } from 'react';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import * as styles from './withdrawPaidFC.module.scss';

interface Props {
  onLinkToTop?: () => void;
}

const Component: FC<Props> = ({ onLinkToTop = () => undefined }) => {
  return (
    <div className={styles.completeContainer}>
      <p className={styles.completeTitle}>退会完了</p>
      <p className={styles.completeRead}>またのご利用お待ちしております。</p>

      <ButtonRoundedCorners
        text="トップへ戻る"
        type="Primary"
        size="Primary"
        onClick={onLinkToTop}
        cssClass={styles.button}
      />
    </div>
  );
};

export default Component;
