import { AllState } from '../store';
import { SelectField } from '../interfaces/utilInterFace';
import { SelectListObject } from '../interfaces/formPartsInterFace';

const getHeaderMode = (state: AllState) => {
  return state.util.headerMode;
};

const getSelectFields = (state: AllState) => {
  return state.util.selectFields;
};

const getGenreFields = (state: AllState) => {
  const fields: SelectField[] = [];

  let tmpMainGenreGroups: SelectField = [];
  state.util.selectFields.genres.forEach((genre, i, genres) => {
    const field: SelectListObject = {
      label: genre.name,
      value: genre.id,
    };

    if (i === 0) {
      tmpMainGenreGroups.push(field);

      return;
    }

    const prev = genres[i - 1];

    if (prev.main_genre_id !== genre.main_genre_id) {
      fields.push(tmpMainGenreGroups);
      tmpMainGenreGroups = [];
    }

    tmpMainGenreGroups.push(field);

    if (i === genres.length - 1) fields.push(tmpMainGenreGroups);
  });

  return fields;
};

const getIsLoading = (state: AllState) => {
  return state.auth.loading;
};

export default {
  getHeaderMode,
  getSelectFields,
  getGenreFields,
  getIsLoading,
};
