/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useMemo } from 'react';
import ClassNames from 'classnames';
import Linkify from 'react-linkify';
import moment from 'moment';

import McsIconLabelRoundTertiary from '../../molecules/iconWithLabel/McsIconLabelRoundTertiary';
import ButtonSquare from '../../atoms/buttonBasic/ButtonSquare';

import {
  Comment,
  CommentInitialValue,
} from '../../../../interfaces/commentInterFace';

import * as styles from './OrgFCListUnitComment.module.scss';

type PartialComment = Partial<Comment>;

interface Props {
  cssClass?: string;
  comment?: PartialComment;
  isAdmin?: boolean;
  isOwn?: boolean;
  onClickUser?: () => void;
  onClickDelete?: () => void;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  comment = CommentInitialValue,
  isAdmin = false,
  isOwn = false,
  onClickUser = () => undefined,
  onClickDelete = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgFCListUnitBase, cssClass);

  const datetime = useMemo(
    () => moment(comment.created_at).format('YYYY.MM.DD HH:mm'),
    [comment.created_at],
  );

  return (
    <li className={classNames} key={comment.id}>
      <div className={styles.OrgFCListUnitUser}>
        <McsIconLabelRoundTertiary
          src={comment.user?.icon_image || ''}
          mainText={comment.user?.nickname || '退会済みユーザー'}
          subText={`@${comment.user?.name_id || ''}`}
          cssClass={styles.OrgFCListUnitUser}
          onClick={onClickUser}
        />
        <div className={styles.OrgFCListUnitTime}>{datetime}</div>
      </div>
      <div className={styles.OrgFCListUnitComment}>
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a target="blank" href={decoratedHref} key={key}>
              {decoratedText}
            </a>
          )}
        >
          {comment.comment}
        </Linkify>
      </div>
      {(isOwn || isAdmin) && (
        <ButtonSquare
          type="Quaternary"
          size="Tertiary"
          text="コメントを削除する"
          onClick={onClickDelete}
          cssClass={styles.OrgFCListUnitDelete}
        />
      )}
    </li>
  );
};

export default OrgListUnit;
