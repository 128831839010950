import * as ActionType from './chatConstants';
import { FanclubDetails } from '../interfaces/fanclubInterFace';
import {
  getChatResponse,
  submitChatParam,
  deleteChatParam,
} from '../interfaces/chatInterFace';

export const getReady = (artistId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: artistId,
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

// ファンクラブ詳細取得
export const getFanclubDetailsEvent = {
  start: (artistId: string) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_START,
      payload: artistId,
    } as const),

  succeed: (result: FanclubDetails) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_SUCCEED,
      payload: result,
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_FAIL,
    } as const),
};

// チャット一覧取得
export const getChatEvent = {
  start: (artistId: string) =>
    ({
      type: ActionType.GET_CHAT_START,
      payload: artistId,
    } as const),

  succeed: (result: getChatResponse) =>
    ({
      type: ActionType.GET_CHAT_SUCCEED,
      payload: result,
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_CHAT_FAIL,
    } as const),
};

// チャット送信
export const submitChatEvent = {
  start: (params: submitChatParam) =>
    ({
      type: ActionType.SUBMIT_CHAT_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.SUBMIT_CHAT_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.SUBMIT_CHAT_FAIL,
    } as const),
};

// チャット既読処理
export const chatReadEvent = {
  start: (artistId: string) =>
    ({
      type: ActionType.CHAT_READ_START,
      payload: artistId,
    } as const),

  succeed: () =>
    ({
      type: ActionType.CHAT_READ_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.CHAT_READ_FAIL,
    } as const),
};

// チャット削除処理
export const chatDeleteEvent = {
  start: (Param: deleteChatParam) =>
    ({
      type: ActionType.CHAT_DELETE_START,
      payload: Param,
    } as const),

  succeed: () =>
    ({
      type: ActionType.CHAT_DELETE_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.CHAT_DELETE_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // ファンクラブ詳細取得
  | ReturnType<typeof getFanclubDetailsEvent.start>
  | ReturnType<typeof getFanclubDetailsEvent.succeed>
  | ReturnType<typeof getFanclubDetailsEvent.fail>
  // チャット一覧取得
  | ReturnType<typeof getChatEvent.start>
  | ReturnType<typeof getChatEvent.succeed>
  | ReturnType<typeof getChatEvent.fail>
  // チャット送信
  | ReturnType<typeof submitChatEvent.start>
  | ReturnType<typeof submitChatEvent.succeed>
  | ReturnType<typeof submitChatEvent.fail>
  // チャット既読処理
  | ReturnType<typeof chatReadEvent.start>
  | ReturnType<typeof chatReadEvent.succeed>
  | ReturnType<typeof chatReadEvent.fail>
  // チャット削除処理
  | ReturnType<typeof chatDeleteEvent.start>
  | ReturnType<typeof chatDeleteEvent.succeed>
  | ReturnType<typeof chatDeleteEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
