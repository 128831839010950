import { call, put, fork, takeLatest, takeLeading } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/editFanclubConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getFanclubDetailsEvent,
  updateFanclubEvent,
} from '../actions/editFanclubActions';

import { Status, ErrorResponse } from '../interfaces/commonInterFace';
import {
  GetFanclubDetailsResponse,
  UpdateFanclubResponse,
  UpdateFanclubErrorResponse,
} from '../interfaces/fanclubInterFace';

// get fanclub details and format for edit
const getFanclubDetailsHandler = api.getGetFactory(true);
function* runGetFanclubDetails(
  handler: typeof getFanclubDetailsHandler,
  action: ReturnType<typeof getFanclubDetailsEvent.start>,
) {
  const { artistId } = action.payload;
  try {
    const ReturnData: GetFanclubDetailsResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(getFanclubDetailsEvent.fail());

      return;
    }

    yield put(getFanclubDetailsEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFanclubDetailsEvent.fail());
  }
}
export function* getFanclubDetails(handler: typeof getFanclubDetailsHandler) {
  yield takeLatest(
    ActionType.GET_FANCLUB_DETAILS_START,
    runGetFanclubDetails,
    handler,
  );
}

// update fanclub
const updateFanclubHandler = api.getPatchFactory(true);
function* runUpdateFanclub(
  handler: typeof updateFanclubHandler,
  action: ReturnType<typeof updateFanclubEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: UpdateFanclubResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(updateFanclubEvent.fail({}));

      return;
    }

    yield put(updateFanclubEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as UpdateFanclubErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(updateFanclubEvent.fail(res.errors));

      return;
    }

    yield put(updateFanclubEvent.fail({}));
  }
}
export function* updateFanclub(handler: typeof updateFanclubHandler) {
  yield takeLeading(ActionType.UPDATE_FANCLUB_START, runUpdateFanclub, handler);
}

export const editFanclubSagas = [
  fork(getFanclubDetails, getFanclubDetailsHandler),
  fork(updateFanclub, updateFanclubHandler),
];
