/* eslint-disable no-multi-assign */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useRef } from 'react';
import ClassNames from 'classnames';

import ThumbnailNoImage from '../../assets/images/thumbnail-noimage.png';
import ButtonMedia from '../../atoms/buttonUtile/ButtonMedia';

import * as styles from './OrgContentMovie.module.scss';

interface Props {
  cssClass?: string;
  thumbnail?: string;
  movie?: string;
}

const OrgContentMovie: FC<Props> = ({
  cssClass = '',
  thumbnail = '',
  movie = '',
}) => {
  const ref = useRef<any>(null);
  const classNames = ClassNames(styles.OrgContentMovieBase, cssClass);

  const handleClick = () => {
    if (!ref.current) return;

    ref.current.webkitEnterFullscreen();
    ref.current.play();
  };

  return (
    <div className={classNames}>
      <div className={styles.OrgContentMovieThumbnailCover} />
      <img
        src={thumbnail}
        alt=""
        className={styles.OrgContentMovieThumbnail}
        onError={e => {
          e.currentTarget.src = ThumbnailNoImage;
        }}
      />
      <div className={styles.OrgContentMovieButton}>
        <ButtonMedia type="PlayVideo" onClick={handleClick} />
      </div>
      <video
        ref={ref}
        src={movie}
        preload="none"
        controlsList="nodownload"
        className={styles.OrgContentMovieVideo}
      />
    </div>
  );
};

export default OrgContentMovie;
