/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import KrapLogo from '../../atoms/iconParts/IconKrapLogo';
import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';
import * as styles from './McsFooterPR.module.scss';

interface Props {
  leftButtonText?: string;
  onClickLeftButton?: () => void;
  rightButtonText?: string;
  onClickRightButton?: () => void;
  cssClass?: string;
}

/**
 * figma上は2つのボタンが表示されているが、
 * 現行仕様では右側のボタンのみを中央表示している
 */
const McsFooterPR: FC<Props> = ({
  leftButtonText = 'Left',
  onClickLeftButton = () => undefined,
  rightButtonText = 'Right',
  onClickRightButton = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.McsFooterPRBase, cssClass);

  return (
    <div className={classNames}>
      <KrapLogo cssClass={styles.McsFooterPRLogo} />
      <p className={styles.McsFooterPRRead}>
        アーティストとファン、ファンとファンをつなぐ
        <br />
        次世代のファンコミュニティです。
      </p>
      <ul className={styles.McsFooterPRButtons}>
        {/* <li>
          <ButtonRoundedCorners
            text={leftButtonText}
            onClick={onClickLeftButton}
            type="Secondary"
          />
        </li> */}
        <li>
          <ButtonRoundedCorners
            text={rightButtonText}
            onClick={onClickRightButton}
          />
        </li>
      </ul>
    </div>
  );
};

export default McsFooterPR;
