import React, { FC } from 'react';
import ClassNames from 'classnames';
import TitleH2 from '../common/atoms/title/TitleH2';
import ButtonSquare from '../common/atoms/buttonBasic/ButtonSquare';
import McsInputPrimary from '../common/molecules/formParts/McsInputPrimary';
import * as styles from './sendEmail.module.scss';

interface Props {
  valueEmail?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  onIssueEvent?: () => void;
  onBackEvent?: () => void;
  error?: string;
}

const Component: FC<Props> = ({
  valueEmail = '',
  onChange = () => undefined,
  onIssueEvent = () => undefined,
  onBackEvent = () => undefined,
  error = '',
}) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      <TitleH2 text="パスワード再発行" cssClass={styles.title} />
      <div className={styles.read}>
        <p>
          KRAPにご登録のメールアドレスを入力し、「パスワード再発行」ボタンをタップしてください。
          <br />
          メールをお送りしますので、記載されたURLからパスワード再発行手続きを完了して下さい。
        </p>
      </div>

      <McsInputPrimary
        value={valueEmail}
        inputType="email"
        inputName="email"
        placeholder="登録メールアドレスを入力"
        labelTitle="登録メールアドレス"
        onChange={onChange}
        isError={!!error}
        errorText={error}
      />

      <div className={styles.note}>
        <p>
          PCやスマートフォンで迷惑メール設定をされているお客様は、[info@krap.com]からのメールが受信できるように設定をご確認・変更下さい。
        </p>
      </div>

      <div className={styles.buttonWrap}>
        <ButtonSquare
          text="パスワード再発行"
          type="Primary"
          size="Primary"
          onClick={onIssueEvent}
          cssClass={styles.button}
        />
        <ButtonSquare
          text="戻る"
          type="Quaternary"
          size="Primary"
          onClick={onBackEvent}
        />
      </div>
    </div>
  );
};

export default Component;
