/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useState, useCallback, useEffect } from 'react';
import moment from 'moment';

import { chat } from '../../interfaces/chatInterFace';
import MscChatListParts from '../common/molecules/iconWithLabel/MscChatListParts';
import McsInputComment from '../common/molecules/formParts/McsInputComment';
import MessageDisplay from '../common/atoms/commonParts/MessageDisplay';

import * as styles from './chat.module.scss';

type Props = {
  userId?: number;
  chats?: chat[];
  unreadCount?: number;
  alreadyReadId?: number;
  membersId?: number[];
  chatInput?: string;
  admin?: boolean;
  deleteList?: number[];
  handleChangeInput?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
  handleSubmitInput?: () => void;
  handleClickUser?: (userId: string) => void;
  handleClickDelete?: (ChatId: number) => void;
};

const Component: FC<Props> = ({
  userId = 0,
  chats = [],
  unreadCount = 0,
  alreadyReadId = 0,
  membersId = [],
  chatInput = '',
  admin = false,
  deleteList = [],
  handleChangeInput = () => undefined,
  handleSubmitInput = () => undefined,
  handleClickUser = () => undefined,
  handleClickDelete = () => undefined,
}) => {
  const [padding, setPadding] = useState(36);
  let previousUser = 0;
  // 前のユーザーID登録
  const previousUserRegistration = (id: number) => {
    previousUser = id;
  };

  // メンバーかどうかチェック
  const memberCheck = (id: number) => {
    return membersId.includes(id);
  };

  // 今日以外の日付の場合、時間のみを表示する
  const timeMolding = (time: string) => {
    const date = moment(time);
    const now = moment().endOf('days');
    if (now.diff(date, 'days') === 0) {
      return date.format('HH:mm');
    }

    return date.format('YYYY年M月D日 HH:mm');
  };

  // foam入力時に高さ調整
  const handleChangeHeight = useCallback((height: number) => {
    const param = height > 152 ? 152 : height;
    setPadding(param);
  }, []);

  useEffect(() => {
    const a = document.documentElement;
    const y = a.scrollHeight - a.clientHeight;
    const check = y - window.scrollY < a.clientHeight * 0.6;
    if (check) {
      window.scrollTo(0, window.scrollY + padding);
    }
  }, [padding]);

  return (
    <div className={styles.chatWrap}>
      {/* チャット一覧 */}
      <div
        style={{
          paddingBottom: padding,
        }}
      >
        {chats.map(c => (
          <React.Fragment key={c.id}>
            {!deleteList.includes(c.id) && (
              <>
                {userId === c.user?.id ? (
                  <MscChatListParts
                    type="myself"
                    content={c.chat}
                    cssClass={styles.myself}
                    tTimeDisplay
                    time={timeMolding(String(c.created_at))}
                    onClickDelete={() => handleClickDelete(c.id)}
                    onClickUserIcon={() =>
                      handleClickUser(c.user?.name_id ?? '')
                    }
                  />
                ) : previousUser === c.user?.id ? (
                  <MscChatListParts
                    type="other"
                    content={c.chat}
                    withIcon={false}
                    cssClass={styles.noIcon}
                    tTimeDisplay
                    admin={admin}
                    time={timeMolding(String(c.created_at))}
                    onClickDelete={() => handleClickDelete(c.id)}
                    onClickUserIcon={() =>
                      handleClickUser(c.user?.name_id ?? '')
                    }
                  />
                ) : (
                  <MscChatListParts
                    type="other"
                    member={memberCheck(c.user?.id ?? 0)}
                    src={String(c.user?.icon_image ?? '')}
                    content={c.chat}
                    name={c.user?.nickname ?? '退会済みユーザー'}
                    cssClass={styles.withIcon}
                    tTimeDisplay
                    admin={admin}
                    time={timeMolding(String(c.created_at))}
                    onClickDelete={() => handleClickDelete(c.id)}
                    onClickUserIcon={() =>
                      handleClickUser(c.user?.name_id ?? '')
                    }
                  />
                )}
                {previousUserRegistration(c.user?.id ?? 0)}
                {alreadyReadId === c.id && unreadCount > 0 && (
                  <>
                    <MessageDisplay
                      top
                      message="ここから未読メッセージ"
                      cssClass={styles.unreadMessage}
                    />
                    {previousUserRegistration(0)}
                  </>
                )}
              </>
            )}
          </React.Fragment>
        ))}
      </div>

      {/* チャット入力 */}
      <McsInputComment
        value={chatInput}
        cssClass={styles.chatInput}
        placeholder="みんなで話そう！"
        onChange={handleChangeInput}
        onSendEvent={handleSubmitInput}
        onChangeHeight={handleChangeHeight}
      />
    </div>
  );
};

export default Component;
