import * as ActionType from './searchConstants';

import {
  GetFindFanclubListParams,
  GetFindFanclubListResult,
  FreejoinFanclubParams,
  WithdrawFanclubParams,
} from '../interfaces/fanclubInterFace';

export const getFindFanclubListEvent = {
  start: (params: GetFindFanclubListParams) =>
    ({
      type: ActionType.GET_FIND_FANCLUB_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetFindFanclubListResult) =>
    ({
      type: ActionType.GET_FIND_FANCLUB_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FIND_FANCLUB_LIST_FAIL,
    } as const),
};

export const freejoinFanclubEvent = {
  start: (params: FreejoinFanclubParams) =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_FAIL,
    } as const),
};

export const withdrawFanclubEvent = {
  start: (params: WithdrawFanclubParams) =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // get all fanclub list
  | ReturnType<typeof getFindFanclubListEvent.start>
  | ReturnType<typeof getFindFanclubListEvent.succeed>
  | ReturnType<typeof getFindFanclubListEvent.fail>
  // freejoin fanclub
  | ReturnType<typeof freejoinFanclubEvent.start>
  | ReturnType<typeof freejoinFanclubEvent.succeed>
  | ReturnType<typeof freejoinFanclubEvent.fail>
  // withdraw fanclub
  | ReturnType<typeof withdrawFanclubEvent.start>
  | ReturnType<typeof withdrawFanclubEvent.succeed>
  | ReturnType<typeof withdrawFanclubEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
