/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { FC, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  unblockUserEvent,
  cleanup,
} from '../../actions/adminBlocksActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import AdminBlocks from '../../components/admin/adminBlocks';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';

const AdminBlocksContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const {
    ready: authReady,
    profile: {
      fanclubs: { manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const { ready, blocks, loading, success } = useSelector(
    selector.adminBlocks.getAdminBlocksState,
  );
  const fanclub = useMemo(
    () => manages.find(manage => manage.group_id === artistId),
    [artistId, manages],
  );
  const isManaged = useMemo(() => !!fanclub, [fanclub]);

  const handleClickUser = useCallback(
    (userId: string) => {
      if (!userId) return;

      history.push(PATH.getUserPath(userId));
    },
    [history],
  );
  const handleUnblockUser = useCallback(
    (userId: string) => {
      if (!artistId) return;
      dispatch(
        unblockUserEvent.start({
          artistId,
          unblock_user: userId,
        }),
      );
    },
    [dispatch, artistId],
  );
  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (artistId && isManaged) {
      dispatch(getReady(artistId));
    } else {
      history.replace(PATH.HOME);
    }
  }, [authReady, dispatch, artistId, history, isManaged]);

  useEffect(() => {
    if (!success || !artistId) return;

    history.replace(PATH.getArtistPath(artistId));
  }, [history, artistId, success]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            ブロック管理 | {fanclub?.name} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      <AdminBlocks
        fanclubName={fanclub?.site_name}
        blockList={blocks}
        isAdmin={fanclub?.pivot?.type === 3}
        handleClickUser={handleClickUser}
        handleUnblockUser={handleUnblockUser}
        handleBack={handleBack}
      />
    </>
  );
};

export default AdminBlocksContainer;
