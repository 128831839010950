import * as ActionType from './utilConstants';
import { GetSelectFieldsResult, HeaderMode } from '../interfaces/utilInterFace';

export const changeHeaderMode = (mode: HeaderMode) =>
  ({
    type: ActionType.CHANGE_HEADER_MODE,
    payload: mode,
  } as const);

export const getSelectFieldsEvent = {
  start: () =>
    ({
      type: ActionType.GET_SELECT_FIELDS_START,
    } as const),

  succeed: (result: GetSelectFieldsResult) =>
    ({
      type: ActionType.GET_SELECT_FIELDS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_SELECT_FIELDS_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // change header mode
  | ReturnType<typeof changeHeaderMode>
  // get select fields
  | ReturnType<typeof getSelectFieldsEvent.start>
  | ReturnType<typeof getSelectFieldsEvent.succeed>
  | ReturnType<typeof getSelectFieldsEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
