/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { FC, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  getCommentListEvent,
  cleanup,
} from '../../actions/adminCommentsActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminComments from '../../components/admin/adminComments';

const AdminContentsContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const {
    ready: authReady,
    profile: {
      fanclubs: { manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const { ready, comments, pager } = useSelector(
    selector.adminComments.getAdminCommentsState,
  );
  const isLastPage = useSelector(selector.adminComments.getIsLastPage);
  const fanclub = useMemo(
    () => manages.find(fanclub => fanclub.group_id === artistId),
    [artistId, manages],
  );

  const handleClickComment = useCallback(
    (artistId: string, contentId: number) => {
      if (!artistId || !contentId) return;

      history.push(PATH.getCommentPath(artistId, contentId));
    },
    [history],
  );
  const handleReadMore = useCallback(() => {
    if (!artistId) return;

    dispatch(
      getCommentListEvent.start({
        group_id: artistId,
        page: pager.current_page + 1,
      }),
    );
  }, [dispatch, artistId, pager.current_page]);
  const handleBack = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'MENU'));
  }, [artistId, history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);
  useEffect(() => {
    if (!authReady) return;

    if (!artistId || !fanclub) {
      history.replace(PATH.HOME);

      return;
    }

    dispatch(getReady({ group_id: artistId }));
  }, [artistId, authReady, dispatch, fanclub, history]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            コメント確認 | {fanclub?.name ?? ''} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      <OrgLoader isLoaded={authReady && ready} />

      <AdminComments
        fanclubName={fanclub?.site_name}
        artistId={fanclub?.group_id}
        commentList={comments}
        onClickComment={handleClickComment}
        isViewReadMoreButton={!isLastPage}
        onReadMore={handleReadMore}
        onBack={handleBack}
      />
    </>
  );
};

export default AdminContentsContainer;
