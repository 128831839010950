/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import { logInEvent, cleanup, setAuthError } from '../../actions/authActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { LogInParams } from '../../interfaces/authInterFace';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import Login from '../../components/reissuePassword/login';

const ReissuePasswordLoginContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [params, setParams] = useState<LogInParams>({
    email: '',
    password: '',
    saveAuthInCookie: true,
  });
  const { loading, error, success } = useSelector(selector.auth.getAuthState);

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    setParams(params => ({ ...params, [name]: value }));
  }, []);
  const handleChangeSaveAuthInCookie = useCallback(() => {
    setParams(params => ({
      ...params,
      saveAuthInCookie: !params.saveAuthInCookie,
    }));
  }, []);
  const handleLogin = useCallback(() => {
    dispatch(logInEvent.start(params));
  }, [dispatch, params]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      window.alert('ログインに失敗しました');

      dispatch(setAuthError(false));
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (success) history.replace(PATH.HOME);
  }, [history, success]);

  return (
    <>
      <Helmet>
        <title>パスワード再発行完了 | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}

      <Login
        email={params.email}
        password={params.password}
        isCheck={params.saveAuthInCookie}
        onChange={handleChange}
        onSaveCheckEvent={handleChangeSaveAuthInCookie}
        onLoginEvent={handleLogin}
      />
    </>
  );
};

export default ReissuePasswordLoginContainer;
