import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './InputFileBox.module.scss';

interface Props {
  preview?: string;
  previewHero?: string;
  type?: string;
  inputName?: string;
  inputNameHero?: string;
  cssClass?: string;

  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;

  isDisabled?: boolean;

  isError?: boolean;
  errorTextHero?: string;
  errorText?: string;
}

const Input: FC<Props> = ({
  preview = '',
  previewHero = '',
  type = 'Primary',
  inputName = '',
  inputNameHero = '',
  cssClass = '',

  onClick = () => undefined,
  onFocus = () => undefined,
  onBlur = () => undefined,
  onChange = () => undefined,

  isDisabled = false,

  isError = false,
  errorTextHero = 'Error',
  errorText = 'Error',
}) => {
  const classNames = ClassNames(styles.InputBase, cssClass, {
    [styles.InputPrimary]: type === 'Primary', // Primary
    [styles.InputError]: isError, // isError
  });

  return (
    <>
      <div className={classNames}>
        <div
          className={styles.InputFileThumbnail}
          style={
            previewHero
              ? {
                  background: `url(${previewHero}) #ffffff no-repeat center center / cover`,
                }
              : {}
          }
        >
          <span />
          <input
            type="file"
            accept="image/*"
            name={inputNameHero}
            onChange={onChange}
            disabled={isDisabled}
          />
        </div>

        <label
          htmlFor={inputName}
          style={
            preview
              ? {
                  background: `url(${preview}) #ffffff no-repeat center center / cover`,
                  backgroundSize: 'cover',
                }
              : {}
          }
        >
          <input
            type="file"
            accept="image/*"
            name={inputName}
            onChange={onChange}
            disabled={isDisabled}
          />
          <span />
        </label>
      </div>
      {isError && <p className={styles.InputErrorText}>{errorTextHero}</p>}
      {isError && <p className={styles.InputErrorText}>{errorText}</p>}
    </>
  );
};

export default Input;
