import * as ActionType from './newsConstants';
import {
  GetNotificationListParams,
  GetNotificationListResult,
  GetNotificationAndMarkAsReadParams,
} from '../interfaces/newsInterFace';

export const getReady = (params: GetNotificationListParams) =>
  ({
    type: ActionType.GET_READY,
    payload: params,
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getNotificationListEvent = {
  start: (params: GetNotificationListParams) =>
    ({
      type: ActionType.GET_NOTIFICATION_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetNotificationListResult) =>
    ({
      type: ActionType.GET_NOTIFICATION_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_NOTIFICATION_LIST_FAIL,
    } as const),
};

export const markAsReadEvent = {
  start: (params: GetNotificationAndMarkAsReadParams) =>
    ({
      type: ActionType.MARK_AS_READ_START,
      payload: params,
    } as const),

  succeed: (params: GetNotificationAndMarkAsReadParams) =>
    ({
      type: ActionType.MARK_AS_READ_SUCCEED,
      payload: { params },
    } as const),

  fail: () =>
    ({
      type: ActionType.MARK_AS_READ_FAIL,
    } as const),
};

export const markAllAsReadEvent = {
  start: () =>
    ({
      type: ActionType.MARK_ALL_AS_READ_START,
    } as const),

  succeed: () =>
    ({
      type: ActionType.MARK_ALL_AS_READ_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.MARK_ALL_AS_READ_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get notification list
  | ReturnType<typeof getNotificationListEvent.start>
  | ReturnType<typeof getNotificationListEvent.succeed>
  | ReturnType<typeof getNotificationListEvent.fail>
  // mark as read
  | ReturnType<typeof markAsReadEvent.start>
  | ReturnType<typeof markAsReadEvent.succeed>
  | ReturnType<typeof markAsReadEvent.fail>
  // mark as all read
  | ReturnType<typeof markAllAsReadEvent.start>
  | ReturnType<typeof markAllAsReadEvent.succeed>
  | ReturnType<typeof markAllAsReadEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
