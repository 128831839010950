/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, join, takeLatest } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';
import { checkAndSetStatusError } from './authSagas';

import * as ActionType from '../actions/likesConstants';
import {
  getReady as getReadyStart,
  gotReady,
  getContentDetailsEvent,
  getLikedUsers,
} from '../actions/likesActions';
import { ErrorResponse } from '../interfaces/commonInterFace';
import { GetContentDetailsResponse } from '../interfaces/contentInterFace';
import { getLikedUsersResponse } from '../interfaces/likesInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const contentId = action.payload;

  const getContentDetailsTask = yield fork(
    runGetContentDetails,
    getContentDetailsHandler,
    getContentDetailsEvent.start(contentId),
  );
  yield join(getContentDetailsTask);

  const getLikedUsersTask = yield fork(
    runGetLikedUsers,
    getLikedUsersHandler,
    getLikedUsers.start({
      contentId,
      limit: 20,
    }),
  );
  yield join(getLikedUsersTask);

  yield put(gotReady());
}

// get content details
const getContentDetailsHandler = api.getGetFactory(true);
function* runGetContentDetails(
  handler: typeof getContentDetailsHandler,
  action: ReturnType<typeof getContentDetailsEvent.start>,
) {
  const contentId = action.payload;
  try {
    const ReturnData: GetContentDetailsResponse = yield call(
      handler,
      {},
      `/contents/${contentId}`,
    );

    if (!ReturnData) {
      yield put(getContentDetailsEvent.fail());

      return;
    }

    const { result } = ReturnData;

    yield put(getContentDetailsEvent.succeed(result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getContentDetailsEvent.fail());
  }
}
export function* getContentDetails(handler: typeof getContentDetailsHandler) {
  yield takeLatest(
    ActionType.GET_CONTENT_DETAILS_START,
    runGetContentDetails,
    handler,
  );
}

// いいねしたユーザー取得
const getLikedUsersHandler = api.getGetFactory(true);
function* runGetLikedUsers(
  handler: typeof getLikedUsersHandler,
  action: ReturnType<typeof getLikedUsers.start>,
) {
  const { contentId, ...data } = action.payload;

  try {
    const ReturnData: getLikedUsersResponse = yield call(
      handler,
      data,
      `/contents/${contentId}/like/users`,
    );

    if (!ReturnData.result) {
      yield put(getLikedUsers.fail());

      return;
    }

    yield put(getLikedUsers.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getLikedUsers.fail());
  }
}

export function* getLikedUsersList(handler: typeof getLikedUsersHandler) {
  yield takeLatest(ActionType.GET_LIKED_USERS_START, runGetLikedUsers, handler);
}

export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

export const likesSagas = [
  fork(getReady),
  fork(getContentDetails, getContentDetailsHandler),
  fork(getLikedUsersList, getLikedUsersHandler),
];
