import React, { FC } from 'react';
import ClassNames from 'classnames';
import {
  Fanclub,
  FanclubInitialValue,
} from '../../../../interfaces/fanclubInterFace';

import McsIconLabelSquareTertiary from '../../molecules/iconWithLabel/McsIconLabelSquareTertiary';
import McsTagWithLabel from '../../molecules/tagWithLabel/McsTagWithLabel';

import * as styles from './OrgProductDetails.module.scss';

type PartialFanclub = Partial<Fanclub>;
interface Props {
  fanclub?: PartialFanclub;
  paymentTagText?: string;
  paymentBodyText?: string;
  cssClass?: string;
}

const OrgProductDetails: FC<Props> = ({
  fanclub = FanclubInitialValue,
  paymentTagText = '',
  paymentBodyText = '',
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.base, cssClass);

  return (
    <ul className={classNames}>
      <li className={styles.unit}>
        <McsIconLabelSquareTertiary
          src={fanclub.icon_image}
          mainText={fanclub.name}
          subText={fanclub.site_name}
        />
      </li>
      <li className={styles.unit2}>
        <McsTagWithLabel
          tagText="料金"
          mainText={`月額${fanclub.price}円（税込）`}
        />
      </li>
      {paymentBodyText || paymentBodyText ? (
        <li className={styles.unit}>
          <McsTagWithLabel
            tagText={paymentTagText}
            mainText={paymentBodyText}
          />
        </li>
      ) : null}
    </ul>
  );
};

export default OrgProductDetails;
