import { AllState } from '../store';
import { SelectField } from '../interfaces/utilInterFace';

const getAdminContentsState = (state: AllState) => {
  return state.adminContents;
};

const getMemberField = (state: AllState): SelectField => {
  const field: SelectField = state.adminContents.members.map(m => ({
    label: m.nickname,
    value: m.id,
  }));

  return field;
};

const getCategoryField = (state: AllState): SelectField => {
  const field: SelectField = state.adminContents.categories.map(c => ({
    label: c.name,
    value: c.id,
  }));

  return field;
};

const getIsLastPage = (state: AllState) => {
  return (
    state.adminContents.pager.current_page ===
    state.adminContents.pager.last_page
  );
};

export default {
  getAdminContentsState,
  getMemberField,
  getCategoryField,
  getIsLastPage,
};
