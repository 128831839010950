import { Reducer } from 'redux';
import { Actions } from '../actions/utilActions';
import * as ActionType from '../actions/utilConstants';
import {
  SelectFields,
  SelectFieldsInitialValue,
  HeaderMode,
} from '../interfaces/utilInterFace';

type State = {
  headerMode: HeaderMode;
  selectFields: SelectFields;
  loading: boolean;
  error: boolean;
};

export const initialState: State = {
  headerMode: '',
  selectFields: SelectFieldsInitialValue,
  loading: false,
  error: false,
};

const utilReducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    // change header mode
    case ActionType.CHANGE_HEADER_MODE: {
      return {
        ...state,
        headerMode: action.payload,
      };
    }

    // get select fields
    case ActionType.GET_SELECT_FIELDS_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_SELECT_FIELDS_SUCCEED: {
      return {
        ...state,
        selectFields: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_SELECT_FIELDS_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default utilReducer;
