import React, { FC } from 'react';
import ClassNames from 'classnames';

import IconShape from '../../atoms/iconParts/IconShape';
import Badge from '../../atoms/badge/Badge';
import {
  PaidFCOption,
  PaidFCOptionInitialValue,
} from '../../../../interfaces/footerInterFace';

import * as styles from './McsFooterPaidFC.module.scss';

interface Props {
  cssClass?: string;
  option?: PaidFCOption;
}

const McsFooterPaidFC: FC<Props> = ({
  cssClass = '',
  option = PaidFCOptionInitialValue,
}) => {
  const classNames = ClassNames(styles.McsFooterBannerWrap, cssClass);

  return (
    <div className={classNames}>
      <div className={styles.timelineIconWrapper}>
        {option.timeline.state ? (
          <IconShape type="TimelineActive" cssClass={styles.IconPartsActive} />
        ) : (
          <IconShape
            type="Timeline"
            cssClass={styles.IconParts}
            onClick={option.timeline.onClick}
          />
        )}
      </div>
      <div className={styles.chatIconWrapper}>
        {option.chat.state ? (
          <IconShape type="ChatActive" cssClass={styles.IconPartsActive} />
        ) : (
          <IconShape
            type="Chat"
            cssClass={styles.IconParts}
            onClick={option.chat.onClick}
          />
        )}
        {option.unreadChat && option.unreadChat > 0 ? (
          <Badge
            type="Primary"
            count={option.unreadChat > 99 ? 99 : option.unreadChat}
            cssClass={styles.chatBadge}
          />
        ) : null}
      </div>
    </div>
  );
};

export default McsFooterPaidFC;
