import { useEffect } from 'react';
import { Location } from 'history';
import ReactGA from 'react-ga';

/**
 * APIから取得したデータに応じてreact-helmetでtitleを設定する場合、
 * title変更後にtrueになるようにする
 */
export const useGA = (location: Location, ready = true) => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development' && ready)
      ReactGA.pageview(location.pathname + location.search);
  }, [location, ready]);
};
