/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { FC, useRef, useState, useEffect } from 'react';
import ClassNames from 'classnames';
import Linkify from 'react-linkify';

import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';
import LabelTag from '../../atoms/label/LabelTag';

import McsIconLabelSquareSenary from '../../molecules/iconWithLabel/McsIconLabelSquareSenary';

import OrgFCListUnitMember from '../listUnitParts/OrgFCListUnitMember';

import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgHeroFC.module.scss';

type NestedPartial<T> = {
  [K in keyof T]?: T[K] extends Array<infer R>
    ? Array<NestedPartial<R>>
    : NestedPartial<T[K]>;
};
type PartialFanclubDetails = NestedPartial<FanclubDetails>;

interface Props {
  cssClass?: string;
  fanclubDetails?: PartialFanclubDetails;
  prefecture?: string;
  isFollow?: boolean;
  isPaid?: boolean;
  isManage?: boolean;
  onLinkClick?: () => void;
  onClickFollowersList?: () => void;
  onFollowClick?: () => void;
}

const OrgHero: FC<Props> = ({
  cssClass = '',
  fanclubDetails = FanclubDetailsInitialValue,
  prefecture = '',
  isFollow = false,
  isPaid = false,
  isManage = false,
  onLinkClick = () => undefined,
  onClickFollowersList = () => undefined,
  onFollowClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgHeroBase, cssClass);

  const descriptionRef = useRef<HTMLParagraphElement>(null);
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    if (!descriptionRef.current) return;

    const height = descriptionRef.current.clientHeight;

    setVisible(height <= 63);
  }, [fanclubDetails]);

  return (
    <div className={classNames}>
      <div className={styles.OrgHeroMain}>
        <div
          className={styles.OrgHeroImage}
          style={{
            background: `url(${fanclubDetails.fanclub?.cover_image}) ${
              fanclubDetails.fanclub?.cover_image ? '#ffffff' : '#EB5757'
            } no-repeat center / cover`,
          }}
        />

        {!isManage && (
          <ButtonRoundedCorners
            text={isPaid ? '入会中' : isFollow ? 'フォロー中' : 'フォローする'}
            type={isPaid || isFollow ? 'Primary' : 'Secondary'}
            size="Quaternary"
            cssClass={styles.OrgHeroFollowButton}
            onClick={onFollowClick}
          />
        )}

        <McsIconLabelSquareSenary
          src={fanclubDetails.fanclub?.icon_image}
          mainText={fanclubDetails.fanclub?.name}
          subText={fanclubDetails.fanclub?.site_name}
          cssClass={styles.OrgHeroThumbnail}
        />
      </div>

      <div className={styles.OrgHeroContent}>
        <div className={styles.OrgHeroFollowList}>
          <OrgFCListUnitMember
            userList={fanclubDetails.members}
            text="人のメンバー"
            onLinkClick={onLinkClick}
          />
        </div>
        <div className={styles.OrgHeroData}>
          <div className={styles.OrgHeroDataPlace}>
            <LabelTag text="活動拠点" type="Secondary" />
            <span>{prefecture}</span>
          </div>
          <div
            className={styles.OrgHeroDataFollow}
            onClick={onClickFollowersList}
          >
            <span>{fanclubDetails.followerCount}</span>人のフォロワー
          </div>
        </div>

        <div
          className={
            isVisible
              ? styles.OrgHeroDescriptionOpen
              : styles.OrgHeroDescriptionHidden
          }
          onClick={() => setVisible(true)}
        >
          <div ref={descriptionRef}>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <a target="blank" href={decoratedHref} key={key}>
                  {decoratedText}
                </a>
              )}
            >
              {fanclubDetails.fanclub?.description}
            </Linkify>
          </div>
          {!isVisible && <div className={styles.OrgHeroDescriptionCover} />}
        </div>
      </div>
    </div>
  );
};

export default OrgHero;
