import { Status, Pager, BoolNumber } from './commonInterFace';
import { OpenArea } from './contentInterFace';

export enum NotificationType {
  // Official
  KRAP = 100,
  // FC
  AddMember = 201,
  TransferAuthority = 202,
  MemberLeft = 203,
  UpdateSiteSettings = 204,
  CloseCommunity = 205,
  NoticeOfCharge = 210,
  ApplicationApproval = 215,
  ApplicationRejected = 216,
  likeNotification = 301,
  commentNotification = 302,
  // User
}

export type NotificationBase = {
  id: number;
  // type: NotificationType;
  title: string; // 'KRAPへようこそ!';
  description: string | null;
  created_at: string | null; // '2020-06-12 10:26:15';
  updated_at: string | null; // '2020-06-12 10:26:15';
  icon_image: string | null;
  pivot: {
    id: number;
    user_id: number;
    notification_id: number;
    notification_user: string | null;
    notification_fanclub: string | null;
    notification_content: string | null;
    is_read: BoolNumber;
    release_at: string | null; // '2020-02-01 00:00:00';
    close_at: string | null;
    created_at: string | null; // '2020-06-12 10:26:15';
    updated_at: string | null; // '2020-06-12 10:26:15';
  };
};

export type NotificationKRAP = NotificationBase & {
  type: NotificationType.KRAP;
};

export type NotificationAddMember = NotificationBase & {
  type: NotificationType.AddMember;
  fanclub_name: string;
  group_id: string;
  user_name: string;
};

export type NotificationPaid = NotificationBase & {
  type:
    | NotificationType.NoticeOfCharge
    | NotificationType.ApplicationApproval
    | NotificationType.ApplicationRejected;
  fanclub_name: string;
  group_id: string;
};

export type NotificationContent = NotificationBase & {
  type:
    | NotificationType.likeNotification
    | NotificationType.commentNotification;
  group_id: string;
  user_name: string;
  name_id: string;
  content_subject: string;
  content_id: number;
  total: number;
};

export type Notification =
  | NotificationKRAP
  | NotificationAddMember
  | NotificationPaid
  | NotificationContent;

export const NotificationInitialValue: Notification = {
  id: 0,
  type: 100,
  title: '',
  description: null,
  created_at: null,
  updated_at: null,
  icon_image: null,
  pivot: {
    id: 0,
    user_id: 0,
    notification_id: 0,
    notification_user: null,
    notification_fanclub: null,
    notification_content: null,
    is_read: 0,
    release_at: null,
    close_at: null,
    created_at: null,
    updated_at: null,
  },
};

/**
 * KRAP公式からのお知らせ
 */
export type NewsDetails = {
  id: number;
  user_id: number;
  subject: string;
  body: string;
  url: string; // サムネイル用画像 'http://***'
  image: string; // 本文用画像 'http://***'
  release_at: string | null; // 'YYYY-MM-DD HH:mm:ss'
  close_at: string | null;
  open_area: OpenArea;
  is_release: BoolNumber;
  total_like: number;
  notification_id: number;
  created_at: string | null; // 'YYYY-MM-DD HH:mm:ss'
  updated_at: string | null; // 'YYYY-MM-DD HH:mm:ss'
  deleted_at: string | null;
};
export const NewsDetailsInitialValue: NewsDetails = {
  id: 0,
  user_id: 0,
  subject: '',
  body: '',
  url: '',
  image: '',
  release_at: null,
  close_at: null,
  open_area: 3,
  is_release: 0,
  total_like: 0,
  notification_id: 0,
  created_at: null,
  updated_at: null,
  deleted_at: null,
};

export type GetNotificationListParams = {
  page?: number;
  limit?: number;
};
export type GetNotificationListResult = Pager & {
  data: Notification[];
  unread_count: number;
};
export type GetNotificationListResponse = {
  status_code: Status.OK;
  result: GetNotificationListResult;
};

export type GetNotificationAndMarkAsReadParams = {
  notificationId: number;
};
export type GetNotificationAndMarkAsReadResponse = {
  status_code: Status.OK;
};

export type MarkAllAsReadResponse = {
  status_code: Status.OK;
  message: string;
};

export type GetNewsDetailsParams = {
  notificationId: number;
};
export type GetNewsDetailsResult = {
  news: NewsDetails;
};
export type GetNewsDetailsResponse = {
  status_code: Status.OK;
  result: GetNewsDetailsResult;
};
