/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/settingConstants';
import {
  runGetUserProfile,
  getUserProfileHandler,
  checkAndSetStatusError,
} from './authSagas';
import {
  gotReady,
  getCardInfoEvent,
  changeReceptionSettingsEvent,
} from '../actions/settingActions';

import { ErrorResponse, commonResponse } from '../interfaces/commonInterFace';
import { GetCardInfoResponse } from '../interfaces/cardInterFace';

// initialize
function* runGetReady() {
  const updateMeTask = yield fork(runGetUserProfile, getUserProfileHandler);
  yield join(updateMeTask);

  const getCardInfoTask = yield fork(runGetCardInfo, getCardInfoHandler);
  yield join(getCardInfoTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get card info
const getCardInfoHandler = api.getGetFactory(true);
function* runGetCardInfo(handler: typeof getCardInfoHandler) {
  try {
    const ReturnData: GetCardInfoResponse = yield call(
      handler,
      {},
      '/user/card',
    );

    if (!ReturnData) {
      yield put(getCardInfoEvent.fail());

      return;
    }

    yield put(getCardInfoEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getCardInfoEvent.fail());
  }
}
export function* getCardInfo(handler: typeof getCardInfoHandler) {
  yield takeLatest(ActionType.GET_CARD_INFO_START, runGetCardInfo, handler);
}

// メール受取設定の変更
const changeReceptionSettingHandler = api.getGetFactory(true);
function* runChangeReceptionSetting(
  handler: typeof changeReceptionSettingHandler,
) {
  try {
    const ReturnData: commonResponse = yield call(
      handler,
      {},
      '/user/email/setting',
    );

    if (!ReturnData) {
      yield put(changeReceptionSettingsEvent.fail());

      return;
    }
    const updateMeTask = yield fork(runGetUserProfile, getUserProfileHandler);
    yield join(updateMeTask);

    yield put(changeReceptionSettingsEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(changeReceptionSettingsEvent.fail());
  }
}
export function* changeReceptionSetting(
  handler: typeof changeReceptionSettingHandler,
) {
  yield takeLeading(
    ActionType.CHANGE_RECEPTION_SETTING_START,
    runChangeReceptionSetting,
    handler,
  );
}

export const settingSagas = [
  fork(getReady),
  fork(getCardInfo, getCardInfoHandler),
  fork(changeReceptionSetting, changeReceptionSettingHandler),
];
