/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useMemo } from 'react';
import ClassNames from 'classnames';

import { Photo } from '../../../../interfaces/contentInterFace';
import { InputFile } from '../../../../interfaces/utilInterFace';

import * as styles from './OrgContentPhotoRegister.module.scss';
import InputFileThumbnail from '../../atoms/formParts/InputFileThumbnail';
import ImageThumbnailWithClose from '../../atoms/imageThumbnail/ImageThumbnailWithClose';

interface Props {
  cssClass?: string;
  currentPhotoList?: Photo[];
  newPhotoList?: InputFile[];
  onDeleteCurrentPhoto?: (id: number) => void;
  onDeleteNewPhoto?: (index: number) => void;
  onAddNewPhoto?: (e: React.FormEvent<HTMLInputElement>) => void;
  errorText?: string;
  isError?: boolean;
}

const OrgContentPhotoRegister: FC<Props> = ({
  cssClass = '',
  currentPhotoList = [],
  newPhotoList = [],
  onDeleteCurrentPhoto = () => undefined,
  onDeleteNewPhoto = () => undefined,
  onAddNewPhoto = () => undefined,
  errorText = '',
  isError = false,
}) => {
  const classNames = ClassNames(styles.OrgContentPhotoRegisterBase, cssClass);

  const isAddable = useMemo(
    () => currentPhotoList.length + newPhotoList.length < 10,
    [currentPhotoList.length, newPhotoList.length],
  );

  return (
    <div>
      <div className={classNames}>
        {currentPhotoList.map(photo => (
          <ImageThumbnailWithClose
            key={`current${photo.id.toString()}`}
            type="Secondary"
            src={photo.url}
            onClick={() => onDeleteCurrentPhoto(photo.id)}
            cssClass={styles.OrgContentPhotoRegisterWrapper}
          />
        ))}
        {newPhotoList.map((photo, index) => (
          <ImageThumbnailWithClose
            key={`new${index.toString()}`}
            type="Secondary"
            src={photo.preview}
            onClick={() => onDeleteNewPhoto(index)}
            cssClass={styles.OrgContentPhotoRegisterWrapper}
          />
        ))}

        {isAddable && (
          <InputFileThumbnail
            title="画像を追加"
            onChange={onAddNewPhoto}
            cssClass={styles.OrgContentPhotoRegisterWrapper}
          />
        )}
      </div>
      {isError && (
        <p className={styles.OrgContentPhotoRegisterInputErrorText}>
          {errorText}
        </p>
      )}
    </div>
  );
};

export default OrgContentPhotoRegister;
