import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './ButtonMedia.module.scss';

interface Props {
  text?: string;
  type: string;
  cssClass?: string;
  onClick?: () => void;
}

const Button: FC<Props> = ({
  text = '',
  type = 'PlayVideo',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.ButtonBase, cssClass, {
    [styles.ButtonPlayVideo]: type === 'PlayVideo', // PlayVideo
    [styles.ButtonPlaySound]: type === 'PlaySound', // PlaySound
    [styles.ButtonPauseSound]: type === 'PauseSound', // PauseSound
    [styles.ButtonSeekSound]: type === 'SeekSound', // SeekSound
  });

  return (
    <div className={classNames}>
      <button type="button" onClick={onClick}>
        <span>{text}</span>
      </button>
    </div>
  );
};

export default Button;
