/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/withdrawPaidFCConstants';
import {
  runGetUserProfile,
  getUserProfileHandler,
  checkAndSetStatusError,
} from './authSagas';
import { getUserProfileEvent as updateMe } from '../actions/authActions';
import {
  gotReady,
  withdrawPaidFCEvent,
} from '../actions/withdrawPaidFCActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import { FreejoinFanclubResponse } from '../interfaces/fanclubInterFace';

// initialize
function* runGetReady() {
  // update me
  const updateMeTask = yield fork(runGetUserProfile, getUserProfileHandler);
  yield join(updateMeTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// withdraw paid fc
const withdrawPaidFCHandler = api.getPostFactory(true);
function* runWithdrawPaidFCFanclub(
  handler: typeof withdrawPaidFCHandler,
  action: ReturnType<typeof withdrawPaidFCEvent.start>,
) {
  const { artistId } = action.payload;
  try {
    const ReturnData: FreejoinFanclubResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/freejoin`,
    );

    if (!ReturnData) {
      yield put(withdrawPaidFCEvent.fail());

      return;
    }

    // update user profile
    yield put(updateMe.start());

    yield put(withdrawPaidFCEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(withdrawPaidFCEvent.fail());
  }
}
export function* withdrawPaidFC(handler: typeof withdrawPaidFCHandler) {
  yield takeLeading(
    ActionType.WITHDRAW_PAID_FC_START,
    runWithdrawPaidFCFanclub,
    handler,
  );
}

export const withdrawPaidFCSagas = [
  fork(getReady),
  fork(withdrawPaidFC, withdrawPaidFCHandler),
];
