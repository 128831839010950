/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import McsIconLabelSquareSeptenary from '../../molecules/iconWithLabel/McsIconLabelSquareSeptenary';

import * as styles from './OrgPostContentFanclubList.module.scss';

import { Fanclub } from '../../../../interfaces/fanclubInterFace';

type Props = {
  title?: string;
  fanclubList?: Partial<Fanclub>[];
  onClickFanclub?: (artistId: string) => void;
  cssClass?: string;
};

const OrgFooterPostContent: FC<Props> = ({
  title = '',
  fanclubList = [],
  onClickFanclub = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgPostContentFanclubListBase, cssClass);

  return (
    <div className={classNames}>
      {/**
       * test
       */}
      <div className={styles.OrgPostContentFanclubListTitleWrapper}>
        <p className={styles.OrgPostContentFanclubListTitle}>{title}</p>
      </div>
      {}
      <ul className={styles.OrgPostContentFanclubListList}>
        {fanclubList.map(fanclub => (
          <li className={styles.OrgPostContentFanclubListListUnit}>
            <McsIconLabelSquareSeptenary
              src={fanclub.icon_image ?? ''}
              mainText={fanclub.name ?? ''}
              subText={fanclub.site_name ?? ''}
              onClick={() => onClickFanclub(fanclub.group_id ?? '')}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OrgFooterPostContent;
