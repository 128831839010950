/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/followArtistConstants';
import { checkAndSetStatusError } from './authSagas';
import { getUserProfileEvent as updateMe } from '../actions/authActions';
import {
  gotReady,
  getRecommendFanclubListEvent,
  freejoinFanclubEvent,
  withdrawFanclubEvent,
} from '../actions/followArtistActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import {
  GetPickupFanclubListResponse,
  FreejoinFanclubResponse,
  WithdrawFanclubResponse,
} from '../interfaces/fanclubInterFace';

// initialize
function* runGetReady() {
  const getRecommendFanclubListTask = yield fork(
    runGetRecommendFanclubList,
    getRecommendFanclubListHandler,
    getRecommendFanclubListEvent.start({ scope: 'recommend' }),
  );
  yield join(getRecommendFanclubListTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get recommend fanclub list
const getRecommendFanclubListHandler = api.getGetFactory(true);
function* runGetRecommendFanclubList(
  handler: typeof getRecommendFanclubListHandler,
  action: ReturnType<typeof getRecommendFanclubListEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: GetPickupFanclubListResponse = yield call(
      handler,
      data,
      '/fanclub',
    );

    if (!ReturnData) {
      yield put(getRecommendFanclubListEvent.fail());

      return;
    }

    yield put(getRecommendFanclubListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getRecommendFanclubListEvent.fail());
  }
}
export function* getRecommendFanclubList(
  handler: typeof getRecommendFanclubListHandler,
) {
  yield takeLatest(
    ActionType.GET_RECOMMEND_FANCLUB_LIST_START,
    runGetRecommendFanclubList,
    handler,
  );
}

// freejoin fanclub
const freejoinFanclubHandler = api.getPostFactory(true);
function* runFreejoinFanclub(
  handler: typeof freejoinFanclubHandler,
  action: ReturnType<typeof freejoinFanclubEvent.start>,
) {
  const { artistId } = action.payload;
  try {
    const ReturnData: FreejoinFanclubResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/freejoin`,
    );

    if (!ReturnData) {
      yield put(freejoinFanclubEvent.fail());

      return;
    }

    // update user profile
    yield put(updateMe.start());

    yield put(freejoinFanclubEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(freejoinFanclubEvent.fail());
  }
}
export function* freejoinFanclub(handler: typeof freejoinFanclubHandler) {
  yield takeLeading(
    ActionType.FREEJOIN_FANCLUB_START,
    runFreejoinFanclub,
    handler,
  );
}

// withdraw fanclub
const withdrawFanclubHandler = api.getPostFactory(true);
function* runWithdrawFanclub(
  handler: typeof withdrawFanclubHandler,
  action: ReturnType<typeof withdrawFanclubEvent.start>,
) {
  const { artistId } = action.payload;
  try {
    const ReturnData: WithdrawFanclubResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/withdraw`,
    );

    if (!ReturnData) {
      yield put(withdrawFanclubEvent.fail());

      return;
    }

    // update user profile
    yield put(updateMe.start());

    yield put(withdrawFanclubEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(withdrawFanclubEvent.fail());
  }
}
export function* withdrawFanclub(handler: typeof withdrawFanclubHandler) {
  yield takeLeading(
    ActionType.WITHDRAW_FANCLUB_START,
    runWithdrawFanclub,
    handler,
  );
}

export const followArtistSagas = [
  fork(getReady),
  fork(getRecommendFanclubList, getRecommendFanclubListHandler),
  fork(freejoinFanclub, freejoinFanclubHandler),
  fork(withdrawFanclub, withdrawFanclubHandler),
];
