/* eslint-disable no-alert */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import IconShape from '../../atoms/iconParts/IconShape';
import * as styles from './OrgFollowerAdminBar.module.scss';

interface Props {
  cssClass?: string;
  followerCount?: number;
  paidFollowerCount?: number;
  blockedCount?: number;
}

const OrgFollowerAdminBar: FC<Props> = ({
  cssClass = '',
  followerCount = 0,
  paidFollowerCount = 0,
  blockedCount = 0,
}) => {
  const classNames = ClassNames(styles.OrgFollowerAdminBarBase, cssClass, {});

  return (
    <div className={classNames}>
      <dl className={styles.OrgFollowerAdminBarItem}>
        <dt>Followers</dt>
        <dd>{followerCount}</dd>
      </dl>
      <dl className={styles.OrgFollowerAdminBarItem}>
        <dt>
          <IconShape type="PMark" />
        </dt>
        <dd>
          {paidFollowerCount}
          <span>/{followerCount}</span>
        </dd>
      </dl>
      <dl className={styles.OrgFollowerAdminBarItem}>
        <dt>Blocks</dt>
        <dd>{blockedCount}</dd>
      </dl>
    </div>
  );
};

export default OrgFollowerAdminBar;
