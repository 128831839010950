/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import ButtonFullWidth from '../../atoms/buttonBasic/ButtonFullWidth';
import Tab from '../../atoms/tab/Tab';
import OrgFCListUnitTimeLine from '../listUnitParts/OrgFCListUnitTimeLine';
import OrgFCListUnitTimeLinePhoto from '../listUnitParts/OrgFCListUnitTimeLinePhoto';
import {
  TimeLineTabList,
  ContentListType,
  Content,
} from '../../../../interfaces/contentInterFace';
import OrgEmbeddedLoading from '../contentParts/OrgEmbeddedLoading';
import selector from '../../../../selectors';

import * as styles from './OrgTimeLine.module.scss';
import { useSelector } from 'react-redux';

interface Props {
  isLoading?: boolean;
  type?: ContentListType;
  isTabView?: boolean;
  onChangeTab?: (type: ContentListType) => void;

  contents?: Content[];
  isViewFanclub?: boolean;
  isViewLikeCount?: boolean;
  isPhotoThumbnail?: boolean;
  onClickContent?: (artistId: string, contentId: number) => void;
  onClickUser?: (userId: string) => void;
  onClickFanclub?: (artistId: string) => void;

  isViewReadMoreButton?: boolean;
  onReadMore?: () => void;

  cssClass?: string;
}

const OrgTimeLine: FC<Props> = ({
  isLoading = false,
  type = 0,
  contents = [],
  isViewFanclub = false,
  isViewLikeCount = false,
  isTabView = true,
  isPhotoThumbnail = false,
  onChangeTab = () => undefined,

  onClickContent = () => undefined,
  onClickUser = () => undefined,
  onClickFanclub = () => undefined,

  isViewReadMoreButton = false,
  onReadMore = () => undefined,

  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListBase, cssClass);

  return (
    <div className={classNames}>
      {isTabView && (
        <div className={styles.OrgFCListTabMenuWrap}>
          <div className={styles.OrgFCListTabMenu}>
            {TimeLineTabList.map(tab => (
              <Tab
                key={`Tab${tab.type}`}
                text={tab.label}
                cssClass={styles.OrgFCListTabMenuItem}
                type={type === tab.type ? 'Tertiary' : 'Quaternary'}
                onClick={() => onChangeTab(tab.type)}
              />
            ))}
          </div>
        </div>
      )}

      {isLoading && <OrgEmbeddedLoading />}

      {isPhotoThumbnail && type === 2 ? (
        <ul className={styles.OrgFCListPhotoWrap}>
          {contents.map((content, i) => (
            <OrgFCListUnitTimeLinePhoto
              cssClass={styles.OrgFCListPhotoItem}
              content={content}
              onClickContent={onClickContent}
            />
          ))}
        </ul>
      ) : (
        contents.map((content, i) => (
          <div key={`${type}-${content.id}-${i.toString()}`}>
            {i === 0 ? null : <hr className={styles.hr} />}
            <div className={styles.OrgFCListWrap}>
              <OrgFCListUnitTimeLine
                content={content}
                isViewFanclub={isViewFanclub}
                isViewLikeCount={isViewLikeCount}
                onClickContent={onClickContent}
                onClickUser={onClickUser}
                onClickFanclub={onClickFanclub}
              />
            </div>
          </div>
        ))
      )}

      {/* Read More Button */}
      {isViewReadMoreButton && (
        <ButtonFullWidth
          text="もっと見る"
          type="Secondary"
          onClick={onReadMore}
        />
      )}
    </div>
  );
};

export default OrgTimeLine;
