/**
 * Cookie、SessionStorageの管理用Helper
 */

import * as cookie from 'cookie_js';

cookie.defaults.path = '/';
cookie.defaults.expires = 3000;

export const ONBOARDED_PERSISTENCE_KEY = 'ONBOARDED' as const;
export const REGISTER_CONTENT_ONBOARDED_PERSISTENCE_KEY = 'REGISTER_CONTENT_ONBOARDED' as const;
export const CHAT_ONBOARD_PERSISTENCE_KEY = 'CHAT_ONBOARD' as const;
export const HIDE_DENIAL = 'HIDE_DENIAL' as const;
export const TOKEN_PERSISTENCE_KEY = 'KRAP_AUTH_TOKEN' as const;
export const TWITTER_TOKEN_PERSISTENCE_KEY = 'KRAP_TWITTER_AUTH_TOKEN' as const;
export const SIGNUP_EMAIL_PERSISTENCE_KEY = 'KRAP_SIGNUP_EMAIL' as const;

// オンボーディング終了フラグ用のCookie
export const setOnboardedCookie = () => {
  // オンボーディング終了済みをセット
  // Cookieがセットされているかどうかしか見ないので、finishedじゃなくてもいい
  cookie.set(ONBOARDED_PERSISTENCE_KEY, 'finished');
};
export const getOnboardedCookie = () => {
  const finished = cookie.get(ONBOARDED_PERSISTENCE_KEY);

  return finished;
};

// コンテンツ投稿のオンボーディング終了フラグ用のCookie
export const setRegisterContentOnboardedCookie = () => {
  // オンボーディング終了済みをセット
  // Cookieがセットされているかどうかしか見ないので、finishedじゃなくてもいい
  cookie.set(REGISTER_CONTENT_ONBOARDED_PERSISTENCE_KEY, 'finished');
};
export const getRegisterContentOnboardedCookie = () => {
  const finished = cookie.get(REGISTER_CONTENT_ONBOARDED_PERSISTENCE_KEY);

  return finished;
};

// チャット機能のオンボーディング終了フラグ用のCookie
export const setChatOnboardCookie = () => {
  // オンボーディング終了済みをセット
  // Cookieがセットされているかどうかしか見ないので、finishedじゃなくてもいい
  cookie.set(CHAT_ONBOARD_PERSISTENCE_KEY, 'finished');
};
export const getChatOnboardCookie = () => {
  return cookie.get(CHAT_ONBOARD_PERSISTENCE_KEY);
};

// コミュニティ有料申請が否認された場合に表示されるテキストを表示しないためのCookie
export const setHideDenialCookie = (artistId: string) => {
  cookie.set(HIDE_DENIAL + artistId, artistId);
};
export const getHideDenialCookie = (artistId: string) => {
  const artistIdInCookie = cookie.get(HIDE_DENIAL + artistId);

  return artistIdInCookie;
};
export const deleteHideDenialCookie = () => {
  cookie.remove(HIDE_DENIAL);
  cookie.removeSpecific(HIDE_DENIAL);
};

// Auth Cookie
export const setAuthCookie = (token: string) => {
  cookie.set(TOKEN_PERSISTENCE_KEY, token);
};
export const getAuthCookie = () => {
  const token = cookie.get(TOKEN_PERSISTENCE_KEY);

  return token;
};
export const deleteAuthCookie = () => {
  cookie.remove(TOKEN_PERSISTENCE_KEY);
  cookie.removeSpecific(TOKEN_PERSISTENCE_KEY);
};

// Auth Session Storage
export const setAuthSessionStorage = (token: string) => {
  sessionStorage.setItem(TOKEN_PERSISTENCE_KEY, token);
};
export const getAuthSessionStorage = () => {
  const token = sessionStorage.getItem(TOKEN_PERSISTENCE_KEY);

  return token;
};
export const deleteAuthSessionStorage = () => {
  sessionStorage.removeItem(TOKEN_PERSISTENCE_KEY);
};

// Twitter Auth Session Storage
export const setTwitterAuthSessionStorage = (token: string) => {
  sessionStorage.setItem(TWITTER_TOKEN_PERSISTENCE_KEY, token);
};
export const getTwitterAuthSessionStorage = () => {
  const token = sessionStorage.getItem(TWITTER_TOKEN_PERSISTENCE_KEY);

  return token;
};
export const deleteTwitterAuthSessionStorage = () => {
  sessionStorage.removeItem(TWITTER_TOKEN_PERSISTENCE_KEY);
};

/**
 * Almighty
 */
export const setCookie = (cookieData: string, cookieKey: string) => {
  cookie.set(cookieKey, cookieData);
};
export const getCookie = (cookieKey: string) => {
  const cookieObj = cookie.get(cookieKey);

  return cookieObj;
};
export const deleteCookie = (cookieKey: string) => {
  cookie.removeSpecific(cookieKey);
};

export const setSessionStorage = (storageData: string, storageKey: string) => {
  sessionStorage.setItem(storageKey, storageData);
};
export const getSessionStorage = (storageKey: string) => {
  const sessionStorageObj = sessionStorage.getItem(storageKey);

  return sessionStorageObj;
};
export const deleteSessionStorage = (storageKey: string) => {
  sessionStorage.removeItem(storageKey);
};
