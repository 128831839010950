import React, { FC, useMemo, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  getNotificationListEvent,
  markAllAsReadEvent,
  markAsReadEvent,
  cleanup,
} from '../../actions/newsActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { NotificationType } from '../../interfaces/newsInterFace';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import News from '../../components/news/news';
import { AdminAdministratorTabMode } from '../admin/adminAdministrator';

const NewsContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { ready: authReady, unread_count } = useSelector(
    selector.auth.getAuthState,
  );

  const [top, setTop] = useState<number>(0);
  const { ready, loading } = useSelector(selector.news.getNewsState);
  const notificationList = useSelector(selector.news.getNotificationSortedList);
  const isLastPage = useSelector(selector.news.getIsLastPage);

  const handleClickMarkAllAsRead = useCallback(() => {
    if (unread_count) dispatch(markAllAsReadEvent.start());
  }, [dispatch, unread_count]);
  const handleClickMarkAsRead = useCallback(
    (id: number) => {
      const notification = notificationList.find(n => n.pivot.id === id);

      if (!notification) return;

      // 既読処理
      dispatch(
        markAsReadEvent.start({
          notificationId: id,
        }),
      );

      // type別に遷移
      if (notification.type === NotificationType.KRAP) {
        history.push(PATH.getNewsPath(notification.id));
      } else if (notification.type === NotificationType.AddMember) {
        history.push(PATH.getAdminPath(notification.group_id, 'MENU'));
      } else if (notification.type === NotificationType.NoticeOfCharge) {
        history.push(PATH.getArtistPath(notification.group_id));
      } else if (notification.type === NotificationType.ApplicationApproval) {
        history.push(
          PATH.getAdminPath(notification.group_id, 'ADMINISTRATOR'),
          {
            ADMIN_ADMINISTRATOR: {
              mode: AdminAdministratorTabMode.BANK,
            },
          },
        );
      } else if (notification.type === NotificationType.ApplicationRejected) {
        history.push(PATH.getAdminPath(notification.group_id, 'MENU'));
      } else if (notification.type === NotificationType.likeNotification) {
        history.push(
          PATH.getLikePath(notification.group_id, notification.content_id),
        );
      } else if (notification.type === NotificationType.commentNotification) {
        history.push(
          PATH.getCommentPath(notification.group_id, notification.content_id),
        );
      }
    },
    [dispatch, history, notificationList],
  );
  // もっと見るクリック時
  const handleClickReadMore = useCallback(() => {
    const topY = window.scrollY;
    setTop(topY);
    dispatch(
      getNotificationListEvent.start({
        limit: notificationList.length + 10,
      }),
    );
  }, [dispatch, notificationList]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    window.scrollTo(0, top);
  }, [top, notificationList]);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    dispatch(getReady({}));
  }, [authReady, dispatch]);

  return (
    <>
      <Helmet>
        <title>お知らせ一覧 | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      <News
        notifications={notificationList.reverse()}
        isViewReadMoreButton={!isLastPage}
        onClickMarkAllAsRead={handleClickMarkAllAsRead}
        onClickNotification={handleClickMarkAsRead}
        onClickReadMore={handleClickReadMore}
      />
    </>
  );
};

export default NewsContainer;
