export const GET_READY = 'SETTING/GET_READY' as const;
export const GOT_READY = 'SETTING/GOT_READY' as const;

export const GET_CARD_INFO_START = 'SETTING/GET_CARD_INFO_START' as const;
export const GET_CARD_INFO_SUCCEED = 'SETTING/GET_CARD_INFO_SUCCEED' as const;
export const GET_CARD_INFO_FAIL = 'SETTING/GET_CARD_INFO_FAIL' as const;

export const CHANGE_RECEPTION_SETTING_START = 'SETTING/CHANGE_RECEPTION_SETTING_START' as const;
export const CHANGE_RECEPTION_SETTING_SUCCEED = 'SETTING/CHANGE_RECEPTION_SETTING_SUCCEED' as const;
export const CHANGE_RECEPTION_SETTING_FAIL = 'SETTING/CHANGE_RECEPTION_SETTING_FAIL' as const;

export const CLEANUP = 'SETTING/CLEANUP' as const;
