/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLeading,
  takeLatest,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/adminApplyPaidFCConstants';
import {
  runGetUserProfile,
  getUserProfileHandler,
  checkAndSetStatusError,
} from './authSagas';
import { getUserProfileEvent as updateMe } from '../actions/authActions';
import {
  gotReady,
  getAddressEvent,
  applyPaidFCEvent,
} from '../actions/adminApplyPaidFCActions';

import { Status } from '../interfaces/commonInterFace';
import {
  ApplyPaidFCResponse,
  ApplyPaidFCErrorResponse,
} from '../interfaces/fanclubInterFace';
import { GetAddressResponse } from '../interfaces/addressInterFace';

// initialize
function* runGetReady() {
  const updateMeTask = yield fork(runGetUserProfile, getUserProfileHandler);
  yield join(updateMeTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get address
const getAddressHandler = api.getAddressFactory();
function* runGetAddress(
  handler: typeof getAddressHandler,
  action: ReturnType<typeof getAddressEvent.start>,
) {
  const data = action.payload;

  try {
    const ReturnData: GetAddressResponse = yield call(
      handler,
      data,
      '/api/json',
    );

    if (!ReturnData) {
      yield put(getAddressEvent.fail());

      return;
    }

    if (!ReturnData.response.location) {
      yield put(getAddressEvent.fail());

      return;
    }

    yield put(getAddressEvent.succeed(ReturnData.response.location));
  } catch (error) {
    yield put(getAddressEvent.fail());
  }
}
export function* getAddress(handler: typeof getAddressHandler) {
  yield takeLeading(ActionType.GET_ADDRESS_START, runGetAddress, handler);
}

// apply paid fc
const applyPaidFCHandler = api.getFormDataFactory('POST');
function* runApplyPaidFC(
  handler: typeof applyPaidFCHandler,
  action: ReturnType<typeof applyPaidFCEvent.start>,
) {
  const { artistId, ...data } = action.payload;

  try {
    const ReturnData: ApplyPaidFCResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/paidApplication`,
    );

    if (!ReturnData) {
      yield put(applyPaidFCEvent.fail({}));

      return;
    }

    yield put(updateMe.start());

    yield put(applyPaidFCEvent.succeed());
  } catch (error) {
    const res = error.response.data as ApplyPaidFCErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(applyPaidFCEvent.fail(res.errors));

      return;
    }

    yield put(applyPaidFCEvent.fail({}));
  }
}
export function* applyPaidFC(handler: typeof applyPaidFCHandler) {
  yield takeLeading(ActionType.APPLY_PAID_FC_START, runApplyPaidFC, handler);
}

export const adminApplyPaidFCSagas = [
  fork(getReady),
  fork(applyPaidFC, applyPaidFCHandler),
  fork(getAddress, getAddressHandler),
];
