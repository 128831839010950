import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import { useHistory, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import * as styles from './NotFound.module.scss';

const NotFound: FC = () => {
  const history = useHistory();

  const handleClickBackToTop = useCallback(() => {
    history.replace(PATH.HOME);
  }, [history]);

  useGA(history.location);

  return (
    <>
      <Helmet>
        <title>404 Not Found | KRAP</title>
      </Helmet>
      <div className={styles.container}>
        <h1 className={styles.title}>404</h1>
        <h2 className={styles.subTitle}>Page Not Found</h2>

        <p className={styles.summary}>ご指定のページが見つかりません</p>

        <p className={styles.description}>
          {
            'KRAPをご利用いただきありがとうございます。\nURLが間違っているか、ページが削除された可能性があります。トップへ戻り、引き続きKRAPをお楽しみください。'
          }
        </p>

        <ButtonRoundedCorners
          size="Primary"
          text="トップへ戻る"
          onClick={handleClickBackToTop}
          cssClass={styles.button}
        />
      </div>
    </>
  );
};

export default NotFound;
