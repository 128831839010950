/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './IconShape.module.scss';

interface Props {
  type?: string;
  cssClass?: string;
  onClick?: () => void;
}

const Icon: FC<Props> = ({
  type = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.IconBase, cssClass, {
    [styles.IconStar]: type === 'Star', // Star
    [styles.IconFavoriteWideLine]: type === 'FavoriteWideLine', // FavoriteWideLine
    [styles.IconFavoriteLine]: type === 'FavoriteLine', // FavoriteLine
    [styles.IconFavoriteFill]: type === 'FavoriteFill', // FavoriteFill
    [styles.IconSpeechBubble]: type === 'SpeechBubble', // SpeechBubble
    [styles.IconCamera]: type === 'Camera', // Camera
    [styles.IconVoice]: type === 'Voice', // Voice
    [styles.IconVoiceGray]: type === 'VoiceGray', // VoiceGray

    [styles.IconPen]: type === 'Pen', // Pen
    [styles.IconPenWhite]: type === 'PenWhite', // PenWhite
    [styles.IconMail]: type === 'Mail', // Mail
    [styles.IconFavoriteLinePink]: type === 'FavoriteLinePink', // FavoriteLinePink
    [styles.IconSpeechBubblePurple]: type === 'SpeechBubblePurple', // SpeechBubblePurple
    [styles.IconSetting]: type === 'Setting', // Setting
    [styles.IconPerson]: type === 'Person', // Person
    [styles.IconPersonWithHeart]: type === 'PersonWithHeart', // PersonWithHeart
    [styles.IconSales]: type === 'Sales', // Sales
    [styles.IconAdmin]: type === 'Admin', // Admin
    [styles.IconTrash]: type === 'Trash', // Trash

    [styles.IconBell]: type === 'Bell', // Bell
    [styles.IconDelate]: type === 'Delete', // Delete
    [styles.IconPMark]: type === 'PMark', // PMark
    [styles.IconSort]: type === 'Sort', // Sort
    [styles.IconTwitterWhite]: type === 'TwitterWhite', // TwitterWhite

    [styles.IconFcHomeOn]: type === 'FCHomeOn', // FCHomeOn
    [styles.IconFcHomeOff]: type === 'FCHomeOff', // FCHomeOff
    [styles.IconFcTwOn]: type === 'FCTwOn', // FCTwOn
    [styles.IconFcTwOff]: type === 'FCTwOff', // FCTwOff
    [styles.IconFcFbOn]: type === 'FCFBOn', // FCFBOn
    [styles.IconFcFbOff]: type === 'FCFBOff', // FCFBOff
    [styles.IconFcIgOn]: type === 'FCIgOn', // FCIgOn
    [styles.IconFcIgOff]: type === 'FCIgOff', // FCIgOff

    [styles.IconChatYellow]: type === 'ChatYellow', // 管理画面用チャットアイコン
    [styles.IconChat]: type === 'Chat', // フッダー用チャットアイコン
    [styles.IconChatActive]: type === 'ChatActive', // フッダー用チャットアイコン（色付き）
    [styles.IconTimeline]: type === 'Timeline', // フッダー用タイムラインアイコン
    [styles.IconTimelineActive]: type === 'TimelineActive', // フッダー用タイムラインアイコン（色付き）

    [styles.IconLock]: type === 'lock', // 鍵マーク
  });

  return (
    <div className={classNames} onClick={onClick}>
      <span />
    </div>
  );
};

export default Icon;
