/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import Icon from '../../atoms/iconThumbnail/IconThumbnailRectangle';
import * as styles from './McsIconLabelRectangle.module.scss';

interface Props {
  src?: string;
  text?: string;
  type?: string;
  blur?: boolean;
  cssClass?: string;
  onClick?: () => void;
}

const McsIconLabel: FC<Props> = ({
  src = '',
  text = 'Text',
  type = 'Primary',
  blur = false,
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsIconLabelBase, cssClass, {
    [styles.McsIconLabelPrimary]: type === 'Primary', // Primary
  });

  return (
    <div className={classNames} onClick={onClick}>
      <Icon src={src} size="Primary" blur={blur} />
      <p className={styles.McsIconLabelText}>{text}</p>
    </div>
  );
};

export default McsIconLabel;
