import { AllState } from '../store';

const getFollowersState = (state: AllState) => {
  const lastPage =
    state.followers.pager.current_page !== state.followers.pager.last_page;

  return { ...state.followers, lastPage };
};

export default {
  getFollowersState,
};
