/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import OrgUserListUnitCategory from '../listUnitParts/OrgUserListUnitCategory';
import { Category } from '../../../../interfaces/categoryInterFace';

import * as styles from './OrgCategoryList.module.scss';

type PartialCategory = Partial<Category>;

interface Props {
  cssClass?: string;
  categoryList?: PartialCategory[];
  onClick?: (id: number) => void;
  onClickClose?: (id: number) => void;
}

const OrgCategoryList: FC<Props> = ({
  cssClass = '',
  categoryList = [],
  onClick = () => undefined,
  onClickClose = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgCategoryListBase, cssClass);

  return (
    <div className={classNames}>
      <ul className={styles.OrgCategoryListWrap}>
        {categoryList.map((item, i) => (
          <OrgUserListUnitCategory
            key={`OrgUserListUnitCategory${i}`}
            category={item}
            onClick={() => onClick(item?.id || 0)}
            onClickClose={() => onClickClose(item?.id || 0)}
          />
        ))}
      </ul>
    </div>
  );
};

export default OrgCategoryList;
