/* eslint-disable no-bitwise */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import thumbnailNoImage from '../../assets/images/thumbnail-noimage.png';
import OrgAudioPlayer from '../mediaParts/OrgAudioPlayer';

import * as styles from './OrgContentVoice.module.scss';

interface Props {
  cssClass?: string;
  thumbnail?: string;
  voice?: string;
}

const OrgContentVoice: FC<Props> = ({
  cssClass = '',
  thumbnail = '',
  voice = '',
}) => {
  const classNames = ClassNames(styles.OrgContentVoiceBase, cssClass);

  const image = thumbnail || thumbnailNoImage;

  return (
    <div className={classNames}>
      <div
        className={styles.OrgContentVoiceThumbnail}
        style={{
          background: `url(${image}) #fff no-repeat center center / cover`,
        }}
      />
      {/* <img src={thumbnail} alt="" /> */}
      <div className={styles.OrgContentVoicePlayerWrapper}>
        <OrgAudioPlayer src={voice} />
      </div>
    </div>
  );
};

export default OrgContentVoice;
