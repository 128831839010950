import React, { FC } from 'react';
import ClassNames from 'classnames';

import InputBox from '../../atoms/formParts/InputBox';
import LabelTitle from '../../atoms/title/TitleDiv';
import LabelTag from '../../atoms/label/LabelTag';

import * as styles from './McsInput.module.scss';

interface Props {
  value: string;
  inputType?: string;

  inputName?: string;
  placeholder?: string;
  maxLength?: number | undefined;
  cssClass?: string;

  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;

  isDisabled?: boolean;

  isError?: boolean;
  errorText?: string;

  labelTitle?: string | undefined;
  labelTag?: string | undefined;
}

const McsInput: FC<Props> = ({
  value = '',
  inputType = 'text',
  inputName = '',
  placeholder = '',
  maxLength = undefined,
  cssClass = '',

  onClick = () => undefined,
  onFocus = () => undefined,
  onBlur = () => undefined,
  onChange = () => undefined,

  isDisabled = false,

  isError = false,
  errorText = 'Error',

  labelTitle = '',
  labelTag = '',
}) => {
  const classNames = ClassNames(styles.McsInputBase, cssClass);

  return (
    <div className={classNames}>
      {labelTitle !== '' && (
        <div className={styles.McsInputLabel}>
          <LabelTitle text={labelTitle} />
          {labelTag !== '' && <LabelTag text={labelTag} />}
        </div>
      )}
      <InputBox
        value={value}
        type="Primary"
        inputType={inputType}
        inputName={inputName}
        maxLength={maxLength}
        placeholder={placeholder}
        onClick={onClick}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        isDisabled={isDisabled}
        isError={isError}
        errorText={errorText}
      />
      {maxLength && (
        <div className={styles.McsInputMaxLength}>
          {value.length}/{maxLength}
        </div>
      )}
    </div>
  );
};

export default McsInput;
