export const GET_READY = 'USER/GET_READY' as const;
export const GOT_READY = 'USER/GOT_READY' as const;

export const GET_USER_PROFILE_START = 'USER/GET_USER_PROFILE_START' as const;
export const GET_USER_PROFILE_SUCCEED = 'USER/GET_USER_PROFILE_SUCCEED' as const;
export const GET_USER_PROFILE_FAIL = 'USER/GET_USER_PROFILE_FAIL' as const;

export const GET_FOLLOWING_FANCLUB_LIST_START = 'USER/GET_FOLLOWING_FANCLUB_LIST_START' as const;
export const GET_FOLLOWING_FANCLUB_LIST_SUCCEED = 'USER/GET_FOLLOWING_FANCLUB_LIST_SUCCEED' as const;
export const GET_FOLLOWING_FANCLUB_LIST_FAIL = 'USER/GET_FOLLOWING_FANCLUB_LIST_FAIL' as const;

export const GET_LIKED_CONTENT_LIST_START = 'USER/GET_LIKED_CONTENT_LIST_START' as const;
export const GET_LIKED_CONTENT_LIST_SUCCEED = 'USER/GET_LIKED_CONTENT_LIST_SUCCEED' as const;
export const GET_LIKED_CONTENT_LIST_FAIL = 'USER/GET_LIKED_CONTENT_LIST_FAIL' as const;

export const GET_PICKUP_FANCLUB_LIST_START = 'USER/GET_PICKUP_FANCLUB_LIST_START' as const;
export const GET_PICKUP_FANCLUB_LIST_SUCCEED = 'USER/GET_PICKUP_FANCLUB_LIST_SUCCEED' as const;
export const GET_PICKUP_FANCLUB_LIST_FAIL = 'USER/GET_PICKUP_FANCLUB_LIST_FAIL' as const;

export const GET_RECOMMEND_FANCLUB_LIST_START = 'USER/GET_RECOMMEND_FANCLUB_LIST_START' as const;
export const GET_RECOMMEND_FANCLUB_LIST_SUCCEED = 'USER/GET_RECOMMEND_FANCLUB_LIST_SUCCEED' as const;
export const GET_RECOMMEND_FANCLUB_LIST_FAIL = 'USER/GET_RECOMMEND_FANCLUB_LIST_FAIL' as const;

export const FREEJOIN_FANCLUB_START = 'USER/FREEJOIN_FANCLUB_START' as const;
export const FREEJOIN_FANCLUB_SUCCEED = 'USER/FREEJOIN_FANCLUB_SUCCEED' as const;
export const FREEJOIN_FANCLUB_FAIL = 'USER/FREEJOIN_FANCLUB_FAIL' as const;

export const WITHDRAW_FANCLUB_START = 'USER/WITHDRAW_FANCLUB_START' as const;
export const WITHDRAW_FANCLUB_SUCCEED = 'USER/WITHDRAW_FANCLUB_SUCCEED' as const;
export const WITHDRAW_FANCLUB_FAIL = 'USER/WITHDRAW_FANCLUB_FAIL' as const;

export const CLEANUP = 'USER/CLEANUP' as const;
