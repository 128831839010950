/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import IconClose from '../iconParts/IconClose';
import * as styles from './ImageThumbnailWithClose.module.scss';

interface Props {
  src?: string;
  type?: string;
  size?: string;
  cssClass?: string;
  onClick?: () => void;
}

const Icon: FC<Props> = ({
  src = '',
  type = 'Primary',
  size = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.IconBase, cssClass, {
    [styles.IconPrimary]: type === 'Primary', // Primary
    [styles.IconSecondary]: type === 'Secondary', // Secondary
    [styles.IconSizePrimary]: size === 'Primary', // Primary
  });

  return (
    <div className={classNames}>
      <IconClose
        type="Secondary"
        cssClass={styles.IconCloseButton}
        onClick={onClick}
      />
      <img src={src} alt="" />
    </div>
  );
};

export default Icon;
