import { call, put, fork, takeLeading } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/contactConstants';
import { checkAndSetStatusError } from './authSagas';
import { submitContactEvent } from '../actions/contactActions';

import { Status } from '../interfaces/commonInterFace';
import {
  SubmitContactResponse,
  SubmitContactErrorResponse,
} from '../interfaces/contactInterFace';

const submitContactHandler = api.getPostFactory(true);
function* runSubmitContact(
  handler: typeof submitContactHandler,
  action: ReturnType<typeof submitContactEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: SubmitContactResponse = yield call(
      handler,
      data,
      '/contact',
    );

    if (!ReturnData) {
      yield put(submitContactEvent.fail({}));

      return;
    }

    yield put(submitContactEvent.succeed());
  } catch (error) {
    const res = error.response.data as SubmitContactErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(submitContactEvent.fail(res.errors));

      return;
    }

    yield put(submitContactEvent.fail({}));
  }
}
export function* submitContact(handler: typeof submitContactHandler) {
  yield takeLeading(ActionType.SUBMIT_CONTACT_START, runSubmitContact, handler);
}

export const contactSagas = [fork(submitContact, submitContactHandler)];
