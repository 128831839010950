/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import Linkify from 'react-linkify';
import ClassNames from 'classnames';
import * as styles from './ChatContent.module.scss';

interface Props {
  content?: string;
  type?: string;
  cssClass?: string;
}

const ChatContent: FC<Props> = ({ content = '', type = '', cssClass = '' }) => {
  const classNames = ClassNames(styles.ChatContentBase, cssClass, {
    [styles.Other]: type === 'other', // 自分以外の発言
    [styles.Myself]: type === 'myself', // 自分の発言
  });

  return (
    <div className={classNames}>
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
      >
        <p>{content}</p>
      </Linkify>
    </div>
  );
};

export default ChatContent;
