/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getReady, cleanup } from '../../actions/adminSalesActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';

import { TabMode } from '../../interfaces/salesInterFace';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminSales from '../../components/admin/adminSales';

const AdminSalesContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const [tabMode, setTabMode] = useState<TabMode>('SALES');

  const {
    ready: authReady,
    profile: {
      fanclubs: { manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const {
    ready,
    this_month_expected_sale_amount,
    salesForecastTitle,
    sales,
    total_sale_amount,
    transferForecastTitle,
    this_month_expected_transfer_amount,
    transferDescription,
    transferWaiting,
    transfers,
    total_transfer_amount,
    description,
  } = useSelector(selector.adminSales.getAdminSalesState);
  const fanclub = useMemo(
    () => manages.find(fanclub => fanclub.group_id === artistId),
    [artistId, manages],
  );

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleChangeTab = useCallback((tabMOde: TabMode) => {
    setTabMode(tabMOde);
  }, []);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!artistId) return;

    dispatch(getReady(artistId));
  }, [artistId, dispatch]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            売上情報 | {fanclub?.name ?? ''} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      <OrgLoader isLoaded={authReady && ready} />

      <AdminSales
        handleBack={handleBack}
        tabMode={tabMode}
        fanclubName={fanclub?.site_name}
        handleChangeTab={handleChangeTab}
        label={tabMode === 'SALES' ? salesForecastTitle : transferForecastTitle}
        amount={
          tabMode === 'SALES'
            ? this_month_expected_sale_amount
            : this_month_expected_transfer_amount
        }
        transferWaiting={transferWaiting}
        description={tabMode === 'SALES' ? description : transferDescription}
        summaryTitle={tabMode === 'SALES' ? '確定分合計' : '振込合計'}
        summaryPrice={
          tabMode === 'SALES' ? total_sale_amount : total_transfer_amount
        }
        list={tabMode === 'SALES' ? sales : transfers}
      />
    </>
  );
};

export default AdminSalesContainer;
