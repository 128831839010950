/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import TitleH2 from '../../atoms/title/TitleH2';
import TitleDiv from '../../atoms/title/TitleDiv';

import {
  Fanclub,
  FanclubInitialValue,
} from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgHeroHome.module.scss';

type Props = {
  fanclub?: Partial<Fanclub>;
  onClick?: () => void;
  cssClass?: string;
};

const OrgHeroHome: FC<Props> = ({
  fanclub = FanclubInitialValue,
  onClick = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgHeroBase, cssClass);

  return (
    <div
      className={classNames}
      style={{
        background: `url(${fanclub.cover_image}) no-repeat center / cover`,
      }}
      onClick={onClick}
    >
      <div className={styles.OrgHeroTitle}>
        <div className={styles.OrgHeroTitlePickUpContainer}>
          <p className={styles.OrgHeroTitlePickUp}>PICK UP</p>
        </div>
        <TitleH2 text={fanclub.name ?? ''} cssClass={styles.OrgHeroTitleMain} />
        <TitleDiv
          text={fanclub.site_name ?? 'OFFICIAL FAN CLUB'}
          cssClass={styles.OrgHeroTitleSub}
        />
      </div>
    </div>
  );
};

export default OrgHeroHome;
