import * as ActionType from './withdrawPaidFCConstants';
import { FreejoinFanclubParams } from '../interfaces/fanclubInterFace';

export const getReady = () =>
  ({
    type: ActionType.GET_READY,
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const withdrawPaidFCEvent = {
  start: (params: FreejoinFanclubParams) =>
    ({
      type: ActionType.WITHDRAW_PAID_FC_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.WITHDRAW_PAID_FC_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.WITHDRAW_PAID_FC_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // withdraw
  | ReturnType<typeof withdrawPaidFCEvent.start>
  | ReturnType<typeof withdrawPaidFCEvent.succeed>
  | ReturnType<typeof withdrawPaidFCEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
