/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import moment from 'moment';

import noImageUrl from '../../assets/images/blank-image.png';

import IconThumbnailRectangle from '../../atoms/iconThumbnail/IconThumbnailRectangle';
import McsIconLabelRoundQuaternary from '../../molecules/iconWithLabel/McsIconLabelRoundQuaternary';

import { DeepPartial } from '../../../../interfaces/commonInterFace';
import {
  ContentBlog,
  ContentBlogInitialValue,
} from '../../../../interfaces/contentInterFace';

import * as styles from './OrgFCListUnitRecentBlog.module.scss';

type Props = {
  blog?: DeepPartial<ContentBlog>;
  onClick?: (artistId: string, contentId: number) => void;
  cssClass?: string;
};

const OrgListUnit: FC<Props> = ({
  blog = ContentBlogInitialValue,
  onClick = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListUnitBase, cssClass);

  return (
    <li
      className={classNames}
      onClick={() => onClick(blog.fanclub?.group_id ?? '', blog.id ?? 0)}
    >
      <IconThumbnailRectangle
        src={blog.url ?? noImageUrl}
        blur={blog.open_area === 1}
        size="Secondary"
        cssClass={styles.OrgFCListUnitThumbnail}
      />
      <div className={styles.OrgFCListUnitBlog}>
        <McsIconLabelRoundQuaternary
          src={blog.user?.icon_image ?? undefined}
          mainText={blog.user?.nickname ?? '退会済みユーザー'}
          subText={`@${blog.user?.name_id ?? ''}`}
          cssClass={styles.OrgFCListUnitUser}
        />
        <p className={styles.OrgFCListUnitHeading}>
          {blog?.subject}
          <span>
            {blog?.release_at
              ? moment(blog.release_at).format('YYYY.MM.DD HH:mm')
              : ''}
          </span>
        </p>
      </div>
    </li>
  );
};

export default OrgListUnit;
