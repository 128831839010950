import React, { FC } from 'react';
import ClassNames from 'classnames';

import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';

import * as styles from './McsFooterBannerBack.module.scss';

interface Props {
  cssClass?: string;
  onClick?: () => void;
}

const McsButtonWithCheck: FC<Props> = ({
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsFooterBannerWrap, cssClass);

  return (
    <div className={classNames}>
      <ButtonRoundedCorners
        text="戻る"
        type="Tertiary"
        size="Secondary"
        onClick={onClick}
        cssClass={styles.McsFooterBannerButton}
      />
    </div>
  );
};

export default McsButtonWithCheck;
