/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './IconCount.module.scss';

interface Props {
  type?:
    | 'Primary'
    | 'Secondary'
    | 'Tertiary'
    | 'Quaternary'
    | 'Quinary'
    | 'Senary';
  cssClass?: string;
  countNumber?: number;
  onClick?: () => void;
}

const Icon: FC<Props> = ({
  type = 'Primary',
  cssClass = '',
  countNumber = 0,
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.IconBase, cssClass, {
    [styles.Primary]: type === 'Primary',
    [styles.Secondary]: type === 'Secondary',
    [styles.Tertiary]: type === 'Tertiary',
    [styles.Quaternary]: type === 'Quaternary',
    [styles.Quinary]: type === 'Quinary',
    [styles.Senary]: type === 'Senary',
  });

  return (
    <div className={classNames} onClick={onClick}>
      <span>{countNumber}</span>
    </div>
  );
};

export default Icon;
