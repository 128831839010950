import React, { FC } from 'react';
import ClassNames from 'classnames';

import McsIconLabelRectanglePrimary from '../../molecules/iconWithLabel/McsIconLabelRectanglePrimary';

import {
  ContentPhoto,
  ContentPhotoInitialValue,
} from '../../../../interfaces/contentInterFace';
import * as styles from './OrgFCListUnitRecentImages.module.scss';

interface Props {
  content?: Partial<ContentPhoto>;
  onClick?: () => void;
  cssClass?: string;
}

const OrgFCListUnitRecentImages: FC<Props> = ({
  content = ContentPhotoInitialValue,
  onClick = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListUnitBase, cssClass);

  return (
    <li className={classNames} key={content.id}>
      <McsIconLabelRectanglePrimary
        src={content.url || undefined}
        text={content.subject}
        blur={content.open_area === 1}
        onClick={onClick}
      />
    </li>
  );
};

export default OrgFCListUnitRecentImages;
