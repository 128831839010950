/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import McsIconLabelSquareTertiary from '../../molecules/iconWithLabel/McsIconLabelSquareTertiary';
import IconArrow from '../../atoms/iconParts/IconArrow';

import {
  Fanclub,
  FanclubInitialValue,
} from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgFCListUnitPrimary.module.scss';

type Props = {
  fanclub?: Partial<Fanclub>;
  onClick?: () => void;
  cssClass?: string;
};

const OrgListUnit: FC<Props> = ({
  fanclub = FanclubInitialValue,
  onClick = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListUnitBase, cssClass);

  return (
    <li className={classNames} key={fanclub.id} onClick={onClick}>
      <McsIconLabelSquareTertiary
        src={fanclub.cover_image}
        mainText={fanclub.name}
        subText={fanclub.site_name}
      />
      <IconArrow />
    </li>
  );
};

export default OrgListUnit;
