import * as ActionType from './followArtistConstants';
import {
  GetPickupFanclubListResult,
  GetRecommendFanclubListParams,
  FreejoinFanclubParams,
  WithdrawFanclubParams,
} from '../interfaces/fanclubInterFace';

export const getReady = () =>
  ({
    type: ActionType.GET_READY,
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getRecommendFanclubListEvent = {
  start: (params: GetRecommendFanclubListParams) =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetPickupFanclubListResult) =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_FAIL,
    } as const),
};

export const freejoinFanclubEvent = {
  start: (params: FreejoinFanclubParams) =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_FAIL,
    } as const),
};

export const withdrawFanclubEvent = {
  start: (params: WithdrawFanclubParams) =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fanclub list
  | ReturnType<typeof getRecommendFanclubListEvent.start>
  | ReturnType<typeof getRecommendFanclubListEvent.succeed>
  | ReturnType<typeof getRecommendFanclubListEvent.fail>
  // freejoin fanclub
  | ReturnType<typeof freejoinFanclubEvent.start>
  | ReturnType<typeof freejoinFanclubEvent.succeed>
  | ReturnType<typeof freejoinFanclubEvent.fail>
  // withdraw fanclub
  | ReturnType<typeof withdrawFanclubEvent.start>
  | ReturnType<typeof withdrawFanclubEvent.succeed>
  | ReturnType<typeof withdrawFanclubEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
