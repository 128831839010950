import { Reducer } from 'redux';
import { Actions } from '../actions/changeEmailActions';
import * as ActionType from '../actions/changeEmailConstants';
import { ChangeEmailErrors } from '../interfaces/emailInterFace';

type State = {
  loading: boolean;
  errors: ChangeEmailErrors;
  success: boolean;
};

export const initialState: State = {
  loading: false,
  errors: {},
  success: false,
};

const changeEmailReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // change email
    case ActionType.CHANGE_EMAIL_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.CHANGE_EMAIL_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ActionType.CHANGE_EMAIL_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default changeEmailReducer;
