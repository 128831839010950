import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import util from '../reducers/utilReducer';
import auth from '../reducers/authReducer';
import footer from '../reducers/footerReducer';
import news from '../reducers/newsReducer';
import newsDetails from '../reducers/newsDetailsReducer';
import signUp from '../reducers/signUpReducer';
import twitter from '../reducers/twitterReducer';
import withdraw from '../reducers/withdrawReducer';
import withdrawPaidFC from '../reducers/withdrawPaidFCReducer';
import reissuePasswordSendEmail from '../reducers/reissuePasswordSendEmailReducer';
import reissuePasswordResetPassword from '../reducers/reissuePasswordResetPasswordReducer';
import changePassword from '../reducers/changePasswordReducer';
import changeEmail from '../reducers/changeEmailReducer';
import changeCreditCard from '../reducers/changeCreditCardReducer';
import attachGenres from '../reducers/attachGenresReducer';
import followArtist from '../reducers/followArtistReducer';
import search from '../reducers/searchReducer';
import home from '../reducers/homeReducer';
import artist from '../reducers/artistReducer';
import content from '../reducers/contentReducer';
import comments from '../reducers/commentsReducer';
import likes from '../reducers/likesReducer';
import chat from '../reducers/chatReducer';
import followers from '../reducers/followersReducer';
import paidjoin from '../reducers/paidjoinReducer';
import user from '../reducers/userReducer';
import setting from '../reducers/settingReducer';
import editUserProfile from '../reducers/editUserProfileReducer';
import openFCRegister from '../reducers/openFCRegisterReducer';
import openFCComplete from '../reducers/openFCCompleteReducer';
import editFanclub from '../reducers/editFanclubReducer';
import adminMenu from '../reducers/adminMenuReducer';
import adminContents from '../reducers/adminContentsReducer';
import adminComments from '../reducers/adminCommentsReducer';
import adminBlogRegister from '../reducers/adminBlogRegisterReducer';
import adminMovieRegister from '../reducers/adminMovieRegisterReducer';
import adminPhotoRegister from '../reducers/adminPhotoRegisterReducer';
import adminVoiceRegister from '../reducers/adminVoiceRegisterReducer';
import adminCategoryEdit from '../reducers/adminCategoryEditReducer';
import adminCommunityEdit from '../reducers/adminCommunityEditReducer';
import adminMembers from '../reducers/adminMembersReducer';
import adminFollowers from '../reducers/adminFollowersReducer';
import adminBlocks from '../reducers/adminBlocksReducer';
import adminLikes from '../reducers/adminLikesReducer';
import adminApplyPaidFC from '../reducers/adminApplyPaidFCReducer';
import adminAdministrator from '../reducers/adminAdministratorReducer';
import contact from '../reducers/contactReducer';
import adminSales from '../reducers/adminSalesReducer';
import adminFCClosed from '../reducers/adminFCClosedReducer';

import rootSaga from '../sagas/index';

const rootReducer = combineReducers({
  util,
  auth,
  footer,
  news,
  newsDetails,
  signUp,
  twitter,
  withdraw,
  withdrawPaidFC,
  reissuePasswordSendEmail,
  reissuePasswordResetPassword,
  changePassword,
  changeEmail,
  changeCreditCard,
  attachGenres,
  followArtist,
  search,
  home,
  artist,
  content,
  comments,
  likes,
  chat,
  followers,
  paidjoin,
  user,
  setting,
  editUserProfile,
  openFCRegister,
  openFCComplete,
  editFanclub,
  adminMenu,
  adminContents,
  adminComments,
  adminBlogRegister,
  adminMovieRegister,
  adminPhotoRegister,
  adminVoiceRegister,
  adminCategoryEdit,
  adminCommunityEdit,
  adminMembers,
  adminFollowers,
  adminBlocks,
  adminLikes,
  adminApplyPaidFC,
  adminAdministrator,
  contact,
  adminSales,
  adminFCClosed,
});

const sagaMiddleWare = createSagaMiddleware();
const composeEnhancers =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleWare)),
);

export const runSaga = () => sagaMiddleWare.run(rootSaga);

export type AllState = ReturnType<typeof store.getState>;
