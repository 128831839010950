import React, { FC } from 'react';
import ClassNames from 'classnames';

import McsIconLabelRoundPrimary from '../../molecules/iconWithLabel/McsIconLabelSquarePrimary';

import krapImageUrl from '../../assets/images/krap-logo.svg';

import {
  Fanclub,
  FanclubInitialValue,
} from '../../../../interfaces/fanclubInterFace';
import * as styles from './OrgFCListUnitQuaternary.module.scss';

type Props = {
  fanclub?: Partial<Fanclub>;
  onClick?: () => void;
  cssClass?: string;
};

const OrgListUnit: FC<Props> = ({
  fanclub = FanclubInitialValue,
  onClick = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListUnitBase, cssClass);

  return (
    <li className={classNames} key={fanclub.id}>
      <McsIconLabelRoundPrimary
        src={fanclub.icon_image}
        errorSrc={krapImageUrl}
        mainText={fanclub.name}
        onClick={onClick}
      />
    </li>
  );
};

export default OrgListUnit;
