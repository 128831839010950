import * as ActionType from './contentConstants';
import {
  OpenArea,
  GetContentDetailsParams,
  GetContentDetailsResult,
  GetRecentContentPhotoListParams,
  GetRecentContentBlogListParams,
  GetContentPhotoListResult,
  GetContentBlogListResult,
  LikeParams,
  LikeResult,
  ReleaseLikeParams,
  ReleaseLikeResult,
} from '../interfaces/contentInterFace';
import {
  Fanclub,
  GetFanclubDetailsParams,
  GetFanclubDetailsResult,
  GetRecommendFanclubListParams,
  GetPickupFanclubListResult,
  FreejoinFanclubParams,
} from '../interfaces/fanclubInterFace';
import {
  getLikedUsersResult,
  getLikedUsersParams,
} from '../interfaces/likesInterFace';

// ready
export const getReady = (artistId: string, contentId: number) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId, contentId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

// get content details
export const getContentDetailsEvent = {
  start: (params: GetContentDetailsParams) =>
    ({
      type: ActionType.GET_CONTENT_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetContentDetailsResult) =>
    ({
      type: ActionType.GET_CONTENT_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: (fanclub: Fanclub, open_area: 0 | OpenArea) =>
    ({
      type: ActionType.GET_CONTENT_DETAILS_FAIL,
      payload: { fanclub, open_area },
    } as const),
};

// get fanclub details
export const getFanclubDetailsEvent = {
  start: (params: GetFanclubDetailsParams) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetFanclubDetailsResult) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_FAIL,
    } as const),
};

// get recent content photo list
export const getRecentContentPhotoListEvent = {
  start: (params: GetRecentContentPhotoListParams) =>
    ({
      type: ActionType.GET_RECENT_CONTENT_PHOTO_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetContentPhotoListResult) =>
    ({
      type: ActionType.GET_RECENT_CONTENT_PHOTO_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_RECENT_CONTENT_PHOTO_LIST_FAIL,
    } as const),
};

// get recent content blog list
export const getRecentContentBlogListEvent = {
  start: (params: GetRecentContentBlogListParams) =>
    ({
      type: ActionType.GET_RECENT_CONTENT_BLOG_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetContentBlogListResult) =>
    ({
      type: ActionType.GET_RECENT_CONTENT_BLOG_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_RECENT_CONTENT_BLOG_LIST_FAIL,
    } as const),
};

// get recommend fanclub list
export const getRecommendFanclubListEvent = {
  start: (params: GetRecommendFanclubListParams) =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetPickupFanclubListResult) =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_FAIL,
    } as const),
};

// like
export const likeEvent = {
  start: (
    params: LikeParams &
      ReleaseLikeParams & {
        type: 'like' | 'unlike';
      },
  ) =>
    ({
      type: ActionType.LIKE_START,
      payload: params,
    } as const),

  succeed: (result: LikeResult | ReleaseLikeResult) =>
    ({
      type: ActionType.LIKE_SUCCEED,
      payload: { result },
    } as const),

  fail: (
    params: LikeParams &
      ReleaseLikeParams & {
        type: 'like' | 'unlike';
      },
  ) =>
    ({
      type: ActionType.LIKE_FAIL,
      payload: { params },
    } as const),
};

// いいねしたユーザー取得
export const getLikedUsers = {
  start: (params: getLikedUsersParams) =>
    ({
      type: ActionType.GET_LIKED_USERS_START,
      payload: params,
    } as const),

  succeed: (result: getLikedUsersResult) =>
    ({
      type: ActionType.GET_LIKED_USERS_SUCCEED,
      payload: result,
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_LIKED_USERS_FAIL,
    } as const),
};

// freejoin fanclub
export const freejoinFanclubEvent = {
  start: (params: FreejoinFanclubParams, content_id: number) =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_START,
      payload: { params, contentId: content_id },
    } as const),

  succeed: () =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get content details
  | ReturnType<typeof getContentDetailsEvent.start>
  | ReturnType<typeof getContentDetailsEvent.succeed>
  | ReturnType<typeof getContentDetailsEvent.fail>
  // get fanclub details
  | ReturnType<typeof getFanclubDetailsEvent.start>
  | ReturnType<typeof getFanclubDetailsEvent.succeed>
  | ReturnType<typeof getFanclubDetailsEvent.fail>
  // get recent content photo list
  | ReturnType<typeof getRecentContentPhotoListEvent.start>
  | ReturnType<typeof getRecentContentPhotoListEvent.succeed>
  | ReturnType<typeof getRecentContentPhotoListEvent.fail>
  // get recent content blog list
  | ReturnType<typeof getRecentContentBlogListEvent.start>
  | ReturnType<typeof getRecentContentBlogListEvent.succeed>
  | ReturnType<typeof getRecentContentBlogListEvent.fail>
  // get recommend fanclub list
  | ReturnType<typeof getRecommendFanclubListEvent.start>
  | ReturnType<typeof getRecommendFanclubListEvent.succeed>
  | ReturnType<typeof getRecommendFanclubListEvent.fail>
  // like
  | ReturnType<typeof likeEvent.start>
  | ReturnType<typeof likeEvent.succeed>
  | ReturnType<typeof likeEvent.fail>
  // いいねしたユーザー取得
  | ReturnType<typeof getLikedUsers.start>
  | ReturnType<typeof getLikedUsers.succeed>
  | ReturnType<typeof getLikedUsers.fail>
  // freejoin fanclub
  | ReturnType<typeof freejoinFanclubEvent.start>
  | ReturnType<typeof freejoinFanclubEvent.succeed>
  | ReturnType<typeof freejoinFanclubEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
