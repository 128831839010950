export const GET_READY = 'ADMIN_MEMBERS/GET_READY' as const;
export const GOT_READY = 'ADMIN_MEMBERS/GOT_READY' as const;

export const GET_FANCLUB_DETAILS_START = 'ADMIN_MEMBERS/GET_FANCLUB_DETAILS_START' as const;
export const GET_FANCLUB_DETAILS_SUCCEED = 'ADMIN_MEMBERS/GET_FANCLUB_DETAILS_SUCCEED' as const;
export const GET_FANCLUB_DETAILS_FAIL = 'ADMIN_MEMBERS/GET_FANCLUB_DETAILS_FAIL' as const;

export const ADD_FC_MEMBERS_START = 'ADMIN_MEMBERS/ADD_FC_MEMBERS_START' as const;
export const ADD_FC_MEMBERS_SUCCEED = 'ADMIN_MEMBERS/ADD_FC_MEMBERS_SUCCEED' as const;
export const ADD_FC_MEMBERS_FAIL = 'ADMIN_MEMBERS/ADD_FC_MEMBERS_FAIL' as const;

export const WITHDRAW_FANCLUB_START = 'ADMIN_MEMBERS/WITHDRAW_FANCLUB_START' as const;
export const WITHDRAW_FANCLUB_SUCCEED = 'ADMIN_MEMBERS/WITHDRAW_FANCLUB_SUCCEED' as const;
export const WITHDRAW_FANCLUB_FAIL = 'ADMIN_MEMBERS/WITHDRAW_FANCLUB_FAIL' as const;

export const DELETE_FC_MEMBERS_START = 'ADMIN_MEMBERS/DELETE_FC_MEMBERS_START' as const;
export const DELETE_FC_MEMBERS_SUCCEED = 'ADMIN_MEMBERS/DELETE_FC_MEMBERS_SUCCEED' as const;
export const DELETE_FC_MEMBERS_FAIL = 'ADMIN_MEMBERS/DELETE_FC_MEMBERS_FAIL' as const;

export const CHANGE_FC_MEMBERS_AUTHORITY_START = 'ADMIN_MEMBERS/DELETE_FC_MEMBERS_AUTHORITY_START' as const;
export const CHANGE_FC_MEMBERS_AUTHORITY_SUCCEED = 'ADMIN_MEMBERS/DELETE_FC_MEMBERS_AUTHORITY_SUCCEED' as const;
export const CHANGE_FC_MEMBERS_AUTHORITY_FAIL = 'ADMIN_MEMBERS/DELETE_FC_MEMBERS_AUTHORITY_FAIL' as const;

export const SEARCH_USER_LIST_START = 'ADMIN_MEMBERS/SEARCH_USER_LIST_START' as const;
export const SEARCH_USER_LIST_SUCCEED = 'ADMIN_MEMBERS/SEARCH_USER_LIST_SUCCEED' as const;
export const SEARCH_USER_LIST_FAIL = 'ADMIN_MEMBERS/SEARCH_USER_LIST_FAIL' as const;

export const CLEANUP = 'ADMIN_MEMBERS/CLEANUP' as const;
