/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import McsIconLabelRoundTertiary from '../../molecules/iconWithLabel/McsIconLabelRoundTertiary';
import IconArrow from '../../atoms/iconParts/IconArrow';

import noUserImageUrl from '../../assets/images/icon-nouser.png';

import { User } from '../../../../interfaces/userInterFace';

import * as styles from './OrgUserListUnitMemberTertiary.module.scss';

type PartialUser = Partial<User>;

interface Props {
  cssClass?: string;
  user?: PartialUser | null;
  onClick?: () => void;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  user = null,
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgUserListUnitBase, cssClass);

  return (
    <li className={classNames} key={user?.id} onClick={onClick}>
      <div className={styles.OrgUserListUnitUser}>
        <McsIconLabelRoundTertiary
          src={user?.icon_image || noUserImageUrl}
          mainText={user?.nickname || '退会済みユーザー'}
          subText={`@${user?.name_id || ''}`}
          cssClass={styles.OrgUserListUnitUser}
        />
        <IconArrow type="Tertiary" />
      </div>
    </li>
  );
};

export default OrgListUnit;
