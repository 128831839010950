/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './LinkText.module.scss';

interface Props {
  text: string;
  type?: string;
  cssClass?: string;
  onClick?: () => void;
}

const Link: FC<Props> = ({
  text = 'Link',
  type = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.LinkBase, cssClass, {
    [styles.LinkPrimary]: type === 'Primary', // Primary
    [styles.LinkSecondary]: type === 'Secondary', // Secondary
  });

  return (
    <a
      href="#"
      className={classNames}
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      {text}
    </a>
  );
};

export default Link;
