/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  cleanup,
  closedFanclubEvent,
} from '../../actions/adminFCClosedActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminClose from '../../components/admin/adminFCClosed';

const AdminFCClosedContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const {
    ready: authReady,
    profile: {
      fanclubs: { manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const { ready, loading, detail } = useSelector(
    selector.adminFCClosed.getAdminFCClosedState,
  );
  const [agreed, setAgreed] = useState<boolean>(false);

  // 戻るボタンの処理
  const handleBack = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'MENU'));
  }, [artistId, history]);

  // 同意クリック時の処理
  const handleToggleAgreed = useCallback(() => {
    if (agreed) {
      setAgreed(false);
    } else {
      setAgreed(true);
    }
  }, [agreed]);

  // コミュニティ閉鎖クリック時の処理
  const handleFCClosed = useCallback(() => {
    if (!authReady || !agreed || !artistId) return;
    dispatch(
      closedFanclubEvent.start({
        artistId,
      }),
    );
  }, [authReady, agreed, dispatch, artistId]);

  // フッター設定＆GA設定
  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);
  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady || !artistId) return;

    const fanclub = manages.find(fanclub => fanclub.group_id === artistId);

    // 有料FCの場合メニュー画面に戻す
    if (fanclub?.paid_status !== 1) {
      history.push(PATH.getAdminPath(artistId, 'MENU'));
    }

    // FCの管理者以外だった場合、403画面に
    if (!fanclub || fanclub?.pivot?.type !== 3) {
      history.replace(PATH.Forbidden);

      return;
    }

    dispatch(getReady(artistId));
  }, [authReady, dispatch, artistId, history, manages]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            コミュニティ設定 | {detail.fanclub.name} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      <AdminClose
        fanclub={detail.fanclub}
        agreed={agreed}
        handleBack={handleBack}
        handleToggleAgreed={handleToggleAgreed}
        handleFCClosed={handleFCClosed}
      />
    </>
  );
};

export default AdminFCClosedContainer;
