import * as ActionType from './adminApplyPaidFCConstants';
import {
  ApplyPaidFCParams,
  ApplyPaidFCErrors,
} from '../interfaces/fanclubInterFace';
import { Address, GetAddressParams } from '../interfaces/addressInterFace';

export const getReady = () =>
  ({
    type: ActionType.GET_READY,
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getAddressEvent = {
  start: (params: GetAddressParams) =>
    ({
      type: ActionType.GET_ADDRESS_START,
      payload: params,
    } as const),

  succeed: (addresses: Address[]) =>
    ({
      type: ActionType.GET_ADDRESS_SUCCEED,
      payload: addresses,
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_ADDRESS_FAIL,
    } as const),
};

export const applyPaidFCEvent = {
  start: (params: ApplyPaidFCParams) =>
    ({
      type: ActionType.APPLY_PAID_FC_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.APPLY_PAID_FC_SUCCEED,
    } as const),

  fail: (errors: ApplyPaidFCErrors) =>
    ({
      type: ActionType.APPLY_PAID_FC_FAIL,
      payload: { errors },
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get address
  | ReturnType<typeof getAddressEvent.start>
  | ReturnType<typeof getAddressEvent.succeed>
  | ReturnType<typeof getAddressEvent.fail>
  // get fanclub details
  | ReturnType<typeof applyPaidFCEvent.start>
  | ReturnType<typeof applyPaidFCEvent.succeed>
  | ReturnType<typeof applyPaidFCEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
