import React, { FC } from 'react';
import ClassNames from 'classnames';
import TitleH2 from '../common/atoms/title/TitleH2';
import ButtonSquare from '../common/atoms/buttonBasic/ButtonSquare';
import McsInputPrimary from '../common/molecules/formParts/McsInputPrimary';
import * as styles from './resetPassword.module.scss';

import { ResetPasswordValidationErrors } from '../../interfaces/passwordInterFace';

interface Props {
  content?: string;
  valuePass?: string;
  valuePassConf?: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  onRegisterEvent?: () => void;
  errors?: ResetPasswordValidationErrors;
}

const Component: FC<Props> = ({
  valuePass = '',
  valuePassConf = '',
  onChange = () => undefined,
  onRegisterEvent = () => undefined,
  errors = {
    token: '',
    email: '',
    password: '',
    password_confirmation: '',
  },
}) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      <TitleH2 text="パスワード再発行" cssClass={styles.title} />
      <div className={styles.read}>
        <p>
          新しいパスワードを登録します。
          <br />
          新しいパスワードを入力し、「パスワード登録」ボタンをクリックしてください。
        </p>
      </div>

      <McsInputPrimary
        value={valuePass}
        inputType="password"
        inputName="password"
        placeholder="新しいパスワードを入力"
        labelTitle="新しいパスワード"
        onChange={onChange}
        cssClass={styles.inputBox}
        isError={!!errors.password}
        errorText={errors.password}
      />

      <McsInputPrimary
        value={valuePassConf}
        inputType="password"
        inputName="passwordConf"
        placeholder="新しいパスワードを入力"
        labelTitle="新しいパスワード（確認用）"
        onChange={onChange}
        isError={!!errors.password_confirmation}
        errorText={errors.password_confirmation}
      />

      <div className={styles.buttonWrap}>
        <ButtonSquare
          text="パスワード再登録"
          type="Primary"
          size="Primary"
          onClick={onRegisterEvent}
          cssClass={styles.button}
        />
      </div>
    </div>
  );
};

export default Component;
