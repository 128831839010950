import React, { FC } from 'react';
import ClassNames from 'classnames';

import TitleH3 from '../../atoms/title/TitleH3';
import OrgFCListUnitRecentBlog from '../listUnitParts/OrgFCListUnitRecentBlog';

import { DeepPartial } from '../../../../interfaces/commonInterFace';
import { ContentBlog } from '../../../../interfaces/contentInterFace';

import * as styles from './OrgRecentBlogFCList.module.scss';

type Props = {
  blogList?: DeepPartial<ContentBlog>[];
  onClickContent?: (artistId: string, contentId: number) => void;
  cssClass?: string;
};

const OrgRecentBlogFCList: FC<Props> = ({
  blogList = [],
  onClickContent = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListBase, cssClass);

  return (
    <div className={classNames}>
      <TitleH3
        text="このアーティストの最近のブログ"
        cssClass={styles.OrgFCListTitle}
      />
      <ul className={styles.OrgFCListWrap}>
        {blogList.map((item, i) => (
          <OrgFCListUnitRecentBlog
            blog={item}
            onClick={onClickContent}
            key={`OrgFCListUnitRecentBlog${i.toString()}`}
          />
        ))}
      </ul>
    </div>
  );
};

export default OrgRecentBlogFCList;
