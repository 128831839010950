export const GET_READY = 'ADMIN_CONTENTS/GET_READY' as const;
export const GOT_READY = 'ADMIN_CONTENTS/GOT_READY' as const;

export const GET_FC_MEMBER_LIST_START = 'ADMIN_CONTENTS/GET_FC_MEMBER_LIST_START' as const;
export const GET_FC_MEMBER_LIST_SUCCEED = 'ADMIN_CONTENTS/GET_FC_MEMBER_LIST_SUCCEED' as const;
export const GET_FC_MEMBER_LIST_FAIL = 'ADMIN_CONTENTS/GET_FC_MEMBER_LIST_FAIL' as const;

export const GET_FC_CATEGORY_LIST_START = 'ADMIN_CONTENTS/GET_FC_CATEGORY_LIST_START' as const;
export const GET_FC_CATEGORY_LIST_SUCCEED = 'ADMIN_CONTENTS/GET_FC_CATEGORY_LIST_SUCCEED' as const;
export const GET_FC_CATEGORY_LIST_FAIL = 'ADMIN_CONTENTS/GET_FC_CATEGORY_LIST_FAIL' as const;

export const GET_CONTENT_LIST_START = 'ADMIN_CONTENTS/GET_CONTENT_LIST_START' as const;
export const GET_CONTENT_LIST_SUCCEED = 'ADMIN_CONTENTS/GET_CONTENT_LIST_SUCCEED' as const;
export const GET_CONTENT_LIST_FAIL = 'ADMIN_CONTENTS/GET_CONTENT_LIST_FAIL' as const;

export const DELETE_CONTENT_START = 'ADMIN_CONTENTS/DELETE_CONTENT_START' as const;
export const DELETE_CONTENT_SUCCEED = 'ADMIN_CONTENTS/DELETE_CONTENT_SUCCEED' as const;
export const DELETE_CONTENT_FAIL = 'ADMIN_CONTENTS/DELETE_CONTENT_FAIL' as const;

export const CLEANUP = 'ADMIN_CONTENTS/CLEANUP' as const;
