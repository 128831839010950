import * as ActionType from './userConstants';
import {
  GetUserProfileParams,
  GetUserProfileResult,
} from '../interfaces/userInterFace';
import {
  GetFollowingFanclubListParams,
  GetFollowingFanclubListResult,
  GetPickupFanclubListParams,
  GetPickupFanclubListResult,
  GetRecommendFanclubListParams,
  GetRecommendFanclubListResult,
  FreejoinFanclubParams,
  WithdrawFanclubParams,
} from '../interfaces/fanclubInterFace';
import {
  GetLikedContentListParams,
  GetLikedContentListResult,
} from '../interfaces/contentInterFace';

export const getReady = (userId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: { userId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getUserProfileEvent = {
  start: (params: GetUserProfileParams) =>
    ({
      type: ActionType.GET_USER_PROFILE_START,
      payload: params,
    } as const),

  succeed: (result: GetUserProfileResult) =>
    ({
      type: ActionType.GET_USER_PROFILE_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_USER_PROFILE_FAIL,
    } as const),
};

export const getFollowingFanclubListEvent = {
  start: (params: GetFollowingFanclubListParams) =>
    ({
      type: ActionType.GET_FOLLOWING_FANCLUB_LIST_START,
      payload: params,
    } as const),

  succeed: (
    params: GetFollowingFanclubListParams,
    result: GetFollowingFanclubListResult,
  ) =>
    ({
      type: ActionType.GET_FOLLOWING_FANCLUB_LIST_SUCCEED,
      payload: { params, result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FOLLOWING_FANCLUB_LIST_FAIL,
    } as const),
};

export const getLikedContentListEvent = {
  start: (params: GetLikedContentListParams) =>
    ({
      type: ActionType.GET_LIKED_CONTENT_LIST_START,
      payload: params,
    } as const),

  succeed: (
    params: GetLikedContentListParams,
    result: GetLikedContentListResult,
  ) =>
    ({
      type: ActionType.GET_LIKED_CONTENT_LIST_SUCCEED,
      payload: { params, result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_LIKED_CONTENT_LIST_FAIL,
    } as const),
};

export const getPickupFanclubListEvent = {
  start: (params: GetPickupFanclubListParams) =>
    ({
      type: ActionType.GET_PICKUP_FANCLUB_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetPickupFanclubListResult) =>
    ({
      type: ActionType.GET_PICKUP_FANCLUB_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_PICKUP_FANCLUB_LIST_FAIL,
    } as const),
};

export const getRecommendFanclubListEvent = {
  start: (params: GetRecommendFanclubListParams) =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetRecommendFanclubListResult) =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_FAIL,
    } as const),
};

export const freejoinFanclubEvent = {
  start: (params: FreejoinFanclubParams) =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_FAIL,
    } as const),
};

export const withdrawFanclubEvent = {
  start: (params: WithdrawFanclubParams) =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_FAIL,
    } as const),
};

export const cleanup = () => ({
  type: ActionType.CLEANUP,
});

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get user profile
  | ReturnType<typeof getUserProfileEvent.start>
  | ReturnType<typeof getUserProfileEvent.succeed>
  | ReturnType<typeof getUserProfileEvent.fail>
  // get following fanclub list
  | ReturnType<typeof getFollowingFanclubListEvent.start>
  | ReturnType<typeof getFollowingFanclubListEvent.succeed>
  | ReturnType<typeof getFollowingFanclubListEvent.fail>
  // get liked content list
  | ReturnType<typeof getLikedContentListEvent.start>
  | ReturnType<typeof getLikedContentListEvent.succeed>
  | ReturnType<typeof getLikedContentListEvent.fail>
  // get pickup fanclub list
  | ReturnType<typeof getPickupFanclubListEvent.start>
  | ReturnType<typeof getPickupFanclubListEvent.succeed>
  | ReturnType<typeof getPickupFanclubListEvent.fail>
  // get recommend fanclub list
  | ReturnType<typeof getRecommendFanclubListEvent.start>
  | ReturnType<typeof getRecommendFanclubListEvent.succeed>
  | ReturnType<typeof getRecommendFanclubListEvent.fail>
  // freejoin fanclub
  | ReturnType<typeof freejoinFanclubEvent.start>
  | ReturnType<typeof freejoinFanclubEvent.succeed>
  | ReturnType<typeof freejoinFanclubEvent.fail>
  // withdraw fanclub
  | ReturnType<typeof withdrawFanclubEvent.start>
  | ReturnType<typeof withdrawFanclubEvent.succeed>
  | ReturnType<typeof withdrawFanclubEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
