/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC, useMemo } from 'react';
import ClassNames from 'classnames';
import moment from 'moment';

import IconThumbnailSquare from '../../atoms/iconThumbnail/IconThumbnailSquare';

import * as styles from './OrgNewsUnit.module.scss';
import {
  Notification,
  NotificationInitialValue,
  NotificationType,
} from '../../../../interfaces/newsInterFace';

interface Props {
  cssClass?: string;
  notification?: Notification;
  onClick?: (id: number) => void;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  notification = NotificationInitialValue,
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(
    styles.OrgNewsContentContainer,
    {
      [styles.OrgNewsContentContainerUnread]: !notification.pivot.is_read,
    },
    cssClass,
  );

  const renderTitle = useMemo(() => {
    if (notification.type === NotificationType.KRAP) {
      return (
        <p className={styles.OrgNewsContentMainText}>{notification.title}</p>
      );
    }

    if (notification.type === NotificationType.AddMember) {
      return (
        <p className={styles.OrgNewsContentMainText}>
          <span>{notification.fanclub_name}</span>
          があなたを管理メンバーに招待しました。
        </p>
      );
    }

    if (notification.type === NotificationType.NoticeOfCharge) {
      return (
        <p className={styles.OrgNewsContentMainText}>
          <span>{notification.fanclub_name}</span>
          が有料コミュニティになりました。有料登録すると、限定コンテンツをお楽しみいただけます。
        </p>
      );
    }

    if (notification.type === NotificationType.ApplicationApproval) {
      return (
        <p className={styles.OrgNewsContentMainText}>
          <span>{notification.fanclub_name}</span>のコミュニティ有料化申請が
          <span>承認</span>
          されました。続きまして、振込先口座の設定をお願いいたします。
        </p>
      );
    }

    if (notification.type === NotificationType.ApplicationRejected) {
      return (
        <p className={styles.OrgNewsContentMainText}>
          <span>{notification.fanclub_name}</span>のコミュニティ有料化申請が
          <span>否認</span>
          されました。申請内容をご確認の上、再度申請をお願いいたします。
        </p>
      );
    }

    if (notification.type === NotificationType.likeNotification) {
      return (
        <p className={styles.OrgNewsContentMainText}>
          <span>{notification.user_name}</span>
          {notification.total > 0
            ? `と他${notification.total.toLocaleString()}人`
            : ''}
          が<span>{notification.content_subject}</span>
          にいいねしました。
        </p>
      );
    }

    if (notification.type === NotificationType.commentNotification) {
      return (
        <p className={styles.OrgNewsContentMainText}>
          <span>{notification.user_name}</span>
          {notification.total > 0
            ? `と他${notification.total.toLocaleString()}人`
            : ''}
          が<span>{notification.content_subject}</span>
          にコメントしました。
        </p>
      );
    }

    return null;
  }, [notification]);

  const date = useMemo(() => {
    if (!notification.pivot.release_at) return '';
    const today = moment();

    const notificationDate = moment(notification.pivot.release_at);

    const yearDiff = today.diff(notificationDate, 'year');
    if (yearDiff) return `${yearDiff}年前`;

    const monthDiff = today.diff(notificationDate, 'month');
    if (monthDiff) return `${monthDiff}ヶ月前`;

    const dayDiff = today.diff(notificationDate, 'day');
    if (dayDiff) return `${dayDiff}日前`;

    const hourDiff = today.diff(notificationDate, 'hour');
    if (hourDiff) return `${hourDiff}時間前`;

    const minuteDiff = today.diff(notificationDate, 'minute');
    if (minuteDiff) return `${minuteDiff}分前`;

    const secondDiff = today.diff(notificationDate, 'second');

    return `${secondDiff}秒前`;
  }, [notification.pivot.release_at]);

  if (renderTitle === null) {
    return null;
  }

  return (
    <li className={classNames} onClick={() => onClick(notification.pivot.id)}>
      <IconThumbnailSquare
        src={notification.icon_image || ''}
        type="Primary"
        size="Quaternary"
        cssClass={styles.OrgNewsContentKRAPIcon}
      />
      <div className={styles.OrgNewsContentMiddleContainer}>
        {renderTitle}
        <p className={styles.OrgNewsContentDateTime}>{date}</p>
      </div>
      <div className={styles.OrgNewsContentMarkWrapper}>
        {notification.pivot.is_read ? null : (
          <div className={styles.OrgNewsContentMark} />
        )}
      </div>
    </li>
  );
};

export default OrgListUnit;
