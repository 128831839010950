import * as ActionType from './adminAdministratorConstants';
import {
  GetAdministratorParams,
  GetAdministratorResult,
  UpdateAdministratorParams,
  UpdateAdministratorErrors,
} from '../interfaces/administratorInterFace';
import {
  GetBankParams,
  GetBankBranchParams,
  GetBankAccountParams,
  GetBankAccountResult,
  SearchBankParams,
  SearchBankBranchParams,
  Bank,
  BankBranch,
  RegisterBankAccountParams,
  RegisterBankAccountErrors,
} from '../interfaces/bankInterFace';
import { Address, GetAddressParams } from '../interfaces/addressInterFace';

export const getReady = (artistId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getCurrentBankEvent = {
  start: (params: GetBankParams) =>
    ({
      type: ActionType.GET_CURRENT_BANK_START,
      payload: params,
    } as const),

  succeed: (bank: Bank) =>
    ({
      type: ActionType.GET_CURRENT_BANK_SUCCEED,
      payload: bank,
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_CURRENT_BANK_FAIL,
    } as const),
};

export const getCurrentBankBranchEvent = {
  start: (params: GetBankBranchParams) =>
    ({
      type: ActionType.GET_CURRENT_BANK_BRANCH_START,
      payload: params,
    } as const),

  succeed: (bankBranch: BankBranch) =>
    ({
      type: ActionType.GET_CURRENT_BANK_BRANCH_SUCCEED,
      payload: bankBranch,
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_CURRENT_BANK_BRANCH_FAIL,
    } as const),
};

export const getAdministratorEvent = {
  start: (params: GetAdministratorParams) =>
    ({
      type: ActionType.GET_ADMINISTRATOR_START,
      payload: params,
    } as const),

  succeed: (result: GetAdministratorResult) =>
    ({
      type: ActionType.GET_ADMINISTRATOR_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_ADMINISTRATOR_FAIL,
    } as const),
};

export const getBankAccountEvent = {
  start: (params: GetBankAccountParams) =>
    ({
      type: ActionType.GET_BANK_ACCOUNT_START,
      payload: params,
    } as const),

  succeed: (result: GetBankAccountResult) =>
    ({
      type: ActionType.GET_BANK_ACCOUNT_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_BANK_ACCOUNT_FAIL,
    } as const),
};

export const getAddressEvent = {
  start: (params: GetAddressParams) =>
    ({
      type: ActionType.GET_ADDRESS_START,
      payload: params,
    } as const),

  succeed: (addresses: Address[]) =>
    ({
      type: ActionType.GET_ADDRESS_SUCCEED,
      payload: addresses,
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_ADDRESS_FAIL,
    } as const),
};

export const searchBankEvent = {
  start: (params: SearchBankParams) =>
    ({
      type: ActionType.SEARCH_BANK_START,
      payload: params,
    } as const),

  succeed: (banks: Bank[]) =>
    ({
      type: ActionType.SEARCH_BANK_SUCCEED,
      payload: banks,
    } as const),

  fail: () =>
    ({
      type: ActionType.SEARCH_BANK_FAIL,
    } as const),
};

export const searchBankBranchEvent = {
  start: (params: SearchBankBranchParams) =>
    ({
      type: ActionType.SEARCH_BANK_BRANCH_START,
      payload: params,
    } as const),

  succeed: (bankBranches: BankBranch[]) =>
    ({
      type: ActionType.SEARCH_BANK_BRANCH_SUCCEED,
      payload: bankBranches,
    } as const),

  fail: () =>
    ({
      type: ActionType.SEARCH_BANK_BRANCH_FAIL,
    } as const),
};

export const updateAdministratorEvent = {
  start: (params: UpdateAdministratorParams) =>
    ({
      type: ActionType.UPDATE_ADMINISTRATOR_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.UPDATE_ADMINISTRATOR_SUCCEED,
    } as const),

  fail: (errors: UpdateAdministratorErrors) =>
    ({
      type: ActionType.UPDATE_ADMINISTRATOR_FAIL,
      payload: { errors },
    } as const),
};

export const createBankAccountEvent = {
  start: (params: RegisterBankAccountParams) =>
    ({
      type: ActionType.CREATE_BANK_ACCOUNT_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.CREATE_BANK_ACCOUNT_SUCCEED,
    } as const),

  fail: (
    params: RegisterBankAccountParams,
    errors: RegisterBankAccountErrors,
  ) =>
    ({
      type: ActionType.CREATE_BANK_ACCOUNT_FAIL,
      payload: { params, errors },
    } as const),
};

export const updateBankAccountEvent = {
  start: (params: RegisterBankAccountParams) =>
    ({
      type: ActionType.UPDATE_BANK_ACCOUNT_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.UPDATE_BANK_ACCOUNT_SUCCEED,
    } as const),

  fail: (
    params: RegisterBankAccountParams,
    errors: RegisterBankAccountErrors,
  ) =>
    ({
      type: ActionType.UPDATE_BANK_ACCOUNT_FAIL,
      payload: { params, errors },
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get current bank
  | ReturnType<typeof getCurrentBankEvent.start>
  | ReturnType<typeof getCurrentBankEvent.succeed>
  | ReturnType<typeof getCurrentBankEvent.fail>
  // get current bank branch
  | ReturnType<typeof getCurrentBankBranchEvent.start>
  | ReturnType<typeof getCurrentBankBranchEvent.succeed>
  | ReturnType<typeof getCurrentBankBranchEvent.fail>
  // get administrator
  | ReturnType<typeof getAdministratorEvent.start>
  | ReturnType<typeof getAdministratorEvent.succeed>
  | ReturnType<typeof getAdministratorEvent.fail>
  // get bank account
  | ReturnType<typeof getBankAccountEvent.start>
  | ReturnType<typeof getBankAccountEvent.succeed>
  | ReturnType<typeof getBankAccountEvent.fail>
  // get address
  | ReturnType<typeof getAddressEvent.start>
  | ReturnType<typeof getAddressEvent.succeed>
  | ReturnType<typeof getAddressEvent.fail>
  // search bank
  | ReturnType<typeof searchBankEvent.start>
  | ReturnType<typeof searchBankEvent.succeed>
  | ReturnType<typeof searchBankEvent.fail>
  // search bank branch
  | ReturnType<typeof searchBankBranchEvent.start>
  | ReturnType<typeof searchBankBranchEvent.succeed>
  | ReturnType<typeof searchBankBranchEvent.fail>
  // update administrator
  | ReturnType<typeof updateAdministratorEvent.start>
  | ReturnType<typeof updateAdministratorEvent.succeed>
  | ReturnType<typeof updateAdministratorEvent.fail>
  // create bank account
  | ReturnType<typeof createBankAccountEvent.start>
  | ReturnType<typeof createBankAccountEvent.succeed>
  | ReturnType<typeof createBankAccountEvent.fail>
  // update bank account
  | ReturnType<typeof updateBankAccountEvent.start>
  | ReturnType<typeof updateBankAccountEvent.succeed>
  | ReturnType<typeof updateBankAccountEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
