import React, { FC } from 'react';
import ClassNames from 'classnames';

import InputBox from '../../atoms/formParts/InputBox';
import LabelTitle from '../../atoms/title/TitleDiv';
import LabelTag from '../../atoms/label/LabelTag';

import * as styles from './McsSelect.module.scss';

interface Props {
  value1: string;
  value2: string;

  inputName1?: string;
  inputName2?: string;

  placeholder?: string;
  maxLength?: number | undefined;
  cssClass?: string;
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: any) => void;

  isDisabled?: boolean;

  isError?: boolean;
  errorText?: string;

  labelTitle?: string | undefined;
  labelSubTitle?: string | undefined;
  labelTag?: string | undefined;
}

const McsSelect: FC<Props> = ({
  value1 = '',
  value2 = '',

  inputName1 = '',
  inputName2 = '',

  cssClass = '',

  onClick = () => undefined,
  onFocus = () => undefined,
  onBlur = () => undefined,
  onChange = () => undefined,

  isDisabled = false,

  isError = false,
  errorText = 'Error',

  labelTitle = '',
  labelSubTitle = '',
  labelTag = '',
}) => {
  const classNames = ClassNames(styles.McsSelectBase, cssClass);

  return (
    <div className={classNames}>
      {labelTitle !== '' && (
        <div className={styles.McsSelectLabel}>
          <LabelTitle text={labelTitle} />
          {labelTag !== '' && <LabelTag text={labelTag} />}
        </div>
      )}
      {labelSubTitle !== '' && (
        <div className={styles.McsSelectSubLabel}>
          <LabelTitle text={labelSubTitle} />
        </div>
      )}
      <div className={styles.McsSelectFlex}>
        <InputBox
          value={value1}
          type="Quinary"
          inputType="date"
          inputName={inputName1}
          min="0000-01-01"
          max="9999-12-31"
          onChange={onChange}
          cssClass={styles.McsSelectFlexItemHalf}
        />
        <InputBox
          value={value2}
          type="Quinary"
          inputType="time"
          inputName={inputName2}
          onChange={onChange}
          cssClass={styles.McsSelectFlexItemHalf}
        />
      </div>
      {isError && <p className={styles.McsSelectBoxErrorText}>{errorText}</p>}
    </div>
  );
};

export default McsSelect;
