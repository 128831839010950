import * as ActionType from './changeEmailConstants';
import {
  ChangeEmailParams,
  ChangeEmailErrors,
} from '../interfaces/emailInterFace';

export const changeEmailEvent = {
  start: (params: ChangeEmailParams) =>
    ({
      type: ActionType.CHANGE_EMAIL_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.CHANGE_EMAIL_SUCCEED,
    } as const),

  fail: (errors: ChangeEmailErrors) =>
    ({
      type: ActionType.CHANGE_EMAIL_FAIL,
      payload: { errors },
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // change email
  | ReturnType<typeof changeEmailEvent.start>
  | ReturnType<typeof changeEmailEvent.succeed>
  | ReturnType<typeof changeEmailEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
