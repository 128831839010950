/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, join, takeLatest } from 'redux-saga/effects';
import moment, { Moment } from 'moment';
import * as api from '../utils/axiosHelper';
import { checkAndSetStatusError } from './authSagas';

import * as ActionType from '../actions/adminSalesConstants';
import {
  getReady as getReadyStart,
  gotReady,
  getFCSalesInfo,
} from '../actions/adminSalesActions';
import { ErrorResponse } from '../interfaces/commonInterFace';
import { AmountList, getSalesInfoResponse } from '../interfaces/salesInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId } = action.payload;

  const getFCSalesInfoTask = yield fork(
    runGetFCSalesInfo,
    getFCSalesHandler,
    getFCSalesInfo.start(artistId),
  );
  yield join(getFCSalesInfoTask);

  yield put(gotReady());
}

// get fc Sales info
const getFCSalesHandler = api.getGetFactory(true);
function* runGetFCSalesInfo(
  handler: typeof getFCSalesHandler,
  action: ReturnType<typeof getFCSalesInfo.start>,
) {
  const artistId = action.payload;

  try {
    const ReturnData: getSalesInfoResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/sales`,
    );

    if (!ReturnData.result) {
      yield put(getFCSalesInfo.fail());

      return;
    }

    /**
     * TODO:
     * apiから受け取ったデータは、生データをそのままreduxのstateに突っ込む
     * 生データは生データとして持ち、viewで扱いやすくするための整形は
     * selector, もしくはcontainer内で行う
     */

    const now = moment();
    const nextMonth = moment().add(1, 'months');
    const lastMonth = moment().subtract(1, 'months');
    const data = ReturnData.result;
    const salesList: AmountList[] = [];
    const transferList: AmountList[] = [];

    // リスト用の文言関数
    const dataFormat = (date: Moment, type: string) => {
      const month = date.format(`YYYY年M月度`);
      if (type === 'sales') {
        return month;
      }
      const depositMonth = date.add(1, 'months').format(`（YYYY年M月入金）`);

      return month + depositMonth;
    };

    // 売り上げ情報のリスト作成
    data.sales.map(sale =>
      salesList.push({
        amount: sale.monthly_sales,
        title: dataFormat(moment(sale.fixed_month), 'sales'),
      }),
    );

    // 振込情報のリスト作成
    const startMonth = moment(data.approval_date).startOf('month');
    const monthDiff1 = now.diff(startMonth, 'month') - 1;
    if (monthDiff1 > 0) {
      [...Array(monthDiff1)].forEach(() => {
        const transferDate = {
          amount: 0,
          title: dataFormat(startMonth, 'transfer'),
        };
        data.transfers.forEach(transfer => {
          if (moment(transfer.transfer_date).isSame(startMonth, 'month')) {
            transferDate.amount = transfer.amount;
          }
        });
        transferList.push(transferDate);
      });
    }

    // 振込予想額の計算
    const difference = data.total_sale_amount - data.total_transfer_amount;
    let transferWaiting = 0;
    let this_month_expected_transfer_amount = difference;
    let transferDescription = '';
    if (difference < 5000) {
      transferWaiting = difference;
      this_month_expected_transfer_amount = 0;
      transferDescription = '振込待機額が基準額（5,000円）を超えていません。';
    }

    const resultData = {
      ...ReturnData.result,
      sales: salesList,
      transfers: transferList.reverse(),
      salesForecastTitle: now.format('YYYY年M月度売り上げ見込み'),
      transferForecastTitle:
        now.format('YYYY年M月末の振込見込み金額') +
        lastMonth.format('（YYYY年M月分まで）'),
      description:
        now.format('M月度の売り上げは') +
        nextMonth.format('YYYY年M月1日に確定します。'),
      this_month_expected_transfer_amount,
      transferWaiting,
      transferDescription,
    };

    yield put(getFCSalesInfo.succeed(resultData));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFCSalesInfo.fail());
  }
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

export const adminSalesSagas = [fork(getReady)];
