/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import McsNavFCLinkLList from '../common/molecules/navParts/McsNavFCLinkLList';
import McsBannerPrimary from '../common/molecules/bannerParts/McsBannerPrimary';

import OrgHeroFC from '../common/organisms/heroParts/OrgHeroFC';
import OrgTimeLIne from '../common/organisms/listParts/OrgTimeLIne';
import OrgRecommendFCList from '../common/organisms/listParts/OrgRecommendFCList';
import OrgUserListModal from '../common/organisms/modalParts/OrgUserListModal';

import {
  Fanclub,
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../../interfaces/fanclubInterFace';

import { ContentListType, Content } from '../../interfaces/contentInterFace';

import * as styles from './artist.module.scss';

interface Props {
  isLoading?: boolean;
  isLogin?: boolean;
  onClickLogin?: () => void;
  onClickStartForFree?: () => void;

  details?: FanclubDetails;
  prefecture?: string;
  isFollow?: boolean;
  isPaid?: boolean;
  isManage?: boolean;
  onFollowClick?: () => void;
  isViewModalMemberList?: boolean;
  onClickMemberList?: () => void;
  onClickFollowersList?: () => void;
  onClickUser?: (userId: string) => void;
  onClickBlur?: () => void;

  isViewPaidJoin?: boolean;
  onClickPaidJoin?: () => void;

  type?: ContentListType;
  contents?: Content[];
  onChangeTab?: (type: ContentListType) => void;
  isViewLikeCount?: boolean;
  handleClickContent?: (artistId: string, contentId: number) => void;
  isViewReadMoreButton?: boolean;
  onReadMore?: () => void;

  isViewRecommends?: boolean;
  recommends?: Fanclub[];
  onClickFanclub?: (artistId: string) => void;
}

const Component: FC<Props> = ({
  isLoading = false,
  isLogin = false,
  onClickLogin = () => undefined,
  onClickStartForFree = () => undefined,

  details = FanclubDetailsInitialValue,
  prefecture = '',
  isFollow = false,
  isPaid = false,
  isManage = false,
  onFollowClick = () => undefined,
  isViewModalMemberList = false,
  onClickMemberList = () => undefined,
  onClickFollowersList = () => undefined,
  onClickUser = () => undefined,
  onClickBlur = () => undefined,

  isViewPaidJoin = false,
  onClickPaidJoin = () => undefined,

  type = 0,
  contents = [],
  onChangeTab = () => undefined,
  isViewLikeCount = false,
  handleClickContent = () => undefined,

  isViewReadMoreButton = false,
  onReadMore = () => undefined,

  isViewRecommends = false,
  recommends = [],
  onClickFanclub = () => undefined,
}) => {
  const classNames = ClassNames(styles.artistWrap);

  return (
    <div className={classNames}>
      {/* NotLogin */}
      {!isLogin && (
        <div className={styles.headButtonWrap}>
          <ButtonRoundedCorners
            type="Secondary"
            size="Tertiary"
            text="ログイン"
            onClick={onClickLogin}
            cssClass={styles.headButtonItem}
          />
          <ButtonRoundedCorners
            size="Tertiary"
            text="無料ではじめる"
            onClick={onClickStartForFree}
            cssClass={styles.headButtonItem}
          />
        </div>
      )}

      {/* Hero Banner */}
      <div>
        <OrgHeroFC
          fanclubDetails={details}
          prefecture={prefecture}
          isFollow={isFollow}
          isPaid={isPaid}
          isManage={isManage}
          onFollowClick={onFollowClick}
          onLinkClick={onClickMemberList}
          onClickFollowersList={onClickFollowersList}
        />
      </div>

      {/* Link List */}
      <div className={styles.contentWrap}>
        <McsNavFCLinkLList
          homeLink={details.fanclub.site_url ?? ''}
          twitterLink={
            details.fanclub.twitter_url
              ? `https://twitter.com/${details.fanclub.twitter_url}`
              : ''
          }
          faceBookLink={
            details.fanclub.facebook_url
              ? `https://www.facebook.com/${details.fanclub.facebook_url}`
              : ''
          }
          instagramLink={
            details.fanclub.instagram_url
              ? `https://www.instagram.com/${details.fanclub.instagram_url}`
              : ''
          }
        />
      </div>

      {isViewPaidJoin && (
        <div className={styles.bannerWrap}>
          <McsBannerPrimary
            bannerText={`月額${details.fanclub.price}円（税込）`}
            buttonText={
              details.user_type === 99
                ? 'コミュニティをフォロー＆入会する'
                : details.user_type === 0
                ? 'コミュニティへ入会する'
                : '利用できません'
            }
            onClick={onClickPaidJoin}
          />
        </div>
      )}

      {/* TimeLine  */}
      <OrgTimeLIne
        isLoading={isLoading}
        type={type}
        contents={contents}
        onChangeTab={onChangeTab}
        isViewLikeCount={isViewLikeCount}
        isPhotoThumbnail
        isViewReadMoreButton={isViewReadMoreButton}
        onReadMore={onReadMore}
        onClickContent={handleClickContent}
        onClickUser={onClickUser}
      />

      {/** Recommends */}
      {isViewRecommends && (
        <OrgRecommendFCList
          fanclubList={recommends}
          onClickFanclub={onClickFanclub}
        />
      )}

      {isViewModalMemberList && (
        <OrgUserListModal
          users={details.members}
          onClickUser={onClickUser}
          onClickBlur={onClickBlur}
        />
      )}
    </div>
  );
};

export default Component;
