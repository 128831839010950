/* eslint-disable no-nested-ternary */
import React, { FC, useMemo } from 'react';
import moment from 'moment';

import LabelTag from '../common/atoms/label/LabelTag';
import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import TitleH2 from '../common/atoms/title/TitleH2';

import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';

import OrgContentMovie from '../common/organisms/contentParts/OrgContentMovie';

import * as styles from './adminMovieRegister.module.scss';
import {
  InputMovieParams,
  InputMovieParamsInitialValue,
} from '../../interfaces/contentInterFace';
import { Category } from '../../interfaces/categoryInterFace';
import { SelectField } from '../../interfaces/utilInterFace';

interface Props {
  mode?: 'create' | 'update';
  title?: string;
  params?: InputMovieParams;
  fanclubName?: string;
  categoryList?: Category[];
  openAreaList?: SelectField;
  onPublish?: () => void;
  onModify?: () => void;
  onBack?: () => void;
}

const Component: FC<Props> = ({
  title = '',
  params = InputMovieParamsInitialValue,
  fanclubName = '',
  categoryList = [],
  onPublish = () => undefined,
  onModify = () => undefined,
  onBack = () => undefined,
}) => {
  const releaseAt = useMemo(() => {
    const release_at = params.release_at_date
      ? moment(
          `${params.release_at_date} ${params.release_at_time || '00:00'}`,
        ).format('YYYY/MM/DD HH:mm')
      : '未設定';

    return release_at;
  }, [params.release_at_date, params.release_at_time]);

  const closeAt = useMemo(() => {
    const close_at = params.close_at_date
      ? moment(
          `${params.close_at_date} ${params.close_at_time || '00:00'}`,
        ).format('YYYY/MM/DD HH:mm')
      : '未設定';

    return close_at;
  }, [params.close_at_date, params.close_at_time]);

  return (
    <div className={styles.containerWrap}>
      <McsTitleSecondary
        mainText={title}
        subText={fanclubName}
        onClick={onBack}
        cssClass={styles.contentTitle}
      />

      <OrgContentMovie
        movie={params.movie.preview}
        thumbnail={params.thumb.preview}
        cssClass={styles.contentConfirmMovie}
      />

      <div className={styles.contentWrap}>
        <TitleH2 text={params.subject} cssClass={styles.contentConfirmTitle} />
      </div>

      <div className={styles.contentCategoryTagList}>
        {categoryList.map(category => (
          <LabelTag
            key={`category${category.id}`}
            text={category.name}
            type="Quaternary"
            cssClass={styles.contentCategoryTag}
          />
        ))}
      </div>

      <div className={styles.contentWrap}>
        <dl className={styles.contentConfirmDateArea}>
          <dt>公開日時</dt>
          <dd>{releaseAt}</dd>
        </dl>

        <dl className={styles.contentConfirmDateArea}>
          <dt>公開期限</dt>
          <dd>{closeAt}</dd>
        </dl>

        <dl className={styles.contentConfirmDateArea}>
          <dt>公開範囲</dt>
          {params.open_area ? (
            <dd>
              {params.open_area === 1
                ? '有料ファンのみに公開'
                : params.open_area === 2
                ? 'ファンのみに公開'
                : params.open_area === 3
                ? '全体に公開'
                : ''}
            </dd>
          ) : (
            <dd>未設定</dd>
          )}
        </dl>
      </div>
      <div className={styles.contentButtonWrap}>
        <ButtonRoundedCorners
          text="公開する"
          type="Senary"
          onClick={onPublish}
          cssClass={styles.contentButtonItem}
        />
        <ButtonRoundedCorners
          text="内容を修正する"
          type="Septenary"
          onClick={onModify}
          cssClass={styles.contentButtonItem}
        />
        <ButtonRoundedCorners
          text="キャンセル"
          type="Quinary"
          onClick={onBack}
          cssClass={styles.cancel}
        />
      </div>
    </div>
  );
};

export default Component;
