/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import IconClose from '../../atoms/iconParts/IconClose';
import { Category } from '../../../../interfaces/categoryInterFace';

import * as styles from './OrgUserListUnitCategory.module.scss';

type PartialCategory = Partial<Category>;

interface Props {
  cssClass?: string;
  category?: PartialCategory | undefined;
  onClick?: () => void;
  onClickClose?: () => void;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  category = undefined,
  onClick = () => undefined,
  onClickClose = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgUserListUnitBase, cssClass);

  return (
    <li className={classNames} key={category?.id} onClick={onClick}>
      <p className={styles.OrgUserListUnitContent}>
        <span className={styles.OrgUserListUnitName}> {category?.name} </span>
        <IconClose
          type="Quaternary"
          cssClass={styles.OrgUserListUnitIconClose}
          onClick={onClickClose}
        />
      </p>
    </li>
  );
};

export default OrgListUnit;
