import { Reducer } from 'redux';
import { Actions } from '../actions/searchActions';
import * as ActionType from '../actions/searchConstants';
import { FanclubListWithKey } from '../interfaces/fanclubInterFace';

type State = {
  fanclubs: FanclubListWithKey[];
  error: boolean;
};

export const initialState: State = {
  fanclubs: [],
  error: false,
};

const searchReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // get all fanclub list with index
    case ActionType.GET_FIND_FANCLUB_LIST_START: {
      return {
        ...state,
        error: false,
      };
    }
    case ActionType.GET_FIND_FANCLUB_LIST_SUCCEED: {
      return {
        ...state,
        fanclubs: action.payload.result.fanclubs,
      };
    }
    case ActionType.GET_FIND_FANCLUB_LIST_FAIL: {
      return {
        ...state,
        error: true,
      };
    }

    // freejoin fanclub
    case ActionType.FREEJOIN_FANCLUB_START: {
      return {
        ...state,
        error: false,
      };
    }
    case ActionType.FREEJOIN_FANCLUB_SUCCEED: {
      return {
        ...state,
      };
    }
    case ActionType.FREEJOIN_FANCLUB_FAIL: {
      return {
        ...state,
        error: true,
      };
    }

    // withdraw fanclub
    case ActionType.WITHDRAW_FANCLUB_START: {
      return {
        ...state,
        error: false,
      };
    }
    case ActionType.WITHDRAW_FANCLUB_SUCCEED: {
      return {
        ...state,
      };
    }
    case ActionType.WITHDRAW_FANCLUB_FAIL: {
      return {
        ...state,
        error: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default searchReducer;
