export const GET_READY = 'ARTIST/GET_READY' as const;
export const GOT_READY = 'ARTIST/GOT_READY' as const;

export const GET_FANCLUB_DETAILS_START = 'ARTIST/GET_FANCLUB_DETAILS_START' as const;
export const GET_FANCLUB_DETAILS_SUCCEED = 'ARTIST/GET_FANCLUB_DETAILS_SUCCEED' as const;
export const GET_FANCLUB_DETAILS_FAIL = 'ARTIST/GET_FANCLUB_DETAILS_FAIL' as const;

export const GET_CONTENT_LIST_START = 'ARTIST/GET_CONTENT_LIST_START' as const;
export const GET_CONTENT_LIST_SUCCEED = 'ARTIST/GET_CONTENT_LIST_SUCCEED' as const;
export const GET_CONTENT_LIST_FAIL = 'ARTIST/GET_CONTENT_LIST_FAIL' as const;

export const GET_RECOMMEND_FANCLUB_LIST_START = 'ARTIST/GET_RECOMMEND_FANCLUB_LIST_START' as const;
export const GET_RECOMMEND_FANCLUB_LIST_SUCCEED = 'ARTIST/GET_RECOMMEND_FANCLUB_LIST_SUCCEED' as const;
export const GET_RECOMMEND_FANCLUB_LIST_FAIL = 'ARTIST/GET_RECOMMEND_FANCLUB_LIST_FAIL' as const;

export const FREEJOIN_FANCLUB_START = 'ARTIST/FREEJOIN_FANCLUB_START' as const;
export const FREEJOIN_FANCLUB_SUCCEED = 'ARTIST/FREEJOIN_FANCLUB_SUCCEED' as const;
export const FREEJOIN_FANCLUB_FAIL = 'ARTIST/FREEJOIN_FANCLUB_FAIL' as const;

export const WITHDRAW_FANCLUB_START = 'ARTIST/WITHDRAW_FANCLUB_START' as const;
export const WITHDRAW_FANCLUB_SUCCEED = 'ARTIST/WITHDRAW_FANCLUB_SUCCEED' as const;
export const WITHDRAW_FANCLUB_FAIL = 'ARTIST/WITHDRAW_FANCLUB_FAIL' as const;

export const GET_UNREAD_CHAT_START = 'ARTIST/GET_UNREAD_CHAT_START' as const;
export const GET_UNREAD_CHAT_SUCCEED = 'ARTIST/GET_UNREAD_CHAT_SUCCEED' as const;
export const GET_UNREAD_CHAT_FAIL = 'ARTIST/GET_UNREAD_CHAT_FAIL' as const;

export const CLEANUP = 'ARTIST/CLEANUP' as const;
