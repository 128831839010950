/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useCallback } from 'react';

import IconClose from '../../atoms/iconParts/IconClose';
import InputBox from '../../atoms/formParts/InputBox';
import ButtonSquare from '../../atoms/buttonBasic/ButtonSquare';
import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';

import {
  RefineSearch,
  RefineSearchInitialValue,
} from '../../../../interfaces/searchInterFace';

import * as styles from './OrgRefineSearchModal.module.scss';

interface Props {
  params?: RefineSearch;
  onChangeWord?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  onClickAttributeButton?: (name: 'free' | 'paid') => void;
  onClickCross?: () => void;
  onClickSearchButton?: () => void;
  onClickBlur?: () => void;
}

const OrgRefineSearchListModal: FC<Props> = ({
  params = RefineSearchInitialValue,
  onChangeWord = () => undefined,
  onClickAttributeButton = () => undefined,
  onClickCross = () => undefined,
  onClickSearchButton = () => undefined,
  onClickBlur = () => undefined,
}) => {
  const handleFrameMouseEvent = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
    },
    [],
  );

  return (
    <div className={styles.container} onClick={onClickBlur}>
      <div className={styles.frame} onClick={handleFrameMouseEvent}>
        <div className={styles.titleContainer}>
          <p className={styles.title}>絞り込み</p>
        </div>

        <IconClose
          type="Tertiary"
          onClick={onClickCross}
          cssClass={styles.iconClose}
        />

        <div className={styles.contentContainer}>
          <p className={styles.contentTitle}>キーワード</p>
          <InputBox
            type="Septenary"
            inputName="search"
            value={params.word}
            placeholder="ニックネーム・ID・メールアドレスを入力"
            onChange={onChangeWord}
            cssClass={styles.contentBodySearchWord}
          />
        </div>

        <div className={styles.contentContainer}>
          <p className={styles.contentTitle}>属性</p>
          <div className={styles.contentBodyAttributes}>
            <ButtonSquare
              text="無料フォロワー"
              type={params.attributes.free ? 'Senary' : 'Quaternary'}
              onClick={() => onClickAttributeButton('free')}
              cssClass={styles.contentBodyAttributesButton}
            />
            <ButtonSquare
              text="有料フォロワー"
              type={params.attributes.paid ? 'Senary' : 'Quaternary'}
              onClick={() => onClickAttributeButton('paid')}
              cssClass={styles.contentBodyAttributesButton}
            />
          </div>
        </div>

        <div className={styles.submitWrapper}>
          <ButtonRoundedCorners
            type="Senary"
            text="検索"
            onClick={onClickSearchButton}
          />
        </div>
      </div>
    </div>
  );
};

export default OrgRefineSearchListModal;
