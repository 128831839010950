/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';
import * as styles from './McsListStatusDisplay.module.scss';

interface Props {
  mainText?: string;
  subText?: string;
  cssClass?: string;
  flag?: boolean;
  onClick?: () => void;
}

const McsLinkList: FC<Props> = ({
  mainText = '',
  subText = '',
  cssClass = '',
  flag = false,
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsLinkListBase, cssClass);

  return (
    <li className={classNames} onClick={onClick}>
      <div>
        <div className={styles.McsLinkListMainTitle}>{mainText}</div>
        {subText !== '' && (
          <div className={styles.McsLinkListSubTitle}>{subText}</div>
        )}
      </div>
      <ButtonRoundedCorners
        text={flag ? '受け取る' : '受け取らない'}
        type={flag ? 'Primary' : 'Secondary'}
        size="Septenary"
        cssClass={styles.OrgFCListUnitFollowButton}
      />
    </li>
  );
};

export default McsLinkList;
