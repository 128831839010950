/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  cleanup,
  getUnreadChatEvent,
} from '../../actions/adminMenuActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import AdminMenu from '../../components/admin/adminMenu';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import OrgOnboarding from '../../components/common/organisms/onboarding/OrgOnBoarding';

import {
  getHideDenialCookie,
  setHideDenialCookie,
  getRegisterContentOnboardedCookie,
  setRegisterContentOnboardedCookie,
} from '../../utils/utilHelper';

import OnboardingImage1 from '../../components/common/assets/images/content-onboarding_01.png';
import OnboardingImage2 from '../../components/common/assets/images/content-onboarding_02.png';
import OnboardingImage3 from '../../components/common/assets/images/content-onboarding_03.png';
import OnboardingImage4 from '../../components/common/assets/images/content-onboarding_04.png';

import { AdminAdministratorTabMode } from './adminAdministrator';

const onboardingImageList = [
  OnboardingImage1,
  OnboardingImage2,
  OnboardingImage3,
  OnboardingImage4,
];

const AdminMenuContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const [isHiddenDenial, setHiddenDenial] = useState(false);
  const [onboarded, setOnboarded] = useState(true);
  const { ready: authReady } = useSelector(selector.auth.getAuthState);
  const { ready, details, unreadChatCount } = useSelector(
    selector.adminMenu.getAdminMenuState,
  );

  const isPaidFC = useMemo(() => details.fanclub.paid_status === 2, [details]);

  const handleFinishOnboarding = useCallback(() => {
    setOnboarded(true);
    setRegisterContentOnboardedCookie();
  }, []);

  // アーティスト詳細
  const handleLinkToArtist = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getArtistPath(artistId));
  }, [artistId, history]);
  // コンテンツ管理
  const handleLinkToContents = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'CONTENTS'));
  }, [artistId, history]);
  // いいね一覧
  const handleLinkToLikes = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'LIKES'));
  }, [artistId, history]);
  // コメント確認
  const handleLinkToComments = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'COMMENTS'));
  }, [artistId, history]);
  // チャット画面
  const handleLinkToChat = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getFCChatPath(artistId));
  }, [artistId, history]);
  // 売上情報
  const handleLinkToSales = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'SALES'));
  }, [artistId, history]);
  // コミュニティ設定
  const handleLinkToCommunityEdit = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'COMMUNITY'));
  }, [artistId, history]);
  // メンバー管理
  const handleLinkToManageMembers = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'MEMBERS'));
  }, [artistId, history]);
  // フォロワー管理
  const handleLinkToManageFollowers = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'FOLLOWERS'));
  }, [artistId, history]);
  // 管理者情報編集
  const handleLinkToAdminEdit = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'ADMINISTRATOR'));
  }, [artistId, history]);
  // 有料化申請等
  const handleClickApplyButton = useCallback(() => {
    if (!artistId) return;

    if (
      details.fanclub.paid_status === 1 ||
      details.fanclub.paid_status === 4
    ) {
      // コミュニティを有料化する
      history.push(PATH.getAdminPath(artistId, 'APPLY'));
    }

    if (details.fanclub.paid_status === 2 && !details.is_account_registerd) {
      // 口座情報を登録する
      history.push(PATH.getAdminPath(artistId, 'ADMINISTRATOR'), {
        ADMIN_ADMINISTRATOR: {
          mode: AdminAdministratorTabMode.BANK,
        },
      });
    }
  }, [
    details.fanclub.paid_status,
    details.is_account_registerd,
    artistId,
    history,
  ]);

  const handleClickApplyCross = useCallback(() => {
    if (!artistId) return;

    // CookieとStateに否認のテキストを隠すように設定
    setHideDenialCookie(artistId);
    setHiddenDenial(true);
  }, [artistId]);

  const handleLinkToCloseFC = useCallback(() => {
    // コミュニティを閉鎖する
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'CLOSE'));
  }, [artistId, history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!artistId) {
      history.replace(PATH.HOME);

      return;
    }

    // 否認のテキストを隠すかどうか
    const hideDenial = !!getHideDenialCookie(artistId);
    setHiddenDenial(hideDenial);
  }, [artistId, history]);

  useEffect(() => {
    // コンテンツ投稿用オンボーディングの終了確認
    const onboarded = getRegisterContentOnboardedCookie();
    setOnboarded(!!onboarded);
  }, []);

  useEffect(() => {
    if (!authReady || !artistId) return;

    dispatch(getReady(artistId));
  }, [authReady, dispatch, artistId]);

  useEffect(() => {
    if (!ready) return;

    if (!(details.user_type === 2 || details.user_type === 3)) {
      history.replace(PATH.HOME);
    }
  }, [details, history, ready]);

  useEffect(() => {
    if (!artistId || !isPaidFC) return;

    dispatch(getUnreadChatEvent.start(artistId));
  }, [dispatch, artistId, isPaidFC]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>{details.fanclub.name} | コミュニティ管理 | KRAP</title>
        )}
      </Helmet>

      <OrgLoader isLoaded={authReady && ready} />

      {/* オンボーディング */}
      {onboarded ? null : (
        <OrgOnboarding
          imageList={onboardingImageList}
          onClickStart={handleFinishOnboarding}
        />
      )}
      <AdminMenu
        details={details}
        isHiddenDenial={isHiddenDenial}
        chatCount={unreadChatCount}
        handleLinkToArtist={handleLinkToArtist}
        handleLinkToContents={handleLinkToContents}
        handleLinkToLikes={handleLinkToLikes}
        handleLinkToComments={handleLinkToComments}
        handleLinkToChat={handleLinkToChat}
        handleLinkToSales={handleLinkToSales}
        handleLinkToCommunityEdit={handleLinkToCommunityEdit}
        handleLinkToManageMembers={handleLinkToManageMembers}
        handleLinkToManageFollowers={handleLinkToManageFollowers}
        handleLinkToAdminEdit={handleLinkToAdminEdit}
        handleClickApplyButton={handleClickApplyButton}
        handleClickApplyCross={handleClickApplyCross}
        handleLinkToCloseFC={handleLinkToCloseFC}
      />
    </>
  );
};

export default AdminMenuContainer;
