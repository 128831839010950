import { Reducer } from 'redux';
import { Actions } from '../actions/footerActions';
import * as ActionType from '../actions/footerConstants';

import { Options } from '../interfaces/footerInterFace';

type State = {
  options: Options;
};

export const initialState: State = {
  options: {},
};

const footerReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // set options
    case ActionType.SET_OPTIONS: {
      return {
        ...state,
        options: action.payload,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default footerReducer;
