import * as ActionType from './authConstants';
import { Status } from '../interfaces/commonInterFace';
import { LogInParams, GetUserProfileResult } from '../interfaces/authInterFace';

export const getReady = () =>
  ({
    type: ActionType.GET_READY,
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const setAccessToken = (access_token: string) =>
  ({
    type: ActionType.SET_ACCESS_TOKEN,
    payload: access_token,
  } as const);

export const setAuthError = (
  error: boolean | Status.Unauthorized | Status.Forbidden | Status.NotFound,
) =>
  ({
    type: ActionType.SET_AUTH_ERROR,
    payload: error,
  } as const);

export const logInEvent = {
  start: (params: LogInParams) =>
    ({
      type: ActionType.LOGIN_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.LOGIN_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.LOGIN_FAIL,
    } as const),
};

export const logoutEvent = {
  start: () =>
    ({
      type: ActionType.LOGOUT_START,
    } as const),

  succeed: () =>
    ({
      type: ActionType.LOGOUT_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.LOGOUT_FAIL,
    } as const),
};

export const getUserProfileEvent = {
  start: () =>
    ({
      type: ActionType.GET_USER_PROFILE_START,
    } as const),

  succeed: (result: GetUserProfileResult) =>
    ({
      type: ActionType.GET_USER_PROFILE_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_USER_PROFILE_FAIL,
    } as const),
};

export const getUnreadCountEvent = {
  start: () =>
    ({
      type: ActionType.GET_UNREAD_COUNT_START,
    } as const),

  succeed: (unreadCount: number) =>
    ({
      type: ActionType.GET_UNREAD_COUNT_SUCCEED,
      payload: unreadCount,
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_UNREAD_COUNT_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // set access token
  | ReturnType<typeof setAccessToken>
  // set auth error
  | ReturnType<typeof setAuthError>
  // login
  | ReturnType<typeof logInEvent.start>
  | ReturnType<typeof logInEvent.succeed>
  | ReturnType<typeof logInEvent.fail>
  // logout
  | ReturnType<typeof logoutEvent.start>
  | ReturnType<typeof logoutEvent.succeed>
  | ReturnType<typeof logoutEvent.fail>
  // get user profile
  | ReturnType<typeof getUserProfileEvent.start>
  | ReturnType<typeof getUserProfileEvent.succeed>
  | ReturnType<typeof getUserProfileEvent.fail>
  // get unread count
  | ReturnType<typeof getUnreadCountEvent.start>
  | ReturnType<typeof getUnreadCountEvent.succeed>
  | ReturnType<typeof getUnreadCountEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
