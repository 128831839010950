/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import McsIconLabelRoundSecondary from '../../molecules/iconWithLabel/McsIconLabelRoundSecondary';
import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';
import LabelTag from '../../atoms/label/LabelTag';
import noUserImageUrl from '../../assets/images/icon-nouser.png';

import { User, UserInitialValue } from '../../../../interfaces/userInterFace';

import * as styles from './OrgUserListUnitMemberQuaternary.module.scss';

type PartialUser = Partial<User>;

interface Props {
  cssClass?: string;
  user?: PartialUser;
  onClickUser?: () => void;
  onClickButton?: () => void;
  tagText?: string;
  isAdmin?: boolean;
  isPIcon?: boolean;
  isButtonHidden?: boolean;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  user = UserInitialValue,
  onClickUser = () => undefined,
  onClickButton = () => undefined,
  tagText = '',
  isAdmin = false,
  isPIcon = false,
  isButtonHidden = false,
}) => {
  const classNames = ClassNames(styles.OrgUserListUnitBase, cssClass);

  return (
    <li className={classNames} key={`OrgUserListUnitMembernQuinary${user.id}`}>
      <div className={styles.OrgUserListUnitContent}>
        <div className={styles.OrgUserListUnitUser}>
          <McsIconLabelRoundSecondary
            src={user.icon_image || noUserImageUrl}
            mainText={user.nickname}
            subText={`@${user.name_id}`}
            isPIcon={isPIcon}
            onClick={onClickUser}
          />
          {isAdmin && (
            <LabelTag
              text="管理者"
              type="Septenary"
              cssClass={styles.OrgUserListUnitLabel}
            />
          )}
        </div>
        {!isButtonHidden && (
          <ButtonRoundedCorners
            text={tagText}
            type="Novenary"
            size="Septenary"
            cssClass={styles.OrgUserListUnitButton}
            onClick={onClickButton}
          />
        )}
      </div>
    </li>
  );
};

export default OrgListUnit;
