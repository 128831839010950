/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import {
  searchUserListEvent,
  addFanclubMembersEvent,
  cleanup,
} from '../../actions/openFCCompleteActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { User } from '../../interfaces/userInterFace';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import Complete from '../../components/openFC/complete';

const OpenFCCompleteContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [text, setText] = useState('');
  const [members, setMembers] = useState<User[]>([]);
  const { ready: authReady, profile: me } = useSelector(
    selector.auth.getAuthState,
  );
  const { users, loading, errors, success } = useSelector(
    selector.openFCComplete.getOpenFCCompleteState,
  );
  const invitableUserList = useMemo(() => {
    const list = users.filter(user => {
      if (user.id === me.user.id) return false;

      if (members.find(member => member.name_id === user.name_id)) return false;

      if (user.fanclubs.length && user.fanclubs[0].pivot?.type !== 0)
        return false;

      return true;
    });

    return list;
  }, [me.user.id, members, users]);
  const artist_id = useMemo(
    () => history.location?.state?.OPEN_FC_COMPLETE?.artistId,
    [history.location],
  );
  const fanclubName = useMemo(
    () => me.fanclubs.manages.find(f => f.group_id === artist_id)?.site_name,
    [artist_id, me.fanclubs.manages],
  );
  const error = useMemo(
    () => (errors?.new_members?.length ? errors.new_members[0] : ''),
    [errors],
  );

  const handleChangeText = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setText(e.currentTarget.value);
    },
    [],
  );
  const handleChangeMembers = useCallback(
    (userId: string) => {
      const user = [...users, ...members].find(m => m.name_id === userId);
      if (!user) return;

      members.find(m => m.name_id === userId)
        ? setMembers(members.filter(m => m.name_id !== userId))
        : setMembers([...members, user]);
    },
    [members, users],
  );
  const handleAddFanclubMembers = useCallback(() => {
    if (!artist_id || !members.length) return;

    dispatch(
      addFanclubMembersEvent.start({
        artistId: artist_id,
        new_members: members.map(m => m.name_id),
      }),
    );
  }, [dispatch, artist_id, members]);
  const handleSkip = useCallback(() => {
    if (!artist_id) return;

    history.push(PATH.getArtistPath(artist_id));
  }, [artist_id, history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    if (!artist_id) history.push(PATH.HOME);

    return () => {
      dispatch(cleanup());
    };
  }, [dispatch, history, artist_id]);

  useEffect(() => {
    if (!text || !artist_id) return;

    dispatch(
      searchUserListEvent.start({
        keyword: text,
        fanclub: artist_id,
      }),
    );
  }, [dispatch, artist_id, text]);

  useEffect(() => {
    if (!success) return;

    if (!artist_id) return;

    history.replace(PATH.getArtistPath(artist_id));
  }, [history, artist_id, success]);

  return (
    <>
      <Helmet>
        <title>メンバー追加 | コミュニティ開設 | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady} />

      <Complete
        userName={me.user.nickname}
        fanclubName={fanclubName}
        text={text}
        userList={invitableUserList}
        memberList={members}
        handleChangeText={handleChangeText}
        handleChangeMembers={handleChangeMembers}
        handleAddFanclubMembers={handleAddFanclubMembers}
        handleSkip={handleSkip}
        error={error}
      />
    </>
  );
};

export default OpenFCCompleteContainer;
