/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/adminVoiceRegisterConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getFanclubDetailsEvent,
  getVoiceEvent,
  getFCCategoryListEvent,
  addFCCategoryEvent,
  createVoiceEvent,
  updateVoiceEvent,
  deleteVoiceEvent,
} from '../actions/adminVoiceRegisterActions';

import { Status, ErrorResponse } from '../interfaces/commonInterFace';
import { GetFanclubDetailsResponse } from '../interfaces/fanclubInterFace';
import {
  GetContentDetailsResponse,
  CreateVoiceResponse,
  CreateVoiceErrorResponse,
  UpdateVoiceResponse,
  UpdateVoiceErrorResponse,
  DeleteContentResponse,
} from '../interfaces/contentInterFace';
import {
  GetFCCategoryListResponse,
  AddFCCategoryResponse,
  AddFCCategoryErrorResponse,
} from '../interfaces/categoryInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId, contentId } = action.payload;

  const getFanclubDetailsTask = yield fork(
    runGetFanclubDetails,
    getFanclubDetailsHandler,
    getFanclubDetailsEvent.start({ artistId }),
  );
  yield join(getFanclubDetailsTask);

  const getFCCategoryListTask = yield fork(
    runGetFCCategoryList,
    getFCCategoryListHandler,
    getFCCategoryListEvent.start({ artistId }),
  );
  yield join(getFCCategoryListTask);

  if (contentId) {
    const getVoiceTask = yield fork(
      runGetVoice,
      getVoiceHandler,
      getVoiceEvent.start({ contentId }),
    );
    yield join(getVoiceTask);
  }

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get fanclub details
const getFanclubDetailsHandler = api.getGetFactory(true);
function* runGetFanclubDetails(
  handler: typeof getFanclubDetailsHandler,
  action: ReturnType<typeof getFanclubDetailsEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetFanclubDetailsResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(getFanclubDetailsEvent.fail());

      return;
    }

    yield put(getFanclubDetailsEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFanclubDetailsEvent.fail());
  }
}
export function* getFanclubDetails(handler: typeof getFanclubDetailsHandler) {
  yield takeLatest(
    ActionType.GET_FANCLUB_DETAILS_START,
    runGetFanclubDetails,
    handler,
  );
}

// get voice
const getVoiceHandler = api.getGetFactory(true);
function* runGetVoice(
  handler: typeof getVoiceHandler,
  action: ReturnType<typeof getVoiceEvent.start>,
) {
  const { contentId } = action.payload;
  try {
    const ReturnData: GetContentDetailsResponse = yield call(
      handler,
      {},
      `/contents/${contentId}`,
    );

    if (!ReturnData) {
      yield put(getVoiceEvent.fail());

      return;
    }

    yield put(getVoiceEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getVoiceEvent.fail());
  }
}
export function* getVoice(handler: typeof getVoiceHandler) {
  yield takeLatest(ActionType.GET_VOICE_START, runGetVoice, handler);
}

// get fc category list
const getFCCategoryListHandler = api.getGetFactory(true);
function* runGetFCCategoryList(
  handler: typeof getFCCategoryListHandler,
  action: ReturnType<typeof getFCCategoryListEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: GetFCCategoryListResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/category`,
    );

    if (!ReturnData) {
      yield put(getFCCategoryListEvent.fail());

      return;
    }

    yield put(getFCCategoryListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFCCategoryListEvent.fail());
  }
}
export function* getFCCategoryList(handler: typeof getFCCategoryListHandler) {
  yield takeLatest(
    ActionType.GET_FC_CATEGORY_LIST_START,
    runGetFCCategoryList,
    handler,
  );
}

// add fc category
const addFCCategoryHandler = api.getPostFactory(true);
function* runAddFCCategory(
  handler: typeof addFCCategoryHandler,
  action: ReturnType<typeof addFCCategoryEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: AddFCCategoryResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/category`,
    );

    if (!ReturnData) {
      yield put(addFCCategoryEvent.fail({}));

      return;
    }

    yield put(addFCCategoryEvent.succeed(action.payload, ReturnData.result));
  } catch (error) {
    const res = error.response.data as AddFCCategoryErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(addFCCategoryEvent.fail(res.errors));

      return;
    }

    yield put(addFCCategoryEvent.fail({}));
  }
}
export function* addFCCategory(handler: typeof addFCCategoryHandler) {
  yield takeLeading(
    ActionType.ADD_FC_CATEGORY_START,
    runAddFCCategory,
    handler,
  );
}

// create voice
const createVoiceHandler = api.getFormDataFactory('POST');
function* runCreateVoice(
  handler: typeof createVoiceHandler,
  action: ReturnType<typeof createVoiceEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: CreateVoiceResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/voice`,
    );

    if (!ReturnData) {
      yield put(createVoiceEvent.fail({}));

      return;
    }

    yield put(createVoiceEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as CreateVoiceErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(createVoiceEvent.fail(res.errors));

      return;
    }

    yield put(createVoiceEvent.fail({}));
  }
}
export function* createVoice(handler: typeof createVoiceHandler) {
  yield takeLeading(ActionType.CREATE_VOICE_START, runCreateVoice, handler);
}

// update voice
const updateVoiceHandler = api.getFormDataFactory('PATCH');
function* runUpdateVoice(
  handler: typeof updateVoiceHandler,
  action: ReturnType<typeof updateVoiceEvent.start>,
) {
  const { artistId, contentId, ...data } = action.payload;
  try {
    const ReturnData: UpdateVoiceResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/voice/${contentId}`,
    );

    if (!ReturnData) {
      yield put(updateVoiceEvent.fail({}));

      return;
    }

    yield put(updateVoiceEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as UpdateVoiceErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(updateVoiceEvent.fail(res.errors));

      return;
    }

    yield put(updateVoiceEvent.fail({}));
  }
}
export function* updateVoice(handler: typeof updateVoiceHandler) {
  yield takeLeading(ActionType.UPDATE_VOICE_START, runUpdateVoice, handler);
}

// delete voice
const deleteVoiceHandler = api.getDeleteFactory(true);
function* runDeleteVoice(
  handler: typeof deleteVoiceHandler,
  action: ReturnType<typeof deleteVoiceEvent.start>,
) {
  const { artistId, contentId } = action.payload;
  try {
    const ReturnData: DeleteContentResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/voice/${contentId}`,
    );

    if (!ReturnData) {
      yield put(deleteVoiceEvent.fail());

      return;
    }

    yield put(deleteVoiceEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(deleteVoiceEvent.fail());
  }
}
export function* deleteVoice(handler: typeof deleteVoiceHandler) {
  yield takeLeading(ActionType.DELETE_VOICE_START, runDeleteVoice, handler);
}

export const adminVoiceRegisterSagas = [
  fork(getReady),
  fork(getFanclubDetails, getFanclubDetailsHandler),
  fork(getVoice, getVoiceHandler),
  fork(getFCCategoryList, getFCCategoryListHandler),
  fork(addFCCategory, addFCCategoryHandler),
  fork(createVoice, createVoiceHandler),
  fork(updateVoice, updateVoiceHandler),
  fork(deleteVoice, deleteVoiceHandler),
];
