import * as ActionType from './changeCreditCardConstants';
import {
  GetCardInfoResult,
  UpdateCardInfoParams,
  UpdateCardInfoResult,
} from '../interfaces/cardInterFace';

export const getReady = () =>
  ({
    type: ActionType.GET_READY,
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getCardInfoEvent = {
  start: () =>
    ({
      type: ActionType.GET_CARD_INFO_START,
    } as const),

  succeed: (result: GetCardInfoResult) =>
    ({
      type: ActionType.GET_CARD_INFO_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_CARD_INFO_FAIL,
    } as const),
};

export const updateCardInfoEvent = {
  start: (params: UpdateCardInfoParams) =>
    ({
      type: ActionType.UPDATE_CARD_INFO_START,
      payload: params,
    } as const),

  succeed: (result: UpdateCardInfoResult) =>
    ({
      type: ActionType.UPDATE_CARD_INFO_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.UPDATE_CARD_INFO_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get card info
  | ReturnType<typeof getCardInfoEvent.start>
  | ReturnType<typeof getCardInfoEvent.succeed>
  | ReturnType<typeof getCardInfoEvent.fail>
  // update card info
  | ReturnType<typeof updateCardInfoEvent.start>
  | ReturnType<typeof updateCardInfoEvent.succeed>
  | ReturnType<typeof updateCardInfoEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
