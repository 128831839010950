import { Reducer } from 'redux';

import { Actions } from '../actions/likesActions';
import * as ActionType from '../actions/likesConstants';

import { Pager, PagerInitialValue } from '../interfaces/commonInterFace';
import {
  ContentDetail,
  ContentDetailInitialValue,
} from '../interfaces/contentInterFace';

import { likedUser } from '../interfaces/likesInterFace';

type State = Pager & {
  detail: ContentDetail;
  data: likedUser[];
  ready: boolean;
  loading: boolean;
};

export const initialState: State = {
  ...PagerInitialValue,
  detail: ContentDetailInitialValue,
  data: [],
  ready: false,
  loading: false,
};

const likesReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get comment list
    case ActionType.GET_CONTENT_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_CONTENT_DETAILS_SUCCEED: {
      return {
        ...state,
        detail: action.payload.result.detail,
        loading: false,
      };
    }
    case ActionType.GET_CONTENT_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // いいねしたユーザー取得
    case ActionType.GET_LIKED_USERS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_LIKED_USERS_SUCCEED: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    case ActionType.GET_LIKED_USERS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default likesReducer;
