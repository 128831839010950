export const GET_READY = 'ADMIN_MOVIE_REGISTER/GET_READY' as const;
export const GOT_READY = 'ADMIN_MOVIE_REGISTER/GOT_READY' as const;

export const GET_FANCLUB_DETAILS_START = 'ADMIN_MOVIE_REGISTER/GET_FANCLUB_DETAILS_START' as const;
export const GET_FANCLUB_DETAILS_SUCCEED = 'ADMIN_MOVIE_REGISTER/GET_FANCLUB_DETAILS_SUCCEED' as const;
export const GET_FANCLUB_DETAILS_FAIL = 'ADMIN_MOVIE_REGISTER/GET_FANCLUB_DETAILS_FAIL' as const;

export const GET_MOVIE_START = 'ADMIN_MOVIE_REGISTER/GET_MOVIE_START' as const;
export const GET_MOVIE_SUCCEED = 'ADMIN_MOVIE_REGISTER/GET_MOVIE_SUCCEED' as const;
export const GET_MOVIE_FAIL = 'ADMIN_MOVIE_REGISTER/GET_MOVIE_FAIL' as const;

export const GET_FC_CATEGORY_LIST_START = 'ADMIN_MOVIE_REGISTER/GET_FC_CATEGORY_LIST_START' as const;
export const GET_FC_CATEGORY_LIST_SUCCEED = 'ADMIN_MOVIE_REGISTER/GET_FC_CATEGORY_LIST_SUCCEED' as const;
export const GET_FC_CATEGORY_LIST_FAIL = 'ADMIN_MOVIE_REGISTER/GET_FC_CATEGORY_LIST_FAIL' as const;

export const ADD_FC_CATEGORY_START = 'ADMIN_MOVIE_REGISTER/ADD_FC_CATEGORY_START' as const;
export const ADD_FC_CATEGORY_SUCCEED = 'ADMIN_MOVIE_REGISTER/ADD_FC_CATEGORY_SUCCEED' as const;
export const ADD_FC_CATEGORY_FAIL = 'ADMIN_MOVIE_REGISTER/ADD_FC_CATEGORY_FAIL' as const;

export const CREATE_MOVIE_START = 'ADMIN_MOVIE_REGISTER/CREATE_MOVIE_START' as const;
export const CREATE_MOVIE_SUCCEED = 'ADMIN_MOVIE_REGISTER/CREATE_MOVIE_SUCCEED' as const;
export const CREATE_MOVIE_FAIL = 'ADMIN_MOVIE_REGISTER/CREATE_MOVIE_FAIL' as const;

export const UPDATE_MOVIE_START = 'ADMIN_MOVIE_REGISTER/UPDATE_MOVIE_START' as const;
export const UPDATE_MOVIE_SUCCEED = 'ADMIN_MOVIE_REGISTER/UPDATE_MOVIE_SUCCEED' as const;
export const UPDATE_MOVIE_FAIL = 'ADMIN_MOVIE_REGISTER/UPDATE_MOVIE_FAIL' as const;

export const DELETE_MOVIE_START = 'ADMIN_MOVIE_REGISTER/DELETE_MOVIE_START' as const;
export const DELETE_MOVIE_SUCCEED = 'ADMIN_MOVIE_REGISTER/DELETE_MOVIE_SUCCEED' as const;
export const DELETE_MOVIE_FAIL = 'ADMIN_MOVIE_REGISTER/DELETE_MOVIE_FAIL' as const;

export const CLEANUP = 'ADMIN_MOVIE_REGISTER/CLEANUP' as const;
