import { Reducer } from 'redux';
import { Actions } from '../actions/openFCRegisterActions';
import * as ActionType from '../actions/openFCRegisterConstants';
import { CreateFanclubErrors } from '../interfaces/fanclubInterFace';

type State = {
  loading: boolean;
  errors: CreateFanclubErrors;
  success: boolean;
};

const initialState: State = {
  loading: false,
  errors: {},
  success: false,
};

const openFCRegisterReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // create fanclub
    case ActionType.CREATE_FANCLUB_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.CREATE_FANCLUB_SUCCEED: {
      return {
        ...state,
        success: true,
        loading: false,
      };
    }
    case ActionType.CREATE_FANCLUB_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default openFCRegisterReducer;
