import { Reducer } from 'redux';
import { Actions } from '../actions/contactActions';
import * as ActionType from '../actions/contactConstants';
import { SubmitContactErrors } from '../interfaces/contactInterFace';

type State = {
  loading: boolean;
  errors: SubmitContactErrors;
  success: boolean;
};

export const initialState: State = {
  loading: false,
  errors: {},
  success: false,
};

const contactReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // submit contact
    case ActionType.SUBMIT_CONTACT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.SUBMIT_CONTACT_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ActionType.SUBMIT_CONTACT_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }

    // cleanup
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default contactReducer;
