/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import moment from 'moment';

import noUserImageUrl from '../../assets/images/icon-nouser.png';
import McsIconLabelRoundQuaternary from '../../molecules/iconWithLabel/McsIconLabelRoundQuaternary';

import * as styles from './OrgAdminListCommentUnit.module.scss';

import {
  CommentDetails,
  CommentDetailsInitialValue,
} from '../../../../interfaces/commentInterFace';

type PartialCommentDetails = Partial<CommentDetails>;

interface Props {
  cssClass?: string;
  comment?: PartialCommentDetails;
  onClick?: () => void;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  comment = CommentDetailsInitialValue,
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgAdminListCommentUnitBase, cssClass);

  return (
    <li key={comment.id} className={classNames} onClick={onClick}>
      <div className={styles.OrgAdminListCommentUnitInfoContainer}>
        <McsIconLabelRoundQuaternary
          src={comment.user?.icon_image || noUserImageUrl}
          mainText={comment.user?.nickname}
          subText={comment.user?.name_id ? `@${comment.user?.name_id}` : ''}
        />
        <span className={styles.OrgAdminListCommentUnitDateTime}>
          {comment.created_at
            ? moment(comment.created_at).format('YYYY.MM.DD HH:mm')
            : ''}
        </span>
      </div>
      <div className={styles.OrgAdminListCommentUnitCommentWrapper}>
        <p className={styles.OrgAdminListCommentUnitComment}>
          {comment.comment}
        </p>
      </div>
    </li>
  );
};

export default OrgListUnit;
