/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useCallback } from 'react';
import ClassNames from 'classnames';
import moment from 'moment';

import noUserImageUrl from '../../assets/images/icon-nouser.png';
import blankImageUrl from '../../assets/images/blank-image.png';

import ButtonMedia from '../../atoms/buttonUtile/ButtonMedia';
import IconShape from '../../atoms/iconParts/IconShape';

import McsIconLabelRoundTertiary from '../../molecules/iconWithLabel/McsIconLabelRoundTertiary';

import McsIconLabelSquareTertiary from '../../molecules/iconWithLabel/McsIconLabelSquareTertiary';
import McsIconWithTextSecondary from '../../molecules/iconWithText/McsIconWithTextSecondary';

import OrgSoundPlayer from '../mediaParts/OrgSoundPlayer';

import {
  Content,
  ContentLabel,
  ContentInitialValue,
} from '../../../../interfaces/contentInterFace';

import * as styles from './OrgFCListUnitTimeLine.module.scss';

type Props = {
  content?: Partial<Content>;
  isViewFanclub?: boolean;
  isViewLikeCount?: boolean;
  onClickContent?: (artistId: string, contentId: number) => void;
  onClickUser?: (userId: string) => void;
  onClickFanclub?: (artistId: string) => void;
  cssClass?: string;
};

const OrgTimeLineUnit: FC<Props> = ({
  content = ContentInitialValue,
  isViewFanclub = false,
  isViewLikeCount = false,
  onClickContent = () => undefined,
  onClickUser = () => undefined,
  onClickFanclub = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListUnitBase, cssClass);

  const handleClickUser = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onClickUser(content.user?.name_id ?? '');

      e.stopPropagation();
    },
    [content.user, onClickUser],
  );

  const handleClickContent = useCallback(() => {
    onClickContent(content?.fanclub?.group_id ?? '', content?.id ?? 0);
  }, [content, onClickContent]);

  const handleClickFanclub = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onClickFanclub(content?.fanclub?.group_id ?? '');

      e.stopPropagation();
    },
    [content, onClickFanclub],
  );

  return (
    <li className={classNames} key={content.id} onClick={handleClickContent}>
      <div
        className={styles.OrgFCListUnitUser}
        onClick={isViewFanclub ? handleClickFanclub : handleClickUser}
      >
        {/** artist画面なら投稿者それ以外ならartist情報 */}
        {isViewFanclub ? (
          <McsIconLabelSquareTertiary
            src={content.fanclub?.icon_image}
            mainText={content.fanclub?.name}
            subText={content.fanclub?.site_name}
            cssClass={styles.OrgFCListUnitUser}
          />
        ) : (
          <McsIconLabelRoundTertiary
            src={content.user?.icon_image ?? noUserImageUrl}
            mainText={content.user?.nickname ?? '退会済みユーザー'}
            subText={`@${content.user?.name_id ?? ''}`}
          />
        )}

        <div className={styles.OrgFCListUnitTime}>
          {content.release_at
            ? moment(content.release_at).format('YYYY.M.D')
            : ''}
        </div>
      </div>

      {/** 1:blog, 2:photo, 3:movie, 4:voice */}
      <div className={styles.OrgFCListUnitComment}>
        {content.type ? ContentLabel[content.type] : 'コンテンツ'}
        を更新しました。{content.subject}
        {content.open_area === 1 && (
          <IconShape type="lock" cssClass={styles.IconLock} />
        )}
      </div>

      {/** thumbnail */}
      {content.url && (content.type === 1 || content.type === 2) ? (
        <div className={styles.OrgFCListUnitPhoto}>
          <div
            /** blog or photo */
            className={
              content.open_area === 1
                ? styles.PaidFCPhotos
                : styles.FreeFCPhotos
            }
            style={{
              background: `url(${content.url ??
                blankImageUrl}) no-repeat center center / cover`,
            }}
          />
        </div>
      ) : content.url && content.type === 3 ? (
        <div
          className={styles.OrgFCListUnitVideo}
          style={{
            background: `url(${content.url ??
              blankImageUrl}) no-repeat center center / cover`,
          }}
        >
          <div className={styles.OrgFCListUnitVideoCover} />
          <ButtonMedia
            type="PlayVideo"
            cssClass={styles.OrgFCListUnitVideoButton}
          />
        </div>
      ) : content.type === 4 ? (
        <div className={styles.OrgFCListUnitSound}>
          <OrgSoundPlayer duration={content?.voice?.recording_time ?? 0} />
        </div>
      ) : null}

      {/* いいね・コメント */}
      <div className={styles.likeFollowWrap}>
        {/* いいね状態で色を変える */}
        <McsIconWithTextSecondary
          iconType={content.is_like ? 'FavoriteFill' : 'FavoriteLine'}
          mainText={`${content.total_like ?? 0}`}
          cssClass={styles.likeFollowItem}
        />
        <McsIconWithTextSecondary
          iconType="SpeechBubble"
          mainText={`${content.total_comment ?? 0}`}
          cssClass={styles.likeFollowItem}
        />
      </div>
    </li>
  );
};

export default OrgTimeLineUnit;
