/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useSelector } from 'react-redux';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import Description from '../../components/openFC/description';

const OpenFCDescriptionContainer: FC = () => {
  const history = useHistory();

  const [agreed, setAgreed] = useState(false);
  const { ready: authReady } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);

  const handleToggleAgreed = useCallback(() => {
    setAgreed(agreed => !agreed);
  }, []);
  const handleOpenFC = useCallback(() => {
    if (agreed) history.push(PATH.OPEN_FC_REGISTER);
  }, [agreed, history]);
  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      nav: true,
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    if (authReady && !isLogin) {
      history.replace(PATH.HOME);
    }
  }, [authReady, history, isLogin]);

  return (
    <>
      <Helmet>
        <title>コミュニティ開設 | KRAP</title>
      </Helmet>

      <OrgLoader isLoaded={authReady} />

      <Description
        handleOpenFC={handleOpenFC}
        handleToggleAgreed={handleToggleAgreed}
        agreed={agreed}
        handleBack={handleBack}
      />
    </>
  );
};

export default OpenFCDescriptionContainer;
