import { useMemo, useCallback } from 'react';
import { useHistory as useDefaultHistory } from 'react-router';

import { UserTabMode } from '../interfaces/userInterFace';

import { ContentRegisterMode } from '../interfaces/contentInterFace';
import { AdminAdministratorTabMode } from '../containers/admin/adminAdministrator';

type State = {
  /**
   * pathname
   * ex.) /signup, /user/tanaka
   */
  referer?: string;
  /**
   * ログイン、会員登録ページ
   * 2020.07.31 ログインしていなくてもコンテンツを見られるように変更したため、未使用
   */
  SIGNUP?: {
    from: 'contents';
  };
  SIGNUP_REGISTER?: {
    email: string;
  };
  /**
   * ユーザ詳細ページ
   */
  USER?: {
    mode?: UserTabMode;
  };
  /**
   * コミュニティ開設
   */
  OPEN_FC_COMPLETE?: {
    artistId: string;
  };
  /**
   * アーティスト管理 - コンテンツ管理
   */
  ADMIN_CONTENTS?: {
    mode: ContentRegisterMode;
    title: string;
    artistId: string;
    contentId: number;
  };
  /**
   * アーティスト管理 - コンテンツ投稿
   */
  ADMIN_REGISTER_CONTENT?: {
    mode: 'tab';
  };
  /**
   * アーティスト管理 - 管理者情報編集
   */
  ADMIN_ADMINISTRATOR?: {
    mode: AdminAdministratorTabMode;
  };
};

export const useHistory = () => {
  const history = useDefaultHistory<State>();

  const push = useCallback(
    (path: string, state?: State) => {
      const current = window.location.pathname;

      // 現在のpathnameをrefererに自動で設定する
      history.push(path, { referer: current, ...state });
    },
    [history],
  );

  const replace = useCallback(
    (path: string, state?: State) => {
      const current = window.location.pathname;

      // 現在のpathnameをrefererに自動で設定する
      history.replace(path, { referer: current, ...state });
    },
    [history],
  );

  return useMemo(
    () => ({
      ...history,
      push,
      replace,
      referer: history.location?.state?.referer,
    }),
    // urlの変更に応じて、返却するcustom-historyを更新する
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [history, history.location.pathname, push, replace],
  );
};
