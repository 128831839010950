/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, takeLeading } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/reissuePasswordResetPasswordConstants';
import { resetPasswordEvent } from '../actions/reissuePasswordResetPasswordActions';
import {
  ResetPasswordResponse,
  ResetPasswordErrorResponse,
} from '../interfaces/passwordInterFace';
import { Status } from '../interfaces/commonInterFace';

// reset password
const resetPasswordHandler = api.getPostFactory();
function* runResetPassword(
  handler: typeof resetPasswordHandler,
  action: ReturnType<typeof resetPasswordEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: ResetPasswordResponse = yield call(
      handler,
      data,
      '/password/reset',
    );

    if (!ReturnData) {
      yield put(resetPasswordEvent.fail({}));

      return;
    }

    yield put(resetPasswordEvent.succeed());
  } catch (error) {
    const res = error.response.data as ResetPasswordErrorResponse;
    if (res.status_code === Status.ValidationFailed) {
      yield put(resetPasswordEvent.fail(res.errors));

      return;
    }

    yield put(resetPasswordEvent.fail({}));
  }
}
export function* resetPassword(handler: typeof resetPasswordHandler) {
  yield takeLeading(ActionType.RESET_PASSWORD_START, runResetPassword, handler);
}

export const reissuePasswordResetPasswordSagas = [
  fork(resetPassword, resetPasswordHandler),
];
