/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  getCurrentBankEvent,
  getCurrentBankBranchEvent,
  getAddressEvent,
  searchBankEvent,
  searchBankBranchEvent,
  updateAdministratorEvent,
  createBankAccountEvent,
  updateBankAccountEvent,
  cleanup,
} from '../../actions/adminAdministratorActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminAdministrator from '../../components/admin/adminAdministrator';

import { getYears, getMonths, getDates } from '../../utils/datetimeHelper';
import {
  validateEmail,
  validateBankKigou,
  validateBankHolder,
} from '../../utils/validationHelper';
import { convert2AccountHolder } from '../../utils/convertHelper';

import {
  UpdateAdministratorInputParams,
  UpdateAdministratorInputParamsInitialValue,
  UpdateAdministratorParams,
  UpdateAdministratorValidationErrors,
} from '../../interfaces/administratorInterFace';
import {
  BankType,
  RegisterJPBankAccountInputParams,
  RegisterJPBankAccountInputParamsInitialValue,
  RegisterOtherBankAccountInputParams,
  RegisterOtherBankAccountInputParamsInitialValue,
  RegisterBankAccountParams,
  RegisterOtherBankAccountValidationErrors,
  RegisterJPBankAccountValidationErrors,
} from '../../interfaces/bankInterFace';

export enum AdminAdministratorTabMode {
  ADMINISTRATOR,
  BANK,
}

const AdminApplyPaidFCContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const [tabMode, setTabMode] = useState<AdminAdministratorTabMode>(
    history.location?.state?.ADMIN_ADMINISTRATOR?.mode ??
      AdminAdministratorTabMode.ADMINISTRATOR,
  );
  const [bankType, setBankType] = useState<BankType>(0); // 銀行 or ゆうちょ銀行
  const [administratorParams, setAdministratorParams] = useState<
    UpdateAdministratorInputParams
  >(UpdateAdministratorInputParamsInitialValue); // 管理者情報入力データ
  const [otherBankAccountParams, setOtherBankAccountParams] = useState<
    RegisterOtherBankAccountInputParams
  >(RegisterOtherBankAccountInputParamsInitialValue); // 銀行情報入力データ
  const [jpBankAccountParams, setJPBankAccountParams] = useState<
    RegisterJPBankAccountInputParams
  >(RegisterJPBankAccountInputParamsInitialValue); // ゆうちょ銀行情報入力データ
  const { assortment, banks } = useSelector(selector.util.getSelectFields);
  const {
    ready: authReady,
    profile: {
      fanclubs: { manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const {
    ready,
    loading,
    administrator,
    bank,
    currentBank,
    currentBankBranch,
    address,
    suggestBanks,
    suggestBankBranches,
    administratorSuccess,
    administratorError,
    administratorErrors,
    bankSuccess: bankAccountSuccess,
    bankError,
    bankErrors,
  } = useSelector(selector.adminAdministrator.getAdminAdministratorState);
  const fanclub = useMemo(
    () => manages.find(manage => manage.group_id === artistId),
    [artistId, manages],
  );
  const isManaged = useMemo(() => !!fanclub, [fanclub]);
  const years = useMemo(
    () =>
      getYears(
        administratorParams.birthday_month,
        administratorParams.birthday_date,
      ),
    [administratorParams.birthday_month, administratorParams.birthday_date],
  );
  const months = useMemo(
    () =>
      getMonths(
        administratorParams.birthday_year,
        administratorParams.birthday_date,
      ),
    [administratorParams.birthday_year, administratorParams.birthday_date],
  );
  const dates = useMemo(
    () =>
      getDates(
        administratorParams.birthday_year,
        administratorParams.birthday_month,
      ),
    [administratorParams.birthday_year, administratorParams.birthday_month],
  );
  const selectedBank = useMemo(
    () =>
      suggestBanks.find(
        bank => bank.name === otherBankAccountParams.bank_name,
      ) ?? null,

    [otherBankAccountParams.bank_name, suggestBanks],
  );
  const selectedBankBranch = useMemo(
    () =>
      suggestBankBranches.find(
        branch => branch.name === otherBankAccountParams.store_name,
      ) ?? null,

    [otherBankAccountParams.store_name, suggestBankBranches],
  );
  const canSaveAdministrator = useMemo(
    () =>
      !!administratorParams.name &&
      !!administratorParams.postal_code &&
      !!administratorParams.address &&
      !!administratorParams.birthday_year &&
      !!administratorParams.birthday_month &&
      !!administratorParams.birthday_date &&
      !!administratorParams.tel &&
      !!administratorParams.email,
    [administratorParams],
  );
  const canSaveOtherBankAccount = useMemo(
    () =>
      !!selectedBank &&
      !!selectedBankBranch &&
      otherBankAccountParams.account_number.length === 7 &&
      !!otherBankAccountParams.name &&
      validateBankHolder(otherBankAccountParams.name, 'other').length === 0,
    [
      otherBankAccountParams.name,
      otherBankAccountParams.account_number,
      selectedBank,
      selectedBankBranch,
    ],
  );
  const canSaveJPBankAccount = useMemo(
    () =>
      jpBankAccountParams.sign.length === 5 &&
      jpBankAccountParams.account_number.length === 8 &&
      !!jpBankAccountParams.name &&
      validateBankHolder(jpBankAccountParams.name, 'jp').length === 0,

    [
      jpBankAccountParams.sign,
      jpBankAccountParams.account_number,
      jpBankAccountParams.name,
    ],
  );
  const canSave =
    tabMode === AdminAdministratorTabMode.ADMINISTRATOR
      ? canSaveAdministrator
      : tabMode === AdminAdministratorTabMode.BANK && bankType === 0
      ? canSaveOtherBankAccount
      : tabMode === AdminAdministratorTabMode.BANK && bankType === 1
      ? canSaveJPBankAccount
      : false;
  const administratorValidationErrors: UpdateAdministratorValidationErrors = useMemo(
    () => ({
      artistId: '',
      name: administratorErrors?.name?.[0] || '',
      postal_code: administratorErrors?.postal_code?.[0] || '',
      address: administratorErrors?.address?.[0] || '',
      birthday: administratorErrors?.birthday?.[0] || '',
      tel: administratorErrors?.tel?.[0] || '',
      email:
        administratorErrors?.email?.[0] ||
        validateEmail(administratorParams.email),
      identification_image:
        administratorErrors?.identification_image?.[0] || '',
    }),
    [administratorErrors, administratorParams.email],
  );
  const otherBankAccountValidationErrors: RegisterOtherBankAccountValidationErrors = useMemo(
    () => ({
      banks: '',
      bank_name: bankErrors?.bank_code?.[0] || '',
      store_name: bankErrors?.store_code?.[0] || '',
      assortment: bankErrors?.assortment?.[0] || '',
      account_number: bankErrors?.account_number?.[0] || '',
      name:
        bankErrors?.name?.[0] ||
        validateBankHolder(otherBankAccountParams.name, 'other'),
    }),
    [bankErrors, otherBankAccountParams.name],
  );
  const jpBankAccountValidationErrors: RegisterJPBankAccountValidationErrors = useMemo(
    () => ({
      banks: '',
      sign:
        bankErrors?.store_code?.[0] ||
        validateBankKigou(jpBankAccountParams.sign),
      assortment: bankErrors?.assortment?.[0] || '',
      account_number: bankErrors?.account_number?.[0] || '',
      name:
        bankErrors?.name?.[0] ||
        validateBankHolder(jpBankAccountParams.name, 'jp'),
    }),
    [bankErrors, jpBankAccountParams.sign, jpBankAccountParams.name],
  );

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);
  const handleChangeTab = useCallback((mode: AdminAdministratorTabMode) => {
    setTabMode(mode);
  }, []);
  const handleChangeAdministrator = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.currentTarget;

      if (name === 'postal_code' && !value.match(/^[0-9]*$/)) {
        // 郵便番号: 数値のみ
        e.preventDefault();
      } else if (name === 'tel' && !value.match(/^[0-9]*$/)) {
        // 代表者電話番号: 数値のみ
        e.preventDefault();
      } else {
        setAdministratorParams(params => ({ ...params, [name]: value }));
      }
    },
    [],
  );
  const handleChangeAdministratorImage = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { name, files } = e.currentTarget;

      if (!files) return;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.size > 10000000) {
          // limit 10MB
          window.alert('画像のファイルサイズは10MB以下です');

          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          setAdministratorParams(params => ({
            ...params,
            [name]: {
              file,
              preview: reader.result as string,
            },
          }));
        };
        reader.readAsDataURL(file);
      }
    },
    [],
  );
  const handleChangeBankType = useCallback(
    (e: React.FormEvent<HTMLSelectElement>) => {
      const bankType = Number(e.currentTarget.value);

      if (bankType === 0 || bankType === 1) {
        setBankType(bankType);
      }
    },
    [],
  );
  const handleChangeOtherBankAccount = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.currentTarget;

      if (name === 'account_number' && !value.match(/^[0-9]*$/)) {
        // 口座番号: 数値のみ
        e.preventDefault();
      } else if (name === 'name') {
        e.preventDefault();

        const holder = convert2AccountHolder(value);

        setOtherBankAccountParams(params => ({
          ...params,
          [name]: holder,
        }));
      } else {
        setOtherBankAccountParams(params => ({
          ...params,
          [name]: value,
        }));
      }
    },
    [],
  );
  const handleChangeJPBankAccount = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.currentTarget;

      if (name === 'name') {
        e.preventDefault();

        const holder = convert2AccountHolder(value);

        setJPBankAccountParams(params => ({
          ...params,
          [name]: holder,
        }));
      } else {
        setJPBankAccountParams(params => ({ ...params, [name]: value }));
      }
    },
    [],
  );
  const handleSelectBank = useCallback(
    (code: string) => {
      const selectBank =
        suggestBanks.filter(bank => bank.code === code)[0] || null;

      setOtherBankAccountParams(params => ({
        ...params,
        bank_name: selectBank.name,
      }));
    },
    [suggestBanks],
  );
  const handleSelectBankBranch = useCallback(
    (code: string) => {
      // select bank branch
      const selectBankBranch =
        suggestBankBranches.filter(branch => branch.code === code)[0] || null;

      setOtherBankAccountParams(params => ({
        ...params,
        store_name: selectBankBranch.name,
      }));
    },
    [suggestBankBranches],
  );
  const updateAdministrator = useCallback(
    (artistId: string, inputParams: UpdateAdministratorInputParams) => {
      const {
        identification_image,
        birthday_year,
        birthday_month,
        birthday_date,
        ...data
      } = inputParams;

      const birthday =
        birthday_year || birthday_month || birthday_date
          ? [
              birthday_year,
              birthday_month.toString().padStart(2, '0'),
              birthday_date.toString().padStart(2, '0'),
            ].join('-')
          : '';

      const params: UpdateAdministratorParams = {
        artistId,
        ...data,
        identification_image: identification_image.file,
        birthday,
      };

      dispatch(updateAdministratorEvent.start(params));
    },
    [dispatch],
  );
  const registerOtherBankAccount = useCallback(
    (
      mode: 'create' | 'update',
      artistId: string,
      inputParams: RegisterOtherBankAccountInputParams,
    ) => {
      if (!selectedBank || !selectedBankBranch) {
        // 正しい銀行、支店が選択されていない場合、終了
        return;
      }

      const params: RegisterBankAccountParams = {
        artistId,
        banks: 0,
        bank_code: selectedBank.code,
        store_code: selectedBankBranch.code,
        assortment: inputParams.assortment,
        account_number: inputParams.account_number,
        name: inputParams.name,
      };

      if (mode === 'create') {
        // 振込先口座新規登録
        dispatch(createBankAccountEvent.start(params));
      } else if (mode === 'update') {
        // 振込先口座更新
        dispatch(updateBankAccountEvent.start(params));
      }
    },
    [dispatch, selectedBank, selectedBankBranch],
  );
  const registerJPBankAccount = useCallback(
    (
      mode: 'create' | 'update',
      artistId: string,
      inputParams: RegisterJPBankAccountInputParams,
    ) => {
      const store_code =
        inputParams.sign[0] === '1'
          ? `${inputParams.sign.substr(1, 2)}8`
          : inputParams.sign[0] === '0'
          ? `${inputParams.sign.substr(1, 2)}9`
          : '';

      if (!store_code) {
        window.alert('正しい記号を入力してください');

        return;
      }

      const params: RegisterBankAccountParams = {
        artistId,
        banks: 1,
        bank_code: '9900',
        store_code,
        assortment: inputParams.assortment,
        account_number: inputParams.account_number,
        name: inputParams.name,
        sign: inputParams.sign,
      };

      if (mode === 'create') {
        // 振込先口座新規登録
        dispatch(createBankAccountEvent.start(params));
      } else if (mode === 'update') {
        // 振込先口座更新
        dispatch(updateBankAccountEvent.start(params));
      }
    },
    [dispatch],
  );
  const handleSave = () => {
    if (!artistId) return;

    if (!canSave) return;

    if (tabMode === AdminAdministratorTabMode.ADMINISTRATOR) {
      // タブが管理者情報の場合
      updateAdministrator(artistId, administratorParams);
    } else if (tabMode === AdminAdministratorTabMode.BANK) {
      // タブが振込先口座情報の場合

      const mode = bank === null ? 'create' : 'update';

      if (bankType === 0) {
        registerOtherBankAccount(mode, artistId, otherBankAccountParams);
      } else {
        registerJPBankAccount(mode, artistId, jpBankAccountParams);
      }
    }
  };

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (artistId && isManaged) {
      dispatch(getReady(artistId));
    } else {
      history.replace(PATH.HOME);
    }
  }, [authReady, dispatch, isManaged, artistId, history]);

  // 管理者でない場合、トップへリダイレクト
  useEffect(() => {
    if (ready && (!fanclub || fanclub.pivot?.type !== 3)) {
      history.replace(PATH.HOME);
    }
  }, [fanclub, history, ready]);

  // 現在の管理者情報取得後、入力内容に設定する
  useEffect(() => {
    if (!administrator) return;

    const { birthday, identification_image, ...data } = administrator;

    const [birthday_year, birthday_month, birthday_date] = birthday
      ? birthday.split('-').map(s => Number(s))
      : [0, 0, 0];

    setAdministratorParams({
      ...data,
      identification_image: {
        preview: identification_image,
        file: null,
      },
      birthday_year,
      birthday_month,
      birthday_date,
    });
  }, [administrator]);

  // 現在の口座情報取得後、入力内容に設定する
  useEffect(() => {
    if (!bank) return;

    if (bank.banks === 0) {
      // KRAPのバックエンドから登録中の銀行情報を受け取ったあと、
      // https://bank.teraren.comから銀行名などを取得する
      dispatch(getCurrentBankEvent.start({ bankCode: bank.bank_code }));
      dispatch(
        getCurrentBankBranchEvent.start({
          bankCode: bank.bank_code,
          branchCode: bank.store_code,
        }),
      );
    }

    setBankType(bank.banks);

    if (bank.banks === 0) {
      setOtherBankAccountParams({
        banks: 0,
        account_number: bank.account_number,
        bank_name: '',
        store_name: '',
        assortment: bank.assortment,
        name: bank.name,
      });
    }

    if (bank.banks === 1) {
      setJPBankAccountParams({
        banks: 1,
        sign: bank.sign || '',
        assortment: bank.assortment,
        account_number: bank.account_number,
        name: bank.name,
      });
    }
  }, [bank, dispatch]);

  // https://bank.teraren.comから銀行情報取得後、銀行名入力
  useEffect(() => {
    if (bank?.banks !== 0) return;

    if (currentBank) {
      setOtherBankAccountParams(params => ({
        ...params,
        bank_name: currentBank.name,
      }));
    }
  }, [bank, currentBank]);

  // https://bank.teraren.comから支店情報取得後、支店名入力
  useEffect(() => {
    if (bank?.banks !== 0) return;

    if (otherBankAccountParams.bank_name && currentBankBranch) {
      setOtherBankAccountParams(params => ({
        ...params,
        store_name: currentBankBranch.name,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank, currentBankBranch]);

  // 銀行名入力時に、サジェスト用に銀行リスト取得
  useEffect(() => {
    if (bankType !== 0) return;

    dispatch(
      searchBankEvent.start({
        name: otherBankAccountParams.bank_name,
      }),
    );
  }, [bankType, dispatch, otherBankAccountParams.bank_name]);

  // 支店名入力時に、サジェスト用に支店リスト取得
  useEffect(() => {
    if (bankType !== 0) return;

    if (!selectedBank) return;

    dispatch(
      searchBankBranchEvent.start({
        code: selectedBank.code,
        name: otherBankAccountParams.store_name,
      }),
    );
  }, [bankType, dispatch, otherBankAccountParams.store_name, selectedBank]);

  // 銀行が正しく選択されていない場合、支店情報を初期化する
  useEffect(() => {
    if (!selectedBank) {
      setOtherBankAccountParams(params => ({ ...params, bank_branch: '' }));
    }
  }, [selectedBank]);

  // 郵便番号から住所情報を取得しにいく
  useEffect(() => {
    if (administratorParams.postal_code.length === 7) {
      dispatch(
        getAddressEvent.start({
          method: 'searchByPostal',
          postal: administratorParams.postal_code,
        }),
      );
    }
  }, [dispatch, administratorParams.postal_code]);

  useEffect(() => {
    if (address) {
      setAdministratorParams(administratorParams => ({
        ...administratorParams,
        address: address.prefecture + address.city,
      }));
    }
  }, [address]);

  useEffect(() => {
    if (administratorSuccess) {
      window.alert('管理者情報が更新されました。');
    }

    if (bankAccountSuccess) {
      window.alert('振込先口座情報が更新されました。');
    }

    if (administratorError) {
      window.alert('管理者情報の更新に失敗しました。');
    }

    if (bankError) {
      window.alert('振込先口座情報の更新に失敗しました。');
    }
  }, [administratorError, administratorSuccess, bankAccountSuccess, bankError]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            管理者情報編集 | {fanclub?.name} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      <AdminAdministrator
        fanclubName={fanclub?.site_name}
        tabMode={tabMode}
        fixedPrice={administrator.price}
        administratorParams={administratorParams}
        bankType={bankType}
        otherBankAccountParams={otherBankAccountParams}
        jpBankAccountParams={jpBankAccountParams}
        yearList={years}
        monthList={months}
        dateList={dates}
        bankTypeList={banks}
        accountTypeList={assortment}
        suggestBankList={suggestBanks}
        suggestBankBranchList={suggestBankBranches}
        selectedBank={selectedBank}
        selectedBankBranch={selectedBankBranch}
        handleChangeTab={handleChangeTab}
        handleChangeAdministrator={handleChangeAdministrator}
        handleChangeAdministratorImage={handleChangeAdministratorImage}
        handleChangeBankType={handleChangeBankType}
        handleChangeOtherBankAccount={handleChangeOtherBankAccount}
        handleChangeJPBankAccount={handleChangeJPBankAccount}
        handleSelectBank={handleSelectBank}
        handleSelectBankBranch={handleSelectBankBranch}
        canInputBankBranch={!!selectedBank}
        handleSave={handleSave}
        canSave={canSave}
        handleBack={handleBack}
        administratorErrors={administratorValidationErrors}
        otherBankAccountErrors={otherBankAccountValidationErrors}
        jpBankAccountErrors={jpBankAccountValidationErrors}
      />
    </>
  );
};

export default AdminApplyPaidFCContainer;
