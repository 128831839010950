/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  withdrawPaidFCEvent,
  cleanup,
} from '../../actions/withdrawPaidFCActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { Fanclub } from '../../interfaces/fanclubInterFace';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';

import WithdrawPaidFCSelect from '../../components/setting/withdrawPaidFCSelect';
import WithdrawPaidFCConfirm from '../../components/setting/withdrawPaidFCConfirm';
import WithdrawPaidFCComplete from '../../components/setting/withdrawPaidFCComplete';

const WithdrawPaidFCContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [select, setSelect] = useState<Fanclub | undefined>(undefined);
  const {
    ready: authReady,
    profile: {
      user,
      fanclubs: { follows },
    },
  } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const { ready, loading, error, success } = useSelector(
    selector.withdrawPaidFC.getWithdrawPaidFCState,
  );

  const fanclubList = useMemo(() => {
    return follows.filter(fanclub => fanclub.pivot?.type === 1);
  }, [follows]);

  const handleBack = useCallback(() => {
    history.push(PATH.SETTING_MENU);
  }, [history]);

  const handleClickFanclub = useCallback(
    (artistId: string) => {
      if (!artistId) return;

      history.push(PATH.getArtistPath(artistId));
    },
    [history],
  );
  const handleSelectFC = useCallback(
    (artistId: string) => {
      if (!artistId) return;

      const fanclub = fanclubList.find(
        fanclub => fanclub.group_id === artistId,
      );

      setSelect(fanclub);
    },
    [fanclubList],
  );

  const handleReleaseSelect = useCallback(() => {
    setSelect(undefined);
  }, []);

  const handleWithdrawPaidFC = useCallback(() => {
    if (!select) return;

    const res = window.confirm('本当に退会しますか？');

    if (!res) return;

    dispatch(withdrawPaidFCEvent.start({ artistId: select.group_id }));
  }, [dispatch, select]);

  const handleLinkToTop = useCallback(() => {
    history.push(PATH.HOME);
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      nav: success,
      copyright: true,
    }),
    [success],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (authReady && !isLogin) {
      history.push(PATH.SIGNUP);
    }

    dispatch(getReady());
  }, [authReady, dispatch, history, isLogin]);

  useEffect(() => {
    if (error) window.alert('退会に失敗しました。');
  }, [error]);

  return (
    <>
      <Helmet>
        <title>コミュニティ退会 | 設定 | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      {success ? (
        <WithdrawPaidFCComplete onLinkToTop={handleLinkToTop} />
      ) : select ? (
        <WithdrawPaidFCConfirm
          userId={user.name_id}
          select={select}
          onBack={handleReleaseSelect}
          onWithdrawPaidFC={handleWithdrawPaidFC}
        />
      ) : (
        <WithdrawPaidFCSelect
          userId={user.name_id}
          fanclubList={fanclubList}
          onBack={handleBack}
          onClickFanclub={handleClickFanclub}
          onWithdrawPaidFC={handleSelectFC}
        />
      )}
    </>
  );
};

export default WithdrawPaidFCContainer;
