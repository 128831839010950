import * as ActionType from './twitterConstants';
import {
  TwitterAuth,
  AuthenticateParams,
} from '../interfaces/twitterInterFace';

export const getTwitterAuthUrlEvent = {
  start: () =>
    ({
      type: ActionType.GET_TWITTER_AUTH_URL_START,
    } as const),

  succeed: (result: TwitterAuth) =>
    ({
      type: ActionType.GET_TWITTER_AUTH_URL_SUCCEED,
      payload: result,
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_TWITTER_AUTH_URL_FAIL,
    } as const),
};

export const authenticateEvent = {
  start: (params: AuthenticateParams) =>
    ({
      type: ActionType.AUTHENTICATE_START,
      payload: params,
    } as const),

  succeed: (message: 'login' | 'create') =>
    ({
      type: ActionType.AUTHENTICATE_SUCCEED,
      payload: message,
    } as const),

  fail: () =>
    ({
      type: ActionType.AUTHENTICATE_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // get twitter auth url
  | ReturnType<typeof getTwitterAuthUrlEvent.start>
  | ReturnType<typeof getTwitterAuthUrlEvent.succeed>
  | ReturnType<typeof getTwitterAuthUrlEvent.fail>
  // authenticate
  | ReturnType<typeof authenticateEvent.start>
  | ReturnType<typeof authenticateEvent.succeed>
  | ReturnType<typeof authenticateEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
