import { User, UserInitialValue } from './userInterFace';
import { Status } from './commonInterFace';

export type chat = {
  id: number;
  group_id: string;
  user_id: number;
  reply_id: number | null;
  chat: string;
  created_at: string | null;
  updated_at: string | null;
  deleted_at: string | null;
  user: User | null;
};

export const chatInitialValue: chat = {
  id: 0,
  group_id: '',
  user_id: 0,
  reply_id: null,
  chat: '',
  created_at: null,
  updated_at: null,
  deleted_at: null,
  user: UserInitialValue,
};

export type getChatResponse = {
  status_code: Status.OK;
  result: {
    chats: chat[];
    already_read_chat_id: number;
    unread_count: number;
  };
};

export type submitChatParam = {
  artistId: string;
  chat: string;
};

export type getUnreadChatResponse = {
  status_code: Status.OK;
  result: {
    unread_count: number;
  };
};

export type deleteChatParam = {
  chatId: number;
  artistId: string;
};
