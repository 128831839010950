import { call, put, fork, takeLeading } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/openFCRegisterConstants';
import { checkAndSetStatusError } from './authSagas';
import { getUserProfileEvent as updateMe } from '../actions/authActions';
import { createFanclubEvent } from '../actions/openFCRegisterActions';

import { Status } from '../interfaces/commonInterFace';
import {
  CreateFanclubResponse,
  CreateFanclubErrorResponse,
} from '../interfaces/fanclubInterFace';

// create fanclub
const createFanclubHandler = api.getFormDataFactory('POST');
function* runCreateFanclub(
  handler: typeof createFanclubHandler,
  action: ReturnType<typeof createFanclubEvent.start>,
) {
  const data = action.payload;

  try {
    const ReturnData: CreateFanclubResponse = yield call(
      handler,
      data,
      '/fanclub',
    );

    if (!ReturnData) {
      yield put(createFanclubEvent.fail({}));

      return;
    }

    yield put(updateMe.start());

    yield put(createFanclubEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as CreateFanclubErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(createFanclubEvent.fail(res.errors));

      return;
    }

    yield put(createFanclubEvent.fail({}));
  }
}
export function* createFanclub(handler: typeof createFanclubHandler) {
  yield takeLeading(ActionType.CREATE_FANCLUB_START, runCreateFanclub, handler);
}

export const openFCRegisterSagas = [fork(createFanclub, createFanclubHandler)];
