/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import LabelTitle from '../../atoms/title/TitleDiv';
import InputFileHero from '../../atoms/formParts/InputFileHero';

import McsInputSecondary from '../../molecules/formParts/McsInputSecondary';
import McsSelectBirthSecondary from '../../molecules/formParts/McsSelectBirthSecondary';

import { SelectField } from '../../../../interfaces/utilInterFace';
import {
  UpdateAdministratorInputParams,
  UpdateAdministratorInputParamsInitialValue,
  UpdateAdministratorValidationErrors,
  UpdateAdministratorValidationErrorsInitialValue,
} from '../../../../interfaces/administratorInterFace';

import * as styles from './OrgAdminFormAdministrator.module.scss';

interface Props {
  administratorParams?: UpdateAdministratorInputParams;
  price?: number;
  yearList?: SelectField;
  monthList?: SelectField;
  dateList?: SelectField;
  handleChangeAdministrator?: (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  handleChangeAdministratorImage?: (
    e: React.FormEvent<HTMLInputElement>,
  ) => void;
  cssClass?: string;
  administratorErrors?: UpdateAdministratorValidationErrors;
}

const YEN = '\u00a5';
const OrgAdminFormAdministrator: FC<Props> = ({
  administratorParams = UpdateAdministratorInputParamsInitialValue,
  price = 0,
  yearList = [],
  monthList = [],
  dateList = [],
  handleChangeAdministrator = () => undefined,
  handleChangeAdministratorImage = () => undefined,
  cssClass = '',
  administratorErrors = UpdateAdministratorValidationErrorsInitialValue,
}) => {
  const classNames = ClassNames(styles.container, cssClass);

  return (
    <div className={classNames}>
      <McsInputSecondary
        value={administratorParams.name}
        type="Senary"
        inputType="text"
        inputName="name"
        placeholder="代表者氏名を入力"
        maxLength={50}
        isHiddenMaxLengthInfo
        labelTitle="代表者氏名"
        onChange={handleChangeAdministrator}
        isError={!!administratorErrors.name}
        errorText={administratorErrors.name}
      />
      <div className={styles.input}>
        <LabelTitle
          text="郵便番号(半角数字ハイフンなし)"
          cssClass={styles.label}
        />
        <McsInputSecondary
          value={administratorParams.postal_code}
          type="Senary"
          inputType="text"
          inputName="postal_code"
          maxLength={7}
          isHiddenMaxLengthInfo
          placeholder="1234567"
          onChange={handleChangeAdministrator}
          cssClass={styles.inputPostal}
          isError={!!administratorErrors.address}
          errorText={administratorErrors.address}
        />
      </div>
      <McsInputSecondary
        value={administratorParams.address}
        type="Senary"
        inputType="text"
        inputName="address"
        placeholder="住所を入力"
        maxLength={100}
        isHiddenMaxLengthInfo
        labelTitle="住所"
        cssClass={styles.input}
        onChange={handleChangeAdministrator}
        isError={!!administratorErrors.address}
        errorText={administratorErrors.address}
      />
      <McsSelectBirthSecondary
        inputName1="birthday_year"
        inputName2="birthday_month"
        inputName3="birthday_date"
        placeholder1="年"
        placeholder2="月"
        placeholder3="日"
        value1={administratorParams.birthday_year.toString()}
        value2={administratorParams.birthday_month.toString()}
        value3={administratorParams.birthday_date.toString()}
        labelTitle="代表者生年月日"
        selectList1={yearList}
        selectList2={monthList}
        selectList3={dateList}
        onChange={handleChangeAdministrator}
        cssClass={styles.input}
        isError={!!administratorErrors.birthday}
        errorText={administratorErrors.birthday}
      />
      <McsInputSecondary
        value={administratorParams.tel}
        type="Senary"
        inputType="tel"
        inputName="tel"
        placeholder="電話番号を入力"
        maxLength={11}
        isHiddenMaxLengthInfo
        labelTitle="代表者電話番号（半角数字ハイフンなし）"
        cssClass={styles.input}
        onChange={handleChangeAdministrator}
        isError={!!administratorErrors.tel}
        errorText={administratorErrors.tel}
      />
      <McsInputSecondary
        value={administratorParams.email}
        type="Senary"
        inputType="email"
        inputName="email"
        placeholder="メールアドレスを入力"
        labelTitle="代表者メールアドレス"
        cssClass={styles.input}
        onChange={handleChangeAdministrator}
        isError={!!administratorErrors.email}
        errorText={administratorErrors.email}
      />
      <div className={styles.input}>
        <LabelTitle text="月額会費" cssClass={styles.label} />
        <McsInputSecondary
          value={`${YEN}${price}`}
          type="Senary"
          isDisabled
          cssClass={styles.inputPrice}
        />
      </div>
      <div className={styles.input}>
        <LabelTitle text="身分証明書の画像" cssClass={styles.label} />
        <InputFileHero
          title="身分証明書画像"
          inputNameHero="identification_image"
          type="Quaternary"
          preview={administratorParams.identification_image.preview}
          onChange={handleChangeAdministratorImage}
          isError={!!administratorErrors.identification_image}
          errorText={administratorErrors.identification_image}
        />
      </div>
      {/* <p className={styles.read}>
          ※身分証明書の例）免許証、旅券(パスポート)、在留カード、特別永住者証明書、外国人登録証明書（在留の資格が特別永住者のものに限ります。）
        </p> */}
    </div>
  );
};

export default OrgAdminFormAdministrator;
