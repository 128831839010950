import { Reducer } from 'redux';
import { Actions } from '../actions/signUpActions';
import * as ActionType from '../actions/signUpConstants';
import {
  CheckDuplicateEmailErrors,
  SignUpErrors,
} from '../interfaces/authInterFace';

type State = {
  loading: boolean;
  errors: {
    check: CheckDuplicateEmailErrors;
    signUp: SignUpErrors;
  };
  success: {
    check: boolean;
    signup: boolean;
  };
};

const initialState: State = {
  loading: false,
  errors: {
    check: {},
    signUp: {},
  },
  success: {
    check: false,
    signup: false,
  },
};

const signupReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // check duplicate email
    case ActionType.CHECK_DUPLICATE_EMAIL_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.CHECK_DUPLICATE_EMAIL_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: {
          ...state.success,
          check: true,
        },
      };
    }
    case ActionType.CHECK_DUPLICATE_EMAIL_FAIL: {
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          check: action.payload.errors,
        },
      };
    }

    // signup
    case ActionType.SIGNUP_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.SIGNUP_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: {
          ...state.success,
          signup: true,
        },
      };
    }
    case ActionType.SIGNUP_FAIL: {
      return {
        ...state,
        loading: false,
        errors: {
          ...state.errors,
          signUp: action.payload.errors,
        },
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default signupReducer;
