/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  SortType,
  RefineSearch,
  RefineSearchInitialValue,
} from '../../interfaces/searchInterFace';
import { GetFCFollowerListParams } from '../../interfaces/followerInterFace';
import {
  getReady,
  getFCFollowerListEvent,
  blockFollowerEvent,
  cleanup,
} from '../../actions/adminFollowersActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminFollowers from '../../components/admin/adminFollowers';

const AdminFollowersContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const [isOpenSortModal, setOpenSortModal] = useState(false);
  const [isOpenRefineSearchModal, setOpenRefineSearchModal] = useState(false);
  const [sortMode, setSortMode] = useState<SortType>('DESC');
  const [refineSearchParams, setRefineSearchParams] = useState<RefineSearch>(
    RefineSearchInitialValue,
  );
  const [searchParams, setSearchParams] = useState<GetFCFollowerListParams>({
    artistId: '',
    sort_type: 'DESC',
    user_type: 3,
  });

  const {
    ready: authReady,
    profile: {
      fanclubs: { manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const { ready, details, followers, pager, loading, success } = useSelector(
    selector.adminFollowers.getAdminFollowersState,
  );
  const isLastPage = useSelector(selector.adminFollowers.getIsLastPage);
  const fanclub = useMemo(
    () => manages.find(manage => manage.group_id === artistId),
    [artistId, manages],
  );

  const handleOpenRefineSearchModal = useCallback(() => {
    setOpenRefineSearchModal(true);
  }, []);
  const handleCloseRefineSearchModal = useCallback(() => {
    setOpenRefineSearchModal(false);
  }, []);

  const handleChangeRefineSearchWord = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setRefineSearchParams({
        ...refineSearchParams,
        word: e.currentTarget.value,
      });
    },
    [refineSearchParams],
  );

  const handleClickRefineSearchAttributeButton = useCallback(
    (name: 'free' | 'paid') => {
      let { free } = refineSearchParams.attributes;
      let { paid } = refineSearchParams.attributes;
      if (name === 'free') {
        free = !free;
      } else {
        paid = !paid;
      }
      setRefineSearchParams({
        ...refineSearchParams,
        attributes: {
          free,
          paid,
        },
      });
    },
    [refineSearchParams],
  );

  const handleSubmitRefineSearch = useCallback(() => {
    dispatch(
      getFCFollowerListEvent.start({
        ...searchParams,
      }),
    );
    setOpenRefineSearchModal(false);
  }, [dispatch, searchParams]);

  const handleBlockUser = useCallback(
    (userId: string) => {
      if (!artistId) return;

      const res = window.confirm('本当にブロックしますか？');
      if (!res) return;

      dispatch(
        blockFollowerEvent.start({
          artistId,
          block_user: userId,
        }),
      );
    },
    [dispatch, artistId],
  );
  const handleClickBlockList = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'BLOCKS'));
  }, [artistId, history]);
  const handleReadMore = useCallback(() => {
    if (!artistId) return;

    dispatch(
      getFCFollowerListEvent.start({
        ...searchParams,
        page: pager.current_page + 1,
      }),
    );
  }, [dispatch, artistId, pager.current_page, searchParams]);
  const handleToggleSort = useCallback(() => {
    setOpenSortModal(value => !value);
  }, []);

  const handleSelectSort = useCallback(
    (index: number) => {
      if (!artistId) return;

      const sortMode = index === 0 ? 'DESC' : 'ASC';
      setSortMode(sortMode);
      dispatch(
        getFCFollowerListEvent.start({
          ...searchParams,
          sort_type: sortMode,
        }),
      );
      setOpenSortModal(false);
    },
    [dispatch, artistId, searchParams],
  );

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!artistId || !fanclub) {
      history.replace(PATH.HOME);

      return;
    }

    dispatch(getReady(artistId));
  }, [authReady, dispatch, fanclub, artistId, history]);

  useEffect(() => {
    if (!success || !artistId) return;

    history.replace(PATH.getArtistPath(artistId));
  }, [history, artistId, success]);

  useEffect(() => {
    if (!artistId) return;
    let user_type = 0;
    if (
      refineSearchParams.attributes.free &&
      refineSearchParams.attributes.paid
    ) {
      user_type = 3;
    } else if (refineSearchParams.attributes.paid) {
      user_type = 2;
    } else if (refineSearchParams.attributes.free) {
      user_type = 1;
    }
    setSearchParams({
      artistId,
      sort_type: sortMode,
      keyword: refineSearchParams.word,
      user_type,
    });
  }, [artistId, sortMode, refineSearchParams]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            フォロワー管理 | {fanclub?.name ?? ''} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      <AdminFollowers
        details={details}
        followerList={followers}
        sortMode={sortMode}
        refineSearchParams={refineSearchParams}
        isAdmin={fanclub?.pivot?.type === 3}
        isOpenSortModal={isOpenSortModal}
        isOpenRefineSearchModal={isOpenRefineSearchModal}
        isViewReadMoreButton={!isLastPage}
        handleClickRefineSearchButton={handleOpenRefineSearchModal}
        handleClickRefineSearchCross={handleCloseRefineSearchModal}
        handleClickRefineSearchBlur={handleCloseRefineSearchModal}
        handleChangeRefineSearchWord={handleChangeRefineSearchWord}
        handleClickRefineSearchAttributeButton={
          handleClickRefineSearchAttributeButton
        }
        handleSubmitRefineSearch={handleSubmitRefineSearch}
        handleBlockUser={handleBlockUser}
        handleClickBlockList={handleClickBlockList}
        handleReadMore={handleReadMore}
        handleClickSort={handleToggleSort}
        handleClickSortBg={handleToggleSort}
        handleSelectSort={handleSelectSort}
        handleBack={handleBack}
      />
    </>
  );
};

export default AdminFollowersContainer;
