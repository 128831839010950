import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './ButtonDialog.module.scss';

interface Props {
  type?: string;
  cssClass?: string;
  onClick?: () => void;
}

const Button: FC<Props> = ({
  type = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.ButtonBase, cssClass, {
    [styles.ButtonPrimary]: type === 'Primary', // Primary
    [styles.ButtonSecondary]: type === 'Secondary', //Secondary
  });

  return (
    <div className={classNames}>
      <button type="button" onClick={onClick}></button>
    </div>
  );
};

export default Button;
