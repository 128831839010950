/* eslint-disable import/no-cycle */
import { Status, Pager } from './commonInterFace';
import { UserForSearch } from './userInterFace';

export type SortType = 'ASC' | 'DESC';

export type RefineSearchAttributes = 'free' | 'paid';

export type RefineSearch = {
  word: string;
  attributes: Record<RefineSearchAttributes, boolean>;
};
export const RefineSearchInitialValue: RefineSearch = {
  word: '',
  attributes: {
    free: true,
    paid: true,
  },
};

export type SearchUserListParams = {
  keyword: string;
  fanclub?: string;
  limit?: number;
};
export type SearchUserListResult = {
  users: Pager & {
    data: UserForSearch[];
  };
};
export type SearchUserListResponse = {
  status_code: Status.OK;
  result: SearchUserListResult;
};
