export const GET_READY = 'FOLLOW_ARTIST/GET_READY' as const;
export const GOT_READY = 'FOLLOW_ARTIST/GOT_READY' as const;

export const GET_RECOMMEND_FANCLUB_LIST_START = 'FOLLOW_ARTIST/GET_RECOMMEND_FANCLUB_LIST_START' as const;
export const GET_RECOMMEND_FANCLUB_LIST_SUCCEED = 'FOLLOW_ARTIST/GET_RECOMMEND_FANCLUB_LIST_SUCCEED' as const;
export const GET_RECOMMEND_FANCLUB_LIST_FAIL = 'FOLLOW_ARTIST/GET_RECOMMEND_FANCLUB_LIST_FAIL' as const;

export const FREEJOIN_FANCLUB_START = 'FOLLOW_ARTIST/FREEJOIN_FANCLUB_START' as const;
export const FREEJOIN_FANCLUB_SUCCEED = 'FOLLOW_ARTIST/FREEJOIN_FANCLUB_SUCCEED' as const;
export const FREEJOIN_FANCLUB_FAIL = 'FOLLOW_ARTIST/FREEJOIN_FANCLUB_FAIL' as const;

export const WITHDRAW_FANCLUB_START = 'FOLLOW_ARTIST/WITHDRAW_FANCLUB_START' as const;
export const WITHDRAW_FANCLUB_SUCCEED = 'FOLLOW_ARTIST/WITHDRAW_FANCLUB_SUCCEED' as const;
export const WITHDRAW_FANCLUB_FAIL = 'FOLLOW_ARTIST/WITHDRAW_FANCLUB_FAIL' as const;

export const CLEANUP = 'FOLLOW_ARTIST/CLEANUP' as const;
