import * as ActionType from './adminFollowersConstants';
import {
  GetFanclubDetailsParams,
  GetFanclubDetailsResult,
} from '../interfaces/fanclubInterFace';
import {
  GetFCFollowerListParams,
  GetFCFollowerListResult,
  BlockFollowerParams,
  BlockFollowerResult,
} from '../interfaces/followerInterFace';

export const getReady = (artistId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getFanclubDetailsEvent = {
  start: (params: GetFanclubDetailsParams) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetFanclubDetailsResult) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_FAIL,
    } as const),
};

export const getFCFollowerListEvent = {
  start: (params: GetFCFollowerListParams) =>
    ({
      type: ActionType.GET_FC_FOLLOWER_LIST_START,
      payload: params,
    } as const),

  succeed: (params: GetFCFollowerListParams, result: GetFCFollowerListResult) =>
    ({
      type: ActionType.GET_FC_FOLLOWER_LIST_SUCCEED,
      payload: { params, result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FC_FOLLOWER_LIST_FAIL,
    } as const),
};

export const blockFollowerEvent = {
  start: (params: BlockFollowerParams) =>
    ({
      type: ActionType.BLOCK_FOLLOWER_START,
      payload: params,
    } as const),

  succeed: (params: BlockFollowerParams, result: BlockFollowerResult) =>
    ({
      type: ActionType.BLOCK_FOLLOWER_SUCCEED,
      payload: { params, result },
    } as const),

  fail: () =>
    ({
      type: ActionType.BLOCK_FOLLOWER_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fanclub details
  | ReturnType<typeof getFanclubDetailsEvent.start>
  | ReturnType<typeof getFanclubDetailsEvent.succeed>
  | ReturnType<typeof getFanclubDetailsEvent.fail>
  // get fc follower list
  | ReturnType<typeof getFCFollowerListEvent.start>
  | ReturnType<typeof getFCFollowerListEvent.succeed>
  | ReturnType<typeof getFCFollowerListEvent.fail>
  // block follower
  | ReturnType<typeof blockFollowerEvent.start>
  | ReturnType<typeof blockFollowerEvent.succeed>
  | ReturnType<typeof blockFollowerEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
