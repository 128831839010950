import React, { FC } from 'react';
import ClassNames from 'classnames';

import SelectBox from '../../atoms/formParts/SelectBox';
import LabelTitle from '../../atoms/title/TitleDiv';
import LabelTag from '../../atoms/label/LabelTag';

import { SelectListObject } from '../../../../interfaces/formPartsInterFace';

import * as styles from './McsSelect.module.scss';

interface Props {
  value: string;
  inputName?: string;
  placeholder?: string;
  maxLength?: number | undefined;
  cssClass?: string;

  selectList: SelectListObject[];
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.FormEvent<HTMLSelectElement>, key?: any) => void;

  isDisabled?: boolean;

  isError?: boolean;
  errorText?: string;

  labelTitle?: string | undefined;
  labelTag?: string | undefined;
}

const McsSelect: FC<Props> = ({
  value = '',
  inputName = '',
  placeholder = '',
  cssClass = '',
  selectList = [],
  onClick = () => undefined,
  onFocus = () => undefined,
  onBlur = () => undefined,
  onChange = () => undefined,

  isDisabled = false,

  isError = false,
  errorText = 'Error',

  labelTitle = '',
  labelTag = '',
}) => {
  const classNames = ClassNames(styles.McsSelectBase, cssClass);

  return (
    <div className={classNames}>
      {labelTitle !== '' && (
        <div className={styles.McsSelectLabel}>
          <LabelTitle text={labelTitle} />
          {labelTag !== '' && <LabelTag text={labelTag} />}
        </div>
      )}
      <SelectBox
        value={value}
        selectList={selectList}
        inputName={inputName}
        placeholder={placeholder}
        onChange={onChange}
        type="Tertiary"
        isError={isError}
        errorText={errorText}
      />
    </div>
  );
};

export default McsSelect;
