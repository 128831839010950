import React, { FC } from 'react';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import McsTitlePrimary from '../common/molecules/titleParts/McsTitlePrimary';
import McsInputSecondary from '../common/molecules/formParts/McsInputSecondary';

import * as styles from './changePassword.module.scss';

import {
  ChangePasswordParams,
  ChangePasswordParamsInitialValue,
  ChangePasswordValidationErrors,
  ChangePasswordValidationErrorsInitialValue,
} from '../../interfaces/passwordInterFace';

type Props = {
  userId?: string;
  params?: ChangePasswordParams;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  onBackEvent?: () => void;
  onChangePassWordEvent?: () => void;
  errors?: ChangePasswordValidationErrors;
};

const Component: FC<Props> = ({
  userId = '',
  params = ChangePasswordParamsInitialValue,
  onChange = () => undefined,
  onBackEvent = () => undefined,
  onChangePassWordEvent = () => undefined,
  errors = ChangePasswordValidationErrorsInitialValue,
}) => {
  return (
    <div className={styles.containerWrap}>
      <McsTitlePrimary
        mainText="パスワード変更"
        subText={`@${userId}`}
        onClick={onBackEvent}
        cssClass={styles.title}
      />

      <div className={styles.wrapper}>
        <div className={styles.read}>
          <p>
            新しいパスワードを入力し、「パスワード変更」ボタンをタップしてください。
          </p>
        </div>

        <McsInputSecondary
          value={params.current_password}
          inputType="password"
          inputName="current_password"
          placeholder="現在のパスワードを入力"
          labelTitle="現在のパスワード"
          cssClass={styles.inputBox}
          onChange={onChange}
          isError={!!errors.current_password}
          errorText={errors.current_password}
        />
        <McsInputSecondary
          value={params.new_password}
          inputType="password"
          inputName="new_password"
          placeholder="新しいパスワードを入力"
          labelTitle="新しいパスワード"
          cssClass={styles.inputBox}
          onChange={onChange}
          isError={!!errors.new_password}
          errorText={errors.new_password}
        />
        <McsInputSecondary
          value={params.new_password_confirmation}
          inputType="password"
          inputName="new_password_confirmation"
          placeholder="新しいパスワードを入力"
          labelTitle="新しいパスワード（確認用）"
          onChange={onChange}
          isError={!!errors.new_password_confirmation}
          errorText={errors.new_password_confirmation}
        />
        <div className={styles.buttonWrap}>
          <ButtonRoundedCorners
            text="パスワードを変更する"
            type="Primary"
            size="Primary"
            onClick={onChangePassWordEvent}
            cssClass={styles.button}
          />
          <ButtonRoundedCorners
            text="キャンセル"
            type="Quinary"
            size="Primary"
            onClick={onBackEvent}
            cssClass={styles.cancel}
          />
        </div>
      </div>
    </div>
  );
};

export default Component;
