import * as ActionType from './openFCRegisterConstants';
import {
  CreateFanclubParams,
  CreateFanclubResult,
  CreateFanclubErrors,
} from '../interfaces/fanclubInterFace';

export const createFanclubEvent = {
  start: (params: CreateFanclubParams) =>
    ({
      type: ActionType.CREATE_FANCLUB_START,
      payload: params,
    } as const),

  succeed: (result: CreateFanclubResult) =>
    ({
      type: ActionType.CREATE_FANCLUB_SUCCEED,
      payload: { result },
    } as const),

  fail: (errors: CreateFanclubErrors) =>
    ({
      type: ActionType.CREATE_FANCLUB_FAIL,
      payload: { errors },
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // create fanclub
  | ReturnType<typeof createFanclubEvent.start>
  | ReturnType<typeof createFanclubEvent.succeed>
  | ReturnType<typeof createFanclubEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
