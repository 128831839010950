import React, { FC } from 'react';
import ClassNames from 'classnames';
import TitleH2 from '../common/atoms/title/TitleH2';
import McsButtonWithCheck from '../common/molecules/formParts/McsButtonWithCheck';
import McsInputPrimary from '../common/molecules/formParts/McsInputPrimary';
import * as styles from './login.module.scss';

interface Props {
  email?: string;
  password?: string;
  onLoginEvent?: () => void;
  onSaveCheckEvent?: () => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  isCheck?: boolean;
}

const Component: FC<Props> = ({
  email = '',
  password = '',
  onLoginEvent = () => undefined,
  onSaveCheckEvent = () => undefined,
  onChange = () => undefined,
  isCheck = false,
}) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      <TitleH2 text="パスワード再発行完了" cssClass={styles.title} />
      <div className={styles.read}>
        <p>
          新しいパスワードの登録が完了しました。 改めてログインしてください。
        </p>
      </div>

      <McsInputPrimary
        value={email}
        inputType="email"
        inputName="email"
        placeholder="メールアドレスを入力"
        onChange={onChange}
        cssClass={styles.inputBox}
      />
      <McsInputPrimary
        value={password}
        inputType="password"
        inputName="password"
        placeholder="新しいパスワードを入力"
        onChange={onChange}
      />

      <div className={styles.buttonWrap}>
        <McsButtonWithCheck
          buttonText="ログイン"
          checkBoxLabel="保存する"
          onClick={onLoginEvent}
          onChange={onSaveCheckEvent}
          checked={isCheck}
        />
      </div>
    </div>
  );
};

export default Component;
