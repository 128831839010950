export const GET_READY = 'ADMIN_APPLY_PAID_FC/GET_READY' as const;
export const GOT_READY = 'ADMIN_APPLY_PAID_FC/GOT_READY' as const;

export const GET_ADDRESS_START = 'ADMIN_APPLY_PAID_FC/GET_ADDRESS_START' as const;
export const GET_ADDRESS_SUCCEED = 'ADMIN_APPLY_PAID_FC/GET_ADDRESS_SUCCEED' as const;
export const GET_ADDRESS_FAIL = 'ADMIN_APPLY_PAID_FC/GET_ADDRESS_FAIL' as const;

export const APPLY_PAID_FC_START = 'ADMIN_APPLY_PAID_FC/APPLY_PAID_FC_START' as const;
export const APPLY_PAID_FC_SUCCEED = 'ADMIN_APPLY_PAID_FC/APPLY_PAID_FC_SUCCEED' as const;
export const APPLY_PAID_FC_FAIL = 'ADMIN_APPLY_PAID_FC/APPLY_PAID_FC_FAIL' as const;

export const CLEANUP = 'ADMIN_APPLY_PAID_FC/CLEANUP' as const;
