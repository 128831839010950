import * as ActionType from './adminFCClosedConstants';
import {
  GetFanclubDetailsParams,
  GetFanclubDetailsResult,
} from '../interfaces/fanclubInterFace';

export const getReady = (artistId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getFanclubDetailsEvent = {
  start: (params: GetFanclubDetailsParams) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetFanclubDetailsResult) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_FAIL,
    } as const),
};

export const closedFanclubEvent = {
  start: (params: GetFanclubDetailsParams) =>
    ({
      type: ActionType.CLOSED_FANCLUB_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.CLOSED_FANCLUB_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.CLOSED_FANCLUB_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fanclub details
  | ReturnType<typeof getFanclubDetailsEvent.start>
  | ReturnType<typeof getFanclubDetailsEvent.succeed>
  | ReturnType<typeof getFanclubDetailsEvent.fail>
  // close fanclub
  | ReturnType<typeof closedFanclubEvent.start>
  | ReturnType<typeof closedFanclubEvent.succeed>
  | ReturnType<typeof closedFanclubEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
