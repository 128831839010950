import { Status } from './commonInterFace';

import { SelectListObject } from './formPartsInterFace';
import { GenreDetails } from './genreInterFace';

export type SelectField = Array<SelectListObject>;

export type SelectFields = {
  prefectures: SelectField;
  genders: SelectField;
  open_area: SelectField;
  genres: GenreDetails[];
  contact_type: SelectField;
  copyright: SelectField;
  assortment: SelectField;
  banks: SelectField;
};

export const SelectFieldsInitialValue: SelectFields = {
  prefectures: [],
  genders: [],
  open_area: [],
  genres: [],
  contact_type: [],
  copyright: [],
  assortment: [],
  banks: [],
};

export type GetSelectFieldsResult = SelectFields;
export type GetSelectFieldsResponse = {
  status_code: Status.OK;
  result: SelectFields;
};

export type InputFile = {
  file: File | null;
  preview: string;
};
export const InputFileInitialValue: InputFile = {
  file: null,
  preview: '',
};

export type HeaderMode = '' | 'menu' | 'search';
