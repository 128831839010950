export const GET_READY = 'CHAT/GET_READY' as const;
export const GOT_READY = 'CHAT/GOT_READY' as const;

export const GET_FANCLUB_DETAILS_START = 'CHAT/GET_FANCLUB_DETAILS_START' as const;
export const GET_FANCLUB_DETAILS_SUCCEED = 'CHAT/GET_FANCLUB_DETAILS_SUCCEED' as const;
export const GET_FANCLUB_DETAILS_FAIL = 'CHAT/GET_FANCLUB_DETAILS_FAIL' as const;

export const GET_CHAT_START = 'CHAT/GET_CHAT_START' as const;
export const GET_CHAT_SUCCEED = 'CHAT/GET_CHAT_SUCCEED' as const;
export const GET_CHAT_FAIL = 'CHAT/GET_CHAT_FAIL' as const;

export const SUBMIT_CHAT_START = 'CHAT/SUBMIT_CHAT_START' as const;
export const SUBMIT_CHAT_SUCCEED = 'CHAT/SUBMIT_CHAT_SUCCEED' as const;
export const SUBMIT_CHAT_FAIL = 'CHAT/SUBMIT_CHAT_FAIL' as const;

export const CHAT_READ_START = 'CHAT/CHAT_READ_START' as const;
export const CHAT_READ_SUCCEED = 'CHAT/CHAT_READ_SUCCEED' as const;
export const CHAT_READ_FAIL = 'CHAT/CHAT_READ_FAIL' as const;

export const CHAT_DELETE_START = 'CHAT/CHAT_DELETE_START' as const;
export const CHAT_DELETE_SUCCEED = 'CHAT/CHAT_DELETE_SUCCEED' as const;
export const CHAT_DELETE_FAIL = 'CHAT/CHAT_DELETE_FAIL' as const;

export const CLEANUP = 'CHAT/CLEANUP' as const;
