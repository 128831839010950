import React, { FC } from 'react';
import ClassNames from 'classnames';
import TitleH2 from '../common/atoms/title/TitleH2';
import TitleDiv from '../common/atoms/title/TitleDiv';
import ButtonSquare from '../common/atoms/buttonBasic/ButtonSquare';
import CheckBoxButton from '../common/atoms/formParts/CheckBoxButton';
import * as styles from './attachGenres.module.scss';
import { GenreWithSelected } from '../../interfaces/genreInterFace';

interface Props {
  genres?: GenreWithSelected[][];
  onChange?: (genreId: number) => void;
  onStartKrapEvent?: () => void;
  disable?: boolean;
}

const Component: FC<Props> = ({
  genres = [],
  onChange = () => undefined,
  onStartKrapEvent = () => undefined,
  disable = false,
}) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      <TitleH2 text="アカウント作成が完了しました。" cssClass={styles.title} />
      <div className={styles.read}>
        <p>KRAPをお楽しみください。</p>
        <p>
          好きなジャンルを3つ以上選んで、お気に入りのアーティストを見つけよう。
        </p>
      </div>

      <TitleDiv text="あなたの好きなジャンル" cssClass={styles.contentTitle} />

      {genres.map((group, i) => (
        <div key={`group-${i.toString()}`} className={styles.checkBoxWrap}>
          {group.map(genre => (
            <CheckBoxButton
              key={genre.id}
              checked={genre.selected}
              labelText={genre.name}
              onChange={() => onChange(genre.id)}
              cssClass={styles.checkBox}
            />
          ))}
        </div>
      ))}

      <ButtonSquare
        text="KRAPを始める"
        type={disable ? 'Tertiary' : 'Primary'}
        size="Secondary"
        onClick={onStartKrapEvent}
        cssClass={styles.button}
      />
    </div>
  );
};

export default Component;
