import React, { FC } from 'react';
import ClassNames from 'classnames';

import VisaCard from '../../assets/images/card-visa.svg';
import MasterCard from '../../assets/images/card-master.svg';
import AmexCard from '../../assets/images/card-amex.svg';
import JCBCard from '../../assets/images/card-jcb.svg';
import DinersCard from '../../assets/images/card-diners.svg';

import * as styles from './OrgCanUseCardInfo.module.scss';

interface Props {
  cssClass?: string;
}

const CARD_LIST = [VisaCard, MasterCard, AmexCard, JCBCard, DinersCard];

const OrgCanUseCardInfo: FC<Props> = ({ cssClass = '' }) => {
  const classNames = ClassNames(styles.base, cssClass);

  return (
    <div className={classNames}>
      <ul className={styles.cardList}>
        {CARD_LIST.map((card, i) => (
          <li key={`cardIcon${i.toString()}`} className={styles.card}>
            <img src={card} alt="card" />
          </li>
        ))}
      </ul>
      <p className={styles.read}>
        {
          '上記の国際ブランド（Visa、MasterCard、AMEX、JCB、Diners）の\nクレジットカードに対応しております。'
        }
      </p>
    </div>
  );
};

export default OrgCanUseCardInfo;
