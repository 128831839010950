/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable consistent-return */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import Pusher from 'pusher-js';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  cleanup,
  submitChatEvent,
  chatReadEvent,
  chatDeleteEvent,
} from '../../actions/chatActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { chat, chatInitialValue } from '../../interfaces/chatInterFace';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgOnboarding from '../../components/common/organisms/onboarding/OrgOnBoarding';

import {
  getChatOnboardCookie,
  setChatOnboardCookie,
} from '../../utils/utilHelper';

import OnboardingImage1 from '../../components/common/assets/images/chat-onboarding_01.png';
import OnboardingImage2 from '../../components/common/assets/images/chat-onboarding_02.png';

import Chat from '../../components/artist/chat';

const onboardingImageList = [OnboardingImage1, OnboardingImage2];

const ChatContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const { funClub, chats, ready, loading } = useSelector(
    selector.chat.getChatState,
  );
  const {
    ready: authReady,
    profile: { user },
  } = useSelector(selector.auth.getAuthState);

  const [onboard, setOnboard] = useState(false);
  const [position, setPosition] = useState(true);
  const [chatList, setChatList] = useState<chat[]>([]);
  const [newChat, setNewChat] = useState<chat>(chatInitialValue);
  const [chatInput, setChatInput] = useState('');
  const [deleteId, setDeleteId] = useState(0);
  const [deleteList, setDeleteList] = useState<number[]>([]);
  // Pusherの接続用変数設定
  const pusher_key = process.env.REACT_APP_PUSHER_KEY;
  const pusher_cluster = process.env.REACT_APP_PUSHER_CLUSTER;

  // チャット用のオンボーディング既読処理
  const handleFinishOnboarding = useCallback(() => {
    setOnboard(true);
    setChatOnboardCookie();
  }, []);

  // ユーザーアイコンクリック時の処理
  const handleClickUser = useCallback(
    (userId: string) => {
      if (!userId) return;

      history.push(PATH.getUserPath(userId));
    },
    [history],
  );

  // チャットの削除クリック時の処理
  const handleClickDelete = useCallback(
    (chatId: number) => {
      if (!artistId) return;
      const res = window.confirm('本当にこのコメントを削除しますか？');

      if (!res) return;
      dispatch(chatDeleteEvent.start({ chatId, artistId }));
    },
    [dispatch, artistId],
  );

  // FCメンバーのID配列取得
  const membersId = useMemo(() => {
    const members: number[] = [];
    funClub.members.map(member => members.push(member.id));

    return members;
  }, [funClub]);

  // 管理者かどうかチェック
  const admin = useMemo(() => {
    return funClub.user_type === 3;
  }, [funClub]);

  // チャットが入力された時の処理
  const handleChangeInput = useCallback(
    (e: React.FormEvent<HTMLTextAreaElement>) => {
      setChatInput(e.currentTarget.value);
    },
    [],
  );

  // チャットが送信された時の処理
  const handleSubmitInput = useCallback(() => {
    const trimmedComment = chatInput.trim();

    if (trimmedComment.length === 0 || !artistId) return;

    if (trimmedComment.length <= 280) {
      dispatch(
        submitChatEvent.start({
          chat: chatInput,
          artistId,
        }),
      );
      setChatInput('');
    } else {
      window.alert(
        `280文字以内で入力してください（現在:${trimmedComment.length}文字）`,
      );
    }
  }, [dispatch, chatInput, artistId]);

  // フッターのオプション設定
  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(() => {
    return {
      paidFC: {
        timeline: {
          state: false,
          onClick: () =>
            artistId ? history.push(PATH.getArtistPath(artistId)) : undefined,
        },
        chat: {
          state: true,
        },
      },
    };
  }, [artistId, history]);
  useFooter(footerOptions);

  // グーグルアナリティクスの設定
  useGA(history.location, ready);

  // Pusherの設定
  useEffect(() => {
    if (!pusher_key || !pusher_cluster) return;
    // キーを設定
    const pusher = new Pusher(pusher_key, {
      cluster: pusher_cluster,
      auth: {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
    });
    // sentチャンネルの購読(追加)
    const channel = pusher.subscribe('chat');
    channel.bind('App\\Events\\MessageSent', data => {
      // pusherから受け取ったデータが同アーティストならstate更新
      if (artistId && artistId === data.chat.group_id) {
        setNewChat(data.chat);
        dispatch(chatReadEvent.start(artistId));
      }
    });

    // deleteチャンネルの購読(削除)
    channel.bind('App\\Events\\MessageDelete', data => {
      // pusherから受け取ったデータが同アーティストならstate更新
      if (artistId && artistId === data.chat.group_id) {
        setDeleteId(data.chat.id);
      }
    });

    return () => {
      // Pusherの接続終了
      pusher.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [artistId, dispatch, pusher_key, pusher_cluster]);

  // 削除リスト更新
  useEffect(() => {
    setPosition(false);
    setDeleteList([...deleteList, deleteId]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteId]);

  // データ追加
  useEffect(() => {
    setPosition(true);
    setChatList([...chatList, newChat]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newChat]);

  // 初回データ取得とクリーンナップ設定
  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);
  useEffect(() => {
    if (!authReady || !artistId) return;

    dispatch(getReady(artistId));
  }, [artistId, authReady, dispatch, history]);

  // 初回データ取得時にチャット一覧をstateに設定
  useEffect(() => {
    setChatList(chats.chats);
  }, [chats]);

  // スクロール位置の設定
  useEffect(() => {
    if (!position) return;
    const a = document.documentElement;
    const y = a.scrollHeight - a.clientHeight;
    const check = y - window.scrollY < a.clientHeight * 0.8;

    if (
      (chats.unread_count === 0 && newChat.user_id === 0) ||
      newChat.user_id === user.id ||
      (newChat.user_id !== 0 && check)
    ) {
      window.scroll(0, y);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatList]);

  // コンテンツ投稿用オンボーディングの終了確認
  useEffect(() => {
    const onboard = getChatOnboardCookie();
    setOnboard(!!onboard);
  }, []);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>KRAP</title>
        ) : (
          <title>チャット | {funClub.fanclub.name} | KRAP</title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      {/* オンボーディング */}
      {onboard ? null : (
        <OrgOnboarding
          imageList={onboardingImageList}
          onClickStart={handleFinishOnboarding}
        />
      )}

      <Chat
        userId={user.id}
        chats={chatList}
        alreadyReadId={chats.already_read_chat_id}
        unreadCount={chats.unread_count}
        membersId={membersId}
        chatInput={chatInput}
        admin={admin}
        deleteList={deleteList}
        handleChangeInput={handleChangeInput}
        handleSubmitInput={handleSubmitInput}
        handleClickUser={handleClickUser}
        handleClickDelete={handleClickDelete}
      />
    </>
  );
};

export default ChatContainer;
