import { Reducer } from 'redux';
import { Actions } from '../actions/adminVoiceRegisterActions';
import * as ActionType from '../actions/adminVoiceRegisterConstants';

import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../interfaces/fanclubInterFace';
import { Category, AddFCCategoryErrors } from '../interfaces/categoryInterFace';
import {
  ContentVoice,
  ContentVoiceInitialValue,
  ContentDetails,
  ContentDetailsInitialValue,
  UpdateVoiceErrors,
  CreateVoiceErrors,
} from '../interfaces/contentInterFace';

type State = {
  details: FanclubDetails;
  current: ContentDetails;
  categories: Category[];
  ready: boolean;
  loading: boolean;
  addCategorySuccess: boolean;
  addCategoryErrors: AddFCCategoryErrors;
  registerVoiceSuccess: boolean;
  contentVoiceResult: ContentVoice;
  registerVoiceErrors: CreateVoiceErrors & UpdateVoiceErrors;
  deleteVoiceSuccess: boolean;
  deleteVoiceError: boolean;
};

export const initialState: State = {
  details: FanclubDetailsInitialValue,
  current: ContentDetailsInitialValue,
  categories: [],
  ready: false,
  loading: false,
  addCategorySuccess: false,
  addCategoryErrors: {},
  registerVoiceSuccess: false,
  contentVoiceResult: ContentVoiceInitialValue,
  registerVoiceErrors: {},
  deleteVoiceSuccess: false,
  deleteVoiceError: false,
};

const adminVoiceRegisterReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fanclub details
    case ActionType.GET_FANCLUB_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_SUCCEED: {
      return {
        ...state,
        details: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // get voice
    case ActionType.GET_VOICE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_VOICE_SUCCEED: {
      return {
        ...state,
        current: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_VOICE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // get fc category list
    case ActionType.GET_FC_CATEGORY_LIST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FC_CATEGORY_LIST_SUCCEED: {
      return {
        ...state,
        categories: action.payload.result.categories,
        loading: false,
      };
    }
    case ActionType.GET_FC_CATEGORY_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // add fc category
    case ActionType.ADD_FC_CATEGORY_START: {
      return {
        ...state,
        loading: true,
        addCategorySuccess: false,
        addCategoryErrors: {},
      };
    }
    case ActionType.ADD_FC_CATEGORY_SUCCEED: {
      return {
        ...state,
        categories: [
          ...state.categories,
          {
            id: action.payload.result.category_id,
            name: action.payload.result.name,
          },
        ],
        loading: false,
        addCategorySuccess: true,
      };
    }
    case ActionType.ADD_FC_CATEGORY_FAIL: {
      return {
        ...state,
        loading: false,
        addCategoryErrors: action.payload.errors,
      };
    }

    // create voice
    case ActionType.CREATE_VOICE_START: {
      return {
        ...state,
        loading: true,
        registerVoiceSuccess: false,
        registerVoiceErrors: {},
      };
    }
    case ActionType.CREATE_VOICE_SUCCEED: {
      return {
        ...state,
        loading: false,
        registerVoiceSuccess: true,
        contentVoiceResult: action.payload.result.content,
      };
    }
    case ActionType.CREATE_VOICE_FAIL: {
      return {
        ...state,
        loading: false,
        registerVoiceErrors: action.payload.errors,
      };
    }

    // update voice
    case ActionType.UPDATE_VOICE_START: {
      return {
        ...state,
        loading: true,
        registerVoiceSuccess: false,
        registerVoiceErrors: {},
      };
    }
    case ActionType.UPDATE_VOICE_SUCCEED: {
      return {
        ...state,
        loading: false,
        registerVoiceSuccess: true,
        contentVoiceResult: action.payload.result.content,
      };
    }
    case ActionType.UPDATE_VOICE_FAIL: {
      return {
        ...state,
        loading: false,
        registerVoiceErrors: action.payload.errors,
      };
    }

    // delete voice
    case ActionType.DELETE_VOICE_START: {
      return {
        ...state,
        loading: true,
        deleteVoiceSuccess: false,
        deleteVoiceError: false,
      };
    }
    case ActionType.DELETE_VOICE_SUCCEED: {
      return {
        ...state,
        loading: false,
        deleteVoiceSuccess: true,
      };
    }
    case ActionType.DELETE_VOICE_FAIL: {
      return {
        ...state,
        loading: false,
        deleteVoiceError: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default adminVoiceRegisterReducer;
