import React, { FC, useCallback } from 'react';

import {
  User,
  UserWithType,
  UserWithTypeInitialValue,
} from '../../interfaces/userInterFace';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';
import TitleH1 from '../common/atoms/title/TitleH1';

import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';
import McsInputSecondary from '../common/molecules/formParts/McsInputSecondary';

import OrgUserListUnitMembernQuinary from '../common/organisms/listUnitParts/OrgUserListUnitMembernQuinary';
import OrgUserListUnitMemberQuaternary from '../common/organisms/listUnitParts/OrgUserListUnitMemberQuaternary';

import OrgInviteMemberList from '../common/organisms/listParts/OrgInviteMemberList';

import * as styles from './adminMembers.module.scss';

interface Props {
  fanclubName?: string;
  me?: UserWithType;
  memberList?: UserWithType[];
  isViewAddMembers?: boolean;
  text?: string;
  userList?: User[];
  openDialogIndex?: number;
  handleCloseDialog?: () => void;
  handleChangeDialogOpen?: (index: number) => void;
  handleDeleteMember?: (userId: string) => void;
  handleTransferAuthority?: (userId: string) => void;
  handleChangeSearchText?: (
    e: React.FormEvent<HTMLInputElement>,
    key?: string,
  ) => void;
  handleAddFanclubMember?: (userId: string) => void;
  handleBack?: () => void;
  error?: string;
}

const Component: FC<Props> = ({
  fanclubName = '',
  me = UserWithTypeInitialValue,
  memberList = [],
  isViewAddMembers = false,
  text = '',
  userList = [],
  openDialogIndex = 0,
  handleCloseDialog = () => undefined,
  handleChangeDialogOpen = () => undefined,
  handleDeleteMember = () => undefined,
  handleTransferAuthority = () => undefined,
  handleChangeSearchText = () => undefined,
  handleAddFanclubMember = () => undefined,
  handleBack = () => undefined,
  error = '',
}) => {
  const handleDialog = useCallback(
    (index: number, userId: string) => {
      if (index === 0) {
        handleDeleteMember(userId);
      }

      if (index === 1) {
        handleTransferAuthority(userId);
      }
    },
    [handleDeleteMember, handleTransferAuthority],
  );

  return (
    <div className={styles.containerWrap}>
      <McsTitleSecondary
        mainText="メンバー管理"
        subText={fanclubName}
        onClick={handleBack}
        cssClass={styles.contentTitle}
      />

      <ul>
        <OrgUserListUnitMembernQuinary
          key="me"
          user={me}
          isAdmin={me.type === 3}
          tagText="脱退する"
          onClickButton={() => handleDeleteMember(me.name_id)}
          cssClass={styles.content}
        />
        {memberList.map((member, i) =>
          me.type === 3 ? (
            <OrgUserListUnitMemberQuaternary
              user={member}
              isAdmin={member.type === 3}
              onClickButton={() => handleChangeDialogOpen(i)}
              isDialog={i === openDialogIndex}
              onShowDialog={index => handleDialog(index, member.name_id)}
              onHideDialog={handleCloseDialog}
              cssClass={styles.content}
            />
          ) : (
            <OrgUserListUnitMembernQuinary
              user={member}
              isButtonHidden
              isAdmin={member.type === 3}
              cssClass={styles.content}
            />
          ),
        )}
      </ul>

      {isViewAddMembers && (
        <div className={styles.addContainer}>
          <TitleH1 text="メンバーを追加する" cssClass={styles.title} />
          <div>
            <McsInputSecondary
              type="Senary"
              value={text}
              inputType="text"
              inputName="artist_name"
              placeholder="ニックネーム・ID・メールアドレスを入力"
              onChange={handleChangeSearchText}
              isError={!!error}
              errorText={error}
            />
          </div>
          <div className={styles.contentWrap}>
            <OrgInviteMemberList
              userList={userList}
              onInviteClick={handleAddFanclubMember}
              isAdminPage
            />
          </div>
        </div>
      )}

      <div className={styles.buttonWrap}>
        <ButtonRoundedCorners
          text="戻る"
          type="Quinary"
          size="Primary"
          onClick={handleBack}
          cssClass={styles.cancel}
        />
      </div>
    </div>
  );
};

export default Component;
