import React, { FC } from 'react';
import ClassNames from 'classnames';

import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';

import OrgAdminListLikeUnit from '../common/organisms/listUnitParts/OrgAdminListLikeUnit';

import * as styles from './adminContents.module.scss';
import {
  Fanclub,
  FanclubInitialValue,
} from '../../interfaces/fanclubInterFace';
import { Content } from '../../interfaces/contentInterFace';

interface Props {
  fanclub?: Fanclub;
  contents?: Content[];

  onClickContent?: (content: Content) => void;
  onBack?: () => void;
}

const Component: FC<Props> = ({
  fanclub = FanclubInitialValue,
  contents = [],

  onClickContent = () => undefined,
  onBack = () => undefined,
}) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      <McsTitleSecondary
        mainText="いいね"
        subText={fanclub.site_name}
        onClick={onBack}
        cssClass={styles.contentTitle}
      />

      <ul className={styles.contentListWrap}>
        {contents.map((content, i) => (
          <OrgAdminListLikeUnit
            key={content.id}
            content={content}
            rank={i + 1}
            cssClass={styles.contentList}
            onClick={() => onClickContent(content)}
          />
        ))}
      </ul>
    </div>
  );
};

export default Component;
