import React, { FC } from 'react';
import ClassNames from 'classnames';

import TitleH3 from '../../atoms/title/TitleH3';

import OrgFCListUnitQuinary from '../listUnitParts/OrgFCListUnitQuinary';

import { Fanclub } from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgUtiltyCommunityFCList.module.scss';

type PartialFanclub = Partial<Fanclub>;
type Props = {
  listTitle?: string;
  fanclubList?: PartialFanclub[];
  onClick?: (artistId: string) => void;
  cssClass?: string;
};

const OrgFCList: FC<Props> = ({
  listTitle = '',
  fanclubList = [],
  onClick = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListBase, cssClass);

  return (
    <div className={classNames}>
      <TitleH3 text={listTitle} cssClass={styles.OrgFCListTitle} />
      <ul className={styles.OrgFCListWrap}>
        {fanclubList.map((item, i) => (
          <OrgFCListUnitQuinary
            fanclub={item}
            key={`OrgFCListUnitQuinary${i.toString()}`}
            onClick={() => onClick(item.group_id || '')}
          />
        ))}
      </ul>
    </div>
  );
};

export default OrgFCList;
