/* eslint-disable no-nested-ternary */
/* eslint-disable no-bitwise */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  getAddressEvent,
  applyPaidFCEvent,
  cleanup,
} from '../../actions/adminApplyPaidFCActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';
import { getYears, getMonths, getDates } from '../../utils/datetimeHelper';
import { validateEmail } from '../../utils/validationHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminApplyPaidFC from '../../components/admin/adminApplyPaidFC';

import {
  ApplyPaidFCParams,
  ApplyPaidFCInputParams,
  ApplyPaidFCInputParamsInitialValue,
  ApplyPaidFCValidationErrors,
} from '../../interfaces/fanclubInterFace';

const YEN_UNICODE = '\u00a5';

const AdminApplyPaidFCContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const [params, setParams] = useState<ApplyPaidFCInputParams>(
    ApplyPaidFCInputParamsInitialValue,
  );
  const {
    ready: authReady,
    profile: {
      fanclubs: { manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const { ready, address, loading, errors, success } = useSelector(
    selector.adminApplyPaidFC.getAdminApplyPaidFCState,
  );
  const fanclub = useMemo(
    () => manages.find(manage => manage.group_id === artistId),
    [artistId, manages],
  );
  const isManaged = useMemo(() => !!fanclub, [fanclub]);
  const years = useMemo(
    () => getYears(params.birthday_month, params.birthday_date),
    [params.birthday_month, params.birthday_date],
  );
  const months = useMemo(
    () => getMonths(params.birthday_year, params.birthday_date),
    [params.birthday_year, params.birthday_date],
  );
  const dates = useMemo(
    () => getDates(params.birthday_year, params.birthday_month),
    [params.birthday_year, params.birthday_month],
  );
  const canApply = useMemo(
    () =>
      !!params.name &&
      params.postal_code.length === 7 &&
      !!params.address &&
      !!params.birthday_year &&
      !!params.birthday_month &&
      !!params.birthday_date &&
      params.tel.length >= 10 &&
      !!params.email &&
      !!params.identification_image.file &&
      !!params.price,
    [params],
  );

  const validationErrors: ApplyPaidFCValidationErrors = useMemo(
    () => ({
      name: errors.name?.[0] ?? '',
      postal_code: errors.postal_code?.[0] ?? '',
      address: errors.address?.[0] ?? '',
      birthday: errors.birthday?.[0] ?? '',
      tel: errors.tel?.[0] ?? '',
      email: errors.email?.[0] ?? validateEmail(params.email),
      price: errors.price?.[0] ?? '',
      identification_image: errors.identification_image?.[0] ?? '',
    }),
    [errors, params.email],
  );

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);
  const handleChange = useCallback(
    (e: React.FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      const { name, value } = e.currentTarget;

      setParams(params => ({ ...params, [name]: value }));
    },
    [],
  );
  const handleChangeImage = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { name, files } = e.currentTarget;

      if (!files) return;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.size > 10000000) {
          // limit 10MB
          window.alert('画像のファイルサイズは10MB以下です');

          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          setParams(params => ({
            ...params,
            [name]: {
              file,
              preview: reader.result as string,
            },
          }));
        };
        reader.readAsDataURL(file);
      }
    },
    [],
  );
  const handleBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (e.currentTarget.name === 'price') {
        const priceNumber = Number(params.price.replace(YEN_UNICODE, ''));

        const price =
          Number.isNaN(priceNumber) || priceNumber < 300
            ? `${YEN_UNICODE}300` // 最低300
            : priceNumber > 1000000
            ? `${YEN_UNICODE}1000000` // 最高100万
            : `${YEN_UNICODE}${Math.floor(priceNumber / 100) * 100}`;

        setParams(params => ({ ...params, price }));
      }
    },
    [params.price],
  );
  const handleApply = () => {
    if (!artistId) return;

    const {
      price,
      identification_image,
      birthday_year,
      birthday_month,
      birthday_date,
      ...data
    } = params;

    const priceNumber = Number(price.replace(YEN_UNICODE, ''));

    const birthday =
      birthday_year || birthday_month || birthday_date
        ? [
            birthday_year,
            birthday_month.toString().padStart(2, '0'),
            birthday_date.toString().padStart(2, '0'),
          ].join('-')
        : '';

    const payload: ApplyPaidFCParams = {
      artistId,
      ...data,
      identification_image: identification_image.file,
      birthday,
      price: Number.isNaN(priceNumber) ? 0 : priceNumber,
    };

    dispatch(applyPaidFCEvent.start(payload));
  };

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (isManaged) {
      dispatch(getReady());
    } else {
      history.replace(PATH.HOME);
    }
  }, [authReady, dispatch, history, isManaged]);

  useEffect(() => {
    if (!ready) return;

    if (!fanclub || fanclub.pivot?.type !== 3) {
      history.replace(PATH.HOME);

      return;
    }

    setParams(params => ({
      ...params,
      price: `${YEN_UNICODE}300`,
      email: fanclub.representative_email,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, ready]);

  // 郵便番号から住所情報を取得しにいく
  useEffect(() => {
    if (params.postal_code.length === 7) {
      dispatch(
        getAddressEvent.start({
          method: 'searchByPostal',
          postal: params.postal_code,
        }),
      );
    }
  }, [dispatch, params.postal_code]);

  useEffect(() => {
    if (address) {
      setParams(params => ({
        ...params,
        address: address.prefecture + address.city,
      }));
    }
  }, [address]);

  useEffect(() => {
    if (!success || !artistId) return;

    history.replace(PATH.getAdminPath(artistId, 'MENU'));
  }, [history, artistId, success]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            コミュニティ有料化申請 | {fanclub?.name} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      <AdminApplyPaidFC
        fanclubName={fanclub?.site_name}
        params={params}
        yearList={years}
        monthList={months}
        dateList={dates}
        handleChange={handleChange}
        handleChangeImage={handleChangeImage}
        handleBlur={handleBlur}
        handleApply={handleApply}
        handleBack={handleBack}
        errors={validationErrors}
        canApply={canApply}
      />
    </>
  );
};

export default AdminApplyPaidFCContainer;
