import * as ActionType from './settingConstants';
import { GetCardInfoResult } from '../interfaces/cardInterFace';

export const getReady = () =>
  ({
    type: ActionType.GET_READY,
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getCardInfoEvent = {
  start: () =>
    ({
      type: ActionType.GET_CARD_INFO_START,
    } as const),

  succeed: (result: GetCardInfoResult) =>
    ({
      type: ActionType.GET_CARD_INFO_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_CARD_INFO_FAIL,
    } as const),
};

export const changeReceptionSettingsEvent = {
  start: () =>
    ({
      type: ActionType.CHANGE_RECEPTION_SETTING_START,
    } as const),

  succeed: () =>
    ({
      type: ActionType.CHANGE_RECEPTION_SETTING_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.CHANGE_RECEPTION_SETTING_FAIL,
    } as const),
};

export const cleanup = () => ({
  type: ActionType.CLEANUP,
});

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get card info
  | ReturnType<typeof getCardInfoEvent.start>
  | ReturnType<typeof getCardInfoEvent.succeed>
  | ReturnType<typeof getCardInfoEvent.fail>
  // メールの受取設定変更
  | ReturnType<typeof changeReceptionSettingsEvent.start>
  | ReturnType<typeof changeReceptionSettingsEvent.succeed>
  | ReturnType<typeof changeReceptionSettingsEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
