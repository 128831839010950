import * as ActionType from './contactConstants';

import {
  SubmitContactParams,
  SubmitContactErrors,
} from '../interfaces/contactInterFace';

export const submitContactEvent = {
  start: (params: SubmitContactParams) => ({
    type: ActionType.SUBMIT_CONTACT_START,
    payload: params,
  }),

  succeed: () => ({
    type: ActionType.SUBMIT_CONTACT_SUCCEED,
  }),

  fail: (errors: SubmitContactErrors) => ({
    type: ActionType.SUBMIT_CONTACT_FAIL,
    payload: { errors },
  }),
};

export const cleanup = () => ({
  type: ActionType.CLEANUP,
});

export type Actions =
  // submit contact
  | ReturnType<typeof submitContactEvent.start>
  | ReturnType<typeof submitContactEvent.succeed>
  | ReturnType<typeof submitContactEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
