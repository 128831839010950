/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, join, takeLatest } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/newsConstants';
import { checkAndSetStatusError } from './authSagas';
import { getUnreadCountEvent } from '../actions/authActions';
import {
  gotReady,
  getNotificationListEvent,
  markAsReadEvent,
  markAllAsReadEvent,
} from '../actions/newsActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import {
  GetNotificationListResponse,
  GetNotificationAndMarkAsReadResponse,
  MarkAllAsReadResponse,
} from '../interfaces/newsInterFace';

// initialize
function* runGetReady() {
  const getNotificationListTask = yield fork(
    runGetNotificationList,
    getNotificationListHandler,
    getNotificationListEvent.start({ page: 1 }),
  );
  yield join(getNotificationListTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get notification list
const getNotificationListHandler = api.getGetFactory(true);
function* runGetNotificationList(
  handler: typeof getNotificationListHandler,
  action: ReturnType<typeof getNotificationListEvent.start>,
) {
  const data = action.payload;

  try {
    const ReturnData: GetNotificationListResponse = yield call(
      handler,
      data,
      `/notifications`,
    );

    if (!ReturnData) {
      yield put(getNotificationListEvent.fail());

      return;
    }

    yield put(getNotificationListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getNotificationListEvent.fail());
  }
}
export function* getNotificationList(
  handler: typeof getNotificationListHandler,
) {
  yield takeLatest(
    ActionType.GET_NOTIFICATION_LIST_START,
    runGetNotificationList,
    handler,
  );
}

// mark as read
const markAsReadHandler = api.getGetFactory(true);
function* runMarkAsRead(
  handler: typeof markAsReadHandler,
  action: ReturnType<typeof markAsReadEvent.start>,
) {
  const { notificationId } = action.payload;

  try {
    const ReturnData: GetNotificationAndMarkAsReadResponse = yield call(
      handler,
      {},
      `/notifications/${notificationId}`,
    );

    if (!ReturnData) {
      yield put(markAsReadEvent.fail());

      return;
    }

    yield put(getUnreadCountEvent.start());

    yield put(markAsReadEvent.succeed(action.payload));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(markAsReadEvent.fail());
  }
}
export function* markAsRead(handler: typeof markAsReadHandler) {
  yield takeLatest(ActionType.MARK_AS_READ_START, runMarkAsRead, handler);
}

// mark all as read
const markAllAsReadHandler = api.getGetFactory(true);
function* runMarkAllAsRead(handler: typeof markAllAsReadHandler) {
  try {
    const ReturnData: MarkAllAsReadResponse = yield call(
      handler,
      {},
      '/notifications/all',
    );

    if (!ReturnData) {
      yield put(markAllAsReadEvent.fail());

      return;
    }

    yield put(getUnreadCountEvent.start());

    yield put(markAllAsReadEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(markAllAsReadEvent.fail());
  }
}
export function* markAllAsRead(handler: typeof markAllAsReadHandler) {
  yield takeLatest(
    ActionType.MARK_ALL_AS_READ_START,
    runMarkAllAsRead,
    handler,
  );
}

export const newsSagas = [
  fork(getReady),
  fork(getNotificationList, getNotificationListHandler),
  fork(markAsRead, markAsReadHandler),
  fork(markAllAsRead, markAllAsReadHandler),
];
