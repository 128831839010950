/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import * as styles from './OrgSortModal.module.scss';

interface Props {
  cssClass?: string;
  list?: any[];
  actList?: any;
  onClick?: (index: number) => void;
}

const OrgSortModal: FC<Props> = ({
  cssClass = '',
  list = [1, 2, 3],
  actList = 0,
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgSortModalBase, cssClass);

  return (
    <ul className={classNames}>
      {list.map((item, i) => (
        <li
          className={
            item === actList
              ? styles.OrgSortModalItemAct
              : styles.OrgSortModalItem
          }
          key={`Dialog${i}`}
          onClick={() => onClick(i)}
        >
          {item}
        </li>
      ))}
    </ul>
  );
};

export default OrgSortModal;
