import * as ActionType from './paidjoinConstants';
import {
  GetFanclubDetailsParams,
  GetFanclubDetailsResult,
  PaidjoinFanclubParams,
} from '../interfaces/fanclubInterFace';
import { GetCardInfoResult } from '../interfaces/cardInterFace';

export const getReady = (artistId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getFanclubDetailsEvent = {
  start: (params: GetFanclubDetailsParams) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetFanclubDetailsResult) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_FAIL,
    } as const),
};

export const getCardInfoEvent = {
  start: () =>
    ({
      type: ActionType.GET_CARD_INFO_START,
    } as const),

  succeed: (result: GetCardInfoResult) =>
    ({
      type: ActionType.GET_CARD_INFO_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_CARD_INFO_FAIL,
    } as const),
};

export const paidjoinFanclubEvent = {
  start: (params: PaidjoinFanclubParams) =>
    ({
      type: ActionType.PAIDJOIN_FANCLUB_START,
      payload: params,
    } as const),

  succeed: (createdAt: string) =>
    ({
      type: ActionType.PAIDJOIN_FANCLUB_SUCCEED,
      payload: createdAt,
    } as const),

  fail: () =>
    ({
      type: ActionType.PAIDJOIN_FANCLUB_FAIL,
    } as const),
};

export const resetApiStatus = () =>
  ({
    type: ActionType.RESET_API_STATUS,
  } as const);

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fanclub details
  | ReturnType<typeof getFanclubDetailsEvent.start>
  | ReturnType<typeof getFanclubDetailsEvent.succeed>
  | ReturnType<typeof getFanclubDetailsEvent.fail>
  // get card info
  | ReturnType<typeof getCardInfoEvent.start>
  | ReturnType<typeof getCardInfoEvent.succeed>
  | ReturnType<typeof getCardInfoEvent.fail>
  // paid join
  | ReturnType<typeof paidjoinFanclubEvent.start>
  | ReturnType<typeof paidjoinFanclubEvent.succeed>
  | ReturnType<typeof paidjoinFanclubEvent.fail>
  // cleanup
  | ReturnType<typeof resetApiStatus>
  // cleanup
  | ReturnType<typeof cleanup>;
