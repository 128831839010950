import { Reducer } from 'redux';
import { Actions } from '../actions/adminCommentsActions';
import * as ActionType from '../actions/adminCommentsConstants';

import { Pager, PagerInitialValue } from '../interfaces/commonInterFace';
import { CommentDetails } from '../interfaces/commentInterFace';

type State = {
  comments: CommentDetails[];
  pager: Pager;
  ready: boolean;
  loading: boolean;
  error: boolean;
};

export const initialState: State = {
  comments: [],
  pager: PagerInitialValue,
  ready: false,
  loading: false,
  error: false,
};

const adminCommentsReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get comment list
    case ActionType.GET_COMMENT_LIST_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_COMMENT_LIST_SUCCEED: {
      const { data, ...pager } = action.payload.result;

      return {
        ...state,
        comments: [...state.comments, ...data],
        pager,
        loading: false,
      };
    }
    case ActionType.GET_COMMENT_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default adminCommentsReducer;
