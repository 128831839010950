/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { FC, useCallback, useEffect, useState, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { logoutEvent, getUnreadCountEvent } from '../../actions/authActions';
import {
  getFindFanclubListEvent,
  freejoinFanclubEvent,
  withdrawFanclubEvent,
} from '../../actions/searchActions';
import { changeHeaderMode } from '../../actions/utilActions';
import selector from '../../selectors';

import { useHistory } from '../../hooks';
import { disableScroll, enableScroll } from '../../utils/scrollHelper';
import { PATH } from '../../utils/routerHelper';

import Header from '../../components/header/header';
import { FanclubWithFollowedAndManagedListWithKey } from '../../interfaces/fanclubInterFace';

const HeaderContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const mode = useSelector(selector.util.getHeaderMode);
  const { unread_count, profile } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const { fanclubs } = useSelector(selector.search.getSearchState);
  const [follows, setFollows] = useState<number[]>([]);
  const [paid, setPaid] = useState<number[]>([]);

  // フォローFCのIDリスト作成
  useEffect(() => {
    const fanClubs: number[] = [];
    const paid: number[] = [];
    profile.fanclubs.follows.map(fanClub => {
      fanClubs.push(fanClub.id);
      if (fanClub.pivot?.type === 1) {
        paid.push(fanClub.id);
      }

      return null;
    });

    setFollows(fanClubs);
    setPaid(paid);
  }, [profile.fanclubs]);

  // アーティスト検索のリスト作成
  const searches = useMemo(() => {
    const { manages } = profile.fanclubs;

    const list: FanclubWithFollowedAndManagedListWithKey[] = fanclubs.map(s => {
      return {
        ...s,
        lists: s.lists
          .filter(
            fanclub =>
              !fanclub.users ||
              !fanclub.users.length ||
              fanclub.users[0].pivot?.type !== 9,
          )
          .map(fanclub => ({
            ...fanclub,
            followed: !!follows.find(f => f === fanclub.id),
            paid: !!paid.find(f => f === fanclub.id),
            managed: !!manages.find(f => f.id === fanclub.id),
          })),
      };
    });

    return list;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fanclubs, follows]);

  const handleLogoClick = useCallback(() => {
    history.push(PATH.HOME);
  }, [history]);
  const handleNavClick = useCallback(() => {
    dispatch(changeHeaderMode(mode === 'menu' ? '' : 'menu'));
  }, [dispatch, mode]);
  const handleSearchClick = useCallback(() => {
    dispatch(changeHeaderMode(mode === 'search' ? '' : 'search'));
  }, [dispatch, mode]);
  const handleBellClick = useCallback(() => {
    history.push(PATH.NEWS);
  }, [history]);

  const handleBgClick = useCallback(() => {
    dispatch(changeHeaderMode(''));
  }, [dispatch]);

  const handleUserClick = useCallback(() => {
    if (!profile.user.name_id) return;

    history.push(PATH.getUserPath(profile.user.name_id));
  }, [history, profile.user.name_id]);
  const handleFollowClick = useCallback(() => {
    if (!profile.user.name_id) return;

    history.push(PATH.getUserPath(profile.user.name_id), {
      USER: { mode: 'follow' },
    });
  }, [history, profile.user.name_id]);
  const handleLikeClick = useCallback(() => {
    if (!profile.user.name_id) return;

    history.push(PATH.getUserPath(profile.user.name_id), {
      USER: { mode: 'like' },
    });
  }, [history, profile.user.name_id]);
  const handleClickSetting = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();

      history.push(PATH.SETTING_MENU);
    },
    [history],
  );
  const handleClickHelp = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();

      history.push(PATH.CONTACT);
    },
    [history],
  );
  const handleLogout = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();

      dispatch(logoutEvent.start());
      history.push(PATH.HOME);
    },
    [dispatch, history],
  );
  const handleClickManageFanclub = useCallback(
    (artistId: string) => {
      if (!artistId) return;

      history.push(PATH.getAdminPath(artistId, 'MENU'));
    },
    [history],
  );
  const handleSearchFanclubClick = useCallback(
    (artistId: string) => {
      if (!artistId) return;

      history.push(PATH.getArtistPath(artistId));
    },
    [history],
  );

  const handleSearchFollowClick = useCallback(
    (artistId: string, id: number) => {
      if (!isLogin) {
        history.push(PATH.SIGNUP);

        return;
      }
      const join = paid.includes(id);
      const followed = follows.includes(id);

      if (join) return;

      if (followed) {
        const followList = follows.filter(item => item !== id);
        setFollows(followList);
        dispatch(withdrawFanclubEvent.start({ artistId }));
      } else {
        setFollows([...follows, id]);
        dispatch(freejoinFanclubEvent.start({ artistId }));
      }
    },
    [dispatch, history, isLogin, paid, follows],
  );

  useEffect(() => {
    dispatch(changeHeaderMode(''));
    if (isLogin) {
      dispatch(getUnreadCountEvent.start());
    }
  }, [dispatch, history.location.pathname, isLogin]);

  useEffect(() => {
    if (mode === '') {
      enableScroll();
    } else {
      disableScroll();
    }

    if (mode === 'search') {
      dispatch(getFindFanclubListEvent.start({ scope: 'find' }));
    }
  }, [dispatch, mode]);

  return (
    <Header
      user={profile.user}
      follows={profile.fanclubs.follows}
      manages={profile.fanclubs.manages}
      like_count={profile.like_count}
      searches={searches}
      isLogin={isLogin}
      unreadCount={unread_count}
      mode={mode}
      handleLogoClick={handleLogoClick}
      handleNavClick={handleNavClick}
      handleUserClick={handleUserClick}
      handleBellClick={handleBellClick}
      handleSearchClick={handleSearchClick}
      handleBgClick={handleBgClick}
      handleFollowClick={handleFollowClick}
      handleLikeClick={handleLikeClick}
      handleClickSetting={handleClickSetting}
      handleClickHelp={handleClickHelp}
      handleLogoutClick={handleLogout}
      handleClickManageFanclub={handleClickManageFanclub}
      handleSearchFanclubClick={handleSearchFanclubClick}
      handleSearchFollowClick={handleSearchFollowClick}
    />
  );
};

export default HeaderContainer;
