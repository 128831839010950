import { Status, ErrorResponse } from './commonInterFace';

export type SendEmailParams = {
  email: string;
};
export type SendEmailResponse = {
  status_code: Status.OK;
  message: string;
};

export type ResetPasswordParams = {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
};
export type ResetPasswordErrors = {
  [key in keyof Partial<ResetPasswordParams>]: string[];
};
export type ResetPasswordValidationErrors = Record<
  keyof ResetPasswordParams,
  string
>;
export type ResetPasswordResponse = {
  status_code: Status.OK;
  message: string;
};
export type ResetPasswordErrorResponse = ErrorResponse<
  keyof ResetPasswordParams
>;

export type ChangePasswordParams = {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
};
export const ChangePasswordParamsInitialValue: ChangePasswordParams = {
  current_password: '',
  new_password: '',
  new_password_confirmation: '',
};
export type ChangePasswordErrors = {
  [key in keyof Partial<ChangePasswordParams>]: string[];
};
export type ChangePasswordValidationErrors = Record<
  keyof ChangePasswordParams,
  string
>;
export const ChangePasswordValidationErrorsInitialValue: ChangePasswordValidationErrors = {
  current_password: '',
  new_password: '',
  new_password_confirmation: '',
};
export type ChangePasswordResponse = {
  status_code: Status.OK;
  message: string;
};
export type ChangePasswordErrorResponse = ErrorResponse<
  keyof ChangePasswordParams
>;
