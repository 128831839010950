/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import ButtonRoundSNS from '../../atoms/buttonSNS/ButtonRoundSNS';
import * as styles from './McsRoundSNSButtons.module.scss';

interface Props {
  cssClass?: string;
  onTwitterClick?: () => void;
  onFaceBookClick?: () => void;
  onLineClick?: () => void;
}

const McsSNSButtons: FC<Props> = ({
  cssClass = '',
  onTwitterClick = () => undefined,
  onFaceBookClick = () => undefined,
  onLineClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsSNSButtonsBase, cssClass);

  return (
    <div className={classNames}>
      <h1>FOLLOW US</h1>
      <ButtonRoundSNS type="Twitter" onClick={onTwitterClick} />
      {/* アカウント削除のため非表示に */}
      {/* <ButtonRoundSNS type="FaceBook" onClick={onFaceBookClick} /> */}
      <ButtonRoundSNS type="Line" onClick={onLineClick} />
    </div>
  );
};

export default McsSNSButtons;
