import { Reducer } from 'redux';
import { Actions } from '../actions/adminMembersActions';
import * as ActionType from '../actions/adminMembersConstants';
import { UserForSearch } from '../interfaces/userInterFace';
import {
  AddFanclubMembersErrors,
  DeleteFanclubMembersErrors,
  ChangeFanclubMembersAuthorityErrors,
} from '../interfaces/memberInterFace';
import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../interfaces/fanclubInterFace';

type State = {
  details: FanclubDetails;
  searches: UserForSearch[];
  ready: boolean;
  loading: boolean;
  success: boolean;
  errors: {
    add: AddFanclubMembersErrors;
    delete: DeleteFanclubMembersErrors;
    change: ChangeFanclubMembersAuthorityErrors;
  };
};

export const initialState: State = {
  details: FanclubDetailsInitialValue,
  searches: [],
  ready: false,
  loading: false,
  success: false,
  errors: {
    add: {},
    delete: {},
    change: {},
  },
};

const adminMembersReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fanclub details
    case ActionType.GET_FANCLUB_DETAILS_START: {
      return {
        ...state,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_SUCCEED: {
      return {
        ...state,
        details: action.payload.result,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_FAIL: {
      return {
        ...state,
      };
    }

    // add fc member
    case ActionType.ADD_FC_MEMBERS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.ADD_FC_MEMBERS_SUCCEED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.ADD_FC_MEMBERS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // withdraw fanclub
    case ActionType.WITHDRAW_FANCLUB_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.WITHDRAW_FANCLUB_SUCCEED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.WITHDRAW_FANCLUB_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // delete fc member
    case ActionType.DELETE_FC_MEMBERS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.DELETE_FC_MEMBERS_SUCCEED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.DELETE_FC_MEMBERS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // change fc member's authority
    case ActionType.CHANGE_FC_MEMBERS_AUTHORITY_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.CHANGE_FC_MEMBERS_AUTHORITY_SUCCEED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.CHANGE_FC_MEMBERS_AUTHORITY_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // search user list
    case ActionType.SEARCH_USER_LIST_START: {
      return {
        ...state,
      };
    }
    case ActionType.SEARCH_USER_LIST_SUCCEED: {
      return {
        ...state,
        searches: action.payload.result.users.data,
      };
    }
    case ActionType.SEARCH_USER_LIST_FAIL: {
      return {
        ...state,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default adminMembersReducer;
