/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/adminFCClosedConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getFanclubDetailsEvent,
  closedFanclubEvent,
} from '../actions/adminFCClosedActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import {
  GetFanclubDetailsResponse,
  DeleteFanclubResponse,
} from '../interfaces/fanclubInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId } = action.payload;

  const getFanclubDetailsTask = yield fork(
    runGetFanclubDetails,
    getFanclubDetailsHandler,
    getFanclubDetailsEvent.start({ artistId }),
  );
  yield join(getFanclubDetailsTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get fanclub details
const getFanclubDetailsHandler = api.getGetFactory(true);
function* runGetFanclubDetails(
  handler: typeof getFanclubDetailsHandler,
  action: ReturnType<typeof getFanclubDetailsEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetFanclubDetailsResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(getFanclubDetailsEvent.fail());

      return;
    }

    yield put(getFanclubDetailsEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFanclubDetailsEvent.fail());
  }
}
export function* getFanclubDetails(handler: typeof getFanclubDetailsHandler) {
  yield takeLatest(
    ActionType.GET_FANCLUB_DETAILS_START,
    runGetFanclubDetails,
    handler,
  );
}

// delete fanclub
const closedFunClubHandler = api.getDeleteFactory(true);
function* runClosedFunClub(
  handler: typeof closedFunClubHandler,
  action: ReturnType<typeof closedFanclubEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: DeleteFanclubResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(closedFanclubEvent.fail());

      return;
    }

    yield put(closedFanclubEvent.succeed());
    // react-routerだとheader情報が更新されなかったのでURL指定でページ遷移
    window.location.href = `/admin/${artistId}/close/complete`;
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(closedFanclubEvent.fail());
  }
}
export function* closedFunClub(handler: typeof closedFunClubHandler) {
  yield takeLeading(ActionType.CLOSED_FANCLUB_START, runClosedFunClub, handler);
}

export const adminFCClosedSagas = [
  fork(getReady),
  fork(getFanclubDetails, getFanclubDetailsHandler),
  fork(closedFunClub, closedFunClubHandler),
];
