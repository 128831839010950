/* eslint-disable react/jsx-no-target-blank */
import React, { FC } from 'react';

import TitleH2 from '../common/atoms/title/TitleH2';
import ButtonSquare from '../common/atoms/buttonBasic/ButtonSquare';
import CheckBox from '../common/atoms/formParts/CheckBox';

import McsInputSecondary from '../common/molecules/formParts/McsInputSecondary';

import * as styles from './register.module.scss';

import {
  SignUpParams,
  SignUpParamsInitialValue,
  SignUpValidationErrors,
  SignUpValidationErrorsInitialValue,
} from '../../interfaces/authInterFace';

type Props = {
  params?: SignUpParams;
  isAccepted?: boolean;
  canSubmit?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  onToggleAccepted?: () => void;
  onClickRegister?: () => void;
  errors?: SignUpValidationErrors;
};

const READ = `会員登録には「利用規約」への同意が必要です。
下記リンクから規約内容をご確認の上、ご同意頂ける場合にのみ「利用規約に同意します」にチェックをいれてお進みください。`;

const Component: FC<Props> = ({
  params = SignUpParamsInitialValue,
  isAccepted = false,
  canSubmit = false,
  onChange = () => undefined,
  onToggleAccepted = () => undefined,
  onClickRegister = () => undefined,
  errors = SignUpValidationErrorsInitialValue,
}) => {
  return (
    <div className={styles.containerWrap}>
      <TitleH2 text="アカウントを作成" cssClass={styles.title} />
      <McsInputSecondary
        value={params.nickname}
        inputName="nickname"
        placeholder="ニックネームを入力"
        maxLength={20}
        labelTitle="ニックネーム"
        onChange={onChange}
        cssClass={styles.input}
        isError={!!errors.nickname}
        errorText={errors.nickname}
      />
      <McsInputSecondary
        value={params.name_id}
        inputName="name_id"
        placeholder="IDを入力"
        labelTitle="ID（半角英数）"
        labelTag="変更不可"
        onChange={onChange}
        cssClass={styles.input}
        isError={!!errors.name_id}
        errorText={errors.name_id}
      />
      <McsInputSecondary
        value={params.password}
        inputName="password"
        placeholder="パスワードを入力"
        inputType="password"
        labelTitle="パスワード"
        onChange={onChange}
        cssClass={styles.input}
        isError={!!errors.password}
        errorText={errors.password}
      />
      <McsInputSecondary
        value={params.password_confirmation}
        inputName="password_confirmation"
        placeholder="パスワードを入力"
        inputType="password"
        labelTitle="パスワードの確認"
        onChange={onChange}
        cssClass={styles.input}
        isError={!!errors.password_confirmation}
        errorText={errors.password_confirmation}
      />

      <p className={styles.read}>{READ}</p>

      <a
        className={styles.termsLink}
        href="https://krap.me/useragreement"
        target="_blank"
      >
        利用規約を確認する
      </a>

      <CheckBox
        labelText="利用規約に同意します"
        checked={isAccepted}
        onChange={onToggleAccepted}
        cssClass={styles.checkbox}
      />
      <ButtonSquare
        text="アカウントを作成"
        type={canSubmit ? 'Primary' : 'Tertiary'}
        size="Secondary"
        onClick={onClickRegister}
        cssClass={styles.button}
      />
    </div>
  );
};

export default Component;
