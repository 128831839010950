import { call, put, fork, takeLatest, takeLeading } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/searchConstants';
import { checkAndSetStatusError } from './authSagas';
import { getUserProfileEvent as updateMe } from '../actions/authActions';
import {
  getFindFanclubListEvent,
  freejoinFanclubEvent,
  withdrawFanclubEvent,
} from '../actions/searchActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import {
  GetFindFanclubListResponse,
  FreejoinFanclubResponse,
  WithdrawFanclubResponse,
} from '../interfaces/fanclubInterFace';

const getFindFanclubListHandler = api.getGetFactory(true);
function* runGetFindFanclubList(
  handler: typeof getFindFanclubListHandler,
  action: ReturnType<typeof getFindFanclubListEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: GetFindFanclubListResponse = yield call(
      handler,
      data,
      '/fanclub',
    );

    if (!ReturnData) {
      yield put(getFindFanclubListEvent.fail());

      return;
    }

    yield put(getFindFanclubListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFindFanclubListEvent.fail());
  }
}
export function* getFindFanclubList(handler: typeof getFindFanclubListHandler) {
  yield takeLatest(
    ActionType.GET_FIND_FANCLUB_LIST_START,
    runGetFindFanclubList,
    handler,
  );
}

// freejoin fanclub
const freejoinFanclubHandler = api.getPostFactory(true);
function* runFreejoinFanclub(
  handler: typeof freejoinFanclubHandler,
  action: ReturnType<typeof freejoinFanclubEvent.start>,
) {
  const { artistId } = action.payload;
  try {
    const ReturnData: FreejoinFanclubResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/freejoin`,
    );

    if (!ReturnData) {
      yield put(freejoinFanclubEvent.fail());

      return;
    }
    // update user profile
    yield put(updateMe.start());

    yield put(freejoinFanclubEvent.succeed());
  } catch (error) {
    window.alert('フォローに失敗しました');
    yield put(freejoinFanclubEvent.fail());
  }
}
export function* freejoinFanclub(handler: typeof freejoinFanclubHandler) {
  yield takeLeading(
    ActionType.FREEJOIN_FANCLUB_START,
    runFreejoinFanclub,
    handler,
  );
}

// withdraw fanclub
const withdrawFanclubHandler = api.getPostFactory(true);
function* runWithdrawFanclub(
  handler: typeof withdrawFanclubHandler,
  action: ReturnType<typeof withdrawFanclubEvent.start>,
) {
  const { artistId } = action.payload;
  try {
    const ReturnData: WithdrawFanclubResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/withdraw`,
    );

    if (!ReturnData) {
      yield put(withdrawFanclubEvent.fail());

      return;
    }
    // update user profile
    yield put(updateMe.start());

    yield put(withdrawFanclubEvent.succeed());
  } catch (error) {
    window.alert('フォロー解除に失敗しました');
    yield put(withdrawFanclubEvent.fail());
  }
}
export function* withdrawFanclub(handler: typeof withdrawFanclubHandler) {
  yield takeLeading(
    ActionType.WITHDRAW_FANCLUB_START,
    runWithdrawFanclub,
    handler,
  );
}

export const searchSagas = [
  fork(getFindFanclubList, getFindFanclubListHandler),
  fork(freejoinFanclub, freejoinFanclubHandler),
  fork(withdrawFanclub, withdrawFanclubHandler),
];
