import { AllState } from '../store';

const getLikesState = (state: AllState) => {
  const lastPage = state.likes.current_page !== state.likes.last_page;

  return { ...state.likes, lastPage };
};

export default {
  getLikesState,
};
