import React, { FC } from 'react';

import McsTitlePrimary from '../common/molecules/titleParts/McsTitlePrimary';

import OrgFCListUnitSecondary from '../common/organisms/listUnitParts/OrgFCListUnitSecondary';

import * as styles from './withdrawPaidFC.module.scss';

import { Fanclub } from '../../interfaces/fanclubInterFace';

type Props = {
  userId?: string;
  fanclubList?: Fanclub[];
  onBack?: () => void;
  onClickFanclub?: (artistId: string) => void;
  onWithdrawPaidFC?: (artistId: string) => void;
};

const Component: FC<Props> = ({
  userId = '',
  fanclubList = [],
  onBack = () => undefined,
  onClickFanclub = () => undefined,
  onWithdrawPaidFC = () => undefined,
}) => {
  return (
    <div>
      <div className={styles.containerWrap}>
        <McsTitlePrimary
          mainText="コミュニティから退会する"
          subText={`@${userId}`}
          onClick={onBack}
          cssClass={styles.title}
        />
        <div className={styles.FanclubListContainer}>
          {fanclubList.map((fanclub, i) => (
            <OrgFCListUnitSecondary
              fanclub={fanclub}
              key={`OrgFCListUnitTertiary${i.toString()}`}
              isViewButton
              buttonType="Secondary"
              buttonText="退会する"
              onClickFanclub={() => onClickFanclub(fanclub?.group_id ?? '')}
              onClickButton={() => onWithdrawPaidFC(fanclub?.group_id ?? '')}
              cssClass={styles.FanclubWrapper}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Component;
