/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  getContentListEvent,
  cleanup,
} from '../../actions/homeActions';
import { changeHeaderMode } from '../../actions/utilActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { FanclubInitialValue } from '../../interfaces/fanclubInterFace';
import {
  ContentListType,
  GetContentListForHomeParams,
} from '../../interfaces/contentInterFace';
import { Options } from '../../interfaces/footerInterFace';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import Home from '../../components/home/home';

const HomeContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [tabMode, setTabMode] = useState<ContentListType>(0);
  const { ready: authReady, profile } = useSelector(selector.auth.getAuthState);
  const { ready, contents, pickups, recommends, loading } = useSelector(
    selector.home.getHomeState,
  );
  const isLogin = useSelector(selector.auth.getIsLogin);
  const isLastPage = useSelector(selector.home.getIsLastPage);
  const isServiceUser = useMemo(
    () => isLogin && profile.fanclubs.follows.length === 0,
    [isLogin, profile.fanclubs.follows.length],
  );
  const isFanUser = useMemo(
    () => isLogin && profile.fanclubs.follows.length !== 0,
    [isLogin, profile.fanclubs.follows.length],
  );
  const { hero, pickupList } = useMemo(() => {
    const hero = isFanUser
      ? FanclubInitialValue
      : pickups.find(
          pickup =>
            !pickup.users ||
            !pickup.users.length ||
            pickup.users[0].pivot?.type !== 9,
        ) || FanclubInitialValue;

    const pickupList = pickups.filter(
      pickup =>
        pickup.id !== hero.id &&
        (!pickup.users ||
          !pickup.users.length ||
          pickup.users[0].pivot?.type !== 9),
    );

    return {
      hero,
      pickupList: pickupList.slice(0, 3),
    };
  }, [isFanUser, pickups]);
  const contentList = useMemo(
    () => contents.list.filter(content => content.user_type !== 9),
    [contents.list],
  );
  const baseParams: Partial<GetContentListForHomeParams> = useMemo(() => {
    if (!isLogin) {
      return {
        open_area: 3,
        limit: 5,
      };
    }

    if (isServiceUser) {
      return {
        limit: 5,
      };
    }

    if (isFanUser) {
      return {
        group_id: [
          ...profile.fanclubs.manages.map(fanclub => fanclub.group_id),
          ...profile.fanclubs.follows.map(fanclub => fanclub.group_id),
        ],
        limit: 5,
      };
    }

    return {
      group_id: profile.fanclubs.follows.map(fanclub => fanclub.group_id),
    };
  }, [
    isFanUser,
    isLogin,
    isServiceUser,
    profile.fanclubs.follows,
    profile.fanclubs.manages,
  ]);

  const handleClickLogin = useCallback(() => {
    history.push(PATH.SIGNUP);
  }, [history]);
  const handleStartForFree = useCallback(() => {
    history.push(PATH.SIGNUP);
  }, [history]);
  const handleFindArtist = useCallback(() => {
    dispatch(changeHeaderMode('search'));
  }, [dispatch]);

  const handleChangeTab = useCallback((mode: ContentListType) => {
    setTabMode(mode);
  }, []);
  const handleClickContent = useCallback(
    (artistId: string, contentId: number) => {
      if (!artistId || !contentId) return;

      const nextPath = PATH.getContentPath(artistId, contentId);

      // if (!isLogin) {
      //   history.push(PATH.SIGNUP, {
      //     referer: nextPath,
      //     SIGNUP: {
      //       from: 'contents',
      //     },
      //   });

      //   return;
      // }

      history.push(nextPath);
    },
    [history],
  );

  const handleReadMoreContents = useCallback(() => {
    dispatch(
      getContentListEvent.start({
        ...baseParams,
        type: tabMode,
        page: contents.pager.current_page + 1,
      }),
    );
  }, [baseParams, contents.pager.current_page, dispatch, tabMode]);
  const handleClickFanclub = useCallback(
    (artistId: string) => {
      if (!artistId) return;

      history.push(PATH.getArtistPath(artistId));
    },
    [history],
  );
  const handleClickUser = useCallback(
    (userId: string) => {
      if (!userId) return;

      history.push(PATH.getUserPath(userId));
    },
    [history],
  );

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(() => {
    const baseOptions: Options = {
      sns: true,
      nav: true,
      copyright: true,
      jasrac: false, // JASRAC解除によりfalseに変更
    };

    if (!isLogin)
      return {
        ...baseOptions,
        pr: {
          right: {
            text: '無料ではじめる',
            onClick: () => history.push(PATH.SIGNUP),
          },
        },
        button: {
          text: '無料ではじめる',
          onClick: () => history.push(PATH.SIGNUP),
        },
      };

    const loginOptions: Options = {
      ...baseOptions,
      pr: {
        right: {
          text: 'コミュニティを作る',
          onClick: () => history.push(PATH.OPEN_FC_AGREEMENT),
        },
      },
    };

    if (profile.fanclubs.manages.length === 0)
      return {
        ...loginOptions,
      };

    return {
      ...loginOptions,
      post: {
        fanclubList: profile.fanclubs.manages,
        onClickFanclub: (artistId: string) =>
          history.push(PATH.getAdminRegisterContentPath(artistId, 'blog'), {
            ADMIN_REGISTER_CONTENT: {
              mode: 'tab',
            },
          }),
      },
    };
  }, [history, isLogin, profile.fanclubs.manages]);
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    const userType = isFanUser ? 'fan' : isServiceUser ? 'service' : 'guest';
    dispatch(
      getReady(userType, {
        ...baseParams,
        reset: true,
        type: tabMode,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authReady, dispatch]);

  useEffect(() => {
    if (!authReady || !ready) return;

    dispatch(
      getContentListEvent.start(
        {
          ...baseParams,
          reset: true,
          type: tabMode,
        },
        true,
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseParams, dispatch, tabMode]);

  return (
    <>
      <Helmet>
        <title>KRAP</title>
      </Helmet>

      <OrgLoader isLoaded={authReady && ready} />

      <Home
        isLoading={loading}
        isLogin={isLogin}
        isServiceUser={isServiceUser}
        isFanUser={isFanUser}
        hero={hero}
        pickups={pickupList}
        recommends={recommends}
        type={tabMode}
        contents={contentList}
        handleFindArtist={handleFindArtist}
        handleStartForFree={handleStartForFree}
        handleClickLogin={handleClickLogin}
        handleChangeTab={handleChangeTab}
        handleClickContent={handleClickContent}
        handleReadMoreContents={handleReadMoreContents}
        handleClickFanclub={handleClickFanclub}
        handleClickUser={handleClickUser}
        isLastPage={isLastPage}
      />
    </>
  );
};

export default HomeContainer;
