import { Reducer } from 'redux';
import { Actions } from '../actions/adminAdministratorActions';
import * as ActionType from '../actions/adminAdministratorConstants';
import {
  Administrator,
  UpdateAdministratorErrors,
  AdministratorInitialValue,
} from '../interfaces/administratorInterFace';
import {
  BankDetails,
  Bank,
  BankBranch,
  RegisterBankAccountErrors,
} from '../interfaces/bankInterFace';
import { Address } from '../interfaces/addressInterFace';

type State = {
  administrator: Administrator;
  bank: BankDetails | null;
  currentBank: Bank | null;
  currentBankBranch: BankBranch | null;
  address: Address | null;
  suggestBanks: Bank[];
  suggestBankBranches: BankBranch[];
  ready: boolean;
  loading: boolean;
  administratorSuccess: boolean;
  bankSuccess: boolean;
  administratorError: boolean;
  administratorErrors: UpdateAdministratorErrors;
  bankError: boolean;
  bankErrors: RegisterBankAccountErrors;
};

const initialState: State = {
  administrator: AdministratorInitialValue,
  bank: null,
  currentBank: null,
  currentBankBranch: null,
  address: null,
  suggestBanks: [],
  suggestBankBranches: [],
  ready: false,
  loading: true,
  administratorSuccess: false,
  bankSuccess: false,
  administratorError: false,
  administratorErrors: {},
  bankError: false,
  bankErrors: {},
};

const adminAdministratorReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get current bank
    case ActionType.GET_CURRENT_BANK_START: {
      return {
        ...state,
      };
    }
    case ActionType.GET_CURRENT_BANK_SUCCEED: {
      return {
        ...state,
        currentBank: action.payload,
      };
    }
    case ActionType.GET_CURRENT_BANK_FAIL: {
      return {
        ...state,
      };
    }

    // get current bank branch
    case ActionType.GET_CURRENT_BANK_BRANCH_START: {
      return {
        ...state,
      };
    }
    case ActionType.GET_CURRENT_BANK_BRANCH_SUCCEED: {
      return {
        ...state,
        currentBankBranch: action.payload,
      };
    }
    case ActionType.GET_CURRENT_BANK_BRANCH_FAIL: {
      return {
        ...state,
      };
    }

    // get administrator
    case ActionType.GET_ADMINISTRATOR_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_ADMINISTRATOR_SUCCEED: {
      return {
        ...state,
        loading: false,
        administrator: action.payload.result,
      };
    }
    case ActionType.GET_ADMINISTRATOR_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // get bank account
    case ActionType.GET_BANK_ACCOUNT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_BANK_ACCOUNT_SUCCEED: {
      return {
        ...state,
        loading: false,
        bank: action.payload.result,
      };
    }
    case ActionType.GET_BANK_ACCOUNT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // get address
    case ActionType.GET_ADDRESS_START: {
      return {
        ...state,
      };
    }
    case ActionType.GET_ADDRESS_SUCCEED: {
      const address = action.payload.length ? action.payload[0] : null;

      if (!address) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        address,
      };
    }
    case ActionType.GET_ADDRESS_FAIL: {
      return {
        ...state,
      };
    }

    // search bank
    case ActionType.SEARCH_BANK_START: {
      return {
        ...state,
      };
    }
    case ActionType.SEARCH_BANK_SUCCEED: {
      return {
        ...state,
        suggestBanks: action.payload,
      };
    }
    case ActionType.SEARCH_BANK_FAIL: {
      return {
        ...state,
      };
    }

    // search bank branches
    case ActionType.SEARCH_BANK_BRANCH_START: {
      return {
        ...state,
      };
    }
    case ActionType.SEARCH_BANK_BRANCH_SUCCEED: {
      return {
        ...state,
        suggestBankBranches: action.payload,
      };
    }
    case ActionType.SEARCH_BANK_BRANCH_FAIL: {
      return {
        ...state,
      };
    }

    // update administrator
    case ActionType.UPDATE_ADMINISTRATOR_START: {
      return {
        ...state,
        loading: true,
        administratorSuccess: false,
        administratorError: false,
        administratorErrors: {},
      };
    }
    case ActionType.UPDATE_ADMINISTRATOR_SUCCEED: {
      return {
        ...state,
        loading: false,
        administratorSuccess: true,
      };
    }
    case ActionType.UPDATE_ADMINISTRATOR_FAIL: {
      return {
        ...state,
        loading: false,
        administratorError: true,
        administratorErrors: action.payload.errors,
      };
    }

    // create bank account
    case ActionType.CREATE_BANK_ACCOUNT_START: {
      return {
        ...state,
        loading: true,
        bankSuccess: false,
        bankError: false,
        bankOtherErrors: {},
        bankJPErrors: {},
      };
    }
    case ActionType.CREATE_BANK_ACCOUNT_SUCCEED: {
      return {
        ...state,
        loading: false,
        bankSuccess: true,
      };
    }
    case ActionType.CREATE_BANK_ACCOUNT_FAIL: {
      const { banks: type } = action.payload.params;

      if (type === 0) {
        return {
          ...state,
          loading: false,
          bankError: true,
          bankOtherErrors: action.payload.errors,
        };
      }

      if (type === 1) {
        return {
          ...state,
          loading: false,
          bankError: true,
          bankJPErrors: action.payload.errors,
        };
      }

      return {
        ...state,
        loading: false,
        bankError: true,
      };
    }

    // update bank account
    case ActionType.UPDATE_BANK_ACCOUNT_START: {
      return {
        ...state,
        loading: true,
        bankSuccess: false,
        bankError: false,
        bankOtherErrors: {},
        bankJPErrors: {},
      };
    }
    case ActionType.UPDATE_BANK_ACCOUNT_SUCCEED: {
      return {
        ...state,
        bankSuccess: true,
        loading: false,
      };
    }
    case ActionType.UPDATE_BANK_ACCOUNT_FAIL: {
      const { banks: type } = action.payload.params;

      if (type === 0) {
        return {
          ...state,
          loading: false,
          bankError: true,
          bankOtherErrors: action.payload.errors,
        };
      }

      if (type === 1) {
        return {
          ...state,
          loading: false,
          bankError: true,
          bankJPErrors: action.payload.errors,
        };
      }

      return {
        ...state,
        loading: false,
        bankError: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default adminAdministratorReducer;
