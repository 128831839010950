/* eslint-disable import/no-cycle */
import { Status, ErrorResponse, Pager, BoolNumber } from './commonInterFace';
import { InputFile } from './utilInterFace';
import { User, UserType } from './userInterFace';
import { Genre } from './genreInterFace';

export type FanclubListScope = 'pickup' | 'recommend' | 'find';

/**
 * 有料フラグ 1: 無料FC 2: 有料許可FC 3: 申請中 4: 申請却下
 */
export type PaidStatus = 1 | 2 | 3 | 4;

export type Fanclub = {
  id: number;
  group_id: string;
  site_name: string;
  name: string;
  name_kana: string;
  prefecture: number;
  formation_on: string | null;
  icon_image: string;
  cover_image: string;
  description: string;
  representative_email: string;
  site_url: string | null;
  /**
   * urlではなく、id
   */
  twitter_url: string | null;
  /**
   * urlではなく、id
   */
  facebook_url: string | null;
  /**
   * urlではなく、id
   */
  instagram_url: string | null;
  paid_status: PaidStatus;
  price: number;
  rejected_reason: string | null;
  product_id: string | null; // Stripeで使用するID
  // created_at: string;
  // updated_at: string;
  // deleted_at: null;
  pivot?: {
    type?: UserType;
  };
};
export const FanclubInitialValue: Fanclub = {
  id: 0,
  group_id: '',
  site_name: '',
  name: '',
  name_kana: '',
  prefecture: 0,
  formation_on: null,
  icon_image: '',
  cover_image: '',
  description: '',
  representative_email: '',
  site_url: '',
  twitter_url: '',
  facebook_url: '',
  instagram_url: '',
  paid_status: 1,
  price: 0,
  rejected_reason: null,
  product_id: null,
  pivot: {
    type: null,
  },
};
export type FanclubWithUsers = Omit<Fanclub, 'pivot'> & {
  users?: User[];
};
export const FanclubWithUsersInitialValue: FanclubWithUsers = {
  ...FanclubInitialValue,
  users: [],
};

export type FanclubWithUserInfo = Fanclub & {
  followed: boolean;
  paid: boolean;
  managed: boolean;
};
export const FanclubWithFollowedAndManagedInitialValue: FanclubWithUserInfo = {
  ...FanclubInitialValue,
  followed: false,
  paid: false,
  managed: false,
};
export type FanclubForEdit = {
  cover_image: File | null;
  icon_image: File | null;
  group_id: string;
  name: string;
  name_kana: string;
  site_name: string;
  prefecture: number;
  formation_on_year: number;
  formation_on_month: number;
  formation_on_date: number;
  genres: number[];
  description: string;
  site_url: string;
  twitter_url: string;
  facebook_url: string;
  instagram_url: string;
};
export const FanclubForEditInitialValue: FanclubForEdit = {
  cover_image: null,
  icon_image: null,
  group_id: '',
  name: '',
  name_kana: '',
  site_name: '',
  prefecture: 0,
  formation_on_year: 0,
  formation_on_month: 0,
  formation_on_date: 0,
  genres: [],
  description: '',
  site_url: '',
  twitter_url: '',
  facebook_url: '',
  instagram_url: '',
};

export type FanclubWithGenres = Fanclub & { genres: Genre[] };
export const FanclubWithGenresInitialValue: FanclubWithGenres = {
  ...FanclubInitialValue,
  genres: [],
};

export type FanclubDetails = {
  fanclub: FanclubWithGenres;
  followerCount: number;
  paidCount: number;
  blockedCount: number;
  members: User[];
  user_type: UserType;
  is_account_registerd: BoolNumber;
};

export const FanclubDetailsInitialValue: FanclubDetails = {
  fanclub: FanclubWithGenresInitialValue,
  followerCount: 0,
  paidCount: 0,
  blockedCount: 0,
  members: [],
  user_type: null,
  is_account_registerd: 0,
};

export type FanclubListWithKey = {
  key: string;
  lists: FanclubWithUsers[];
};
export type FanclubWithFollowedAndManagedListWithKey = {
  key: string;
  lists: FanclubWithUserInfo[];
};

export type GetFanclubDetailsParams = {
  artistId: string;
};
export type GetFanclubDetailsResult = FanclubDetails;
export type GetFanclubDetailsResponse = {
  status_code: Status.OK;
  result: GetFanclubDetailsResult;
};

export type CreateFanclubParams = {
  cover_image: File | null;
  icon_image: File | null;
  group_id: string;
  name: string;
  name_kana: string;
  site_name: string;
  prefecture: number;
  formation_on?: string;
  description: string;
  genres: number[];
  site_url: string;
  twitter_url: string;
  facebook_url: string;
  instagram_url: string;
};
export type CreateFanclubInputParams = Required<
  Omit<CreateFanclubParams, 'cover_image' | 'icon_image' | 'formation_on'>
> & {
  cover_image: InputFile;
  icon_image: InputFile;
  formation_on_year: number;
  formation_on_month: number;
  formation_on_date: number;
};
export const CreateFanclubInputParamsInitialValue: CreateFanclubInputParams = {
  cover_image: {
    file: null,
    preview: '',
  },
  icon_image: {
    file: null,
    preview: '',
  },
  group_id: '',
  name: '',
  name_kana: '',
  site_name: '',
  prefecture: 0,
  formation_on_year: 0,
  formation_on_month: 0,
  formation_on_date: 0,
  genres: [],
  description: '',
  site_url: '',
  twitter_url: '',
  facebook_url: '',
  instagram_url: '',
};
export type CreateFanclubErrors = {
  [key in keyof Partial<CreateFanclubParams>]: string[];
};
export type CreateFanclubValidationErrors = Record<
  keyof CreateFanclubParams,
  string
>;
export const CreateFanclubValidationErrorsInitialValue: CreateFanclubValidationErrors = {
  cover_image: '',
  icon_image: '',
  group_id: '',
  name: '',
  name_kana: '',
  site_name: '',
  prefecture: '',
  formation_on: '',
  genres: '',
  description: '',
  site_url: '',
  twitter_url: '',
  facebook_url: '',
  instagram_url: '',
};
export type CreateFanclubResult = {};
export type CreateFanclubResponse = {
  status_code: Status.OK;
  result: CreateFanclubResult;
};
export type CreateFanclubErrorResponse = ErrorResponse<
  keyof CreateFanclubParams
>;

export type EditFanclubParams = FanclubForEdit;

export type UpdateFanclubParams = {
  artistId: string;
  name: string;
  name_kana: string;
  site_name: string;
  prefecture: number;
  formation_on?: string;
  description: string;
  genres: number[];
  icon_image?: File;
  cover_image?: File;
  site_url: string;
  twitter_url: string;
  facebook_url: string;
  instagram_url: string;
};
export type UpdateFanclubInputParams = Required<
  Omit<
    UpdateFanclubParams,
    'artistId' | 'cover_image' | 'icon_image' | 'formation_on'
  >
> & {
  cover_image: InputFile;
  icon_image: InputFile;
  formation_on_year: number;
  formation_on_month: number;
  formation_on_date: number;
};
export const UpdateFanclubInputParamsInitialValue: UpdateFanclubInputParams = {
  cover_image: {
    file: null,
    preview: '',
  },
  icon_image: {
    file: null,
    preview: '',
  },
  name: '',
  name_kana: '',
  site_name: '',
  prefecture: 0,
  formation_on_year: 0,
  formation_on_month: 0,
  formation_on_date: 0,
  genres: [],
  description: '',
  site_url: '',
  twitter_url: '',
  facebook_url: '',
  instagram_url: '',
};
export type UpdateFanclubErrors = {
  [key in keyof Partial<Omit<UpdateFanclubParams, 'artistId'>>]: string[];
};
export type UpdateFanclubValidationErrors = Record<
  keyof Omit<UpdateFanclubParams, 'artistId'>,
  string
>;
export type UpdateFanclubResult = {};
export type UpdateFanclubResponse = {
  status_code: Status.OK;
  result: UpdateFanclubResult;
};
export type UpdateFanclubErrorResponse = ErrorResponse<
  keyof UpdateFanclubParams
>;

export type GetFanclubListParams = {
  scope?: FanclubListScope;
  limit?: number;
  page?: number;
};

export type GetFindFanclubListParams = GetFanclubListParams & {
  scope: 'find';
};
export type GetFindFanclubListResult = {
  fanclubs: FanclubListWithKey[];
};
export type GetFindFanclubListResponse = {
  status_code: Status.OK;
  result: GetFindFanclubListResult;
};

export type GetPickupFanclubListParams = GetFanclubListParams & {
  scope: 'pickup';
};
export type GetPickupFanclubListResult = Pager & {
  data: FanclubWithUsers[];
};
export type GetPickupFanclubListResponse = {
  status_code: Status.OK;
  result: GetPickupFanclubListResult;
};

export type GetRecommendFanclubListParams = GetFanclubListParams & {
  scope: 'recommend';
};
export type GetRecommendFanclubListResult = Pager & {
  data: Fanclub[];
};
export type GetRecommendFanclubListResponse = {
  status_code: Status.OK;
  result: GetRecommendFanclubListResult;
};

export type GetFollowingFanclubListParams = {
  userId: string;
  page?: number;
  reset?: boolean;
};
export type GetFollowingFanclubListResult = {
  fanclubs: Pager & {
    data: Fanclub[];
  };
};
export type GetFollowingFanclubListResponse = {
  status_code: Status.OK;
  result: GetFollowingFanclubListResult;
};

export type FreejoinFanclubParams = {
  artistId: string;
};
export type FreejoinFanclubResponse = {
  status_code: Status.OK;
  message: string;
};

export type PaidjoinFanclubParams = {
  artistId: string;
  stripeToken?: string;
  stripeCard?: string;
};
export type PaidjoinFanclubResponse = {
  status_code: Status.OK;
  created_at: string; // 2020-06-19 12:18:16
};

export type WithdrawFanclubParams = {
  artistId: string;
};
export type WithdrawFanclubResponse = {
  status_code: Status.OK;
  message: string;
};

export type ApplyPaidFCParams = {
  artistId: string;
  name: string;
  postal_code: string;
  address: string;
  email: string;
  birthday: string;
  tel: string;
  identification_image: File | null;
  price: number;
};
export type ApplyPaidFCResponse = {
  status_code: Status.OK;
  message: string;
};
export type ApplyPaidFCErrors = {
  [key in keyof Partial<ApplyPaidFCParams>]: string[];
};
export type ApplyPaidFCErrorResponse = ErrorResponse<
  keyof Omit<ApplyPaidFCParams, 'artistId'>
>;
export type ApplyPaidFCValidationErrors = Record<
  keyof Omit<ApplyPaidFCParams, 'artistId'>,
  string
>;
export const ApplyPaidFCValidationErrorsInitialValue: ApplyPaidFCValidationErrors = {
  name: '',
  postal_code: '',
  address: '',
  email: '',
  birthday: '',
  tel: '',
  identification_image: '',
  price: '',
};
export type ApplyPaidFCInputParams = Omit<
  ApplyPaidFCParams,
  'artistId' | 'birthday' | 'identification_image' | 'price'
> & {
  birthday_year: number;
  birthday_month: number;
  birthday_date: number;
  identification_image: InputFile;
  price: string;
};
export const ApplyPaidFCInputParamsInitialValue: ApplyPaidFCInputParams = {
  name: '',
  postal_code: '',
  address: '',
  email: '',
  birthday_year: 0,
  birthday_month: 0,
  birthday_date: 0,
  tel: '',
  identification_image: {
    file: null,
    preview: '',
  },
  price: '300',
};
export type DeleteFanclubResponse = {
  status_code: Status.OK;
  message: string;
};

export type startParamWithChat = {
  artistId: string;
  chatAvailable: boolean;
};
