import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './InputBox.module.scss';

interface Props {
  value: string;
  type?: string;
  inputType?: string;
  inputName?: string;
  placeholder?: string;
  min?: string | number;
  max?: string | number;
  maxLength?: number | undefined;
  cssClass?: string;

  onClick?: () => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>, key?: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>, key?: string) => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;

  isDisabled?: boolean;

  isError?: boolean;
  errorText?: string;
}

const Input: FC<Props> = ({
  value = '',
  type = 'Primary',
  inputType = 'text',
  inputName = '',
  placeholder = '',
  min = undefined,
  max = undefined,
  maxLength = undefined,
  cssClass = '',

  onClick = () => undefined,
  onFocus = () => undefined,
  onBlur = () => undefined,
  onChange = () => undefined,

  isDisabled = false,

  isError = false,
  errorText = 'Error',
}) => {
  const classNames = ClassNames(styles.InputBase, cssClass, {
    [styles.InputPrimary]: type === 'Primary', // Primary
    [styles.InputSecondary]: type === 'Secondary', // Secondary
    [styles.InputTertiary]: type === 'Tertiary', // Tertiary
    [styles.InputQuaternary]: type === 'Quaternary', // Quaternary
    [styles.InputQuinary]: type === 'Quinary', // Quinary
    [styles.InputSenary]: type === 'Senary', // Senary
    [styles.InputSeptenary]: type === 'Septenary', // Septenary
    [styles.InputDisabled]: isDisabled, // isDisabled
    [styles.InputError]: isError, // isError
    [styles.InputErrorWithOutBorder]:
      isError && (type === 'Tertiary' || type === 'Secondary'), // isError &&
  });

  return (
    <>
      <div className={classNames}>
        <input
          type={inputType}
          name={inputName}
          placeholder={placeholder}
          value={value}
          min={min}
          max={max}
          maxLength={maxLength}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          disabled={isDisabled}
        />
      </div>
      {isError && <p className={styles.InputErrorText}>{errorText}</p>}
    </>
  );
};

export default Input;
