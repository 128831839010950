import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './ButtonRoundedCorners.module.scss';

interface Props {
  text: string;
  type?: string;
  size?: string;
  cssClass?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button: FC<Props> = ({
  text = 'Button',
  type = 'Primary',
  size = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.ButtonBase, cssClass, {
    [styles.ButtonPrimary]: type === 'Primary', // Primary
    [styles.ButtonSecondary]: type === 'Secondary', // Secondary
    [styles.ButtonTertiary]: type === 'Tertiary', // Tertiary
    [styles.ButtonQuaternary]: type === 'Quaternary', // Quaternary
    [styles.ButtonQuinary]: type === 'Quinary', // Quinary
    [styles.ButtonSenary]: type === 'Senary', // Senary
    [styles.ButtonSeptenary]: type === 'Septenary', // Septenary
    [styles.ButtonOctonary]: type === 'Octonary', // Octonary
    [styles.ButtonNovenary]: type === 'Novenary', // Novenary
    [styles.ButtonDecenary]: type === 'Decenary', // Decenary

    [styles.ButtonSizePrimary]: size === 'Primary', // Primary
    [styles.ButtonSizeSecondary]: size === 'Secondary', // Secondary
    [styles.ButtonSizeTertiary]: size === 'Tertiary', // Tertiary
    [styles.ButtonSizeQuaternary]: size === 'Quaternary', // Quaternary
    [styles.ButtonSizeSenary]: size === 'Quaternary' && type === 'Secondary', // WithLineButton
    [styles.ButtonSizeSeptenary]: size === 'Septenary', // Quaternary
    [styles.ButtonSizeOctonary]: size === 'Septenary' && type === 'Secondary', // WithLineButton
    [styles.ButtonSizeNovenary]: size === 'Novenary', // Novenary
  });

  return (
    <div className={classNames}>
      <button type="button" onClick={onClick}>
        <span>{text}</span>
      </button>
    </div>
  );
};

export default Button;
