import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './TextAreaBox.module.scss';

interface Props {
  value: string;
  type?: string;
  TextAreaName?: string;
  placeholder?: string;
  maxLength?: number | undefined;
  cssClass?: string;

  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.FormEvent<HTMLTextAreaElement>, key?: string) => void;

  isDisabled?: boolean;
  isError?: boolean;
  errorText?: string;
}

const TextArea: FC<Props> = ({
  value = '',
  type = 'Primary',
  TextAreaName = '',
  placeholder = '',
  maxLength = undefined,
  cssClass = '',

  onClick = () => undefined,
  onFocus = () => undefined,
  onBlur = () => undefined,
  onChange = () => undefined,

  isDisabled = false,
  isError = false,
  errorText = 'Error',
}) => {
  const classNames = ClassNames(styles.TextAreaBase, cssClass, {
    [styles.TextAreaPrimary]: type === 'Primary', // Primary
    [styles.TextAreaSecondary]: type === 'Secondary', // Secondary
    [styles.TextAreaTertiary]: type === 'Tertiary', // Tertiary
    [styles.TextAreaQuaternary]: type === 'Quaternary', // Quaternary
    [styles.TextAreaSenary]: type === 'Senary', // Senary

    [styles.TextAreaDisabled]: isDisabled, //  isDisabled
    [styles.TextAreaError]: isError, // isError
    [styles.TextAreaErrorWithOutBorder]:
      isError && (type === 'Tertiary' || type === 'Secondary'), // isError &&
  });

  return (
    <>
      <div className={classNames}>
        <textarea
          name={TextAreaName}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          disabled={isDisabled}
        />
      </div>
      {isError && <p className={styles.TextAreaErrorText}>{errorText}</p>}
    </>
  );
};

export default TextArea;
