export const GET_READY = 'AUTH/GET_READY' as const;
export const GOT_READY = 'AUTH/GOT_READY' as const;

export const SET_ACCESS_TOKEN = 'AUTH/SET_ACCESS_TOKEN' as const;
export const SET_AUTH_ERROR = 'AUTH/SET_AUTH_ERROR' as const;

export const LOGIN_START = 'AUTH/LOGIN_START' as const;
export const LOGIN_SUCCEED = 'AUTH/LOGIN_SUCCEED' as const;
export const LOGIN_FAIL = 'AUTH/LOGIN_FAIL' as const;

export const LOGOUT_START = 'AUTH/LOGOUT_START' as const;
export const LOGOUT_SUCCEED = 'AUTH/LOGOUT_SUCCEED' as const;
export const LOGOUT_FAIL = 'AUTH/LOGOUT_FAIL' as const;

export const GET_USER_PROFILE_START = 'AUTH/GET_USER_PROFILE_START' as const;
export const GET_USER_PROFILE_SUCCEED = 'AUTH/GET_USER_PROFILE_SUCCEED' as const;
export const GET_USER_PROFILE_FAIL = 'AUTH/GET_USER_PROFILE_FAIL' as const;

export const GET_UNREAD_COUNT_START = 'AUTH/GET_UNREAD_COUNT_START' as const;
export const GET_UNREAD_COUNT_SUCCEED = 'AUTH/GET_UNREAD_COUNT_SUCCEED' as const;
export const GET_UNREAD_COUNT_FAIL = 'AUTH/GET_UNREAD_COUNT_FAIL' as const;

export const CLEANUP = 'AUTH/CLEANUP' as const;
