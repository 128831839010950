const ADMIN_ROUTE_TYPE = {
  MENU: '' as const, // アーティスト管理メニュー
  CONTENTS: '/contents' as const, // コンテンツ管理
  CATEGORY: '/category' as const, // カテゴリ管理
  LIKES: '/likes' as const, // いいねランキング
  COMMENTS: '/comments' as const, // コメント一覧
  SALES: '/sales' as const, // 売上情報
  COMMUNITY: '/community' as const, // コミュニティ情報編集
  MEMBERS: '/members' as const, // メンバー管理
  FOLLOWERS: '/followers' as const, //  フォロワー管理
  BLOCKS: '/blocks' as const, // ブロック管理
  APPLY: '/apply' as const, // コミュニティ有料化申請
  ADMINISTRATOR: '/administrator' as const, // 管理者情報編集
  CLOSE: '/close' as const, // FC閉鎖
  CLOSE_COMPLETE: '/close/complete' as const, // FC閉鎖完了画面
} as const;

/**
 * userId: ユーザのname_id
 * artistId: コミュニティのgroup_id
 * contentId: コンテンツのid
 * notificationId: お知らせのid
 */
export const PATH = {
  /**
   * トップ
   */
  HOME: '/' as const, // ホーム
  /**
   * ログイン、会員登録
   */
  SIGNUP: '/signup' as const, // ログイン、会員登録（メール重複チェック）
  SIGNUP_REGISTER: '/signup/register1' as const, // 会員登録（アカウント情報入力）
  SIGNUP_ATTACH_GENRES: '/signup/register2' as const, // 会員登録完了（ジャンル選択）
  SIGNUP_FOLLOW_ARTIST: '/signup/register3' as const, // 会員登録完了（アーティストフォロー）
  OAUTH_TWITTER_CALLBACK: '/auth/twitter/callback' as const, // ツイッター認証処理
  /**
   * ユーザ設定
   */
  SETTING_MENU: '/setting' as const, // 設定メニュー
  SETTING_PROFILE: '/setting/profile' as const, // ユーザ情報編集
  SETTING_EMAIL: '/setting/email' as const, // メール設定
  SETTING_PASSWORD: '/setting/password' as const, // パスワード設定
  SETTING_CREDIT_CARD: '/setting/credit' as const, // クレジットカード設定
  SETTING_WITHDRAW: '/setting/withdraw' as const, // KRAP退会
  SETTING_WITHDRAW_FC: '/setting/fc/withdraw' as const, // 有料入会中のコミュニティ退会
  /**
   * パスワード再発行
   */
  REISSUE_PASSWORD_SEND_EMAIL: '/password/email' as const, // リセットメール送信
  REISSUE_PASSWORD_CHANGE_PASSWORD: '/password/change' as const, // パスワード変更 ※リセットメールから遷移
  REISSUE_PASSWORD_LOGIN: '/password/login' as const, // パスワード変更完了（ログイン）
  /**
   * ユーザ、アーティスト、コンテンツ
   */
  USER: '/user/:userId' as const, // ユーザ詳細
  getUserPath: (userId: string) => `/user/${userId}`,
  ARTIST: '/artist/:artistId' as const, // アーティスト詳細
  getArtistPath: (artistId: string) => `/artist/${artistId}`,
  ARTIST_FOLLOWER: '/artist/:artistId/followers' as const, // アーティストフォロワー一覧
  getFollowerPath: (artistId: string) => `/artist/${artistId}/followers`,
  CONTENT: '/artist/:artistId/contents/id/:contentId' as const, // コンテンツ詳細
  getContentPath: (artistId: string, contentId: number) =>
    `/artist/${artistId}/contents/id/${contentId}`,
  COMMENT: '/artist/:artistId/contents/id/:contentId/comments' as const, // コメント
  getCommentPath: (artistId: string, contentId: number) =>
    `/artist/${artistId}/contents/id/${contentId}/comments`,
  LIKE: '/artist/:artistId/contents/id/:contentId/likes' as const, // いいね
  getLikePath: (artistId: string, contentId: number) =>
    `/artist/${artistId}/contents/id/${contentId}/likes`,
  /**
   * チャット機能（有料FC限定）
   */
  FC_CHAT: '/artist/:artistId/chat' as const, //
  getFCChatPath: (artistId: string) => `/artist/${artistId}/chat`,
  /**
   * 有料コミュニティ入会
   */
  PAIDJOIN: '/artist/:artistId/join' as const, // 有料コミュニティ入会
  getPaidjoinPath: (artistId: string) => `/artist/${artistId}/join`,
  /**
   * コミュニティ開設
   */
  OPEN_FC_AGREEMENT: '/fc/open' as const, // コミュニティ開設（利用規約）
  OPEN_FC_REGISTER: '/fc/open/register', // コミュニティ開設（コミュニティ情報入力）
  OPEN_FC_COMPLETE: '/fc/open/complete' as const, // コミュニティ開設完了（メンバー追加）
  /**
   * アーティスト管理
   */
  ADMIN_MENU: `/admin/:artistId${ADMIN_ROUTE_TYPE.MENU}`, // アーティスト管理メニュー
  ADMIN_CONTENTS: `/admin/:artistId${ADMIN_ROUTE_TYPE.CONTENTS}`, // コンテンツ管理
  ADMIN_CATEGORY: `/admin/:artistId${ADMIN_ROUTE_TYPE.CATEGORY}`, // カテゴリ管理
  ADMIN_CREATE_BLOG: '/admin/:artistId/contents/blog/register' as const, // ブログ投稿
  ADMIN_UPDATE_BLOG: '/admin/:artistId/contents/blog/edit/id/:contentId' as const, // ブログ編集
  ADMIN_CREATE_MOVIE: '/admin/:artistId/contents/movie/register' as const, // 動画投稿
  ADMIN_UPDATE_MOVIE: '/admin/:artistId/contents/movie/edit/id/:contentId' as const, // 動画編集
  ADMIN_CREATE_PHOTO: '/admin/:artistId/contents/photo/register' as const, // 画像投稿
  ADMIN_UPDATE_PHOTO: '/admin/:artistId/contents/photo/edit/id/:contentId' as const, // 画像編集
  ADMIN_CREATE_VOICE: '/admin/:artistId/contents/voice/register' as const, // 音声投稿
  ADMIN_UPDATE_VOICE: '/admin/:artistId/contents/voice/edit/id/:contentId' as const, // 音声編集
  ADMIN_LIKES: `/admin/:artistId${ADMIN_ROUTE_TYPE.LIKES}`, // いいねランキング
  ADMIN_COMMENTS: `/admin/:artistId${ADMIN_ROUTE_TYPE.COMMENTS}`, // コメント一覧
  ADMIN_SALES: `/admin/:artistId${ADMIN_ROUTE_TYPE.SALES}`, // 売上情報
  ADMIN_COMMUNITY: `/admin/:artistId${ADMIN_ROUTE_TYPE.COMMUNITY}`, // コミュニティ情報編集
  ADMIN_MEMBERS: `/admin/:artistId${ADMIN_ROUTE_TYPE.MEMBERS}`, // メンバー管理
  ADMIN_FOLLOWERS: `/admin/:artistId${ADMIN_ROUTE_TYPE.FOLLOWERS}`, //  フォロワー管理
  ADMIN_BLOCKS: `/admin/:artistId${ADMIN_ROUTE_TYPE.BLOCKS}`, // ブロック管理
  ADMIN_APPLY: `/admin/:artistId${ADMIN_ROUTE_TYPE.APPLY}`, // コミュニティ有料化申請
  ADMIN_ADMINISTRATOR: `/admin/:artistId${ADMIN_ROUTE_TYPE.ADMINISTRATOR}`, // 管理者情報編集
  ADMIN_CLOSE_FC: `/admin/:artistId${ADMIN_ROUTE_TYPE.CLOSE}`, // FC閉鎖
  ADMIN_CLOSE_FC_COMPLETE: `/admin/:artistId${ADMIN_ROUTE_TYPE.CLOSE_COMPLETE}`, // FC閉鎖完了画面
  getAdminPath: (artistId: string, type: keyof typeof ADMIN_ROUTE_TYPE) =>
    `/admin/${artistId}${ADMIN_ROUTE_TYPE[type]}`,
  getAdminRegisterContentPath: (
    artistId: string,
    type: 'blog' | 'movie' | 'photo' | 'voice',
    contentId?: number, // 未指定でCREATE
  ) =>
    `/admin/${artistId}/contents/${type}/${
      contentId ? `edit/id/${contentId}` : 'register'
    }`,
  /**
   * お知らせ
   */
  NEWS: '/news' as const, // お知らせ一覧
  NEWS_DETAILS: '/news/:notificationId' as const, // お知らせ詳細画面
  getNewsPath: (notificationId: number) => `/news/${notificationId}`,
  /**
   * お問い合わせ
   */
  CONTACT: '/help' as const, // お問い合わせ
  /**
   * エラー
   */
  Forbidden: '/403' as const,
  NotFound: '/404' as const,
  /**
   * 開発
   */
  ATOM_PARTS_LIST: '/atoms' as const,
  MOLECULE_PARTS_LIST: '/molecules' as const,
  ORGANISM_PARTS_LIST: '/organisms' as const,
  /**
   * 外部サイト
   */
  EXTERNAL_NANOMEDIA_CO: 'https://www.nanomedia.jp' as const,
};
