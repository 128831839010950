import { AllState } from '../store';

const getNewsState = (state: AllState) => {
  return state.news;
};

const getNotificationSortedList = (state: AllState) => {
  const { notifications } = state.news;

  const list: typeof notifications = [];
  notifications.forEach(n => list.unshift(n));

  return list;
};

const getIsLastPage = (state: AllState) => {
  return state.news.pager.current_page === state.news.pager.last_page;
};

export default {
  getNewsState,
  getNotificationSortedList,
  getIsLastPage,
};
