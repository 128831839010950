/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useState, useCallback } from 'react';
import ClassNames from 'classnames';

import IconShape from '../../atoms/iconParts/IconShape';

import OrgPostContentFanclubList from './OrgPostContentFanclubList';

import * as styles from './OrgPostContent.module.scss';

import { Fanclub } from '../../../../interfaces/fanclubInterFace';

type Props = {
  fanclubList?: Fanclub[];
  onClickFanclub?: (artistId: string) => void;
  cssClass?: string;
};

const OrgPostContent: FC<Props> = ({
  fanclubList = [],
  onClickFanclub = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgPostContentBase, cssClass);

  const [isVisible, setVisible] = useState(false);

  const handleClickButton = useCallback(() => {
    if (fanclubList.length === 0) return;

    if (fanclubList.length === 1) onClickFanclub(fanclubList[0].group_id);

    if (fanclubList.length > 1) setVisible(true);
  }, [fanclubList, onClickFanclub]);

  const handleBlur = useCallback(() => {
    setVisible(false);
  }, []);

  return (
    <div className={classNames}>
      <button
        type="button"
        className={styles.OrgPostContentButton}
        onClick={handleClickButton}
      >
        <IconShape type="PenWhite" />
        <p>コンテンツを投稿する</p>
      </button>
      {isVisible && (
        <>
          <OrgPostContentFanclubList
            title="投稿するコミュニティを選択"
            fanclubList={fanclubList}
            onClickFanclub={onClickFanclub}
            cssClass={styles.OrgPostContentModal}
          />
          <div className={styles.OrgPostContentModalBg} onClick={handleBlur} />
        </>
      )}
    </div>
  );
};

export default OrgPostContent;
