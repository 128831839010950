import React, { FC } from 'react';

import McsHeaderComment from '../common/molecules/headerParts/McsHeaderComment';
import OrgUserListUnitMemberTertiary from '../common/organisms/listUnitParts/OrgUserListUnitMemberTertiary';
import ButtonFullWidth from '../common/atoms/buttonBasic/ButtonFullWidth';

import { likedUser } from '../../interfaces/likesInterFace';

import * as styles from './like.module.scss';

type Props = {
  total?: number;
  users?: likedUser[];
  isViewReadMoreButton?: boolean;
  handleBack?: () => void;
  onClickUser?: (userId: string) => void;
  handleReadMore?: () => void;
};

const Component: FC<Props> = ({
  total = 0,
  users = [],
  isViewReadMoreButton = false,
  handleBack = () => undefined,
  onClickUser = () => undefined,
  handleReadMore = () => undefined,
}) => {
  return (
    <>
      <div className={styles.commentWrap}>
        <McsHeaderComment
          total={total}
          text=" いいね"
          onClickButton={handleBack}
          cssClass={styles.commentHeader}
        />
        {users.map(userInfo => (
          <div className={styles.userWrap} key={userInfo.user.name_id}>
            <OrgUserListUnitMemberTertiary
              user={userInfo.user}
              onClick={() => onClickUser(userInfo.user.name_id)}
            />
          </div>
        ))}
        {/* Read More Button */}
        {isViewReadMoreButton && (
          <ButtonFullWidth
            text="もっと見る"
            type="Senary"
            onClick={handleReadMore}
          />
        )}
      </div>
    </>
  );
};

export default Component;
