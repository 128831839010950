import { Reducer } from 'redux';
import { Actions } from '../actions/attachGenresActions';
import * as ActionType from '../actions/attachGenresConstants';

type State = {
  loading: boolean;
  error: boolean;
  success: boolean;
};

export const initialState: State = {
  loading: false,
  error: false,
  success: false,
};

const attachGenresReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // attach genres
    case ActionType.ATTACH_GENRES_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.ATTACH_GENRES_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ActionType.ATTACH_GENRES_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default attachGenresReducer;
