import { Status, ErrorResponse } from './commonInterFace';

/**
 * 0: 銀行, 1: ゆうちょ銀行
 */
export type BankType = 0 | 1;

/**
 * 1: 普通, 2: 当座
 */
export type AccountType = 1 | 2;

export type BankDetails = {
  id: number;
  fanclub_id: number;
  name: string;
  banks: BankType;
  bank_code: string;
  store_code: string;
  assortment: AccountType;
  account_number: string;
  /**
   * 記号（ゆうちょ専用）
   */
  sign: string | null;
  // created_at: string | null;
  // updated_at: string | null;
  // deleted_at: string | null;
};
export const BankDetailsInitialValue: BankDetails = {
  id: 0,
  fanclub_id: 0,
  name: '',
  banks: 0,
  bank_code: '',
  store_code: '',
  assortment: 1,
  account_number: '',
  sign: null,
};

export type GetBankAccountParams = {
  artistId: string;
};
export type GetBankAccountResult = BankDetails | null;
export type GetBankAccountResponse = {
  status_code: Status.OK;
  result: GetBankAccountResult;
};
export type RegisterBankAccountParams = Omit<
  BankDetails,
  'id' | 'fanclub_id' | 'sign'
> & {
  artistId: string;
  sign?: string;
};

export type RegisterOtherBankAccountInputParams = Required<
  Omit<
    RegisterBankAccountParams,
    'artistId' | 'bank_code' | 'store_code' | 'sign'
  >
> & {
  banks: 0;
  bank_name: string;
  store_name: string;
};
export const RegisterOtherBankAccountInputParamsInitialValue: RegisterOtherBankAccountInputParams = {
  banks: 0,
  bank_name: '',
  store_name: '',
  assortment: 1,
  account_number: '',
  name: '',
};
export type RegisterJPBankAccountInputParams = Required<
  Omit<RegisterBankAccountParams, 'artistId' | 'bank_code' | 'store_code'>
> & {
  banks: 1;
  sign: string;
};
export const RegisterJPBankAccountInputParamsInitialValue: RegisterJPBankAccountInputParams = {
  banks: 1,
  sign: '',
  assortment: 1,
  account_number: '',
  name: '',
};
export type RegisterBankAccountResponse = {
  status_code: Status.OK;
  message: string;
};
export type RegisterBankAccountErrorResponse = ErrorResponse<
  keyof RegisterBankAccountParams
>;
export type RegisterBankAccountErrors = {
  [key in keyof Partial<RegisterBankAccountParams>]: string[];
};
export type RegisterOtherBankAccountValidationErrors = Record<
  keyof RegisterOtherBankAccountInputParams | 'banks',
  string
>;
export const RegisterOtherBankAccountValidationErrorsInitialValue: RegisterOtherBankAccountValidationErrors = {
  banks: '',
  bank_name: '',
  store_name: '',
  assortment: '',
  name: '',
  account_number: '',
};
export type RegisterJPBankAccountValidationErrors = Record<
  keyof RegisterJPBankAccountInputParams | 'banks',
  string
>;
export const RegisterJPBankAccountValidationErrorsInitialValue: RegisterJPBankAccountValidationErrors = {
  banks: '',
  sign: '',
  assortment: '',
  account_number: '',
  name: '',
};

/**
 * 銀行情報取得に使用しているAPIは、下記を参照
 * https://bank.teraren.com/
 */
export type Bank = {
  code: string; // '0001'
  kana: string; // 'ミズホ'
  name: string; // 'みずほ'
  roma: string; // 'mizuho'
  hira: string; // 'みずほ'
};

/**
 * 銀行情報取得に使用しているAPIは、下記を参照
 * https://bank.teraren.com/
 */
export type BankBranch = {
  code: string; // '001'
  kana: string; // 'トウキヨウ'
  name: string; // '東京営業部'
  roma: string; // 'toukiyou'
  hira: string; // 'とうきよう'
};

export type SearchBankParams = {
  name: string;
};
export type SearchBankResponse = Bank[];
export type SearchBankBranchParams = {
  code: string;
  name: string;
};
export type SearchBankBranchResponse = BankBranch[];

export type GetBankParams = {
  bankCode: string;
};
export type GetBankResponse = Bank;
export type GetBankBranchParams = {
  bankCode: string;
  branchCode: string;
};
export type GetBankBranchResponse = BankBranch;
