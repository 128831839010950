/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, join, takeLatest } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/adminCommentsConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getCommentListEvent,
} from '../actions/adminCommentsActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import { GetCommentListResponse } from '../interfaces/commentInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const getCommentListTask = yield fork(
    runGetCommentList,
    getCommentListHandler,
    getCommentListEvent.start(action.payload),
  );
  yield join(getCommentListTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get comment list
const getCommentListHandler = api.getGetFactory(true);
function* runGetCommentList(
  handler: typeof getCommentListHandler,
  action: ReturnType<typeof getCommentListEvent.start>,
) {
  const data = action.payload;

  try {
    const ReturnData: GetCommentListResponse = yield call(
      handler,
      data,
      `/comment`,
    );

    if (!ReturnData) {
      yield put(getCommentListEvent.fail());

      return;
    }

    yield put(getCommentListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getCommentListEvent.fail());
  }
}
export function* getCommentList(handler: typeof getCommentListHandler) {
  yield takeLatest(
    ActionType.GET_COMMENT_LIST_START,
    runGetCommentList,
    handler,
  );
}

export const adminCommentsSagas = [
  fork(getReady),
  fork(getCommentList, getCommentListHandler),
];
