/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, join, takeLatest } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';
import { checkAndSetStatusError } from './authSagas';

import * as ActionType from '../actions/chatConstants';
import {
  getReady as getReadyStart,
  gotReady,
  getFanclubDetailsEvent,
  getChatEvent,
  submitChatEvent,
  chatReadEvent,
  chatDeleteEvent,
} from '../actions/chatActions';
import { ErrorResponse } from '../interfaces/commonInterFace';
import { GetFanclubDetailsResponse } from '../interfaces/fanclubInterFace';
import { chat, getChatResponse } from '../interfaces/chatInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const artistId = action.payload;

  const getFanclubDetailsTask = yield fork(
    runGetFanclubDetails,
    getFanclubDetailsHandler,
    getFanclubDetailsEvent.start(artistId),
  );
  yield join(getFanclubDetailsTask);

  const getChatTask = yield fork(
    runGetChat,
    getChatHandler,
    getChatEvent.start(artistId),
  );
  yield join(getChatTask);

  yield put(gotReady());
}

// ファンクラブ詳細取得
const getFanclubDetailsHandler = api.getGetFactory(true);
function* runGetFanclubDetails(
  handler: typeof getFanclubDetailsHandler,
  action: ReturnType<typeof getFanclubDetailsEvent.start>,
) {
  const artistId = action.payload;
  try {
    const ReturnData: GetFanclubDetailsResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(getFanclubDetailsEvent.fail());

      return;
    }

    yield put(getFanclubDetailsEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFanclubDetailsEvent.fail());
  }
}
export function* getFanclubDetails(handler: typeof getFanclubDetailsHandler) {
  yield takeLatest(
    ActionType.GET_FANCLUB_DETAILS_START,
    runGetFanclubDetails,
    handler,
  );
}

// チャットリスト取得
const getChatHandler = api.getGetFactory(true);
function* runGetChat(
  handler: typeof getChatHandler,
  action: ReturnType<typeof getChatEvent.start>,
) {
  const artistId = action.payload;
  try {
    const ReturnData: getChatResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/chats`,
    );

    if (!ReturnData) {
      yield put(getChatEvent.fail());

      return;
    }

    yield put(getChatEvent.succeed(ReturnData));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getChatEvent.fail());
  }
}
export function* getChat(handler: typeof getChatHandler) {
  yield takeLatest(ActionType.GET_CHAT_START, runGetChat, handler);
}

// チャット送信
const postChatHandler = api.getPostFactory(true);
function* runPostChat(
  handler: typeof postChatHandler,
  action: ReturnType<typeof submitChatEvent.start>,
) {
  const { chat: text, artistId } = action.payload;
  try {
    const ReturnData: chat = yield call(
      handler,
      { chat: text },
      `/fanclub/${artistId}/chats`,
    );

    if (!ReturnData) {
      yield put(submitChatEvent.fail());

      return;
    }
    yield put(submitChatEvent.succeed());
  } catch (error) {
    yield put(submitChatEvent.fail());
  }
}
export function* postChat(handler: typeof postChatHandler) {
  yield takeLatest(ActionType.SUBMIT_CHAT_START, runPostChat, handler);
}

// チャットの既読処理
const chatReadHandler = api.getGetFactory(true);
function* runChatRead(
  handler: typeof chatReadHandler,
  action: ReturnType<typeof chatReadEvent.start>,
) {
  const artistId = action.payload;
  try {
    const ReturnData: getChatResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/chats/alreadyRead`,
    );

    if (!ReturnData) {
      yield put(chatReadEvent.fail());

      return;
    }

    yield put(chatReadEvent.succeed());
  } catch (error) {
    yield put(chatReadEvent.fail());
  }
}
export function* chatRead(handler: typeof chatReadHandler) {
  yield takeLatest(ActionType.CHAT_READ_START, runChatRead, handler);
}

// チャット削除処理
const chatDeleteHandler = api.getDeleteFactory(true);
function* runChatDelete(
  handler: typeof chatDeleteHandler,
  action: ReturnType<typeof chatDeleteEvent.start>,
) {
  const { chatId, artistId } = action.payload;
  try {
    const ReturnData: chat = yield call(
      handler,
      {},
      `/fanclub/${artistId}/chats/${chatId}`,
    );

    if (!ReturnData) {
      yield put(chatDeleteEvent.fail());

      return;
    }
    yield put(chatDeleteEvent.succeed());
  } catch (error) {
    window.alert('削除に失敗しました');
    yield put(chatDeleteEvent.fail());
  }
}
export function* chatDelete(handler: typeof chatDeleteHandler) {
  yield takeLatest(ActionType.CHAT_DELETE_START, runChatDelete, handler);
}

export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

export const chatSagas = [
  fork(getReady),
  fork(getFanclubDetails, getFanclubDetailsHandler),
  fork(getChat, getChatHandler),
  fork(postChat, postChatHandler),
  fork(chatRead, chatReadHandler),
  fork(chatDelete, chatDeleteHandler),
];
