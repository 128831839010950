/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, join, takeLatest } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/followersConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getFanclubDetailsEvent,
  getFCFollowerListEvent,
} from '../actions/followersActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import { GetFCFollowerListResponse } from '../interfaces/followerInterFace';
import { GetFanclubDetailsResponse } from '../interfaces/fanclubInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId } = action.payload;

  const getFCDetailTask = yield fork(
    runGetFanclubDetails,
    getFanclubDetailsHandler,
    getFanclubDetailsEvent.start({ artistId }),
  );
  yield join(getFCDetailTask);

  const getFCFollowerListTask = yield fork(
    runGetFCFollowerList,
    getFCFollowerListHandler,
    getFCFollowerListEvent.start({
      artistId,
      sort_type: 'DESC',
      limit: 20,
      user_type: 3,
    }),
  );
  yield join(getFCFollowerListTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get fanclub details
const getFanclubDetailsHandler = api.getGetFactory(true);
function* runGetFanclubDetails(
  handler: typeof getFanclubDetailsHandler,
  action: ReturnType<typeof getFanclubDetailsEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetFanclubDetailsResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(getFanclubDetailsEvent.fail());

      return;
    }

    yield put(getFanclubDetailsEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFanclubDetailsEvent.fail());
  }
}
export function* getFanclubDetails(handler: typeof getFanclubDetailsHandler) {
  yield takeLatest(
    ActionType.GET_FANCLUB_DETAILS_START,
    runGetFanclubDetails,
    handler,
  );
}

// get fc follower list
const getFCFollowerListHandler = api.getGetFactory(true);
function* runGetFCFollowerList(
  handler: typeof getFCFollowerListHandler,
  action: ReturnType<typeof getFCFollowerListEvent.start>,
) {
  const { artistId, ...data } = action.payload;

  try {
    const ReturnData: GetFCFollowerListResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/follower`,
    );

    if (!ReturnData) {
      yield put(getFCFollowerListEvent.fail());

      return;
    }

    yield put(
      getFCFollowerListEvent.succeed(action.payload, ReturnData.result),
    );
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFCFollowerListEvent.fail());
  }
}
export function* getFCFollowerList(handler: typeof getFCFollowerListHandler) {
  yield takeLatest(
    ActionType.GET_FC_FOLLOWER_LIST_START,
    runGetFCFollowerList,
    handler,
  );
}

export const followersSagas = [
  fork(getReady),
  fork(getFanclubDetails, getFanclubDetailsHandler),
  fork(getFCFollowerList, getFCFollowerListHandler),
];
