/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import ButtonShapeSNS from '../../atoms/buttonSNS/ButtonShapeSNS';
import * as styles from './McsSquareSNSButtons.module.scss';

interface Props {
  cssClass?: string;
  onTwitterClick?: () => void;
  onFaceBookClick?: () => void;
  onLineClick?: () => void;
}

const McsSNSButtons: FC<Props> = ({
  cssClass = '',
  onTwitterClick = () => undefined,
  onFaceBookClick = () => undefined,
  onLineClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsSNSButtonsBase, cssClass);

  return (
    <ul className={classNames}>
      <li className={styles.McsSNSButtonsTwitter}>
        <ButtonShapeSNS type="Twitter" onClick={onTwitterClick} />
      </li>
      <li className={styles.McsSNSButtonsFaceBook}>
        <ButtonShapeSNS type="FaceBook" onClick={onFaceBookClick} />
      </li>
      <li className={styles.McsSNSButtonsLine}>
        <ButtonShapeSNS type="Line" onClick={onLineClick} />
      </li>
    </ul>
  );
};

export default McsSNSButtons;
