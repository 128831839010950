/* eslint-disable no-useless-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  updateUserProfileEvent,
  cleanup,
} from '../../actions/editUserProfileActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';
import { getYears, getMonths, getDates } from '../../utils/datetimeHelper';
import { validateNickName } from '../../utils/validationHelper';

import { GenreWithSelected } from '../../interfaces/genreInterFace';
import {
  UpdateUserProfileInputParams,
  UpdateUserProfileInputParamsInitialValue,
  UpdateUserProfileParams,
  UpdateUserProfileValidationErrors,
} from '../../interfaces/userInterFace';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import EditUserProfile from '../../components/user/editUserProfile';

const EditUserProfileContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [params, setParams] = useState<UpdateUserProfileInputParams>(
    UpdateUserProfileInputParamsInitialValue,
  );

  const { ready: authReady, profile } = useSelector(selector.auth.getAuthState);
  const { ready, loading, errors, success } = useSelector(
    selector.editUserProfile.getEditUserProfileState,
  );
  const { prefectures } = useSelector(selector.util.getSelectFields);
  const genreFields = useSelector(selector.util.getGenreFields);
  const genres: GenreWithSelected[][] = useMemo(
    () =>
      genreFields.map(group =>
        group.map(genre => ({
          id: genre.value,
          name: genre.label,
          selected: params.genres.includes(genre.value),
        })),
      ),
    [params.genres, genreFields],
  );
  const years = useMemo(
    () => getYears(params.birthday_month, params.birthday_date),
    [params.birthday_month, params.birthday_date],
  );
  const months = useMemo(
    () => getMonths(params.birthday_year, params.birthday_date),
    [params.birthday_year, params.birthday_date],
  );
  const dates = useMemo(
    () => getDates(params.birthday_year, params.birthday_month),
    [params.birthday_year, params.birthday_month],
  );

  const validationErrors: UpdateUserProfileValidationErrors = useMemo(
    () => ({
      nickname: errors.nickname?.[0] ?? validateNickName(params.nickname),
      name_id: errors.name_id?.[0] ?? '',
      icon_image: errors.icon_image?.[0] ?? '',
      cover_image: errors.cover_image?.[0] ?? '',
      prefecture: errors.prefecture?.[0] ?? '',
      self_introduction: errors.self_introduction?.[0] ?? '',
      genres: errors.genres?.[0] ?? '',
      birthday: errors.birthday?.[0] ?? '',
    }),
    [errors, params.nickname],
  );

  const canSubmit = useMemo(() => {
    return (
      !!params.nickname &&
      validateNickName(params.nickname).length === 0 &&
      !!params.genres.length
    );
  }, [params.genres.length, params.nickname]);

  const handleChange = useCallback(
    (
      e: React.FormEvent<
        HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
      >,
    ) => {
      const { name, value } = e.currentTarget;

      setParams(params => ({ ...params, [name]: value }));
    },
    [],
  );
  const handleChangeGenre = useCallback((genre_id: number) => {
    setParams(params => ({
      ...params,
      genres: params.genres.includes(genre_id)
        ? params.genres.filter(genre => genre !== genre_id)
        : [...params.genres, genre_id],
    }));
  }, []);
  const handleChangeImage = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { name, files } = e.currentTarget;

      if (!files) return;

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.size > 10000000) {
          // limit 10MB
          window.alert('画像のファイルサイズは10MB以下です');

          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          setParams(params => ({
            ...params,
            [name]: {
              file,
              preview: reader.result as string,
            },
          }));
        };
        reader.readAsDataURL(file);
      }
    },
    [],
  );

  // memo化しない
  const handleSubmit = () => {
    if (!canSubmit) return;

    const {
      cover_image,
      icon_image,
      birthday_year,
      birthday_month,
      birthday_date,
      ...data
    } = params;

    const birthday =
      birthday_year || birthday_month || birthday_date
        ? [
            birthday_year,
            birthday_month.toString().padStart(2, '0'),
            birthday_date.toString().padStart(2, '0'),
          ].join('-')
        : undefined;

    const updateUserProfileParams: UpdateUserProfileParams = {
      ...data,
      name_id: profile.user.name_id,
      cover_image: cover_image.file ?? undefined,
      icon_image: icon_image.file ?? undefined,
      birthday,
    };
    dispatch(updateUserProfileEvent.start(updateUserProfileParams));
  };

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      pr: {
        right: {
          text: 'コミュニティを作る',
          onClick: () => history.push(PATH.OPEN_FC_AGREEMENT),
        },
      },
      sns: true,
      nav: true,
      copyright: true,
    }),
    [history],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    dispatch(getReady());
  }, [authReady, dispatch]);

  useEffect(() => {
    if (profile.user.id === 0) return;

    const { user, genres } = profile;

    const [birthday_year, birthday_month, birthday_date] = user.birthday
      ?.split('-')
      .map(s => Number(s)) ?? [0, 0, 0];

    const params: UpdateUserProfileInputParams = {
      cover_image: {
        file: null,
        preview: user.cover_image ?? '',
      },
      icon_image: {
        file: null,
        preview: user.icon_image ?? '',
      },
      nickname: user.nickname ?? '',
      prefecture: user.prefecture ?? 0,
      self_introduction: user.self_introduction ?? '',
      genres: genres.map(g => g.id),
      birthday_year,
      birthday_month,
      birthday_date,
    };

    setParams(params);
  }, [profile]);

  useEffect(() => {
    if (success) {
      history.push(PATH.getUserPath(profile.user.name_id));
    }
  }, [profile.user.name_id, history, success]);

  useEffect(() => {
    if (JSON.stringify(errors) === JSON.stringify({})) return;

    window.alert('入力項目を確認してください');
    window.scrollTo(0, 0);
  }, [errors]);

  return (
    <>
      <Helmet>
        <title>プロフィール編集 | 設定 | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      <EditUserProfile
        userId={profile.user.name_id}
        user={params}
        genreList={genres}
        prefectureList={prefectures}
        yearList={years}
        monthList={months}
        dateList={dates}
        onChange={handleChange}
        onChangeImage={handleChangeImage}
        onChangeGenre={handleChangeGenre}
        onBack={handleBack}
        onSubmit={handleSubmit}
        errors={validationErrors}
        canSubmit={canSubmit}
      />
    </>
  );
};

export default EditUserProfileContainer;
