/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import Badge from '../../atoms/badge/Badge';
import IconShape from '../../atoms/iconParts/IconShape';
import IconKrapLogo from '../../atoms/iconParts/IconKrapLogo';
import IconThumbnailRound from '../../atoms/iconThumbnail/IconThumbnailRound';

import ButtonSearch from '../../atoms/buttonUtile/ButtonSearch';
import ButtonNav from '../../atoms/buttonUtile/ButtonNav';

import * as styles from './OrgHeaderBar.module.scss';

interface Props {
  cssClass?: string;
  isOpen: boolean;
  isSearchOpen: boolean;
  isLogin: boolean;
  iconSrc?: string;
  unreadCount?: number;
  onNavClick: () => void;
  onLogoClick: () => void;
  onBellClick: () => void;
  onSearchClick: () => void;
}

const OrgHeaderBar: FC<Props> = ({
  cssClass = '',
  isOpen = false,
  isSearchOpen = false,
  isLogin = false,
  iconSrc = '',
  unreadCount = 0,
  onNavClick = () => undefined,
  onLogoClick = () => undefined,
  onBellClick = () => undefined,
  onSearchClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgHeaderBarBase, cssClass);

  return (
    <div className={classNames}>
      <div className={styles.OrgHeaderBarLeftContainer}>
        {!isLogin ? (
          <div className={styles.OrgHeaderBarUserIconDummy} />
        ) : !isOpen ? (
          <div className={styles.OrgHeaderBarUserIcon} onClick={onNavClick}>
            <IconThumbnailRound src={iconSrc} size="Quinary" />
          </div>
        ) : (
          <ButtonNav type={!isOpen ? 'Open' : 'Close'} onClick={onNavClick} />
        )}

        <div className={styles.OrgHeaderBarUserIconDummy} />
      </div>

      <IconKrapLogo onClick={onLogoClick} />

      <div className={styles.OrgHeaderBarRightContainer}>
        {/* news bell icon */}
        {isLogin ? (
          <div className={styles.OrgHeaderBarBell}>
            <IconShape type="Bell" onClick={onBellClick} />
            {unreadCount > 0 ? (
              <Badge
                type="Primary"
                count={unreadCount > 99 ? 99 : unreadCount}
                cssClass={styles.OrgHeaderBarBellBadge}
              />
            ) : null}
          </div>
        ) : (
          <div className={styles.OrgHeaderBarUserIconDummy} />
        )}

        {!isSearchOpen ? (
          <ButtonSearch onClick={onSearchClick} />
        ) : (
          <ButtonNav type="Close" onClick={onSearchClick} />
        )}
      </div>
    </div>
  );
};

export default OrgHeaderBar;
