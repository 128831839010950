import * as ActionType from './adminBlocksConstants';
import {
  GetFCBlockListParams,
  GetFCBlockListResult,
  UnblockUserParams,
  UnblockUserResult,
} from '../interfaces/followerInterFace';

export const getReady = (artistId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getFCBlockListEvent = {
  start: (params: GetFCBlockListParams) =>
    ({
      type: ActionType.GET_FC_BLOCK_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetFCBlockListResult) =>
    ({
      type: ActionType.GET_FC_BLOCK_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FC_BLOCK_LIST_FAIL,
    } as const),
};

export const unblockUserEvent = {
  start: (params: UnblockUserParams) =>
    ({
      type: ActionType.UNBLOCK_USER_START,
      payload: params,
    } as const),

  succeed: (params: UnblockUserParams, result: UnblockUserResult) =>
    ({
      type: ActionType.UNBLOCK_USER_SUCCEED,
      payload: { params, result },
    } as const),

  fail: () =>
    ({
      type: ActionType.UNBLOCK_USER_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fanclub details
  | ReturnType<typeof getFCBlockListEvent.start>
  | ReturnType<typeof getFCBlockListEvent.succeed>
  | ReturnType<typeof getFCBlockListEvent.fail>
  // unblock follower
  | ReturnType<typeof unblockUserEvent.start>
  | ReturnType<typeof unblockUserEvent.succeed>
  | ReturnType<typeof unblockUserEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
