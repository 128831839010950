/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import {
  loadStripe,
  StripeCardNumberElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
} from '@stripe/stripe-js';
import {
  Elements,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  paidjoinFanclubEvent,
  resetApiStatus,
  cleanup,
} from '../../actions/paidjoinActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { StripeInputErrors } from '../../interfaces/stripeInterFace';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';

import PaidjoinRegister from '../../components/paidjoin/paidjoinRegister';
import PaidjoinComplete from '../../components/paidjoin/paidjoinComplete';
import PaidjoinError from '../../components/paidjoin/paidjoinError';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '', {
  locale: 'ja',
});

const PaidJoinContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const [name, setName] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [cardErrors, setCardErrors] = useState<StripeInputErrors>({});
  const stripe = useStripe();
  const elements = useElements();
  const { ready: authReady } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const {
    ready,
    card,
    details,
    paidjoin_at,
    success,
    error,
    loading,
  } = useSelector(selector.paidjoin.getPaidjoinState);
  const canPaidjoin = useMemo(() => {
    const noError =
      !cardErrors.name &&
      !cardErrors.cardNumber &&
      !cardErrors.cardExpiry &&
      !cardErrors.name &&
      !cardErrors.cardCvc;

    const hasCard = !!card;

    return (noError || hasCard) && agreed;
  }, [agreed, card, cardErrors]);

  // Stripeのリアルタイムバリデーション
  const handleChangeCardInfo = useCallback(
    (
      e:
        | StripeCardNumberElementChangeEvent
        | StripeCardCvcElementChangeEvent
        | StripeCardExpiryElementChangeEvent,
    ) => {
      if (
        e.elementType === 'cardNumber' &&
        !e.empty &&
        !(
          e.brand === 'visa' ||
          e.brand === 'jcb' ||
          e.brand === 'mastercard' ||
          e.brand === 'amex' ||
          e.brand === 'diners'
        )
      ) {
        setCardErrors(errors => ({
          ...errors,
          cardNumber: '使用できないカードブランドです。',
        }));

        return;
      }

      if (e.error) {
        setCardErrors(errors => ({
          ...errors,
          [e.elementType]: e.error?.message ?? '',
        }));
      } else {
        setCardErrors(errors => ({
          ...errors,
          [e.elementType]: '',
        }));
      }
    },
    [],
  );

  const handleChangeName = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;

      if (value && !value.trim()) {
        setCardErrors(errors => ({
          ...errors,
          name: 'カード名義に不備があります。',
        }));
      } else {
        setCardErrors(errors => ({
          ...errors,
          name: '',
        }));
      }

      if (!value.match(/^[a-zA-Z0-9 ]*$/)) {
        e.preventDefault();

        return;
      }

      setName(value);
    },
    [],
  );

  const handleLinkToChangeCreditCard = useCallback(() => {
    history.push(PATH.SETTING_CREDIT_CARD);
  }, [history]);

  const handleToggleAgreed = useCallback(() => {
    setAgreed(agreed => !agreed);
  }, []);

  const handlePaidjoin = async () => {
    if (!artistId) return;

    if (!agreed) return;

    // 既にカードを登録済みの場合
    if (card) {
      const res = window.confirm('本当に入会しますか？');

      if (!res) return;

      // 有料入会処理
      dispatch(
        paidjoinFanclubEvent.start({
          artistId,
        }),
      );

      return;
    }

    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) return;

    const result = await stripe.createToken(cardElement, { name });

    let error = false;

    const isEnteredAndUsableCard =
      !result.token?.card?.funding || result.token?.card?.funding === 'credit';

    if (!isEnteredAndUsableCard) {
      setCardErrors(errors => ({
        ...errors,
        cardNumber: 'クレジットカードのみ使用できます。',
      }));

      error = true;
    }

    if (!name.trim()) {
      setCardErrors(errors => ({
        ...errors,
        name: 'カード名義に不備があります。',
      }));

      error = true;
    }

    if (result.error || error) {
      // カード情報の作成に失敗した場合
      window.scrollTo(0, 0);
    } else if (result.token) {
      setCardErrors({});

      const res = window.confirm('本当に入会しますか？');

      if (!res) return;

      // 有料入会処理
      dispatch(
        paidjoinFanclubEvent.start({
          artistId,
          stripeToken: result.token.id,
          stripeCard: result.token.card?.id,
        }),
      );
    }
  };

  const handleLinkToArtist = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getArtistPath(artistId));
  }, [artistId, history]);

  const handleReEnterCardInfo = useCallback(() => {
    setName('');
    setAgreed(false);
    dispatch(resetApiStatus());
  }, [dispatch]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      nav: true,
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!isLogin || !artistId) {
      history.replace(PATH.HOME);

      return;
    }

    dispatch(getReady(artistId));
  }, [artistId, authReady, dispatch, history, isLogin]);

  useEffect(() => {
    if (ready && details.fanclub.paid_status !== 2) {
      history.replace(PATH.NotFound);
    }
  }, [details.fanclub.paid_status, history, ready]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>KRAP</title>
        ) : (
          <title>コミュニティ入会 | {details.fanclub.name} | KRAP</title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      {error ? (
        <PaidjoinError onClickButton={handleReEnterCardInfo} />
      ) : success ? (
        <PaidjoinComplete
          details={details}
          firstPaymentDate={paidjoin_at}
          onClickButton={handleLinkToArtist}
        />
      ) : (
        <PaidjoinRegister
          details={details}
          card={card}
          name={name}
          agreed={agreed}
          onLinkToChangeCreditCard={handleLinkToChangeCreditCard}
          canPaidjoin={canPaidjoin}
          onChangeCardInfo={handleChangeCardInfo}
          onChangeName={handleChangeName}
          onToggleAgreed={handleToggleAgreed}
          onSubmit={handlePaidjoin}
          cardErrors={cardErrors}
        />
      )}
    </>
  );
};

const StripeContainer: FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaidJoinContainer />
    </Elements>
  );
};

export default StripeContainer;
