import { Reducer } from 'redux';
import { Actions } from '../actions/commentsActions';
import * as ActionType from '../actions/commentsConstants';
import { Comment, SubmitCommentErrors } from '../interfaces/commentInterFace';
import {
  ContentDetail,
  ContentDetailInitialValue,
} from '../interfaces/contentInterFace';
import {
  SimpleUser,
  SimpleUserInitialValue,
} from '../interfaces/userInterFace';

type State = {
  detail: ContentDetail;
  create_user: SimpleUser | null;
  comments: Comment[];
  ready: boolean;
  loading: boolean;
  errors: SubmitCommentErrors;
  success: boolean;
};

export const initialState: State = {
  detail: ContentDetailInitialValue,
  create_user: SimpleUserInitialValue,
  comments: [],
  ready: false,
  loading: false,
  errors: {},
  success: false,
};

const commentsReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get comment list
    case ActionType.GET_CONTENT_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_CONTENT_DETAILS_SUCCEED: {
      return {
        ...state,
        detail: action.payload.result.detail,
        create_user: action.payload.result.create_user,
        comments: action.payload.result.comments,
        loading: false,
      };
    }
    case ActionType.GET_CONTENT_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // submit comment
    case ActionType.SUBMIT_COMMENT_START: {
      return {
        ...state,
        loading: true,
        success: false,
      };
    }
    case ActionType.SUBMIT_COMMENT_SUCCEED: {
      const { name_id, ...result } = action.payload.result;

      return {
        ...state,
        comments: [
          ...state.comments,
          {
            ...result,
            user: {
              ...action.payload.params.user,
              name_id,
            },
          },
        ],
        loading: false,
        success: true,
      };
    }
    case ActionType.SUBMIT_COMMENT_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }

    // delete comment
    case ActionType.DELETE_COMMENT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.DELETE_COMMENT_SUCCEED: {
      return {
        ...state,
        comments: state.comments.filter(
          c => c.id !== action.payload.params.commentId,
        ),
        loading: false,
      };
    }
    case ActionType.DELETE_COMMENT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // freejoin fanclub
    case ActionType.FREEJOIN_FANCLUB_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.FREEJOIN_FANCLUB_SUCCEED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.FREEJOIN_FANCLUB_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default commentsReducer;
