/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import McsIconLabelRoundSecondary from '../../molecules/iconWithLabel/McsIconLabelRoundSecondary';
import LabelTag from '../../atoms/label/LabelTag';
import ButtonDialog from '../../atoms/buttonUtile/ButtonDialog';
import OrgDialogModal from '../modalParts/OrgDialogModal';

import noUserImageUrl from '../../assets/images/icon-nouser.png';

import { User, UserInitialValue } from '../../../../interfaces/userInterFace';

import * as styles from './OrgUserListUnitMemberQuaternary.module.scss';

type PartialUser = Partial<User>;

interface Props {
  cssClass?: string;
  user?: PartialUser;
  onClickButton?: () => void;
  onShowDialog?: (index: number) => void;
  onHideDialog?: () => void;
  isAdmin?: boolean;
  isDialog?: boolean;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  user = UserInitialValue,
  onClickButton = () => undefined,
  onShowDialog = () => undefined,
  onHideDialog = () => undefined,
  isAdmin = false,
  isDialog = false,
}) => {
  const classNames = ClassNames(styles.OrgUserListUnitBase, cssClass);

  return (
    <li
      className={classNames}
      key={`OrgUserListUnitMemberQuaternary${user.id}`}
    >
      <div className={styles.OrgUserListUnitContent}>
        <div className={styles.OrgUserListUnitUser}>
          <McsIconLabelRoundSecondary
            src={user.icon_image || noUserImageUrl}
            mainText={user.nickname}
            subText={`@${user.name_id}`}
          />
          {isAdmin && (
            <LabelTag
              text="管理者"
              type="Septenary"
              cssClass={styles.OrgUserListUnitLabel}
            />
          )}
        </div>

        {isDialog ? (
          <ButtonDialog type="Secondary" onClick={onClickButton} />
        ) : (
          <ButtonDialog onClick={onClickButton} />
        )}

        {isDialog && (
          <>
            <OrgDialogModal
              list={['削除する', '管理者権限を譲渡する']}
              onClick={onShowDialog}
              cssClass={styles.OrgUserListUnitDialog}
            />
            <div
              className={styles.OrgUserListUnitDialogBg}
              onClick={onHideDialog}
            />
          </>
        )}
      </div>
    </li>
  );
};

export default OrgListUnit;
