/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';

import OrgUserList from '../listParts/OrgUserList';

import { User } from '../../../../interfaces/userInterFace';

import * as styles from './OrgUserListModal.module.scss';

interface Props {
  users?: User[];
  onClickUser?: (userId: string) => void;
  onClickBlur?: () => void;
}

const OrgUserListModal: FC<Props> = ({
  users = [],
  onClickUser = () => undefined,
  onClickBlur = () => undefined,
}) => {
  return (
    <div className={styles.OrgUserListModalNavWrap} onClick={onClickBlur}>
      <div className={styles.OrgUserListModalNavContent}>
        {users.length && <OrgUserList userList={users} onClick={onClickUser} />}
      </div>
      <div className={styles.OrgUserListModalNavBg} />
    </div>
  );
};

export default OrgUserListModal;
