import React, { FC } from 'react';
import ClassNames from 'classnames';
import OrgUserListUnitMemberTertiary from '../listUnitParts/OrgUserListUnitMemberTertiary';
import { User } from '../../../../interfaces/userInterFace';

import * as styles from './OrgUserList.module.scss';

type PartialUser = Partial<User>;

interface Props {
  cssClass?: string;
  userList?: PartialUser[];
  onClick?: (userId: string) => void;
}

const OrgUserList: FC<Props> = ({
  cssClass = '',
  userList = [],
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgUserListBase, cssClass);

  return (
    <ul className={classNames}>
      {userList.map((user, i) => (
        <OrgUserListUnitMemberTertiary
          user={user}
          key={`OrgUserListUnitMemberTertiary${i.toString()}`}
          onClick={() => onClick(user?.name_id || '')}
        />
      ))}
    </ul>
  );
};

export default OrgUserList;
