import React, { FC } from 'react';
import ClassNames from 'classnames';
import TitleH2 from '../../atoms/title/TitleH2';
import ButtonSquare from '../../atoms/buttonBasic/ButtonSquare';
import McsInputPrimary from '../../molecules/formParts/McsInputPrimary';

import * as styles from './OrgSignUpRegister.module.scss';
import { CheckDuplicateEmailValidationErrors } from '../../../../interfaces/authInterFace';

interface Props {
  cssClass?: string;
  valueEmail?: string;
  onTwitterRegisterEvent?: () => void;
  onRegisterEvent?: () => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;

  errors?: CheckDuplicateEmailValidationErrors;
}

const OrgSignUpLogin: FC<Props> = ({
  cssClass = '',
  valueEmail = '',
  onTwitterRegisterEvent = () => undefined,
  onRegisterEvent = () => undefined,
  onChange = () => undefined,

  errors = {},
}) => {
  const classNames = ClassNames(styles.OrgSingUpRegisterBase, cssClass);

  return (
    <div className={classNames}>
      <div className={styles.OrgSingUpRegisterTitle}>
        <TitleH2 text="KRAPをはじめよう" />
        <p className={styles.OrgSingUpRegisterTitleSub}>アカウント作成</p>
      </div>
      <McsInputPrimary
        value={valueEmail}
        inputName="email"
        placeholder="メールアドレス"
        cssClass={styles.OrgSingUpRegisterInput}
        onChange={onChange}
        isError={!!errors.email}
        errorText={errors.email}
      />
      <ButtonSquare
        text="アカウントを作成"
        type="Primary"
        size="Secondary"
        cssClass={styles.OrgSingUpRegisterInput}
        onClick={onRegisterEvent}
      />
      <ButtonSquare
        text="Twitterで新規登録"
        type="Twitter"
        size="Secondary"
        cssClass={styles.OrgSingUpRegisterTwitterButton}
        onClick={onTwitterRegisterEvent}
      />
      <span className={styles.OrgSingUpRegisterNote}>
        ※承認なくTwitterへポストすることはありません
      </span>
    </div>
  );
};

export default OrgSignUpLogin;
