/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import TitleDiv from '../../atoms/title/TitleDiv';

import OrgFCListUnitSecondary from '../listUnitParts/OrgFCListUnitSecondary';

import { FanclubWithUserInfo } from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgFindWordUnitFCList.module.scss';

type Props = {
  wordTitle?: string;
  fanclubList?: Partial<FanclubWithUserInfo>[];
  onClickFanclub?: (artistId: string) => void;
  onClickFollow?: (artistId: string, id: number) => void;
  cssClass?: string;
};

const OrgFCList: FC<Props> = ({
  fanclubList = [],
  onClickFanclub = () => undefined,
  onClickFollow = () => undefined,
  wordTitle = '?',
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListBase, cssClass);

  return (
    <div className={classNames}>
      <TitleDiv text={wordTitle} cssClass={styles.OrgFCListTitle} />
      <ul className={styles.OrgFCListWrap}>
        {fanclubList.map((item, i) => (
          <OrgFCListUnitSecondary
            fanclub={item}
            key={`OrgFCListUnitTertiary${i.toString()}`}
            isViewButton={!item.managed}
            buttonType={item.followed ? 'Primary' : 'Secondary'}
            buttonText={
              item.paid ? '入会中' : item.followed ? 'フォロー中' : 'フォロー'
            }
            onClickFanclub={() => onClickFanclub(item.group_id ?? '')}
            onClickButton={() =>
              onClickFollow(item.group_id ?? '', item.id ?? 0)
            }
          />
        ))}
      </ul>
    </div>
  );
};

export default OrgFCList;
