/* eslint-disable import/no-cycle */
import { Status, ErrorResponse, BoolNumber } from './commonInterFace';
import { InputFile } from './utilInterFace';
import { Fanclub } from './fanclubInterFace';
import { Genre } from './genreInterFace';

/**
 * 0: 男性
 * 1: 女性
 */
export type Gender = 0 | 1;

/**
 * 0: 無料FC会員
 * 1: 有料FC会員
 * 2: メンバー
 * 3: 管理者
 * 9: ブロック
 * 99: KRAP会員（非FC会員）
 * null: 非ログイン
 */
export type UserType = 0 | 1 | 2 | 3 | 9 | 99 | null;

/**
 * follow: フォローアーティスト一覧
 * like: いいねコンテンツ一覧
 */
export type UserTabMode = 'follow' | 'like';

export type User = {
  id: number;
  email: string;
  twitter_id: string | null;
  name_id: string;
  nickname: string;
  prefecture: number | null;
  gender: Gender | null;
  icon_image: string | null;
  cover_image: string | null;
  birthday: string | null;
  self_introduction: string | null;
  send_flag: boolean;
  stripe_id: string | null;
  card_brand: string | null;
  card_last_four: string | null;
  trial_ends_at: string | null; // timestamp
  email_verified_at: string | null;
  pivot?: {
    fanclub_id?: number;
    user_id?: number;
    type?: UserType;
  };
};
export const UserInitialValue: User = {
  id: 0,
  email: '',
  twitter_id: null,
  name_id: '',
  nickname: '',
  prefecture: null,
  gender: null,
  icon_image: null,
  cover_image: null,
  birthday: null,
  self_introduction: null,
  send_flag: false,
  stripe_id: null,
  card_brand: null,
  card_last_four: null,
  trial_ends_at: null,
  email_verified_at: null,
};

export type UserForSearch = User & {
  created_at: string | null; // '2020-05-28 18:03:54';
  updated_at: string | null; // '2020-05-28 18:03:54';
  deleted_at: string | null;
  existence: BoolNumber;
  fanclubs: Fanclub[];
};

export const UserForSearchInitialValue: UserForSearch = {
  ...UserInitialValue,
  created_at: null,
  deleted_at: null,
  updated_at: null,
  existence: 1,
  fanclubs: [],
};

export type SimpleUser = {
  name_id: string;
  nickname: string;
  icon_image: string | null;
};
export const SimpleUserInitialValue: SimpleUser = {
  name_id: '',
  nickname: '',
  icon_image: null,
};

export type UserWithType = User & {
  type: UserType;
};
export const UserWithTypeInitialValue: UserWithType = {
  ...UserInitialValue,
  type: null,
};

export type UserProfile = {
  user: User;
  genres: Genre[];
  fanclubs: {
    manages: Fanclub[];
    follows: Fanclub[];
  };
  like_count: number;
};
export const UserProfileInitialValue: UserProfile = {
  user: UserInitialValue,
  genres: [],
  fanclubs: {
    manages: [],
    follows: [],
  },
  like_count: 0,
};

export type GetUserProfileParams = {
  userId: string;
};
export type GetUserProfileResult = UserProfile;
export type GetUserProfileResponse = {
  status_code: Status.OK;
  result: GetUserProfileResult;
};

export type UpdateUserProfileParams = {
  nickname: string;
  name_id: string;
  icon_image?: File;
  cover_image?: File;
  birthday?: string; // YYYY-MM-DD
  prefecture: number;
  self_introduction: string;
  genres: number[];
};
export type UpdateUserProfileInputParams = Required<
  Omit<
    UpdateUserProfileParams,
    'name_id' | 'cover_image' | 'icon_image' | 'birthday'
  >
> & {
  cover_image: InputFile;
  icon_image: InputFile;
  birthday_year: number; // timestamp
  birthday_month: number; // timestamp
  birthday_date: number; // timestamp
};
export const UpdateUserProfileInputParamsInitialValue: UpdateUserProfileInputParams = {
  cover_image: {
    file: null,
    preview: '',
  },
  icon_image: {
    file: null,
    preview: '',
  },
  nickname: '',
  prefecture: 0,
  self_introduction: '',
  genres: [],
  birthday_year: 0,
  birthday_month: 0,
  birthday_date: 0,
};
export type UpdateUserProfileErrors = {
  [key in keyof Partial<UpdateUserProfileParams>]: string[];
};
export type UpdateUserProfileValidationErrors = Record<
  keyof UpdateUserProfileParams,
  string
>;
export const UpdateUserProfileValidationErrorsInitialValue: UpdateUserProfileValidationErrors = {
  cover_image: '',
  icon_image: '',
  nickname: '',
  name_id: '',
  prefecture: '',
  self_introduction: '',
  genres: '',
  birthday: '',
};
export type UpdateUserProfileResult = UserProfile;
export type UpdateUserProfileResponse = {
  status_code: Status.OK;
  result: UpdateUserProfileResult;
};
export type UpdateUserProfileErrorResponse = ErrorResponse<
  keyof UpdateUserProfileParams
>;
