/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/adminMembersConstants';
import { checkAndSetStatusError } from './authSagas';
import { getUserProfileEvent as updateMe } from '../actions/authActions';
import {
  getReady as getReadyStart,
  gotReady,
  getFanclubDetailsEvent,
  addFCMembersEvent,
  withdrawFanclubEvent,
  deleteFCMembersEvent,
  changeFCMembersAuthorityEvent,
  searchUserListEvent,
} from '../actions/adminMembersActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import {
  AddFanclubMembersResponse,
  AddFanclubMembersErrorResponse,
  DeleteFanclubMembersResponse,
  DeleteFanclubMembersErrorResponse,
  ChangeFanclubMembersAuthorityResponse,
  ChangeFanclubMembersAuthorityErrorResponse,
} from '../interfaces/memberInterFace';
import {
  GetFanclubDetailsResponse,
  WithdrawFanclubResponse,
} from '../interfaces/fanclubInterFace';
import { SearchUserListResponse } from '../interfaces/searchInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId } = action.payload;

  const getFCBlockListTask = yield fork(
    runGetFanclubDetails,
    getFanclubDetailsHandler,
    getFanclubDetailsEvent.start({ artistId }),
  );
  yield join(getFCBlockListTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get fanclub details
const getFanclubDetailsHandler = api.getGetFactory(true);
function* runGetFanclubDetails(
  handler: typeof getFanclubDetailsHandler,
  action: ReturnType<typeof getFanclubDetailsEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetFanclubDetailsResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(getFanclubDetailsEvent.fail());

      return;
    }

    yield put(getFanclubDetailsEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFanclubDetailsEvent.fail());
  }
}
export function* getFanclubDetails(handler: typeof getFanclubDetailsHandler) {
  yield takeLatest(
    ActionType.GET_FANCLUB_DETAILS_START,
    runGetFanclubDetails,
    handler,
  );
}

// add fanclub members
const addFCMembersHandler = api.getPostFactory(true);
function* runAddFCMembers(
  handler: typeof addFCMembersHandler,
  action: ReturnType<typeof addFCMembersEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: AddFanclubMembersResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/member`,
    );

    if (!ReturnData) {
      yield put(addFCMembersEvent.fail());

      return;
    }

    yield put(getFanclubDetailsEvent.start({ artistId }));

    yield put(addFCMembersEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as AddFanclubMembersErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(addFCMembersEvent.fail());
  }
}
export function* addFCMembers(handler: typeof addFCMembersHandler) {
  yield takeLeading(ActionType.ADD_FC_MEMBERS_START, runAddFCMembers, handler);
}

// withdraw fanclub
const withdrawFanclubHandler = api.getPostFactory(true);
function* runWithdrawFanclub(
  handler: typeof withdrawFanclubHandler,
  action: ReturnType<typeof withdrawFanclubEvent.start>,
) {
  const { artistId } = action.payload;
  try {
    const ReturnData: WithdrawFanclubResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/withdraw`,
    );

    if (!ReturnData) {
      yield put(withdrawFanclubEvent.fail());

      return;
    }

    // update user profile
    yield put(updateMe.start());

    yield put(withdrawFanclubEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(withdrawFanclubEvent.fail());
  }
}
export function* withdrawFanclub(handler: typeof withdrawFanclubHandler) {
  yield takeLeading(
    ActionType.WITHDRAW_FANCLUB_START,
    runWithdrawFanclub,
    handler,
  );
}

// delete fc members
const deleteFCMembersHandler = api.getDeleteFactory(true);
function* runDeleteFCMembers(
  handler: typeof deleteFCMembersHandler,
  action: ReturnType<typeof deleteFCMembersEvent.start>,
) {
  const { artistId, ...data } = action.payload;

  try {
    const ReturnData: DeleteFanclubMembersResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/member`,
    );

    if (!ReturnData) {
      yield put(deleteFCMembersEvent.fail());

      return;
    }

    yield put(updateMe.start());
    yield put(getFanclubDetailsEvent.start({ artistId }));

    yield put(deleteFCMembersEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as DeleteFanclubMembersErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(deleteFCMembersEvent.fail());
  }
}
export function* deleteFCMembers(handler: typeof deleteFCMembersHandler) {
  yield takeLeading(
    ActionType.DELETE_FC_MEMBERS_START,
    runDeleteFCMembers,
    handler,
  );
}

// change fc member's authority
const changeFCMembersAuthorityHandler = api.getPostFactory(true);
function* runChangeFCMembersAuthority(
  handler: typeof changeFCMembersAuthorityHandler,
  action: ReturnType<typeof changeFCMembersAuthorityEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: ChangeFanclubMembersAuthorityResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/change-admin`,
    );

    if (!ReturnData) {
      yield put(changeFCMembersAuthorityEvent.fail());

      return;
    }

    yield put(updateMe.start());
    yield put(getFanclubDetailsEvent.start({ artistId }));

    yield put(changeFCMembersAuthorityEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as DeleteFanclubMembersErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(changeFCMembersAuthorityEvent.fail());
  }
}
export function* changeFCMembersAuthority(
  handler: typeof changeFCMembersAuthorityHandler,
) {
  yield takeLeading(
    ActionType.CHANGE_FC_MEMBERS_AUTHORITY_START,
    runChangeFCMembersAuthority,
    handler,
  );
}

// search user list
const searchUserListHandler = api.getGetFactory(true);
function* runSearchUserList(
  handler: typeof searchUserListHandler,
  action: ReturnType<typeof searchUserListEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: SearchUserListResponse = yield call(
      handler,
      data,
      '/search/user',
    );

    if (!ReturnData) {
      yield put(searchUserListEvent.fail());

      return;
    }

    yield put(searchUserListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response
      .data as ChangeFanclubMembersAuthorityErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(searchUserListEvent.fail());
  }
}
export function* searchUserList(handler: typeof searchUserListHandler) {
  yield takeLatest(
    ActionType.SEARCH_USER_LIST_START,
    runSearchUserList,
    handler,
  );
}

export const adminMembersSagas = [
  fork(getReady),
  fork(getFanclubDetails, getFanclubDetailsHandler),
  fork(addFCMembers, addFCMembersHandler),
  fork(withdrawFanclub, withdrawFanclubHandler),
  fork(deleteFCMembers, deleteFCMembersHandler),
  fork(changeFCMembersAuthority, changeFCMembersAuthorityHandler),
  fork(searchUserList, searchUserListHandler),
];
