/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './IconArrow.module.scss';

interface Props {
  type?: string;
  cssClass?: string;
  onClick?: () => void;
}

const Icon: FC<Props> = ({
  type = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.IconBase, cssClass, {
    [styles.IconPrimary]: type === 'Primary', // Primary
    [styles.IconSecondary]: type === 'Secondary', // Secondary
    [styles.IconTertiary]: type === 'Tertiary', // Tertiary
    [styles.IconQuaternary]: type === 'Quaternary', // Quaternary
    [styles.IconQuinary]: type === 'Quinary', // Quaternary
  });

  return (
    <div className={classNames} onClick={onClick}>
      <span />
    </div>
  );
};

export default Icon;
