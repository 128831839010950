import * as ActionType from './likesConstants';
import { GetContentDetailsResult } from '../interfaces/contentInterFace';
import {
  getLikedUsersResult,
  getLikedUsersParams,
} from '../interfaces/likesInterFace';

export const getReady = (contentId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: contentId,
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getContentDetailsEvent = {
  start: (params: string) =>
    ({
      type: ActionType.GET_CONTENT_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetContentDetailsResult) =>
    ({
      type: ActionType.GET_CONTENT_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_CONTENT_DETAILS_FAIL,
    } as const),
};

export const getLikedUsers = {
  start: (params: getLikedUsersParams) =>
    ({
      type: ActionType.GET_LIKED_USERS_START,
      payload: params,
    } as const),

  succeed: (result: getLikedUsersResult) =>
    ({
      type: ActionType.GET_LIKED_USERS_SUCCEED,
      payload: result,
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_LIKED_USERS_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get content details
  | ReturnType<typeof getContentDetailsEvent.start>
  | ReturnType<typeof getContentDetailsEvent.succeed>
  | ReturnType<typeof getContentDetailsEvent.fail>
  // いいねしたユーザー取得
  | ReturnType<typeof getLikedUsers.start>
  | ReturnType<typeof getLikedUsers.succeed>
  | ReturnType<typeof getLikedUsers.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
