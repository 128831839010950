/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useEffect, useRef, useCallback } from 'react';
import ClassNames from 'classnames';
import * as styles from './MessageDisplay.module.scss';

interface Props {
  message?: string;
  cssClass?: string;
  top?: boolean;
}

const MessageDisplay: FC<Props> = ({
  message = 'メッセージ',
  cssClass = '',
  top = false,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  // 要素を画面上部に表示する関数
  const displayTop = useCallback(() => {
    if (!ref.current) return;
    const position = ref.current.offsetTop;
    window.scroll(0, position - 60);
  }, [ref]);
  useEffect(() => {
    if (top) {
      displayTop();
    }
  }, [top, displayTop]);
  const classNames = ClassNames(styles.messageBase, cssClass);

  return (
    <div className={classNames} ref={ref}>
      <p>{message}</p>
    </div>
  );
};

export default MessageDisplay;
