import * as ActionType from './adminLikesConstants';
import {
  GetLikeListParams,
  GetLikeListResult,
} from '../interfaces/contentInterFace';

export const getReady = (artistId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getLikeListEvent = {
  start: (params: GetLikeListParams) =>
    ({
      type: ActionType.GET_LIKE_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetLikeListResult) =>
    ({
      type: ActionType.GET_LIKE_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_LIKE_LIST_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get like list
  | ReturnType<typeof getLikeListEvent.start>
  | ReturnType<typeof getLikeListEvent.succeed>
  | ReturnType<typeof getLikeListEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
