import React, { FC } from 'react';
import ClassNames from 'classnames';
import JasracLogo from '../../atoms/iconParts/IconJasracLogo';
import * as styles from './McsJasrac.module.scss';

interface Props {
  cssClass?: string;
}

const McsFooterJasrac: FC<Props> = ({ cssClass = '' }) => {
  const classNames = ClassNames(styles.McsJasracBase, cssClass);

  return (
    <div className={classNames}>
      <JasracLogo cssClass={styles.McsJasracLogo} />
      <p className={styles.McsJasracRead}>
        JARAC許諾第6625519271Y45040号
        <br />
        JARAC許諾第6625519270Y45037号
      </p>
    </div>
  );
};

export default McsFooterJasrac;
