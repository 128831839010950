import { Reducer } from 'redux';

import { Actions } from '../actions/adminBlocksActions';
import * as ActionType from '../actions/adminBlocksConstants';

import { User } from '../interfaces/userInterFace';
import { BlockFollowerErrors } from '../interfaces/followerInterFace';

type State = {
  blocks: User[];
  ready: boolean;
  loading: boolean;
  success: boolean;
  errors: BlockFollowerErrors;
};

export const initialState: State = {
  blocks: [],
  ready: false,
  loading: false,
  success: false,
  errors: {},
};

const adminFollowersReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fc block list
    case ActionType.GET_FC_BLOCK_LIST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FC_BLOCK_LIST_SUCCEED: {
      const { data: blocks } = action.payload.result.blocks;

      return {
        ...state,
        blocks,
        loading: false,
      };
    }
    case ActionType.GET_FC_BLOCK_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // unblock follower
    case ActionType.UNBLOCK_USER_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.UNBLOCK_USER_SUCCEED: {
      return {
        ...state,
        blocks: state.blocks.filter(
          user => user.name_id !== action.payload.params.unblock_user,
        ),
        loading: false,
      };
    }
    case ActionType.UNBLOCK_USER_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default adminFollowersReducer;
