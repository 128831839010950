/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import * as styles from './Badge.module.scss';

interface Props {
  count?: string | number;
  type?: 'Primary';
  onClick?: () => void;
  cssClass?: string;
}

const Button: FC<Props> = ({
  count = 0,
  type = 'Primary',
  onClick = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.base, cssClass, {
    [styles.Primary]: type === 'Primary', // Primary
  });

  return (
    <div className={classNames} onClick={onClick}>
      <p>{count}</p>
    </div>
  );
};

export default Button;
