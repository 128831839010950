import React, { FC } from 'react';
import ClassNames from 'classnames';

import { User } from '../../interfaces/userInterFace';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';
import TitleH1 from '../common/atoms/title/TitleH1';
import McsInputSecondary from '../common/molecules/formParts/McsInputSecondary';
import OrgInviteMemberList from '../common/organisms/listParts/OrgInviteMemberList';
import OrgInviteMemberListSub from '../common/organisms/listParts/OrgInviteMemberListSub';

import * as styles from './complete.module.scss';

interface Props {
  userName?: string;
  fanclubName?: string;
  userList?: User[];
  memberList?: User[];
  text?: string;
  handleChangeText?: (
    e: React.FormEvent<HTMLInputElement>,
    key?: string,
  ) => void;
  handleChangeMembers?: (userId: string) => void;
  handleAddFanclubMembers?: () => void;
  handleSkip?: () => void;
  error?: string;
}

const Component: FC<Props> = ({
  userName = '',
  fanclubName = '',
  userList = [],
  memberList = [],
  text = '',
  handleChangeText = () => undefined,
  handleChangeMembers = () => undefined,
  handleAddFanclubMembers = () => undefined,
  handleSkip = () => undefined,
  error = '',
}) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      <TitleH1 text="メンバー追加" cssClass={styles.title} />

      <div className={styles.read}>
        <p>{userName}様</p>
        <p>「{fanclubName}」の開設、おめでとうございます！</p>
        <p>
          まずは、コミュニティを運営するメンバーやスタッフを招待しましょう！
        </p>
      </div>

      <div>
        <McsInputSecondary
          value={text}
          inputType="text"
          inputName="artist_name"
          placeholder="ニックネーム・ID・メールアドレスを入力"
          onChange={handleChangeText}
          isError={!!error}
          errorText={error}
        />
      </div>

      <div className={styles.contentWrap}>
        <OrgInviteMemberList
          userList={userList}
          onInviteClick={handleChangeMembers}
        />
      </div>
      <div className={styles.contentWrap}>
        <OrgInviteMemberListSub
          userList={memberList}
          onInviteClick={handleChangeMembers}
        />
      </div>

      <div className={styles.buttonWrap}>
        <ButtonRoundedCorners
          text="メンバーを招待"
          type={memberList.length === 0 ? 'Quaternary' : 'Primary'}
          size="Primary"
          onClick={handleAddFanclubMembers}
          cssClass={styles.button}
        />
        <ButtonRoundedCorners
          text="スキップ"
          type="Quinary"
          size="Primary"
          onClick={handleSkip}
          cssClass={styles.cancel}
        />
      </div>
    </div>
  );
};

export default Component;
