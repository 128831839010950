/* eslint-disable yoda */

import moment from 'moment';

export const isEmpty = (text: string) => {
  return text === '';
};

/**
 * 下記、Sakuraさんのルールに準拠
 * https://help.sakura.ad.jp/206108842/
 */
export const isEmail = (text: string) => {
  const regex = /^[0-9a-zA-Z_.-]+@([0-9a-zA-Z-]+\.)+[a-zA-Z]+$/;
  const found = text.match(regex);

  return !!found;
};
export const isPassword = (text: string) => {
  const regex = /^[0-9a-zA-Z!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+$/;
  const found = text.match(regex);

  return !!found;
};
export const isNumber = (text: string | number) => {
  const regex = /^[1-9][0-9]*$/;
  const found = text.toString().match(regex);

  return !!found;
};
export const isAlphanumeric = (text: string) => {
  const regex = /^[0-9a-zA-Z]+$/;
  const found = text.match(regex);

  return !!found;
};
export const isAlphanumericAndUnderScore = (text: string) => {
  const regex = /^[0-9a-zA-Z_]+$/;
  const found = text.match(regex);

  return !!found;
};
export const isHiragana = (text: string) => {
  const regex = /^[ぁ-んー]+$/;
  const found = text.match(regex);

  return !!found;
};
export const isDateTime = (text: string) => {
  return moment(text).isValid();
};
export const isUrl = (text: string) => {
  const regex = /^https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+\\$,%#]+$/;
  const found = text.match(regex);

  return !!found;
};
export const isJPBankKigou = (text: string) => {
  const regex = /^((0|1)[0-9]{0,4})?$/;
  const found = text.match(regex);

  return !!found;
};

export const isOtherBankAccountHolder = (text: string) => {
  // eslint-disable-next-line no-irregular-whitespace
  const regex = /^[アイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲンガギグゲゴザジズゼゾダヂヅデドバビブベボヴパピプペポ０１２３４５６７８９ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺ（）．ー／　「」]+$/;

  const found = text.match(regex);

  return !!found;
};

export const isJPBankAccountHolder = (text: string) => {
  // eslint-disable-next-line no-irregular-whitespace
  const regex = /^[アイウエオカキクケコサシスセソタチツテトナニヌネノハヒフヘホマミムメモヤユヨラリルレロワヲンガギグゲゴザジズゼゾダヂヅデドバビブベボヴパピプペポ０１２３４５６７８９ＡＢＣＤＥＦＧＨＩＪＫＬＭＮＯＰＱＲＳＴＵＶＷＸＹＺ（）．ー／　「」，]+$/;

  const found = text.match(regex);

  return !!found;
};

/**
 * validator
 */

export const validateEmail = (email: string | undefined) => {
  if (email === undefined || email.length === 0) return '';

  if (!isEmail(email)) return 'メールアドレスに誤りがあります';

  return '';
};

export const validatePassword = (password: string) => {
  if (password.length === 0) return '';

  if (!isPassword(password))
    return 'パスワードに使用できない文字が含まれています';

  if (0 < password.length && password.length < 8)
    return 'パスワードは8文字以上です';

  return '';
};

export const validateNickName = (nickName: string) => {
  if (nickName.length === 0) return '';

  if (nickName.trim().length > 20) return 'ニックネームは20文字以内です';

  return '';
};

export const validateId = (id: string | number) => {
  if (id.toString().length === 0) return '';

  if (!isNumber(id)) return 'IDは半角数字です';

  return '';
};

export const validateUserId = (userId: string) => {
  if (userId.length === 0) return '';

  if (!isAlphanumericAndUnderScore(userId))
    return 'IDは半角英数とアンダースコア(_)の20文字以内です';

  if (userId.length > 20)
    return 'IDは半角英数とアンダースコア(_)の20文字以内です';

  return '';
};

export const validateRuby = (nameKana: string) => {
  if (nameKana.length === 0) return '';

  if (!isHiragana(nameKana))
    return '読み仮名に使用できない文字が含まれています';

  return '';
};

export const validateConfirmPassword = (password: string, confirm: string) => {
  if (confirm.length === 0) return '';

  if (password !== confirm) return 'パスワードが異なります';

  return '';
};

export const validateArtistId = (artistId: string) => {
  if (artistId.length === 0) return '';

  if (!isAlphanumericAndUnderScore(artistId))
    return 'グループIDは半角英数とアンダースコア(_)の20文字以内です';

  if (artistId.length > 20)
    return 'グループIDは半角英数とアンダースコア(_)の20文字以内です';

  return '';
};

export const validateArtistName = (name: string) => {
  if (name.length === 0) return '';

  return '';
};

export const validateArtistNameKana = (kana: string) => {
  if (kana.length === 0) return '';

  if (!isHiragana(kana)) return '読み仮名に使用できない文字が含まれています';

  return '';
};

export const validateCommunityName = (name: string) => {
  if (name.length === 0) return '';

  return '';
};

export const validateUrl = (url: string) => {
  if (url.length === 0) return '';

  if (!isUrl(url)) return 'URLを入力してください';

  return '';
};

export const validateDateTime = (dateTime: string) => {
  if (dateTime.trim() === '') return '';

  if (!isDateTime(dateTime)) return '不正な日時が含まれています';

  return '';
};

export const validateJASRAC = (text: string) => {
  if (isEmpty(text)) return '入力が必要です';

  return '';
};

export const validateBankKigou = (kigou: string) => {
  if (isEmpty(kigou)) return '';

  if (!isJPBankKigou(kigou)) return '不正な記号です';

  return '';
};

export const validateBankHolder = (holder: string, type: 'other' | 'jp') => {
  if (isEmpty(holder)) return '';

  if (type === 'other' && !isOtherBankAccountHolder(holder)) {
    return '使用できない文字列が含まれています';
  }

  if (type === 'jp' && !isJPBankAccountHolder(holder)) {
    return '使用できない文字列が含まれています';
  }

  return '';
};
