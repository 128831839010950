/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import ChatContent from '../../atoms/commonParts/ChatContent';
import Icon from '../../atoms/iconThumbnail/IconThumbnailRound';
import IconWithBorder from '../../atoms/iconThumbnail/IconThumbnailRoundWithBorder';
import TitleH4 from '../../atoms/title/TitleH4';
import IconShape from '../../atoms/iconParts/IconShape';
import * as styles from './MscChatListParts.module.scss';

interface Props {
  content?: string;
  type?: string;
  cssClass?: string;
  src?: string;
  withIcon?: boolean;
  name?: string;
  time?: string;
  tTimeDisplay?: boolean;
  member?: boolean;
  admin?: boolean;
  onClickDelete?: () => void;
  onClickUserIcon?: () => void;
}

const MscChatListParts: FC<Props> = ({
  content = '',
  type = 'other',
  cssClass = '',
  src = '',
  withIcon = true,
  name = '',
  time = '',
  tTimeDisplay = true,
  member = false,
  admin = false,
  onClickDelete = () => undefined,
  onClickUserIcon = () => undefined,
}) => {
  const classNames = ClassNames(styles.MscChatWrap, cssClass);

  const contentState = ClassNames(styles.contentSpace, {
    [styles.OthersContent]: type === 'other', // 自分以外の発言
    [styles.MyselfContent]: type === 'myself', // 自分の発言
  });

  return (
    <div className={classNames}>
      {withIcon && type === 'other' ? (
        <>
          <div className={styles.iconSpace}>
            {member ? (
              <IconWithBorder
                src={src}
                size="Quaternary"
                color="grade"
                onClick={onClickUserIcon}
              />
            ) : (
              <Icon src={src} size="Quaternary" onClick={onClickUserIcon} />
            )}
          </div>
          <div className={styles.OthersContentWithIcon}>
            <TitleH4 text={name} type="Primary" />
            <div>
              <ChatContent
                content={content}
                type={type}
                cssClass={styles.chatContent}
              />
              {admin && (
                <IconShape
                  type="Delete"
                  cssClass={styles.DeleteIcon}
                  onClick={onClickDelete}
                />
              )}
            </div>
            {tTimeDisplay && <p className={styles.time}>{time}</p>}
          </div>
        </>
      ) : (
        <>
          <div className={styles.iconSpace} />
          <div className={contentState}>
            <div>
              <ChatContent
                content={content}
                type={type}
                cssClass={styles.chatContent}
              />
              {(type === 'myself' || admin) && (
                <IconShape
                  type="Delete"
                  cssClass={styles.DeleteIcon}
                  onClick={onClickDelete}
                />
              )}
            </div>
            {tTimeDisplay && <p className={styles.time}>{time}</p>}
          </div>
        </>
      )}
    </div>
  );
};

export default MscChatListParts;
