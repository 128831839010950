import { Reducer } from 'redux';

import { Actions } from '../actions/adminSalesActions';
import * as ActionType from '../actions/adminSalesConstants';

import { AmountList } from '../interfaces/salesInterFace';

type State = {
  this_month_expected_sale_amount: number;
  salesForecastTitle: string;
  description: string;
  sales: AmountList[];
  total_sale_amount: number;
  total_transfer_amount: number;
  this_month_expected_transfer_amount: number;
  transferForecastTitle: string;
  transferWaiting: number;
  transferDescription: string;
  transfers: AmountList[];
  ready: boolean;
};

export const initialState: State = {
  this_month_expected_sale_amount: 0,
  salesForecastTitle: '',
  description: '',
  sales: [],
  total_sale_amount: 0,
  total_transfer_amount: 0,
  this_month_expected_transfer_amount: 0,
  transferForecastTitle: '',
  transferDescription: '',
  transferWaiting: 0,
  transfers: [],
  ready: false,
};

const adminSalesReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        ready: true,
      };
    }

    // get fc sales info
    case ActionType.GET_FC_SALES_INFO_START: {
      return {
        ...state,
      };
    }
    case ActionType.GET_FC_SALES_INFO_SUCCEED: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case ActionType.GET_FC_SALES_INFO_FAIL: {
      return {
        ...state,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP: {
      return {
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default adminSalesReducer;
