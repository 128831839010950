import * as ActionType from './adminMenuConstants';
import {
  GetFanclubDetailsParams,
  GetFanclubDetailsResult,
} from '../interfaces/fanclubInterFace';

export const getReady = (artistId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getFanclubDetailsEvent = {
  start: (params: GetFanclubDetailsParams) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetFanclubDetailsResult) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_FAIL,
    } as const),
};

export const getUnreadChatEvent = {
  start: (artistId: string) =>
    ({
      type: ActionType.GET_UNREAD_CHAT_START,
      payload: artistId,
    } as const),

  succeed: (unreadChatCount: number) =>
    ({
      type: ActionType.GET_UNREAD_CHAT_SUCCEED,
      payload: unreadChatCount,
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_UNREAD_CHAT_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fanclub details
  | ReturnType<typeof getFanclubDetailsEvent.start>
  | ReturnType<typeof getFanclubDetailsEvent.succeed>
  | ReturnType<typeof getFanclubDetailsEvent.fail>
  // チャットの未読件数取得
  | ReturnType<typeof getUnreadChatEvent.start>
  | ReturnType<typeof getUnreadChatEvent.succeed>
  | ReturnType<typeof getUnreadChatEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
