import React, { FC } from 'react';
import ClassNames from 'classnames';

import { LoginValidationErrors } from '../../../../interfaces/authInterFace';

import TitleH2 from '../../atoms/title/TitleH2';
import ButtonSquare from '../../atoms/buttonBasic/ButtonSquare';
import LinkText from '../../atoms/link/LinkText';

import McsInputPrimary from '../../molecules/formParts/McsInputPrimary';
import McsButtonWithCheck from '../../molecules/formParts/McsButtonWithCheck';

import * as styles from './OrgSignUpLogin.module.scss';

interface Props {
  valueID?: string;
  valuePass?: string;
  onClickForgetPassword?: () => void;
  onTwitterLoginEvent?: () => void;
  onLoginEvent?: () => void;
  onSaveCheckEvent?: () => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  isCheck?: boolean;
  errors?: LoginValidationErrors;
  cssClass?: string;
}

const OrgSignUpLogin: FC<Props> = ({
  valueID = '',
  valuePass = '',
  onClickForgetPassword = () => undefined,
  onTwitterLoginEvent = () => undefined,
  onLoginEvent = () => undefined,
  onSaveCheckEvent = () => undefined,
  onChange = () => undefined,
  isCheck = false,
  errors = {
    email: '',
    password: '',
  },
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgSingUpLoginBase, cssClass);

  return (
    <div className={classNames}>
      <TitleH2 text="KRAPにログイン" cssClass={styles.OrgSingUpLoginTitle} />
      <McsInputPrimary
        value={valueID}
        inputName="id"
        inputType="email"
        placeholder="メールアドレス"
        cssClass={styles.OrgSingUpLoginInput}
        onChange={onChange}
        isError={!!errors.email}
        errorText={errors.email}
      />
      <McsInputPrimary
        value={valuePass}
        inputName="pass"
        inputType="password"
        placeholder="パスワード"
        cssClass={styles.OrgSingUpLoginInput}
        onChange={onChange}
        isError={!!errors.password}
        errorText={errors.password}
      />
      <LinkText
        text="パスワードを忘れた場合はこちら"
        cssClass={styles.OrgSingUpLoginLink}
        onClick={onClickForgetPassword}
      />
      <McsButtonWithCheck
        buttonText="ログイン"
        checkBoxLabel="保存する"
        cssClass={styles.OrgSingUpLoginButton}
        onClick={onLoginEvent}
        onChange={onSaveCheckEvent}
        checked={isCheck}
      />
      <ButtonSquare
        text="Twitterでログイン"
        type="Twitter"
        size="Secondary"
        cssClass={styles.OrgSingUpLoginTwitterButton}
        onClick={onTwitterLoginEvent}
      />
    </div>
  );
};

export default OrgSignUpLogin;
