/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import LabelTag from '../../atoms/label/LabelTag';

import * as styles from './McsTitleAndLabelButtonListUnit.module.scss';

interface Props {
  title?: string;
  buttonText?: string;
  onClick?: () => void;
  cssClass?: string;
}

const OrgMemberList: FC<Props> = ({
  title = '',
  buttonText = '',
  onClick = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(
    styles.McsTitleAndLabelButtonListUnitBase,
    cssClass,
  );

  return (
    <li className={classNames}>
      <span className={styles.McsTitleAndLabelButtonListUnitTitle}>
        {title}
      </span>
      <LabelTag type="Quinary" text={buttonText} onClick={onClick} />
    </li>
  );
};

export default OrgMemberList;
