import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './CheckBox.module.scss';

interface Props {
  value?: string;
  type?: string;
  labelText?: string;
  inputName?: string | undefined;
  cssClass?: string;
  onChange?: () => void;
  checked?: boolean;
  isDisabled?: boolean;
}

const CheckBox: FC<Props> = ({
  value = '',
  type = 'Primary',
  labelText = 'Label',
  inputName = undefined,
  cssClass = '',
  onChange = () => undefined,
  checked = false,
  isDisabled = false,
}) => {
  const classNames = ClassNames(styles.CheckBoxBase, cssClass, {
    [styles.CheckBoxPrimary]: type === 'Primary', // Primary
  });

  return (
    <div className={classNames}>
      <label htmlFor={inputName}>
        <input
          onChange={onChange}
          type="checkbox"
          name={inputName}
          value={value}
          checked={checked}
          disabled={isDisabled}
        />
        <span>{labelText}</span>
      </label>
    </div>
  );
};

export default CheckBox;
