import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './ButtonNav.module.scss';

interface Props {
  type: string;
  cssClass?: string;
  onClick?: () => void;
}

const Button: FC<Props> = ({
  type = 'Open',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.ButtonBase, cssClass, {
    [styles.ButtonOpen]: type === 'Open', // Open
    [styles.ButtonClose]: type === 'Close', // Close
  });

  return (
    <div className={classNames}>
      <button type="button" onClick={onClick}>
        <span />
        <span />
        <span />
      </button>
    </div>
  );
};

export default Button;
