/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './IconKrapLogo.module.scss';

interface Props {
  type?: string;
  cssClass?: string;
  onClick?: () => void;
}

const Icon: FC<Props> = ({
  type = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.IconBase, cssClass, {
    [styles.IconPrimary]: type === 'Primary', // Primary
  });

  return (
    <h1 className={classNames}>
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          onClick();
        }}
      >
        KRAP
      </a>
    </h1>
  );
};

export default Icon;
