/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, takeLeading } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/signUpConstants';
import { setAccessToken } from '../actions/authActions';
import {
  checkDuplicateEmailEvent,
  signupEvent,
} from '../actions/signUpActions';
import {
  CheckDuplicateEmailResponse,
  CheckDuplicateEmailErrorResponse,
  SignupResponse,
  SignupErrorResponse,
} from '../interfaces/authInterFace';

import { setAuthSessionStorage, setAuthCookie } from '../utils/utilHelper';
import { Status } from '../interfaces/commonInterFace';

// check duplicate email
const checkDuplicateEmailHandler = api.getPostFactory();
function* runCheckDuplicateEmail(
  handler: typeof checkDuplicateEmailHandler,
  action: ReturnType<typeof checkDuplicateEmailEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: CheckDuplicateEmailResponse = yield call(
      handler,
      data,
      '/user/email/check',
    );

    if (!ReturnData) {
      yield put(checkDuplicateEmailEvent.fail({}));

      return;
    }

    yield put(checkDuplicateEmailEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as CheckDuplicateEmailErrorResponse;
    if (res.status_code === Status.ValidationFailed) {
      yield put(checkDuplicateEmailEvent.fail(res.errors));

      return;
    }

    yield put(checkDuplicateEmailEvent.fail({}));
  }
}
export function* checkDuplicateEmail(
  handler: typeof checkDuplicateEmailHandler,
) {
  yield takeLeading(
    ActionType.CHECK_DUPLICATE_EMAIL_START,
    runCheckDuplicateEmail,
    handler,
  );
}

// signup
const signupHandler = api.getPostFactory();
function* runSignup(
  handler: typeof signupHandler,
  action: ReturnType<typeof signupEvent.start>,
) {
  const data = action.payload;

  try {
    const ReturnData: SignupResponse = yield call(
      handler,
      data,
      '/user/profile',
    );

    if (!ReturnData) {
      yield put(checkDuplicateEmailEvent.fail({}));

      return;
    }

    const { access_token } = ReturnData;

    yield call(setAuthSessionStorage, access_token);
    yield call(setAuthCookie, access_token);
    yield put(setAccessToken(access_token));

    yield put(signupEvent.succeed());
  } catch (error) {
    const res = error.response.data as SignupErrorResponse;
    if (res.status_code === Status.ValidationFailed) {
      yield put(signupEvent.fail(res.errors));

      return;
    }

    yield put(signupEvent.fail({}));
  }
}
export function* signup(handler: typeof signupHandler) {
  yield takeLeading(ActionType.SIGNUP_START, runSignup, handler);
}

export const signUpSagas = [
  fork(checkDuplicateEmail, checkDuplicateEmailHandler),
  fork(signup, signupHandler),
];
