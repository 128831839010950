/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react';

import ButtonSquare from '../common/atoms/buttonBasic/ButtonSquare';
import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import McsInputSecondary from '../common/molecules/formParts/McsInputSecondary';
import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';

import OrgCategoryList from '../common/organisms/listParts/OrgCategoryList';

import { Category } from '../../interfaces/categoryInterFace';

import * as styles from './adminCategoryEdit.module.scss';

interface Props {
  fanclubName?: string;
  categories?: Category[];
  newCategoryText?: string;
  onChangeNewCategoryText?: (e: React.FormEvent<HTMLInputElement>) => void;
  onAdd?: () => void;
  onDelete?: (categoryId: number) => void;
  onBack?: () => void;
  error?: string;
}

const Component: FC<Props> = ({
  fanclubName = '',
  categories = [],
  newCategoryText = '',
  onChangeNewCategoryText = () => undefined,
  onAdd = () => undefined,
  onDelete = () => undefined,
  onBack = () => undefined,
  error = '',
}) => {
  return (
    <div className={styles.containerWrap}>
      <McsTitleSecondary
        mainText="カテゴリ編集"
        subText={fanclubName}
        onClick={onBack}
        cssClass={styles.contentTitle}
      />

      <div className={styles.contentWrap}>
        <div className={styles.OrgContentAddCategoryInputContainer}>
          <McsInputSecondary
            type="Senary"
            inputName="subject"
            value={newCategoryText}
            placeholder="新規カテゴリ名"
            maxLength={20}
            onChange={onChangeNewCategoryText}
            cssClass={styles.OrgContentAddCategoryInput}
            isError={!!error}
            errorText={error}
          />
          <ButtonSquare
            text="追加する"
            type={!newCategoryText || error ? 'Tertiary' : 'Senary'}
            size="Primary"
            onClick={onAdd}
            cssClass={styles.OrgContentAddCategoryButton}
          />
        </div>

        <div>
          <OrgCategoryList
            categoryList={categories}
            onClickClose={onDelete}
            cssClass={styles.OrgContentAddCategoryItem}
          />
        </div>

        <div className={styles.cancelWrapper}>
          <ButtonRoundedCorners
            text="戻る"
            type="Quinary"
            size="Primary"
            onClick={onBack}
            cssClass={styles.cancel}
          />
        </div>
      </div>
    </div>
  );
};

export default Component;
