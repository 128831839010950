import React, { FC } from 'react';
import ClassNames from 'classnames';

import blankImageUrl from '../../assets/images/blank-image.png';
import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';
import McsIconLabelSquareTertiary from '../iconWithLabel/McsIconLabelSquareTertiary';

import * as styles from './McsFooterBannerFC.module.scss';

interface Props {
  cssClass?: string;
  imageUrl?: string;
  mainText?: string;
  subText?: string;
  buttonText?: string;
  onClickFanclub?: () => void;
  onClickButton?: () => void;
}

const McsButtonWithCheck: FC<Props> = ({
  cssClass = '',
  imageUrl = '',
  mainText = '',
  subText = '',
  buttonText = 'ファンクラブへ入会する',
  onClickFanclub = () => undefined,
  onClickButton = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsFooterBannerWrap, cssClass);

  return (
    <div className={classNames}>
      <McsIconLabelSquareTertiary
        src={imageUrl || blankImageUrl}
        mainText={mainText}
        subText={subText}
        onClick={onClickFanclub}
      />
      <ButtonRoundedCorners
        text={buttonText}
        type="Secondary"
        size="Secondary"
        onClick={onClickButton}
      />
    </div>
  );
};

export default McsButtonWithCheck;
