import { Reducer } from 'redux';

import { Actions } from '../actions/followersActions';
import * as ActionType from '../actions/followersConstants';

import { Pager, PagerInitialValue } from '../interfaces/commonInterFace';

import { User } from '../interfaces/userInterFace';
import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../interfaces/fanclubInterFace';

type State = {
  details: FanclubDetails;
  followers: User[];
  pager: Pager;
  ready: boolean;
  loading: boolean;
};

export const initialState: State = {
  details: FanclubDetailsInitialValue,
  followers: [],
  pager: PagerInitialValue,
  ready: false,
  loading: false,
};

const followersReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fanclub details
    case ActionType.GET_FANCLUB_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_SUCCEED: {
      return {
        ...state,
        loading: false,
        details: action.payload.result,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // get fc follower list
    case ActionType.GET_FC_FOLLOWER_LIST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FC_FOLLOWER_LIST_SUCCEED: {
      const { data: followers, ...pager } = action.payload.result.followers;

      return {
        ...state,
        loading: false,
        followers,
        pager,
      };
    }
    case ActionType.GET_FC_FOLLOWER_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default followersReducer;
