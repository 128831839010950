/* eslint-disable no-nested-ternary */
import React, { FC, useCallback } from 'react';
import { Helmet } from 'react-helmet';

import { useHistory, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';
import TitleH2 from '../common/atoms/title/TitleH2';

import * as styles from './adminFCClosed.module.scss';

const CLOSED = `コミュニティの閉鎖が完了しました。
KRAPをご利用いただき、誠にありがとうございました。

またのご利用をお待ちしております。`;

const FCClosedComplete: FC = () => {
  const history = useHistory();

  const handleClickBackToTop = useCallback(() => {
    history.replace(PATH.HOME);
  }, [history]);

  useGA(history.location);

  return (
    <>
      <Helmet>
        <title>コミュニティ閉鎖完了 | コミュニティ管理 | KRAP</title>
      </Helmet>

      <div className={styles.containerWrap}>
        <TitleH2 text="コミュニティ閉鎖完了" cssClass={styles.title} />
        <p className={styles.text}>{CLOSED}</p>
        <div className={styles.buttonWrap}>
          <ButtonRoundedCorners
            text="TOP"
            type="Primary"
            size="Primary"
            onClick={handleClickBackToTop}
            cssClass={styles.button}
          />
        </div>
      </div>
    </>
  );
};

export default FCClosedComplete;
