/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';

import noUserImageUrl from '../common/assets/images/icon-nouser.png';

import OrgHeaderBar from '../common/organisms/headerParts/OrgHeaderBar';
import OrgHeaderNav from '../common/organisms/headerParts/OrgHeaderNav';
import OrgHeaderFCList from '../common/organisms/headerParts/OrgHeaderFCList';
import OrgFindWordUnitFCList from '../common/organisms/listParts/OrgFindWordUnitFCList';

import { User, UserInitialValue } from '../../interfaces/userInterFace';
import {
  Fanclub,
  FanclubWithFollowedAndManagedListWithKey,
} from '../../interfaces/fanclubInterFace';
import { HeaderMode } from '../../interfaces/utilInterFace';

import * as styles from './header.module.scss';

interface Props {
  user?: User;
  follows?: Fanclub[];
  manages?: Fanclub[];
  like_count?: number;
  searches?: FanclubWithFollowedAndManagedListWithKey[];
  isLogin?: boolean;
  unreadCount?: number;
  mode?: HeaderMode;
  handleLogoClick?: () => void;
  handleNavClick?: () => void;
  handleUserClick?: () => void;
  handleBellClick?: () => void;
  handleSearchClick?: () => void;
  handleBgClick?: () => void;
  handleFollowClick?: () => void;
  handleLikeClick?: () => void;
  handleClickSetting?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => void;
  handleClickHelp?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => void;
  handleLogoutClick?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => void;
  handleClickManageFanclub?: (artistId: string) => void;
  handleSearchFanclubClick?: (artistId: string) => void;
  handleSearchFollowClick?: (artistId: string, id: number) => void;
}

const Component: FC<Props> = ({
  user = UserInitialValue,
  follows = [],
  manages = [],
  like_count = 0,
  searches = [],
  isLogin = false,
  unreadCount = 0,
  mode = '',
  handleLogoClick = () => undefined,
  handleNavClick = () => undefined,
  handleUserClick = () => undefined,
  handleBellClick = () => undefined,
  handleSearchClick = () => undefined,
  handleBgClick = () => undefined,
  handleFollowClick = () => undefined,
  handleLikeClick = () => undefined,
  handleClickSetting = () => undefined,
  handleClickHelp = () => undefined,
  handleLogoutClick = () => undefined,
  handleClickManageFanclub = () => undefined,
  handleSearchFanclubClick = () => undefined,
  handleSearchFollowClick = () => undefined,
}) => {
  return (
    <>
      <OrgHeaderBar
        isLogin={isLogin}
        isOpen={mode === 'menu'}
        isSearchOpen={mode === 'search'}
        unreadCount={unreadCount}
        onLogoClick={handleLogoClick}
        onNavClick={handleNavClick}
        onBellClick={handleBellClick}
        onSearchClick={handleSearchClick}
        iconSrc={user.icon_image ? user.icon_image : noUserImageUrl}
        cssClass={styles.headerWrap}
      />

      {mode === 'menu' ? (
        <div className={styles.headerNavWrap} onClick={handleBgClick}>
          <div className={styles.headerNavContent}>
            <OrgHeaderNav
              userName={user.nickname}
              userId={user.name_id}
              iconSrc={user.icon_image ? user.icon_image : noUserImageUrl}
              onUserClick={handleUserClick}
              userFollow={follows.length.toString()}
              onFollowClick={handleFollowClick}
              userLike={like_count.toString()}
              onLikeClick={handleLikeClick}
              onClickSetting={handleClickSetting}
              onClickHelp={handleClickHelp}
              onLogoutClick={handleLogoutClick}
              cssClass={styles.headerNavContentMain}
            />
            {manages.length && (
              <OrgHeaderFCList
                fanclubList={manages}
                onClick={handleClickManageFanclub}
              />
            )}
          </div>
          <div className={styles.headerNavBg} />
        </div>
      ) : mode === 'search' ? (
        <div className={styles.headerSearchContainer}>
          <p className={styles.headerSearchTitle}>アーティストを見つけよう</p>
          {searches.map(({ key, lists }) => {
            if (!lists.length) return null;

            return (
              <OrgFindWordUnitFCList
                key={`SearchListWithTitle-${key}`}
                wordTitle={key}
                fanclubList={lists}
                onClickFanclub={handleSearchFanclubClick}
                onClickFollow={handleSearchFollowClick}
              />
            );
          })}
        </div>
      ) : null}
    </>
  );
};

export default Component;
