import React, { FC } from 'react';
import ClassNames from 'classnames';

import {
  LoginValidationErrors,
  CheckDuplicateEmailValidationErrors,
} from '../../interfaces/authInterFace';

import OrgSignUpLogin from '../common/organisms/signUpParts/OrgSignUpLogin';
import OrgSignUpRegister from '../common/organisms/signUpParts/OrgSignUpRegister';
import * as styles from './signUp.module.scss';

interface Props {
  isViewPleaseLogin?: boolean;

  valueID?: string;
  valuePass?: string;
  onClickForgetPassword?: () => void;
  onTwitterLoginEvent?: () => void;
  onLoginEvent?: () => void;
  onSaveCheckEvent?: () => void;

  valueEmail?: string;
  onTwitterRegisterEvent?: () => void;
  onRegisterEvent?: () => void;

  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  isCheck?: boolean;

  loginErrors?: LoginValidationErrors;
  checkDuplicateEmailErrors?: CheckDuplicateEmailValidationErrors;
}

const Component: FC<Props> = ({
  isViewPleaseLogin = false,

  valueID = '',
  valuePass = '',
  onClickForgetPassword = () => undefined,
  onTwitterLoginEvent = () => undefined,
  onLoginEvent = () => undefined,
  onSaveCheckEvent = () => undefined,

  valueEmail = '',
  onTwitterRegisterEvent = () => undefined,
  onRegisterEvent = () => undefined,

  onChange = () => undefined,
  isCheck = false,

  loginErrors = {
    email: '',
    password: '',
  },
  checkDuplicateEmailErrors = {
    email: '',
  },
}) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      {/** ログインしてください */}
      {isViewPleaseLogin && (
        <div className={styles.pleaseLoginContainer}>
          <p className={styles.pleaseLoginTitle}>ログインしてください</p>
          <p className={styles.pleaseLoginDetails}>
            {
              'コンテンツを閲覧するには、ログインが必要です。\nアカウントをお持ちでない方はアカウント（無料）を作成してください。\n既にアカウントをお持ちの方は下部ログインフォームよりログインして頂いてからコンテンツをお楽しみ下さい。'
            }
          </p>
        </div>
      )}
      <OrgSignUpLogin
        valueID={valueID}
        valuePass={valuePass}
        onClickForgetPassword={onClickForgetPassword}
        onTwitterLoginEvent={onTwitterLoginEvent}
        onLoginEvent={onLoginEvent}
        onSaveCheckEvent={onSaveCheckEvent}
        onChange={onChange}
        isCheck={isCheck}
        errors={loginErrors}
      />
      <OrgSignUpRegister
        valueEmail={valueEmail}
        onTwitterRegisterEvent={onTwitterRegisterEvent}
        onRegisterEvent={onRegisterEvent}
        onChange={onChange}
        errors={checkDuplicateEmailErrors}
        cssClass={styles.signup}
      />
    </div>
  );
};

export default Component;
