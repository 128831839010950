/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import moment from 'moment';
import noUserImageUrl from '../../assets/images/icon-nouser.png';
import blankImageUrl from '../../assets/images/blank-image.png';

import LabelTag from '../../atoms/label/LabelTag';
import IconThumbnailRectangle from '../../atoms/iconThumbnail/IconThumbnailRectangle';
import LabelTitleSeries from '../../atoms/label/LabelTitleSeries';
import ButtonMedia from '../../atoms/buttonUtile/ButtonMedia';
import IconCount from '../../atoms/iconParts/IconCount';
import IconShape from '../../atoms/iconParts/IconShape';
import McsIconLabelRoundQuaternary from '../../molecules/iconWithLabel/McsIconLabelRoundQuaternary';
import McsIconWithTextSecondary from '../../molecules/iconWithText/McsIconWithTextSecondary';

import * as styles from './OrgAdminListTimeLineUnit.module.scss';
import {
  Content,
  ContentInitialValue,
} from '../../../../interfaces/contentInterFace';

type PartialContent = Partial<Content>;

interface Props {
  cssClass?: string;
  content?: PartialContent;
  categories?: string[];
  isViewDeleteButton?: boolean;
  onClick?: () => void;
  onDelete?: (e: React.MouseEvent<HTMLDListElement, MouseEvent>) => void;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  content = ContentInitialValue,
  categories = [],
  isViewDeleteButton = false,
  onClick = () => undefined,
  onDelete = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgAdminListMenuUnitBase, cssClass);

  return (
    <li
      className={classNames}
      onClick={onClick}
      style={{
        backgroundColor: content.is_release ? '#ffffff' : '#f6f6f6',
      }}
    >
      {!content.is_release && (
        <div className={styles.OrgAdminListMenuUnitDraftWrapper}>
          <p className={styles.OrgAdminListMenuUnitDraft}>下書き保存中</p>
        </div>
      )}

      <div className={styles.OrgAdminListMenuUnitMainContent}>
        <div className={styles.OrgAdminListMenuUnitUserImage}>
          {content.type === 4 ? (
            <div className={styles.OrgAdminListMenuUnitWall} />
          ) : (
            <IconThumbnailRectangle
              src={content.url || blankImageUrl}
              size="Secondary"
            />
          )}

          {/* タイプで出し分け */}
          {/* 1:blog, 2:photo, 3:movie, 4:voice */}
          {content.type === 4 ? (
            <IconShape
              type="VoiceGray"
              cssClass={styles.OrgAdminListMenuUnitVoice}
            />
          ) : content.type === 3 ? (
            <ButtonMedia
              type="PlayVideo"
              cssClass={styles.OrgAdminListMenuUnitVideo}
            />
          ) : content.type === 2 ? (
            <IconCount
              type="Secondary"
              countNumber={content.photos?.length}
              cssClass={styles.OrgAdminListMenuUnitCount}
            />
          ) : null}
        </div>
        <div>
          <LabelTitleSeries
            mainText={content?.subject}
            subText={
              content.release_at
                ? moment(content.release_at).format('YYYY.MM.DD HH:mm')
                : ''
            }
            type="Quinary"
            cssClass={styles.OrgAdminListMenuUnitUserText}
          />
          <McsIconLabelRoundQuaternary
            src={content.user?.icon_image || noUserImageUrl}
            mainText={content.user?.nickname || '退会済みユーザー'}
            subText={`@${content.user?.name_id || ''}`}
          />
        </div>
      </div>
      <div className={styles.OrgAdminListMenuUnitSubContent}>
        <ul className={styles.OrgAdminListMenuUnitTag}>
          {categories.map((c, i) => (
            <li key={`${content.id}-${c}-${i.toString()}`}>
              <LabelTag
                text={c}
                type="Senary"
                cssClass={styles.OrgAdminListMenuUnitTagItem}
              />
            </li>
          ))}
        </ul>
        <div className={styles.OrgAdminListMenuUnitInfo}>
          {content?.total_like ? (
            <McsIconWithTextSecondary
              iconType="FavoriteFill"
              mainText={content.total_like.toString()}
            />
          ) : null}
          {isViewDeleteButton && (
            <McsIconWithTextSecondary
              iconType="Delete"
              mainText="削除する"
              cssClass={styles.OrgAdminListMenuUnitInfoItem}
              onClick={onDelete}
            />
          )}
        </div>
      </div>
    </li>
  );
};

export default OrgListUnit;
