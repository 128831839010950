/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/artistConstants';
import { checkAndSetStatusError } from './authSagas';
import { getUserProfileEvent as updateMe } from '../actions/authActions';
import {
  getReady as getReadyStart,
  gotReady,
  getFanclubDetailsEvent,
  getContentListEvent,
  getRecommendFanclubListEvent,
  freejoinFanclubEvent,
  withdrawFanclubEvent,
  getUnreadChatEvent,
} from '../actions/artistActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import {
  GetFanclubDetailsResponse,
  GetPickupFanclubListResponse,
  FreejoinFanclubResponse,
  WithdrawFanclubResponse,
} from '../interfaces/fanclubInterFace';
import { getUnreadChatResponse } from '../interfaces/chatInterFace';
import { GetContentListResponse } from '../interfaces/contentInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId, chatAvailable } = action.payload;

  const getFanclubDetailsTask = yield fork(
    runGetFanclubDetails,
    getFanclubDetailsHandler,
    getFanclubDetailsEvent.start({ artistId }),
  );
  yield join(getFanclubDetailsTask);

  const getContentListTask = yield fork(
    runGetContentList,
    getContentListHandler,
    getContentListEvent.start({ type: 0, group_id: artistId }),
  );
  yield join(getContentListTask);

  if (chatAvailable) {
    const getUnreadChatTask = yield fork(
      runGetUnreadChat,
      getUnreadChatHandler,
      getUnreadChatEvent.start(artistId),
    );
    yield join(getUnreadChatTask);
  }

  yield put(gotReady());

  yield put(getRecommendFanclubListEvent.start({ scope: 'recommend' }));
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get fanclub details
const getFanclubDetailsHandler = api.getGetFactory(true);
function* runGetFanclubDetails(
  handler: typeof getFanclubDetailsHandler,
  action: ReturnType<typeof getFanclubDetailsEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetFanclubDetailsResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(getFanclubDetailsEvent.fail());

      return;
    }

    yield put(getFanclubDetailsEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFanclubDetailsEvent.fail());
  }
}
export function* getFanclubDetails(handler: typeof getFanclubDetailsHandler) {
  yield takeLatest(
    ActionType.GET_FANCLUB_DETAILS_START,
    runGetFanclubDetails,
    handler,
  );
}

// get content list
const getContentListHandler = api.getGetFactory(true);
function* runGetContentList(
  handler: typeof getContentListHandler,
  action: ReturnType<typeof getContentListEvent.start>,
) {
  const { type, reset: _, ...data } = action.payload;
  const params = type === 0 ? data : { ...data, type };

  try {
    const ReturnData: GetContentListResponse = yield call(
      handler,
      params,
      '/contents',
    );

    if (!ReturnData) {
      yield put(getContentListEvent.fail());

      return;
    }

    yield put(getContentListEvent.succeed(action.payload, ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getContentListEvent.fail());
  }
}
export function* getContentList(handler: typeof getContentListHandler) {
  yield takeLatest(
    ActionType.GET_CONTENT_LIST_START,
    runGetContentList,
    handler,
  );
}

// get recommend fanclub list
const getRecommendFanclubListHandler = api.getGetFactory(true);
function* runGetRecommendFanclubList(
  handler: typeof getRecommendFanclubListHandler,
  action: ReturnType<typeof getRecommendFanclubListEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: GetPickupFanclubListResponse = yield call(
      handler,
      data,
      '/fanclub',
    );

    if (!ReturnData) {
      yield put(getRecommendFanclubListEvent.fail());

      return;
    }

    yield put(getRecommendFanclubListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getRecommendFanclubListEvent.fail());
  }
}
export function* getRecommendFanclubList(
  handler: typeof getRecommendFanclubListHandler,
) {
  yield takeLatest(
    ActionType.GET_RECOMMEND_FANCLUB_LIST_START,
    runGetRecommendFanclubList,
    handler,
  );
}

// freejoin fanclub
const freejoinFanclubHandler = api.getPostFactory(true);
function* runFreejoinFanclub(
  handler: typeof freejoinFanclubHandler,
  action: ReturnType<typeof freejoinFanclubEvent.start>,
) {
  const { artistId } = action.payload;
  try {
    const ReturnData: FreejoinFanclubResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/freejoin`,
    );

    if (!ReturnData) {
      yield put(freejoinFanclubEvent.fail());

      return;
    }
    // update user profile
    yield put(updateMe.start());

    yield put(freejoinFanclubEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    window.alert('フォローに失敗しました');

    yield put(freejoinFanclubEvent.fail());
  }
}
export function* freejoinFanclub(handler: typeof freejoinFanclubHandler) {
  yield takeLeading(
    ActionType.FREEJOIN_FANCLUB_START,
    runFreejoinFanclub,
    handler,
  );
}

// withdraw fanclub
const withdrawFanclubHandler = api.getPostFactory(true);
function* runWithdrawFanclub(
  handler: typeof withdrawFanclubHandler,
  action: ReturnType<typeof withdrawFanclubEvent.start>,
) {
  const { artistId } = action.payload;
  try {
    const ReturnData: WithdrawFanclubResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/withdraw`,
    );

    if (!ReturnData) {
      yield put(withdrawFanclubEvent.fail());

      return;
    }
    // update user profile
    yield put(updateMe.start());

    yield put(withdrawFanclubEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    window.alert('フォロー解除に失敗しました');

    yield put(withdrawFanclubEvent.fail());
  }
}
export function* withdrawFanclub(handler: typeof withdrawFanclubHandler) {
  yield takeLeading(
    ActionType.WITHDRAW_FANCLUB_START,
    runWithdrawFanclub,
    handler,
  );
}

// チャットの未読件数取得
const getUnreadChatHandler = api.getGetFactory(true);
function* runGetUnreadChat(
  handler: typeof getUnreadChatHandler,
  action: ReturnType<typeof getUnreadChatEvent.start>,
) {
  const artistId = action.payload;
  try {
    const ReturnData: getUnreadChatResponse = yield call(
      handler,
      {},
      `fanclub/${artistId}/chats/unreadCount`,
    );

    if (!ReturnData) {
      yield put(getUnreadChatEvent.fail());

      return;
    }

    yield put(getUnreadChatEvent.succeed(ReturnData.result.unread_count));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getUnreadChatEvent.fail());
  }
}
export function* getUnreadChat(handler: typeof getUnreadChatHandler) {
  yield takeLatest(ActionType.GET_UNREAD_CHAT_START, runGetUnreadChat, handler);
}

export const artistSagas = [
  fork(getReady),
  fork(getFanclubDetails, getFanclubDetailsHandler),
  fork(getContentList, getContentListHandler),
  fork(getRecommendFanclubList, getRecommendFanclubListHandler),
  fork(freejoinFanclub, freejoinFanclubHandler),
  fork(withdrawFanclub, withdrawFanclubHandler),
  fork(getUnreadChat, getUnreadChatHandler),
];
