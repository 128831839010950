/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import LabelTitle from '../common/atoms/title/TitleDiv';
import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';
import InputFileHero from '../common/atoms/formParts/InputFileHero';

import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';
import McsInputSecondary from '../common/molecules/formParts/McsInputSecondary';
import McsSelectBirthSecondary from '../common/molecules/formParts/McsSelectBirthSecondary';

import * as styles from './adminApplyPaidFC.module.scss';

import { SelectField } from '../../interfaces/utilInterFace';
import {
  ApplyPaidFCInputParams,
  ApplyPaidFCInputParamsInitialValue,
  ApplyPaidFCValidationErrors,
  ApplyPaidFCValidationErrorsInitialValue,
} from '../../interfaces/fanclubInterFace';

type Props = {
  fanclubName?: string;
  params?: ApplyPaidFCInputParams;
  yearList?: SelectField;
  monthList?: SelectField;
  dateList?: SelectField;
  handleChange?: (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  handleChangeImage?: (
    e: React.FormEvent<HTMLInputElement>,
    key?: string,
  ) => void;
  handleBlur?: (e: React.FocusEvent<HTMLInputElement>, key?: string) => void;
  handleApply?: () => void;
  handleBack?: () => void;
  errors?: ApplyPaidFCValidationErrors;
  canApply?: boolean;
};

const Component: FC<Props> = ({
  fanclubName = '',
  params = ApplyPaidFCInputParamsInitialValue,
  yearList = [],
  monthList = [],
  dateList = [],
  handleChange = () => undefined,
  handleChangeImage = () => undefined,
  handleBlur = () => undefined,
  handleApply = () => undefined,
  handleBack = () => undefined,
  errors = ApplyPaidFCValidationErrorsInitialValue,
  canApply = false,
}) => {
  const classNames = ClassNames(styles.container);

  return (
    <div className={classNames}>
      <McsTitleSecondary
        mainText="コミュニティを有料化する"
        subText={fanclubName}
        onClick={handleBack}
        cssClass={styles.title}
      />

      <div className={styles.body}>
        <p className={styles.read}>
          {`コミュニティを有料化すると、有料コンテンツの作成が可能になります。
必要項目をご記入の上、「有料化申請」ボタンをタップしてください。
申請から審査完了まで、通常3営業日頂戴いたします。`}
        </p>

        <McsInputSecondary
          value={params.name}
          type="Senary"
          inputType="text"
          inputName="name"
          placeholder="代表者氏名を入力"
          maxLength={50}
          isHiddenMaxLengthInfo
          labelTitle="代表者氏名"
          cssClass={styles.input}
          onChange={handleChange}
          isError={!!errors.name}
          errorText={errors.name}
        />

        <div className={styles.input}>
          <LabelTitle
            text="郵便番号(半角数字ハイフンなし)"
            cssClass={styles.label}
          />
          <McsInputSecondary
            value={params.postal_code}
            type="Senary"
            inputType="text"
            inputName="postal_code"
            maxLength={7}
            isHiddenMaxLengthInfo
            placeholder="1234567"
            onChange={handleChange}
            cssClass={styles.inputPostal}
            isError={!!errors.address}
            errorText={errors.address}
          />
        </div>

        <McsInputSecondary
          value={params.address}
          type="Senary"
          inputType="text"
          inputName="address"
          placeholder="住所を入力"
          maxLength={100}
          isHiddenMaxLengthInfo
          labelTitle="住所"
          cssClass={styles.input}
          onChange={handleChange}
          isError={!!errors.address}
          errorText={errors.address}
        />

        <McsSelectBirthSecondary
          inputName1="birthday_year"
          inputName2="birthday_month"
          inputName3="birthday_date"
          placeholder1="年"
          placeholder2="月"
          placeholder3="日"
          value1={params.birthday_year.toString()}
          value2={params.birthday_month.toString()}
          value3={params.birthday_date.toString()}
          labelTitle="代表者生年月日"
          selectList1={yearList}
          selectList2={monthList}
          selectList3={dateList}
          onChange={handleChange}
          cssClass={styles.input}
          isError={!!errors.birthday}
          errorText={errors.birthday}
        />

        <McsInputSecondary
          value={params.tel}
          type="Senary"
          inputType="tel"
          inputName="tel"
          placeholder="電話番号を入力"
          maxLength={11}
          isHiddenMaxLengthInfo
          labelTitle="代表者電話番号（半角数字ハイフンなし）"
          cssClass={styles.input}
          onChange={handleChange}
          isError={!!errors.tel}
          errorText={errors.tel}
        />

        <McsInputSecondary
          value={params.email}
          type="Senary"
          inputType="email"
          inputName="email"
          placeholder="メールアドレスを入力"
          labelTitle="代表者メールアドレス"
          cssClass={styles.input}
          onChange={handleChange}
          isError={!!errors.email}
          errorText={errors.email}
        />

        <div className={styles.input}>
          <LabelTitle
            text="月額会費（最低¥300、¥100単位）"
            cssClass={styles.label}
          />
          <McsInputSecondary
            value={params.price}
            type="Senary"
            inputType="text"
            inputName="price"
            placeholder="月額を入力する"
            cssClass={styles.inputPrice}
            onChange={handleChange}
            onBlur={handleBlur}
            isError={!!errors.price}
            errorText={errors.price}
          />
        </div>

        <div className={styles.input}>
          <LabelTitle text="身分証明書の画像" cssClass={styles.label} />
          <InputFileHero
            title="身分証明書画像"
            inputNameHero="identification_image"
            type="Quaternary"
            preview={params.identification_image.preview}
            onChange={handleChangeImage}
            isVariableHeight
            isError={!!errors.identification_image}
            errorText={errors.identification_image}
          />
        </div>
        <p className={styles.read}>
          ※身分証明書の例）免許証、旅券(パスポート)、在留カード、特別永住者証明書、外国人登録証明書（在留の資格が特別永住者のものに限ります。）
        </p>

        <ButtonRoundedCorners
          text="有料化申請"
          type={canApply ? 'Senary' : 'Quaternary'}
          size="Primary"
          onClick={handleApply}
          cssClass={styles.submit}
        />

        <div className={styles.cancelWrapper}>
          <ButtonRoundedCorners
            text="戻る"
            type="Quinary"
            size="Primary"
            onClick={handleBack}
            cssClass={styles.cancel}
          />
        </div>
      </div>
    </div>
  );
};

export default Component;
