import * as ActionType from './adminCommunityEditConstants';
import {
  GetFanclubDetailsParams,
  GetFanclubDetailsResult,
  UpdateFanclubParams,
  UpdateFanclubResult,
  UpdateFanclubErrors,
} from '../interfaces/fanclubInterFace';

export const getReady = (artistId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getFanclubDetailsEvent = {
  start: (params: GetFanclubDetailsParams) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetFanclubDetailsResult) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_FAIL,
    } as const),
};
export const updateFanclubEvent = {
  start: (params: UpdateFanclubParams) =>
    ({
      type: ActionType.UPDATE_FANCLUB_START,
      payload: params,
    } as const),

  succeed: (result: UpdateFanclubResult) =>
    ({
      type: ActionType.UPDATE_FANCLUB_SUCCEED,
      payload: { result },
    } as const),

  fail: (errors: UpdateFanclubErrors) =>
    ({
      type: ActionType.UPDATE_FANCLUB_FAIL,
      payload: { errors },
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fanclub details
  | ReturnType<typeof getFanclubDetailsEvent.start>
  | ReturnType<typeof getFanclubDetailsEvent.succeed>
  | ReturnType<typeof getFanclubDetailsEvent.fail>
  // update fanclub
  | ReturnType<typeof updateFanclubEvent.start>
  | ReturnType<typeof updateFanclubEvent.succeed>
  | ReturnType<typeof updateFanclubEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
