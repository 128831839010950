/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import McsSuggestBankListUnit from '../../molecules/listUnitParts/McsTitleAndLabelButtonListUnit';
import McsInputSecondary from '../../molecules/formParts/McsInputSecondary';
import McsSelectSecondary from '../../molecules/formParts/McsSelectSecondary';

import { SelectField } from '../../../../interfaces/utilInterFace';
import {
  BankType,
  Bank,
  BankBranch,
  RegisterOtherBankAccountInputParams,
  RegisterOtherBankAccountInputParamsInitialValue,
  RegisterJPBankAccountInputParams,
  RegisterJPBankAccountInputParamsInitialValue,
  RegisterOtherBankAccountValidationErrors,
  RegisterOtherBankAccountValidationErrorsInitialValue,
  RegisterJPBankAccountValidationErrors,
  RegisterJPBankAccountValidationErrorsInitialValue,
} from '../../../../interfaces/bankInterFace';

import * as styles from './OrgAdminFormBankAccount.module.scss';

interface Props {
  bankType?: BankType;
  otherBankAccountParams?: RegisterOtherBankAccountInputParams;
  jpBankAccountParams?: RegisterJPBankAccountInputParams;
  bankTypeList?: SelectField;
  assortmentTypeList?: SelectField;
  suggestBankList?: Bank[];
  suggestBankBranchList?: BankBranch[];
  selectedBank?: Bank | null;
  selectedBankBranch?: BankBranch | null;
  handleChangeBankType?: (e: React.FormEvent<HTMLSelectElement>) => void;
  handleChangeOtherBankAccount?: (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  handleChangeJPBankAccount?: (
    e: React.FormEvent<HTMLInputElement | HTMLSelectElement>,
  ) => void;
  handleSelectBank?: (code: string) => void;
  handleSelectBankBranch?: (code: string) => void;
  canInputBankBranch?: boolean;
  cssClass?: string;
  otherBankAccountErrors?: RegisterOtherBankAccountValidationErrors;
  jpBankAccountErrors?: RegisterJPBankAccountValidationErrors;
}

const OrgAdminFormAdministrator: FC<Props> = ({
  bankType = 0,
  otherBankAccountParams = RegisterOtherBankAccountInputParamsInitialValue,
  jpBankAccountParams = RegisterJPBankAccountInputParamsInitialValue,
  bankTypeList = [],
  assortmentTypeList = [],
  suggestBankList = [],
  suggestBankBranchList = [],
  selectedBank = null,
  selectedBankBranch = null,
  handleChangeBankType = () => undefined,
  handleChangeOtherBankAccount = () => undefined,
  handleChangeJPBankAccount = () => undefined,
  handleSelectBank = () => undefined,
  handleSelectBankBranch = () => undefined,
  canInputBankBranch = true,
  cssClass = '',
  otherBankAccountErrors = RegisterOtherBankAccountValidationErrorsInitialValue,
  jpBankAccountErrors = RegisterJPBankAccountValidationErrorsInitialValue,
}) => {
  const classNames = ClassNames(styles.container, cssClass);

  const params = bankType === 0 ? otherBankAccountParams : jpBankAccountParams;
  const errors = bankType === 0 ? otherBankAccountErrors : jpBankAccountErrors;

  return (
    <div className={classNames}>
      <McsSelectSecondary
        inputName="banks"
        value={bankType.toString()}
        labelTitle="種類"
        selectList={bankTypeList}
        onChange={handleChangeBankType}
        cssClass={styles.inputType}
        isError={!!errors.banks}
        errorText={errors.banks}
      />

      {bankType === 0 ? (
        <>
          <div className={styles.input}>
            <McsInputSecondary
              value={otherBankAccountParams.bank_name}
              type="Senary"
              inputType="text"
              inputName="bank_name"
              placeholder="銀行名を入力"
              labelTitle="銀行名（全角）"
              onChange={handleChangeOtherBankAccount}
              isError={!!otherBankAccountErrors.bank_name}
              errorText={otherBankAccountErrors.bank_name}
            />
            {selectedBank
              ? null
              : suggestBankList.map((bank, i) => (
                  <McsSuggestBankListUnit
                    key={`suggestBank${i.toString()}`}
                    title={bank.name}
                    buttonText="選択する"
                    onClick={() => handleSelectBank(bank.code)}
                    cssClass={styles.listItemWrapper}
                  />
                ))}
          </div>
          <div className={styles.input}>
            <McsInputSecondary
              value={otherBankAccountParams.store_name}
              type="Senary"
              inputType="text"
              inputName="store_name"
              placeholder="支店名を入力"
              labelTitle="支店名（全角）"
              isDisabled={!canInputBankBranch}
              onChange={handleChangeOtherBankAccount}
              isError={!!otherBankAccountErrors.store_name}
              errorText={otherBankAccountErrors.store_name}
            />
            {selectedBankBranch
              ? null
              : suggestBankBranchList.map((branch, i) => (
                  <McsSuggestBankListUnit
                    key={`suggestBankBranch${i.toString()}`}
                    title={branch.name}
                    buttonText="選択する"
                    onClick={() => handleSelectBankBranch(branch.code)}
                    cssClass={styles.listItemWrapper}
                  />
                ))}
          </div>
        </>
      ) : bankType === 1 ? (
        <>
          <McsInputSecondary
            value={jpBankAccountParams.sign}
            type="Senary"
            inputType="text"
            inputName="sign"
            placeholder="記号を入力"
            maxLength={5}
            isHiddenMaxLengthInfo
            labelTitle="記号"
            cssClass={styles.input}
            onChange={handleChangeJPBankAccount}
            isError={!!jpBankAccountErrors.sign}
            errorText={jpBankAccountErrors.sign}
          />
        </>
      ) : null}

      <McsSelectSecondary
        inputName="assortment"
        value={params.assortment.toString()}
        labelTitle="口座種別"
        selectList={assortmentTypeList}
        onChange={
          bankType === 0
            ? handleChangeOtherBankAccount
            : handleChangeJPBankAccount
        }
        cssClass={styles.inputAccountType}
        isError={!!errors.assortment}
        errorText={errors.assortment}
      />

      <McsInputSecondary
        value={params.account_number}
        type="Senary"
        inputType="text"
        inputName="account_number"
        placeholder="口座番号を入力"
        maxLength={bankType === 0 ? 7 : 8}
        isHiddenMaxLengthInfo
        labelTitle="口座番号"
        cssClass={styles.input}
        onChange={
          bankType === 0
            ? handleChangeOtherBankAccount
            : handleChangeJPBankAccount
        }
        isError={!!errors.account_number}
        errorText={errors.account_number}
      />

      <McsInputSecondary
        value={params.name}
        type="Senary"
        inputType="text"
        inputName="name"
        placeholder="口座名義人（全角カナ）を入力"
        labelTitle="口座名義人（全角カナ）"
        cssClass={styles.input}
        onChange={
          bankType === 0
            ? handleChangeOtherBankAccount
            : handleChangeJPBankAccount
        }
        isError={!!errors.name}
        errorText={errors.name}
      />
    </div>
  );
};

export default OrgAdminFormAdministrator;
