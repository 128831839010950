/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLeading,
  takeLatest,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/adminLikesConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getLikeListEvent,
} from '../actions/adminLikesActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import { GetLikeListResponse } from '../interfaces/contentInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId } = action.payload;

  const getLikeListTask = yield fork(
    runGetLikeList,
    getLikeListHandler,
    getLikeListEvent.start({ artistId }),
  );
  yield join(getLikeListTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get like list
const getLikeListHandler = api.getGetFactory(true);
function* runGetLikeList(
  handler: typeof getLikeListHandler,
  action: ReturnType<typeof getLikeListEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetLikeListResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/likes`,
    );

    if (!ReturnData) {
      yield put(getLikeListEvent.fail());

      return;
    }

    yield put(getLikeListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getLikeListEvent.fail());
  }
}
export function* getLikeList(handler: typeof getLikeListHandler) {
  yield takeLeading(ActionType.GET_LIKE_LIST_START, runGetLikeList, handler);
}

export const adminLikesSagas = [
  fork(getReady),
  fork(getLikeList, getLikeListHandler),
];
