export const GET_READY = 'COMMENTS/GET_READY' as const;
export const GOT_READY = 'COMMENTS/GOT_READY' as const;

export const GET_CONTENT_DETAILS_START = 'COMMENTS/GET_CONTENT_DETAILS_START' as const;
export const GET_CONTENT_DETAILS_SUCCEED = 'COMMENTS/GET_CONTENT_DETAILS_SUCCEED' as const;
export const GET_CONTENT_DETAILS_FAIL = 'COMMENTS/GET_CONTENT_DETAILS_FAIL' as const;

export const SUBMIT_COMMENT_START = 'COMMENTS/SUBMIT_COMMENT_START' as const;
export const SUBMIT_COMMENT_SUCCEED = 'COMMENTS/SUBMIT_COMMENT_SUCCEED' as const;
export const SUBMIT_COMMENT_FAIL = 'COMMENTS/SUBMIT_COMMENT_FAIL' as const;

export const DELETE_COMMENT_START = 'COMMENTS/DELETE_COMMENT_START' as const;
export const DELETE_COMMENT_SUCCEED = 'COMMENTS/DELETE_COMMENT_SUCCEED' as const;
export const DELETE_COMMENT_FAIL = 'COMMENTS/DELETE_COMMENT_FAIL' as const;

export const FREEJOIN_FANCLUB_START = 'COMMENTS/FREEJOIN_FANCLUB_START' as const;
export const FREEJOIN_FANCLUB_SUCCEED = 'COMMENTS/FREEJOIN_FANCLUB_SUCCEED' as const;
export const FREEJOIN_FANCLUB_FAIL = 'COMMENTS/FREEJOIN_FANCLUB_FAIL' as const;

export const CLEANUP = 'COMMENTS/CLEANUP' as const;
