export const GET_READY = 'CHANGE_CREDIT_CARD/GET_READY' as const;
export const GOT_READY = 'CHANGE_CREDIT_CARD/GOT_READY' as const;

export const GET_CARD_INFO_START = 'CHANGE_CREDIT_CARD/GET_CARD_INFO_START' as const;
export const GET_CARD_INFO_SUCCEED = 'CHANGE_CREDIT_CARD/GET_CARD_INFO_SUCCEED' as const;
export const GET_CARD_INFO_FAIL = 'CHANGE_CREDIT_CARD/GET_CARD_INFO_FAIL' as const;

export const UPDATE_CARD_INFO_START = 'CHANGE_CREDIT_CARD/UPDATE_CARD_INFO_START' as const;
export const UPDATE_CARD_INFO_SUCCEED = 'CHANGE_CREDIT_CARD/UPDATE_CARD_INFO_SUCCEED' as const;
export const UPDATE_CARD_INFO_FAIL = 'CHANGE_CREDIT_CARD/UPDATE_CARD_INFO_FAIL' as const;

export const CLEANUP = 'CHANGE_CREDIT_CARD/CLEANUP' as const;
