export const GET_READY = 'ADMIN_ADMINISTRATOR/GET_READY' as const;
export const GOT_READY = 'ADMIN_ADMINISTRATOR/GOT_READY' as const;

export const GET_ADMINISTRATOR_START = 'ADMIN_ADMINISTRATOR/GET_ADMINISTRATOR_START' as const;
export const GET_ADMINISTRATOR_SUCCEED = 'ADMIN_ADMINISTRATOR/GET_ADMINISTRATOR_SUCCEED' as const;
export const GET_ADMINISTRATOR_FAIL = 'ADMIN_ADMINISTRATOR/GET_ADMINISTRATOR_FAIL' as const;

export const GET_CURRENT_BANK_START = 'ADMIN_ADMINISTRATOR/GET_CURRENT_BANK_START' as const;
export const GET_CURRENT_BANK_SUCCEED = 'ADMIN_ADMINISTRATOR/GET_CURRENT_BANK_SUCCEED' as const;
export const GET_CURRENT_BANK_FAIL = 'ADMIN_ADMINISTRATOR/GET_CURRENT_BANK_FAIL' as const;

export const GET_CURRENT_BANK_BRANCH_START = 'ADMIN_ADMINISTRATOR/GET_CURRENT_BANK_BRANCH_START' as const;
export const GET_CURRENT_BANK_BRANCH_SUCCEED = 'ADMIN_ADMINISTRATOR/GET_CURRENT_BANK_BRANCH_SUCCEED' as const;
export const GET_CURRENT_BANK_BRANCH_FAIL = 'ADMIN_ADMINISTRATOR/GET_CURRENT_BANK_BRANCH_FAIL' as const;

export const GET_BANK_ACCOUNT_START = 'ADMIN_ADMINISTRATOR/GET_BANK_ACCOUNT_START' as const;
export const GET_BANK_ACCOUNT_SUCCEED = 'ADMIN_ADMINISTRATOR/GET_BANK_ACCOUNT_SUCCEED' as const;
export const GET_BANK_ACCOUNT_FAIL = 'ADMIN_ADMINISTRATOR/GET_BANK_ACCOUNT_FAIL' as const;

export const GET_ADDRESS_START = 'ADMIN_ADMINISTRATOR/GET_ADDRESS_START' as const;
export const GET_ADDRESS_SUCCEED = 'ADMIN_ADMINISTRATOR/GET_ADDRESS_SUCCEED' as const;
export const GET_ADDRESS_FAIL = 'ADMIN_ADMINISTRATOR/GET_ADDRESS_FAIL' as const;

export const SEARCH_BANK_START = 'ADMIN_ADMINISTRATOR/SEARCH_BANK_START' as const;
export const SEARCH_BANK_SUCCEED = 'ADMIN_ADMINISTRATOR/SEARCH_BANK_SUCCEED' as const;
export const SEARCH_BANK_FAIL = 'ADMIN_ADMINISTRATOR/SEARCH_BANK_FAIL' as const;

export const SEARCH_BANK_BRANCH_START = 'ADMIN_ADMINISTRATOR/SEARCH_BANK_BRANCH_START' as const;
export const SEARCH_BANK_BRANCH_SUCCEED = 'ADMIN_ADMINISTRATOR/SEARCH_BANK_BRANCH_SUCCEED' as const;
export const SEARCH_BANK_BRANCH_FAIL = 'ADMIN_ADMINISTRATOR/SEARCH_BANK_BRANCH_FAIL' as const;

export const UPDATE_ADMINISTRATOR_START = 'ADMIN_ADMINISTRATOR/UPDATE_ADMINISTRATOR_START' as const;
export const UPDATE_ADMINISTRATOR_SUCCEED = 'ADMIN_ADMINISTRATOR/UPDATE_ADMINISTRATOR_SUCCEED' as const;
export const UPDATE_ADMINISTRATOR_FAIL = 'ADMIN_ADMINISTRATOR/UPDATE_ADMINISTRATOR_FAIL' as const;

export const CREATE_BANK_ACCOUNT_START = 'ADMIN_ADMINISTRATOR/CREATE_BANK_ACCOUNT_START' as const;
export const CREATE_BANK_ACCOUNT_SUCCEED = 'ADMIN_ADMINISTRATOR/CREATE_BANK_ACCOUNT_SUCCEED' as const;
export const CREATE_BANK_ACCOUNT_FAIL = 'ADMIN_ADMINISTRATOR/CREATE_BANK_ACCOUNT_FAIL' as const;

export const UPDATE_BANK_ACCOUNT_START = 'ADMIN_ADMINISTRATOR/UPDATE_BANK_ACCOUNT_START' as const;
export const UPDATE_BANK_ACCOUNT_SUCCEED = 'ADMIN_ADMINISTRATOR/UPDATE_BANK_ACCOUNT_SUCCEED' as const;
export const UPDATE_BANK_ACCOUNT_FAIL = 'ADMIN_ADMINISTRATOR/UPDATE_BANK_ACCOUNT_FAIL' as const;

export const CLEANUP = 'ADMIN_ADMINISTRATOR/CLEANUP' as const;
