import React, { FC } from 'react';
import ClassNames from 'classnames';

import Button from '../../atoms/buttonBasic/ButtonSquare';
import CheckBox from '../../atoms/formParts/CheckBox';

import * as styles from './McsButtonWithCheck.module.scss';

interface Props {
  buttonText?: string;
  checkBoxLabel?: string;
  cssClass?: string;

  onClick?: () => void;
  onChange?: () => void;
  checked?: boolean;
  isDisabled?: boolean;
}

const McsButtonWithCheck: FC<Props> = ({
  buttonText = 'Click',
  checkBoxLabel = 'Save',
  cssClass = '',

  onClick = () => undefined,
  onChange = () => undefined,

  checked = false,
  isDisabled = false,
}) => {
  const classNames = ClassNames(styles.McsButtonWithCheckBase, cssClass);

  return (
    <div className={classNames}>
      <Button text={buttonText} size="Secondary" onClick={onClick} />
      <CheckBox
        labelText={checkBoxLabel}
        onChange={onChange}
        checked={checked}
        isDisabled={isDisabled}
      />
    </div>
  );
};

export default McsButtonWithCheck;
