import * as ActionType from './withdrawConstants';
import { WithdrawErrors } from '../interfaces/authInterFace';

export const withdrawEvent = {
  start: () =>
    ({
      type: ActionType.WITHDRAW_START,
    } as const),

  succeed: () =>
    ({
      type: ActionType.WITHDRAW_SUCCEED,
    } as const),

  fail: (errors: WithdrawErrors) =>
    ({
      type: ActionType.WITHDRAW_FAIL,
      payload: { errors },
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // withdraw
  | ReturnType<typeof withdrawEvent.start>
  | ReturnType<typeof withdrawEvent.succeed>
  | ReturnType<typeof withdrawEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
