import React, { FC } from 'react';
import ClassNames from 'classnames';

import LabelTitle from '../../atoms/title/TitleDiv';

import * as styles from './McsTextBoxScroll.module.scss';

interface Props {
  value: string;
  cssClass?: string;
  labelTitle?: string | undefined;
  readonly?: boolean;
}

const McsTextArea: FC<Props> = ({
  value = '',
  cssClass = '',
  labelTitle = '',
  readonly = false,
}) => {
  const classNames = ClassNames(styles.McsTextAreaBase, cssClass);

  return (
    <div className={classNames}>
      <LabelTitle text={labelTitle} cssClass={styles.McsTextAreaTitle} />
      <textarea
        value={value}
        readOnly={readonly}
        className={styles.McsTextAreaBox}
      />
    </div>
  );
};

export default McsTextArea;
