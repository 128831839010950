/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useMemo } from 'react';

import Tab from '../common/atoms/tab/Tab';

import OrgHeroUser from '../common/organisms/heroParts/OrgHeroUser';
import OrgTimeLIne from '../common/organisms/listParts/OrgTimeLIne';
import OrgFollowLikeFCList from '../common/organisms/listParts/OrgFollowLikeFCList';
import OrgRecommendFCList from '../common/organisms/listParts/OrgRecommendFCList';
import OrgPickUpFCList from '../common/organisms/listParts/OrgPickUpFCList';
import OrgFanclubListModal from '../common/organisms/modalParts/OrgFanclubListModal';

import { SelectField } from '../../interfaces/utilInterFace';
import {
  UserProfile,
  UserTabMode,
  UserProfileInitialValue,
} from '../../interfaces/userInterFace';
import {
  Fanclub,
  FanclubWithUserInfo,
} from '../../interfaces/fanclubInterFace';
import { Content } from '../../interfaces/contentInterFace';

import * as styles from './user.module.scss';

type Props = {
  profile?: UserProfile;
  prefectures?: SelectField;
  tabMode?: UserTabMode;
  follows?: FanclubWithUserInfo[];
  likes?: Content[];
  isViewEditButton?: boolean;
  isViewManageListModal?: boolean;
  isViewReadMoreButton?: boolean;
  onClickEdit?: () => void;
  onClickManageList?: () => void;
  onClickFanclub?: (artistId: string) => void;
  onClickBlur?: () => void;
  onClickTab?: (mode: UserTabMode) => void;
  onClickFollowButton?: (artistId: string, id: number) => void;
  onClickContent?: (artistId: string, contentId: number) => void;
  onClickUser?: (userId: string) => void;
  onClickReadMore?: () => void;
  pickups?: Fanclub[];
  recommends?: Fanclub[];
};
const Component: FC<Props> = ({
  profile = UserProfileInitialValue,
  prefectures = [],
  tabMode = 'follow',
  follows = [],
  likes = [],
  isViewEditButton = false,
  isViewManageListModal = false,
  isViewReadMoreButton = false,
  onClickEdit = () => undefined,
  onClickManageList = () => undefined,
  onClickFanclub = () => undefined,
  onClickBlur = () => undefined,
  onClickTab = () => undefined,
  onClickFollowButton = () => undefined,
  onClickContent = () => undefined,
  onClickUser = () => undefined,
  onClickReadMore = () => undefined,
  pickups = [],
  recommends = [],
}) => {
  const prefecture = useMemo(
    () =>
      prefectures.find(p => p.value === profile.user.prefecture)?.label ?? '',
    [prefectures, profile.user.prefecture],
  );

  return (
    <div className={styles.userWrap}>
      {/* Hero Banner */}
      <div>
        <OrgHeroUser
          user={profile.user}
          prefecture={prefecture}
          manages={profile.fanclubs.manages}
          isViewEditButton={isViewEditButton}
          onEditClick={onClickEdit}
          onLinkClick={onClickManageList}
        />
      </div>

      <>
        <div className={styles.tabMenuWrap}>
          <Tab
            text="フォロー"
            cssClass={styles.tabMenuItem}
            type={tabMode === 'follow' ? 'Tertiary' : 'Quaternary'}
            onClick={() => {
              onClickTab('follow');
            }}
          />
          <Tab
            text="いいね"
            type={tabMode === 'like' ? 'Tertiary' : 'Quaternary'}
            cssClass={styles.tabMenuItem}
            onClick={() => {
              onClickTab('like');
            }}
          />
        </div>

        {tabMode === 'follow' && (
          <OrgFollowLikeFCList
            fanclubList={follows}
            onClickFanclub={onClickFanclub}
            onClickButton={onClickFollowButton}
            isViewReadMoreButton={isViewReadMoreButton}
            onReadMore={onClickReadMore}
            cssClass={styles.OrgFollowLikeFCList}
          />
        )}

        {tabMode === 'like' && (
          <div className={styles.OrgTimeLine}>
            <OrgTimeLIne
              isTabView={false}
              contents={likes}
              isViewFanclub
              isViewReadMoreButton={isViewReadMoreButton}
              onClickContent={onClickContent}
              onClickUser={onClickUser}
              onClickFanclub={onClickFanclub}
              onReadMore={onClickReadMore}
            />
          </div>
        )}
      </>

      <div className={styles.sectionWrap}>
        {/* おすすめコミュニティ */}
        <OrgRecommendFCList
          fanclubList={recommends}
          onClickFanclub={onClickFanclub}
        />

        {/* PICK UP */}
        <OrgPickUpFCList
          fanclubList={pickups.slice(0, 3)}
          onClickFanclub={onClickFanclub}
          cssClass={styles.contentWrap}
        />
      </div>

      {isViewManageListModal && (
        <OrgFanclubListModal
          fanclubs={profile.fanclubs.manages}
          onClickFanclub={onClickFanclub}
          onClickBlur={onClickBlur}
        />
      )}
    </div>
  );
};

export default Component;
