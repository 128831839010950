import React, { FC } from 'react';
import ClassNames from 'classnames';
import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import TitleH1 from '../common/atoms/title/TitleH1';
import CheckBox from '../common/atoms/formParts/CheckBox';
import McsTextBoxScroll from '../common/molecules/formParts/McsTextBoxScroll';
import * as styles from './description.module.scss';

interface Props {
  handleOpenFC?: () => void;
  agreed?: boolean;
  handleToggleAgreed?: () => void;
  handleBack?: () => void;
}

const READ = `下記利用規約をお読みいただき、内容に同意していただけましたら、「利用規約に同意します」チェックを押した上、「コミュニティを開設する」ボタンをクリックしてお進みください。

※未成年の方は保護者の同意が必要となります。お申込みいただいた時点で、利用規約に同意いただいたものとみなし手続きを行います。必ず保護者の同意を得た上でお進みください。`;

const DESCRIPTION = `前文
本利用規約（以下「本規約」といいます。）には、KRAPサービス（以下「本サービス」といいます。）の提供条件及び株式会社ナノ・メディア（以下「当社」といいます。」とアーティストの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。

第1条（適用）
1.	本規約は、本サービスの提供条件及び本サービスの利用に関する当社とアーティストとの間の権利義務関係を定めることを目的とし、アーティストと当社との間の本サービスの利用に関わる一切の関係に適用されます。
2.	当社が本サイト上で随時掲載する本サービスに関するルール、諸規定等は本規約の一部を構成するものとします。
3.	本規約の内容と、前項のルール、諸規定等その他本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。

第2条（定義）
KRAP利用規約に基づきます。

第3条（登録）
1.	本サービスの利用を希望する者（以下「アーティスト登録希望者」といいます。）は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」といいます。）を当社の定める方法で当社に提供することにより、当社に対し、本サービスの利用の登録を申請することができます。
2.	当社は、当社の基準に従って、第１項に基づいて登録申請を行ったアーティスト登録希望者（以下「登録申請者」といいます。）の登録の可否を判断し、当社が登録を認める場合にはその旨を登録申請者に通知します。登録申請者のアーティストとしての登録は、当社が本項の通知を行ったことをもって完了したものとします。
3.	前項に定める登録の完了時に、サービス利用契約がアーティストと当社の間に成立し、アーティストは本サービスを本規約に従い利用することができるようになります。
4.	当社は、登録申請者が、以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。
(1)	当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
(2)	未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
(3)	反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ。）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する等反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
(4)	過去当社との契約に違反した者またはその関係者であると当社が判断した場合
(5)	第10条に定める措置を受けたことがある場合
(6)	その他、登録を適当でないと当社が判断した場合
5.	有料アーティストとしての登録は、別途当社が用意した規定に沿って申請を行うことができます。

第4条（登録事項の変更）
アーティストは、登録事項に変更があった場合、当社の定める方法により当該変更事項を遅滞なく当社に通知するものとします。

第5条（パスワード及びユーザーIDの管理）
1.	アーティストは、自己の責任において、本サービスに関するパスワード及びユーザーIDを適切に管理及び保管するものとし、これを第三者に利用させ、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
2.	パスワードまたはユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任はユーザーが負うものとします。

第6条（料金及び支払方法）
1.	有料アーティストによるコミュニティの対価は、月額最低価格を300円（税別）とし、有料アーティストが独自に定めた価格（以下「月額会費」といいます。）とします。月額会費は税込金額を当社ウェブサイトに表示し、登録後は変更できません。
2.	有料フォロワーが本サービスを通して支払った月額会費は、当社が回収し、当社が指定する支払方法により有料アーティストの登録指定口座に支払うものとします。支払にかかる振込手数料は有料アーティストのご負担とさせていただきます。
3.	当社から有料アーティストに支払われる金額（以下「アーティスト分配金」といいます。) は、月額会費の60％とし、毎月１日に前月までの分配金を確定し、第４項で定める最低振込金額に達した場合に、確定した月の末日までにお支払いします。別途当社と有料アーティストとの取り決めによって料率を変更する場合は、別途契約書を締結することとします。
4.	最低振込金額は、5,000円（税込）とし、毎月１日時点で、最低振込金額に満たない場合は、翌月以降に留保します。毎月１日時点で、留保した最低振込金額の累計が5,000円（税込）に達した場合に、確定した月の末日までにお支払いいたします。
5.	口座情報不備等で、お振込みが完了できなかった場合は、アーティスト分配金を翌月以降に持越しとし、正しい口座情報の修正登録が完了した月の翌月末までにお支払いいたします。また、アーティスト起因による事情で、正常にお支払いが行われなかった場合も、振込手数料は発生し、当該金額はアーティスト分配金より控除されます。なお、口座番号の入力ミスにより第三者の口座に収益が支払われた場合、当社はアーティストに対し何らの責任も負わないものとします。
6.	アーティストが第10条第1項に従いアーティスト資格を取り消された場合、支払いはせず、収益に関する権利を放棄したものとみなします。


第7条（禁止事項）
アーティストは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。
(1)	ユーザー登録時に承諾したKRAP利用規約から逸脱した行為
(2)	当社ウェブサイト上で掲載する本サービス利用に関するルール（https://krap.me）に抵触する行為
(3)	その他、当社が不適切と判断する行為

第8条（本サービスの停止等）
KRAP利用規約に基づきます。

第9条（権利帰属）
1.	当社ウェブサイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社ウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
2.	アーティストは、投稿データについて、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことについて、当社に対し表明し、保証するものとします。
3.	アーティストは、投稿データについて、当社に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。
4.	ユーザーは、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。

第10条（登録抹消等）
1.	当社は、アーティストが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、コミュニティ・投稿データを削除もしくは非表示にし、当該アーティストについて本サービスの利用を一時的に停止し、またはユーザーとしての登録を抹消することができます。
(1)	本規約のいずれかの条項に違反した場合
(2)	登録事項に虚偽の事実があることが判明した場合
(3)	支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
(4)	【6】ヶ月以上本サービスの利用がない場合
(5)	当社からの問い合わせその他の回答を求める連絡に対して【30日】間以上応答がない場合
(6)	第3条第4項各号に該当する場合
(7)	その他、当社が本サービスの利用またはユーザーとしての登録の継続を適当でないと判断した場合
2.	前項各号のいずれかの事由に該当した場合、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
3.	コミュニティを閉鎖・削除した場合（アーティスト・当社どちらが行った場合でも）コミュニティの再開、復帰はできません。

第11条（コミュニティの移譲）
1.	アーティストは作成したコミュニティの移譲を行うことができます。
2.	コミュニティの移譲は、ユーザー間の責任において執り行うことができます。移譲に関して当社は関与いたしません。
3.	有料アーティストの作成した有料コミュニティの移譲に関しては、別途当社と協議の上で執り行います。

第12条（退会）
アーティストは、管理しているコミュニティの管理者の移譲または、コミュニティの閉鎖を行わなければ、退会できません。

第13条（本サービスの内容の変更、終了）
KRAP利用規約に基づきます。

第14条（保証の否認及び免責）
1.	当社は、本サービスがアーティストの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、アーティストによる本サービスの利用がアーティストに適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。
2.	当社は、本サービスに関してアーティストが被った損害につき、一切の賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害についても、賠償する責任を負わないものとします。
3.	本サービスまたは当社ウェブサイトに関連してアーティストと他のユーザーまたは第三者との間において生じた取引、連絡、紛争等については、アーティストが自己の責任によって解決するものとします。
4.	アーティストは、本サービスまたはコミュニティの利用に関して、当社に損害を与えた場合、当社が被った一切の損害を賠償するものとします。

第15条（秘密保持）
KRAP利用規約に基づきます。

第16条（利用者情報の取扱い）
1.	当社によるアーティストの利用者情報の取扱いについては、別途当社プライバシーポリシー（https://krap.me/privacypolicy）の定めによるものとし、アーティストはこのプライバシーポリシーに従って当社がアーティストの利用者情報を取扱うことについて同意するものとします。
2.	当社は、アーティストが当社に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当社の裁量で、利用及び公開することができるものとし、アーティストはこれに異議を唱えないものとします。
3.	当社は、ユーザー記述情報を本サービスの提供及び利用促進に必要な範囲において、無償で、当社サーバーへの複製、フォロワー及びアーティストに対する公衆送信その他の方法により利用することができるものとします。ただし、裁判所等の公的機関により法令又は令状等に基づき開示請求がなされる場合及び当社の業務上必要とされる場合等正当な理由に基づく場合を除き、フォロワー間又はアーティストとフォロワー間においてやりとりされる情報のうち、閲覧できるフォロワー又はアーティストが特定された情報を、当社、第三者（当該フォロワー又は当該アーティストが閲覧を許可した第三者を除きます。）が閲覧することはありません。なお、ユーザー記述情報に含まれる著作権は当社に譲渡されるものではありません。

第17条（本規約等の変更）
当社は、当社が必要と認めた場合は、本規約を変更できるものとします。本規約を変更する場合、変更後の本規約の施行時期及び内容を当社ウェブサイト上での掲示その他の適切な方法により周知し、またはアーティストに通知します。但し、法令上アーティストの同意が必要となるような内容の変更の場合は、当社所定の方法でアーティストの同意を得るものとします。

第18条（連絡／通知）
KRAP利用規約に基づきます。

第19条（サービス利用契約上の地位の譲渡等）
KRAP利用規約に基づきます。

第20条（分離可能性）
KRAP利用規約に基づきます。

第21条（準拠法及び管轄裁判所）
KRAP利用規約に基づきます。`;

const Component: FC<Props> = ({
  handleOpenFC = () => undefined,
  agreed = false,
  handleToggleAgreed = () => undefined,
  handleBack = () => undefined,
}) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      <TitleH1 text="コミュニティを作ろう" cssClass={styles.title} />

      <div className={styles.read}>
        <p>{READ}</p>
      </div>

      <McsTextBoxScroll
        value={DESCRIPTION}
        labelTitle="利用規約"
        readonly
        cssClass={styles.textArea}
      />

      <CheckBox
        labelText="利用規約に同意します"
        checked={agreed}
        onChange={handleToggleAgreed}
      />

      <div className={styles.buttonWrap}>
        <ButtonRoundedCorners
          text="コミュニティを開設する"
          type={agreed ? 'Primary' : 'Quaternary'}
          size="Primary"
          onClick={() => {
            if (!agreed) return;

            handleOpenFC();
          }}
          cssClass={styles.button}
        />
        <ButtonRoundedCorners
          text="戻る"
          type="Quinary"
          size="Primary"
          onClick={handleBack}
          cssClass={styles.cancel}
        />
      </div>
    </div>
  );
};

export default Component;
