import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './CheckBoxButton.module.scss';

interface Props {
  value?: string;
  type?: 'Primary' | 'Secondary';
  labelText?: string;
  inputName?: string | undefined;
  cssClass?: string;
  onChange?: () => void;
  checked?: boolean;
  isDisabled?: boolean;
}

const CheckBox: FC<Props> = ({
  value = '',
  type = 'Primary',
  labelText = 'Label',
  inputName = undefined,
  cssClass = '',
  onChange = () => undefined,
  checked = false,
  isDisabled = false,
}) => {
  const classNames = ClassNames(styles.CheckBoxBase, cssClass, {
    [styles.CheckBoxPrimary]:
      type === 'Primary' && checked === true && 'Primary', // Primary
    [styles.CheckBoxSecondary]:
      type === 'Primary' && checked === false && 'Secondary', // Secondary
    [styles.CheckBoxTertiary]:
      type === 'Secondary' && checked === true && 'Tertiary', // Tertiary
    [styles.CheckBoxQuaternary]:
      type === 'Secondary' && checked === false && 'Quaternary', // Quaternary
  });

  return (
    <div className={classNames}>
      <label htmlFor={inputName}>
        <input
          onChange={onChange}
          type="checkbox"
          name={inputName}
          value={value}
          checked={checked}
          disabled={isDisabled}
        />
        <div>
          <span> {labelText} </span>
        </div>
      </label>
    </div>
  );
};

export default CheckBox;
