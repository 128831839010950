import { Reducer } from 'redux';

import { Actions } from '../actions/newsActions';
import * as ActionType from '../actions/newsConstants';

import { Pager, PagerInitialValue } from '../interfaces/commonInterFace';
import { Notification } from '../interfaces/newsInterFace';

type State = {
  notifications: Notification[];
  pager: Pager;
  ready: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
};

export const initialState: State = {
  notifications: [],
  pager: PagerInitialValue,
  ready: false,
  loading: false,
  success: false,
  error: false,
};

const newsReducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get notification list
    case ActionType.GET_NOTIFICATION_LIST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_NOTIFICATION_LIST_SUCCEED: {
      const { data: notifications, ...pager } = action.payload.result;

      return {
        ...state,
        notifications,
        pager,
        loading: false,
      };
    }
    case ActionType.GET_NOTIFICATION_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // mark as read
    case ActionType.MARK_AS_READ_START: {
      return {
        ...state,
        error: false,
      };
    }
    case ActionType.MARK_AS_READ_SUCCEED: {
      const { notificationId } = action.payload.params;

      return {
        ...state,
        notifications: state.notifications.map(n =>
          n.id !== notificationId
            ? n
            : {
                ...n,
                pivot: { ...n.pivot, is_read: 1 },
              },
        ),
      };
    }
    case ActionType.MARK_AS_READ_FAIL: {
      return {
        ...state,
      };
    }

    // mark all as read
    case ActionType.MARK_ALL_AS_READ_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.MARK_ALL_AS_READ_SUCCEED: {
      return {
        ...state,
        loading: false,
        notifications: state.notifications.map(n => ({
          ...n,
          pivot: { ...n.pivot, is_read: 1 },
        })),
      };
    }
    case ActionType.MARK_ALL_AS_READ_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default newsReducer;
