import * as ActionType from './reissuePasswordResetPasswordConstants';
import {
  ResetPasswordParams,
  ResetPasswordErrors,
} from '../interfaces/passwordInterFace';

export const resetPasswordEvent = {
  start: (params: ResetPasswordParams) =>
    ({
      type: ActionType.RESET_PASSWORD_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.RESET_PASSWORD_SUCCEED,
    } as const),

  fail: (errors: ResetPasswordErrors) =>
    ({
      type: ActionType.RESET_PASSWORD_FAIL,
      payload: { errors },
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // reset password
  | ReturnType<typeof resetPasswordEvent.start>
  | ReturnType<typeof resetPasswordEvent.succeed>
  | ReturnType<typeof resetPasswordEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
