/* eslint-disable no-useless-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import { submitContactEvent, cleanup } from '../../actions/contactActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { validateEmail } from '../../utils/validationHelper';
import {
  ContactType,
  SubmitContactParams,
  SubmitContactValidationErrors,
} from '../../interfaces/contactInterFace';

import { SelectField } from '../../interfaces/utilInterFace';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import Contact from '../../components/nav/contact';

const ContactContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [contactType, setContactType] = useState<ContactType>(0);
  const [params, setParams] = useState<SubmitContactParams>({
    fanclub_id: '0',
    name: '',
    email: '',
    description: '',
  });
  const {
    ready: authReady,
    profile: {
      fanclubs: { follows },
      user,
    },
  } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const { loading, errors, success } = useSelector(
    selector.contact.getContactState,
  );
  const { contact_type } = useSelector(selector.util.getSelectFields);
  const fanclubList: SelectField = useMemo(
    () =>
      follows.map(fanclub => ({
        value: fanclub.id,
        label: fanclub.name,
      })),
    [follows],
  );
  const validationErrors: SubmitContactValidationErrors = {
    fanclub_id: errors?.fanclub_id?.length ? errors.fanclub_id[0] : '',
    email: errors?.email?.length
      ? errors.email[0]
      : validateEmail(params.email),
    name: errors?.name?.length ? errors.name[0] : '',
    description: errors?.description?.length ? errors.description[0] : '',
  };

  const handleChange = useCallback(
    (
      e: React.FormEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >,
    ) => {
      const { name, value } = e.currentTarget;

      const key =
        name === 'type'
          ? 'type'
          : name === 'fanclub_id'
          ? 'fanclub_id'
          : name === 'name'
          ? 'name'
          : name === 'email'
          ? 'email'
          : name === 'description'
          ? 'description'
          : '';

      if (!key) return;

      if (key === 'type') {
        setContactType(Number(value) as ContactType);

        return;
      }

      setParams(params => ({
        ...params,
        [key]: value,
      }));
    },
    [],
  );
  const handleSubmit = useCallback(() => {
    dispatch(
      submitContactEvent.start({
        ...params,
        fanclub_id: contactType === 0 ? '0' : params.fanclub_id,
      }),
    );
  }, [contactType, dispatch, params]);
  const handleCancel = useCallback(() => {
    history.goBack();
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      nav: true,
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    if (isLogin) {
      setParams(params => ({
        ...params,
        name: user.nickname,
        email: user.email,
      }));
    }

    return () => {
      dispatch(cleanup());
    };
  }, [dispatch, isLogin, user]);

  useEffect(() => {
    if (JSON.stringify(errors) === JSON.stringify({})) return;

    window.alert('入力項目を確認してください');
    window.scrollTo(0, 0);
  }, [errors]);

  useEffect(() => {
    if (success) {
      window.alert('送信しました');

      history.push(PATH.HOME);
    }
  }, [dispatch, history, success]);

  return (
    <>
      <Helmet>
        <title>ヘルプセンター | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady} />

      <Contact
        user={user}
        params={params}
        contactType={contactType}
        contactList={follows.length ? contact_type : contact_type.slice(0, 1)}
        artistList={fanclubList}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
        errors={validationErrors}
      />
    </>
  );
};

export default ContactContainer;
