/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/changeCreditCardConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  gotReady,
  getCardInfoEvent,
  updateCardInfoEvent,
} from '../actions/changeCreditCardActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import {
  GetCardInfoResponse,
  UpdateCardInfoResponse,
} from '../interfaces/cardInterFace';

// initialize
function* runGetReady() {
  const getCardInfoTask = yield fork(runGetCardInfo, getCardInfoHandler);
  yield join(getCardInfoTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get card info
const getCardInfoHandler = api.getGetFactory(true);
function* runGetCardInfo(handler: typeof getCardInfoHandler) {
  try {
    const ReturnData: GetCardInfoResponse = yield call(
      handler,
      {},
      '/user/card',
    );

    if (!ReturnData) {
      yield put(getCardInfoEvent.fail());

      return;
    }

    yield put(getCardInfoEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getCardInfoEvent.fail());
  }
}
export function* getCardInfo(handler: typeof getCardInfoHandler) {
  yield takeLatest(ActionType.GET_CARD_INFO_START, runGetCardInfo, handler);
}

// update card info
const updateCardInfoHandler = api.getPatchFactory(true);
function* runUpdateCardInfo(
  handler: typeof updateCardInfoHandler,
  action: ReturnType<typeof updateCardInfoEvent.start>,
) {
  const data = action.payload;

  try {
    const ReturnData: UpdateCardInfoResponse = yield call(
      handler,
      data,
      '/user/card',
    );

    if (!ReturnData) {
      yield put(updateCardInfoEvent.fail());

      return;
    }

    yield put(updateCardInfoEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(updateCardInfoEvent.fail());
  }
}
export function* updateCardInfo(handler: typeof updateCardInfoHandler) {
  yield takeLeading(
    ActionType.UPDATE_CARD_INFO_START,
    runUpdateCardInfo,
    handler,
  );
}

export const changeCreditCardSagas = [
  fork(getReady),
  fork(getCardInfo, getCardInfoHandler),
  fork(updateCardInfo, updateCardInfoHandler),
];
