export const GET_READY = 'ADMIN_FOLLOWERS/GET_READY' as const;
export const GOT_READY = 'ADMIN_FOLLOWERS/GOT_READY' as const;

export const GET_FANCLUB_DETAILS_START = 'ADMIN_FOLLOWERS/GET_FANCLUB_DETAILS_START' as const;
export const GET_FANCLUB_DETAILS_SUCCEED = 'ADMIN_FOLLOWERS/GET_FANCLUB_DETAILS_SUCCEED' as const;
export const GET_FANCLUB_DETAILS_FAIL = 'ADMIN_FOLLOWERS/GET_FANCLUB_DETAILS_FAIL' as const;

export const GET_FC_FOLLOWER_LIST_START = 'ADMIN_FOLLOWERS/GET_FC_FOLLOWER_LIST_START' as const;
export const GET_FC_FOLLOWER_LIST_SUCCEED = 'ADMIN_FOLLOWERS/GET_FC_FOLLOWER_LIST_SUCCEED' as const;
export const GET_FC_FOLLOWER_LIST_FAIL = 'ADMIN_FOLLOWERS/GET_FC_FOLLOWER_LIST_FAIL' as const;

export const BLOCK_FOLLOWER_START = 'ADMIN_FOLLOWERS/BLOCK_FOLLOWER_START' as const;
export const BLOCK_FOLLOWER_SUCCEED = 'ADMIN_FOLLOWERS/BLOCK_FOLLOWER_SUCCEED' as const;
export const BLOCK_FOLLOWER_FAIL = 'ADMIN_FOLLOWERS/BLOCK_FOLLOWER_FAIL' as const;

export const CLEANUP = 'ADMIN_FOLLOWERS/CLEANUP' as const;
