/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLeading,
  takeLatest,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/adminAdministratorConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getCurrentBankEvent,
  getCurrentBankBranchEvent,
  getAdministratorEvent,
  getBankAccountEvent,
  getAddressEvent,
  searchBankEvent,
  searchBankBranchEvent,
  updateAdministratorEvent,
  createBankAccountEvent,
  updateBankAccountEvent,
} from '../actions/adminAdministratorActions';

import { Status, ErrorResponse } from '../interfaces/commonInterFace';
import {
  GetAdministratorResponse,
  UpdateAdministratorResponse,
  UpdateAdministratorErrorResponse,
} from '../interfaces/administratorInterFace';
import {
  GetBankResponse,
  GetBankBranchResponse,
  GetBankAccountResponse,
  SearchBankResponse,
  SearchBankBranchResponse,
  RegisterBankAccountResponse,
  RegisterBankAccountErrorResponse,
} from '../interfaces/bankInterFace';
import { GetAddressResponse } from '../interfaces/addressInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId } = action.payload;

  const getBankAccountTask = yield fork(
    runGetBankAccount,
    getBankAccountHandler,
    getBankAccountEvent.start({ artistId }),
  );
  yield join(getBankAccountTask);

  const getAdministratorTask = yield fork(
    runGetAdministrator,
    getAdministratorHandler,
    getAdministratorEvent.start({ artistId }),
  );
  yield join(getAdministratorTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get current bank
const getCurrentBankHandler = api.getBankFactory();
function* runGetCurrentBank(
  handler: typeof getCurrentBankHandler,
  action: ReturnType<typeof getCurrentBankEvent.start>,
) {
  const { bankCode } = action.payload;

  try {
    const ReturnData: GetBankResponse = yield call(
      handler,
      {},
      `/banks/${bankCode}.json`,
    );

    if (!ReturnData) {
      yield put(getCurrentBankEvent.fail());

      return;
    }

    yield put(getCurrentBankEvent.succeed(ReturnData));
  } catch (error) {
    yield put(getCurrentBankEvent.fail());
  }
}
export function* getCurrentBank(handler: typeof getCurrentBankHandler) {
  yield takeLatest(
    ActionType.GET_CURRENT_BANK_START,
    runGetCurrentBank,
    handler,
  );
}

// get current bank branch
const getCurrentBankBranchHandler = api.getBankFactory();
function* runGetCurrentBankBranch(
  handler: typeof getCurrentBankBranchHandler,
  action: ReturnType<typeof getCurrentBankBranchEvent.start>,
) {
  const { bankCode, branchCode } = action.payload;

  try {
    const ReturnData: GetBankBranchResponse = yield call(
      handler,
      {},
      `/banks/${bankCode}/branches/${branchCode}.json`,
    );

    if (!ReturnData) {
      yield put(getCurrentBankBranchEvent.fail());

      return;
    }

    yield put(getCurrentBankBranchEvent.succeed(ReturnData));
  } catch (error) {
    yield put(getCurrentBankBranchEvent.fail());
  }
}
export function* getCurrentBankBranch(
  handler: typeof getCurrentBankBranchHandler,
) {
  yield takeLatest(
    ActionType.GET_CURRENT_BANK_BRANCH_START,
    runGetCurrentBankBranch,
    handler,
  );
}

// get administrator
const getAdministratorHandler = api.getGetFactory(true);
function* runGetAdministrator(
  handler: typeof getAdministratorHandler,
  action: ReturnType<typeof getAdministratorEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetAdministratorResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/representation`,
    );

    if (!ReturnData) {
      yield put(getAdministratorEvent.fail());

      return;
    }

    yield put(getAdministratorEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(getAdministratorEvent.fail());

      return;
    }

    yield put(getAdministratorEvent.fail());
  }
}
export function* getAdministrator(handler: typeof getAdministratorHandler) {
  yield takeLatest(
    ActionType.GET_ADMINISTRATOR_START,
    runGetAdministrator,
    handler,
  );
}

// get bank account
const getBankAccountHandler = api.getGetFactory(true);
function* runGetBankAccount(
  handler: typeof getBankAccountHandler,
  action: ReturnType<typeof getBankAccountEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetBankAccountResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/account`,
    );

    if (!ReturnData) {
      yield put(getBankAccountEvent.fail());

      return;
    }

    yield put(getBankAccountEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(getBankAccountEvent.fail());

      return;
    }

    yield put(getBankAccountEvent.fail());
  }
}
export function* getBankAccount(handler: typeof getBankAccountHandler) {
  yield takeLatest(
    ActionType.GET_BANK_ACCOUNT_START,
    runGetBankAccount,
    handler,
  );
}

// get address
const getAddressHandler = api.getAddressFactory();
function* runGetAddress(
  handler: typeof getAddressHandler,
  action: ReturnType<typeof getAddressEvent.start>,
) {
  const data = action.payload;

  try {
    const ReturnData: GetAddressResponse = yield call(
      handler,
      data,
      '/api/json',
    );

    if (!ReturnData) {
      yield put(getAddressEvent.fail());

      return;
    }

    if (!ReturnData.response.location) {
      yield put(getAddressEvent.fail());

      return;
    }

    yield put(getAddressEvent.succeed(ReturnData.response.location));
  } catch (error) {
    yield put(getAddressEvent.fail());
  }
}
export function* getAddress(handler: typeof getAddressHandler) {
  yield takeLeading(ActionType.GET_ADDRESS_START, runGetAddress, handler);
}

// search bank
const searchBankHandler = api.getBankFactory();
function* runSearchBank(
  handler: typeof searchBankHandler,
  action: ReturnType<typeof searchBankEvent.start>,
) {
  const data = action.payload;

  try {
    const ReturnData: SearchBankResponse = yield call(
      handler,
      data,
      '/banks/search.json',
    );

    if (!ReturnData) {
      yield put(searchBankEvent.fail());

      return;
    }

    yield put(searchBankEvent.succeed(ReturnData));
  } catch (error) {
    yield put(searchBankEvent.fail());
  }
}
export function* searchBank(handler: typeof searchBankHandler) {
  yield takeLatest(ActionType.SEARCH_BANK_START, runSearchBank, handler);
}

// search bank branch
const searchBankBranchHandler = api.getBankFactory();
function* runSearchBankBranch(
  handler: typeof searchBankBranchHandler,
  action: ReturnType<typeof searchBankBranchEvent.start>,
) {
  const { code, ...data } = action.payload;

  try {
    const ReturnData: SearchBankBranchResponse = yield call(
      handler,
      data,
      `/banks/${code}/branches/search.json`,
    );

    if (!ReturnData) {
      yield put(searchBankBranchEvent.fail());

      return;
    }

    yield put(searchBankBranchEvent.succeed(ReturnData));
  } catch (error) {
    yield put(searchBankBranchEvent.fail());
  }
}
export function* searchBankBranch(handler: typeof searchBankBranchHandler) {
  yield takeLatest(
    ActionType.SEARCH_BANK_BRANCH_START,
    runSearchBankBranch,
    handler,
  );
}

// update administrator
const updateAdministratorHandler = api.getFormDataFactory('PATCH');
function* runUpdateAdministrator(
  handler: typeof updateAdministratorHandler,
  action: ReturnType<typeof updateAdministratorEvent.start>,
) {
  const { artistId, ...data } = action.payload;

  try {
    const ReturnData: UpdateAdministratorResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/representation`,
    );

    if (!ReturnData) {
      yield put(updateAdministratorEvent.fail({}));

      return;
    }

    // 管理者情報更新後、現在の管理者情報を更新
    yield put(getAdministratorEvent.start({ artistId }));

    yield put(updateAdministratorEvent.succeed());
  } catch (error) {
    const res = error.response.data as UpdateAdministratorErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(updateAdministratorEvent.fail(res.errors));

      return;
    }

    yield put(updateAdministratorEvent.fail({}));
  }
}
export function* updateAdministrator(
  handler: typeof updateAdministratorHandler,
) {
  yield takeLeading(
    ActionType.UPDATE_ADMINISTRATOR_START,
    runUpdateAdministrator,
    handler,
  );
}

// create bank account
const createBankAccountHandler = api.getPostFactory(true);
function* runCreateBankAccount(
  handler: typeof createBankAccountHandler,
  action: ReturnType<typeof createBankAccountEvent.start>,
) {
  const { artistId, ...data } = action.payload;

  try {
    const ReturnData: RegisterBankAccountResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/account`,
    );

    if (!ReturnData) {
      yield put(createBankAccountEvent.fail(action.payload, {}));

      return;
    }

    // カード作成後、現在のカード情報を更新
    yield put(getBankAccountEvent.start({ artistId }));

    yield put(createBankAccountEvent.succeed());
  } catch (error) {
    const res = error.response.data as RegisterBankAccountErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(createBankAccountEvent.fail(action.payload, res.errors));

      return;
    }

    yield put(createBankAccountEvent.fail(action.payload, {}));
  }
}
export function* createBankAccount(handler: typeof createBankAccountHandler) {
  yield takeLeading(
    ActionType.CREATE_BANK_ACCOUNT_START,
    runCreateBankAccount,
    handler,
  );
}

// update bank account
const updateBankAccountHandler = api.getPatchFactory(true);
function* runUpdateBankAccount(
  handler: typeof updateBankAccountHandler,
  action: ReturnType<typeof updateBankAccountEvent.start>,
) {
  const { artistId, ...data } = action.payload;

  try {
    const ReturnData: RegisterBankAccountResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/account`,
    );

    if (!ReturnData) {
      yield put(updateBankAccountEvent.fail(action.payload, {}));

      return;
    }

    // カード更新後、現在のカード情報を更新
    yield put(getBankAccountEvent.start({ artistId }));

    yield put(updateBankAccountEvent.succeed());
  } catch (error) {
    const res = error.response.data as RegisterBankAccountErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(updateBankAccountEvent.fail(action.payload, res.errors));

      return;
    }

    yield put(updateBankAccountEvent.fail(action.payload, {}));
  }
}
export function* updateBankAccount(handler: typeof updateBankAccountHandler) {
  yield takeLeading(
    ActionType.UPDATE_BANK_ACCOUNT_START,
    runUpdateBankAccount,
    handler,
  );
}

export const adminAdministratorSagas = [
  fork(getReady),
  fork(getCurrentBank, getCurrentBankHandler),
  fork(getCurrentBankBranch, getCurrentBankBranchHandler),
  fork(getAdministrator, getAdministratorHandler),
  fork(getBankAccount, getBankAccountHandler),
  fork(getAddress, getAddressHandler),
  fork(searchBank, searchBankHandler),
  fork(searchBankBranch, searchBankBranchHandler),
  fork(updateAdministrator, updateAdministratorHandler),
  fork(createBankAccount, createBankAccountHandler),
  fork(updateBankAccount, updateBankAccountHandler),
];
