/* eslint-disable no-alert */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import ButtonFooterNav from '../../atoms/buttonBasic/ButtonFooterNav';

import * as styles from './McsFooterNav.module.scss';

type Props = {
  onClickAbout?: () => void;
  onClickUserAgreement?: () => void;
  onClickTransactions?: () => void;
  onClickPrivacyPolicy?: () => void;
  onClickFAQ?: () => void;
  onClickRule?: () => void;
  onClickOperatingCompany?: () => void;
  onClickContact?: () => void;

  cssClass?: string;
};

const McsFooterNav: FC<Props> = ({
  onClickAbout = () => undefined,
  onClickUserAgreement = () => undefined,
  onClickTransactions = () => undefined,
  onClickPrivacyPolicy = () => undefined,
  onClickFAQ = () => undefined,
  onClickRule = () => undefined,
  onClickOperatingCompany = () => undefined,
  onClickContact = () => undefined,

  cssClass = '',
}) => {
  const classNames = ClassNames(styles.McsFooterNavBase, cssClass);

  return (
    <nav>
      <ul className={classNames}>
        <li>
          <ButtonFooterNav text="KRAPについて" onClick={onClickAbout} />
        </li>
        <li>
          <ButtonFooterNav text="利用規約" onClick={onClickUserAgreement} />
        </li>
        <li>
          <ButtonFooterNav
            text="特商法に基づく表記"
            onClick={onClickTransactions}
          />
        </li>
        <li>
          <ButtonFooterNav
            text="プライバシーポリシー"
            onClick={onClickPrivacyPolicy}
          />
        </li>
        <li>
          <ButtonFooterNav text="よくあるご質問" onClick={onClickFAQ} />
        </li>
        <li>
          <ButtonFooterNav
            text="ルール/レギュレーション"
            onClick={onClickRule}
          />
        </li>
        <li>
          <ButtonFooterNav text="運営会社" onClick={onClickOperatingCompany} />
        </li>
        <li>
          <ButtonFooterNav text="お問い合わせ" onClick={onClickContact} />
        </li>
      </ul>
    </nav>
  );
};

export default McsFooterNav;
