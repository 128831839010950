/* eslint-disable import/no-cycle */
import { User, SimpleUser } from './userInterFace';
import { Status, ErrorResponse, Pager } from './commonInterFace';

/**
 * コメントしたユーザがKRAP退会済みの場合、
 * ユーザ情報はnullで返却される
 */
export type Comment = {
  id: number;
  comment: string;
  user_id: number | null;
  content_id: number;
  created_at: string;
  updated_at: string;
  user: SimpleUser | null;
};
export const CommentInitialValue: Comment = {
  id: 0,
  comment: '',
  user_id: null,
  content_id: 0,
  created_at: '',
  updated_at: '',
  user: null,
};
/**
 * コメントしたユーザがKRAP退会済みの場合、
 * ユーザ情報はnullで返却される
 */
export type CommentDetails = Comment & {
  user: User | null;
};
export const CommentDetailsInitialValue: CommentDetails = {
  ...CommentInitialValue,
  user: null,
};

export type GetCommentListParams = {
  group_id: string;
  page?: number;
  limit?: number;
};
export type GetCommentListResult = Pager & {
  data: CommentDetails[];
};

export type GetCommentListResponse = {
  status_code: Status.OK;
  result: GetCommentListResult;
};

export type SubmitCommentParams = {
  content_id: number;
  comment: string;
  user: SimpleUser;
};
export type SubmitCommentResult = {
  id: number;
  comment: string;
  user_id: number;
  name_id: string;
  content_id: number;
  created_at: string;
  updated_at: string;
};
export type SubmitCommentErrors = {
  [key in keyof Partial<SubmitCommentParams>]: string[];
};
export type SubmitCommentResponse = {
  status_code: Status.OK;
  result: SubmitCommentResult;
};
export type SubmitCommentErrorResponse = ErrorResponse<
  keyof SubmitCommentParams
>;

export type DeleteCommentParams = {
  commentId: number;
};
export type DeleteCommentResponse = {
  status_code: Status.OK;
  message: string;
};
