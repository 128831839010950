/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/paidjoinConstants';
import { checkAndSetStatusError } from './authSagas';
import { getUserProfileEvent as updateMe } from '../actions/authActions';
import {
  getReady as getReadyStart,
  gotReady,
  getFanclubDetailsEvent,
  getCardInfoEvent,
  paidjoinFanclubEvent,
} from '../actions/paidjoinActions';
import { ErrorResponse } from '../interfaces/commonInterFace';
import {
  GetFanclubDetailsResponse,
  PaidjoinFanclubResponse,
} from '../interfaces/fanclubInterFace';
import { GetCardInfoResponse } from '../interfaces/cardInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId } = action.payload;

  const getFanclubDetailsTask = yield fork(
    runGetFanclubDetails,
    getFanclubDetailsHandler,
    getFanclubDetailsEvent.start({ artistId }),
  );
  yield join(getFanclubDetailsTask);

  const getCardInfoTask = yield fork(runGetCardInfo, getCardInfoHandler);
  yield join(getCardInfoTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get fanclub details
const getFanclubDetailsHandler = api.getGetFactory(true);
function* runGetFanclubDetails(
  handler: typeof getFanclubDetailsHandler,
  action: ReturnType<typeof getFanclubDetailsEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetFanclubDetailsResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}`,
    );

    if (!ReturnData) {
      yield put(getFanclubDetailsEvent.fail());

      return;
    }

    yield put(getFanclubDetailsEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFanclubDetailsEvent.fail());
  }
}
export function* getFanclubDetails(handler: typeof getFanclubDetailsHandler) {
  yield takeLatest(
    ActionType.GET_FANCLUB_DETAILS_START,
    runGetFanclubDetails,
    handler,
  );
}

// get card info
const getCardInfoHandler = api.getGetFactory(true);
function* runGetCardInfo(handler: typeof getCardInfoHandler) {
  try {
    const ReturnData: GetCardInfoResponse = yield call(
      handler,
      {},
      '/user/card',
    );

    if (!ReturnData) {
      yield put(getCardInfoEvent.fail());

      return;
    }

    yield put(getCardInfoEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getCardInfoEvent.fail());
  }
}
export function* getCardInfo(handler: typeof getCardInfoHandler) {
  yield takeLatest(ActionType.GET_CARD_INFO_START, runGetCardInfo, handler);
}

// paidjoin fanclub
const paidjoinFanclubHandler = api.getPostFactory(true);
function* runPaidjoinFanclub(
  handler: typeof paidjoinFanclubHandler,
  action: ReturnType<typeof paidjoinFanclubEvent.start>,
) {
  const { artistId, ...data } = action.payload;

  try {
    const ReturnData: PaidjoinFanclubResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/paidjoin`,
    );

    if (!ReturnData) {
      yield put(paidjoinFanclubEvent.fail());

      return;
    }

    yield put(updateMe.start());

    yield put(paidjoinFanclubEvent.succeed(ReturnData.created_at));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(paidjoinFanclubEvent.fail());
  }
}
export function* paidjoinFanclub(handler: typeof paidjoinFanclubHandler) {
  yield takeLeading(
    ActionType.PAIDJOIN_FANCLUB_START,
    runPaidjoinFanclub,
    handler,
  );
}

export const paidjoinSagas = [
  fork(getReady),
  fork(getFanclubDetails, getFanclubDetailsHandler),
  fork(getCardInfo, getCardInfoHandler),
  fork(paidjoinFanclub, paidjoinFanclubHandler),
];
