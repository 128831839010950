import React, { FC } from 'react';

import ReactH5AudioPlayer, { RHAP_UI } from 'react-h5-audio-player';
import './OrgAudioPlayer.scss';
import * as styles from './OrgAudioPlayer.module.scss';
import ButtonMedia from '../../atoms/buttonUtile/ButtonMedia';

type Props = {
  src?: string;
};

const OrgAudioPlayer: FC<Props> = ({ src = '' }) => {
  return (
    <ReactH5AudioPlayer
      src={src}
      // src="https://www.soundhelix.com/examples/mp3/SoundHelix-Song-4.mp3"
      layout="horizontal-reverse"
      showJumpControls={false}
      customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
      autoPlayAfterSrcChange={false}
      customProgressBarSection={[
        RHAP_UI.CURRENT_TIME,
        <div style={{ margin: '0 4px' }}>/</div>,
        RHAP_UI.DURATION,
        RHAP_UI.PROGRESS_BAR,
      ]}
      customIcons={{
        play: (
          <ButtonMedia
            type="PlaySound"
            cssClass={styles.OrgAudioPlayerButton}
          />
        ),
        pause: (
          <ButtonMedia
            type="PauseSound"
            cssClass={styles.OrgAudioPlayerButton}
          />
        ),
      }}
    />
  );
};

export default OrgAudioPlayer;
