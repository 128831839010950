/* eslint-disable object-shorthand */
/* eslint-disable no-useless-return */
/* eslint-disable react/button-has-type */
import React, { FC, useRef, useState, useEffect } from 'react';
import ClassNames from 'classnames';
import * as styles from './McsInputComment.module.scss';

interface Props {
  value: string;
  placeholder?: string;
  cssClass?: string;
  onSendEvent?: () => void;
  onChange?: (e: React.FormEvent<HTMLTextAreaElement>, key?: string) => void;
  onChangeHeight?: (height: number) => void;
}

const McsInput: FC<Props> = ({
  value = '',
  placeholder = '',
  cssClass = '',
  onSendEvent = () => undefined,
  onChange = () => undefined,
  onChangeHeight = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsInputBase, cssClass);

  const [height, setHeight] = useState(26);
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    if (!value) {
      setHeight(26);
      onChangeHeight(26);

      return;
    }

    setHeight(ref.current.scrollHeight - 4);
    onChangeHeight(ref.current.scrollHeight - 4);
  }, [onChangeHeight, value]);

  const submitColor = ClassNames({
    [styles.SubmitButtonGray]: value.trim().length === 0, // 未入力時
    [styles.SubmitButtonGrade]: value.trim().length !== 0, // 入力時
  });

  return (
    <div className={classNames}>
      <div
        className={styles.McsInputForm}
        style={{
          height: height,
          maxHeight: 148,
        }}
      >
        <textarea
          ref={ref}
          rows={1}
          name=""
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </div>
      <div className={styles.McsInputButton}>
        <button onClick={onSendEvent}>
          <span className={submitColor}>送信</span>
        </button>
      </div>
    </div>
  );
};

export default McsInput;
