import * as ActionType from './adminCategoryEditConstants';
import {
  GetFCCategoryListParams,
  GetFCCategoryListResult,
  AddFCCategoryParams,
  AddFCCategoryResult,
  AddFCCategoryErrors,
  // UpdateFCCategoryParams,
  // UpdateFCCategoryResult,
  // UpdateFCCategoryErrors,
  DeleteFCCategoryParams,
} from '../interfaces/categoryInterFace';

export const getReady = (artistId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getFCCategoryListEvent = {
  start: (params: GetFCCategoryListParams) =>
    ({
      type: ActionType.GET_FC_CATEGORY_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetFCCategoryListResult) =>
    ({
      type: ActionType.GET_FC_CATEGORY_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FC_CATEGORY_LIST_FAIL,
    } as const),
};

export const addFCCategoryEvent = {
  start: (params: AddFCCategoryParams) =>
    ({
      type: ActionType.ADD_FC_CATEGORY_START,
      payload: params,
    } as const),

  succeed: (params: AddFCCategoryParams, result: AddFCCategoryResult) =>
    ({
      type: ActionType.ADD_FC_CATEGORY_SUCCEED,
      payload: { params, result },
    } as const),

  fail: (errors: AddFCCategoryErrors) =>
    ({
      type: ActionType.ADD_FC_CATEGORY_FAIL,
      payload: { errors },
    } as const),
};

export const deleteFCCategoryEvent = {
  start: (params: DeleteFCCategoryParams) =>
    ({
      type: ActionType.DELETE_FC_CATEGORY_START,
      payload: params,
    } as const),

  succeed: (params: DeleteFCCategoryParams) =>
    ({
      type: ActionType.DELETE_FC_CATEGORY_SUCCEED,
      payload: { params },
    } as const),

  fail: () =>
    ({
      type: ActionType.DELETE_FC_CATEGORY_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fc category list
  | ReturnType<typeof getFCCategoryListEvent.start>
  | ReturnType<typeof getFCCategoryListEvent.succeed>
  | ReturnType<typeof getFCCategoryListEvent.fail>
  // add fc category
  | ReturnType<typeof addFCCategoryEvent.start>
  | ReturnType<typeof addFCCategoryEvent.succeed>
  | ReturnType<typeof addFCCategoryEvent.fail>
  // delete fc category
  | ReturnType<typeof deleteFCCategoryEvent.start>
  | ReturnType<typeof deleteFCCategoryEvent.succeed>
  | ReturnType<typeof deleteFCCategoryEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
