import * as ActionType from './artistConstants';
import {
  GetFanclubDetailsParams,
  GetFanclubDetailsResult,
  GetPickupFanclubListResult,
  GetRecommendFanclubListParams,
  FreejoinFanclubParams,
  WithdrawFanclubParams,
  startParamWithChat,
} from '../interfaces/fanclubInterFace';
import {
  GetContentListParams,
  GetContentListResult,
} from '../interfaces/contentInterFace';

export const getReady = (param: startParamWithChat) =>
  ({
    type: ActionType.GET_READY,
    payload: param,
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getFanclubDetailsEvent = {
  start: (params: GetFanclubDetailsParams) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetFanclubDetailsResult) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_FAIL,
    } as const),
};

export const getContentListEvent = {
  start: (params: GetContentListParams) =>
    ({
      type: ActionType.GET_CONTENT_LIST_START,
      payload: params,
    } as const),

  succeed: (params: GetContentListParams, result: GetContentListResult) =>
    ({
      type: ActionType.GET_CONTENT_LIST_SUCCEED,
      payload: { params, result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_CONTENT_LIST_FAIL,
    } as const),
};

export const getRecommendFanclubListEvent = {
  start: (params: GetRecommendFanclubListParams) =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetPickupFanclubListResult) =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_RECOMMEND_FANCLUB_LIST_FAIL,
    } as const),
};

export const freejoinFanclubEvent = {
  start: (params: FreejoinFanclubParams) =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_FAIL,
    } as const),
};

export const withdrawFanclubEvent = {
  start: (params: WithdrawFanclubParams) =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_FAIL,
    } as const),
};

export const getUnreadChatEvent = {
  start: (artistId: string) =>
    ({
      type: ActionType.GET_UNREAD_CHAT_START,
      payload: artistId,
    } as const),

  succeed: (unreadChatCount: number) =>
    ({
      type: ActionType.GET_UNREAD_CHAT_SUCCEED,
      payload: unreadChatCount,
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_UNREAD_CHAT_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fanclub details
  | ReturnType<typeof getFanclubDetailsEvent.start>
  | ReturnType<typeof getFanclubDetailsEvent.succeed>
  | ReturnType<typeof getFanclubDetailsEvent.fail>
  // get content list
  | ReturnType<typeof getContentListEvent.start>
  | ReturnType<typeof getContentListEvent.succeed>
  | ReturnType<typeof getContentListEvent.fail>
  // get fanclub list
  | ReturnType<typeof getRecommendFanclubListEvent.start>
  | ReturnType<typeof getRecommendFanclubListEvent.succeed>
  | ReturnType<typeof getRecommendFanclubListEvent.fail>
  // freejoin fanclub
  | ReturnType<typeof freejoinFanclubEvent.start>
  | ReturnType<typeof freejoinFanclubEvent.succeed>
  | ReturnType<typeof freejoinFanclubEvent.fail>
  // withdraw fanclub
  | ReturnType<typeof withdrawFanclubEvent.start>
  | ReturnType<typeof withdrawFanclubEvent.succeed>
  | ReturnType<typeof withdrawFanclubEvent.fail>
  // チャットの未読件数取得
  | ReturnType<typeof getUnreadChatEvent.start>
  | ReturnType<typeof getUnreadChatEvent.succeed>
  | ReturnType<typeof getUnreadChatEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
