/* eslint-disable no-shadow */
import React, { FC, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  freejoinFanclubEvent,
  cleanup,
} from '../../actions/followArtistActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { FanclubWithUserInfo as FanclubWithUserType } from '../../interfaces/fanclubInterFace';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import FollowArtist from '../../components/signup/followArtist';

const FollowArtistContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    ready: authReady,
    profile: {
      fanclubs: { follows, manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const { ready, recommendFanclubs: recommends } = useSelector(
    selector.followArtist.getFollowArtistState,
  );
  const fanclubs: FanclubWithUserType[] = useMemo(
    () =>
      recommends.map(fanclub => ({
        ...fanclub,
        followed: !!follows.find(f => f.id === fanclub.id),
        paid: follows.find(f => f.id === fanclub.id)?.pivot?.type === 1,
        managed: !!manages.find(f => f.id === fanclub.id),
      })),
    [follows, manages, recommends],
  );

  const handleFollow = useCallback(
    (artistId: string) => {
      const followed = follows.find(f => f.group_id === artistId);

      if (!followed) dispatch(freejoinFanclubEvent.start({ artistId }));
    },
    [dispatch, follows],
  );
  const handleStartKRAP = useCallback(() => {
    history.push(PATH.HOME);
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!isLogin) {
      history.push(PATH.SIGNUP);

      return;
    }

    dispatch(getReady());
  }, [authReady, dispatch, history, isLogin]);

  return (
    <>
      <Helmet>
        <title>アーティストをフォローしよう | KRAP</title>
      </Helmet>

      <OrgLoader isLoaded={authReady && ready} />

      <FollowArtist
        fanclubList={fanclubs}
        onFollowClick={handleFollow}
        onStartKrapEvent={handleStartKRAP}
      />
    </>
  );
};

export default FollowArtistContainer;
