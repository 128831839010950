/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/editUserProfileConstants';
import {
  runGetUserProfile,
  getUserProfileHandler,
  checkAndSetStatusError,
} from './authSagas';
import { getUserProfileEvent as updateMe } from '../actions/authActions';
import {
  gotReady,
  updateUserProfileEvent,
} from '../actions/editUserProfileActions';

import { Status } from '../interfaces/commonInterFace';
import {
  UpdateUserProfileResponse,
  UpdateUserProfileErrorResponse,
} from '../interfaces/userInterFace';

// initialize
function* runGetReady() {
  const updateMeTask = yield fork(runGetUserProfile, getUserProfileHandler);
  yield join(updateMeTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// update user profile
const updateUserProfileHandler = api.getFormDataFactory('PATCH');
function* runUpdateUserProfile(
  handler: typeof updateUserProfileHandler,
  action: ReturnType<typeof updateUserProfileEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: UpdateUserProfileResponse = yield call(
      handler,
      data,
      '/user/profile',
    );

    if (!ReturnData) {
      yield put(updateUserProfileEvent.fail({}));

      return;
    }

    // update user self profile
    yield put(updateMe.start());

    yield put(updateUserProfileEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as UpdateUserProfileErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(updateUserProfileEvent.fail(res.errors));

      return;
    }

    yield put(updateUserProfileEvent.fail({}));
  }
}
export function* updateUserProfile(handler: typeof updateUserProfileHandler) {
  yield takeLeading(
    ActionType.UPDATE_USER_PROFILE_START,
    runUpdateUserProfile,
    handler,
  );
}

export const editUserProfileSagas = [
  fork(getReady),
  fork(updateUserProfile, updateUserProfileHandler),
];
