import React, { FC } from 'react';
import ClassNames from 'classnames';

import TitleH3 from '../../atoms/title/TitleH3';

import OrgFCListUnitQuaternary from '../listUnitParts/OrgFCListUnitQuaternary';

import { Fanclub } from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgRecommendFCList.module.scss';

type Props = {
  fanclubList?: Partial<Fanclub>[];
  onClickFanclub?: (artistId: string) => void;
  cssClass?: string;
};

const OrgFCList: FC<Props> = ({
  fanclubList = [],
  onClickFanclub = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListBase, cssClass);

  return (
    <div className={classNames}>
      <TitleH3 text="おすすめコミュニティ" cssClass={styles.OrgFCListTitle} />
      <ul className={styles.OrgFCListWrap}>
        {fanclubList.map((item, i) => (
          <OrgFCListUnitQuaternary
            key={`RecommendListItem${i.toString()}`}
            fanclub={item}
            onClick={() => onClickFanclub(item.group_id ?? '')}
          />
        ))}
      </ul>
    </div>
  );
};

export default OrgFCList;
