import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './ButtonFullWidth.module.scss';

interface Props {
  text: string;
  type?: string;
  size?: string;
  cssClass?: string;
  onClick?: () => void;
}

const Button: FC<Props> = ({
  text = 'Button',
  type = 'Primary',
  size = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.ButtonBase, cssClass, {
    [styles.ButtonPrimary]: type === 'Primary', // Primary
    [styles.ButtonSecondary]: type === 'Secondary', // Secondary
    [styles.ButtonTertiary]: type === 'Tertiary', // Tertiary
    [styles.ButtonQuaternary]: type === 'Quaternary', // Quaternary
    [styles.ButtonQuinary]: type === 'Quinary', // Quinary
    [styles.ButtonSenary]: type === 'Senary', // Senary

    [styles.ButtonSizePrimary]: size === 'Primary', // Primary
    [styles.ButtonSizeSecondary]: size === 'Secondary', // Secondary
    [styles.ButtonSizeTertiary]: size === 'Tertiary', // Tertiary
  });

  return (
    <div className={classNames}>
      <button type="button" onClick={onClick}>
        <span>{text}</span>
      </button>
    </div>
  );
};

export default Button;
