/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/userConstants';
import { checkAndSetStatusError } from './authSagas';
import { getUserProfileEvent as updateMe } from '../actions/authActions';
import {
  getReady as getReadyStart,
  gotReady,
  getUserProfileEvent,
  getFollowingFanclubListEvent,
  getLikedContentListEvent,
  getPickupFanclubListEvent,
  getRecommendFanclubListEvent,
  freejoinFanclubEvent,
  withdrawFanclubEvent,
} from '../actions/userActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import { GetUserProfileResponse } from '../interfaces/userInterFace';
import {
  GetPickupFanclubListResponse,
  GetFollowingFanclubListResponse,
  FreejoinFanclubResponse,
  WithdrawFanclubResponse,
} from '../interfaces/fanclubInterFace';
import { GetLikedContentListResponse } from '../interfaces/contentInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { userId } = action.payload;

  const getUserProfileTask = yield fork(
    runGetUserProfile,
    getUserProfileHandler,
    getUserProfileEvent.start({ userId }),
  );
  yield join(getUserProfileTask);

  yield put(gotReady());

  yield put(getRecommendFanclubListEvent.start({ scope: 'recommend' }));
  yield put(getPickupFanclubListEvent.start({ scope: 'pickup' }));
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get user profile
const getUserProfileHandler = api.getGetFactory(true);
function* runGetUserProfile(
  handler: typeof getUserProfileHandler,
  action: ReturnType<typeof getUserProfileEvent.start>,
) {
  const { userId } = action.payload;
  try {
    const ReturnData: GetUserProfileResponse = yield call(
      handler,
      {},
      `/user/profile/${userId}`,
    );

    if (!ReturnData) {
      yield put(getUserProfileEvent.fail());

      return;
    }

    yield put(getUserProfileEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getUserProfileEvent.fail());
  }
}
export function* getUserProfile(handler: typeof getUserProfileHandler) {
  yield takeLatest(
    ActionType.GET_USER_PROFILE_START,
    runGetUserProfile,
    handler,
  );
}

// get following fanclub list
const getFollowingFanclubListHandler = api.getGetFactory(true);
function* runGetFollowingFanclubList(
  handler: typeof getFollowingFanclubListHandler,
  action: ReturnType<typeof getFollowingFanclubListEvent.start>,
) {
  const { userId, ...data } = action.payload;
  try {
    const ReturnData: GetFollowingFanclubListResponse = yield call(
      handler,
      data,
      `/user/${userId}/following-fanclub`,
    );

    if (!ReturnData) {
      yield put(getFollowingFanclubListEvent.fail());

      return;
    }

    yield put(
      getFollowingFanclubListEvent.succeed(action.payload, ReturnData.result),
    );
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFollowingFanclubListEvent.fail());
  }
}
export function* getFollowingFanclubList(
  handler: typeof getFollowingFanclubListHandler,
) {
  yield takeLatest(
    ActionType.GET_FOLLOWING_FANCLUB_LIST_START,
    runGetFollowingFanclubList,
    handler,
  );
}

// get liked content list
const getLikedContentListHandler = api.getGetFactory(true);
function* runGetLikedContentList(
  handler: typeof getLikedContentListHandler,
  action: ReturnType<typeof getLikedContentListEvent.start>,
) {
  const { userId, ...data } = action.payload;
  try {
    const ReturnData: GetLikedContentListResponse = yield call(
      handler,
      data,
      `/like/${userId}`,
    );

    if (!ReturnData) {
      throw new Error('something wrong');
    }

    yield put(
      getLikedContentListEvent.succeed(action.payload, ReturnData.result),
    );
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getLikedContentListEvent.fail());
  }
}
export function* getLikedContentList(
  handler: typeof getLikedContentListHandler,
) {
  yield takeLatest(
    ActionType.GET_LIKED_CONTENT_LIST_START,
    runGetLikedContentList,
    handler,
  );
}

// get pickup fanclub list
const getPickupFanclubListHandler = api.getGetFactory(true);
function* runGetPickupFanclubList(
  handler: typeof getPickupFanclubListHandler,
  action: ReturnType<typeof getPickupFanclubListEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: GetPickupFanclubListResponse = yield call(
      handler,
      data,
      '/fanclub',
    );

    if (!ReturnData) {
      yield put(getPickupFanclubListEvent.fail());

      return;
    }

    yield put(getPickupFanclubListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getPickupFanclubListEvent.fail());
  }
}
export function* getPickupFanclubList(
  handler: typeof getPickupFanclubListHandler,
) {
  yield takeLatest(
    ActionType.GET_PICKUP_FANCLUB_LIST_START,
    runGetPickupFanclubList,
    handler,
  );
}

// get recommend fanclub list
const getRecommendFanclubListHandler = api.getGetFactory(true);
function* runGetRecommendFanclubList(
  handler: typeof getRecommendFanclubListHandler,
  action: ReturnType<typeof getRecommendFanclubListEvent.start>,
) {
  const data = action.payload;
  try {
    const ReturnData: GetPickupFanclubListResponse = yield call(
      handler,
      data,
      '/fanclub',
    );

    if (!ReturnData) {
      yield put(getRecommendFanclubListEvent.fail());

      return;
    }

    yield put(getRecommendFanclubListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getRecommendFanclubListEvent.fail());
  }
}
export function* getRecommendFanclubList(
  handler: typeof getRecommendFanclubListHandler,
) {
  yield takeLatest(
    ActionType.GET_RECOMMEND_FANCLUB_LIST_START,
    runGetRecommendFanclubList,
    handler,
  );
}

// freejoin fanclub
const freejoinFanclubHandler = api.getPostFactory(true);
function* runFreejoinFanclub(
  handler: typeof freejoinFanclubHandler,
  action: ReturnType<typeof freejoinFanclubEvent.start>,
) {
  const { artistId } = action.payload;
  try {
    const ReturnData: FreejoinFanclubResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/freejoin`,
    );

    if (!ReturnData) {
      yield put(freejoinFanclubEvent.fail());

      return;
    }

    // update user profile
    yield put(updateMe.start());

    yield put(freejoinFanclubEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    window.alert('フォローに失敗しました');

    yield put(freejoinFanclubEvent.fail());
  }
}
export function* freejoinFanclub(handler: typeof freejoinFanclubHandler) {
  yield takeLeading(
    ActionType.FREEJOIN_FANCLUB_START,
    runFreejoinFanclub,
    handler,
  );
}

// withdraw fanclub
const withdrawFanclubHandler = api.getPostFactory(true);
function* runWithdrawFanclub(
  handler: typeof withdrawFanclubHandler,
  action: ReturnType<typeof withdrawFanclubEvent.start>,
) {
  const { artistId } = action.payload;
  try {
    const ReturnData: WithdrawFanclubResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/withdraw`,
    );

    if (!ReturnData) {
      yield put(withdrawFanclubEvent.fail());

      return;
    }

    // update user profile
    yield put(updateMe.start());

    yield put(withdrawFanclubEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    window.alert('フォロー解除に失敗しました');

    yield put(withdrawFanclubEvent.fail());
  }
}
export function* withdrawFanclub(handler: typeof withdrawFanclubHandler) {
  yield takeLeading(
    ActionType.WITHDRAW_FANCLUB_START,
    runWithdrawFanclub,
    handler,
  );
}

export const userSagas = [
  fork(getReady),
  fork(getUserProfile, getUserProfileHandler),
  fork(getFollowingFanclubList, getFollowingFanclubListHandler),
  fork(getLikedContentList, getLikedContentListHandler),
  fork(getPickupFanclubList, getPickupFanclubListHandler),
  fork(getRecommendFanclubList, getRecommendFanclubListHandler),
  fork(freejoinFanclub, freejoinFanclubHandler),
  fork(withdrawFanclub, withdrawFanclubHandler),
];
