/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import Linkify from 'react-linkify';
import ClassNames from 'classnames';
import McsPhotoListUnit from '../../molecules/listUnitParts/McsPhotoListUnit';
import * as styles from './OrgContentBlog.module.scss';

interface Props {
  cssClass?: string;
  body?: string;
  photo?: string;
  photos?: string[];
  onClickPhotoModalOpen?: () => void;
}

const OrgContentBlog: FC<Props> = ({
  cssClass = '',
  body = '',
  photo = '',
  photos = [],
  onClickPhotoModalOpen = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgContentBlogBase, cssClass);

  // iframeが入っている場合の処理
  const pattern = /(<iframe[^>]*><\/iframe>)/g;
  const content = body.split(pattern);
  const createMarkup = (text: string) => ({ __html: text });

  return (
    <div className={classNames}>
      <div className={styles.OrgContentBlogBodyWrapper}>
        <div className={styles.OrgContentBlogBody}>
          <Linkify
            componentDecorator={(decoratedHref, decoratedText, key) => (
              <a target="blank" href={decoratedHref} key={key}>
                {decoratedText}
              </a>
            )}
          >
            {content.map(text => (
              <React.Fragment key={text}>
                {text.match(/<iframe/) ? (
                  <div dangerouslySetInnerHTML={createMarkup(text)} />
                ) : (
                  <>{text}</>
                )}
              </React.Fragment>
            ))}
          </Linkify>
        </div>
      </div>
      {photo && (
        <img className={styles.OrgContentBlogThumbnail} src={photo} alt="" />
      )}
      {photos && (
        <McsPhotoListUnit
          photos={photos}
          cssClass={styles.OrgContentBlogPhotos}
          onClick={onClickPhotoModalOpen}
        />
      )}
    </div>
  );
};

export default OrgContentBlog;
