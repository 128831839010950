/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import LabelTitleSeries from '../../atoms/label/LabelTitleSeries';
import IconArrow from '../../atoms/iconParts/IconArrow';
import IconShape from '../../atoms/iconParts/IconShape';
import Badge from '../../atoms/badge/Badge';

import * as styles from './OrgAdminListMenuUnit.module.scss';

interface Props {
  cssClass?: string;
  iconType?: string | undefined;
  mainTitle?: string;
  subTitle?: string;
  count?: number;
  onLinkClick?: () => void;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  iconType = undefined,
  mainTitle = '',
  subTitle = '',
  count = 0,
  onLinkClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgAdminListMenuUnitBase, cssClass);

  return (
    <li className={classNames} onClick={onLinkClick}>
      <div className={styles.OrgAdminListMenuUnitMenu}>
        {iconType ? (
          <>
            {count > 0 ? (
              <div className={styles.OrgAdminListMenuUnitIconWithBadge}>
                <IconShape type={iconType} />
                <Badge count={count} />
              </div>
            ) : (
              <IconShape
                type={iconType}
                cssClass={styles.OrgAdminListMenuUnitIcon}
              />
            )}
          </>
        ) : (
          <div className={styles.OrgAdminListMenuUnitIcon} />
        )}
        <LabelTitleSeries
          mainText={mainTitle}
          subText={subTitle}
          type="Quaternary"
        />
        <IconArrow cssClass={styles.OrgAdminListMenuUnitArrow} />
      </div>
    </li>
  );
};

export default OrgListUnit;
