import React, { FC } from 'react';
import ClassNames from 'classnames';
import TitleH2 from '../common/atoms/title/TitleH2';
import ButtonSquare from '../common/atoms/buttonBasic/ButtonSquare';
import * as styles from './sendEmail.module.scss';

interface Props {
  onReissuePassword?: () => void;
}

const Component: FC<Props> = ({ onReissuePassword = () => undefined }) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      <TitleH2 text="メールの送信が完了しました" cssClass={styles.title} />
      <div className={styles.read}>
        <p>
          ご登録いただいているメールアドレスに、メールを送信しました。
          <br />
          送信されたメールに記載されているURLより、パスワードの再設定をお願いします。
        </p>
      </div>

      <div className={styles.note}>
        <p>
          メールが届かない方は、再度パスワード再発行画面よりお試しください。
        </p>
      </div>

      <div className={styles.buttonWrap}>
        <ButtonSquare
          text="パスワード再発行"
          type="Quaternary"
          size="Primary"
          onClick={onReissuePassword}
        />
      </div>
    </div>
  );
};

export default Component;
