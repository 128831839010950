/* eslint-disable no-bitwise */
import React, { FC, useMemo } from 'react';
import ClassNames from 'classnames';
import ButtonMedia from '../../atoms/buttonUtile/ButtonMedia';

import * as styles from './OrgSoundPlayer.module.scss';

interface Props {
  cssClass?: string;
  duration?: number;
}

const OrgListUnit: FC<Props> = ({ cssClass = '', duration = 0 }) => {
  const classNames = ClassNames(styles.OrgSoundPlayerBase, cssClass);

  const time = useMemo(
    () =>
      `${((duration / 60) | 0).toString().padStart(2, '0')}:${(
        duration % 60 |
        0
      )
        .toString()
        .padStart(2, '0')}`,
    [duration],
  );

  return (
    <div className={classNames}>
      <ButtonMedia type="PlaySound" cssClass={styles.OrgSoundPlayerButton} />
      <div className={styles.OrgSoundPlayerTime}>00:00 / {time}</div>
      <div className={styles.OrgSoundPlayerSeek}>
        <ButtonMedia
          type="SeekSound"
          cssClass={styles.OrgSoundPlayerSeekButton}
        />
      </div>
    </div>
  );
};

export default OrgListUnit;
