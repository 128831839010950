import React, { FC, Fragment } from 'react';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';
import CheckBoxButton from '../common/atoms/formParts/CheckBoxButton';
import InputFileBox from '../common/atoms/formParts/InputFileBox';
import TitleH1 from '../common/atoms/title/TitleH1';
import LabelTitle from '../common/atoms/title/TitleDiv';

import McsInputSecondary from '../common/molecules/formParts/McsInputSecondary';
import McsSelectSecondary from '../common/molecules/formParts/McsSelectSecondary';
import McsTextAreaSecondary from '../common/molecules/formParts/McsTextAreaSecondary';
import McsSelectBirthSecondary from '../common/molecules/formParts/McsSelectBirthSecondary';

import * as styles from './register.module.scss';

import { SelectField } from '../../interfaces/utilInterFace';
import {
  CreateFanclubInputParams,
  CreateFanclubInputParamsInitialValue,
  CreateFanclubValidationErrors,
  CreateFanclubValidationErrorsInitialValue,
} from '../../interfaces/fanclubInterFace';
import { GenreWithSelected } from '../../interfaces/genreInterFace';

type Props = {
  fanclub?: CreateFanclubInputParams;
  previewCoverImage?: string;
  previewIconImage?: string;
  genres?: GenreWithSelected[][];
  onChange?: (
    e: React.FormEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key?: string,
  ) => void;
  onChangeImage?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  onChangeGenre?: (genreId: number) => void;
  onBack?: () => void;
  onSubmit?: () => void;
  prefectureList?: SelectField;
  yearList?: SelectField;
  monthList?: SelectField;
  dateList?: SelectField;
  errors?: CreateFanclubValidationErrors;
  canSubmit?: boolean;
};

const Component: FC<Props> = ({
  fanclub = CreateFanclubInputParamsInitialValue,
  genres = [],
  onChange = () => undefined,
  onChangeImage = () => undefined,
  onChangeGenre = () => undefined,
  onBack = () => undefined,
  onSubmit = () => undefined,
  prefectureList = [],
  yearList = [],
  monthList = [],
  dateList = [],
  errors = CreateFanclubValidationErrorsInitialValue,
  canSubmit = false,
}) => {
  return (
    <div className={styles.containerWrap}>
      <TitleH1 text="アーティスト情報の入力" cssClass={styles.title} />
      <div className={styles.fileBox}>
        <InputFileBox
          inputName="icon_image"
          inputNameHero="cover_image"
          preview={fanclub.icon_image.preview}
          previewHero={fanclub.cover_image.preview}
          onChange={onChangeImage}
          isError={!!errors.cover_image || !!errors.icon_image}
          errorTextHero={errors.cover_image}
          errorText={errors.icon_image}
        />
      </div>
      <McsInputSecondary
        value={fanclub.group_id}
        inputType="text"
        inputName="group_id"
        placeholder="グループIDを入力"
        labelTitle="グループID（半角英数）"
        cssClass={styles.inputBox}
        maxLength={20}
        onChange={onChange}
        labelTag="変更不可"
        isError={!!errors.group_id}
        errorText={errors.group_id}
      />

      <McsInputSecondary
        value={fanclub.name}
        inputType="text"
        inputName="name"
        placeholder="アーティスト名を入力"
        labelTitle="アーティスト名"
        cssClass={styles.inputBox}
        onChange={onChange}
        isError={!!errors.name}
        errorText={errors.name}
      />

      <McsInputSecondary
        value={fanclub.name_kana}
        inputType="text"
        inputName="name_kana"
        placeholder="読み仮名を入力"
        labelTitle="読み仮名（ひらがな）"
        cssClass={styles.inputBox}
        onChange={onChange}
        isError={!!errors.name_kana}
        errorText={errors.name_kana}
      />

      <McsInputSecondary
        value={fanclub.site_name}
        inputType="text"
        inputName="site_name"
        placeholder="コミュニティ名を入力"
        labelTitle="コミュニティ名"
        cssClass={styles.inputBox}
        maxLength={50}
        onChange={onChange}
        isError={!!errors.site_name}
        errorText={errors.site_name}
      />

      <McsSelectSecondary
        value={fanclub.prefecture.toString()}
        inputName="prefecture"
        labelTitle="活動拠点（都道府県）"
        placeholder="選択してください"
        selectList={prefectureList}
        cssClass={styles.inputBox}
        onChange={onChange}
        isError={!!errors.prefecture}
        errorText={errors.prefecture}
      />

      <McsSelectBirthSecondary
        value1={fanclub.formation_on_year.toString()}
        value2={fanclub.formation_on_month.toString()}
        value3={fanclub.formation_on_date.toString()}
        inputName1="formation_on_year"
        inputName2="formation_on_month"
        inputName3="formation_on_date"
        placeholder1="年"
        placeholder2="月"
        placeholder3="日"
        labelTitle="結成（年月日）"
        selectList1={yearList}
        selectList2={monthList}
        selectList3={dateList}
        cssClass={styles.inputBox}
        onChange={onChange}
        isError={!!errors.formation_on}
        errorText={errors.formation_on}
      />

      <div className={styles.inputBox}>
        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 8 }}>
          <LabelTitle text="音楽ジャンル" />
          {!!errors.genres && (
            <div style={{ marginLeft: '8px' }}>
              <span className={styles.errorText}>{errors.genres}</span>
            </div>
          )}
        </div>
        {genres.map((group, i) => (
          <Fragment key={`genre-${i.toString()}`}>
            <hr className={styles.hr} />
            {group.map((genre, j) => (
              <CheckBoxButton
                key={`genre-${i.toString()}-${j.toString()}`}
                checked={genre.selected}
                labelText={genre.name}
                onChange={() => onChangeGenre(genre.id)}
                cssClass={styles.buttonMini}
              />
            ))}
          </Fragment>
        ))}
      </div>

      <McsTextAreaSecondary
        value={fanclub.description}
        TextAreaName="description"
        placeholder="紹介文を入力"
        labelTitle="コミュニティ紹介文"
        cssClass={styles.inputBox}
        onChange={onChange}
        isError={!!errors.description}
        errorText={errors.description}
      />

      <McsInputSecondary
        value={fanclub.site_url}
        inputType="text"
        inputName="site_url"
        placeholder="URLを入力"
        labelTitle="ホームページ"
        cssClass={styles.inputBox}
        onChange={onChange}
        isError={!!errors.site_url}
        errorText={errors.site_url}
      />

      <McsInputSecondary
        value={fanclub.twitter_url}
        inputType="text"
        inputName="twitter_url"
        placeholder="TwitterIDを入力"
        labelTitle="Twitterアカウント"
        cssClass={styles.inputBox}
        onChange={onChange}
        isError={!!errors.twitter_url}
        errorText={errors.twitter_url}
      />

      <McsInputSecondary
        value={fanclub.facebook_url}
        inputType="text"
        inputName="facebook_url"
        placeholder="FacebookIDを入力"
        labelTitle="Facebookアカウント"
        cssClass={styles.inputBox}
        onChange={onChange}
        isError={!!errors.facebook_url}
        errorText={errors.facebook_url}
      />

      <McsInputSecondary
        value={fanclub.instagram_url}
        inputType="text"
        inputName="instagram_url"
        placeholder="InstagramIDを入力"
        labelTitle="instagramアカウント"
        cssClass={styles.inputBox}
        onChange={onChange}
        isError={!!errors.instagram_url}
        errorText={errors.instagram_url}
      />

      <div className={styles.buttonWrap}>
        <ButtonRoundedCorners
          text="コミュニティを開設する"
          type={canSubmit ? 'Primary' : 'Quaternary'}
          size="Primary"
          onClick={onSubmit}
          cssClass={styles.button}
        />
        <ButtonRoundedCorners
          text="戻る"
          type="Quinary"
          size="Primary"
          onClick={onBack}
          cssClass={styles.cancel}
        />
      </div>
    </div>
  );
};

export default Component;
