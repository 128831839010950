import React, { FC } from 'react';
import ClassNames from 'classnames';

import {
  StripeCardNumberElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
} from '@stripe/stripe-js';
import { User, UserInitialValue } from '../../interfaces/userInterFace';
import { StripeInputErrors } from '../../interfaces/stripeInterFace';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import McsTitlePrimary from '../common/molecules/titleParts/McsTitlePrimary';

import OrgCanUseCardInfo from '../common/organisms/paidjoin/OrgCanUseCardInfo';
import OrgCardInput from '../common/organisms/paidjoin/OrgCardInput';

import * as styles from './changeCreditCard.module.scss';

interface Props {
  user?: User;
  name?: string;
  onChangeCardInfo?: (
    e:
      | StripeCardNumberElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | StripeCardExpiryElementChangeEvent,
  ) => void;
  onChangeName?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  onBack?: () => void;
  onSubmit?: () => void;
  canSubmit?: boolean;
  cardErrors?: StripeInputErrors;
}

const Component: FC<Props> = ({
  user = UserInitialValue,
  name = '',
  onChangeCardInfo = () => undefined,
  onChangeName = () => undefined,
  onBack = () => undefined,
  onSubmit = () => undefined,
  canSubmit = false,
  cardErrors = {},
}) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      <McsTitlePrimary
        mainText="クレジットカード変更"
        subText={`@${user.name_id}`}
        onClick={onBack}
        cssClass={styles.title}
      />

      <form>
        <div className={styles.read}>
          <p>
            新しいクレジットカード情報を入力し、「クレジットカード変更」ボタンをタップしてください。
          </p>
        </div>

        <OrgCanUseCardInfo
        // cssClass={styles.cardInfo}
        />

        <OrgCardInput
          name={name}
          onChangeCardInfo={onChangeCardInfo}
          onChangeName={onChangeName}
          errors={cardErrors}
        />

        <div className={styles.buttonWrap}>
          <ButtonRoundedCorners
            text="クレジットカード変更"
            type={canSubmit ? 'Primary' : 'Quaternary'}
            size="Primary"
            onClick={onSubmit}
            cssClass={styles.button}
          />
          <ButtonRoundedCorners
            text="キャンセル"
            type="Quinary"
            size="Primary"
            onClick={onBack}
            cssClass={styles.cancel}
          />
        </div>
      </form>
    </div>
  );
};

export default Component;
