import React, { FC } from 'react';
import ReactGA from 'react-ga';

// eslint-disable-next-line import/no-named-as-default
import ErrorBoundary from './components/common/errorBoundary';
import Auth from './Auth';
import ContentRouter from './routers/ContentRouter';

// Google Analyticsの初期化
if (process.env.NODE_ENV !== 'development') {
  const trackingId = process.env.REACT_APP_API_TRACKING_ID || '';
  ReactGA.initialize(trackingId);
}

const App: FC = () => {
  return (
    <ErrorBoundary>
      <Auth>
        <ContentRouter />
      </Auth>
    </ErrorBoundary>
  );
};

export default App;
