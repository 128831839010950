import { Reducer } from 'redux';
import { Actions } from '../actions/authActions';
import * as ActionType from '../actions/authConstants';
import { Status } from '../interfaces/commonInterFace';
import {
  UserProfile,
  UserProfileInitialValue,
} from '../interfaces/userInterFace';

type State = {
  access_token: string;
  profile: UserProfile;
  unread_count: number;
  ready: boolean;
  loading: boolean;
  error: boolean | Status.Unauthorized | Status.Forbidden | Status.NotFound;
  success: boolean;
};

export const initialState: State = {
  access_token: '',
  profile: UserProfileInitialValue,
  unread_count: 0,
  ready: false,
  loading: false,
  error: false,
  success: false,
};

const authReducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // set auth token
    case ActionType.SET_ACCESS_TOKEN: {
      return {
        ...state,
        access_token: action.payload,
      };
    }

    // set auth error
    case ActionType.SET_AUTH_ERROR: {
      return {
        ...state,
        error: action.payload,
      };
    }

    // login
    case ActionType.LOGIN_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.LOGIN_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ActionType.LOGIN_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // logout
    case ActionType.LOGOUT_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.LOGOUT_SUCCEED: {
      return {
        ...initialState,
        ready: state.ready,
      };
    }
    case ActionType.LOGOUT_FAIL: {
      return {
        ...initialState,
        ready: state.ready,
      };
    }

    // get user profile
    case ActionType.GET_USER_PROFILE_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_USER_PROFILE_SUCCEED: {
      return {
        ...state,
        profile: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_USER_PROFILE_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // get unread count
    case ActionType.GET_UNREAD_COUNT_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_UNREAD_COUNT_SUCCEED: {
      return {
        ...state,
        unread_count: action.payload,
        loading: false,
      };
    }
    case ActionType.GET_UNREAD_COUNT_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
        ready: state.ready,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default authReducer;
