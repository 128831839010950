/* eslint-disable no-nested-ternary */
import React, { FC, useMemo } from 'react';
import ClassNames from 'classnames';
import { Card, CardInitialValue } from '../../../../interfaces/cardInterFace';

import VisaCard from '../../assets/images/card-visa.svg';
import MasterCard from '../../assets/images/card-master.svg';
import AmexCard from '../../assets/images/card-amex.svg';
import JCBCard from '../../assets/images/card-jcb.svg';
import DinersCard from '../../assets/images/card-diners.svg';

import * as styles from './OrgCardInfo.module.scss';

interface Props {
  card?: Card;
  cssClass?: string;
}

const OrgCardInfo: FC<Props> = ({ card = CardInitialValue, cssClass = '' }) => {
  const classNames = ClassNames(styles.base, cssClass);

  const expiry = useMemo(
    () =>
      `${card.exp_month
        .toString()
        .padStart(2, '0')}/${card.exp_year.toString().padStart(4, '0')}`,

    [card.exp_year, card.exp_month],
  );

  const cardImage = useMemo(
    () =>
      card.brand === 'Visa'
        ? VisaCard
        : card.brand === 'MasterCard'
        ? MasterCard
        : card.brand === 'American Express'
        ? AmexCard
        : card.brand === 'JCB'
        ? JCBCard
        : card.brand === 'Diners Club'
        ? DinersCard
        : '',
    [card.brand],
  );

  return (
    <div className={classNames}>
      <p className={styles.label}>カード番号（半角数字）</p>
      <div className={styles.cardNumberContainer}>
        <span className={styles.text}>
          **** **** **** {card.last_four || '0000'}
        </span>
        <img src={cardImage} alt="card" />
      </div>
      <p className={styles.label}>有効期限（月/年）</p>
      <p className={styles.text}>{expiry}</p>
      <p className={styles.label}>カード名義（半角）</p>
      <p className={styles.text}>{card.name || 'NAME'}</p>
    </div>
  );
};

export default OrgCardInfo;
