import { Reducer } from 'redux';
import { Actions } from '../actions/newsDetailsActions';
import * as ActionType from '../actions/newsDetailsConstants';
import {
  NewsDetails,
  NewsDetailsInitialValue,
} from '../interfaces/newsInterFace';

type State = {
  news: NewsDetails;
  ready: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
};

export const initialState: State = {
  news: NewsDetailsInitialValue,
  ready: false,
  loading: false,
  success: false,
  error: false,
};

const commentsReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get news details
    case ActionType.GET_NEWS_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_NEWS_DETAILS_SUCCEED: {
      return {
        ...state,
        news: action.payload.result.news,
        loading: false,
      };
    }
    case ActionType.GET_NEWS_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default commentsReducer;
