/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';
import ButtonFullWidth from '../common/atoms/buttonBasic/ButtonFullWidth';
import Tab from '../common/atoms/tab/Tab';

import McsSelectSecondary from '../common/molecules/formParts/McsSelectSecondary';
import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';

import OrgAdminListTimeLineUnit from '../common/organisms/listUnitParts/OrgAdminListTimeLineUnit';

import * as styles from './adminContents.module.scss';

import { SelectField } from '../../interfaces/utilInterFace';
import { User, UserInitialValue } from '../../interfaces/userInterFace';
import {
  Fanclub,
  FanclubInitialValue,
} from '../../interfaces/fanclubInterFace';
import {
  AdminContentsTabList,
  ContentType,
  Content,
} from '../../interfaces/contentInterFace';

type Props = {
  user?: User;
  fanclub?: Fanclub;
  type?: ContentType;
  createUserId?: number;
  categoryId?: number;
  memberList?: SelectField;
  categoryList?: SelectField;
  contents?: Content[];
  total?: number;

  isViewReadMoreButton?: boolean;
  onChangeTab?: (type: ContentType) => void;
  onChangeMember?: (e: React.FormEvent<HTMLSelectElement>) => void;
  onChangeCategory?: (e: React.FormEvent<HTMLSelectElement>) => void;
  onClickContent?: (content: Content) => void;
  onDelete?: (
    e: React.MouseEvent<HTMLDListElement, MouseEvent>,
    content: Content,
  ) => void;
  onReadMore?: () => void;
  onClickRegister?: () => void;
  onClickEditCategory?: () => void;
  onBack?: () => void;
};

const Component: FC<Props> = ({
  user = UserInitialValue,
  fanclub = FanclubInitialValue,
  type = 0,
  createUserId = 0,
  categoryId = 0,
  memberList = [],
  categoryList = [],
  contents = [],
  total = 0,

  isViewReadMoreButton = false,
  onChangeTab = () => undefined,
  onChangeMember = () => undefined,
  onChangeCategory = () => undefined,
  onClickContent = () => undefined,
  onDelete = () => undefined,
  onReadMore = () => undefined,
  onClickRegister = () => undefined,
  onClickEditCategory = () => undefined,
  onBack = () => undefined,
}) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      <McsTitleSecondary
        mainText="コンテンツ"
        subText={fanclub.site_name}
        onClick={onBack}
        cssClass={styles.contentTitle}
      />
      <ul className={styles.contentTabMenu}>
        {AdminContentsTabList.map(tab => (
          <Tab
            key={`Tab${tab.type}`}
            text={tab.label}
            type={type === tab.type ? 'Quinary' : 'Quaternary'}
            onClick={() => onChangeTab(tab.type)}
            cssClass={styles.contentTabMenuItem}
          />
        ))}
      </ul>

      <div className={styles.contentButtonWrap}>
        <ButtonRoundedCorners
          text="新規投稿する"
          type="Senary"
          cssClass={styles.contentButtonItem}
          onClick={onClickRegister}
        />
        <ButtonRoundedCorners
          text="カテゴリ編集"
          type="Septenary"
          cssClass={styles.contentButtonItem}
          onClick={onClickEditCategory}
        />
      </div>

      <div className={styles.contentSelectWrap}>
        <McsSelectSecondary
          value={createUserId.toString()}
          selectList={memberList}
          cssClass={styles.contentSelectItem}
          onChange={onChangeMember}
        />
        <McsSelectSecondary
          value={categoryId.toString()}
          selectList={categoryList}
          cssClass={styles.contentSelectItem}
          onChange={onChangeCategory}
        />
      </div>

      <div className={styles.contentListTitle}>{total}POSTS</div>

      {/* TimeLine */}
      <ul className={styles.contentListWrap}>
        {contents.map(content => (
          <OrgAdminListTimeLineUnit
            key={content.id}
            content={content}
            categories={
              content.categories.length
                ? content.categories.map(
                    cc =>
                      categoryList.find(c => c.value === cc.id)?.label ?? '',
                  )
                : ['未分類']
            }
            cssClass={styles.contentList}
            isViewDeleteButton={
              fanclub?.pivot?.type === 3 || user.id === content.user_id
            }
            onClick={() => onClickContent(content)}
            onDelete={e => onDelete(e, content)}
          />
        ))}
      </ul>

      {/* Read More Button */}
      {isViewReadMoreButton && (
        <ButtonFullWidth text="もっと見る" type="Senary" onClick={onReadMore} />
      )}
    </div>
  );
};

export default Component;
