import * as ActionType from './newsDetailsConstants';
import {
  GetNewsDetailsParams,
  GetNewsDetailsResult,
} from '../interfaces/newsInterFace';

export const getReady = (params: GetNewsDetailsParams) =>
  ({
    type: ActionType.GET_READY,
    payload: params,
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getNewsDetailsEvent = {
  start: (params: GetNewsDetailsParams) =>
    ({
      type: ActionType.GET_NEWS_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetNewsDetailsResult) =>
    ({
      type: ActionType.GET_NEWS_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_NEWS_DETAILS_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get news details
  | ReturnType<typeof getNewsDetailsEvent.start>
  | ReturnType<typeof getNewsDetailsEvent.succeed>
  | ReturnType<typeof getNewsDetailsEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
