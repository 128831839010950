/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC, useState } from 'react';
import ClassNames from 'classnames';
import * as styles from './IconThumbnailRound.module.scss';

import noUserImageUrl from '../../assets/images/icon-nouser.png';

interface Props {
  src?: string;
  errorSrc?: string;

  type?: string;
  size?: string;
  cssClass?: string;
  onClick?: () => void;
}

const Icon: FC<Props> = ({
  src = '',
  errorSrc = noUserImageUrl,
  type = 'Primary',
  size = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.IconBase, cssClass, {
    [styles.IconPrimary]: type === 'Primary', // Primary
    [styles.IconSecondary]: type === 'Secondary', // Secondary

    [styles.IconSizePrimary]: size === 'Primary', // Primary
    [styles.IconSizeSecondary]: size === 'Secondary', // Secondary
    [styles.IconSizeTertiary]: size === 'Tertiary', // Tertiary
    [styles.IconSizeQuaternary]: size === 'Quaternary', // Quaternary
    [styles.IconSizeQuinary]: size === 'Quinary', // Quinary
    [styles.IconSizeSenary]: size === 'Senary', // Senary
    [styles.IconSizeSeptenary]: size === 'Septenary', // Septenary
  });

  return (
    <div className={classNames} onClick={onClick}>
      <img
        src={src}
        alt=""
        onError={e => {
          e.currentTarget.src = errorSrc;
        }}
      />
    </div>
  );
};

export default Icon;
