import { Reducer } from 'redux';
import { Actions } from '../actions/reissuePasswordSendEmailActions';
import * as ActionType from '../actions/reissuePasswordSendEmailConstants';
import {} from '../interfaces/passwordInterFace';

type State = {
  loading: boolean;
  error: string;
  success: boolean;
};

export const initialState: State = {
  loading: false,
  error: '',
  success: false,
};

const reissuePasswordSendEmailReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // send email
    case ActionType.SEND_EMAIL_START: {
      return {
        ...state,
        loading: true,
        errors: {},
      };
    }
    case ActionType.SEND_EMAIL_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ActionType.SEND_EMAIL_FAIL: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default reissuePasswordSendEmailReducer;
