/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/commentsConstants';
import { checkAndSetStatusError } from './authSagas';
import { getUserProfileEvent as updateMe } from '../actions/authActions';
import {
  getReady as getReadyStart,
  gotReady,
  getContentDetailsEvent,
  submitCommentEvent,
  deleteCommentEvent,
  freejoinFanclubEvent,
} from '../actions/commentsActions';
import { Status, ErrorResponse } from '../interfaces/commonInterFace';
import { GetContentDetailsResponse } from '../interfaces/contentInterFace';
import {
  SubmitCommentResponse,
  SubmitCommentErrorResponse,
  DeleteCommentResponse,
} from '../interfaces/commentInterFace';
import { FreejoinFanclubResponse } from '../interfaces/fanclubInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { contentId } = action.payload;

  const getContentDetailsTask = yield fork(
    runGetContentDetails,
    getContentDetailsHandler,
    getContentDetailsEvent.start({ contentId }),
  );
  yield join(getContentDetailsTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get content details
const getContentDetailsHandler = api.getGetFactory(true);
function* runGetContentDetails(
  handler: typeof getContentDetailsHandler,
  action: ReturnType<typeof getContentDetailsEvent.start>,
) {
  const { contentId } = action.payload;
  try {
    const ReturnData: GetContentDetailsResponse = yield call(
      handler,
      {},
      `/contents/${contentId}`,
    );

    if (!ReturnData) {
      yield put(getContentDetailsEvent.fail());

      return;
    }

    const { result } = ReturnData;

    yield put(getContentDetailsEvent.succeed(result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getContentDetailsEvent.fail());
  }
}
export function* getContentDetails(handler: typeof getContentDetailsHandler) {
  yield takeLatest(
    ActionType.GET_CONTENT_DETAILS_START,
    runGetContentDetails,
    handler,
  );
}

// submit comment
const submitCommentHandler = api.getPostFactory(true);
function* runSubmitComment(
  handler: typeof submitCommentHandler,
  action: ReturnType<typeof submitCommentEvent.start>,
) {
  const { user: _, ...data } = action.payload;
  try {
    const ReturnData: SubmitCommentResponse = yield call(
      handler,
      data,
      '/comment',
    );

    if (!ReturnData) {
      yield put(submitCommentEvent.fail({}));

      return;
    }

    yield put(submitCommentEvent.succeed(action.payload, ReturnData.result));
  } catch (error) {
    const res = error.response.data as SubmitCommentErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(submitCommentEvent.fail(res.errors));

      return;
    }

    yield put(submitCommentEvent.fail({}));
  }
}
export function* submitComment(handler: typeof submitCommentHandler) {
  yield takeLeading(ActionType.SUBMIT_COMMENT_START, runSubmitComment, handler);
}

// delete comment
const deleteCommentHandler = api.getDeleteFactory(true);
function* runDeleteComment(
  handler: typeof deleteCommentHandler,
  action: ReturnType<typeof deleteCommentEvent.start>,
) {
  const { commentId } = action.payload;
  try {
    const ReturnData: DeleteCommentResponse = yield call(
      handler,
      {},
      `/comment/${commentId}`,
    );

    if (!ReturnData) {
      yield put(deleteCommentEvent.fail());

      return;
    }

    yield put(deleteCommentEvent.succeed(action.payload));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(deleteCommentEvent.fail());
  }
}
export function* deleteComment(handler: typeof deleteCommentHandler) {
  yield takeLeading(ActionType.DELETE_COMMENT_START, runDeleteComment, handler);
}

// freejoin fanclub
const freejoinFanclubHandler = api.getPostFactory(true);
function* runFreejoinFanclub(
  handler: typeof freejoinFanclubHandler,
  action: ReturnType<typeof freejoinFanclubEvent.start>,
) {
  const { artistId } = action.payload;
  try {
    const ReturnData: FreejoinFanclubResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/freejoin`,
    );

    if (!ReturnData) {
      yield put(freejoinFanclubEvent.fail());

      return;
    }

    // update user profile
    yield put(updateMe.start());

    yield put(freejoinFanclubEvent.succeed());
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(freejoinFanclubEvent.fail());
  }
}
export function* freejoinFanclub(handler: typeof freejoinFanclubHandler) {
  yield takeLeading(
    ActionType.FREEJOIN_FANCLUB_START,
    runFreejoinFanclub,
    handler,
  );
}

export const commentsSagas = [
  fork(getReady),
  fork(getContentDetails, getContentDetailsHandler),
  fork(submitComment, submitCommentHandler),
  fork(deleteComment, deleteCommentHandler),
  fork(freejoinFanclub, freejoinFanclubHandler),
];
