import { Status } from './commonInterFace';

/**
 * stripe-jsのinterfaceを参照
 * ./node_modules/@stripe/stripe-js/types/api/Cards.d.ts
 *
 * Card brand. Can be `American Express`, `Diners Club`, `Discover`, `JCB`, `MasterCard`, `UnionPay`, `Visa`, or `Unknown`.
 */
export type CardBrand =
  | 'American Express'
  | 'Diners Club'
  | 'Discover'
  | 'JCB'
  | 'MasterCard'
  | 'UnionPay'
  | 'Visa'
  | 'Unknown';

export type Card = {
  // id: number; // 1;
  // user_id: number; // 1
  // card_id: string; // 'card_1Gx7wMEuu1wF2AnpEbQ59Agu';
  brand: CardBrand;
  exp_month: number; // 2
  exp_year: number; // 2022
  last_four: string; // '4242'
  name: string; // 'TAROU YAMADA'
  // created_at: string | null; // '2020-06-23 17:57:18'
  // updated_at: string | null; // '2020-06-23 17:57:18'
  // deleted_at: string | null;
};

export const CardInitialValue: Card = {
  brand: 'Unknown',
  exp_month: 0,
  exp_year: 0,
  last_four: '',
  name: '',
};

export type GetCardInfoResult = Card | null;
export type GetCardInfoResponse = {
  status_code: Status.OK;
  result: GetCardInfoResult;
};

export type UpdateCardInfoParams = {
  stripeToken: string;
};
export type UpdateCardInfoResult = Card;
export type UpdateCardInfoResponse = {
  status_code: Status.OK;
  result: UpdateCardInfoResult;
};
