import { Reducer } from 'redux';
import { Actions } from '../actions/adminContentsActions';
import * as ActionType from '../actions/adminContentsConstants';

import { Pager, PagerInitialValue } from '../interfaces/commonInterFace';
import { Content } from '../interfaces/contentInterFace';
import { User } from '../interfaces/userInterFace';
import { Category } from '../interfaces/categoryInterFace';

type State = {
  members: User[];
  categories: Category[];
  contents: Content[];
  pager: Pager;
  ready: boolean;
  loading: boolean;
  error: boolean;
};

export const initialState: State = {
  members: [],
  categories: [],
  contents: [],
  pager: PagerInitialValue,
  ready: false,
  loading: false,
  error: false,
};

const adminContentsReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fc member list
    case ActionType.GET_FC_MEMBER_LIST_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_FC_MEMBER_LIST_SUCCEED: {
      return {
        ...state,
        members: action.payload.result.members.data,
        loading: false,
      };
    }
    case ActionType.GET_FC_MEMBER_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // get fc category
    case ActionType.GET_FC_CATEGORY_LIST_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_FC_CATEGORY_LIST_SUCCEED: {
      return {
        ...state,
        categories: action.payload.result.categories,
        loading: false,
      };
    }
    case ActionType.GET_FC_CATEGORY_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // get content list
    case ActionType.GET_CONTENT_LIST_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.GET_CONTENT_LIST_SUCCEED: {
      const { reset } = action.payload.params;
      const { data, ...pager } = action.payload.result;
      const contents = reset ? data : [...state.contents, ...data];

      return {
        ...state,
        contents,
        pager,
        loading: false,
      };
    }
    case ActionType.GET_CONTENT_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // delete content
    case ActionType.DELETE_CONTENT_START: {
      return {
        ...state,
        loading: true,
        error: false,
      };
    }
    case ActionType.DELETE_CONTENT_SUCCEED: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.DELETE_CONTENT_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default adminContentsReducer;
