import * as ActionType from './footerConstants';

import { Options } from '../interfaces/footerInterFace';

export const setOptions = (options: Options) =>
  ({
    type: ActionType.SET_OPTIONS,
    payload: options,
  } as const);

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // set options
  | ReturnType<typeof setOptions>
  // cleanup
  | ReturnType<typeof cleanup>;
