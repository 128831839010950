import React, { FC } from 'react';
import ClassNames from 'classnames';

import OrgFCListUnitPrimary from '../listUnitParts/OrgFCListUnitPrimary';

import { Fanclub } from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgHeaderFCList.module.scss';

type PartialFanclub = Partial<Fanclub>;
type Props = {
  fanclubList?: PartialFanclub[];
  onClick?: (artistId: string) => void;
  cssClass?: string;
};

const OrgHeaderFCList: FC<Props> = ({
  fanclubList = [],
  onClick = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgHeaderFCListBase, cssClass);

  return (
    <ul className={classNames}>
      {fanclubList.map((item, i) => (
        <OrgFCListUnitPrimary
          fanclub={item}
          key={`OrgFCListUnitPrimary${i.toString()}`}
          onClick={() => onClick(item?.group_id || '')}
        />
      ))}
    </ul>
  );
};

export default OrgHeaderFCList;
