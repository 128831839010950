/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import Icon from '../../atoms/iconThumbnail/IconThumbnailRound';
import LabelTitle from '../../atoms/label/LabelTitleParallel';
import * as styles from './McsIconLabelRound.module.scss';

interface Props {
  src?: string;
  mainText?: string;
  subText?: string;

  type?: string;
  cssClass?: string;
  onClick?: () => void;
}

const McsIconLabel: FC<Props> = ({
  src = '',
  mainText = 'Text',
  subText = 'SubText',

  type = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsIconLabelBase, cssClass, {
    [styles.McsIconLabelPrimary]: type === 'Primary', // Primary
  });

  return (
    <div className={classNames} onClick={onClick}>
      <Icon src={src} size="Senary" cssClass={styles.McsIconLabelImage} />
      <LabelTitle type="Tertiary" mainText={mainText} subText={subText} />
    </div>
  );
};

export default McsIconLabel;
