/* eslint-disable no-useless-return */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import {
  loadStripe,
  StripeCardNumberElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
} from '@stripe/stripe-js';
import {
  Elements,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  updateCardInfoEvent,
  cleanup,
} from '../../actions/changeCreditCardActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import { StripeInputErrors } from '../../interfaces/stripeInterFace';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import ChangeCreditCard from '../../components/setting/changeCreditCard';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY || '', {
  locale: 'ja',
});

const ChangeCreditCardContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [name, setName] = useState('');
  const [cardErrors, setCardErrors] = useState<StripeInputErrors>({});
  const stripe = useStripe();
  const elements = useElements();
  const {
    ready: authReady,
    profile: { user },
  } = useSelector(selector.auth.getAuthState);
  const isLogin = useSelector(selector.auth.getIsLogin);
  const { ready, hasNoCard, loading, success, error } = useSelector(
    selector.changeCreditCard.getChangeCreditCardState,
  );

  const canSubmit = useMemo(() => {
    const can =
      !cardErrors.name &&
      !cardErrors.cardNumber &&
      !cardErrors.cardExpiry &&
      !cardErrors.name &&
      !cardErrors.cardCvc;

    return can;
  }, [cardErrors]);

  // Stripeのリアルタイムバリデーション
  const handleChangeCardInfo = useCallback(
    (
      e:
        | StripeCardNumberElementChangeEvent
        | StripeCardCvcElementChangeEvent
        | StripeCardExpiryElementChangeEvent,
    ) => {
      if (
        e.elementType === 'cardNumber' &&
        !e.empty &&
        !(
          e.brand === 'visa' ||
          e.brand === 'jcb' ||
          e.brand === 'mastercard' ||
          e.brand === 'amex' ||
          e.brand === 'diners'
        )
      ) {
        setCardErrors(errors => ({
          ...errors,
          cardNumber: '使用できないカードブランドです。',
        }));

        return;
      }

      if (e.error) {
        setCardErrors(errors => ({
          ...errors,
          [e.elementType]: e.error?.message ?? '',
        }));
      } else {
        setCardErrors(errors => ({
          ...errors,
          [e.elementType]: '',
        }));
      }
    },
    [],
  );

  const handleChangeName = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      const { value } = e.currentTarget;

      if (value && !value.trim()) {
        setCardErrors(errors => ({
          ...errors,
          name: 'カード名義に不備があります。',
        }));
      } else {
        setCardErrors(errors => ({
          ...errors,
          name: '',
        }));
      }

      if (!value.match(/^[a-zA-Z0-9 ]*$/)) {
        e.preventDefault();

        return;
      }

      setName(value);
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardNumberElement);

    if (!cardElement) return;

    const result = await stripe.createToken(cardElement, { name });

    let error = false;

    const isEnteredAndUsableCard =
      !result.token?.card?.funding || result.token?.card?.funding === 'credit';

    if (!isEnteredAndUsableCard) {
      setCardErrors(errors => ({
        ...errors,
        cardNumber: 'クレジットカードのみ使用できます。',
      }));

      error = true;
    }

    if (!name.trim()) {
      setCardErrors(errors => ({
        ...errors,
        name: 'カード名義に不備があります。',
      }));

      error = true;
    }

    if (result.error || error) {
      // カード情報の作成に失敗した場合
      window.scrollTo(0, 0);
    } else if (result.token) {
      setCardErrors({});

      // 有料入会処理
      dispatch(updateCardInfoEvent.start({ stripeToken: result.token.id }));
    }
  }, [dispatch, elements, name, stripe]);

  const handleBack = useCallback(() => {
    history.push(history.referer ?? PATH.SETTING_MENU);
  }, [history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!isLogin) {
      history.push(PATH.SIGNUP);

      return;
    }

    if (hasNoCard) {
      history.replace(PATH.SETTING_MENU);

      return;
    }

    dispatch(getReady());
  }, [authReady, dispatch, hasNoCard, history, isLogin]);

  useEffect(() => {
    if (success) {
      history.push(history.referer ?? PATH.SETTING_MENU);
    }
  }, [history, success]);

  useEffect(() => {
    if (error) window.alert('カード情報の更新に失敗しました');
  }, [error]);

  return (
    <>
      <Helmet>
        <title>クレジットカード変更 | 設定 | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      <ChangeCreditCard
        user={user}
        name={name}
        onChangeCardInfo={handleChangeCardInfo}
        onChangeName={handleChangeName}
        onSubmit={handleSubmit}
        onBack={handleBack}
        canSubmit={canSubmit}
        cardErrors={cardErrors}
      />
    </>
  );
};

const StripeContainer: FC = () => {
  return (
    <Elements stripe={stripePromise}>
      <ChangeCreditCardContainer />
    </Elements>
  );
};

export default StripeContainer;
