/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-alert */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import IconShape from '../../atoms/iconParts/IconShape';
import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';
import OrgSortModal from '../modalParts/OrgSortModal';

import { SortType } from '../../../../interfaces/searchInterFace';

import * as styles from './OrgFollowerAdminNav.module.scss';

interface Props {
  cssClass?: string;
  sortType?: SortType;
  onClickBlockList?: () => void;
  onClickSort?: () => void;
  onClickSortBg?: () => void;
  onSelectSort?: (index: number) => void;
  isOpenModal?: boolean;
}

const OrgFollowerAdminNav: FC<Props> = ({
  cssClass = '',
  sortType = 'DESC',
  onClickBlockList = () => undefined,
  onClickSort = () => undefined,
  onClickSortBg = () => undefined,
  onSelectSort = () => undefined,
  isOpenModal = false,
}) => {
  const classNames = ClassNames(styles.OrgFollowerAdminNavBase, cssClass, {});

  const actList =
    sortType === 'ASC' ? 'フォローが古い順' : 'フォローが新しい順';

  return (
    <div className={classNames}>
      <div className={styles.OrgFollowerAdminNavTitle}>
        <IconShape type="PMark" />
        <p>: 有料フォロワー</p>
      </div>
      <div className={styles.OrgFollowerAdminNavList}>
        <ButtonRoundedCorners
          text="ブロックリスト"
          type="Decenary"
          size="Septenary"
          onClick={onClickBlockList}
        />
        <IconShape type="Sort" onClick={onClickSort} />
      </div>

      {isOpenModal && (
        <>
          <OrgSortModal
            list={['フォローが新しい順', 'フォローが古い順']}
            actList={actList}
            onClick={onSelectSort}
            cssClass={styles.OrgFollowerAdminNavDialog}
          />
          <div
            className={styles.OrgFollowerAdminNavDialogBg}
            onClick={onClickSortBg}
          />
        </>
      )}
    </div>
  );
};

export default OrgFollowerAdminNav;
