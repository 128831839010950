/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import React, { FC, useCallback } from 'react';

import { useSelector } from 'react-redux';
import selector from '../../selectors';

import { useHistory } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import Footer from '../../components/footer/footer';

const openAnotherWindow = (url: string) => {
  window.open(
    url,
    'window',
    'width=550, height=450, personalbar=0, toolbar=0, scrollbars=1',
  );
};

const FooterContainer: FC = () => {
  const history = useHistory();

  const { options } = useSelector(selector.footer.getFooterState);

  const handleClickTwitter = useCallback(() => {
    window.open('https://twitter.com/KRAP_jp');
  }, []);
  const handleClickLine = useCallback(() => {
    window.open('https://lin.ee/6rPDPSl');
  }, []);
  const handleClickAbout = useCallback(() => {
    openAnotherWindow(`${process.env.PUBLIC_URL}/about`);
  }, []);
  const handleClickUserAgreement = useCallback(() => {
    openAnotherWindow(`${process.env.PUBLIC_URL}/useragreement`);
  }, []);
  const handleClickTransactions = useCallback(() => {
    openAnotherWindow(`${process.env.PUBLIC_URL}/transactions`);
  }, []);
  const handleClickPrivacyPolicy = useCallback(() => {
    openAnotherWindow(`${process.env.PUBLIC_URL}/privacypolicy`);
  }, []);
  const handleClickFAQ = useCallback(() => {
    openAnotherWindow(`${process.env.PUBLIC_URL}/rule/faq`);
  }, []);
  const handleClickRule = useCallback(() => {
    openAnotherWindow(`${process.env.PUBLIC_URL}/rule/regulation`);
  }, []);
  const handleClickOperatingCompany = useCallback(() => {
    openAnotherWindow(PATH.EXTERNAL_NANOMEDIA_CO);
  }, []);
  const handleClickContact = useCallback(() => {
    history.push(PATH.CONTACT);
  }, [history]);

  return (
    <Footer
      // PR
      isViewPR={!!options.pr}
      prOption={options.pr}
      // SNS
      isViewSNS={options.sns}
      onClickTwitter={handleClickTwitter}
      onClickLine={handleClickLine}
      // nav
      isViewNav={options.nav}
      onClickAbout={handleClickAbout}
      onClickUserAgreement={handleClickUserAgreement}
      onClickTransactions={handleClickTransactions}
      onClickPrivacyPolicy={handleClickPrivacyPolicy}
      onClickFAQ={handleClickFAQ}
      onClickRule={handleClickRule}
      onClickOperatingCompany={handleClickOperatingCompany}
      onClickContact={handleClickContact}
      // jasrac
      isViewJasrac={options.jasrac}
      // copyright
      isViewCopyRight={options.copyright}
      // button
      isViewButton={!!options.button}
      buttonOption={options.button}
      // post
      isViewPost={!!options.post}
      postOption={options.post}
      // fanclub
      isViewFanclub={!!options.fanclub}
      fanclubOption={options.fanclub}
      // back
      isViewBack={!!options.back}
      backOption={options.back}
      // twitter
      isViewTwitter={!!options.twitter}
      twitterOption={options.twitter}
      // 有料FCフッダー
      isPaidFC={!!options.paidFC}
      paidFCOption={options.paidFC}
    />
  );
};

export default FooterContainer;
