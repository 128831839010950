import * as ActionType from './commentsConstants';
import { FreejoinFanclubParams } from '../interfaces/fanclubInterFace';
import {
  GetContentDetailsParams,
  GetContentDetailsResult,
} from '../interfaces/contentInterFace';
import {
  SubmitCommentParams,
  SubmitCommentResult,
  SubmitCommentErrors,
  DeleteCommentParams,
} from '../interfaces/commentInterFace';

export const getReady = (contentId: number) =>
  ({
    type: ActionType.GET_READY,
    payload: { contentId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getContentDetailsEvent = {
  start: (params: GetContentDetailsParams) =>
    ({
      type: ActionType.GET_CONTENT_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetContentDetailsResult) =>
    ({
      type: ActionType.GET_CONTENT_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_CONTENT_DETAILS_FAIL,
    } as const),
};

export const submitCommentEvent = {
  start: (params: SubmitCommentParams) =>
    ({
      type: ActionType.SUBMIT_COMMENT_START,
      payload: params,
    } as const),

  succeed: (params: SubmitCommentParams, result: SubmitCommentResult) =>
    ({
      type: ActionType.SUBMIT_COMMENT_SUCCEED,
      payload: { params, result },
    } as const),

  fail: (errors: SubmitCommentErrors) =>
    ({
      type: ActionType.SUBMIT_COMMENT_FAIL,
      payload: { errors },
    } as const),
};

export const deleteCommentEvent = {
  start: (params: DeleteCommentParams) =>
    ({
      type: ActionType.DELETE_COMMENT_START,
      payload: params,
    } as const),

  succeed: (params: DeleteCommentParams) =>
    ({
      type: ActionType.DELETE_COMMENT_SUCCEED,
      payload: { params },
    } as const),

  fail: () =>
    ({
      type: ActionType.DELETE_COMMENT_FAIL,
    } as const),
};

export const freejoinFanclubEvent = {
  start: (params: FreejoinFanclubParams) =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.FREEJOIN_FANCLUB_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get content details
  | ReturnType<typeof getContentDetailsEvent.start>
  | ReturnType<typeof getContentDetailsEvent.succeed>
  | ReturnType<typeof getContentDetailsEvent.fail>
  // submit comment
  | ReturnType<typeof submitCommentEvent.start>
  | ReturnType<typeof submitCommentEvent.succeed>
  | ReturnType<typeof submitCommentEvent.fail>
  // delete comment
  | ReturnType<typeof deleteCommentEvent.start>
  | ReturnType<typeof deleteCommentEvent.succeed>
  | ReturnType<typeof deleteCommentEvent.fail>
  // freejoin fanclub
  | ReturnType<typeof freejoinFanclubEvent.start>
  | ReturnType<typeof freejoinFanclubEvent.succeed>
  | ReturnType<typeof freejoinFanclubEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
