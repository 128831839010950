import { Reducer } from 'redux';
import { Actions } from '../actions/withdrawPaidFCActions';
import * as ActionType from '../actions/withdrawPaidFCConstants';

type State = {
  ready: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
};

export const initialState: State = {
  ready: false,
  loading: false,
  success: false,
  error: false,
};

const withdrawPaidFCReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // withdraw
    case ActionType.WITHDRAW_PAID_FC_START: {
      return {
        ...state,
        error: false,
        loading: true,
      };
    }
    case ActionType.WITHDRAW_PAID_FC_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ActionType.WITHDRAW_PAID_FC_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default withdrawPaidFCReducer;
