/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/adminCategoryEditConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getFCCategoryListEvent,
  addFCCategoryEvent,
  deleteFCCategoryEvent,
} from '../actions/adminCategoryEditActions';
import { Status, ErrorResponse } from '../interfaces/commonInterFace';
import {
  GetFCCategoryListResponse,
  AddFCCategoryResponse,
  AddFCCategoryErrorResponse,
  DeleteFCCategoryResponse,
} from '../interfaces/categoryInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId } = action.payload;

  const getFCCategoryListTask = yield fork(
    runGetFCCategoryList,
    getFCCategoryListHandler,
    getFCCategoryListEvent.start({ artistId }),
  );
  yield join(getFCCategoryListTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get fc category list
const getFCCategoryListHandler = api.getGetFactory(true);
function* runGetFCCategoryList(
  handler: typeof getFCCategoryListHandler,
  action: ReturnType<typeof getFCCategoryListEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: GetFCCategoryListResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/category`,
    );

    if (!ReturnData) {
      yield put(getFCCategoryListEvent.fail());

      return;
    }

    yield put(getFCCategoryListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFCCategoryListEvent.fail());
  }
}
export function* getFCCategoryList(handler: typeof getFCCategoryListHandler) {
  yield takeLatest(
    ActionType.GET_FC_CATEGORY_LIST_START,
    runGetFCCategoryList,
    handler,
  );
}

// add fc category
const addFCCategoryHandler = api.getPostFactory(true);
function* runAddFCCategory(
  handler: typeof addFCCategoryHandler,
  action: ReturnType<typeof addFCCategoryEvent.start>,
) {
  const { artistId, ...data } = action.payload;
  try {
    const ReturnData: AddFCCategoryResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/category`,
    );

    if (!ReturnData) {
      yield put(addFCCategoryEvent.fail({}));

      return;
    }

    yield put(addFCCategoryEvent.succeed(action.payload, ReturnData.result));
  } catch (error) {
    const res = error.response.data as AddFCCategoryErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);
    if (res.status_code === Status.ValidationFailed) {
      yield put(addFCCategoryEvent.fail(res.errors));

      return;
    }

    yield put(addFCCategoryEvent.fail({}));
  }
}
export function* addFCCategory(handler: typeof addFCCategoryHandler) {
  yield takeLeading(
    ActionType.ADD_FC_CATEGORY_START,
    runAddFCCategory,
    handler,
  );
}

// delete fc category
const deleteFCCategoryHandler = api.getDeleteFactory(true);
function* runDeleteFCCategory(
  handler: typeof deleteFCCategoryHandler,
  action: ReturnType<typeof deleteFCCategoryEvent.start>,
) {
  const { artistId, categoryId } = action.payload;
  try {
    const ReturnData: DeleteFCCategoryResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/category/${categoryId}`,
    );

    if (!ReturnData) {
      yield put(deleteFCCategoryEvent.fail());

      return;
    }

    yield put(deleteFCCategoryEvent.succeed(action.payload));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(deleteFCCategoryEvent.fail());
  }
}
export function* deleteFCCategory(handler: typeof deleteFCCategoryHandler) {
  yield takeLeading(
    ActionType.DELETE_FC_CATEGORY_START,
    runDeleteFCCategory,
    handler,
  );
}

export const adminCategoryEditSagas = [
  fork(getReady),
  fork(getFCCategoryList, getFCCategoryListHandler),
  fork(addFCCategory, addFCCategoryHandler),
  fork(deleteFCCategory, deleteFCCategoryHandler),
];
