/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import McsIconLabelRoundQuinary from '../../molecules/iconWithLabel/McsIconLabelRoundQuinary';
import IconClose from '../../atoms/iconParts/IconClose';

import { User, UserInitialValue } from '../../../../interfaces/userInterFace';

import * as styles from './OrgUserListUnitMemberSecondary.module.scss';

type PartialUser = Partial<User>;

interface Props {
  cssClass?: string;
  user?: PartialUser;
  onInviteClick?: () => void;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  user = UserInitialValue,
  onInviteClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgUserListUnitBase, cssClass);

  return (
    <li className={classNames} key={user.id} onClick={onInviteClick}>
      <McsIconLabelRoundQuinary
        src={user.icon_image || ''}
        mainText={user.nickname}
      />
      <IconClose />
    </li>
  );
};

export default OrgListUnit;
