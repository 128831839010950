import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';

type Props = RouteComponentProps<{}>;

export class ErrorBoundary extends React.Component<Props> {
  // eslint-disable-next-line react/state-in-constructor
  public state = { hasError: false };

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      hasError: true,
    });
    // eslint-disable-next-line no-console
    console.log(error);
    // eslint-disable-next-line no-console
    console.log(errorInfo);
  }

  public render() {
    const { children } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state.hasError) {
      return children;
    }

    return <div>Error</div>;
  }
}

export default withRouter(ErrorBoundary);
