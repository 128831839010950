import { AllState } from '../store';

const getArtistState = (state: AllState) => {
  return state.artist;
};

const getIsLastPage = (state: AllState) => {
  return (
    state.artist.contents.pager.current_page ===
    state.artist.contents.pager.last_page
  );
};

export default {
  getArtistState,
  getIsLastPage,
};
