/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  fork,
  join,
  takeLeading,
  takeLatest,
} from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/adminBlocksConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getFCBlockListEvent,
  unblockUserEvent,
} from '../actions/adminBlocksActions';

import { ErrorResponse } from '../interfaces/commonInterFace';
import {
  GetFCBlockListResponse,
  UnblockUserResponse,
} from '../interfaces/followerInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { artistId } = action.payload;

  const getFCBlockListTask = yield fork(
    runGetFCBlockList,
    getFCBlockListHandler,
    getFCBlockListEvent.start({ artistId }),
  );
  yield join(getFCBlockListTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get fc block list
const getFCBlockListHandler = api.getGetFactory(true);
function* runGetFCBlockList(
  handler: typeof getFCBlockListHandler,
  action: ReturnType<typeof getFCBlockListEvent.start>,
) {
  const { artistId } = action.payload;

  try {
    const ReturnData: GetFCBlockListResponse = yield call(
      handler,
      {},
      `/fanclub/${artistId}/blocks`,
    );

    if (!ReturnData) {
      yield put(getFCBlockListEvent.fail());

      return;
    }

    yield put(getFCBlockListEvent.succeed(ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getFCBlockListEvent.fail());
  }
}
export function* getFCBlockList(handler: typeof getFCBlockListHandler) {
  yield takeLeading(
    ActionType.GET_FC_BLOCK_LIST_START,
    runGetFCBlockList,
    handler,
  );
}

// unblock user
const unblockUserHandler = api.getPostFactory(true);
function* runUnblockUser(
  handler: typeof unblockUserHandler,
  action: ReturnType<typeof unblockUserEvent.start>,
) {
  const { artistId, ...data } = action.payload;

  try {
    const ReturnData: UnblockUserResponse = yield call(
      handler,
      data,
      `/fanclub/${artistId}/unblock`,
    );

    if (!ReturnData) {
      yield put(unblockUserEvent.fail());

      return;
    }

    yield put(unblockUserEvent.succeed(action.payload, ReturnData.result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(unblockUserEvent.fail());
  }
}
export function* unblockUser(handler: typeof unblockUserHandler) {
  yield takeLeading(ActionType.UNBLOCK_USER_START, runUnblockUser, handler);
}

export const adminBlocksSagas = [
  fork(getReady),
  fork(getFCBlockList, getFCBlockListHandler),
  fork(unblockUser, unblockUserHandler),
];
