import util from './utilSelectors';
import auth from './authSelectors';
import footer from './footerSelectors';
import news from './newsSelectors';
import newsDetails from './newsDetailsSelectors';
import signUp from './signUpSelectors';
import changeEmail from './changeEmailSelectors';
import changePassword from './changePasswordSelectors';
import changeCreditCard from './changeCreditCardSelectors';
import search from './searchSelectors';
import withdraw from './withdrawSelectors';
import withdrawPaidFC from './withdrawPaidFCSelectors';
import attachGenres from './attachGenresSelectors';
import followArtist from './followArtistSelectors';
import twitter from './twitterSelectors';
import reissuePasswordSendEmail from './reissuePasswordSendEmailSelectors';
import reissuePasswordResetPassword from './reissuePasswordResetPasswordSelectors';
import home from './homeSelectors';
import artist from './artistSelectors';
import content from './contentSelectors';
import comments from './commentsSelectors';
import likes from './likesSelectors';
import chat from './chatSelectors';
import followers from './followersSelectors';
import user from './userSelectors';
import setting from './settingSelectors';
import paidjoin from './paidjoinSelectors';
import editUserProfile from './editUserProfileSelectors';
import openFCRegister from './openFCRegisterSelectors';
import openFCComplete from './openFCCompleteSelectors';
import adminMenu from './adminMenuSelectors';
import adminContents from './adminContentsSelectors';
import adminComments from './adminCommentsSelectors';
import adminBlogRegister from './adminBlogRegisterSelectors';
import adminMovieRegister from './adminMovieRegisterSelectors';
import adminPhotoRegister from './adminPhotoRegisterSelectors';
import adminVoiceRegister from './adminVoiceRegisterSelectors';
import adminCategoryEdit from './adminCategoryEditSelectors';
import adminCommunityEdit from './adminCommunityEditSelectors';
import adminMembers from './adminMembersSelectors';
import adminFollowers from './adminFollowersSelectors';
import adminBlocks from './adminBlocksSelectors';
import adminLikes from './adminLikesSelectors';
import adminApplyPaidFC from './adminApplyPaidFCSelectors';
import adminAdministrator from './adminAdministratorSelectors';
import contact from './contactSelectors';
import adminSales from './adminSalesSelectors';
import adminFCClosed from './adminFCClosedSelectors';

export default {
  util,
  auth,
  footer,
  news,
  newsDetails,
  signUp,
  changeEmail,
  changePassword,
  changeCreditCard,
  search,
  withdraw,
  withdrawPaidFC,
  attachGenres,
  followArtist,
  twitter,
  reissuePasswordSendEmail,
  reissuePasswordResetPassword,
  home,
  artist,
  content,
  comments,
  likes,
  chat,
  followers,
  user,
  setting,
  paidjoin,
  editUserProfile,
  openFCRegister,
  openFCComplete,
  adminMenu,
  adminContents,
  adminComments,
  adminBlogRegister,
  adminMovieRegister,
  adminPhotoRegister,
  adminVoiceRegister,
  adminCategoryEdit,
  adminCommunityEdit,
  adminMembers,
  adminFollowers,
  adminBlocks,
  adminLikes,
  adminApplyPaidFC,
  adminAdministrator,
  contact,
  adminSales,
  adminFCClosed,
};
