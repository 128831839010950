/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable no-bitwise */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useRef, useState } from 'react';
import ClassNames from 'classnames';

import * as styles from './OrgContentPhoto.module.scss';

interface Props {
  cssClass?: string;
  photos?: string[];
}

const OrgContentPhoto: FC<Props> = ({ cssClass = '', photos = [] }) => {
  const classNames = ClassNames(styles.OrgContentPhotoBase, cssClass);

  const [index, setIndex] = useState(0);
  const scrollRef = useRef<HTMLUListElement>(null);

  const handleScroll = () => {
    if (!scrollRef.current) return;

    const index =
      (scrollRef.current.scrollLeft / scrollRef.current.clientWidth) | 0;

    setIndex(index);
  };

  const handleClickPointReader = (index: number) => {
    if (!scrollRef.current) return;

    scrollRef.current.scrollTo({ left: scrollRef.current.clientWidth * index });
  };

  return (
    <div className={classNames}>
      <div className={styles.OrgContentPhotoListWrapper}>
        <ul
          ref={scrollRef}
          onScroll={handleScroll}
          className={styles.OrgContentPhotoList}
        >
          {photos.map((photo, i) => (
            <li
              key={`photo_${i.toString()}`}
              className={styles.OrgContentPhotoListItem}
            >
              <img src={photo} alt="" className={styles.OrgContentPhoto} />
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.OrgContentPhotoPointReaderContainer}>
        <ul className={styles.OrgContentPhotoPointReaderList}>
          {photos.map((_, i) => (
            <li
              key={`point${i.toString()}`}
              onClick={() => handleClickPointReader(i)}
              style={{ marginLeft: i === 0 ? 0 : 8 }}
            >
              <div
                className={
                  index === i
                    ? styles.OrgContentPhotoPointReaderActive
                    : styles.OrgContentPhotoPointReaderInactive
                }
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default OrgContentPhoto;
