/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useCallback } from 'react';
import ClassNames from 'classnames';

import blankImageUrl from '../../assets/images/blank-image.png';
import ImageThumbnail from '../../atoms/imageThumbnail/ImageThumbnail';

import {
  Content,
  ContentInitialValue,
} from '../../../../interfaces/contentInterFace';

import * as styles from './OrgFCListUnitTimeLinePhoto.module.scss';

type PartialContent = Partial<Content>;

type Props = {
  cssClass?: string;
  content?: PartialContent;
  onClickContent?: (artistId: string, contentId: number) => void;
};

const OrgTimeLineUnit: FC<Props> = ({
  cssClass = '',
  content = ContentInitialValue,
  onClickContent = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgFCListUnitBase, cssClass);

  const handleClick = useCallback(() => {
    onClickContent(content?.fanclub?.group_id ?? '', content?.id ?? 0);
  }, [content, onClickContent]);

  if (content.type !== 2) return null;

  return (
    <li className={classNames} key={content.id} onClick={handleClick}>
      <ImageThumbnail
        src={content.url ?? blankImageUrl}
        cssClass={content.open_area === 1 ? styles.PaidFCPhotos : ''}
      />
    </li>
  );
};

export default OrgTimeLineUnit;
