import * as ActionType from './signUpConstants';
import {
  CheckDuplicateEmailParams,
  CheckDuplicateEmailResult,
  CheckDuplicateEmailErrors,
  SignUpParams,
  SignUpErrors,
} from '../interfaces/authInterFace';

export const checkDuplicateEmailEvent = {
  start: (params: CheckDuplicateEmailParams) =>
    ({
      type: ActionType.CHECK_DUPLICATE_EMAIL_START,
      payload: params,
    } as const),

  succeed: (result: CheckDuplicateEmailResult) =>
    ({
      type: ActionType.CHECK_DUPLICATE_EMAIL_SUCCEED,
      payload: { result },
    } as const),

  fail: (errors: CheckDuplicateEmailErrors) =>
    ({
      type: ActionType.CHECK_DUPLICATE_EMAIL_FAIL,
      payload: { errors },
    } as const),
};

export const signupEvent = {
  start: (params: SignUpParams) =>
    ({
      type: ActionType.SIGNUP_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.SIGNUP_SUCCEED,
    } as const),

  fail: (errors: SignUpErrors) =>
    ({
      type: ActionType.SIGNUP_FAIL,
      payload: { errors },
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // check duplicate email
  | ReturnType<typeof checkDuplicateEmailEvent.start>
  | ReturnType<typeof checkDuplicateEmailEvent.succeed>
  | ReturnType<typeof checkDuplicateEmailEvent.fail>
  // signup
  | ReturnType<typeof signupEvent.start>
  | ReturnType<typeof signupEvent.succeed>
  | ReturnType<typeof signupEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
