export const GET_READY = 'CONTENT/GET_READY' as const;
export const GOT_READY = 'CONTENT/GOT_READY' as const;

export const GET_CONTENT_DETAILS_START = 'CONTENT/GET_CONTENT_DETAILS_START' as const;
export const GET_CONTENT_DETAILS_SUCCEED = 'CONTENT/GET_CONTENT_DETAILS_SUCCEED' as const;
export const GET_CONTENT_DETAILS_FAIL = 'CONTENT/GET_CONTENT_DETAILS_FAIL' as const;

export const GET_FANCLUB_DETAILS_START = 'CONTENT/GET_FANCLUB_DETAILS_START' as const;
export const GET_FANCLUB_DETAILS_SUCCEED = 'CONTENT/GET_FANCLUB_DETAILS_SUCCEED' as const;
export const GET_FANCLUB_DETAILS_FAIL = 'CONTENT/GET_FANCLUB_DETAILS_FAIL' as const;

export const GET_RECENT_CONTENT_PHOTO_LIST_START = 'CONTENT/GET_RECENT_CONTENT_PHOTO_LIST_START' as const;
export const GET_RECENT_CONTENT_PHOTO_LIST_SUCCEED = 'CONTENT/GET_RECENT_CONTENT_PHOTO_LIST_SUCCEED' as const;
export const GET_RECENT_CONTENT_PHOTO_LIST_FAIL = 'CONTENT/GET_RECENT_CONTENT_PHOTO_LIST_FAIL' as const;

export const GET_RECENT_CONTENT_BLOG_LIST_START = 'CONTENT/GET_RECENT_CONTENT_BLOG_LIST_START' as const;
export const GET_RECENT_CONTENT_BLOG_LIST_SUCCEED = 'CONTENT/GET_RECENT_CONTENT_BLOG_LIST_SUCCEED' as const;
export const GET_RECENT_CONTENT_BLOG_LIST_FAIL = 'CONTENT/GET_RECENT_CONTENT_BLOG_LIST_FAIL' as const;

export const GET_RECOMMEND_FANCLUB_LIST_START = 'CONTENT/GET_RECOMMEND_FANCLUB_LIST_START' as const;
export const GET_RECOMMEND_FANCLUB_LIST_SUCCEED = 'CONTENT/GET_RECOMMEND_FANCLUB_LIST_SUCCEED' as const;
export const GET_RECOMMEND_FANCLUB_LIST_FAIL = 'CONTENT/GET_RECOMMEND_FANCLUB_LIST_FAIL' as const;

export const LIKE_START = 'CONTENT/LIKE_START' as const;
export const LIKE_SUCCEED = 'CONTENT/LIKE_SUCCEED' as const;
export const LIKE_FAIL = 'CONTENT/LIKE_FAIL' as const;

export const GET_LIKED_USERS_START = 'CONTENT/GET_LIKE_USERS_START' as const;
export const GET_LIKED_USERS_SUCCEED = 'CONTENT/GET_LIKE_USERS_SUCCEED' as const;
export const GET_LIKED_USERS_FAIL = 'CONTENT/GET_LIKE_USERS_FAIL' as const;

export const FREEJOIN_FANCLUB_START = 'CONTENT/FREEJOIN_FANCLUB_START' as const;
export const FREEJOIN_FANCLUB_SUCCEED = 'CONTENT/FREEJOIN_FANCLUB_SUCCEED' as const;
export const FREEJOIN_FANCLUB_FAIL = 'CONTENT/FREEJOIN_FANCLUB_FAIL' as const;

export const CLEANUP = 'CONTENT/CLEANUP' as const;
