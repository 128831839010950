import { Reducer } from 'redux';
import { Actions } from '../actions/adminCategoryEditActions';
import * as ActionType from '../actions/adminCategoryEditConstants';

import { Category } from '../interfaces/categoryInterFace';

type State = {
  current: Category[];
  ready: boolean;
  loading: boolean;
  errors: {
    add: boolean;
    delete: boolean;
  };
  success: {
    add: boolean;
    delete: boolean;
  };
};

export const initialState: State = {
  current: [],
  ready: false,
  loading: false,
  errors: {
    add: false,
    delete: false,
  },
  success: {
    add: false,
    delete: false,
  },
};

const adminCategoryEditReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fc category list
    case ActionType.GET_FC_CATEGORY_LIST_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FC_CATEGORY_LIST_SUCCEED: {
      return {
        ...state,
        current: action.payload.result.categories,
        loading: false,
      };
    }
    case ActionType.GET_FC_CATEGORY_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // add fc category
    case ActionType.ADD_FC_CATEGORY_START: {
      return {
        ...state,
        loading: true,
        errors: initialState.errors,
        success: initialState.success,
      };
    }
    case ActionType.ADD_FC_CATEGORY_SUCCEED: {
      return {
        ...state,
        current: [
          ...state.current,
          {
            fanclub_id: 0, // action.payload.params.fanclub_group_id
            id: action.payload.result.category_id,
            name: action.payload.result.name,
          },
        ],
        loading: false,
        success: {
          add: true,
          delete: false,
        },
      };
    }
    case ActionType.ADD_FC_CATEGORY_FAIL: {
      return {
        ...state,
        loading: false,
        errors: {
          add: true,
          delete: false,
        },
      };
    }

    // delete fc category
    case ActionType.DELETE_FC_CATEGORY_START: {
      return {
        ...state,
        loading: true,
        errors: initialState.errors,
        success: initialState.success,
      };
    }
    case ActionType.DELETE_FC_CATEGORY_SUCCEED: {
      return {
        ...state,
        current: state.current.filter(
          c => c.id !== action.payload.params.categoryId,
        ),
        loading: false,
        success: {
          add: false,
          delete: true,
        },
      };
    }
    case ActionType.DELETE_FC_CATEGORY_FAIL: {
      return {
        ...state,
        loading: false,
        errors: {
          add: false,
          delete: true,
        },
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default adminCategoryEditReducer;
