import React, { FC } from 'react';
import ClassNames from 'classnames';
import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';
import McsTitleTertiary from '../common/molecules/titleParts/McsTitleTertiary';

import McsSelectSecondary from '../common/molecules/formParts/McsSelectSecondary';
import McsInputSecondary from '../common/molecules/formParts/McsInputSecondary';
import McsTextAreaSecondary from '../common/molecules/formParts/McsTextAreaSecondary';

import { User, UserInitialValue } from '../../interfaces/userInterFace';
import { SelectField } from '../../interfaces/utilInterFace';

import {
  SubmitContactValidationErrors,
  ContactType,
  SubmitContactParams,
} from '../../interfaces/contactInterFace';

import * as styles from './contact.module.scss';

interface Props {
  user?: User;
  params?: SubmitContactParams;
  contactType?: ContactType;
  contactList?: SelectField;
  artistList?: SelectField;
  handleChange?: (
    e: React.FormEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    key?: string,
  ) => void;
  handleSubmit?: () => void;
  handleCancel?: () => void;
  errors?: SubmitContactValidationErrors;
}

const Component: FC<Props> = ({
  user = UserInitialValue,
  params = {
    fanclub_id: 0,
    name: '',
    email: '',
    description: '',
  },
  contactType = undefined,
  contactList = [],
  artistList = [],
  handleChange = () => undefined,
  handleSubmit = () => undefined,
  handleCancel = () => undefined,
  errors = {
    fanclub_id: '',
    email: '',
    description: '',
    name: '',
  },
}) => {
  const classNames = ClassNames(styles.containerWrap);

  return (
    <div className={classNames}>
      <McsTitleTertiary
        mainText="ヘルプセンター"
        subText=""
        cssClass={styles.title}
      />

      <div className={styles.read}>
        <p>
          KRAPやコミュニティに関するお問い合わせは、下記フォームよりご連絡ください。
          <br />
          迷惑メール防止設定をされている方は、こちらからの返信メールが届くよう『krap.me』をドメイン指定してください。
        </p>
      </div>

      <McsSelectSecondary
        inputName="type"
        value={String(contactType)}
        labelTitle="お問い合わせ内容"
        labelTag="必須"
        selectList={contactList}
        cssClass={styles.inputBox}
        onChange={handleChange}
        isError={!!errors.fanclub_id}
        errorText={errors.fanclub_id}
      />

      {contactType === 1 && (
        <McsSelectSecondary
          inputName="fanclub_id"
          value={String(params.fanclub_id)}
          labelTitle="アーティスト"
          labelTag="必須"
          placeholder="選択してください"
          selectList={artistList}
          cssClass={styles.inputBox}
          onChange={handleChange}
        />
      )}

      {!!user.name_id && (
        <McsInputSecondary
          value={user.name_id}
          inputType="text"
          inputName="id"
          placeholder=""
          labelTitle="ID"
          cssClass={styles.inputBox}
          onChange={handleChange}
          isDisabled
        />
      )}

      <McsInputSecondary
        value={params.name}
        inputType="text"
        inputName="name"
        placeholder="お名前を入力"
        labelTitle="お名前"
        labelTag="必須"
        cssClass={styles.inputBox}
        onChange={handleChange}
        isError={!!errors.name}
        errorText={errors.name}
      />

      <McsInputSecondary
        value={params.email}
        inputType="text"
        inputName="email"
        placeholder="メールアドレスを入力"
        labelTitle="メールアドレス"
        labelTag="必須"
        cssClass={styles.inputBox}
        onChange={handleChange}
        isError={!!errors.email}
        errorText={errors.email}
      />

      <McsTextAreaSecondary
        value={params.description}
        TextAreaName="description"
        placeholder="例）KRAPにログインできません"
        labelTitle="お問い合わせ詳細"
        labelTag="必須"
        cssClass={styles.inputBox}
        onChange={handleChange}
        isError={!!errors.description}
        errorText={errors.description}
      />

      <div className={styles.buttonWrap}>
        <ButtonRoundedCorners
          text="送信する"
          type="Primary"
          size="Primary"
          onClick={handleSubmit}
          cssClass={styles.button}
        />
        <ButtonRoundedCorners
          text="キャンセル"
          type="Quinary"
          size="Primary"
          onClick={handleCancel}
          cssClass={styles.cancel}
        />
      </div>
    </div>
  );
};

export default Component;
