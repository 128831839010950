/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import Icon from '../../atoms/iconThumbnail/IconThumbnailSquare';
import LabelTitle from '../../atoms/label/LabelTitleParallel';

import krapImageUrl from '../../assets/images/krap-logo.svg';

import * as styles from './McsIconLabelSquare.module.scss';

interface Props {
  src?: string;
  mainText?: string;
  subText?: string;
  readText?: string;

  type?: string;
  cssClass?: string;
  onClick?: () => void;
}

const McsIconLabel: FC<Props> = ({
  src = '',
  mainText = 'Text',
  subText = 'SubText',
  readText = 'ReadText',

  type = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsIconLabelBase, cssClass, {
    [styles.McsIconLabelPrimary]: type === 'Primary', // Primary
  });

  return (
    <div className={classNames} onClick={onClick}>
      <Icon
        src={src}
        errorSrc={krapImageUrl}
        size="Tertiary"
        cssClass={styles.McsIconLabelImage}
      />
      <div>
        <LabelTitle type="Primary" mainText={mainText} subText={subText} />
        <p className={styles.McsIconLabelReadText}>{readText}</p>
      </div>
    </div>
  );
};

export default McsIconLabel;
