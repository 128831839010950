/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import ButtonFullWidth from '../../atoms/buttonBasic/ButtonFullWidth';

import OrgFCListUnitSecondary from '../listUnitParts/OrgFCListUnitSecondary';

import { FanclubWithUserInfo } from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgFollowLikeFCList.module.scss';

type Props = {
  fanclubList?: Partial<FanclubWithUserInfo>[];
  onClickFanclub?: (artistId: string) => void;
  onClickButton?: (artistId: string, id: number) => void;
  isViewReadMoreButton?: boolean;
  onReadMore?: () => void;
  cssClass?: string;
};

const OrgFCList: FC<Props> = ({
  fanclubList = [],
  onClickFanclub = () => undefined,
  onClickButton = () => undefined,
  isViewReadMoreButton = false,
  onReadMore = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListBase, cssClass);

  return (
    <>
      <div className={classNames}>
        <ul className={styles.OrgFCListWrap}>
          {fanclubList.map((item, i) => (
            <OrgFCListUnitSecondary
              fanclub={item}
              key={`OrgFCListUnitTertiary${i.toString()}`}
              isViewButton={!item.managed}
              buttonType={item.followed ? 'Primary' : 'Secondary'}
              buttonText={
                item.paid ? '入会中' : item.followed ? 'フォロー中' : 'フォロー'
              }
              onClickFanclub={() => onClickFanclub(item.group_id ?? '')}
              onClickButton={() =>
                onClickButton(item.group_id ?? '', item.id ?? 0)
              }
            />
          ))}
        </ul>
      </div>
      {isViewReadMoreButton && (
        <ButtonFullWidth
          text="もっと見る"
          type="Secondary"
          onClick={onReadMore}
        />
      )}
    </>
  );
};

export default OrgFCList;
