/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { call, put, fork, join, takeLatest } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/newsDetailsConstants';
import { checkAndSetStatusError } from './authSagas';
import {
  getReady as getReadyStart,
  gotReady,
  getNewsDetailsEvent,
} from '../actions/newsDetailsActions';
import { Status, ErrorResponse } from '../interfaces/commonInterFace';
import { GetNewsDetailsResponse } from '../interfaces/newsInterFace';

// initialize
function* runGetReady(action: ReturnType<typeof getReadyStart>) {
  const { notificationId } = action.payload;

  const getNewsDetailsTask = yield fork(
    runGetNewsDetails,
    getNewsDetailsHandler,
    getNewsDetailsEvent.start({ notificationId }),
  );
  yield join(getNewsDetailsTask);

  yield put(gotReady());
}
export function* getReady() {
  yield takeLatest(ActionType.GET_READY, runGetReady);
}

// get news details
const getNewsDetailsHandler = api.getGetFactory(true);
function* runGetNewsDetails(
  handler: typeof getNewsDetailsHandler,
  action: ReturnType<typeof getNewsDetailsEvent.start>,
) {
  const { notificationId } = action.payload;
  try {
    const ReturnData: GetNewsDetailsResponse = yield call(
      handler,
      {},
      `/news/${notificationId}`,
    );

    if (!ReturnData) {
      yield put(getNewsDetailsEvent.fail());

      return;
    }

    const { result } = ReturnData;

    yield put(getNewsDetailsEvent.succeed(result));
  } catch (error) {
    const res = error.response.data as ErrorResponse;
    yield fork(checkAndSetStatusError, res.status_code);

    yield put(getNewsDetailsEvent.fail());
  }
}
export function* getNewsDetails(handler: typeof getNewsDetailsHandler) {
  yield takeLatest(
    ActionType.GET_NEWS_DETAILS_START,
    runGetNewsDetails,
    handler,
  );
}

export const newsDetailsSagas = [
  fork(getReady),
  fork(getNewsDetails, getNewsDetailsHandler),
];
