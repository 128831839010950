/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import Icon from '../../atoms/iconThumbnail/IconThumbnailSquare';
import LabelTitle from '../../atoms/label/LabelTitleSeries';
import * as styles from './McsIconLabelSquare.module.scss';

interface Props {
  src?: string;
  mainText?: string;
  subText?: string;
  size?: string;
  type?: string;
  cssClass?: string;
  onClick?: () => void;
}

const McsIconLabel: FC<Props> = ({
  src = '',
  mainText = 'Text',
  subText = 'SubText',
  size = 'Quaternary',
  type = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.McsIconLabelBase, cssClass, {
    [styles.McsIconLabelPrimary]: type === 'Primary', // Primary
  });

  return (
    <div className={classNames} onClick={onClick}>
      <Icon src={src} size={size} cssClass={styles.McsIconLabelImage} />
      <LabelTitle type="Tertiary" mainText={mainText} subText={subText} />
    </div>
  );
};

export default McsIconLabel;
