import { all } from 'redux-saga/effects';

import { utilSagas } from './utilSagas';
import { authSagas } from './authSagas';
import { newsSagas } from './newsSagas';
import { newsDetailsSagas } from './newsDetailsSagas';
import { signUpSagas } from './signUpSagas';
import { twitterSagas } from './twitterSagas';
import { withdrawSagas } from './withdrawSagas';
import { withdrawPaidFCSagas } from './withdrawPaidFCSagas';
import { reissuePasswordSendEmailSagas } from './reissuePasswordSendEmailSagas';
import { reissuePasswordResetPasswordSagas } from './reissuePasswordResetPasswordSagas';
import { changePasswordSagas } from './changePasswordSagas';
import { changeEmailSagas } from './changeEmailSagas';
import { changeCreditCardSagas } from './changeCreditCardSagas';
import { attachGenresSagas } from './attachGenresSagas';
import { followArtistSagas } from './followArtistSagas';
import { searchSagas } from './searchSagas';
import { homeSagas } from './homeSagas';
import { artistSagas } from './artistSagas';
import { contentSagas } from './contentSagas';
import { commentsSagas } from './commentsSagas';
import { likesSagas } from './likesSagas';
import { chatSagas } from './chatSagas';
import { followersSagas } from './followersSagas';
import { userSagas } from './userSagas';
import { settingSagas } from './settingSagas';
import { paidjoinSagas } from './paidjoinSagas';
import { editUserProfileSagas } from './editUserProfileSagas';
import { openFCRegisterSagas } from './openFCRegisterSagas';
import { openFCCompleteSagas } from './openFCCompleteSagas';
import { editFanclubSagas } from './editFanclubSagas';
import { adminMenuSagas } from './adminMenuSagas';
import { adminContentsSagas } from './adminContentsSagas';
import { adminCommentsSagas } from './adminCommentsSagas';
import { adminBlogRegisterSagas } from './adminBlogRegisterSagas';
import { adminMovieRegisterSagas } from './adminMovieRegisterSagas';
import { adminPhotoRegisterSagas } from './adminPhotoRegisterSagas';
import { adminVoiceRegisterSagas } from './adminVoiceRegisterSagas';
import { adminCategoryEditSagas } from './adminCategoryEditSagas';
import { adminCommunityEditSagas } from './adminCommunityEditSagas';
import { adminMembersSagas } from './adminMembersSagas';
import { adminFollowersSagas } from './adminFollowersSagas';
import { adminBlocksSagas } from './adminBlocksSagas';
import { adminLikesSagas } from './adminLikesSagas';
import { adminApplyPaidFCSagas } from './adminApplyPaidFCSagas';
import { adminAdministratorSagas } from './adminAdministratorSagas';
import { contactSagas } from './contactSagas';
import { adminSalesSagas } from './adminSalesSagas';
import { adminFCClosedSagas } from './adminFCClosedSagas';

// root
export default function* rootSaga() {
  yield all([
    ...utilSagas,
    ...authSagas,
    ...newsSagas,
    ...newsDetailsSagas,
    ...signUpSagas,
    ...twitterSagas,
    ...withdrawSagas,
    ...withdrawPaidFCSagas,
    ...reissuePasswordSendEmailSagas,
    ...reissuePasswordResetPasswordSagas,
    ...changePasswordSagas,
    ...changeEmailSagas,
    ...changeCreditCardSagas,
    ...attachGenresSagas,
    ...followArtistSagas,
    ...searchSagas,
    ...homeSagas,
    ...artistSagas,
    ...contentSagas,
    ...commentsSagas,
    ...likesSagas,
    ...chatSagas,
    ...followersSagas,
    ...userSagas,
    ...settingSagas,
    ...paidjoinSagas,
    ...editUserProfileSagas,
    ...openFCRegisterSagas,
    ...openFCCompleteSagas,
    ...editFanclubSagas,
    ...adminMenuSagas,
    ...adminContentsSagas,
    ...adminCommentsSagas,
    ...adminBlogRegisterSagas,
    ...adminMovieRegisterSagas,
    ...adminPhotoRegisterSagas,
    ...adminVoiceRegisterSagas,
    ...adminCategoryEditSagas,
    ...adminCommunityEditSagas,
    ...adminMembersSagas,
    ...adminFollowersSagas,
    ...adminBlocksSagas,
    ...adminLikesSagas,
    ...adminApplyPaidFCSagas,
    ...adminAdministratorSagas,
    ...contactSagas,
    ...adminSalesSagas,
    ...adminFCClosedSagas,
  ]);
}
