import { AllState } from '../store';

const getAuthState = (state: AllState) => {
  return state.auth;
};

const getUserProfile = (state: AllState) => {
  return state.auth.profile;
};

const getIsLogin = (state: AllState) => {
  return state.auth.profile.user.id !== 0;
};

const getIsLoading = (state: AllState) => {
  return state.auth.loading;
};

export default {
  getAuthState,
  getUserProfile,
  getIsLogin,
  getIsLoading,
};
