import * as ActionType from './adminMembersConstants';
import {
  AddFanclubMembersParams,
  AddFanclubMembersResult,
  DeleteFanclubMembersParams,
  DeleteFanclubMembersResult,
  ChangeFanclubMembersAuthorityParams,
  ChangeFanclubMembersAuthorityResult,
} from '../interfaces/memberInterFace';
import {
  GetFanclubDetailsParams,
  GetFanclubDetailsResult,
  WithdrawFanclubParams,
} from '../interfaces/fanclubInterFace';
import {
  SearchUserListParams,
  SearchUserListResult,
} from '../interfaces/searchInterFace';

export const getReady = (artistId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getFanclubDetailsEvent = {
  start: (params: GetFanclubDetailsParams) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_START,
      payload: params,
    } as const),

  succeed: (result: GetFanclubDetailsResult) =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FANCLUB_DETAILS_FAIL,
    } as const),
};

export const addFCMembersEvent = {
  start: (params: AddFanclubMembersParams) =>
    ({
      type: ActionType.ADD_FC_MEMBERS_START,
      payload: params,
    } as const),

  succeed: (result: AddFanclubMembersResult) =>
    ({
      type: ActionType.ADD_FC_MEMBERS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.ADD_FC_MEMBERS_FAIL,
    } as const),
};

export const withdrawFanclubEvent = {
  start: (params: WithdrawFanclubParams) =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_SUCCEED,
    } as const),

  fail: () =>
    ({
      type: ActionType.WITHDRAW_FANCLUB_FAIL,
    } as const),
};

export const deleteFCMembersEvent = {
  start: (params: DeleteFanclubMembersParams) =>
    ({
      type: ActionType.DELETE_FC_MEMBERS_START,
      payload: params,
    } as const),

  succeed: (result: DeleteFanclubMembersResult) =>
    ({
      type: ActionType.DELETE_FC_MEMBERS_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.DELETE_FC_MEMBERS_FAIL,
    } as const),
};

export const changeFCMembersAuthorityEvent = {
  start: (params: ChangeFanclubMembersAuthorityParams) =>
    ({
      type: ActionType.CHANGE_FC_MEMBERS_AUTHORITY_START,
      payload: params,
    } as const),

  succeed: (result: ChangeFanclubMembersAuthorityResult) =>
    ({
      type: ActionType.CHANGE_FC_MEMBERS_AUTHORITY_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.CHANGE_FC_MEMBERS_AUTHORITY_FAIL,
    } as const),
};

export const searchUserListEvent = {
  start: (params: SearchUserListParams) =>
    ({
      type: ActionType.SEARCH_USER_LIST_START,
      payload: params,
    } as const),

  succeed: (result: SearchUserListResult) =>
    ({
      type: ActionType.SEARCH_USER_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.SEARCH_USER_LIST_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get fanclub details
  | ReturnType<typeof getFanclubDetailsEvent.start>
  | ReturnType<typeof getFanclubDetailsEvent.succeed>
  | ReturnType<typeof getFanclubDetailsEvent.fail>
  // add fc member list
  | ReturnType<typeof addFCMembersEvent.start>
  | ReturnType<typeof addFCMembersEvent.succeed>
  | ReturnType<typeof addFCMembersEvent.fail>
  // withdraw fanclub
  | ReturnType<typeof withdrawFanclubEvent.start>
  | ReturnType<typeof withdrawFanclubEvent.succeed>
  | ReturnType<typeof withdrawFanclubEvent.fail>
  // delete fc member list
  | ReturnType<typeof deleteFCMembersEvent.start>
  | ReturnType<typeof deleteFCMembersEvent.succeed>
  | ReturnType<typeof deleteFCMembersEvent.fail>
  // change fc member authority
  | ReturnType<typeof changeFCMembersAuthorityEvent.start>
  | ReturnType<typeof changeFCMembersAuthorityEvent.succeed>
  | ReturnType<typeof changeFCMembersAuthorityEvent.fail>
  // search user list
  | ReturnType<typeof searchUserListEvent.start>
  | ReturnType<typeof searchUserListEvent.succeed>
  | ReturnType<typeof searchUserListEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
