import React, { FC, useMemo } from 'react';
import ClassNames from 'classnames';
import moment from 'moment';

import TitleH1 from '../common/atoms/title/TitleH1';
import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import OrgProductDetails from '../common/organisms/paidjoin/OrgProductDetails';

import * as styles from './paidjoinComplete.module.scss';

import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../../interfaces/fanclubInterFace';

interface Props {
  details?: FanclubDetails;
  firstPaymentDate?: string; // 2020-02-20
  onClickButton?: () => void;
}

const Component: FC<Props> = ({
  details = FanclubDetailsInitialValue,
  firstPaymentDate = '',
  onClickButton = () => undefined,
}) => {
  const classNames = ClassNames(styles.container);
  const paymentBodyText = useMemo(() => {
    const dateText = firstPaymentDate
      ? moment(firstPaymentDate).format('YYYY年M月DD日（M月分として）')
      : '不明';

    return dateText;
  }, [firstPaymentDate]);

  return (
    <div className={classNames}>
      <TitleH1 text="ご入会ありがとうございます" cssClass={styles.title} />
      <p className={styles.read}>お客様の入会手続きはすべて完了しました</p>

      <OrgProductDetails
        fanclub={details.fanclub}
        cssClass={styles.product}
        paymentTagText="初回決済日"
        paymentBodyText={paymentBodyText}
      />

      <ButtonRoundedCorners
        text="コミュニティTOPへ"
        type="Primary"
        size="Primary"
        onClick={onClickButton}
        cssClass={styles.button}
      />
    </div>
  );
};

export default Component;
