import { Fanclub, FanclubInitialValue } from './fanclubInterFace';

export type Options = {
  pr?: PROption;
  sns?: boolean;
  nav?: boolean;
  jasrac?: boolean;
  copyright?: boolean;
  button?: ButtonOption;
  post?: PostOption;
  fanclub?: FanclubOption;
  back?: BackOption;
  twitter?: TwitterOption;
  paidFC?: PaidFCOption;
};

/**
 * figma上は2つのボタンが表示されているが、
 * 現行仕様では右側のボタンのみを中央表示している
 */
export type PROption = {
  // left: {
  //   text: string;
  //   onClick: () => void;
  // };
  right: {
    text: string;
    onClick: () => void;
  };
};

export const PROptionInitialValue: PROption = {
  right: {
    text: '',
    onClick: () => undefined,
  },
};

export type ButtonOption = {
  text: string;
  onClick: () => void;
};

export const ButtonOptionInitialValue: ButtonOption = {
  text: '',
  onClick: () => undefined,
};

export type PostOption = {
  fanclubList: Fanclub[];
  onClickFanclub: (artistId: string) => void;
};

export const PostOptionInitialValue: PostOption = {
  fanclubList: [],
  onClickFanclub: () => undefined,
};

export type FanclubOption = {
  fanclub: Fanclub;
  text: string;
  onClickFanclub: () => void;
  onClickButton: () => void;
};

export const FanclubOptionInitialValue: FanclubOption = {
  fanclub: FanclubInitialValue,
  text: '',
  onClickFanclub: () => undefined,
  onClickButton: () => undefined,
};

export type BackOption = {
  onClick: () => void;
};

export const BackOptionInitialValue: BackOption = {
  onClick: () => undefined,
};

export type TwitterOption = {
  text: string;
  onClickButton: () => void;
};

export const TwitterOptionInitialValue: TwitterOption = {
  text: '',
  onClickButton: () => undefined,
};

export type PaidFCOption = {
  timeline: {
    state: boolean;
    onClick?: () => void;
  };
  chat: {
    state: boolean;
    onClick?: () => void;
  };
  unreadChat?: number;
};

export const PaidFCOptionInitialValue: PaidFCOption = {
  timeline: {
    state: false,
    onClick: () => undefined,
  },
  chat: {
    state: false,
    onClick: () => undefined,
  },
  unreadChat: 0,
};
