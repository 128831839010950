/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import moment from 'moment';

import noUserImageUrl from '../../assets/images/icon-nouser.png';
import blankImageUrl from '../../assets/images/blank-image.png';

import LabelTag from '../../atoms/label/LabelTag';
import IconThumbnailRectangle from '../../atoms/iconThumbnail/IconThumbnailRectangle';
import LabelTitleSeries from '../../atoms/label/LabelTitleSeries';
import IconCount from '../../atoms/iconParts/IconCount';

import McsIconLabelRoundQuaternary from '../../molecules/iconWithLabel/McsIconLabelRoundQuaternary';
import McsIconWithTextSecondary from '../../molecules/iconWithText/McsIconWithTextSecondary';

import * as styles from './OrgAdminListLikeUnit.module.scss';
import {
  Content,
  ContentInitialValue,
} from '../../../../interfaces/contentInterFace';

type PartialContent = Partial<Content>;

interface Props {
  cssClass?: string;
  content?: PartialContent;
  rank?: number;
  onClick?: () => void;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  content = ContentInitialValue,
  rank = 0,
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgAdminListLikeUnitBase, cssClass);

  const label =
    content?.type === 1
      ? 'ブログ'
      : content?.type === 2
      ? '画像'
      : content?.type === 3
      ? '動画'
      : content?.type === 4
      ? '音声'
      : '';

  return (
    <li className={classNames} onClick={onClick}>
      <div className={styles.OrgAdminListLikeUnitMainContent}>
        <div className={styles.OrgAdminListLikeUnitUserImage}>
          <IconThumbnailRectangle
            src={content.url || blankImageUrl}
            size="Secondary"
          />

          <IconCount
            type={
              rank === 1
                ? 'Tertiary'
                : rank === 2
                ? 'Quaternary'
                : rank === 3
                ? 'Quinary'
                : 'Senary'
            }
            countNumber={rank}
            cssClass={styles.OrgAdminListLikeUnitCount}
          />
        </div>
        <div>
          <LabelTitleSeries
            mainText={content?.subject}
            subText={
              content.release_at
                ? moment(content.release_at).format('YYYY.MM.DD HH:mm')
                : ''
            }
            type="Secondary"
            cssClass={styles.OrgAdminListLikeUnitUserText}
          />
          <McsIconLabelRoundQuaternary
            src={content.user?.icon_image || noUserImageUrl}
            mainText={content.user?.nickname}
            subText={content.user?.name_id ? `@${content.user?.name_id}` : ''}
          />
        </div>
      </div>

      <div className={styles.OrgAdminListLikeUnitSubContent}>
        <ul className={styles.OrgAdminListLikeUnitTag}>
          <li key={`${content.id}-${label}}`}>
            <LabelTag
              text={label}
              type="Senary"
              cssClass={styles.OrgAdminListLikeUnitTagItem}
            />
          </li>
        </ul>
        <div className={styles.OrgAdminListLikeUnitInfo}>
          <McsIconWithTextSecondary
            iconType="FavoriteFill"
            mainText={
              content.total_like ? content?.total_like?.toString() : '0'
            }
          />
        </div>
      </div>
    </li>
  );
};

export default OrgListUnit;
