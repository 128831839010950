import React, { FC } from 'react';
import ClassNames from 'classnames';

import LabelTag from '../../atoms/label/LabelTag';

import * as styles from './OrgPool.module.scss';

interface Props {
  price?: number;
  cssClass?: string;
}

const OrgPool: FC<Props> = ({ price = 0, cssClass = '' }) => {
  const classNames = ClassNames(styles.container, cssClass);

  return (
    <div className={classNames}>
      <LabelTag type="Secondary" text="振込待機額" />
      <p className={styles.price}>
        {price.toLocaleString()}
        <span>円</span>
      </p>
    </div>
  );
};

export default OrgPool;
