import * as ActionType from './adminContentsConstants';
import {
  GetAdminContentListParams,
  GetContentListResult,
  DeleteContentParams,
} from '../interfaces/contentInterFace';
import {
  GetFCMemberListParams,
  GetFCMemberListResult,
} from '../interfaces/memberInterFace';
import {
  GetFCCategoryListParams,
  GetFCCategoryListResult,
} from '../interfaces/categoryInterFace';

export const getReady = (artistId: string) =>
  ({
    type: ActionType.GET_READY,
    payload: { artistId },
  } as const);

export const gotReady = () =>
  ({
    type: ActionType.GOT_READY,
  } as const);

export const getFCMemberListEvent = {
  start: (params: GetFCMemberListParams) =>
    ({
      type: ActionType.GET_FC_MEMBER_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetFCMemberListResult) =>
    ({
      type: ActionType.GET_FC_MEMBER_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FC_MEMBER_LIST_FAIL,
    } as const),
};

export const getFCCategoryListEvent = {
  start: (params: GetFCCategoryListParams) =>
    ({
      type: ActionType.GET_FC_CATEGORY_LIST_START,
      payload: params,
    } as const),

  succeed: (result: GetFCCategoryListResult) =>
    ({
      type: ActionType.GET_FC_CATEGORY_LIST_SUCCEED,
      payload: { result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_FC_CATEGORY_LIST_FAIL,
    } as const),
};

export const getContentListEvent = {
  start: (params: GetAdminContentListParams) =>
    ({
      type: ActionType.GET_CONTENT_LIST_START,
      payload: params,
    } as const),

  succeed: (params: GetAdminContentListParams, result: GetContentListResult) =>
    ({
      type: ActionType.GET_CONTENT_LIST_SUCCEED,
      payload: { params, result },
    } as const),

  fail: () =>
    ({
      type: ActionType.GET_CONTENT_LIST_FAIL,
    } as const),
};

export const deleteContentEvent = {
  start: (params: DeleteContentParams & GetAdminContentListParams) =>
    ({
      type: ActionType.DELETE_CONTENT_START,
      payload: params,
    } as const),

  succeed: (params: DeleteContentParams) =>
    ({
      type: ActionType.DELETE_CONTENT_SUCCEED,
      payload: params,
    } as const),

  fail: () =>
    ({
      type: ActionType.DELETE_CONTENT_FAIL,
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // ready
  | ReturnType<typeof getReady>
  | ReturnType<typeof gotReady>
  // get content list
  | ReturnType<typeof getContentListEvent.start>
  | ReturnType<typeof getContentListEvent.succeed>
  | ReturnType<typeof getContentListEvent.fail>
  // get fc member list
  | ReturnType<typeof getFCMemberListEvent.start>
  | ReturnType<typeof getFCMemberListEvent.succeed>
  | ReturnType<typeof getFCMemberListEvent.fail>
  // get fc category list
  | ReturnType<typeof getFCCategoryListEvent.start>
  | ReturnType<typeof getFCCategoryListEvent.succeed>
  | ReturnType<typeof getFCCategoryListEvent.fail>
  // delete content
  | ReturnType<typeof deleteContentEvent.start>
  | ReturnType<typeof deleteContentEvent.succeed>
  | ReturnType<typeof deleteContentEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
