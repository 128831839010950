/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import * as styles from './McsTagWithLabel.module.scss';

interface Props {
  tagText?: string;
  mainText?: string;
}

const McsIconLabel: FC<Props> = ({ tagText = '', mainText = '' }) => {
  const classNames = ClassNames(styles.base);

  return (
    <div className={classNames}>
      <span className={styles.tag}>{tagText}</span>
      <span className={styles.text}>{mainText}</span>
    </div>
  );
};

export default McsIconLabel;
