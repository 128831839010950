import React, { FC } from 'react';
import ClassNames from 'classnames';

import TitleH1 from '../common/atoms/title/TitleH1';
import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';

import * as styles from './paidjoinError.module.scss';

interface Props {
  onClickButton?: () => void;
}

const Component: FC<Props> = ({ onClickButton = () => undefined }) => {
  const classNames = ClassNames(styles.container);

  return (
    <div className={classNames}>
      <TitleH1 text="決済エラー" cssClass={styles.title} />
      <p className={styles.read}>
        {
          'クレジットカード決済に失敗しました。\nカード情報を確認のうえ再度手続きをしてください。'
        }
      </p>

      <ButtonRoundedCorners
        text="もう一度カード情報を入力する"
        type="Primary"
        size="Primary"
        onClick={onClickButton}
        cssClass={styles.button}
      />
    </div>
  );
};

export default Component;
