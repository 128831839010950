/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import * as styles from './ImageThumbnail.module.scss';

interface Props {
  src?: string;
  type?: string;
  size?: string;
  cssClass?: string;
  onClick?: () => void;
}

const Thumbnail: FC<Props> = ({
  src = '',
  type = 'Primary',
  size = 'Primary',
  cssClass = '',
  onClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.ThumbnailBase, cssClass, {
    [styles.ThumbnailPrimary]: type === 'Primary', // Primary
    [styles.ThumbnailSizePrimary]: size === 'Primary', // Primary
  });

  return (
    <div
      className={classNames}
      style={{
        background: `url(${src}) #fff no-repeat center center / cover`,
      }}
      onClick={onClick}
    >
      {/* <img src={src} alt="" /> */}
    </div>
  );
};

export default Thumbnail;
