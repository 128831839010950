import React, { FC, useMemo, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getReady, cleanup } from '../../actions/newsDetailsActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import NewsDetails from '../../components/news/newsDetails';

const NewsContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { notificationId } = useParams<{ notificationId?: string }>();

  const { ready: authReady } = useSelector(selector.auth.getAuthState);
  const { ready, news } = useSelector(selector.newsDetails.getNewsDetailsState);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      pr: {
        right: {
          text: 'コミュニティを作る',
          onClick: () => history.push(PATH.OPEN_FC_AGREEMENT),
        },
      },
      sns: true,
      nav: true,
      copyright: true,
    }),
    [history],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    const id = Number(notificationId);
    if (!id) {
      history.replace(PATH.NotFound);

      return;
    }

    dispatch(getReady({ notificationId: id }));
  }, [authReady, dispatch, history, notificationId]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>お知らせ | KRAP</title>
        ) : (
          <title>{news.subject ?? ''} | お知らせ | KRAP</title>
        )}
      </Helmet>

      <OrgLoader isLoaded={authReady && ready} />

      <NewsDetails news={news} />
    </>
  );
};

export default NewsContainer;
