export const GET_READY = 'ADMIN_CATEGORY_EDIT/GET_READY' as const;
export const GOT_READY = 'ADMIN_CATEGORY_EDIT/GOT_READY' as const;

export const GET_FC_CATEGORY_LIST_START = 'ADMIN_CATEGORY_EDIT/GET_FC_CATEGORY_LIST_START' as const;
export const GET_FC_CATEGORY_LIST_SUCCEED = 'ADMIN_CATEGORY_EDIT/GET_FC_CATEGORY_LIST_SUCCEED' as const;
export const GET_FC_CATEGORY_LIST_FAIL = 'ADMIN_CATEGORY_EDIT/GET_FC_CATEGORY_LIST_FAIL' as const;

export const ADD_FC_CATEGORY_START = 'ADMIN_CATEGORY_EDIT/ADD_FC_CATEGORY_START' as const;
export const ADD_FC_CATEGORY_SUCCEED = 'ADMIN_CATEGORY_EDIT/ADD_FC_CATEGORY_SUCCEED' as const;
export const ADD_FC_CATEGORY_FAIL = 'ADMIN_CATEGORY_EDIT/ADD_FC_CATEGORY_FAIL' as const;

export const DELETE_FC_CATEGORY_START = 'ADMIN_CATEGORY_EDIT/DELETE_FC_CATEGORY_START' as const;
export const DELETE_FC_CATEGORY_SUCCEED = 'ADMIN_CATEGORY_EDIT/DELETE_FC_CATEGORY_SUCCEED' as const;
export const DELETE_FC_CATEGORY_FAIL = 'ADMIN_CATEGORY_EDIT/DELETE_FC_CATEGORY_FAIL' as const;

export const CLEANUP = 'ADMIN_CATEGORY_EDIT/CLEANUP' as const;
