import { call, put, fork, takeLatest } from 'redux-saga/effects';
import * as api from '../utils/axiosHelper';

import * as ActionType from '../actions/utilConstants';
import { getSelectFieldsEvent } from '../actions/utilActions';

import { GetSelectFieldsResponse } from '../interfaces/utilInterFace';

const getSelectFieldsHandler = api.getGetFactory();
function* runGetSelectFields(handler: typeof getSelectFieldsHandler) {
  try {
    const ReturnData: GetSelectFieldsResponse = yield call(
      handler,
      {},
      '/select_fields',
    );

    if (!ReturnData) {
      yield put(getSelectFieldsEvent.fail());

      return;
    }

    yield put(getSelectFieldsEvent.succeed(ReturnData.result));
  } catch (error) {
    yield put(getSelectFieldsEvent.fail());
  }
}
export function* getSelectFields(handler: typeof getSelectFieldsHandler) {
  yield takeLatest(
    ActionType.GET_SELECT_FIELDS_START,
    runGetSelectFields,
    handler,
  );
}

export const utilSagas = [fork(getSelectFields, getSelectFieldsHandler)];
