import { Reducer } from 'redux';
import { Actions } from '../actions/reissuePasswordResetPasswordActions';
import * as ActionType from '../actions/reissuePasswordResetPasswordConstants';
import { ResetPasswordErrors } from '../interfaces/passwordInterFace';

type State = {
  loading: boolean;
  errors: ResetPasswordErrors;
  success: boolean;
};

export const initialState: State = {
  loading: false,
  errors: {},
  success: false,
};

const reissuePasswordResetPasswordReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // reset password
    case ActionType.RESET_PASSWORD_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.RESET_PASSWORD_SUCCEED: {
      return {
        ...state,
        loading: false,
        success: true,
      };
    }
    case ActionType.RESET_PASSWORD_FAIL: {
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      };
    }

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default reissuePasswordResetPasswordReducer;
