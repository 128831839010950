import React, { FC } from 'react';
import ClassNames from 'classnames';

import {
  StripeElementStyle,
  StripeCardNumberElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
} from '@stripe/stripe-js';
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import { StripeInputErrors } from '../../../../interfaces/stripeInterFace';

import LabelTitle from '../../atoms/title/TitleDiv';

import McsInputSecondary from '../../molecules/formParts/McsInputSecondary';

import styles from './OrgCardInput.module.scss';
import './OrgCardInput.scss';

const STRIPE_OPTIONS_STYLE: StripeElementStyle = {
  base: {
    fontSize: '16px',
    '::placeholder': {
      color: '#CCCCCC',
    },
    fontFamily:
      "'Noto Sans JP',-apple-system, 'San Francisco', BlinkMacSystemFont, 'Helvetica Neue', 'Hiragino Sans', Meiryo, 'MS PGothic', sans-serif",
  },
};

interface Props {
  name?: string;
  onChangeCardInfo?: (
    e:
      | StripeCardNumberElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | StripeCardExpiryElementChangeEvent,
  ) => void;
  onChangeName?: (e: React.FormEvent<HTMLInputElement>) => void;
  errors?: StripeInputErrors;
  cssClass?: string;
}

const OrgCardInput: FC<Props> = ({
  name = '',
  onChangeCardInfo = () => undefined,
  onChangeName = () => undefined,
  errors = {},
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.base, cssClass);

  return (
    <div className={classNames}>
      <div className={styles.input}>
        <LabelTitle text="カード番号（半角数字）" cssClass={styles.label} />
        <CardNumberElement
          id="cardNumber"
          onChange={onChangeCardInfo}
          options={{
            showIcon: true,
            iconStyle: 'solid',
            placeholder: 'カード番号を入力',
            style: STRIPE_OPTIONS_STYLE,
          }}
        />
        {errors.cardNumber ? (
          <p className={styles.error}>{errors.cardNumber}</p>
        ) : null}
      </div>

      <div className={styles.input}>
        <LabelTitle text="有効期限（月/年）" cssClass={styles.label} />
        <CardExpiryElement
          id="expiry"
          onChange={onChangeCardInfo}
          options={{
            style: STRIPE_OPTIONS_STYLE,
          }}
        />
        {errors.cardExpiry ? (
          <p className={styles.error}>{errors.cardExpiry}</p>
        ) : null}
      </div>

      <McsInputSecondary
        type="Secondary"
        inputName="name"
        labelTitle="カード名義（半角）"
        placeholder="カード名義を入力"
        value={name}
        onChange={onChangeName}
        cssClass={styles.input}
        isError={!!errors.name}
        errorText={errors.name}
      />

      <div className={styles.input}>
        <LabelTitle
          text="セキュリティコード（半角数字）"
          cssClass={styles.label}
        />
        <CardCvcElement
          id="cvc"
          onChange={onChangeCardInfo}
          options={{
            placeholder: '',
            style: STRIPE_OPTIONS_STYLE,
          }}
        />
        {errors.cardCvc ? (
          <p className={styles.error}>{errors.cardCvc}</p>
        ) : null}
      </div>
    </div>
  );
};

export default OrgCardInput;
