import React, { FC } from 'react';
import ClassNames from 'classnames';

import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';

import McsIconLabelSquareTertiary from '../../molecules/iconWithLabel/McsIconLabelSquareTertiary';

import {
  Fanclub,
  FanclubInitialValue,
} from '../../../../interfaces/fanclubInterFace';

import * as styles from './OrgFCListUnitSecondary.module.scss';

type Props = {
  fanclub?: Partial<Fanclub>;
  isViewButton?: boolean;
  buttonText?: string;
  buttonType?: 'Primary' | 'Secondary';
  onClickFanclub?: () => void;
  onClickButton?: () => void;
  cssClass?: string;
};

const OrgListUnit: FC<Props> = ({
  fanclub = FanclubInitialValue,
  isViewButton = false,
  buttonText = '',
  buttonType = 'Primary',
  onClickFanclub = () => undefined,
  onClickButton = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgFCListUnitBase, cssClass);

  return (
    <li className={classNames} key={fanclub.id}>
      <McsIconLabelSquareTertiary
        src={fanclub.icon_image}
        mainText={fanclub.name}
        subText={fanclub.site_name}
        onClick={onClickFanclub}
        // onClick={() => {
        //   history.push(`/artist/${fanclub.group_id}`);
        // }}
        cssClass={styles.OrgFCListUnitUser}
      />
      {/* {!isManage && ( */}
      {isViewButton && (
        <ButtonRoundedCorners
          text={buttonText}
          type={buttonType}
          size="Septenary"
          cssClass={styles.OrgFCListUnitFollowButton}
          onClick={onClickButton}
        />
      )}
    </li>
  );
};

export default OrgListUnit;
