/* eslint-disable no-shadow */
import React, { FC, useState, useCallback, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  getReady,
  setAuthError,
  getUserProfileEvent,
  getUnreadCountEvent,
  logoutEvent,
} from './actions/authActions';
import { getSelectFieldsEvent } from './actions/utilActions';
import selector from './selectors';

import { useHistory } from './hooks';
import { disableScroll, enableScroll } from './utils/scrollHelper';
import { PATH } from './utils/routerHelper';
import { setOnboardedCookie, getOnboardedCookie } from './utils/utilHelper';

import OrgOnboarding from './components/common/organisms/onboarding/OrgOnBoarding';

import { Status } from './interfaces/commonInterFace';

import OnboardingImage1 from './components/common/assets/images/onboarding-01.png';
import OnboardingImage2 from './components/common/assets/images/onboarding-02.png';
import OnboardingImage3 from './components/common/assets/images/onboarding-03.png';
import OnboardingImage4 from './components/common/assets/images/onboarding-04.png';

const onboardingImageList = [
  OnboardingImage1,
  OnboardingImage2,
  OnboardingImage3,
  OnboardingImage4,
];

const Auth: FC = ({ children }) => {
  const dispatch = useDispatch();
  const [onboarded, setOnboarded] = useState(true);
  const { ready, access_token, error } = useSelector(
    selector.auth.getAuthState,
  );
  const history = useHistory();

  const handleFinishOnboarding = useCallback(() => {
    setOnboarded(true);
    setOnboardedCookie();
  }, []);

  useEffect(() => {
    dispatch(getReady());
    dispatch(getSelectFieldsEvent.start());

    // オンボーディング終了済みかどうか
    const onboarded = !!getOnboardedCookie();
    setOnboarded(onboarded);
  }, [dispatch]);

  useEffect(() => {
    if (!ready) return;

    if (access_token) {
      // ユーザ情報取得
      dispatch(getUserProfileEvent.start());
      dispatch(getUnreadCountEvent.start());
    } else {
      // ログアウト
      dispatch(logoutEvent.start());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [access_token, dispatch]);

  useEffect(() => {
    if (error === Status.Unauthorized) {
      // 401 Unauthorizedエラーが起きた場合に、
      // 強制ログアウト、ログインページへ遷移させる
      dispatch(logoutEvent.start());

      dispatch(setAuthError(false));

      history.push(PATH.SIGNUP);
    }

    if (error === Status.Forbidden) {
      dispatch(setAuthError(false));

      history.replace('/403');
    }

    if (error === Status.NotFound) {
      dispatch(setAuthError(false));

      history.replace('/404');
    }
  }, [dispatch, error, history]);

  useEffect(() => {
    if (onboarded) {
      enableScroll();
    } else {
      disableScroll();
    }
  }, [onboarded]);

  // URL変更時に、スクロール状態の初期化
  useEffect(() => {
    window.scrollTo(0, 0);
    enableScroll();
  }, [history.location]);

  return (
    <>
      {/* オンボーディング */}
      {onboarded ? null : (
        <OrgOnboarding
          imageList={onboardingImageList}
          onClickStart={handleFinishOnboarding}
        />
      )}
      {children}
    </>
  );
};

export default Auth;
