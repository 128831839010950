import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import { logInEvent, setAuthError } from '../../actions/authActions';
import {
  checkDuplicateEmailEvent,
  cleanup as signupCleanup,
} from '../../actions/signUpActions';
import { getTwitterAuthUrlEvent } from '../../actions/twitterActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';
import { validateEmail } from '../../utils/validationHelper';

import {
  LogInParams,
  LoginValidationErrors,
  SignUpParams,
  CheckDuplicateEmailValidationErrors,
} from '../../interfaces/authInterFace';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';

import SignUp from '../../components/signup/singUp';

const SignUpContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [logInParams, setLogInParams] = useState<LogInParams>({
    email: '',
    password: '',
    saveAuthInCookie: true,
  });
  const [checkDuplicateEmailParams, setCheckDuplicateEmailParams] = useState<
    Pick<SignUpParams, 'email'>
  >({
    email: '',
  });
  const { loading: loginLoading, error: logInError } = useSelector(
    selector.auth.getAuthState,
  );
  const isLogin = useSelector(selector.auth.getIsLogin);
  const {
    loading: signupLoading,
    errors: { check: checkDuplicateEmailErrors },
    success: { check: checkDuplicateEmailSuccess },
  } = useSelector(selector.signUp.getSignupState);
  const { auth } = useSelector(selector.twitter.getTwitterState);
  // const isViewPleaseLogin = useMemo(() => {
  //   const from = history.location?.state?.SIGNUP?.from;

  //   return from === 'contents';
  // }, [history.location]);
  const loginValidationErrors: LoginValidationErrors = useMemo(
    () => ({
      email: validateEmail(logInParams.email),
      password: '', // validatePassword(logInParams.password)
    }),
    [logInParams.email],
  );
  const checkDuplicateEmailValidationErrors: CheckDuplicateEmailValidationErrors = useMemo(
    () => ({
      email: checkDuplicateEmailErrors?.email?.length
        ? checkDuplicateEmailErrors.email[0]
        : validateEmail(checkDuplicateEmailParams.email),
    }),
    [checkDuplicateEmailErrors, checkDuplicateEmailParams.email],
  );

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    switch (name) {
      case 'id': {
        setLogInParams(params => ({
          ...params,
          email: value,
        }));
        break;
      }
      case 'pass': {
        setLogInParams(params => ({
          ...params,
          password: value,
        }));
        break;
      }
      case 'email': {
        setCheckDuplicateEmailParams(params => ({
          ...params,
          email: value,
        }));
        break;
      }
      default: {
        break;
      }
    }
  }, []);

  const handleChangeSaveAuthInCookie = useCallback(() => {
    setLogInParams(params => ({
      ...params,
      saveAuthInCookie: !params.saveAuthInCookie,
    }));
  }, []);

  const handleClickForgetPassword = useCallback(() => {
    history.push(PATH.REISSUE_PASSWORD_SEND_EMAIL);
  }, [history]);
  const handleLogIn = useCallback(() => {
    dispatch(logInEvent.start(logInParams));
  }, [dispatch, logInParams]);
  const handleLoginAndSignupWithTwitter = useCallback(() => {
    // Twitterログイン後のためにsessionStorageにrefererを保存
    if (history.referer) {
      sessionStorage.setItem('referer', history.referer);
    }
    dispatch(getTwitterAuthUrlEvent.start());
  }, [dispatch, history]);
  const handleRegister = useCallback(() => {
    dispatch(checkDuplicateEmailEvent.start(checkDuplicateEmailParams));
  }, [checkDuplicateEmailParams, dispatch]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    if (!auth.url) return;

    window.location.href = auth.url;
  }, [auth.url]);

  useEffect(() => {
    return () => {
      dispatch(signupCleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isLogin) {
      history.replace(history.referer ?? PATH.HOME);
    }
  }, [history, isLogin]);

  useEffect(() => {
    if (!checkDuplicateEmailSuccess) return;

    history.push(PATH.SIGNUP_REGISTER, {
      referer: history.referer,
      SIGNUP_REGISTER: {
        email: checkDuplicateEmailParams.email,
      },
    });
  }, [checkDuplicateEmailParams.email, checkDuplicateEmailSuccess, history]);

  useEffect(() => {
    if (logInError) {
      window.alert('ログインに失敗しました');

      dispatch(setAuthError(false));
    }
  }, [dispatch, logInError]);

  if (isLogin) return null;

  return (
    <>
      <Helmet>
        <title>ログイン・会員登録 | KRAP</title>
      </Helmet>

      {(loginLoading || signupLoading) && <OrgLoading />}

      <SignUp
        // isViewPleaseLogin={isViewPleaseLogin}
        isViewPleaseLogin={false}
        valueID={logInParams.email}
        valuePass={logInParams.password}
        isCheck={logInParams.saveAuthInCookie}
        onClickForgetPassword={handleClickForgetPassword}
        onTwitterLoginEvent={handleLoginAndSignupWithTwitter}
        onLoginEvent={handleLogIn}
        onSaveCheckEvent={handleChangeSaveAuthInCookie}
        valueEmail={checkDuplicateEmailParams.email}
        onTwitterRegisterEvent={handleLoginAndSignupWithTwitter}
        onRegisterEvent={handleRegister}
        onChange={handleChange}
        loginErrors={loginValidationErrors}
        checkDuplicateEmailErrors={checkDuplicateEmailValidationErrors}
      />
    </>
  );
};

export default SignUpContainer;
