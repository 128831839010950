/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */

export type FormDataParams = {
  [key in string]: number | number[] | string | string[] | File | File[];
};

type MethodType = 'GET' | 'POST' | 'PATCH' | 'DELETE' | 'PUT';

export const getFormData = (
  params: FormDataParams,
  methodType: MethodType = 'POST',
): FormData => {
  const formData = new FormData();

  Object.keys(params).forEach(key => {
    const value = params[key];

    // nullかundefinedの場合、パラメータを無視する
    if (value === undefined || value === null) return;

    // 配列はキー名を配列用に変換する
    if (Array.isArray(value)) {
      value.forEach((v: number | string | File) => {
        typeof v === 'number'
          ? formData.append(`${key}[]`, v.toString())
          : typeof v === 'boolean'
          ? formData.append(`${key}[]`, v ? '1' : '0')
          : formData.append(`${key}[]`, v);
      });

      return;
    }

    typeof value === 'number'
      ? formData.append(`${key}`, value.toString())
      : typeof value === 'boolean'
      ? formData.append(`${key}`, value ? '1' : '0')
      : formData.append(`${key}`, value);
  });

  if (
    methodType === 'PATCH' ||
    methodType === 'DELETE' ||
    methodType === 'PUT'
  ) {
    formData.append('_method', methodType);
  }

  return formData;
};
