/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC, useCallback } from 'react';
import ClassNames from 'classnames';

import IconShape from '../../atoms/iconParts/IconShape';

import * as styles from './McsNavFCLinkLList.module.scss';

interface Props {
  cssClass?: string;
  homeLink?: string;
  twitterLink?: string;
  faceBookLink?: string;
  instagramLink?: string;
}

const McsNavFCLinkLList: FC<Props> = ({
  cssClass = '',
  homeLink = '',
  twitterLink = '',
  faceBookLink = '',
  instagramLink = '',
}) => {
  const classNames = ClassNames(styles.McsNavFCLinkLListBase, cssClass);

  const openUrl = useCallback((url: string) => {
    if (!url) return;

    window.open(
      url,
      'window',
      'width=550, height=450, personalbar=0, toolbar=0, scrollbars=1',
    );
  }, []);

  return (
    <div className={classNames}>
      {homeLink ? (
        <IconShape
          type="FCHomeOn"
          cssClass={styles.McsNavFCLinkLListLink}
          onClick={() => {
            openUrl(homeLink);
          }}
        />
      ) : (
        <IconShape type="FCHomeOff" cssClass={styles.McsNavFCLinkLListLink} />
      )}
      {twitterLink ? (
        <IconShape
          type="FCTwOn"
          cssClass={styles.McsNavFCLinkLListLink}
          onClick={() => {
            openUrl(twitterLink);
          }}
        />
      ) : (
        <IconShape type="FCTwOff" cssClass={styles.McsNavFCLinkLListLink} />
      )}
      {faceBookLink ? (
        <IconShape
          type="FCFBOn"
          cssClass={styles.McsNavFCLinkLListLink}
          onClick={() => {
            openUrl(faceBookLink);
          }}
        />
      ) : (
        <IconShape type="FCFBOff" cssClass={styles.McsNavFCLinkLListLink} />
      )}
      {instagramLink ? (
        <IconShape
          type="FCIgOn"
          cssClass={styles.McsNavFCLinkLListLink}
          onClick={() => {
            openUrl(instagramLink);
          }}
        />
      ) : (
        <IconShape type="FCIgOff" cssClass={styles.McsNavFCLinkLListLink} />
      )}
    </div>
  );
};

export default McsNavFCLinkLList;
