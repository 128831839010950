/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { PATH } from '../../utils/routerHelper';

import {
  getReady,
  addFCCategoryEvent,
  deleteFCCategoryEvent,
  cleanup,
} from '../../actions/adminCategoryEditActions';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import OrgLoader from '../../components/common/organisms/contentParts/OrgLoader';
import AdminCategoryEdit from '../../components/admin/adminCategoryEdit';

const AdminCategoryEditContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { artistId } = useParams<{ artistId?: string }>();

  const [newCategoryText, setNewCategoryText] = useState('');
  const {
    ready: authReady,
    profile: {
      fanclubs: { manages },
    },
  } = useSelector(selector.auth.getAuthState);
  const { ready, current, loading, errors, success } = useSelector(
    selector.adminCategoryEdit.getAdminCategoryEditState,
  );
  const fanclub = useMemo(
    () => manages.find(fanclub => fanclub.group_id === artistId),
    [artistId, manages],
  );

  const validationError = useMemo(
    () =>
      current.find(category => category.name === newCategoryText)
        ? '同名のカテゴリが存在します'
        : '',

    [current, newCategoryText],
  );

  const handleChangeNewCategoryText = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      setNewCategoryText(e.currentTarget.value);
    },
    [],
  );

  const handleAdd = useCallback(() => {
    if (!newCategoryText || validationError || !artistId) return;

    dispatch(
      addFCCategoryEvent.start({
        artistId,
        name: newCategoryText,
      }),
    );
  }, [dispatch, artistId, newCategoryText, validationError]);

  const handleDelete = useCallback(
    (categoryId: number) => {
      if (!artistId) return;

      dispatch(
        deleteFCCategoryEvent.start({
          artistId,
          categoryId,
        }),
      );
    },
    [dispatch, artistId],
  );

  const handleBack = useCallback(() => {
    if (!artistId) return;

    history.push(PATH.getAdminPath(artistId, 'CONTENTS'));
  }, [artistId, history]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location, ready);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!authReady) return;

    if (!artistId || !fanclub) {
      history.replace(PATH.HOME);

      return;
    }

    dispatch(getReady(artistId));
  }, [authReady, dispatch, fanclub, artistId, history]);

  useEffect(() => {
    if (success.add) {
      setNewCategoryText('');
    }

    if (errors.add) {
      window.alert('カテゴリの追加に失敗しました');
    }

    if (errors.delete) {
      window.alert('カテゴリの削除に失敗しました');
    }
  }, [errors, success]);

  return (
    <>
      <Helmet>
        {!ready ? (
          <title>コミュニティ管理 | KRAP</title>
        ) : (
          <title>
            カテゴリ編集 | {fanclub?.name ?? ''} | コミュニティ管理 | KRAP
          </title>
        )}
      </Helmet>

      {loading && <OrgLoading />}
      <OrgLoader isLoaded={authReady && ready} />

      <AdminCategoryEdit
        fanclubName={fanclub?.site_name}
        categories={current}
        newCategoryText={newCategoryText}
        onChangeNewCategoryText={handleChangeNewCategoryText}
        onAdd={handleAdd}
        onDelete={handleDelete}
        onBack={handleBack}
        error={validationError}
      />
    </>
  );
};

export default AdminCategoryEditContainer;
