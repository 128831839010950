/* eslint-disable object-shorthand */
import React, { FC, useState, useEffect } from 'react';
import ClassNames from 'classnames';

import * as styles from './OrgLoader.module.scss';

interface Props {
  cssClass?: string;
  isLoaded?: boolean;
}

const getWindowSize = () => {
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  // const width = window.innerWidth || e.clientWidth || g.clientWidth;
  const height = window.innerHeight || e.clientHeight || g.clientHeight;

  return {
    // width,
    height,
  };
};
const OrgLoader: FC<Props> = ({ cssClass = '', isLoaded = false }) => {
  const classNames = ClassNames(styles.OrgLoaderBase, cssClass, {
    [styles.OrgLoaderDone]: isLoaded,
  });
  const classNamesLoaded = ClassNames(styles.OrgLoaderIcon, {
    [styles.OrgLoaderIconDone]: isLoaded,
  });

  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(getWindowSize().height);
  }, []);

  return (
    <div className={classNames}>
      <div className={classNames} style={{ height: height }}>
        <div className={classNamesLoaded} />
      </div>
    </div>
  );
};

export default OrgLoader;
