/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';

import IconShape from '../../atoms/iconParts/IconShape';

import * as styles from './OrgTwitterShareButton.module.scss';

type Props = {
  text?: string;
  onClickButton?: () => void;
  cssClass?: string;
};

const OrgTwitterShareButton: FC<Props> = ({
  text = '',
  onClickButton = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.OrgTwitterShareButtonBase, cssClass);

  return (
    <div className={classNames}>
      <p className={styles.OrgTwitterShareButtonText}>{text}</p>
      <button
        type="button"
        className={styles.OrgTwitterShareButtonButton}
        onClick={onClickButton}
      >
        <IconShape type="TwitterWhite" />
        <p>Twitterでシェアする</p>
      </button>
    </div>
  );
};

export default OrgTwitterShareButton;
