import React, { FC, useState, useMemo, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { useDispatch, useSelector } from 'react-redux';
import {
  sendEmailEvent,
  cleanup,
} from '../../actions/reissuePasswordSendEmailActions';
import selector from '../../selectors';

import { useHistory, useFooter, useGA } from '../../hooks';
import { validateEmail } from '../../utils/validationHelper';

import OrgLoading from '../../components/common/organisms/contentParts/OrgLoading';
import SendEmail from '../../components/reissuePassword/sendEmail';
import SendEmailComplete from '../../components/reissuePassword/sendEmailComplete';

const ReissuePasswordSendEmailContainer: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const { loading, error, success } = useSelector(
    selector.reissuePasswordSendEmail.getReissuePasswordSendEmailState,
  );
  const errorMessage = useMemo(() => error || validateEmail(email), [
    email,
    error,
  ]);

  const handleChange = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    setEmail(e.currentTarget.value);
  }, []);
  const handleSendEmail = useCallback(() => {
    dispatch(sendEmailEvent.start({ email }));
  }, [dispatch, email]);

  const footerOptions: Parameters<typeof useFooter>[0] = useMemo(
    () => ({
      copyright: true,
    }),
    [],
  );
  useFooter(footerOptions);

  useGA(history.location);

  useEffect(() => {
    return () => {
      dispatch(cleanup());
    };
  }, [dispatch]);

  if (success) {
    return <SendEmailComplete onReissuePassword={() => dispatch(cleanup())} />;
  }

  return (
    <>
      <Helmet>
        <title>パスワード再発行 | KRAP</title>
      </Helmet>

      {loading && <OrgLoading />}

      <SendEmail
        valueEmail={email}
        onIssueEvent={handleSendEmail}
        onChange={handleChange}
        onBackEvent={() => history.goBack()}
        error={errorMessage}
      />
    </>
  );
};

export default ReissuePasswordSendEmailContainer;
