import React, { FC } from 'react';
import ClassNames from 'classnames';
import McsIconLabelRoundTertiary from '../../molecules/iconWithLabel/McsIconLabelRoundTertiary';
import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';

import { User, UserInitialValue } from '../../../../interfaces/userInterFace';

import * as styles from './OrgUserListUnitMemberPrimary.module.scss';

type PartialUser = Partial<User>;

interface Props {
  cssClass?: string;
  user?: PartialUser;
  onInviteClick?: () => void;
  isAdminPage?: boolean;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  user = UserInitialValue,
  onInviteClick = () => undefined,
  isAdminPage = false,
}) => {
  const classNames = ClassNames(styles.OrgUserListUnitBase, cssClass);

  return (
    <li className={classNames} key={`OrgUserListUnitMemberPrimary${user.id}`}>
      <div className={styles.OrgUserListUnitUser}>
        <McsIconLabelRoundTertiary
          src={user.icon_image || ''}
          mainText={user.nickname}
          subText={`@${user.name_id}`}
          cssClass={styles.OrgUserListUnitUser}
        />
        <ButtonRoundedCorners
          text="招待する"
          type={isAdminPage ? 'Novenary' : 'Secondary'}
          size="Septenary"
          cssClass={styles.OrgUserListUnitInviteButton}
          onClick={onInviteClick}
        />
      </div>
    </li>
  );
};

export default OrgListUnit;
