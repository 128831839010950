import React, { FC } from 'react';
import ClassNames from 'classnames';

import SelectBox from '../../atoms/formParts/SelectBox';
import LabelTitle from '../../atoms/title/TitleDiv';
import LabelTag from '../../atoms/label/LabelTag';

import { SelectListObject } from '../../../../interfaces/formPartsInterFace';

import * as styles from './McsSelect.module.scss';

interface Props {
  value1: string;
  value2: string;
  value3: string;

  inputName1?: string;
  inputName2?: string;
  inputName3?: string;

  placeholder1?: string;
  placeholder2?: string;
  placeholder3?: string;

  maxLength?: number | undefined;
  cssClass?: string;

  selectList1: SelectListObject[];
  selectList2: SelectListObject[];
  selectList3: SelectListObject[];
  onClick?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onChange?: (e: React.FormEvent<HTMLSelectElement>, key?: any) => void;

  isDisabled?: boolean;

  isError?: boolean;
  errorText?: string;

  labelTitle?: string | undefined;
  labelTag?: string | undefined;
}

const McsSelect: FC<Props> = ({
  value1 = '',
  value2 = '',
  value3 = '',

  inputName1 = '',
  inputName2 = '',
  inputName3 = '',

  placeholder1 = '',
  placeholder2 = '',
  placeholder3 = '',

  cssClass = '',

  selectList1 = [],
  selectList2 = [],
  selectList3 = [],

  onClick = () => undefined,
  onFocus = () => undefined,
  onBlur = () => undefined,
  onChange = () => undefined,

  isDisabled = false,

  isError = false,
  errorText = 'Error',

  labelTitle = '',
  labelTag = '',
}) => {
  const classNames = ClassNames(styles.McsSelectBase, cssClass);

  return (
    <div className={classNames}>
      {labelTitle !== '' && (
        <div className={styles.McsSelectLabel}>
          <LabelTitle text={labelTitle} />
          {labelTag !== '' && <LabelTag text={labelTag} />}
        </div>
      )}
      <div className={styles.McsSelectFlex}>
        <SelectBox
          value={value1}
          selectList={selectList1}
          inputName={inputName1}
          placeholder={placeholder1}
          onChange={onChange}
          type="Tertiary"
          cssClass={styles.McsSelectFlexItem}
        />
        <SelectBox
          value={value2}
          selectList={selectList2}
          inputName={inputName2}
          placeholder={placeholder2}
          onChange={onChange}
          type="Tertiary"
          cssClass={styles.McsSelectFlexItem}
        />
        <SelectBox
          value={value3}
          selectList={selectList3}
          inputName={inputName3}
          placeholder={placeholder3}
          onChange={onChange}
          type="Tertiary"
          cssClass={styles.McsSelectFlexItem}
        />
      </div>
      {isError && <p className={styles.McsSelectBoxErrorText}>{errorText}</p>}
    </div>
  );
};

export default McsSelect;
