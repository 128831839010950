import * as ActionType from './changePasswordConstants';
import {
  ChangePasswordParams,
  ChangePasswordErrors,
} from '../interfaces/passwordInterFace';

export const changePasswordEvent = {
  start: (params: ChangePasswordParams) =>
    ({
      type: ActionType.CHANGE_PASSWORD_START,
      payload: params,
    } as const),

  succeed: () =>
    ({
      type: ActionType.CHANGE_PASSWORD_SUCCEED,
    } as const),

  fail: (errors: ChangePasswordErrors) =>
    ({
      type: ActionType.CHANGE_PASSWORD_FAIL,
      payload: { errors },
    } as const),
};

export const cleanup = () =>
  ({
    type: ActionType.CLEANUP,
  } as const);

export type Actions =
  // change password
  | ReturnType<typeof changePasswordEvent.start>
  | ReturnType<typeof changePasswordEvent.succeed>
  | ReturnType<typeof changePasswordEvent.fail>
  // cleanup
  | ReturnType<typeof cleanup>;
