/* eslint-disable no-unused-expressions */
import React, { FC, useState } from 'react';
import ClassNames from 'classnames';
import InputBox from '../../atoms/formParts/InputBox';
import LabelTitle from '../../atoms/title/TitleDiv';
import LabelTag from '../../atoms/label/LabelTag';

import * as styles from './McsInput.module.scss';

interface Props {
  value: string;
  type?: 'Secondary' | 'Senary';
  inputType?: string;

  inputName?: string;
  placeholder?: string;
  maxLength?: number;
  isHiddenMaxLengthInfo?: boolean;
  cssClass?: string;

  onClick?: () => void;
  onChange?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>, key?: string) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>, key?: string) => void;

  isDisabled?: boolean;

  isError?: boolean;
  errorText?: string;

  labelTitle?: string | undefined;
  labelTag?: string | undefined;
}

const McsInput: FC<Props> = ({
  value = '',
  type = 'Secondary',
  inputType = 'text',
  inputName = '',
  placeholder = '',
  maxLength,
  isHiddenMaxLengthInfo = false,
  cssClass = '',

  onClick = () => undefined,
  onChange = () => undefined,
  onFocus = () => undefined,
  onBlur = () => undefined,

  isDisabled = false,

  isError = false,
  errorText = 'Error',

  labelTitle = '',
  labelTag = '',
}) => {
  const classNames = ClassNames(styles.McsInputBase, cssClass);
  const [Type, setType] = useState('Tertiary');
  const typeChange = (addType: string) => {
    addType === type ? setType('Tertiary') : setType(type);
  };

  return (
    <div className={classNames}>
      {labelTitle !== '' && (
        <div className={styles.McsInputLabel}>
          <LabelTitle text={labelTitle} />
          {labelTag !== '' && <LabelTag text={labelTag} />}
        </div>
      )}
      <InputBox
        value={value}
        type={Type}
        inputType={inputType}
        inputName={inputName}
        maxLength={maxLength}
        placeholder={placeholder}
        onClick={onClick}
        onFocus={e => {
          onFocus(e);
          typeChange(Type);
        }}
        onBlur={e => {
          onBlur(e);
          typeChange(Type);
        }}
        onChange={onChange}
        isDisabled={isDisabled}
        isError={isError}
        errorText={errorText}
      />
      {!isHiddenMaxLengthInfo && maxLength ? (
        <div className={styles.McsInputMaxLength}>
          {value.length}/{maxLength}
        </div>
      ) : null}
    </div>
  );
};

export default McsInput;
