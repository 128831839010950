import { Status, ErrorResponse } from './commonInterFace';
import { InputFile } from './utilInterFace';

export type Administrator = {
  name: string;
  postal_code: string;
  address: string;
  birthday: string; // YYYY-MM-DD
  tel: string;
  email: string;
  identification_image: string;
  /**
   * 税抜き
   */
  price: number;
};

export const AdministratorInitialValue: Administrator = {
  name: '',
  postal_code: '',
  address: '',
  birthday: '',
  tel: '',
  email: '',
  identification_image: '',
  price: 0,
};

export type GetAdministratorParams = {
  artistId: string;
};
export type GetAdministratorResult = Administrator;
export type GetAdministratorResponse = {
  status_code: Status.OK;
  result: GetAdministratorResult;
};

export type UpdateAdministratorParams = {
  artistId: string;
  name: string;
  postal_code: string;
  address: string;
  email: string;
  birthday: string;
  tel: string;
  identification_image: File | null;
};
export type UpdateAdministratorResponse = {
  status_code: Status.OK;
  message: string;
};
export type UpdateAdministratorErrors = {
  [key in keyof Partial<UpdateAdministratorParams>]: string[];
};
export type UpdateAdministratorErrorResponse = ErrorResponse<
  keyof UpdateAdministratorParams
>;
export type UpdateAdministratorValidationErrors = Record<
  keyof UpdateAdministratorParams,
  string
>;
export const UpdateAdministratorValidationErrorsInitialValue: UpdateAdministratorValidationErrors = {
  artistId: '',
  name: '',
  postal_code: '',
  address: '',
  birthday: '',
  tel: '',
  email: '',
  identification_image: '',
};
export type UpdateAdministratorInputParams = Omit<
  UpdateAdministratorParams,
  'artistId' | 'birthday' | 'identification_image'
> & {
  birthday_year: number; // timestamp
  birthday_month: number; // timestamp
  birthday_date: number; // timestamp
  identification_image: InputFile;
};
export const UpdateAdministratorInputParamsInitialValue: UpdateAdministratorInputParams = {
  name: '',
  postal_code: '',
  address: '',
  email: '',
  birthday_year: 0,
  birthday_month: 0,
  birthday_date: 0,
  tel: '',
  identification_image: {
    file: null,
    preview: '',
  },
};
