import React, { FC } from 'react';
import ClassNames from 'classnames';

import ButtonRoundedCorners from '../../atoms/buttonBasic/ButtonRoundedCorners';

import * as styles from './McsHeaderComment.module.scss';

type Props = {
  total?: number;
  text?: string;
  onClickButton?: () => void;
  cssClass?: string;
};

const McsHeaderComment: FC<Props> = ({
  total = 0,
  text = '',
  onClickButton = () => undefined,
  cssClass = '',
}) => {
  const classNames = ClassNames(styles.McsHeaderWrap, cssClass);

  return (
    <div className={classNames}>
      <ButtonRoundedCorners
        text="戻る"
        type="Tertiary"
        size="Secondary"
        onClick={onClickButton}
        cssClass={styles.McsHeaderButton}
      />
      <span className={styles.McsHeaderTitle}>
        {total.toLocaleString()}
        {text}
      </span>
    </div>
  );
};

export default McsHeaderComment;
