import React, { FC } from 'react';
import ClassNames from 'classnames';

import * as styles from './OrgHistory.module.scss';
import { AmountList } from '../../../../interfaces/salesInterFace';

interface Props {
  summaryTitle?: string;
  cssClass?: string;
  summaryPrice?: number;
  list?: AmountList[];
}

const OrgPool: FC<Props> = ({
  summaryTitle = '',
  cssClass = '',
  summaryPrice = 0,
  list = [],
}) => {
  const classNames = ClassNames(styles.container, cssClass);

  return (
    <ul className={classNames}>
      <li className={styles.row}>
        <p className={styles.summaryTitle}>{summaryTitle}</p>
        <span className={styles.summaryPrice}>
          {`${summaryPrice.toLocaleString()}円`}
        </span>
      </li>
      {list.map(item => (
        <li className={styles.row} key={item.title}>
          <p className={styles.title}>{item.title}</p>
          <span className={styles.price}>
            {`${item.amount.toLocaleString()}円`}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default OrgPool;
