/* eslint-disable no-nested-ternary */
import React, { FC } from 'react';

import Tab from '../common/atoms/tab/Tab';
import LabelTag from '../common/atoms/label/LabelTag';
import IconClose from '../common/atoms/iconParts/IconClose';
import ButtonRoundedCorners from '../common/atoms/buttonBasic/ButtonRoundedCorners';
import ButtonSquare from '../common/atoms/buttonBasic/ButtonSquare';
import InputFileHero from '../common/atoms/formParts/InputFileHero';

import McsSelectSecondary from '../common/molecules/formParts/McsSelectSecondary';
import McsTitleSecondary from '../common/molecules/titleParts/McsTitleSecondary';
import McsInputSecondary from '../common/molecules/formParts/McsInputSecondary';
import McsInputDateTimeSecondary from '../common/molecules/formParts/McsInputDateTimeSecondary';

import OrgContentAddCategory from '../common/organisms/contentParts/OrgContentAddCategory';
import OrgAudioPlayer from '../common/organisms/mediaParts/OrgAudioPlayer';

import * as styles from './adminVoiceRegister.module.scss';

import {
  AdminContentsTabList,
  ContentType,
  InputVoiceParams,
  InputVoiceParamsInitialValue,
  RegisterVoiceValidationErrors,
  RegisterVoiceValidationErrorsInitialValue,
} from '../../interfaces/contentInterFace';
import { CategoryWithSelected } from '../../interfaces/categoryInterFace';
import { SelectField } from '../../interfaces/utilInterFace';

type Props = {
  mode?: 'create' | 'update';
  type?: ContentType;
  title?: string;
  params?: InputVoiceParams;
  fanclubName?: string;
  categoryList?: CategoryWithSelected[];
  openAreaList?: SelectField;
  copyrightList?: SelectField;
  isViewTab?: boolean;
  onChangeTab?: (type: ContentType) => void;
  onChange?: (
    e: React.FormEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
    key?: string,
  ) => void;
  onChangeFile?: (e: React.FormEvent<HTMLInputElement>, key?: string) => void;
  onClickDeleteThumbnail?: () => void;
  onChangeCategory?: (categoryId: number) => void;
  onAddCategory?: () => void;
  onClickJASRACDatabase?: () => void;
  canClickConfirm?: boolean;
  onConfirm?: () => void;
  onSaveAsDraft?: () => void;
  onDelete?: () => void;
  onBack?: () => void;
  errors?: RegisterVoiceValidationErrors;
  newCategory?: string;
  onChangeNewCategory?: (
    e: React.FormEvent<HTMLInputElement>,
    key?: string,
  ) => void;
  categoryError?: string;
};

const Component: FC<Props> = ({
  mode = 'create',
  type = 4,
  title = '',
  params = InputVoiceParamsInitialValue,
  fanclubName = '',
  categoryList = [],
  openAreaList = [],
  copyrightList = [],
  isViewTab = false,
  onChangeTab = () => undefined,
  onChange = () => undefined,
  onChangeFile = () => undefined,
  onClickDeleteThumbnail = () => undefined,
  onChangeCategory = () => undefined,
  onAddCategory = () => undefined,
  onClickJASRACDatabase = () => undefined,
  canClickConfirm = false,
  onConfirm = () => undefined,
  onSaveAsDraft = () => undefined,
  onDelete = () => undefined,
  onBack = () => undefined,
  errors = RegisterVoiceValidationErrorsInitialValue,
  newCategory = '',
  onChangeNewCategory = () => undefined,
  categoryError = '',
}) => {
  return (
    <div className={styles.containerWrap}>
      <McsTitleSecondary
        mainText={title}
        subText={fanclubName}
        onClick={onBack}
        cssClass={styles.contentTitle}
      />
      {isViewTab && (
        <ul className={styles.contentTabMenu}>
          {AdminContentsTabList.map(tab => (
            <Tab
              key={`Tab${tab.type}`}
              text={tab.label}
              type={type === tab.type ? 'Quinary' : 'Quaternary'}
              onClick={() => onChangeTab(tab.type)}
              cssClass={styles.contentTabMenuItem}
            />
          ))}
        </ul>
      )}
      <div className={styles.contentWrap}>
        <div className={styles.contentImageWrapper}>
          {params.thumb.preview && (
            <IconClose
              type="Secondary"
              onClick={onClickDeleteThumbnail}
              cssClass={styles.contentDeleteButton}
            />
          )}
          <InputFileHero
            title="サムネイル画像"
            inputNameHero="thumb"
            preview={params.thumb.preview}
            onChange={onChangeFile}
            cssClass={styles.contentItem}
            isError={!!errors.thumb}
            errorText={errors.thumb}
          />
        </div>
        <McsInputSecondary
          type="Senary"
          inputName="subject"
          value={params.subject}
          placeholder="タイトルを入力"
          onChange={onChange}
          isError={!!errors.subject}
          errorText={errors.subject}
        />
      </div>
      <div className={styles.contentWrap}>
        <div className={styles.contentItem}>
          {categoryList.map((category, i) => (
            <LabelTag
              key={`category-${i.toString()}`}
              text={category.name}
              type={category.selected ? 'Quaternary' : 'Quinary'}
              cssClass={styles.contentCategoryTag}
              onClick={() => onChangeCategory(category.id)}
            />
          ))}
        </div>
        <OrgContentAddCategory
          error={categoryError}
          text={newCategory}
          onChangeText={onChangeNewCategory}
          onClickAdd={onAddCategory}
        />
      </div>
      <div className={styles.contentWrap}>
        {params.voice.preview ? (
          <div>
            <OrgAudioPlayer src={params.voice.preview} />

            <div className={styles.contentReselectVoiceContainer}>
              <div className={styles.contentReselectVoiceButtonWrapper}>
                <input
                  name="voice"
                  type="file"
                  accept="audio/mpeg,audio/aac"
                  className={styles.contentReselectVoiceButtonInput}
                  onChange={onChangeFile}
                />
                <ButtonSquare
                  text="音声ファイルを再選択する"
                  type="Quaternary"
                  size="Tertiary"
                />
              </div>
              {errors.voice && (
                <p className={styles.InputErrorText}>{errors.voice}</p>
              )}
            </div>
          </div>
        ) : (
          <InputFileHero
            type="Tertiary"
            preview={params.voice.preview}
            fileName={params.voice.file?.name}
            fileType="audio"
            inputNameHero="voice"
            title="音声を選択する"
            onChange={onChangeFile}
            cssClass={styles.contentInputVoice}
            isError={!!errors.voice}
            errorText={errors.voice}
          />
        )}
      </div>
      {/* <div className={styles.contentWrap}> */}
        {/* <div style={{ marginTop: 24 }}>
          <McsSelectSecondary
            labelTitle="著作権（楽曲を投稿する場合）"
            inputName="copyright"
            value={params.copyright.toString()}
            selectList={copyrightList}
            onChange={onChange}
            isError={!!errors.copyright}
            errorText={errors.copyright}
          />
        </div> */}

        {/* {params.copyright === 2 && (
          <>
            <div style={{ marginTop: 24 }}>
              <McsInputSecondary
                type="Senary"
                labelTitle="楽曲名"
                inputName="song_title"
                value={params.song_title}
                placeholder="楽曲名を入力"
                onChange={onChange}
                isError={!!errors.song_title}
                errorText={errors.song_title}
              />
            </div>
            <div style={{ marginTop: 24 }}>
              <McsInputSecondary
                type="Senary"
                labelTitle="作曲者"
                inputName="composer"
                value={params.composer}
                placeholder="作曲者を入力"
                onChange={onChange}
                isError={!!errors.composer}
                errorText={errors.composer}
              />
            </div>
            <div style={{ marginTop: 24 }}>
              <McsInputSecondary
                type="Senary"
                labelTitle="作詞者"
                inputName="lyricist"
                value={params.lyricist}
                placeholder="作詞者を入力"
                onChange={onChange}
                isError={!!errors.lyricist}
                errorText={errors.lyricist}
              />
            </div>
            <div style={{ marginTop: 24 }}>
              <McsInputSecondary
                type="Senary"
                labelTitle="作品コード"
                inputName="work_code"
                value={params.work_code}
                placeholder="例：xxx-xxxx-x"
                onChange={onChange}
                isError={!!errors.work_code}
                errorText={errors.work_code}
              />
            </div>
            <div className={styles.contentJASRACButtonWrapper}>
              <ButtonSquare
                text="JASRAC作品データベース"
                type="Quaternary"
                size="Tertiary"
                onClick={onClickJASRACDatabase}
                cssClass={styles.contentJASRACButton}
              />
            </div>
          </>
        )} */}
      {/* </div> */}
      <div className={styles.contentWrap}>
        <McsInputDateTimeSecondary
          labelTitle="公開日時"
          inputName1="release_at_date"
          inputName2="release_at_time"
          value1={params.release_at_date}
          value2={params.release_at_time}
          onChange={onChange}
          cssClass={styles.contentItemSecondary}
          isError={!!errors.release_at}
          errorText={errors.release_at}
        />
        <McsInputDateTimeSecondary
          labelTitle="公開期限"
          labelSubTitle="設定すると、指定の時間に下書き状態になります。"
          inputName1="close_at_date"
          inputName2="close_at_time"
          value1={params.close_at_date}
          value2={params.close_at_time}
          onChange={onChange}
          cssClass={styles.contentItemSecondary}
          isError={!!errors.close_at}
          errorText={errors.close_at}
        />
        <McsSelectSecondary
          labelTitle="公開範囲"
          inputName="open_area"
          value={params.open_area.toString()}
          selectList={openAreaList}
          onChange={onChange}
          isError={!!errors.open_area}
          errorText={errors.open_area}
        />
      </div>
      <div className={styles.contentButtonWrap}>
        <ButtonRoundedCorners
          text="確認する"
          type={canClickConfirm ? 'Senary' : 'Quaternary'}
          onClick={onConfirm}
          cssClass={styles.contentButtonItem}
        />
        <ButtonRoundedCorners
          text="下書き保存"
          type="Septenary"
          onClick={onSaveAsDraft}
          cssClass={styles.contentButtonItem}
        />

        {mode === 'create' ? (
          <ButtonRoundedCorners
            text="キャンセル"
            type="Quinary"
            onClick={onBack}
            cssClass={styles.cancel}
          />
        ) : mode === 'update' ? (
          <ButtonRoundedCorners
            text="投稿を削除する"
            type="Quinary"
            onClick={onDelete}
            cssClass={styles.cancel}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Component;
