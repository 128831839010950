import { Reducer } from 'redux';
import { Actions } from '../actions/paidjoinActions';
import * as ActionType from '../actions/paidjoinConstants';
import {
  FanclubDetails,
  FanclubDetailsInitialValue,
} from '../interfaces/fanclubInterFace';
import { Card } from '../interfaces/cardInterFace';

type State = {
  details: FanclubDetails;
  card: Card | null;
  paidjoin_at: string;
  ready: boolean;
  loading: boolean;
  success: boolean;
  error: boolean;
};

export const initialState: State = {
  details: FanclubDetailsInitialValue,
  card: null,
  paidjoin_at: '',
  ready: false,
  loading: false,
  success: false,
  error: false,
};

const paidjoinReducer: Reducer<State, Actions> = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    // ready
    case ActionType.GET_READY: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GOT_READY: {
      return {
        ...state,
        loading: false,
        ready: true,
      };
    }

    // get fanclub details
    case ActionType.GET_FANCLUB_DETAILS_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_SUCCEED: {
      return {
        ...state,
        details: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_FANCLUB_DETAILS_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // get card info
    case ActionType.GET_CARD_INFO_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.GET_CARD_INFO_SUCCEED: {
      return {
        ...state,
        card: action.payload.result,
        loading: false,
      };
    }
    case ActionType.GET_CARD_INFO_FAIL: {
      return {
        ...state,
        loading: false,
      };
    }

    // paidjoin
    case ActionType.PAIDJOIN_FANCLUB_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.PAIDJOIN_FANCLUB_SUCCEED: {
      return {
        ...state,
        loading: false,
        paidjoin_at: action.payload,
        success: true,
      };
    }
    case ActionType.PAIDJOIN_FANCLUB_FAIL: {
      return {
        ...state,
        loading: false,
        error: true,
      };
    }

    // reset api status
    case ActionType.RESET_API_STATUS:
      return {
        ...state,
        success: false,
        error: false,
      };

    // CLEANUP
    case ActionType.CLEANUP:
      return {
        ...initialState,
      };

    default: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const _: never = action;

      return state;
    }
  }
};

export default paidjoinReducer;
