/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { FC } from 'react';
import ClassNames from 'classnames';
import noUserImageUrl from '../../assets/images/icon-nouser.png';
import IconThumbnailRound from '../../atoms/iconThumbnail/IconThumbnailRound';
import IconArrow from '../../atoms/iconParts/IconArrow';

import { User } from '../../../../interfaces/userInterFace';

import * as styles from './OrgFCListUnitMember.module.scss';

type PartialUser = Partial<User>;

interface Props {
  cssClass?: string;
  userList?: PartialUser[];
  limit?: number;
  text?: string;
  total?: number;
  onLinkClick?: () => void;
}

const OrgListUnit: FC<Props> = ({
  cssClass = '',
  userList = [],
  limit = 4,
  text = '',
  total = -1,
  onLinkClick = () => undefined,
}) => {
  const classNames = ClassNames(styles.OrgUserListUnitBase, cssClass);

  return (
    <div className={classNames} onClick={onLinkClick}>
      <div className={styles.OrgUserListUnitLeftComponent}>
        <ul>
          {userList.map((item, i) =>
            i <= limit - 1 ? (
              <IconThumbnailRound
                src={item.icon_image ? item.icon_image : noUserImageUrl}
                type="Secondary"
                size="Tertiary"
                key={`IconThumbnailRound${i}`}
                cssClass={styles.OrgUserListUnitIcon}
              />
            ) : null,
          )}
        </ul>
        <span className={styles.OrgUserListUnitText}>
          {total > 0 ? total : userList.length}
          {text}
        </span>
      </div>
      <IconArrow type="Tertiary" />
    </div>
  );
};

export default OrgListUnit;
